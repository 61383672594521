import { FC, useState } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom'
import Select from 'views/components/UI/Select'
import { TextArea, ActionDiv, InputGrid } from './styled'
import TitleDiv from 'views/components/UI/TitleDiv'
const Home: FC = () => {
  const navigate = useNavigate();
  const [alertLevel, setAlertLevel] = useState('')
  const skillsOptions = [
    { value: 'Competencia 1', label: 'Competencia 1' },
    { value: 'Competencia 2', label: 'Competencia 2' },
    { value: 'Competencia 3', label: 'Competencia 3' },
    { value: 'Competencia 4', label: 'Competencia 4' },
  ]
  const dimenssionOptions = [
    { value: 'Dimensión 1', label: 'Dimensión 1' },
    { value: 'Dimensión 2', label: 'Dimensión 2' },
    { value: 'Dimensión 3', label: 'Dimensión 3' },
    { value: 'Dimensión 4', label: 'Dimensión 4' },
  ]
  const resultOptions = [
    { value: 'Resultado 1', label: 'Resultado 1' },
    { value: 'Resultado 2', label: 'Resultado 2' },
    { value: 'Resultado 3', label: 'Resultado 3' },
    { value: 'Resultado 4', label: 'Resultado 4' },
  ]
  return (
        <MainLayout>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>Nuevo texto por resultado</h2>
          </TitleDiv>
          <InputGrid>
            <Select
              label="Competencia"
              options={skillsOptions}
              value={alertLevel}
              onChange={(e) => setAlertLevel(e)}
            />
            <Select
              label="Dimensión"
              options={dimenssionOptions}
              value={alertLevel}
              onChange={(e) => setAlertLevel(e)}
            />
            <Select
              label="Resultado"
              options={resultOptions}
              value={alertLevel}
              onChange={(e) => setAlertLevel(e)}
            />
          </InputGrid>
        <TextArea placeholder="Texto" />
        <ActionDiv>
        <span style={{ fontWeight: 'bold', color: '#2D749C', cursor:'pointer'}} onClick={() => { navigate(-1)}} >Cancelar</span>
        <button
          style={{
            background: ' #C6016B',
            width: 162,
            height: 52,
            padding: '0 20px',
            borderRadius: 8,
            color: '#fff',
            border: 'none',
          }}
        >
          Guardar
        </button>
      </ActionDiv>
        </MainLayout>
  )}
export default Home
