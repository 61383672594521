import axios from 'axios'


/** 
 * @description service to create a new skill question
 */
const getAllQuestionSkills = async (limit: number, page:number, categoryId: number | string) => {

    const response = await axios.get( `/question?page=${page}&limit=${limit}&category_id=${categoryId}`).then((res) => {
        return res.data
    }).catch((error) => {
        return { ok: false, message: error.message };
    })

    return response
  
}

export default getAllQuestionSkills
