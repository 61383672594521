import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3%;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

interface IRowContainer {
    justifyContent?: string;
}


export const RowContainer = styled.div<IRowContainer>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    width: 100%;
    height: 60px;
`;

export const ContainerItemType = styled.div`
    height: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 1%;
`;

export const UnderlineItemType = styled.div`
    width: 90%;
    height: 2px;
    background-color: #2D749C;
    margin-top: 5px;
`;

