import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ReportBetesa from 'views/screens/report'
import ReportSkills from 'views/screens/report/skill'
import NotFound from 'views/screens/NotFound'
import EvaluatedUser from 'views/screens/super-admin/info-evaluated-user'
import TestResults from 'views/screens/Modules/TestResults'
import Percentiles from 'views/screens/super-admin/percentiles'
import Home from 'views/screens/super-admin/home'
import Companies from 'views/screens/Companies'
import NewCompany from 'views/screens/Companies/New'
import UpdateCompany from 'views/screens/Companies/Update'
import Company from 'views/screens/Companies/Company'
import Modules from 'views/screens/Modules'
import Users from 'views/screens/Users'
import UserCompany from 'views/screens/Users'
import EvaluatedUserPerCompany from 'views/screens/super-admin/evaluated-user-company'
import EvaluatedUserPerCompanyDetail from 'views/screens/super-admin/evaluated-user-company-detail'
import TestResultsSkills from 'views/components/TestResultsSkills'
import AdminPlatformPerCompany from 'views/screens/super-admin/admin-platform-company/admin-platform-company'
import CreateGeneralText from 'views/screens/Modules/GeneralTexts/New'
import TalentsModule from 'views/screens/Modules/betesa-talents'
import SkillsModule from 'views/screens/Modules/Skills'
import SkillsQuestions from 'views/screens/Modules/Skills/questions'
import NewSkillQuestion from 'views/screens/Modules/Skills/questions/New'
import UpdateSkillQuestion from 'views/screens/Modules/Skills/questions/update'
import SkillsActions from 'views/screens/Modules/Skills/Actions'
import NewActionInSkills from 'views/screens/Modules/Skills/Actions/create'
import NewSkillActions from 'views/screens/Modules/Skills/Actions/create-action'
import UpdateSkillActions from 'views/screens/Modules/Skills/Actions/edit-actions'
import UpdateOneSkillActions from 'views/screens/Modules/Skills/Actions/edit-one-action'
import InterpretationIndex from 'views/screens/super-admin/betesa-interpretations'
import Interpret from 'views/screens/super-admin/betesa-interpretations/interpretation-detail'
import ReinterpretationBetesa from 'views/screens/super-admin/betesa-interpretations/reinterpretation-betesa-detail'
import ListBetesaInterpretationByUser from 'views/screens/super-admin/betesa-interpretations/list-betesa-by-user'
import SkillsTexts from 'views/screens/Modules/Skills/Texts'
import SkillsBank from 'views/screens/Modules/Skills/bank'
import SkillsNew from 'views/screens/Modules/Skills/new-skill'
import SkillsUpdate from 'views/screens/Modules/Skills/description'
import SkillsTextsResult from 'views/screens/Modules/Skills/Texts/AccordingToResult'
import SkillsTextsQalitative from 'views/screens/Modules/Skills/Texts/Qualitative'
import SkillsTextsGeneral from 'views/screens/Modules/Skills/Texts/General'
import NewSkillsTextsResult from 'views/screens/Modules/Skills/Texts/AccordingToResult/New'
import SkillsPercentils from 'views/screens/Modules/Skills/Percentils'
import UpdateSkillPercentil from 'views/screens/Modules/Skills/Percentils/Update'
import TalentsQuestionnaire from 'views/screens/Modules/betesa-talents/pages/questionnaires'
import TalentsQuestionnaireSettings from 'views/screens/Modules/betesa-talents/pages/questionnaires/Settings'
import TalentsQuestionnaireSettingsInfo from 'views/screens/Modules/betesa-talents/pages/questionnaires/Settings/info'
import TalentsQuestionnaireSettingsQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/Settings/questions'
import TalentsNewQuestionnaire from 'views/screens/Modules/betesa-talents/pages/questionnaires/New'
import TalentsChapterQuestionnaire from 'views/screens/Modules/betesa-talents/pages/questionnaires/chapter'
import TalentsCreateChapter from 'views/screens/Modules/betesa-talents/pages/questionnaires/chapter/create-chapter-questionnaire'
import TalentsQuestionnaireQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/questions'
import CreateTalentsQuestionnaireQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/questions/create'
import UpdateTalentsQuestionnaireQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/questions/update'
import TalentsTexts from 'views/screens/Modules/betesa-talents/pages/Texts'
import GeneralTalentsTexts from 'views/screens/Modules/betesa-talents/pages/Texts/Generals'
import AlertTalentsTexts from 'views/screens/Modules/betesa-talents/pages/Texts/AlertLevel'
import QuadrantTalentsTexts from 'views/screens/Modules/betesa-talents/pages/Texts/Qadrant'
import TalentsDevelopmentPlans from 'views/screens/Modules/betesa-talents/pages/development-plans'
import CreateAction from 'views/screens/Modules/betesa-talents/pages/development-plans/create-action'
import CreateTalent from 'views/screens/Modules/betesa-talents/pages/development-plans/create-talent'
import EditActions from 'views/screens/Modules/betesa-talents/pages/development-plans/edit-actions'
import EditOneAction from 'views/screens/Modules/betesa-talents/pages/development-plans/edit-one-action'

import UserSuspend from 'views/screens/Users/Suspension'

//INVENTORY
import Inventory from 'views/screens/super-admin/inventory'
import NewInventory from 'views/screens/inventory/new'
import UpdateInventory from 'views/screens/inventory/update'

import { specialAdminRoutes, superAdminRoutes } from 'router/routes'
import RoleFamilyRoutes from 'views/screens/super-admin/role-family/routes/role-family.routes'
import EditChapter from 'views/screens/Modules/betesa-talents/pages/questionnaires/chapter/edit-chapter/edit-chapter'
import EditTalent from 'views/screens/Modules/betesa-talents/pages/development-plans/edit-talent/edit-talent'
import TestResultsBetesa from 'views/components/TestResultsBetesa'
import PreviewGenerateReport from 'views/components/preview-generate-report'

const SpecialAdminRouter: FC = () => (
  <Routes>
    <Route path="/login" element={<Navigate to="/" replace />} />
    <Route path={superAdminRoutes.Home} element={<Home />} />
    <Route path={superAdminRoutes.InfoEvaluatedUser} element={<EvaluatedUser />} />
    <Route path={superAdminRoutes.ListBetesaUninterpreted} element={<InterpretationIndex />} />
    <Route path={superAdminRoutes.InterpretationBetesa} element={<Interpret />} />
    <Route path={superAdminRoutes.ReinterpretationBetesa} element={<ReinterpretationBetesa />} />
    <Route path={superAdminRoutes.ListBetesaInterpretedByUser} element={<ListBetesaInterpretationByUser />} />
    <Route path={superAdminRoutes.TestResult} element={<TestResults />} />
    <Route path={superAdminRoutes.Percentiles} element={<Percentiles />} />
    <Route path={superAdminRoutes.Companies} element={<Companies />} />
    <Route path={superAdminRoutes.CompanyInfo} element={<Company />} />
    <Route path={superAdminRoutes.CreateCompany} element={<NewCompany />} />
    <Route path={superAdminRoutes.EvaluatedUserByCompany} element={<EvaluatedUserPerCompany />} />
    <Route path={superAdminRoutes.InfoEvaluatedUserByCompany} element={<EvaluatedUserPerCompanyDetail />} />
    <Route path={superAdminRoutes.ResultsSkills} element={<TestResultsSkills />} />
    <Route path={superAdminRoutes.ResultsBetesa} element={<TestResultsBetesa />} />
    <Route path={superAdminRoutes.PlatformAdminsByCompany} element={<AdminPlatformPerCompany />} />
    <Route path={superAdminRoutes.UpdateCompany} element={<UpdateCompany />} />
    <Route path={superAdminRoutes.Users} element={<Users />} />
    <Route path={superAdminRoutes.UsersByCompany} element={<UserCompany />} />
    <Route path={superAdminRoutes.SuspendUser} element={<UserSuspend />} />
    <Route path={specialAdminRoutes.Modules} element={<Modules />} />
    <Route path={specialAdminRoutes.ModulesBetesa} element={<TalentsDevelopmentPlans />} />
    <Route path={specialAdminRoutes.ModulesTalents} element={<TalentsModule />} />
    <Route path={specialAdminRoutes.QuestionnairesBetesa} element={<TalentsQuestionnaire />} />
    <Route path={specialAdminRoutes.NewQuestionnaireBetesa} element={<TalentsNewQuestionnaire />} />
    <Route path={superAdminRoutes.ChaptersQuestionaire} element={<TalentsChapterQuestionnaire />} />
    <Route path={superAdminRoutes.CreateChapterQuestionaire} element={<TalentsCreateChapter />} />
    <Route path={superAdminRoutes.EditChapter} element={<EditChapter />} />
    <Route path={superAdminRoutes.EditChapter} element={<EditChapter />} />
    <Route path={superAdminRoutes.QuestionsTalentQuestionaire} element={<TalentsQuestionnaireQuestions />} />
    <Route path={superAdminRoutes.CreateQuestionQuestionaire} element={<CreateTalentsQuestionnaireQuestions />} />
    <Route path={superAdminRoutes.UpdateQuestionQuestionaire} element={<UpdateTalentsQuestionnaireQuestions />} />
    <Route path={superAdminRoutes.SettingQuestionaire} element={<TalentsQuestionnaireSettings />} />
    <Route path={superAdminRoutes.EditBasicInfoQuestionaire} element={<TalentsQuestionnaireSettingsInfo />} />
    <Route path={superAdminRoutes.QuestionsQuestionaire} element={<TalentsQuestionnaireSettingsQuestions />} />
    <Route path={specialAdminRoutes.BetesaTexts} element={<TalentsTexts />} />
    <Route path={specialAdminRoutes.BetesaGeneralTexts} element={<GeneralTalentsTexts />} />
    <Route path={specialAdminRoutes.AlertBetesaTexts} element={<AlertTalentsTexts />} />
    <Route path={specialAdminRoutes.QuadrantBetesaTexts} element={<QuadrantTalentsTexts />} />
    <Route path={specialAdminRoutes.BetesaActions} element={<TalentsDevelopmentPlans />} />
    <Route path={specialAdminRoutes.NewBetesaAction} element={<CreateAction />} />
    <Route path={specialAdminRoutes.NewBetesaTalent} element={<CreateTalent />} />
    <Route path={specialAdminRoutes.EditBetesaTalent} element={<EditTalent />} />
    <Route path={specialAdminRoutes.UpdateBetesaAction} element={<EditActions />} />
    <Route path={specialAdminRoutes.UpdateBetesaOneAction} element={<EditOneAction />} />
    <Route path={specialAdminRoutes.ModulesSkills} element={<SkillsModule />} />
    <Route path={specialAdminRoutes.NewSkill} element={<SkillsNew />} />
    <Route path={specialAdminRoutes.UpdateSkill} element={<SkillsUpdate />} />
    <Route path={specialAdminRoutes.SkillBank} element={<SkillsBank />} />
    <Route path={specialAdminRoutes.SkillsQuestions} element={<SkillsQuestions />} />
    <Route path={specialAdminRoutes.NewSkillsQuestion} element={<NewSkillQuestion />} />
    <Route path={specialAdminRoutes.UpdateSkillsQuestion} element={<UpdateSkillQuestion />} />
    <Route path={specialAdminRoutes.SkillsActions} element={<SkillsActions />} />
    <Route path={specialAdminRoutes.NewSkillsAction} element={<NewSkillActions />} />
    <Route path={specialAdminRoutes.NewActionSkill} element={<NewActionInSkills />} />
    <Route path={specialAdminRoutes.UpdateSkillsAction} element={<UpdateSkillActions />} />
    <Route path={specialAdminRoutes.UpdateSkillsOneAction} element={<UpdateOneSkillActions />} />
    <Route path={specialAdminRoutes.SkillTexts} element={<SkillsTexts />} />
    <Route path={specialAdminRoutes.TextsResultsSkills} element={<SkillsTextsResult />} />
    <Route path={specialAdminRoutes.TextNewResultSkills} element={<NewSkillsTextsResult />} />
    <Route path={specialAdminRoutes.QualitativeTextsSkills} element={<SkillsTextsQalitative />} />
    <Route path={specialAdminRoutes.GeneralTextsSkills} element={<SkillsTextsGeneral />} />
    <Route path={superAdminRoutes.PercentilsSkills} element={<SkillsPercentils />} />
    <Route path={superAdminRoutes.UpdatePercentilsSkills} element={<UpdateSkillPercentil />} />
    <Route path={superAdminRoutes.CreateGeneralText} element={<CreateGeneralText />} />
    <Route path={`${superAdminRoutes.RoleFamily}/*`} element={<RoleFamilyRoutes />} />
    <Route path={superAdminRoutes.Inventory} element={<Inventory />} />
    <Route path={superAdminRoutes.NewInventory} element={<NewInventory />} />
    <Route path={superAdminRoutes.UpdateInventory} element={<UpdateInventory />} />
    <Route path="/report" element={<ReportBetesa />} />
    <Route path="/report-skill" element={<ReportSkills />} />
    <Route path="/reports-download" element={<PreviewGenerateReport />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default SpecialAdminRouter
