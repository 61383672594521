import Modal from 'views/components/UI/Modal'
import { Button, ButtonBack, ButtonContainer, Container, SubTitleContainer, Title } from './styled'
import { FC } from 'react'

interface ModalProps {
  showConfirmModal: boolean
  setShowConfirmModal: (e: boolean) => void
  onConfirm: () => void
}

const FinishProcessModal: FC<ModalProps> = ({ showConfirmModal, setShowConfirmModal, onConfirm }) => {
  const handleCancel = () => {
    setShowConfirmModal(false)
  }

  const handleConfirm = () => {
    onConfirm()
    setShowConfirmModal(false)
  }

  return (
    <Modal styleContent={{ width: '500px' }} show={showConfirmModal} setShow={setShowConfirmModal}>
      <Container>
        <SubTitleContainer>
          <Title>¿Estás seguro que deseas crear un proceso sin agregar usuarios?</Title>
        </SubTitleContainer>
        <ButtonContainer>
          <ButtonBack onClick={handleCancel}>Cancelar</ButtonBack>
          <Button onClick={handleConfirm}>Finalizar proceso</Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default FinishProcessModal
