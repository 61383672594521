import { createSelector } from '@reduxjs/toolkit'

// TODO: import Store type and add reducer type in Store interface
const getUser = (state: any) => state.user

const getToken = (state: any) => state.user.token

const getError = (state: any) => state.user.error


export const userSelector = createSelector(getUser, (user) => user)

export const tokenSelector = createSelector(getToken, (token) => token)

export const errorSelector = createSelector(getError, (error) => {
    return error
})

export const companyIdSelector = createSelector(getUser, (user) => user.companyId)