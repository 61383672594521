import styled from 'styled-components'

interface IDividerProps {
  margin?: string
}

export const Divider = styled.div<IDividerProps>`
  width: 100%;
  height: 1px;
  background-color: #1f2124;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 3.5%;
  margin-bottom: 40px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
`

export const StepsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 3% 0%;
`

export const GridContainer = styled.div`
  width: 100%;
  padding: 0% 0%;
  margin-top: 20px;
`

export const GridCompetence = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  width: 100%;
  padding: 0% 0%;
`

interface IGridItemProps {
  flexDirection?: string
  margin?: string
  height?: string
  width?: string
}

export const GridItem = styled.div<IGridItemProps>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '70px')};
  padding: 0% 0%;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`

export const TitleGrid = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1f2124;
  font-family: 'Public Sans', sans-serif;
  font-weight: 600;
  margin: 10px 0px 1px 0px;
`

interface IRowProps {
  width?: string
  justify?: string
  height?: string
  margin?: string
}

export const Row = styled.div<IRowProps>`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.width ? props.width : '100%')};
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`

// competences added

export const CompetencesAddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0% 0%;
  margin-top: 20px;
`

export const BottomButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
`
