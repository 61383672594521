import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getAdminsByCompany from 'services/companies/getAdmins/get-admins-service';
import { useNotification } from 'lib/context/notification.context';
import { userSelector } from 'ducks/user';
import { Admin } from 'types/common';

function useAdminsByCompany() {
  const { getError, getWarning } = useNotification();
  const { companyId } = useSelector(userSelector);

  const [adminsByCompany, setAdminsByCompany] = useState<Admin[]>([]);
  const [loadingAdminsByCompany, setLoadingAdminsByCompany] = useState(false);
  const [errorAdminsByCompany, setErrorAdminsByCompany] = useState<any>(null);

  useEffect(() => {
    const fetchAdminsByCompany = async () => {
      setLoadingAdminsByCompany(true);
      try {
        const response = await getAdminsByCompany(companyId);
        if (response.status === 'OK') {
          setAdminsByCompany(response.data);
        } else {
          setErrorAdminsByCompany('Ha ocurrido un error al intentar obtener los administradores disponibles');
          getWarning('Crea a otros administradores en esta compañía para asignar permisos.');
        }
      } catch (error) {
        setErrorAdminsByCompany('Ha ocurrido un error al intentar obtener los administradores disponibles');
        getError('Ha ocurrido un error al intentar obtener los administradores disponibles');
      } finally {
        setLoadingAdminsByCompany(false);
      }
    };

    fetchAdminsByCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return { adminsByCompany, loading: loadingAdminsByCompany, error: errorAdminsByCompany };
}

export default useAdminsByCompany;