import { FC, useEffect, useState } from 'react'
import {
  UpdateTag,
  GridModal,
  ModalContainer,
  ModalHeader,
  ModalBody,
  Title,
  UpdateDiv,
  RowTitle,
  RoleFamilyContentData,
  RoleFamilyDataItem,
  RoleFamilyContent,
  ItemContent,
  TextWidthDotContainer,
  Dot,
  TextDot,
  NavigationContainer,
  NavigationItem,
} from './styled'
import Modal from 'views/components/UI/Modal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import EditIcon from 'assets/icons/EditCoin'
import RightWithCircleIcon from 'assets/icons/RightWithCircleIcon'
import LeftWithCircleIcon from 'assets/icons/LeftWithCircleIcon'
import { P } from 'views/components/UI/Text'
import { getFamilyRolInformation } from 'services/rolFamily/fetchGetRolFamily'
import { useNavigate } from 'react-router-dom'
import { superAdminRoutes } from 'router/routes'
import { ROLE_FAMILY_SETTINGS_PATH } from '../../constants'
import { useNotification } from 'lib/context/notification.context'
import { t } from 'i18next'

interface ActionDetailModalProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  actionId: number
  onNextRoleFamily: () => void
  onPrevRoleFamily: () => void
}
interface FamilyDataInterface {
  betesa_skills: any[]
  competence_skills: any[]
  family_skills: any[]
  ideal_profile: any[]
  role_families: any
  typical_positions: any[]
  description?: string
  test_types?: string
}

interface ItemTextWidthDotProps {
  text?: string
  title?: string
}

const TextWidthDot = (props: ItemTextWidthDotProps) => {
  const { text, title } = props

  return (
    <TextWidthDotContainer>
      <Dot />
      {title && (
        <P marginLeft="10px" weight={500} marginRight="8px">
          {title}
        </P>
      )}
      <TextDot>{text}</TextDot>
    </TextWidthDotContainer>
  )
}

const ActionDetailModal: FC<ActionDetailModalProps> = ({
  openModal,
  setOpenModal,
  actionId,
  onNextRoleFamily,
  onPrevRoleFamily,
}) => {
  const navigate = useNavigate()
  const { getWarning } = useNotification()
  const [familyData, setFamilyData] = useState<FamilyDataInterface>()

  const getFamilyInfo = () => {
    getFamilyRolInformation(actionId).then((res: any) => {
      if (res.status === 'OK') {
        setFamilyData(res.data)
      } else {
        getWarning('No fue posible obtener la información selecionada')
      }
    })
  }

  const handleButtonClick = (id: number) => {
    navigate(`${superAdminRoutes.RoleFamily}/${ROLE_FAMILY_SETTINGS_PATH}/${id}`)
  }

  useEffect(() => {
    if (actionId) {
      getFamilyInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId])

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '55%',
        height: '80%',
        borderRadius: '10px',
        padding: '20px',
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>
        <ModalBody>
          <RowTitle>
            <Title>{familyData?.role_families[0].name}</Title>
            <UpdateDiv>
              <EditIcon />
              <UpdateTag
                onClick={() => {
                  handleButtonClick(familyData?.role_families[0].id)
                  
                }}
              >
                Editar familia de rol
              </UpdateTag>
            </UpdateDiv>
          </RowTitle>
          <GridModal>
            <RoleFamilyContentData>
              <RoleFamilyContent>
                <ItemContent>
                  <RoleFamilyDataItem width="25%">
                    <P fontWeight={700}>Descripción general:</P>
                  </RoleFamilyDataItem>
                  <RoleFamilyDataItem width="75%">
                    <P color="#797A7C">{familyData?.role_families[0].description || 'N/A'}</P>
                  </RoleFamilyDataItem>
                </ItemContent>
                <ItemContent>
                  <RoleFamilyDataItem width="25%">
                    <P fontWeight={700}>Cargos típicos</P>
                  </RoleFamilyDataItem>
                  <RoleFamilyDataItem width="75%">
                    {familyData?.typical_positions?.map((typicalPosition: any) => (
                      <TextWidthDot key={typicalPosition.id} text={typicalPosition.name} />
                    ))}
                  </RoleFamilyDataItem>
                </ItemContent>
                <ItemContent>
                  <RoleFamilyDataItem width="25%">
                    <P fontWeight={700}>Nivel:</P>
                  </RoleFamilyDataItem>
                  <RoleFamilyDataItem width="75%">
                    <P color="#797A7C"> {familyData?.role_families[0].level} </P>
                  </RoleFamilyDataItem>
                </ItemContent>
                <ItemContent>
                  <RoleFamilyDataItem width="25%">
                    <P fontWeight={700}>Diagnósticos sugeridos:</P>
                  </RoleFamilyDataItem>
                  <RoleFamilyDataItem width="75%">
                    <P color="#797A7C">
                      {' '}
                      {familyData?.test_types ||
                        `
                      ${familyData?.competence_skills?.length ? t('global.common.skillText') : ''}    
                      ${
                        familyData?.competence_skills?.length &&
                        (familyData?.ideal_profile.length || familyData?.betesa_skills?.length)
                          ? ' - '
                          : ''
                      }
                      ${familyData?.betesa_skills?.length || familyData?.ideal_profile.length ? 'Betesa' : ''}  
                      `}
                    </P>
                  </RoleFamilyDataItem>
                </ItemContent>
                <ItemContent>
                  <RoleFamilyDataItem width="25%">
                    <P fontWeight={700}>Habilidades generales asociadas:</P>
                  </RoleFamilyDataItem>
                  <RoleFamilyDataItem width="75%">
                    {familyData?.family_skills?.map((skill: any) => (
                      <TextWidthDot key={skill.id} text={skill.name} />
                    ))}
                  </RoleFamilyDataItem>
                </ItemContent>
                {familyData?.competence_skills?.length ? (
                  <ItemContent>
                    <RoleFamilyDataItem width="25%">
                      <P fontWeight={700}>Perfi lideal (Competencias):</P>
                    </RoleFamilyDataItem>
                    <RoleFamilyDataItem width="75%">
                      {familyData?.competence_skills?.map(({ id, skill, weight }: any) => (
                        <TextWidthDot key={id} title={`${skill}:`} text={`${weight}`} />
                      ))}
                    </RoleFamilyDataItem>
                  </ItemContent>
                ) : null}

                {familyData?.ideal_profile.length ? (
                  <ItemContent>
                    <RoleFamilyDataItem width="25%">
                      <P fontWeight={700}>Perfil ideal (Betesa):</P>
                    </RoleFamilyDataItem>
                    <RoleFamilyDataItem width="75%">
                      {familyData?.ideal_profile?.map(({ id, quadrant, min, max }: any) => (
                        <TextWidthDot key={id} title={quadrant} text={`${min} - ${max}`} />
                      ))}
                    </RoleFamilyDataItem>
                  </ItemContent>
                ) : null}
                <NavigationContainer>
                  <NavigationItem onClick={onPrevRoleFamily}>
                    <LeftWithCircleIcon />
                    <P color="#2D749C" fontWeight={700}>
                      Anterior
                    </P>
                  </NavigationItem>
                  <NavigationItem onClick={onNextRoleFamily}>
                    <P color="#2D749C" fontWeight={700}>
                      Siguiente
                    </P>
                    <RightWithCircleIcon />
                  </NavigationItem>
                </NavigationContainer>
              </RoleFamilyContent>
            </RoleFamilyContentData>
          </GridModal>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}

export default ActionDetailModal
