import { ANDROID_DEVICE, IOS_DEVICE } from 'constants/global.constants'
import styled, { css } from 'styled-components'

const iOS = IOS_DEVICE.test(navigator.userAgent)

const Android = ANDROID_DEVICE.test(navigator.userAgent)

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fafcff;
  @media (max-width: 992px) {
    /* padding-top:40%; */
    /* padding-bottom:20%; */
  }

  ${iOS &&
  css`
    height: 100dvh;
    overflow-y: auto;
    /* @media (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 20%;
    }
    @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      margin-top: 40px;
      padding-top: 35%;
    }
    @media (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 20%;
    }
    @media (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 20%;
    }
    @media (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 15%;
    }
    @media (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 15%;
    } */
  `}
  ${Android &&
  css`
    margin-top: 80px;
    height: auto;
    overflow-y: auto;
  `}
`
interface IContentProps {
  isSidebarOpen: boolean
}

export const Content = styled.div<IContentProps>`
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  transition: margin-left 0.5s ease-in-out;
  margin: 0 auto;
  padding: 2% 5%;
  background-color: #fafcff;

  @media (max-width: 992px) {
    display: flex;
    box-sizing: border-box;
    margin: auto;
    height: max-content;
  }
  @media (max-width: 768px) {
    display: block;
    justify-content: center;
    align-content: center;
    margin: auto;
    margin-top: 0;
  }
  ${iOS &&
  css`
    overflow-y: auto;
    @media (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      margin-top: 0 !important;
    }
  `}
`
