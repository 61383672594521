import { FC } from 'react'
import { store, persistor } from 'lib/redux'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { theme } from '../src/lib/theme'
import StyledApp from '../src/lib/theme/StyledApp'
import { PersistGate } from 'redux-persist/integration/react'
import Router from './router'
import { NotificationProvider } from 'lib/context/notification.context'

const App: FC = () => (
  <NotificationProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <StyledApp />
          <Router />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </NotificationProvider>
)
export default App
