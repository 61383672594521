import { useRef } from 'react'
import { Formik, Form, FormikProps } from 'formik'
import { Button } from 'views/components/UI/Buttons'
import { TextError, NewContainerItem, Container } from './styled'
import Input from 'views/components/UI/TextInput'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim() // Elimina espacios al inicio y al final
    .min(2, 'El nombre debe tener al menos 2 caracteres')
    .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, 'El nombre solo debe contener letras y espacios')
    .required('El nombre es requerido'),

  last_name: Yup.string()
    .trim()
    .min(2, 'El apellido debe tener al menos 2 caracteres')
    .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, 'El apellido solo debe contener letras y espacios')
    .required('El apellido es requerido'),

  email: Yup.string().trim().email('El email no es válido').required('El correo es requerido'),
})

type ISuperadmin = {
  first_name: string
  last_name: string
  email: string
  document_type?: string
  document?: string
}

interface IProps {
  onSubmit: (values: ISuperadmin) => void
  onCancel: () => void
}

type FormValues = {
  first_name: string
  last_name: string
  email: string
  document_type?: string
  document?: string
}

const NewSuperAdmin = (props: IProps) => {
  const { onSubmit, onCancel } = props

  const formikRef = useRef<FormikProps<FormValues>>(null)

  const initialValues: ISuperadmin = {
    first_name: '',
    last_name: '',
    email: '',
  }

  const handleSubmit = (values: any, { resetForm }: { resetForm: () => void }): void => {
    onSubmit(values)
    resetForm()
  }

  return (
    <Container>
      <Formik
        validateOnChange={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={handleSubmit}
      >
        {({ errors, values, handleChange, isSubmitting }) => (
          <Form>
            <NewContainerItem>
              <Input
                value={values.first_name}
                onChange={handleChange}
                name="first_name"
                type="text"
                label="Nombre"
                placeholder="Nombre"
                style={{ width: '100%' }}
              />
              {errors.first_name && <TextError>{errors.first_name}</TextError>}
            </NewContainerItem>
            <NewContainerItem>
              <Input
                value={values.last_name}
                onChange={handleChange}
                name="last_name"
                type="text"
                label="Apellido"
                placeholder="Apellido"
                style={{ width: '100%' }}
              />
              {errors.last_name && <TextError>{errors.last_name}</TextError>}
            </NewContainerItem>
            <NewContainerItem>
              <Input
                value={values.email}
                onChange={handleChange}
                name="email"
                type="text"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                style={{ width: '100%' }}
              />
              {errors.email && <TextError>{errors.email}</TextError>}
            </NewContainerItem>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <p
                onClick={() => {
                  onCancel()
                  formikRef.current?.resetForm()
                }}
                style={{
                  color: '#2D749C',
                  fontSize: 14,
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                Cancelar
              </p>
              <Button disabled={Object.keys(errors).length > 0 || isSubmitting} type="submit">
                Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default NewSuperAdmin
