import React, { useState, useEffect } from 'react'

import {
  ContainerItems,
  GridContainer,
  GridCompetence,
  GridItem,
  TitleGrid,
  Divider,
  Row,
  CompetencesAddedContainer,
  PaginationDiv,
} from './styled'
import { H4, P } from 'views/components/UI/Text'
import { Button } from 'views/components/UI/Buttons'
import SearchBar from 'views/components/Searchbar'
import Pagination from '@mui/material/Pagination'
import ActionDetailModal from '../Modal'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import fetchCompetences from 'services/competence/fechtCompetences'
import Stack from '@mui/material/Stack'
import { Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'

function ListTable({ handleAdd, competencesAdded, setCompetencesAdded, filterData }: any) {
  const { t } = useTranslation()
  const [competences, setCompetences] = useState<any>({
    data: [],
    total_pages: 0,
  })
  const [actionId, setActionId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [selectedPage, setSelected] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const handleAddCompetence = (competence: any, competenceId: any) => {
    handleAdd(competence, competenceId)
  }

  async function handleSearch(page = 1, searchValueString?: string) {
    if (searchValueString) {
      await fetchCompetences(page, 10, searchValueString, filterData)
        .then(({ skills, total_pages }) => {
          setCompetences({
            data: skills,
            total_pages: total_pages,
          })
        })
    } else {
      await fetchCompetences(page, 10, undefined, filterData)
        .then(({ skills, total_pages }) => {
          setCompetences({
            data: skills,
            total_pages: total_pages,
          })
        })
    }
  }

  const handlePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
    setSelected(value)
    handleSearch(value)
  }

  const handleOpenModal = (id: any) => {
    setActionId(id)
    setOpenModal(true)
  }

  useEffect(() => {
    const handleSearchInitial = async () => {
      await fetchCompetences(1, undefined, undefined, filterData)
        .then(({ skills, total_pages }) => {
          setCompetences({
            data: skills,
            total_pages: total_pages,
          })
        })
    }

    handleSearchInitial()
  }, [filterData])

  return (
    <>
      <ActionDetailModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        actionId={actionId}
        functionAdd={handleAddCompetence}
        competencesAdded={competencesAdded}
      />
      <H4 margin="24px 0px 24px 0px">{t('global.common.skillText')}</H4>

      <SearchBar
        value={searchValue}
        onChange={(e) => {
          handleSearch(undefined, e.target.value)
          setSearchValue(e.target.value)
        }}
        key="competences_add"
        label="Buscar competencia"
        onClick={() => handleSearch(undefined, searchValue)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSearch()
          }
        }}
      />

      <GridContainer>
        <GridCompetence>
          <GridItem>
            <TitleGrid>Nombre</TitleGrid>
          </GridItem>
        </GridCompetence>
        <Divider margin="10px 0px 16px 0px" />
        <GridCompetence>
          {competences?.data?.length > 0 ? (
            competences?.data?.map(
              ({ skill, id, description }: any) =>
                skill !== 'Betesa' && (
                  <ContainerItems key={`${id}_${skill}`}>
                    <GridItem>
                      <P>{skill}</P>
                    </GridItem>
                    <GridItem>
                      <Row justify="flex-end" gap='60px'>
                        <P
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleOpenModal(id)}
                          weight={700}
                          color="#2D749C;"
                          // margin="0px 80px"
                          width='40px'
                          fontWeight={600}
                        >
                          Ver
                        </P>
                        <Button
                          onClick={() => handleAdd(skill, id, undefined, description)}
                          type="button"
                          disabled={competencesAdded?.find((object: any) => object.skill === skill)}
                        >
                          {competencesAdded?.find((object: any) => object.skill === skill) ? 'Agregada' : 'Agregar'}
                        </Button>
                      </Row>
                    </GridItem>
                  </ContainerItems>
                )
            )
          ) : (
            <p>No hay datos para mostrar</p>
          )}
        </GridCompetence>
      </GridContainer>

      {competences?.total_pages !== 0 ? (
        <PaginationDiv>
          <Stack spacing={2}>
            <Pagination
              count={competences?.total_pages}
              page={selectedPage}
              onChange={handlePaginate}
              color="primary"
            />
          </Stack>
        </PaginationDiv>
      ) : null}

      <CompetencesAddedContainer>
        <H4 margin="24px 0px 24px 0px">Competencias agregadas</H4>
        <GridCompetence>
          <GridItem>
            <TitleGrid>Nombre</TitleGrid>
          </GridItem>
        </GridCompetence>
        <Divider margin="10px 0px 16px 0px" />
        <GridCompetence>
          {competencesAdded?.map(({ skill, skill_id, description }: any) => (
            <ContainerItems key={`${skill_id}_${skill}`}>
              <GridItem>
                <P>{skill}</P>
              </GridItem>
              <GridItem>
                <Tooltip title={description} placement="top">
                  <InfoIcon
                    style={{ color: '#2BB5D4', width: '14px', height: '14px', cursor: 'pointer', marginLeft: '-10px' }}
                  />
                </Tooltip>
              </GridItem>
              <GridItem>
                <Row justify="flex-end">
                  <DeleteForeverOutlinedIcon
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setCompetencesAdded(competencesAdded.filter((object: any) => object.skill !== skill))
                    }}
                  />
                </Row>
              </GridItem>
            </ContainerItems>
          ))}
        </GridCompetence>
      </CompetencesAddedContainer>
    </>
  )
}

export default ListTable
