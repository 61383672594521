export const HEADER = [
  {
    key: 'user_name',
    name: 'Nombre de usuario',
  },
  {
    key: 'company_name',
    name: 'Nombre de empresa',
  }
]

export const ACTIONS = [
  {
    label: "Interpretar",
    url: `/interpretation/interpret?id=`,
  }
];

export const SEARCH_LABEL = 'Buscar por:'

export const TABLE_SCORES_HEADERS: any = {
  'Niñez y Juventud': 'Totales juvenil',
  'Tiempo Libre': 'Total Tiempo libre',
  'Vida Laboral': 'Totales Vida laboral',
  'Autopercepción': 'Total Auto percepción'
}

export const TABLE_SCORE_HEADER_TOTAL_SUMMARY = 'Totales Actuales'

export const INTERPRETATION_OPTIONS = [
  {
    value: 'FI',
  },
  {
    value: 'FD',
  },
  {
    value: 'BI',
  },
  {
    value: 'BD',
  },
  {
    value: 'No interpretable',
  }
]
