import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Modal from 'views/components/UI/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'views/components/UI/Buttons'
import { H4 } from 'views/components/UI/Text'
import { ModalBody, ModalContainer, ModalHeader } from '../styled'
import { Row } from 'views/components/UI/Row'
import { companyIdSelector } from 'ducks/user'
import getOneCompany from 'services/companies/getOneCompany/getOneCompany'
import { Company } from 'types/common'

interface DisclaimerModalProps {
  accepTerms: boolean
  setAccepTerms: (value: boolean) => void
}
const DisclaimerModal: FC<DisclaimerModalProps> = ({ accepTerms, setAccepTerms }) => {
  const companyId = useSelector(companyIdSelector)

  const [companyInfo, setCompanyInfo] = useState<Company>()

  useEffect(() => {
    if (companyId) {
      getOneCompany(companyId).then((res) => {
        if (res.status === 'OK') {
          setCompanyInfo(res.data)
        }
      })
    }
  }, [companyId])

  const navigate = useNavigate()

  return (
    <Modal
      hideCloseButton={true}
      show={!accepTerms}
      setShow={setAccepTerms}
      styleContent={{
        width: '80%',
        height: '400px',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(-1)
            }}
          >
            <CloseIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <H4 style={{ textAlign: 'center' }}>Descargo de responsabilidad</H4>
          <p>
            En atención a nuestra relación comercial y de servicios, se advierte la responsabilidad que tiene{' '}
            {companyInfo?.name} y sus funcionarios de registrar la información veraz de los usuarios que realizarán el
            proceso de diagnóstico, lo anterior de conformidad con lo dispuesto en la Ley 1581 de 2012 de la legislación
            que rige a Bluesite S.A.S. Por tanto, se hace obligatorio y necesario indicar el número de identidad
            correcto de cada uno de los usuarios, con el fin de ser tratado como información psicológica sensible.
          </p>
          <p>
            Desatender esta responsabilidad deriva en el incumplimiento del deber de informar de manera correcta los
            datos personales de quienes participan en el proceso y será de exclusiva responsabilidad de{' '}
            {companyInfo?.name}, debiendo declarar indemne a la sociedad Bluesite S.A.S.
          </p>
          <p>Al continuar con el proceso estarás aceptando estos términos.</p>
          <Row justifyContent="space-between" alignItems="center">
            <span style={{ color: '#2D749C', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigate(-1)}>
              Cancelar
            </span>
            <Button
              onClick={() => {
                setAccepTerms(true)
              }}
            >
              Continuar
            </Button>
          </Row>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}
export default DisclaimerModal
