import styled from 'styled-components'

export const ContainerForm = styled.div`
  width: 80%;
  height: 100%;
  background-color: '#FAFCFF';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`
