export const DEVELOPMENT_PLAN = 'development_plan'
export const ACTION_PLAN = 'action_plan'

export const DEVELOPMENT_PLAN_TABS = {
  create: DEVELOPMENT_PLAN,
  action: DEVELOPMENT_PLAN,
}

export const ACTION_TABS = {
  create: ACTION_PLAN,
  action: ACTION_PLAN,
  update: ACTION_PLAN
}


export const BACK_PAGE = -1;

export const QUADRANT_OPTIONS = [
  { value: 'Frontal Izquierdo', label: 'Frontal Izquierdo' },
  { value: 'Frontal Derecho', label: 'Frontal Derecho' },
  { value: 'Basal Izquierdo', label: 'Basal Izquierdo' },
  { value: 'Basal Derecho', label: 'Basal Derecho' },
  { value: 'Competence', label: 'Competence' },
]
export const VIEW_URL = "/company";
export const UPDATE_URL = "/companies/update";

export const HEADERS = [
  {
    key: 'name',
    name: 'Competencia',
  },
  {
    key: 'dimension',
    name: 'Dimensión',
  },
  {
    key: 'levelName',
    name: 'Nivel'
  }
];

