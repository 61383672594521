import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem 0 1.5rem 0;
  align-items: baseline;
  position: sticky;
  top: 0;
  background-color: #fafcff;
  z-index: 1;
`

export const BetesaTitle = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0;
`
