
import axios from "lib/axios";
import { CompetenceUpdate } from "views/screens/Modules/Skills/store";

export const fetchUpdateCompetence = async (id: number, data: CompetenceUpdate) => {
    const competence = await axios.put(`/skill/${id}`, data);
    return competence;
}


