import { Typography } from '@mui/material';
import styled from 'styled-components';
interface TitleProps {
    margin?: string
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    background-color: #FAFCFF;
    padding: 3.5%;
    overflow-y: scroll;
    // hide scrollbar
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Title = styled(Typography).attrs({
  variant: 'h2',
})<TitleProps>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.00735em;
    color: #303030;
    margin: ${(props) => props.margin};

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 540px) {
      font-size: 20px;
      line-height: 32px;
    }

  }
`

export const Head = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 20px;
`;
