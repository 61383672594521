import axios from 'axios'
import { IBodyData } from './../store/entitites'
import { API_URL } from '../../constants'

export const fetchGetInventoryAction = async (id: number): Promise<IBodyData> => {
  try {
    const response = await axios.get(`${API_URL}${id}`)
    return response.data.data as IBodyData
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const updateInventory = async (data: IBodyData, id: number) => {
  const { expiration_date: expirationDate, name, credits, tests_used: testsUsed } = data
  const body = {
    expiration_date: expirationDate,
    name,
    credits,
    testsUsed,
  }
  await axios
    .put(`${API_URL}${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}
