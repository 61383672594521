import { FC, useState, useEffect } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import Radio from '@mui/material/Radio'
import {
  fetchGetSillsGeneralAction,
  fetchUpdateGeneralTextAction,
  fetchCreateGeneralTextAction,
} from './services/fetch_general_skills'
import { Option } from '../Qualitative/store/entities'
import { handleFetchError, BACK_PAGE } from './constants'
import { TextArea, ActionDiv, LanguageRow, LanguageRowItem, Paragraph, SaveButton, InputGrid } from './styled'
import { useNotification } from 'lib/context/notification.context'

const SkillsGeneralTexts: FC = () => {
  const navigate = useNavigate()
  const { getWarning, getSuccess } = useNotification()
  const [textTypeOptions, setTextTypeOptions] = useState<Option[]>([])
  const [selectedTextType, setSelectedTextType] = useState('')
  const [descriptionText, setDescriptionText] = useState('')
  const [idText, setIdText] = useState('')
  const [selectedLenguage, setSelectedLenguage] = useState('Español')

  useEffect(() => {
    const fetchData = () => {
      fetchGetSillsGeneralAction()
        .then((responseGeneral) => {
          if (!responseGeneral) {
            throw new Error('Error in the request')
          }
          const textType = responseGeneral.map((item: { id: number; type_general_text: string }) => ({
            value: item.id,
            label: item.type_general_text,
          }))
          setTextTypeOptions(textType)
        })
        .catch((error) => {
          handleFetchError(error)
        })
    }
    fetchData()
  }, [])


  useEffect(() => {
    if (selectedTextType) {
      const fetchSelectedData = () => {
        fetchGetSillsGeneralAction(Number(selectedTextType))
          .then((response) => {
            if (!response) {
              throw new Error('Error in the request')
            }
            setDescriptionText(response.text)
            setIdText(response.id)
          })
          .catch((error) => {
            handleFetchError(error)
          })
      }
      fetchSelectedData()
    }
  }, [selectedTextType])

  const handleLanguageChange = (language: string) => {
    setSelectedLenguage(language)
  }

  const handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const body = {
      id: idText,
      text: descriptionText,
      typeGeneralId: selectedTextType,
    }
    let actionPromise
    let successMessage: string
    if (idText) {
      actionPromise = fetchUpdateGeneralTextAction(body)
      successMessage = 'Texto general actualizado correctamente'
    } else {
      actionPromise = fetchCreateGeneralTextAction(body)
      successMessage = 'Texto general creado correctamente'
    }
    actionPromise
      .then(() => {
        getSuccess(successMessage)
        navigate(BACK_PAGE)
      })
      .catch((error) => {
        getWarning(error)
      })
  }

  const handleCompetenciaChange = (selectedOption: any) => {
    if (selectedOption === 0) {
      setDescriptionText('')
      setSelectedTextType('')
    } else {
      setSelectedTextType(selectedOption)
    }
  }

  return (
    <MainLayout>
      <form onSubmit={handleUpdate}>
        <TitleDiv>
          <h2 style={{ fontSize: 32 }}>Generales</h2>
        </TitleDiv>
        <InputGrid>
          <Select
            label="Tipo de texto"
            options={textTypeOptions}
            value={selectedTextType}
            hideNeverChoice
            onChange={handleCompetenciaChange}
            style={{ width: 400 }}
          />
          <LanguageRow>
            <LanguageRowItem>
              <Paragraph>Idioma del texto</Paragraph>
            </LanguageRowItem>
            <LanguageRowItem>
              <Radio
                checked={selectedLenguage === 'Español'}
                disabled={true}
                onChange={() => handleLanguageChange('Español')}
              />
              <Paragraph>Español</Paragraph>
            </LanguageRowItem>
            {/* <LanguageRowItem>
              <Radio
                checked={selectedLenguage === 'Inglés'}
                disabled={true}
                onChange={() => handleLanguageChange('Inglés')}
              />
              <Paragraph>Inglés</Paragraph>
            </LanguageRowItem> */}
          </LanguageRow>
        </InputGrid>
        <TextArea
          placeholder="Texto"
          value={descriptionText || ''}
          onChange={(e) => setDescriptionText(e.target.value)}
        />
        <ActionDiv>
          <span
            style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }}
            onClick={() => {
              navigate(-1)
            }}
          >
            Cancelar
          </span>
          <SaveButton type="submit" disabled={!selectedTextType || !descriptionText} descriptionText={descriptionText}>
            Guardar
          </SaveButton>
        </ActionDiv>
      </form>
    </MainLayout>
  )
}
export default SkillsGeneralTexts
