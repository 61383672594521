import { AxiosResponse } from 'axios'
import axios from 'lib/axios'

type Email = {
  email: string
}

interface DataResponse {
  msg?: string
  status: string
  error?: any
  data: Array<{
    active: boolean
    id: number
    test_reminder: string
    status: string
  }>
}

async function sendReminderTestToUser(emails: Email[] | any, process_id: any): Promise<DataResponse> {
  const PATH = '/user/reminder'
  const requestData = { emails: emails.emails, process_id };

  try {
    const response: AxiosResponse<DataResponse> = await axios.post(PATH, requestData)
    return response.data
  } catch (error) {
    return { status: 'error', error: error, data: [] }
  }
}

export default sendReminderTestToUser
