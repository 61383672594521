import { FC, useEffect, useState, useCallback } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import { StrokedButton } from 'views/components/UI/Buttons'
import {
  ContainerRow,
  BtnGoBack,
  SubTitleRow,
  ModalContent,
  ModalButtonsContainer,
  TextCancel,
  NoDataFoundContainer,
} from './styled'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { ActionEditAndDisassociateQuestions } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { superAdminRoutes } from 'router/routes'
import Modal from 'views/components/UI/Modal'
import { H2, P } from 'views/components/UI/Text'
import DisassociateIcon from 'assets/icons/DIsassociate'
import { Button } from 'views/components/UI/Buttons'

// services
import getChapters from 'services/common/chapters/get'
import getQuestionsByFilter from 'services/common/questions/getByFilters'
import deleteQuestionSkillsById from 'services/common/questions/delete'
import { headers } from '../../constants'

const QuestionsQuestionnaire: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // local state
  const [chapterOptions, setChapterOptions] = useState<any[] | []>([])
  const [chapterSelected, setChapterSelected] = useState<number | null>(null)
  const [questionsData, setQuestionsData] = useState<any[] | []>([])
  const [totalPages, setTotalPages] = useState(1)
  const [searchParam, setSearchParam] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const [idQuestionToDissasociate, setIdQuestionToDissasociate] = useState<number | null>(null)

  const [showModal, setShowModal] = useState<boolean>(false)

  const { questionnaireName, questionnaireId } = location.state

  const getFilteredQuestions = useCallback(
    (page = 1, limit = 10) => {
      setCurrentPage(page)
      if (chapterSelected) {
        getQuestionsByFilter({ chapterId: chapterSelected, typeCategoryTestId: questionnaireId }, limit, page).then(
          ({ questions, total_pages }) => {
            setQuestionsData(questions)
            setTotalPages(total_pages)
          }
        )
      }
    },
    [chapterSelected, questionnaireId]
  )

  const fetchSearchData = useCallback(
    (searchValue: any, page = 1, limit = 10) => {
      const chapterToSelect = chapterSelected === null ? undefined : chapterSelected
      setSearchParam(searchValue)

      if (searchValue) {
        getQuestionsByFilter(
          { chapterId: chapterToSelect, typeCategoryTestId: questionnaireId },
          limit,
          page,
          searchValue
        )
          .then(({ questions, total_pages }) => {
            setQuestionsData(questions)
            setTotalPages(total_pages)
          })
      }
    },
    [chapterSelected, questionnaireId]
  )

  useEffect(() => {
    // GETTING CHAPTERS OPTIONS
    getChapters({ typeCategoryTestId: questionnaireId }).then((res: any) => {
      if (res.status === 'OK') {
        const options = res.chapters
          .filter((chapter: any) => chapter.id !== 1)
          .map((chapter: any) => {
            if (!chapterSelected && chapter.id !== 1) {
              setChapterSelected(chapter.id)
            }
            return { value: chapter.id, label: chapter.name }
          })
        setChapterOptions(options)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchParam.length <= 0) {
      getFilteredQuestions()
    }
  }, [searchParam, getFilteredQuestions])

  useEffect(() => {
    if (searchParam.length > 0) {
      fetchSearchData(searchParam)
    }
  }, [searchParam, fetchSearchData])

  // Function to dissasociate a question
  function handleDissasociateQuestion() {
    if (idQuestionToDissasociate) {
      deleteQuestionSkillsById(idQuestionToDissasociate).then((res: any) => {
        if (res.status === 'OK' || res.status === 'success') {
          setShowModal(false)
          setIdQuestionToDissasociate(null)
          window.location.reload()
        } else {
          console.error('error deleting an question', res)
        }
      })
    }
  }

  return (
    <MainLayout>
      {chapterSelected ? (
        <>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>Preguntas {questionnaireName}</h2>
          </TitleDiv>
          <div style={{ width: '50%', margin: 10 }}>
            <Select
              hideNeverChoice={true}
              label="Capítulo"
              options={chapterOptions}
              value={chapterSelected}
              onChange={(e: any) => setChapterSelected(e)}
            />
          </div>
          <SubTitleRow>
            <h4 style={{ fontSize: 20 }}>Preguntas</h4>
            <Link
              to={superAdminRoutes.CreateQuestionQuestionaire}
              state={{ questionnaireId, chapterId: chapterSelected }}
            >
              <StrokedButton>Nueva pregunta</StrokedButton>
            </Link>
          </SubTitleRow>
          <BluesiteTableComponent
            extraDataAction={{
              questionnaireId,
              questionnaireName,
              onDissasociate: (e: number) => {
                setShowModal(true)
                setIdQuestionToDissasociate(e)
              },
            }}
            data={questionsData}
            ActionComponent={ActionEditAndDisassociateQuestions}
            totalPages={totalPages}
            handlePagination={getFilteredQuestions}
            currentPage={currentPage}
            headers={headers}
            searchBar={true}
            setSearchParam={setSearchParam}
            handleSearchFunction={() => fetchSearchData(searchParam)}
            onSearch={fetchSearchData}
            searchLabel=""
          />
          <ContainerRow>
            <BtnGoBack onClick={() => navigate(-1)}>Cancelar</BtnGoBack>
            <Button onClick={() => navigate(superAdminRoutes.ChaptersQuestionaire, { state: { questionnaireId } })}>
              Guardar
            </Button>
          </ContainerRow>
        </>
      ) : (
        <NoDataFoundContainer>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>
              El cuestionario {questionnaireName} No tiene capitulos ni preguntas asociadas
            </h2>
          </TitleDiv>

          <StrokedButton
            onClick={() => navigate(superAdminRoutes.CreateChapterQuestionaire, { state: { questionnaireId } })}
          >
            Crear un capítulo
          </StrokedButton>
        </NoDataFoundContainer>
      )}
      <Modal
        setShow={setShowModal}
        show={showModal}
        children={
          <ModalContent>
            <DisassociateIcon width="80px" height="80px" />
            <H2 margin="20px 0px">¿Estás seguro de que deseas desasociar esta pregunta?</H2>
            <P>Si eliminas esta pregunta no podrás volver a acceder a ella</P>
            <ModalButtonsContainer>
              <TextCancel onClick={() => setShowModal(false)}>Cancelar</TextCancel>
              <Button type="button" onClick={handleDissasociateQuestion}>
                Desasociar
              </Button>
            </ModalButtonsContainer>
          </ModalContent>
        }
      />
    </MainLayout>
  )
}
export default QuestionsQuestionnaire
