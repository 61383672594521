
import { useNavigate } from 'react-router-dom';
import {
  TextArea,
  InputContainer,
} from './styled';
import { MainLayout } from 'views/layouts';
import { H2 } from 'views/components/UI/Text';
import TitleDiv from 'views/components/UI/TitleDiv';
import { Button } from 'views/components/UI/Buttons';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { fetchUpdateBetesaAction } from '../../../services/fetch-betesa-talents';
import { useNotification } from 'lib/context/notification.context';
import { ErrorContainer, TextError } from 'views/components/MinumRecord/styled';



const EditOneAction = ({action, handleStep}: any) => {
  const navigate = useNavigate();
  const { getError, getSuccess } = useNotification();

  const actionId = action?.data?.id
  const editTalentSchema = Yup.object().shape({
    description: Yup.string().trim().required('El campo es obligatorio'),
  })
  const initial = {
    description: action?.data?.description ? action.data.description : '',
  }
  const onSubmit = (description: string, resetForm: any) => {
   
    fetchUpdateBetesaAction({
      description_spa: description,
    }, Number(actionId))
      .then((res) => {
        if(res.status === 'OK' || res.status === 'success'){
          getSuccess('Administrador creado correctamente')
          handleStep()
          resetForm()
        }else {
          if(!res?.ok) getError(res?.message ? res?.message : 'No se pudo crear el administrador')
        }
    })
  }

  return (
    <MainLayout>
      <TitleDiv>
        <H2 style={{ fontSize: 32 }}>Editar acción de desarrollo</H2>
      </TitleDiv>
      <Formik
        validateOnChange={true}
        initialValues={initial}
        validationSchema={editTalentSchema}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values.description,resetForm )
        }} 

      >{({ errors, touched, values, handleBlur, handleSubmit, isSubmitting, setFieldValue, isValid  }) => (
        <Form id="form-edit-one-action">
          <InputContainer>
            <TextArea
              color="#1F2124" fontSize="16px"
              placeholder="Texto de la acción de desarrollo"
              value={values.description}
              name="description"
              onChange={(e: any) => {
                setFieldValue('description', e.target.value)
              }}
              onBlur={handleBlur}
            />
          </InputContainer>
          {(errors.description && touched.description) && (
              <ErrorContainer>
                <TextError>El campo es obligatorio</TextError>
              </ErrorContainer>
            )}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 35, marginTop: 40, alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }} onClick={() => { navigate(-1) }} >Cancelar</span>
            <Button
              type='submit'
              onClick={handleSubmit}
              disabled={isSubmitting || !isValid}
            >
              Guardar
            </Button>
          </div>
        </Form>
      )}
      </Formik>
    </MainLayout>
  )
}
export default EditOneAction
