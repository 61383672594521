import DisassociateIcon from 'assets/icons/DIsassociate'
import React, { FC } from 'react'
import { ContainerButtons, ModalBody, ModalContainer, LargeText, Title, Action } from './styled'
import { Button } from 'views/components/UI/Buttons'
import Modal from 'views/components/UI/Modal'
import RollBackIcon from 'assets/icons/RollBackIcon'

interface SuspendAdminPlatformModalProps {
  isClosed: boolean
  setIsClosed: (value: boolean) => void
  handleCloseClick: () => void
  isSuspendAction?: boolean
  handleButtonActionClick?: () => void
  idStatus?: number
}

const SuspendAdminPlatformModal: FC<SuspendAdminPlatformModalProps> = ({
  isClosed,
  setIsClosed,
  handleCloseClick,
  isSuspendAction,
  handleButtonActionClick = () => {},
  idStatus,
}) => {
  return (
    <Modal
      show={!isClosed}
      setShow={setIsClosed}
      hideCloseButton={true}
      styleContent={{
        width: '50%',
        height: 'auto',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
      }}
    >
      {isSuspendAction ? (
        <>
          <ModalContainer>
            <ModalBody>
              <DisassociateIcon width="82px" height="82px" />
              <Title> ¿Seguro que deseas suspender a este usuario?</Title>
              <LargeText>
                Si suspendes a este usuario no podrá ingresar a su cuenta de Bluesite. Podrás reactivarlo en cualquier
                momento y su información no se perderá.
              </LargeText>
            </ModalBody>
            <ContainerButtons>
              <Action onClick={handleCloseClick}>Cancelar</Action>
              <Button type="submit" style={{ display: 'flex', alignItems: 'center' }} onClick={handleButtonActionClick}>
                <DisassociateIcon color="#FFFFFF" />
                Suspender
              </Button>
            </ContainerButtons>
          </ModalContainer>
        </>
      ) : idStatus === 3 ? (
        <>
          <ModalContainer>
            <ModalBody>
              <Title> ¿Seguro que deseas eliminar a este usuario?</Title>
              <LargeText>El usuario deberá volverse a registrar.</LargeText>
            </ModalBody>
            <ContainerButtons>
              <Action onClick={handleCloseClick}>Cancelar</Action>
              <Button
                type="submit"
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                onClick={handleButtonActionClick}
              >
                <RollBackIcon color="#FFFFFF" />
                Eliminar
              </Button>
            </ContainerButtons>
          </ModalContainer>
        </>
      ) : (
        <>
          <ModalContainer>
            <ModalBody>
              <RollBackIcon width="82px" height="82px" />
              <Title> ¿Seguro que deseas reactivar a este usuario?</Title>
              <LargeText>El usuario podrá acceder nuevamente a su cuenta de Bluesite.</LargeText>
            </ModalBody>
            <ContainerButtons>
              <Action onClick={handleCloseClick}>Cancelar</Action>
              <Button
                type="submit"
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                onClick={handleButtonActionClick}
              >
                <RollBackIcon color="#FFFFFF" />
                Reactivar
              </Button>
            </ContainerButtons>
          </ModalContainer>
        </>
      )}
    </Modal>
  )
}

export default SuspendAdminPlatformModal
