import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputAdornment from '@mui/material/InputAdornment'
import { TextField } from '@mui/material'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import {
  Container,
  DeleteButtonContainer,
  GridContainer,
  HistoryButton,
  InputsContainer,
  MagnifyingGlass,
  SearchBox,
  SubContainer,
  TableButtonsContainer,
  Title,
} from './styled'
import { MainLayout } from 'views/layouts'
import { HistoryData, HistoryResponse } from '../Constants/types'
import { useNotification } from 'lib/context/notification.context'
import DeleteMapModal from './DeleteMapModal'
import { useNavigate } from 'react-router-dom'
import { platformAdminRoutes } from 'router/routes'
import { useTeamMap } from '../Hooks/useTeamMap'
import BackButton from '../../../../components/UI/BackButton/back-button'
import { HISTORY_HEADERS } from '../Constants'
import { debounce } from 'lodash'

const HistoryTeamMap: FC = () => {
  const { getError } = useNotification()
  const navigate = useNavigate()
  const { getHistoryTeamMap, deleteTeamMap } = useTeamMap()
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [data, setData] = useState<HistoryResponse>([] as any)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState<number | null>(null)

  const debouncedSetSearch = debounce((value) => {
    setSearch(value);
  }, 500);

  useEffect(() => {
    getHistoryTeamMap(page, search)
      .then((res) => {
        setData(res as HistoryResponse)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error al obtener los datos:', error)
        getError('Ha ocurrido un error al cargar los históricos')
      })
  }, [page, getError, getHistoryTeamMap, search])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInput(value);
    debouncedSetSearch(value);
  };

  useEffect(() => {
    return () => {
      debouncedSetSearch.cancel();
    };
  // eslint-disable-next-line
  }, []);

  const onSubmitSearch = () => {
    if (search && input !== '') {
      setSearch(input)
    }
  }

  const onResetSearch = () => {
    setInput('')
    setSearch('')
  }

  const handleSelectMap = (history: HistoryData) => {
    setSearch('')
    const mapName = history.name
    navigate(platformAdminRoutes.TeamMapDevelopment, { state: { mapName, teamMapId: history.id } })
  }

  const handleDelete = async (id: number | null) => {
    const newData = data?.data?.filter((history) => history.id !== id)
    setData((prev) => ({ ...prev, data: newData }))
    if (id) await deleteTeamMap(id)
    setShowDeleteModal(false)
  }

  const handleButtonDelete = (id: number) => {
    setIdToDelete(id)
    setShowDeleteModal(true)
  }

  const filteredHistory = data?.data?.filter((history) => history.name.toLowerCase().includes(search.toLowerCase()))

  const Button = ({ history }: { history: HistoryData }) => {
    return (
      <TableButtonsContainer>
        <DeleteButtonContainer onClick={() => handleButtonDelete(history.id)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_641_1347)">
              <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
              <path d="M4.5 5V22H19.5V5H4.5Z" stroke="#E44646" strokeWidth="1.5" strokeLinejoin="round" />
              <path d="M10 10V16.5" stroke="#E44646" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 10V16.5" stroke="#E44646" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2 5H22" stroke="#E44646" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8 5L9.6445 2H14.3885L16 5H8Z" stroke="#E44646" strokeWidth="1.5" strokeLinejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_641_1347">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </DeleteButtonContainer>
        <HistoryButton onClick={() => handleSelectMap(history)}>Ver</HistoryButton>
      </TableButtonsContainer>
    )
  }

  const dataArray = filteredHistory?.map((item) => ({
    name: item.name,
    id: item.id,
    button: <Button history={item} />,
  }))

  return (
    <MainLayout>
      <Container>
        <DeleteMapModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          onDelete={handleDelete}
          idToDelete={idToDelete}
        />
        <SubContainer>
          <Title>Histórico de Mapas Betesa</Title>
          <InputsContainer>
            <TextField
              type="text"
              value={input}
              name="history"
              onChange={handleInputChange}
              label="Buscar"
              InputProps={
                input.length
                  ? {
                      endAdornment: (
                        <InputAdornment sx={{ cursor: 'pointer' }} onClick={onResetSearch} position="end">
                          <HighlightOffIcon />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
              sx={{
                borderRadius: '8px 0 0 8px',
                boxShadow: '0px 3px 6px #00000029',
                width: '410px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '8px 0 0 8px',
                },
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSubmitSearch()
                }
              }}
            />
            <SearchBox onClick={onSubmitSearch}>
              <MagnifyingGlass src={IconMagnifying} />
            </SearchBox>
          </InputsContainer>
          <GridContainer>
            <BluesiteTableComponent
              data={dataArray}
              totalPages={data?.total_pages}
              headers={HISTORY_HEADERS}
              searchBar={false}
              handlePagination={setPage}
              currentPage={page}
              searchLabel=""
              loading={loading}
            />
            <BackButton
              text="Atrás"
              onClick={() => navigate(-1)}
              type="button"
              style={{ marginBottom: '20px', marginLeft: '-40px', fontSize: '16px' }}
            />
          </GridContainer>
        </SubContainer>
      </Container>
    </MainLayout>
  )
}

export default HistoryTeamMap
