/* eslint-disable max-len */
import React from 'react'
import { MainLayout } from 'views/layouts/'
import { Link } from 'react-router-dom'
// components
import {
  Container,
  ChartContainer,
  SubContainerChart,
  RowContainer,
  InfoUserContainer,
  InfoColumn,
  CardInfo,
  SkillsContainer,
  ReportContainer,
} from './styled'
import Radar from '../../../components/Graphics/Radar'
import { H2, H5, H4, P } from 'views/components/UI/Text'
import CheckboxLabels from 'views/components/UI/CheckboxLabels'
import { StrokedButton } from 'views/components/UI/Buttons'
import CardTypeTest from 'views/components/Cards/TestType'

import imageBox from 'assets/images/box-icon.png' // ojo para eliminar
import { useTranslation } from 'react-i18next'

const skills = [
  {
    id: 1,
    name: 'Aprendizaje rapido',
  },
  {
    id: 2,
    name: 'Innovación',
  },
  {
    id: 3,
    name: 'Flexibilidad',
  },
  {
    id: 4,
    name: 'Pensamiento critico',
  },
  {
    id: 5,
    name: 'Resolución de problemas',
  },
  {
    id: 6,
    name: 'Orientación a los datos',
  },
]

const Index = () => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <Container>
        <div>
          <H2 margin="1.5% 2.3%">{t('global.common.skillText')} - Nombre de usuario</H2>
        </div>
        <InfoUserContainer>
          <InfoColumn>
            <CardInfo>
              <H5 margin="8px 0px">Datos de la prueba</H5>
              <RowContainer margin="8px 0px">
                <P margin="0px 6px 0px 0px" weight={700}>
                  Fecha:{' '}
                </P>
                <P>12/12/2020 </P>
              </RowContainer>
              <RowContainer margin="2px 0px">
                <P weight={700} margin="0px 6px 0px 0px">
                  {' '}
                  Cuadrante dominante:{' '}
                </P>
                <P>Derecha</P>
              </RowContainer>
            </CardInfo>
            <CardInfo>
              <H5>Comentarios del plan de desarrollo</H5>
              <div style={{ height: '70%' }}>
                <P>
                  El plan de desarrollo es un documento que contiene las acciones que se deben realizar para mejorar,{' '}
                </P>
              </div>
            </CardInfo>
          </InfoColumn>
          <InfoColumn>
            <CardInfo>
              <H4>Clusteres evaluados</H4>
              <RowContainer margin="0px">
                <P margin="0px 6px 0px 0px" weight={700}>
                  Adaptación:{' '}
                </P>
                <P>5, 60 puntuación mediana </P>
              </RowContainer>
              <RowContainer margin="0px">
                <P margin="0px 6px 0px 0px" weight={700}>
                  Liderazgo:{' '}
                </P>
                <P>3, 60 puntuación mediana </P>
              </RowContainer>
              <RowContainer margin="0px 0px 10px 0px">
                <P margin="0px 6px 0px 0px" weight={700}>
                  Trabaja con otros:{' '}
                </P>
                <P>4, 60 puntuación mediana </P>
              </RowContainer>
              <P>
                Se ajusta a Nombre del perfil ideal, ya que lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
              </P>
              <div style={{ width: '50%' }}>
                <StrokedButton type="button">
                  <Link to="/super-admin/percentiles/">Ver percentiles</Link>
                </StrokedButton>
              </div>
            </CardInfo>
          </InfoColumn>
        </InfoUserContainer>
        <ReportContainer>
          <CardTypeTest link="/test-results/" title="Competencias" image={imageBox} />
          <div style={{ width: '28%', height: '100px' }}>
            <StrokedButton type="button">Enviar reporte a usuario </StrokedButton>
          </div>
        </ReportContainer>

        <ChartContainer>
          <SubContainerChart>
            <Radar userResultData={[]} userResultLabels={[]} />
          </SubContainerChart>
          <SubContainerChart>
            <SkillsContainer>
              {skills.map((skill) => (
                <CheckboxLabels checked={true} onChange={(e) => console.log(e)} key={skill.id} label={skill.name} />
              ))}
            </SkillsContainer>
          </SubContainerChart>
        </ChartContainer>
      </Container>
    </MainLayout>
  )
}

export default Index
