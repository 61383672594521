import axios from 'axios'
import { ReportData } from 'views/components/preview-generate-report/store'


interface Response  {
    status: string
    data: ReportData
}


export const DimensionScore = async (token: string, user_id:number, test_id:number) => {

  const apiTest = `${process.env.REACT_APP_MAIN_API}/competence/results/dimension_score/${user_id}/test/${test_id}`

  const config = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`,
    },
  }
    const response: Response = await axios.get(apiTest , config).then((res) => {
      return res.data
    }).catch((err) => {
      return err
    })

    return response
}
