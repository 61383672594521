import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  padding: 2% 0%;
  margin-bottom: 3%;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #1f2124;
  margin-bottom: 1rem;
  margin-top: -0.8rem;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 0.8rem;
`
