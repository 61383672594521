
import axios from 'axios'
/**
 * @description service to get questions by filters
 */

type IQuestion = {
    id: number
    text: string
    type_question: string
    type_category_test_id: number
    chapter_id: number
    dimension_id: number
    active: boolean
    skill: string
    group: string
    level_id: number
    level: string
}


interface IQuestionResponse  {
    status: string | 'success' | 'error'
    questions: IQuestion[]
    total_pages: any
}

interface IFilters {
    groupId?: number | string
    levelId?: number | string
    dimensionId?: number | string
    categoryId? : number | string
    chapterId?: number | string
    typeCategoryTestId?: number | string
    text?: string
    typeQuestion?: string 
}

const getQuestionsByFilter = async (filters: IFilters, limit:number, page:number, search?:string ) => {

  let apiUrl = `${process.env.REACT_APP_MAIN_API}/question`


if (filters) {
    if (filters.groupId) {
      apiUrl += `?group_id=${filters.groupId}`
    }

    if (filters.levelId) {
        apiUrl += `${ filters.groupId? '&' : '?' }level_id=${filters.levelId}`
    }

    if (filters.dimensionId) {
        apiUrl += `${ filters.groupId || filters.levelId ? '&' : '?' }dimension_id=${filters.dimensionId}`
    }

    if (filters.chapterId ) {
        apiUrl += `${ filters.groupId || filters.levelId || filters.dimensionId ? '&' : '?' }chapter_id=${filters.chapterId}`
    }


    if (filters.typeCategoryTestId) {
        apiUrl += `${ filters.groupId || filters.levelId || filters.dimensionId || filters.chapterId ? '&' : '?' }type_category_test_id=${filters.typeCategoryTestId}`
    }

    if (filters.text) {
        apiUrl += `${ filters.groupId || filters.levelId || filters.dimensionId || filters.chapterId || filters.typeCategoryTestId ? '&' : '?' }text=${filters.text}`
    }

    if (filters.typeQuestion) {
        apiUrl += `${ filters.groupId || filters.levelId || filters.dimensionId || filters.chapterId || filters.typeCategoryTestId || filters.text ? '&' : '?' }type_question=${filters.typeQuestion}&page=${page}&limit=${limit}`
    }

    if (filters.categoryId) {
        apiUrl += `${ filters.groupId || filters.levelId || filters.dimensionId || filters.chapterId || filters.typeCategoryTestId || filters.text || filters.typeQuestion ? '&' : '?' }category_id=${filters.categoryId}`
    }

    if (search) {
        apiUrl += `${ filters.groupId || filters.levelId || filters.dimensionId || filters.chapterId || filters.typeCategoryTestId || filters.text || filters.typeQuestion ? '&' : '?' }search=${search}`
    }

 
}
  
  const addAndOperator = apiUrl.includes('?');

  apiUrl += (addAndOperator ? '&' : '?') + `page=${page}&limit=${limit}`;
  

    const response: IQuestionResponse = await axios.get(apiUrl).then((res) => {
      return res.data
    }).catch((error) => {
      console.error('error on get all skills', error)
      return { ok: false, message: error.message };
    })
    return response
}

export default getQuestionsByFilter
