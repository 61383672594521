import { useNotification } from 'lib/context/notification.context'
import { useCallback, useState } from 'react'
import { getProcessReports } from 'services/common/reports/getProcessReport/get-process-reports-service'

interface ReportsResult {
  status: string
  data?: any
  error?: any
}

interface FilterGetReports {
  allReports?: boolean
  userIds?: Set<number> | []
}

export const useGetReports = (processId: number) => {
  const [loadingGetReports, setLoading] = useState<boolean>(false)
  const [reportsResult, setReportsResult] = useState<ReportsResult>({ status: 'waiting' })
  const { getWarning } = useNotification()

  const handleGetReports = useCallback(
    async ({ allReports, userIds }: FilterGetReports) => {
      setLoading(true)

      let body = {}

      if (userIds) {
        body = {
          all: allReports ? true : false,
          users: Array.from(userIds).map((id) => ({ id })),
          process_id: processId,
        }
      } else {
        body = {
          all: allReports ? true : false,
          process_id: processId,
        }
      }

      try {
        const response: any = await getProcessReports(body)
        getProcessReports({ ...body, for_missing_reports: true }).then((res) => {
          const countReportsWithoutLink: any = res.data
          if (countReportsWithoutLink > 0) {
            getWarning(
              `Descargando reportes disponibles. Algunos reportes no están disponibles por el momento. Te enviaremos un correo para que puedas intentarlo nuevamente.`
            )
          }
        })

        if (response.status === 'error') {
          console.error('error getting reports', response.error)
          setReportsResult({
            status: 'error',
            error: `No se pudo obtener los reportes de este proceso`,
          })
        } else {
          setReportsResult({ status: 'success', data: response })
        }
      } catch (error) {
        console.error(error)
        setReportsResult({ status: 'error', error: 'Ha ocurrido un error al obtener los reportes' })
      } finally {
        setLoading(false)
      }
    },
    [processId, getWarning]
  )

  return { loadingGetReports, reportsResult, handleGetReports }
}
