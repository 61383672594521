/* eslint-disable @typescript-eslint/no-shadow */
import { Alert, AlertColor } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import React, { useCallback, useMemo, useState } from 'react'

type ContextProps = {
  getError: (message: string) => void
  getWarning: (message: string) => void
  getSuccess: (message: string) => void
}
type NotificationProps = {
  open: boolean
  message: string
  severity: AlertColor | undefined
}
const NotificationContext = React.createContext<ContextProps | null>(null)

export const NotificationProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationProps>({ open: false, message: '', severity: undefined })

  const handleClose = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setNotification({ ...notification, open: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openNotification = useCallback((message: string, severity: AlertColor) => {
    setNotification({ open: true, message, severity })
  }, [])

  const value = useMemo(
    () => ({
      getError: (message: string) => openNotification(message, 'error'),
      getSuccess: (message: string) => openNotification(message, 'success'),
      getWarning: (message: string) => openNotification(message, 'warning'),
    }),
    [openNotification]
  )

  return (
    <NotificationContext.Provider value={value}>
      <Snackbar
        open={notification.open}
        autoHideDuration={7000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          width: '95%',
        }}
      >
        <Alert
          onClose={handleClose}
          severity={notification.severity}
          sx={{
            width: '100%',
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const context = React.useContext(NotificationContext)
  if (!context) throw new Error('No existe el contexto')
  return context
}
