import axios from 'lib/axios'
import { GET_USER, USER_DETAIL_PROCESS } from '../constants'
import { Filters, IResponse } from '../store/entities'
import { USER_ROLE_ID } from 'constants/global.constants'

export const fetchGetUsersTableAction = async (
  companyId: number,
  page_number: number,
  process?: number | number[],
  search?: string
) => {
  try {
    const body = search
      ? { companyId, processExist: true, search: search, process_id: process, role: [USER_ROLE_ID.user] }
      : { companyId, processExist: true, search: '4', process_id: process, role: [USER_ROLE_ID.user] }
    if (process === 0) delete body.process_id

    const response = await axios.post(`${GET_USER}?page=${page_number}`, body)
    return { response: response.data.users, total_pages: response.data.total_pages }
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}

export const getProcessByCompany = async (filters: Filters) => {
  let PATH = `/process`

  const queryParams = new URLSearchParams()

  if (filters.companyId) queryParams.append('company_id', filters.companyId.toString())
  if (filters.processId) queryParams.append('process_id', filters.processId.toString())
  if (typeof filters.sent === 'boolean') queryParams.append('sent', filters.sent.toString())
  if (filters.limit) queryParams.append('limit', filters.limit.toString())
  if (filters.page) queryParams.append('page', filters.page.toString())

  PATH = `${PATH}?${queryParams.toString()}`

  try {
    const response: IResponse = await axios
      .get(PATH)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.error('error on get process by company', err)
      })
    return response
  } catch (error) {
    console.error('error on get process by company', error)
  }
}

export const fetchGetUserProcessAction = async (userId: number, processId: number) => {
  try {
    const response = await axios.get(`${USER_DETAIL_PROCESS}${userId}?process_id=${processId}`)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}
