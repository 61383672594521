export const ROLE_FAMILY_SETTINGS_PATH = 'edit';
export const ROLE_FAMILY_CREATE_PATH = 'create';

export const ROLE_FAMILY_PATHS = {
  basic: 'basic',
  jobPosition: 'job-position',
  idealProfile: 'ideal-profile',
  skills: 'skills',
  diagnostic: 'diagnostic',
}

export const HEADERS = [
  {
    key: "skill",
    name: "Nombre",
  },
]
export const ADDED_HEADERS = [
  {
    key: "skill",
    name: "Nombre",
    description: 'description',
    showDescriptionTooltip: true,
  },
]

export const ROLE_FAMILY_SKILLS_FIELDS = [
  {
    fieldName: 'family_skills_data_1',
    placeholder: 'Diseña proyectos y ...',
  },
  {
    fieldName: 'family_skills_data_2',
    placeholder: 'Lidera la ...',
  },
  {
    fieldName: 'family_skills_data_3',
    placeholder: 'Agrega valor a ...',
  }
]

export type TypeCompetence = {
  skill: string;
  weight?: number;
  skill_id: string;
  description?: string;
};
export interface ActionComponentProps {
  id: number;
  skill: {
    skill: string,
    weight: number,
    level_name: string;
    description: string;
    skill_id: number;
    group_name: string;
  }
}
