import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetDataHome } from 'views/screens/Evaluated-User/store';

interface MyState {
  dataHome: GetDataHome[] | null;
}

const initialState: MyState = {
  dataHome: null,
};

const dataHomeSlice = createSlice({
  name: 'dataHome',
  initialState,
  reducers: {
    setDataHomeGeneral: (state, action: PayloadAction<GetDataHome[] | null>) => {
      state.dataHome = action.payload;
    },
  },
});

export const { setDataHomeGeneral } = dataHomeSlice.actions;

export default dataHomeSlice.reducer;