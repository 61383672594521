import { FC } from 'react'
// import { TestLayout } from 'views/layouts'
import { BtnGoBack, SaveButton, Row, GridModal, ModalContainer, ModalHeader, ModalBody } from './styled'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Modal from 'views/components/UI/Modal'
import { SendTestModal } from 'views/screens/Evaluated-User/store'

export const SendModal: FC<SendTestModal> = ({
  openModal,
  setOpenModal,
  onSubmit,
  isLoading = false,
}: SendTestModal) => {
  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '560px',
        height: '300px',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <h3 style={{ textAlign: 'center' }}>Enviar prueba</h3>
          <GridModal>
            <p>Si envías la prueba, no podrás volver a realizarla. ¿Estás seguro de que quieres enviarla?</p>
            <Row>
              <BtnGoBack
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Cancelar
              </BtnGoBack>
              <SaveButton
                onClick={() => {
                  onSubmit()
                }}
              >
                {isLoading ? 'Cargando...' : 'Enviar'}
              </SaveButton>
            </Row>
          </GridModal>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}
