import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "views/layouts";
import { Container, BottomContainer, Row, ModalCloseButtonContainer, DescriptionText, SubtitleText, ModalBottomNavigationContainer, NavigationText } from "./styled";
import { Button } from "views/components/UI/Buttons";
import { H2, P, H3 } from "views/components/UI/Text";
import Grid from "views/components/UI/BluesiteTable/bluesite-table";
import { ActionAddSkillToDevelop, ActionDeleteSkillToDevelop } from "views/components/UI/BluesiteTable/Actions/GridActions";
import { useNotification } from "lib/context/notification.context";
import Modal from "views/components/UI/Modal";
import CloseIcon from "assets/icons/CloseIcon";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
// routes
import { platformAdminRoutes } from "router/routes";
import { MOCK_COMPETENCE_LIST } from "views/screens/Platform-administrator/Mocks/mocks-platform-admin";

const HEADERS = [
  {
    key: 'name',
    name: 'Nombre',
  },
]

interface Skill {
  id: number;
  name: string;
}

// Modal component
const ModalSkills: React.FC<{
  data: any,
  actions: Skill[],
  actionDescription: string,
  onClose: () => void,
  visible: boolean
}> = ({
  actions,
  onClose,
  visible,
  data,
  actionDescription,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const ITEMS_PER_PAGE = 10;

    const displayedActions = actions.slice(currentIndex, currentIndex + ITEMS_PER_PAGE);

    const handlePrev = () => {
      if (currentIndex >= ITEMS_PER_PAGE) {
        setCurrentIndex(currentIndex - ITEMS_PER_PAGE);
      }
    };

    const handleNext = () => {
      if (currentIndex + ITEMS_PER_PAGE < actions.length) {
        setCurrentIndex(currentIndex + ITEMS_PER_PAGE);
      }
    };

    return (
      <Modal styleContent={{ width: '400px', height: '80vh', padding: '30px' }} show={visible} setShow={onClose} hideCloseButton>
        <ModalCloseButtonContainer>
          <CloseIcon onClick={onClose} />
        </ModalCloseButtonContainer>
        <H3 marginTop='-10px'>{data.name}</H3>
        <DescriptionText>{actionDescription}</DescriptionText>
        <SubtitleText>Acciones de desarrollo</SubtitleText>
        {
          displayedActions.map((action: Skill) => (
            <P margin='5px 0px'>{action.name}</P>
          ))
        }
        <ModalBottomNavigationContainer>
          <Row>
            {currentIndex !== 0 && (
              <>
                <ArrowCircleLeftOutlinedIcon onClick={handlePrev} style={{ color: '#2D749C' }} />
                <NavigationText onClick={handlePrev}>Anterior</NavigationText>
              </>
            )}
          </Row>
          <Row>
            {currentIndex + ITEMS_PER_PAGE < actions.length && (
              <>
                <NavigationText onClick={handleNext}>Siguiente</NavigationText>
                <ArrowCircleRightOutlinedIcon onClick={handleNext} style={{ color: '#2D749C' }} />
              </>
            )}
          </Row>
        </ModalBottomNavigationContainer>
      </Modal>
    );
  };


const SelectSkillsToDevelop = () => {
  const navigate = useNavigate();
  const { getSuccess, getWarning } = useNotification();

  const [skillsSelected, setSkillsSelected] = useState<any>([]);
  const [currentSkill, setCurrentSkill] = useState<any>(null);
  const [idSkillSelected, setIdSkillSelected] = useState<any>([]);
  const [showResumeSkillsAdded, setShowResumeSkillsAdded] = useState(false);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
  }, [skillsSelected])


  // HANDLERS
  const handleAddSkill = (skill: Skill) => {
    if (skillsSelected.length >= 3) {
      return;
    }
    setSkillsSelected([...skillsSelected, skill]);
    setIdSkillSelected([...idSkillSelected, skill.id]);
  };

  const onDeleteSkill = (skill: { id: number | string }) => {
    if (skillsSelected.length === 1) {
      getWarning('No hay comptencias seleccionadas, debes volver a seleccionarlos');
      setShowResumeSkillsAdded(false);
    }

    const newSkillsSelected = skillsSelected.filter((item: any) => item.id !== skill.id);
    setSkillsSelected(newSkillsSelected);
  }

  const handleSendDevelopmentPlan = () => {
    getSuccess('Plan de desarrollo enviado correctamente');
    navigate(platformAdminRoutes.DetailEvaluatedUser);
  };


  return (
    <MainLayout>
      <Container>
        {
          !showResumeSkillsAdded ? (
            <>
              <H2>Competencias a desarrollar</H2>
              <P margin='20px 0px'>Selecciona máximo tres competencias a mejorar en tu plan de desarrollo.</P>
              <Grid
                data={MOCK_COMPETENCE_LIST.data.skills}
                headers={HEADERS}
                searchBar={false}
                ActionComponent={ActionAddSkillToDevelop}
                extraDataAction={{
                  handleAddSkill,
                  skillsSelected,
                  onViewSkill: (e: any) => {
                    setCurrentSkill(e);
                    setShowModal(true);
                  }
                }}
                searchLabel=''
              />
              {
                skillsSelected.length > 0 && (
                  <>
                    <H2 style={{ marginBottom: '20px' }}>Competencias agregadas</H2>
                    <Grid
                      data={skillsSelected}
                      extraDataAction={{
                        onDeleteSkill,
                        skillsSelected,
                        onViewSkill: (e: any) => {
                          setCurrentSkill(e);
                          setShowModal(true);
                        }
                      }}
                      headers={HEADERS}
                      searchBar={false}
                      ActionComponent={ActionDeleteSkillToDevelop}
                      searchLabel=''
                      totalPages={0}
                      handlePagination={() => { }}
                      currentPage={1}
                    />
                    <BottomContainer>
                      <Button type='button' onClick={() => setShowResumeSkillsAdded(true)} >Guardar</Button>
                    </BottomContainer>
                  </>
                )
              }
            </>
          ) : (
            <>
              <H2 style={{ marginBottom: '20px' }}>Resumen de competencias agregadas</H2>
              <Grid
                data={skillsSelected}
                extraDataAction={{
                  onDeleteSkill,
                  skillsSelected,
                  onViewSkill: (e: any) => {
                    setCurrentSkill(e);
                    setShowModal(true);
                  }
                }}
                headers={HEADERS}
                searchBar={false}
                ActionComponent={ActionDeleteSkillToDevelop}
                searchLabel=''
                totalPages={0}
                handlePagination={() => { }}
                currentPage={1}
              />
              <BottomContainer>
                <Button type='button' onClick={handleSendDevelopmentPlan}>Enviar</Button>
              </BottomContainer>
            </>
          )
        }
      </Container>
      {
        showModal && (
          <Modal show={showModal} setShow={setShowModal} >
            <ModalSkills
              data={currentSkill}
              actions={currentSkill?.actions || []}
              actionDescription={currentSkill?.actionDescription || ''}
              onClose={() => setShowModal(false)}
              visible={showModal}
            />
          </Modal>

        )

      }
    </MainLayout>
  );
};


export default SelectSkillsToDevelop;
