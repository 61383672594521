import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { CircularProgress } from '@mui/material';
import { SearchButton, SearchContainer } from './styled';

import Input from 'views/components/UI/TextInput';
import SearchIcon from 'assets/icons/SearchIcon';

interface IProps {
    value: string | undefined;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    style?: React.CSSProperties;
    onClick?:(e?: any)=> void;
    onKeyDown?:(e?:any)=> void;
    isLoading?: boolean;
}

const SearchBar = (props: IProps) => {
  const { value, onClick, onChange, label, style, onKeyDown, isLoading } = props;
  
  const [searchTerm, setSearchTerm] = useState(value);

  const debouncedSearchTerm = debounce(searchTerms => {
    if (onChange) onChange({ target: { value: searchTerms, name: 'input' } } as React.ChangeEvent<HTMLInputElement>);
}, 300);

  useEffect(() => {
    debouncedSearchTerm(searchTerm);

    return () => {
      debouncedSearchTerm.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }

  return (
    <SearchContainer style={style}>
      <Input
        name='input'
        type="text"
        label={label || 'Insert label'}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        style={{
          borderRadius: '0',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          width: '100%'
        }}
      />
      <SearchButton onClick={onClick} onKeyDown={onKeyDown} type='button'>
        {isLoading ? <CircularProgress size={20}/> : <SearchIcon />}
      </SearchButton>
    </SearchContainer>
  );
}

export default SearchBar;

