import styled from 'styled-components'

interface IButtonProps {
  background?: string
}

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap:10px;
`
export const LanguageRow = styled.div`
   display: flex;
   flex-direction: row;
   gap:10px;
`
export const LanguageRowItem = styled.div`
   display: flex;
   flex-direction: row;
   gap:5px;
`
export const SelectDiv = styled.div`
   display: flex;
   flex-direction: row;
   width: 50%;
`
export const Paragraph = styled.p`
   color: #1F2124; 
   font-size:18px;
  @media (max-width: 574px)  {
    font-size: 2.5vw;
  }
`
export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`
export const TextAreaDiv = styled.div`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 220px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;
overflow: auto;
&::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2D749C;
    border-radius: 10px;
    border: 1px solid #2D749C;
    cursor: pointer;
  }

`
export const SaveButton = styled.button<IButtonProps>`
  background-color: ${(props) => props.background || '#C2C2C2'};
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
  }`