import { FC, useEffect, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Option } from './store/entities'
import { MainLayout } from 'views/layouts'
import { handleFetchError } from './constants'
import { StrokedButton } from 'views/components/UI/Buttons'
import { fetchGetSkillTableAction } from './services/fetch_skills_table'
import { SubtitleText, SearchButton, MagnifyingGlass } from './styled'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import TitleDiv from 'views/components/UI/TitleDiv'
import Select from 'views/components/UI/Select'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import { fetchGetSkillTextTypeAction, fetchGetScoreTextAction } from '../Qualitative/services/fetch_skills_texts'
import { renderLink, modifiedHeaders } from './update/update'
import { useSelector } from 'react-redux'
import { generalDataSelector } from 'ducks/auth'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'

const QualitiveText: FC = () => {
  const [tableData, setTableData] = useState([])
  const [textTypeOptions, setTextTypeOptions] = useState<Option[]>([])
  const [selectedTextType, setSelectedTextType] = useState<string>('')
  const [dimensionTypeSelect, setDimensionTypeSelect] = useState<string>('')
  const [dimensionTypeOptions, setDimensionTypeOptions] = useState([])
  const [scoreTypeSelect, setScoreTypeSelect] = useState<string>('')
  const [scoreTypeOptions, setScoreTypeOptions] = useState([])
  const [groupTypeSelect, setGroupTypeSelect] = useState<string>('')
  const [groupTypeOptions, setGroupTypeOptions] = useState([])
  const [levelSelect, setLevelSelect] = useState<string>('')
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data: generalData } = useSelector(generalDataSelector)

  const { level } = generalData

  let levelOptions = []
  if (level) {
    levelOptions = level
      .filter((item: any) => item.name !== 'Betesa' && item.name !== 'Estratégico-Táctico')
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
  }
 
  useEffect(() => {
    const fetchSkills = () => {
      if (!levelSelect) return
      const categoryId = 2
      const groupId = groupTypeSelect
      const levelId = Number(levelSelect)
      fetchGetSkillTextTypeAction({ categoryId, groupId, levelId })
        .then((responseCompetencie) => {
          if (!responseCompetencie) {
            throw new Error('Error in the request')
          }
          const textType = responseCompetencie.map((item: { id: number; skill: string }) => ({
            value: item.id,
            label: item.skill,
          }))
          setTextTypeOptions(textType)
        })
        .catch((error) => {
          handleFetchError(error)
        })
    }
    fetchSkills()
  }, [groupTypeSelect, levelSelect])

  useEffect(() => {
    const dimensionOptionsData = generalData.dimension
      .map((dimension: any) => {
        if (dimension.name === 'Betesa' || dimension.name === 'betesa') {
          return null
        }
        return {
          value: dimension.id,
          label: dimension.name,
        }
      })
      .filter((option: any) => option !== null)
    setDimensionTypeOptions(dimensionOptionsData)
  }, [generalData.dimension])

  useEffect(() => {
    const fetchDataScore = () => {
      fetchGetScoreTextAction()
        .then((response) => {
          if (!response) {
            throw new Error('Error in the request')
          }
          const filteredResponse = response.filter((item: any) => item.name !== 'Betesa')
          const textType = filteredResponse.map((item: any) => ({
            value: item.id,
            label: item.name,
          }))
          setScoreTypeOptions(textType)
        })
        .catch((error) => {
          handleFetchError(error)
        })
    }
    fetchDataScore()
  }, [])

  useEffect(() => {
    const groupOptionsData = generalData.group
      .map((group: any) => {
        if (group.id === 1 || group.name === 'Betesa') {
          return null
        }
        return {
          value: group.id,
          label: group.name,
        }
      })
      .filter((option: any) => option !== null)
    setGroupTypeOptions(groupOptionsData)
  }, [generalData.group])

  const render = (row: any) => {
    const { typeCategoryId, result, dimensionId } = row
    return renderLink(typeCategoryId, result, dimensionId)
  }

  const urlNew = `/modules/skills/texts/qualitative?new=${true}`

  const fetchData = useCallback(
    (page = 1) => {
      setCurrentPage(page)
      if (isLoading) return
      setIsLoading(true)
      fetchGetSkillTableAction(page)
        .then((response) => {
          if (!response) {
            throw new Error('Error in the request')
          }

          const formattedData = response.dataResult.map((item: any) => ({
            group: item.group_name,
            dimenssion: item.dimension_name,
            skill: item.competencie,
            result: item.score_name,
            typeCategoryId: item.type_category_test_id,
            dimensionId: item.dimension_id,
          }))

          setTotalPages(response.total_pages)
          setTableData(formattedData)
          setIsLoading(false)
        })
        .catch((error) => {
          handleFetchError(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupTypeSelect, selectedTextType, dimensionTypeSelect, scoreTypeSelect, levelSelect]
  )

  useEffect(
    () => {
      if (!groupTypeSelect && !selectedTextType && !dimensionTypeSelect && !scoreTypeSelect && !levelSelect) {
        fetchData(currentPage)
        return
      }

      const filters = {
        group: Number(groupTypeSelect),
        competence: Number(selectedTextType),
        dimension: Number(dimensionTypeSelect),
        result: Number(scoreTypeSelect),
        level: Number(levelSelect),
      }
      if (isLoading) return
      fetchGetSkillTableAction(currentPage, filters)
        .then((response) => {
          if (!response) {
            throw new Error('Error in the request')
          }

          const formattedData = response.dataResult.map((item: any) => ({
            group: item.group_name,
            dimenssion: item.dimension_name,
            skill: item.competencie,
            result: item.score_name,
            typeCategoryId: item.type_category_test_id,
            dimensionId: item.dimension_id,
          }))

          setTotalPages(response.total_pages)
          setTableData(formattedData)
        })
        .catch((error) => {
          handleFetchError(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupTypeSelect, selectedTextType, dimensionTypeSelect, scoreTypeSelect, levelSelect, triggerSearch]
  )

  const handleSearchButtonClick = () => {
    setTriggerSearch((prev) => !prev)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetFilters = () => {
    setGroupTypeSelect('')
    setLevelSelect('')
    setSelectedTextType('')
    setDimensionTypeSelect('')
    setScoreTypeSelect('')
    fetchData()
  }

  const handlePagination = (page: number) => {
    setCurrentPage(page)
    setTriggerSearch((prev) => !prev)
    if (!groupTypeSelect && !selectedTextType && !dimensionTypeSelect && !scoreTypeSelect && !levelSelect) {
      fetchData(page)
    }
  }

  return (
    <MainLayout>
      <>
        <TitleDiv>
          <h2 style={{ fontSize: 32 }}>Según resultado</h2>
        </TitleDiv>
        <SubtitleText>
          <p style={{ fontSize: 21 }}>Buscar Por...</p>
        </SubtitleText>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 35, alignItems: 'center', gap: 35 }}>
          <Select
            label="Grupo"
            options={groupTypeOptions}
            value={groupTypeSelect}
            onChange={(e) => {
              setCurrentPage(1)
              setGroupTypeSelect(e === '0' ? '' : e)
            }}
          />
          <Select
            label="Nivel"
            hideNeverChoice
            options={levelOptions}
            value={levelSelect}
            onChange={(e) => {
              setCurrentPage(1)
              setLevelSelect(e === '0' ? '' : e)
            }}
          />
          <Select
            label="Competencia"
            options={textTypeOptions}
            value={selectedTextType}
            disabled={!levelSelect}
            onChange={(e) => {
              setCurrentPage(1)
              setSelectedTextType(e === '0' ? '' : e)
            }}
          />
          <Select
            label="Dimensión"
            options={dimensionTypeOptions}
            value={dimensionTypeSelect}
            onChange={(e) => {
              setCurrentPage(1)
              setDimensionTypeSelect(e === '0' ? '' : e)
            }}
          />
          <Select
            label="Resultado"
            options={scoreTypeOptions}
            value={scoreTypeSelect}
            onChange={(e) => {
              setCurrentPage(1)
              setScoreTypeSelect(e === '0' ? '' : e)
            }}
          />
          <div>
            <HighlightOffIcon
              style={{ cursor: 'pointer', color: 'gray', marginLeft: '-10px', marginRight: '-10px' }}
              onClick={() => {
                handleResetFilters()
              }}
            />
          </div>
          <SearchButton onClick={() => handleSearchButtonClick} style={{ fontSize: 14 }}>
            <MagnifyingGlass src={IconMagnifying} />
          </SearchButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 15,
            marginTop: 0,
            alignItems: 'baseline',
          }}
        >
          <h5 style={{ fontSize: 22 }}>Textos</h5>
          <Link to={urlNew}>
            <StrokedButton>Nuevo texto</StrokedButton>
          </Link>
        </div>
        <BluesiteTableComponent
          data={tableData}
          handlePagination={handlePagination}
          currentPage={currentPage}
          totalPages={totalPages}
          headers={modifiedHeaders}
          searchBar={false}
          searchLabel="Buscar por:"
          ActionComponent={render}
        />
      </>
      <ModalLoader isLoading={isLoading} />
    </MainLayout>
  )
}
export default QualitiveText
