import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import Sad from 'views/components/assets/sad.svg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useNavigate } from 'react-router-dom'
import { Container, Button, NotFoundDiv } from './styled'
import { P } from 'views/components/UI/Text'
const Home: FC = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <div style={{ display: 'flex', flexDirection:'column', paddingTop: 10}}>
        <Container>
          <NotFoundDiv>
          <img src={Sad} alt="logo de pagina 404" />
          <h2>Pagina no encontrada</h2> 
          </NotFoundDiv>
          <P>La página a la que intentas acceder no existe. 
            Intentaremos redirigirte automáticamente a nuestra página de inicio en 10 segundos.</P>
          <P>Haz click en el siguiente botón, para ir a la página de inicio</P>
        </Container> 
        <Button onClick={()=>{navigate('/')}}><HomeOutlinedIcon /> Inicio</Button>     
      </div>
    </MainLayout>
  )
}
export default Home
