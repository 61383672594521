import { useState, useCallback, FC } from 'react'
import MainLayout from 'views/layouts/Main'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, ConfigsContainer, ButtonsContainer } from './styled'
import { H2 } from 'views/components/UI/Text'
import { Button, BackButton } from 'views/components/UI/Buttons'
import BrainQuadrant from 'views/containers/BrainQuadrant'
import Select from 'views/components/UI/Select'
import { useNotification } from 'lib/context/notification.context'
import { ALERT_LEVELS, BetesaIdealProfile } from 'types/common'
import { fetchUpdateBetesaIdealProfile } from 'views/screens/Platform-administrator/services/fetchUpdateBetesaIdealProfile'
import { UpdateIdealProfile } from '../../store'
import SaveModalConfirmation from './components/SaveModalConfirmation'
import useReportDownloaderByExcel from 'hooks/process/useGetExcelReportByProcess/use-get-excel-report-by-process'

function transformToNumberArray(input: string): number[] {
  const stringArray = input.replace(/,$/, '').split(',')
  const numberArray = stringArray.map((str) => parseInt(str, 10))
  return numberArray
}

function validateProfileValues(profileValues: any[]): boolean {
  for (const profile of profileValues) {
    if (profile.min < 10 || profile.max < 20) {
      return false
    }
  }

  return true
}

const EditIdealProfileBetesa: FC = () => {
  const navigate = useNavigate()
  const { fetchGetReportsByExcel } = useReportDownloaderByExcel()
  const { getSuccess, getWarning } = useNotification()
  const location = useLocation()
  const idealProfileBetesaState = location.state?.idealProfileBetesa
  const betesaTestId = location.state?.betesaTestId
  const priorityQuadrant = location.state?.priorityQuadrant || ('' as string)
  const processId = location.state?.processId
  const processName = location.state?.processName
  const alertLevelLabels = Object.keys(ALERT_LEVELS)
  const alertLevelOptions = Object.values(ALERT_LEVELS).map((value: number[], index) => {
    return {
      value: `${value}`,
      label: alertLevelLabels[index],
    }
  })
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const alertLevelMatch = alertLevelOptions.find((option) =>
    option.label.includes(idealProfileBetesaState[0].alert_level)
  )
  const idealProfile: BetesaIdealProfile[] = idealProfileBetesaState.map((idealProfileBetesa: any) => {
    return {
      quadrant: idealProfileBetesa.quadrant,
      priority: idealProfileBetesa.priority,
      min: idealProfileBetesa.min,
      max: idealProfileBetesa.max,
    }
  })

  const [alertLevel, setAlertLevel] = useState<number[] | string>(
    transformToNumberArray(alertLevelMatch?.value as string)
  )
  const [betesaIdealProfile, setBetesaIdealProfile] = useState<BetesaIdealProfile[]>(idealProfile)

  const handleNextStep = () => {
    if (!alertLevel) {
      getWarning('Debes seleccionar un nivel de alerta')
      return
    }

    if (!validateProfileValues(betesaIdealProfile)) {
      getWarning('El valor mínimo de cada cuadrante debe ser 10 y el máximo debe ser 20')
      return
    }
    setShowModal(true)
  }

  const handleOnClickSave = useCallback(async () => {
    setIsLoading(true)
    const alertLevelArray = typeof alertLevel === 'string' ? transformToNumberArray(alertLevel as string) : alertLevel

    const updateIdealProfile: UpdateIdealProfile = {
      alertLevelIds: alertLevelArray,
      profileValues: betesaIdealProfile,
    }
    try {
      fetchGetReportsByExcel({
        processId,
        useBetesa: true,
        useCompetence: false,
        processName,
      }).then(async () => {
        await fetchUpdateBetesaIdealProfile(betesaTestId, updateIdealProfile)
        navigate(-1)
        getSuccess('Perfil ideal para Betesa actualizado correctamente')
        setShowModal(false)
        setIsLoading(false)
      })
    } catch (error) {
      getWarning('Ocurrió un error al actualizar el perfil ideal para Betesa')
    }
  }, [
    alertLevel,
    betesaIdealProfile,
    betesaTestId,
    getWarning,
    navigate,
    getSuccess,
    fetchGetReportsByExcel,
    processId,
    processName,
  ])

  const handlerBackButtonClick = () => {
    navigate(-1)
  }

  return (
    <MainLayout>
      <Container>
        <H2>Edita el Perfil ideal para Betesa</H2>
        <ConfigsContainer>
          <div style={{ width: '30%', marginLeft: '-10px' }}>
            <Select
              label="Nivel de alerta"
              options={alertLevelOptions as any}
              value={alertLevel}
              onChange={(e) => {
                setAlertLevel(e as any)
              }}
              hideNeverChoice
            />
          </div>
        </ConfigsContainer>
        <BrainQuadrant
          defaultQuadrant={priorityQuadrant || ''}
          onChange={(e) => setBetesaIdealProfile(e)}
          quadrantData={betesaIdealProfile}
        />
        <ButtonsContainer>
          <Button
            disabled={
              !alertLevel?.length || betesaIdealProfile?.length === 0 || !validateProfileValues(betesaIdealProfile)
            }
            type="submit"
            onClick={handleNextStep}
            onDisabledClick={() => {
              if (!alertLevel?.length) {
                getWarning('Debes seleccionar un nivel de alerta')
              }
              if (betesaIdealProfile?.length === 0) {
                getWarning('Debes seleccionar un perfil ideal')
              }

              if (!validateProfileValues(betesaIdealProfile)) {
                getWarning('El valor mínimo de cada cuadrante debe ser 10 y el mínimo del máximo debe ser 20')
              }
            }}
          >
            Guardar
          </Button>
          <BackButton onClick={handlerBackButtonClick} />
        </ButtonsContainer>
        <SaveModalConfirmation
          show={showModal}
          setShow={setShowModal}
          onSave={handleOnClickSave}
          isLoading={isLoading}
        />
      </Container>
    </MainLayout>
  )
}

export default EditIdealProfileBetesa
