

import { useParams, } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { SaveButton } from './styled'
import DiagnosticInfo from '../../../components/diagnostic-info-form'
import { Form, Formik } from 'formik'
import { useNotification } from 'lib/context/notification.context'
import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { putFamilyRolFamilyCompetenceSkill } from 'services/rolFamily/fetchPutBasicInfo'
import { useSelector } from 'react-redux'
import { generalDataSelector } from 'ducks/auth'
import MinimumRecord from 'views/components/MinumRecord'
import { TypeCompetence } from '../../../constants/constants'
import BackButton from 'views/components/UI/BackButton/back-button'

const validationSchema = Yup.object().shape({
  competence_skills: Yup.array()
    .of(
      Yup.object().shape({
        skill_id: Yup.number().required("El ID de la habilidad es requerido"),
        weight: Yup.number().required("El peso de la habilidad es requerido"),
      })
    ).required("La prioridad es requerida")
})

const EditDiagnostic = ({ information, handleForm }: any) => {
  const { id }: any = useParams()
  const { getError, getSuccess } = useNotification();
  const basicInfo = useRef({
    competence_skills: [],
    level_id: 0
  })
  const [subStep, setSubStep] = useState(1)
  const [competencesAdded, setCompetencesAdded] = useState<TypeCompetence[] | []>([]);
  const { data } = useSelector(generalDataSelector);
  const { level } = data;

  const handleSubmit = async (values: any) => {
    const familyData = Object.assign({}, values)
    delete familyData.level_id;
    await putFamilyRolFamilyCompetenceSkill(familyData, id).then((res: any) => {
      if (!res.ok && res.message) getError('No se pudo guardar, verifica que los campos esten llenos')
      if (res?.status === 'OK') handleForm(0)
      getSuccess('Datos guardados correctamente')
    }).catch(() => {
      getError('No se pudo guardar, verifica que los campos esten llenos')
    })
  }

  useEffect(() => {
    const skill = information?.competence_skills.map((skillObject: any) => {
      return {
        skill: skillObject?.skill,
        weight: skillObject?.weight,
        skill_id: skillObject?.id,
        description: skillObject?.description
      }
    })
    basicInfo.current = {
      competence_skills: skill,
      level_id: level.find((element: any) => element.name === information.role_families.level)?.id
    }
    setCompetencesAdded(information?.competence_skills)
  }, [information, level])

  return (
    <MainLayout>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 35, marginTop: 50, alignItems: 'baseline' }}>
        <h2 style={{ fontSize: 32 }}>Diagnosticos sugeridos de familia de rol</h2>
      </div>
      {
        subStep === 1
          ?
          <div>
            <DiagnosticInfo props={{ competencesAdded, values: basicInfo.current }} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30, alignItems: 'center', paddingBottom: 20 }}>
              <BackButton type='button' onClick={() => handleForm(0)} text='Cancelar' />
              <SaveButton onClick={() => setSubStep(2)}>Guardar</SaveButton>
            </div>
          </div>
          :
          <Formik
            initialValues={basicInfo.current}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values, errors, touched }) => (
              <Form id="form-family-rol-settings-skill">

                <MinimumRecord props={{ competencesAdded, setFieldValue, values, errors, touched }} />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30, alignItems: 'center', paddingBottom: 20 }}>

                  <BackButton type='button' onClick={() => setSubStep(1)} text='Cancelar' />

                  <SaveButton type="submit" >Guardar</SaveButton>
                </div>

              </Form>
            )}
          </Formik>
      }
    </MainLayout>
  )
}

export default EditDiagnostic
