import axios from 'axios'
import { TOKEN } from 'constants/global.constants'

axios.defaults.baseURL = process.env.REACT_APP_MAIN_API
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem(TOKEN)

    if (token && request.headers) {
      // eslint-disable-next-line
      request.headers['Authorization'] = `Bearer ${token}`
    }

    return request
  },
  (error) => {
    window.location.href = '/login'
    return Promise.reject(error)
  }
)

export default axios
