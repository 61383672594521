import { FieldArray } from 'formik';
import {
  GridContainer,
  GridCompetence,
  GridItem, TitleGrid,
  Divider, MinimumRecordContainer,
  GridMinimumRecord
} from './styled';

import Input from 'views/components/UI/TextInput';
import { H2, P } from 'views/components/UI/Text';
import { ContainerItems } from './styled';
import { useTranslation } from 'react-i18next';

const MinimumRecord = ({ props }: any) => {
  const { t } = useTranslation()
  const { competencesAdded, setFieldValue, values } = props
  return (
    <MinimumRecordContainer>
      <H2>Perfil ideal de {t('global.common.skillText')}</H2>
      <GridContainer>
        <GridMinimumRecord>
          <GridItem>
            <TitleGrid>Nombre</TitleGrid>
          </GridItem>
          <GridItem>
            <TitleGrid>Porcentaje de puntaje mínimo</TitleGrid>
          </GridItem>
        </GridMinimumRecord>
        <Divider margin='10px 0px 16px 0px' />
      </GridContainer>
      <GridCompetence>
        <FieldArray name="competence_skills">
          {() => (
            <>
              {competencesAdded?.map((competence: any, index: any) => (
                <ContainerItems key={`${competence?.skill_id}.${index}`}>
                  <GridItem>
                    <P>{competence?.skill}</P>

                  </GridItem>
                  <GridItem height='80px'>
                    <Input
                      name={`competence_skills.${index}.weight`}
                      placeholder=""
                      type="number"
                      label='Porcentaje de puntaje mínimo'
                      onChange={(e: any) => {
                        setFieldValue(`competence_skills.${index}`, {
                          ...values.competence_skills[index],
                          skill_id: competence?.skill_id,
                          weight: e.target.value,
                        })
                      }}
                      value={values.competence_skills[index]?.weight ? values.competence_skills[index]?.weight : ''}
                    />
                  </GridItem>
                </ContainerItems>
              ))
              }
            </>
          )}
        </FieldArray>
      </ GridCompetence>

    </MinimumRecordContainer>
  )
}

export default MinimumRecord;
