import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre es requerido')
      .matches(/^(?!^\s+$)[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s']{2,}$/, 'El nombre debe tener mínimo dos caracteres y solo puede contener letras, espacios, apóstrofos y tildes.'),
    lastName: Yup.string()
      .required('El Apellido es requerido')
      .matches(/^(?!^\s+$)[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s']{2,}$/, 'El apellido debe tener mínimo dos caracteres y solo puede contener letras, espacios, apóstrofos y tildes.'),
    mail: Yup.string().email('Correo electrónico inválido').required('El correo electrónico es requerido'),
    documentType: Yup.string().required('El tipo de documento es requerido'),
    identification: Yup.string().when('documentType', {
      is: '3',
      then: Yup.string().required('El número de documento es requerido').max(16, 'El número de documento no puede tener más de 16 caracteres'),
      otherwise: Yup.string().required('El número de documento es requerido').matches(/^[0-9]+$/, 'El número de documento debe ser numérico').max(16, 'El número de documento no puede tener más de 16 caracteres'),
    }),
  });

export interface ModalAddAdminProps {
    isOpen: boolean;
    setOpenModal: (value: boolean) => void;
    onCreateSuccess: () => void;
}

export const HEADERS_ADMIN_USERS = [
  { name: 'Nombre', key: 'first_name' },
  { name: 'Apellido', key: 'last_name' },
]
