import axios from 'lib/axios'
import { TeamMapBody } from '../Entities'

export const fetchCreateNewTeamMap = async (body: TeamMapBody) => {
  try {
    const response = await axios.post('/team_map/create', body)
    return response.data
  } catch (error) {
    throw new Error('Error creating new team map')
  }
}

