import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  AddUsersButton,
  InputsContainer,
  UsersModalText,
  UsersModalTitle,
  ResetButton,
  DeleteButtonContainer,
  DeleteButtonIcon,
  DeleteButtonText,
  UsersGridContainer,
  StandardButton,
  ButtonContainer,
  ButtonDisabled,
  MagnifyingGlass,
  SearchButton,
  UsersContainer,
} from './styled'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { useNotification } from 'lib/context/notification.context'
import BackButton from '../../../../components/UI/BackButton/back-button'

import IconDeleteRed from '../../../../components/assets/iconDeleteRed.png'
import { TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { H2 } from 'views/components/UI/Text'
import { platformAdminRoutes } from 'router/routes'
import { useTeamMap } from '../Hooks/useTeamMap'
import { TeamMapUser } from '../Entities'
import { HEADERS, HEADERS_ADDED_USERS } from '../Constants'

interface IProps {
  processId: string | undefined
  manageUsers: {
    users: TeamMapUser[]
  }
  mapName: string
  teamMapId: number
  betesaMapId?: number
}

const UsersManager = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getError } = useNotification()
  const { getAllBetesaUsers, addUsersTeamMap, deleteTeamMap } = useTeamMap()
  const { manageUsers, mapName, teamMapId, betesaMapId } = location.state as IProps
  const [users, setUsers] = useState<TeamMapUser[]>([] as TeamMapUser[])
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const initialUserState = {
    document: '',
    email: '',
    name: '',
  }
  const initialUserFullNameState = {
    document: '',
    email: '',
    full_name: '',
  }
  const [inputs, setInputs] = useState(initialUserState)
  const [searchCriteria, setSearchCriteria] = useState(initialUserState)
  const [inputsAddedUsers, setInputsAddedUsers] = useState(initialUserFullNameState)
  const [searchCriteriaAddedUsers, setSearchCriteriaAddedUsers] = useState(initialUserFullNameState)
  const [addedUsers, setAddedUsers] = useState<TeamMapUser[]>([] as TeamMapUser[])
  const [addedUsersPage, setAddedUsersPage] = useState(1)
  const usersAddedPerPage = 5

  useEffect(() => {
    if (manageUsers?.users?.length <= 0) {
      return
    }

    const usersAdded = manageUsers?.users.map((user) => {
      return {
        user_test_id: user.user_test_id,
        full_name: user.full_name,
        email: user.email,
        document: user.document,
      }
    })
    setAddedUsers(usersAdded)
  }, [manageUsers])

  useEffect(() => {
    setLoading(true)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filteredCriteria = Object.fromEntries(Object.entries(searchCriteria).filter(([_, v]) => v !== ''))
    getAllBetesaUsers(page, filteredCriteria)
      .then((data) => {
        setUsers(data?.users || [])
        setTotalPages(data?.total_pages || 0)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        getError('Ha ocurrido un error al cargar los usuarios')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchCriteria])

  const handleAddUser = (user: TeamMapUser) => {
    if (!user) return

    setAddedUsers((prevState) => {
      if (!prevState) return [user]
      const userExists = prevState?.some((existingUser) => existingUser.user_test_id === user.user_test_id)
      return userExists ? prevState : [...prevState, user]
    })
  }

  const handleDeleteUser = (user: TeamMapUser) => {
    setAddedUsers((prevState) => prevState?.filter((item) => item.user_test_id !== user.user_test_id))
  }

  const Button = ({ user }: { user: TeamMapUser }) => {
    const added = addedUsers?.some((addedUser) => addedUser.user_test_id === user.user_test_id)

    return (
      <AddUsersButton disabled={added} onClick={() => handleAddUser(user)}>
        Agregar
      </AddUsersButton>
    )
  }

  const DeleteButton = ({ user }: { user: TeamMapUser }) => {
    return (
      <DeleteButtonContainer onClick={() => handleDeleteUser(user)}>
        <DeleteButtonIcon src={IconDeleteRed} />
        <DeleteButtonText>Eliminar</DeleteButtonText>
      </DeleteButtonContainer>
    )
  }

  const handleBackButtonClick = async () => {
    await deleteTeamMap(teamMapId, true)
    navigate(-1)
  }

  const dataArray = users?.map((item) => ({
    user_id: item.user_test_id,
    full_name: item.full_name,
    document: item.document,
    email: item.email,
    button: <Button user={item} />,
  }))

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleInputChangeAddedUsers = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputsAddedUsers((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearchUsers = () => {
    if (!inputs.document.trim() && !inputs.email.trim() && !inputs.name.trim()) return
    setPage(1)

    setSearchCriteria({
      document: inputs.document,
      email: inputs.email,
      name: inputs.name,
    })
  }

  const handleResetSearch = () => {
    setPage(1)
    setInputs(initialUserState)
    setSearchCriteria(initialUserState)
  }

  const handleSearchAddedUsers = () => {
    if (!inputsAddedUsers.document.trim() && !inputsAddedUsers.email.trim() && !inputsAddedUsers.full_name.trim())
      return

    setAddedUsersPage(1)

    setSearchCriteriaAddedUsers({
      document: inputsAddedUsers.document,
      email: inputsAddedUsers.email,
      full_name: inputsAddedUsers.full_name,
    })
  }

  const handleResetSearchAddedUsers = () => {
    setInputsAddedUsers(initialUserFullNameState)
    setSearchCriteriaAddedUsers(initialUserFullNameState)
  }

  const handleSaveUsers = async () => {
    const addedUsersTestIds = addedUsers?.map((user) => user.user_test_id)
    await addUsersTeamMap(addedUsersTestIds, betesaMapId ?? teamMapId)
    navigate(platformAdminRoutes.TeamMapDevelopment, {
      state: { mapName, addedUsers, teamMapId: betesaMapId ?? teamMapId },
    })
  }

  const filteredUsers = addedUsers?.filter((user) => {
    return Object.entries(searchCriteriaAddedUsers).every(([key, value]) => {
      if (!value) return true
      return (
        user[key as keyof TeamMapUser] &&
        String(user[key as keyof TeamMapUser])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    })
  })

  const indexOfLastUser = addedUsersPage * usersAddedPerPage
  const indexOfFirstUser = indexOfLastUser - usersAddedPerPage
  const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser)

  const dataUsersAdded = currentUsers?.map((user) => ({
    ...user,
    button: <DeleteButton user={user} />,
  }))

  return (
    <MainLayout>
      <UsersContainer>
        <H2>Mapa - {mapName}</H2>
        <UsersModalTitle>Administrar usuarios</UsersModalTitle>
        <InputsContainer>
          <UsersModalText style={{ marginRight: '30px' }}>Buscar por:</UsersModalText>
          <TextField
            type="number"
            value={inputs.document}
            name="document"
            onChange={handleInputChange}
            label="Número de documento"
            sx={{
              boxShadow: '0px 3px 6px #00000029',
              width: '220px',
              marginRight: '40px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchUsers()
              }
            }}
          />
          <TextField
            type="text"
            value={inputs.email}
            name="email"
            onChange={handleInputChange}
            label="Email"
            sx={{
              boxShadow: '0px 3px 6px #00000029',
              width: '220px',
              marginRight: '40px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchUsers()
              }
            }}
          />
          <TextField
            type="text"
            value={inputs.name}
            name="name"
            onChange={handleInputChange}
            label="Nombre"
            sx={{
              boxShadow: '0px 3px 6px #00000029',
              width: '220px',
              marginRight: '40px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchUsers()
              }
            }}
          />
          <SearchButton onClick={handleSearchUsers} style={{ fontSize: 14 }}>
            <MagnifyingGlass src={IconMagnifying} />
          </SearchButton>
          {searchCriteria.document || searchCriteria.email || searchCriteria.name ? (
            <ResetButton onClick={handleResetSearch}>
              <HighlightOffIcon />
            </ResetButton>
          ) : null}
        </InputsContainer>

        <UsersGridContainer>
          <BluesiteTableComponent
            data={dataArray}
            totalPages={totalPages}
            headers={HEADERS}
            searchBar={false}
            handlePagination={setPage}
            currentPage={page}
            searchLabel=""
            loading={loading}
          />
        </UsersGridContainer>
        <UsersModalTitle>Usuarios agregados</UsersModalTitle>
        <InputsContainer>
          <UsersModalText style={{ marginRight: '30px' }}>Buscar por:</UsersModalText>
          <TextField
            type="number"
            value={inputsAddedUsers.document}
            name="document"
            onChange={handleInputChangeAddedUsers}
            label="Número de documento"
            sx={{
              boxShadow: '0px 3px 6px #00000029',
              width: '220px',
              marginRight: '40px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchAddedUsers()
              }
            }}
          />
          <TextField
            type="text"
            value={inputsAddedUsers.email}
            name="email"
            onChange={handleInputChangeAddedUsers}
            label="Email"
            sx={{
              boxShadow: '0px 3px 6px #00000029',
              width: '220px',
              marginRight: '40px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchAddedUsers()
              }
            }}
          />
          <TextField
            type="text"
            value={inputsAddedUsers.full_name}
            name="full_name"
            onChange={handleInputChangeAddedUsers}
            label="Nombre"
            sx={{
              boxShadow: '0px 3px 6px #00000029',
              width: '220px',
              marginRight: '40px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchAddedUsers()
              }
            }}
          />
          <SearchButton onClick={handleSearchAddedUsers} style={{ fontSize: 14 }}>
            <MagnifyingGlass src={IconMagnifying} />
          </SearchButton>

          {inputsAddedUsers.document || inputsAddedUsers.email || inputsAddedUsers.full_name ? (
            <ResetButton onClick={handleResetSearchAddedUsers}>
              <HighlightOffIcon />
            </ResetButton>
          ) : null}
        </InputsContainer>
        <UsersGridContainer>
          <BluesiteTableComponent
            data={dataUsersAdded}
            totalPages={Math.ceil(filteredUsers?.length / usersAddedPerPage)}
            headers={HEADERS_ADDED_USERS}
            searchBar={false}
            handlePagination={setAddedUsersPage}
            currentPage={addedUsersPage}
            searchLabel=""
          />
        </UsersGridContainer>
        <ButtonContainer>
          <BackButton text="Atrás" onClick={handleBackButtonClick} type="button" style={{ fontSize: '16px' }} />
          {addedUsers?.length ? (
            <StandardButton onClick={handleSaveUsers}>{teamMapId ? 'Crear' : 'Guardar'}</StandardButton>
          ) : (
            <ButtonDisabled>{teamMapId ? 'Crear' : 'Guardar'}</ButtonDisabled>
          )}
        </ButtonContainer>
      </UsersContainer>
    </MainLayout>
  )
}

export default UsersManager
