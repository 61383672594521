import axios from 'lib/axios';

interface IBody {
  userId?: number;
  companyId: number;
  users?: number[];
}

/**
 * @description service to send report to user evaluated
 * @param token string
 * @param body IBody
 */
const sendReportToUser = async (body: IBody) => {
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/exam/send_results_email`;

  const data: any = {
    company_id: body.companyId,
  }

  if (body.userId) {
    data.user_id = body.userId;
  }

  if (body.users) {
    data.users = body.users;
  }

  try {
      const response = await axios.post(apiUrl, data);
      return response.data;
  } catch (error: any) {
      return {status: 'error', message: error.message };
  }
};

export default sendReportToUser;
