import axios from 'axios'



/**
 * @description service to find user
 * @param token
 */

type ICompanies = {
    id: number
    name: string
}


interface IFindUserResponse  {
    status: string
    data: ICompanies[]
    total_pages:number
    total_results: string
}




const getAllCompanies = async (token: string, page:number, limit: number, search?: string ) => {

    let apiUrl = `${process.env.REACT_APP_MAIN_API}/company`

    if (search) {
      apiUrl = `${apiUrl}?search=${search}&page=${page || 1}&limit=${limit || 10}`;
    } else if (page && limit) {
      apiUrl = `${apiUrl}?page=${page || 1}&limit=${limit || 10}`;
    }

//  const apiUrl = `${process.env.REACT_APP_MAIN_API}/company`


  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`,
    }, 
  }

    // post get
    const response: IFindUserResponse = await axios.get(apiUrl, config).then((res) => {
      
      return res.data
    }).catch((error) => {
      return { ok: false, message: error.message };
    })
    return response
}

export default getAllCompanies