import { FC } from 'react'
import { Link } from 'react-router-dom'
import { TextInterpretation, ContainerActions } from './styled'

interface InterpretationButtonProps {
  betesa_result_id: number,
  company_name: string,
  user_name: string,
}

const InterpretationButton: FC<InterpretationButtonProps> = ({
  betesa_result_id
}) => {

  return (
    <ContainerActions>
      <Link to={`/interpretation/detail?id=${betesa_result_id}`}>
        <TextInterpretation>
          Interpretar
        </TextInterpretation>
      </Link>
    </ContainerActions>
  )
}

export default InterpretationButton
