import styled from 'styled-components'


export const RowTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const BtnNext = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 113px;
    height: 48px;
    background: #C6016B;
    color: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
    border-radius: 8px;
    cursor: pointer;
`

export const BtnGoBack = styled.div`
    color: #2D749C;
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
`
export const GridModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-bottom:40px;
    align-items: center;
`;
export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
`;
export const ContainerActionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
`
export const UpdateTag = styled.span`
font-family: Public Sans;
font-weight: 700;
color: #2D749C;
font-size: 14px;
margin: 16px 10px 0px 10px;

`
export const UpdateDiv = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap:5px;
cursor: pointer;
svg{
  width: 24px !important;
  height: 24px !important;
  padding-top: 15px !important;
}
`
export const DeleteTag = styled.span`
font-family: Public Sans;
font-weight: 700;
color: #C6016B;
font-size: 14px;
margin: 16px 10px 0px 10px;
cursor: pointer;
`
export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    svg {
      width: 30px;
      height: 30px;
      align-self: center;
      margin-bottom: 0;
    }
`;
export const Title = styled.h3`
    margin-top: 0;
    margin-bottom: 0;
`;
export const Strong = styled.span`
    color:black;
    font-weight: bold;
    margin: 16px 10px 0px 10px;
`;
export const Row = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`;
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #C2C2C2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
    background: #C6016B;
  }`
  
  export const RoleFamilyContentData = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RoleFamilyfDataHeader = styled.div`
  display: flex;
  flex-direction: row;    
  width: 100%;
`;

export const RoleFamilyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:10px;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 0px;
  gap:10px;
`;


interface IRoleFamilyDataItemProps {
  width?: string;
}

export const RoleFamilyDataItem = styled.div<IRoleFamilyDataItemProps>`
  display: flex;
  flex-direction: column;
  width : ${props => props.width ? props.width : '100%'};
`;


export const TextWidthDotContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
`;

export const TextDot = styled.p`
  color: #797A7C;
  font-family: 'Inter', sans-serif;
  margin-left: 10px;
  margin-top: 10px;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0px 4px;
  background-color: #797A7C;
`;

export const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const NavigationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
