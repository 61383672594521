export const GET_USER = "/user"
export const GET_PROCESS = "/company/process/"
export const USER_DETAIL_PROCESS = "/company/process/user/detail/"

export const handleFetchError = (text: any) => {
  return text
}

export const BACK_PAGE = -1;

export const HEADERS = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'mail',
    name: 'Correo',
  },
  {
    key: 'name',
    name: 'Nombre',
  },
];

export const downloadHeader = {
  key: 'download',
  name: '',
};
