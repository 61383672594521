import { FC } from 'react'

import { CardTitleWithColor, CardInfoWithBorderTitle, BoldParagraph, InfoText, InfoUserWrapper, BoldParagraphRose } from './styled'
import { H5, P } from 'views/components/UI/Text'

interface UserInfoProps {
  userName: string
  age?: { years: number }
  documentTypeName: string
  document: string
  previousInterpretationInfo?: any
  isProcessDevelop: boolean
}

const UserInfo: FC<UserInfoProps> = ({
  userName,
  age = { years: 0 },
  documentTypeName,
  document,
  previousInterpretationInfo,
  isProcessDevelop
}) => {
  const hasPreviousInterpretation = previousInterpretationInfo?.hasPreviousInterpretation
  const companyPreviousInterpretation = previousInterpretationInfo?.result?.company_name
  const previousInterpretation = previousInterpretationInfo?.result?.domination
  return (
    <CardInfoWithBorderTitle>
      <CardTitleWithColor>
        <H5>Información del Candidato</H5>
      </CardTitleWithColor>
      <InfoUserWrapper>
        <P>
          <BoldParagraph>Nombre:</BoldParagraph> <InfoText>{userName}</InfoText>
        </P>
        <P>
          <BoldParagraph>Tipo de identificación:</BoldParagraph> <InfoText>{documentTypeName}</InfoText>
        </P>
        <P>
          <BoldParagraph>Número de identificación:</BoldParagraph> <InfoText>{document}</InfoText>
        </P>
        <P>
          <BoldParagraph>Edad:</BoldParagraph> <InfoText>{age?.years} años</InfoText>
        </P>
        {hasPreviousInterpretation && (
          <>
            <P><BoldParagraphRose>Primer Betesa</BoldParagraphRose></P>
            <P>
              <BoldParagraph>Dominancia:</BoldParagraph> <InfoText>{previousInterpretation}</InfoText>
            </P>
            <P>
              <BoldParagraph>Compañia:</BoldParagraph>{' '}
              <InfoText>{companyPreviousInterpretation}</InfoText>
            </P>
          </>
        )}
        <P>
          <BoldParagraph>Tipo de proceso:</BoldParagraph> <InfoText>{isProcessDevelop ? 'Desarrollo' : 'Selección'}</InfoText>
        </P>
      </InfoUserWrapper>
    </CardInfoWithBorderTitle>
  )
}

export default UserInfo
