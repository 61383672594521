import { useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { MainLayout } from 'views/layouts'
import { Link } from 'react-router-dom'
import {
  Container,
} from './styled'
import { H2, H5 } from 'views/components/UI/Text'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { useNotification } from 'lib/context/notification.context';
import { ActionEditDraftProcess, ActionViewDownloadAddUsers } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { StrokedButton } from 'views/components/UI/Buttons'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader';
import { platformAdminRoutes } from 'router/routes'
import { userSelector } from 'ducks/user'
import { HEADERS_DRAFT_PROCESSES, HEADERS_SENT_PROCESSES } from './constants'
import { useFinishProcess } from 'hooks/process/useFinishProcess/useFinishProcess'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'
import { useFetchProcesses } from 'hooks/process/useGetProcess/useGetProcess'

function MyProcesses() {
  const { getError } = useNotification();
  const navigate = useNavigate();
  const location = useLocation()
  const dataUser = useSelector((state: any) => state.user.userId)

  const { companyId } = useSelector(userSelector);
  const { listProcess: listProcessCreated, fetchProcesses: fetchProcessesCreated, loadingListProcesses: loadingListProcessesCreated } = useFetchProcesses();
  const { listProcess: listProcessDraft, fetchProcesses: fetchProcessesDraft, loadingListProcesses: loadingListProcessesDraft } = useFetchProcesses();
  const { handleFinishProcess } = useFinishProcess();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProcessesDraft({ sent: false, userId: dataUser })
    if (!location.state) {
      return
    }
    fetchProcessesCreated({ sent: true, processId: location.state })
  }, [dataUser, fetchProcessesCreated, fetchProcessesDraft, location.state])

  const onFinishProcess = useCallback(
    (processId: number) => {
      if (!loading) {
        setLoading(true)
        getProcessDetail(processId)
          .then((response: any) => {
            setLoading(false)
            handleFinishProcess(response.process)
          })
          .catch(() => {
            getError('Ha ocurrido un error al cargar este proceso')
            setLoading(false)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, getError]
  )

  function handleViewProcess(id: number) {
    navigate(platformAdminRoutes.DetailProcessDelivered, { state: { id } })
  }

  const handleAddUsers = useCallback((processId: number) => {
    navigate(platformAdminRoutes.CreateUsers, { state: { id: processId, isSendedProcess: true } })
  }, [navigate]);

  return (
    <MainLayout>
      {
        !loadingListProcessesCreated && !loading && !loadingListProcessesDraft ? (
          <Container>
            <H2>Mis procesos</H2>
            <H5 margin='16px 0px'>Procesos en borrador</H5>
            <BluesiteTable
              data={listProcessDraft}
              headers={HEADERS_DRAFT_PROCESSES}
              searchBar={false}
              searchLabel=''
              ActionComponent={ActionEditDraftProcess}
              totalPages={0}
              extraDataAction={{
                onFinishProcess
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '-20px' }}>
              <Link to={platformAdminRoutes.UnpublishedProcess}>
                <StrokedButton> Ver más </StrokedButton>
              </Link>
            </div>

            <H5 margin='20px 0px'>Procesos enviados</H5>
            <BluesiteTable
              data={listProcessCreated}
              headers={HEADERS_SENT_PROCESSES}
              searchBar={false}
              searchLabel=''
              ActionComponent={ActionViewDownloadAddUsers}
              totalPages={0} extraDataAction={{
                onAddUsers: (e: number) => handleAddUsers(e),
                onViewProcess: (e: number) => handleViewProcess(e),
                // onDownloadProcess: (e: IDownloadProcess) => handleGetReports(e)
                onDownloadProcess: () => alert('Funcion de Descargar proceso')
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '-20px' }}>
              <Link to={platformAdminRoutes.ProcessesCreated}>
                <StrokedButton>
                  Ver más
                </StrokedButton>
              </Link>
            </div>

          </Container>
        ) : (
          <ModalLoader isLoading={loading || loadingListProcessesCreated} />
        )
      }
    </MainLayout>
  )
}

export default MyProcesses
