import getUsers from "views/screens/Platform-administrator/services";

const searchUserByDocuments = async (documents: string[]) => {
  try {
    const response = await getUsers({ documentsArray: documents, statusInfo: true, limit: '1000000' });;
    if (response.status === 'OK') {
  
      return response.users;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export default searchUserByDocuments;
