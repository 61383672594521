import updateUser from 'services/common/users/updateUser/update-user-service';

interface IUpdateUserProps {
  userId: number,
  firstName: string,
  lastName: string,
  birthDate?: string,
  email?: string,
}

export const useUpdateUser = () => {
    const updateUserHandler = async (props: IUpdateUserProps): Promise<any> => {
      const bodyUpdateUser: any = {
        userId: props.userId,
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.email,
      }
  

      if (props.birthDate) {
        bodyUpdateUser.birthDate = props.birthDate
      }
  
      return updateUser(bodyUpdateUser)
    };
  
    return { updateUserHandler };
  };