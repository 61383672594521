import styled from 'styled-components'

interface Props {
  allowed?: boolean
}

export const CardInfoWithBorderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
`

export const CardTitleWithColor = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  background: #e6eef3;
  color: #212b36;
  width: 99%;
  border-radius: 10px 10px 0 0;
  padding: 3px;
  font-size: 18px;
  line-height: 22px;
`

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  & > :nth-child(5) {
    grid-column: 1 / -1;
  }

  @media (max-width: 1024px) {
    justify-content: center;
    padding: 0;
  }
`

export const WrapperContainerForm = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 40px;
  gap: 15%;
  @media (max-width: 1024px) {
    padding: 0;
    flex-direction: column;
  }
`

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5;
  align-items: center;
`

export const RadioLabel = styled.label`
  font-size: 16px;
  line-height: 22px;
  color: #797a7c;
`

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`

export const SaveButton = styled.button<Props>`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: ${(props) => (props.allowed ? '#c6016b' : '#c2c2c2')};
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`
