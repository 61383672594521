import axios from 'lib/axios'

export const fetchDeleteTeamMap = async (teamMapId: number, isForBack? : boolean) => {
  try {
    const response = await axios.delete(`/team_map/delete/${teamMapId}${isForBack ? '?isForBack=true' : ''}`)
    return response.data
  } catch (error) {
    throw new Error('Error fetching team map')
  }
}

