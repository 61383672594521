import axios from 'axios'

/**
 * @description service to login in bluesite
 * @param email 
 * @param password 
 */
const login = async (email: string, password: string) => {
  const apiUrl =`${process.env.REACT_APP_MAIN_API}/auth/login`

  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
      const dataResp =  await axios.post(
      `${apiUrl}`,
      { email: email,
        password: password
      },
      config
    ).then((response) => {
      return response
    })
    .catch((error) => {
      console.error("error on login bluesite",error)
      return { ok: false, message: error.message }
    })
    return dataResp
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export default login
