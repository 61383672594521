import React from 'react';
import { ModalContainer, ModalContent, Header, ButtonClose} from './styled';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    show: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
    styleContent?: React.CSSProperties;
    setShow: (value: boolean) => void;
    hideCloseButton?: boolean;
    onlyContent?: boolean;
}


function Modal({ show, children, style, styleContent, setShow, hideCloseButton, onlyContent}: IProps) {
    return (
      <ModalContainer style={{ display: show ? 'flex' : 'none', ...style}}>
        {
          !onlyContent? (
            <>
              <Header>
                {!hideCloseButton && (<ButtonClose onClick={() => {
                    setShow(false);
                }}>
                  <CloseIcon style={{color: '#FFFFFF'}} />
                </ButtonClose>)}
              </Header>
              <ModalContent style={styleContent}>
                {children}
              </ModalContent>
            </>
          ) :
          (
            <>
              {children}
            </>
          )
        }
      </ModalContainer>
    );
  }

export default Modal;