import { AxiosResponse } from 'axios';
import axios from 'lib/axios';
import { DetailRoleFamilyResponse } from 'types/common';

const getDetailRoleFamilyByName = async (roleFamilyName: string): Promise<DetailRoleFamilyResponse> => {
    const PATH = `${process.env.REACT_APP_MAIN_API}/role_family/details?name=${roleFamilyName}`
    try {
        const response: AxiosResponse<DetailRoleFamilyResponse> = await axios.get(PATH);
        return response.data;
    } catch (error) {
        console.error('Error getting roleFamilies in process', error);
        return { status: 'error' };
    }
}

export default getDetailRoleFamilyByName
