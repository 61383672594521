import axios from 'lib/axios'
import { SearchParams } from '../Entities';

const buildQueryParams = (page: number, search?: SearchParams): string => {
  const params = new URLSearchParams({ limit: '10', page: String(page) });
  
  if (search?.name) {
    params.append('searchName', search.name);
  }
  
  if (search?.email) {
    params.append('searchEmail', search.email);
  }
  
  if (search?.document) {
    params.append('searchDocument', search.document);
  }

  return params.toString();
}

export const fetchGetAllBetesaUsers = async (page: number, search?: SearchParams) => {
  const queryParams = buildQueryParams(page, search);
  const url = `/team_map/users/test?${queryParams}`;

  try {
    return await axios.get(url);
  } catch (error) {
    console.error("Error fetching Betesa users:", error);
    throw new Error('Error fetching Betesa users');
  }
}