export const headers = [
  {
    key: 'group_name',
    name: 'Grupo',
  },
  {
    key: 'skill',
    name: 'Competencia',
  },
  {
    key: 'level_name',
    name: 'Nivel',
  }

]