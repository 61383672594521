import _ from 'lodash'
import { InventoriesAvailables } from 'types/common'

export function isUnlimitedInventory(collection: InventoriesAvailables[], inventoryType: string) {
    const findInventorySelected = _.find(collection, {'subscriptionName': inventoryType })
    return findInventorySelected?.subscriptionId === 3
}

export const parseInventoryNameToId = (collection: InventoriesAvailables[], inventoryName: string) => {
    const ID = collection.find((item: any) => item.subscriptionName === inventoryName)?.subscriptionId
    return ID
}

export const parseInventoryIdToName = (collection: InventoriesAvailables[], inventoryId: number) => {
    const NAME = collection.find((item: any) => item.suscription_company_id === inventoryId)?.subscriptionName
    return NAME
}

export const getSubcriptionCompanyId = (collection: InventoriesAvailables[], inventoryName: string) => {
    const ID = collection.find((item: any) => item.subscriptionName === inventoryName)?.suscription_company_id
    return ID
}
  