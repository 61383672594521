import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { MainLayout } from 'views/layouts'
import { generalDataSelector } from 'ducks/auth'
import { userSelector } from 'ducks/user'
import { headers } from './constants'
import { H3 } from 'views/components/UI/Text'
import { Inventory } from 'types/common'
import getInventoryByCompany from 'services/inventory/getByCompany'
import { DEFAULT_LIMIT } from 'constants/global.constants'
import { fetchEditInventoryStatus } from 'services/inventory/updateInventory'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'

const InventoryScreen: FC = () => {
  const { data } = useSelector(generalDataSelector)
  const { companyId } = useSelector(userSelector)
  const typesSubscription = data.subscription
  const [inventoryResult, setInventoryResult] = useState<Inventory[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUpdatingInventory, setIsUpdatingInventory] = useState(false)

  dayjs.extend(utc)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const limit = DEFAULT_LIMIT
        const response = await getInventoryByCompany(companyId, currentPage, limit)
        if (!response || response.status === 'error') {
          setTotalPage(0)
          return
        }
        setTotalPage(response.total_pages)
        if (response.status === 'success') {
          const res = response.data
          await Promise.all(
            res.map(async (item: any) => {
              const formatter = new Date()
              const today = dayjs(formatter).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
              const expiration = item.expiration_date
              const updateData = {
                start_date: today,
                expiration_date: expiration,
              }

              if (item.tests_used < item.credits && today < expiration && item.state !== true) {
                const updateDataActive = {
                  ...updateData,
                  credits: item.credits,
                  tests_used: item.tests_used,
                }
                await fetchEditInventoryStatus(Number(item.id), updateDataActive)
                setIsUpdatingInventory(true)
              }
              if (today > expiration && item.state !== false) {
                await fetchEditInventoryStatus(Number(item.id), updateData)
              }
              return item
            })
          )
        }
        setIsLoading(false)
      } catch (error) {
        setTotalPage(0)
      }
    }
    fetchData()
  }, [companyId, typesSubscription, currentPage])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const limit = DEFAULT_LIMIT
        const response = await getInventoryByCompany(companyId, currentPage, limit)
        if (!response) {
          setTotalPage(0)
          return
        }
        setTotalPage(response.total_pages)
        if (response?.status === 'success') {
          const res = response.data
          const formattedData = await Promise.all(
            res.map(async (item: any) => {
              const formattedItem = {
                name: item.name,
                type: _.find(typesSubscription, { id: item.subscription_id })?.name,
                credits: parseInt(item.credits) > 900000000 ? '∞' : item.credits,
                tests_used: item.tests_used,
                tests_available:
                  parseInt(item.credits) > 900000000 ? '∞' : parseInt(item.credits) - parseInt(item.tests_used),
                start_date: dayjs.utc(item.start_date).format('DD/MM/YYYY'),
                expiration_date: dayjs.utc(item.expiration_date).format('DD/MM/YYYY'),
                state: item.state ? 'Activo' : 'Inactivo',
              }
              return formattedItem
            })
          )
          setInventoryResult(formattedData)
        }
        setIsLoading(false)
      } catch (error) {
        setTotalPage(0)
        return error
      }
    }

    if (!isUpdatingInventory) {
      fetchData()
    } else {
      setIsUpdatingInventory(false)
    }
  }, [companyId, typesSubscription, currentPage, isUpdatingInventory])

  return (
    <MainLayout>
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '40px 0px 10px 0px',
            alignItems: 'center',
          }}
        >
          <H3>Inventarios</H3>
        </div>
        <div style={{ marginBottom: 30 }}>
          {!isUpdatingInventory && (
            <BluesiteTableComponent
              data={inventoryResult}
              headers={headers}
              totalPages={totalPage}
              handlePagination={setCurrentPage}
              currentPage={currentPage}
              searchBar={false}
              searchLabel=""
            />
          )}
        </div>
      </>
      <ModalLoader isLoading={isLoading} />
    </MainLayout>
  )
}

export default InventoryScreen
