import axios, { AxiosResponse } from "axios"
import { UpdateProcessDetailsBody } from "../store"

export async function fetchIdealProfileTest(testId: number, categoryId: number): Promise<any> {
  const PATH = `/ideal_profile/${testId}/${categoryId}`
  const response: AxiosResponse<any> = await axios.get(PATH)
  return response.data
}

export async function fetchUpdateProcessDetails(processId: number, updateProcessBody: UpdateProcessDetailsBody): Promise<any> {
  const PATH = `/process/details/update/${processId}`
  const response: AxiosResponse<any> = await axios.put(PATH, updateProcessBody)
  return response.data
}