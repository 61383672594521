import styled from 'styled-components'
import { StrokedButton } from 'views/components/UI/Buttons'

export const Title = styled.h2`
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
  margin-bottom: 35;
  margin-top: 30;
  align-items: 'baseline';
  width: '100%';
  font-size: 32;
`

export const WrapperButtons = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  justify-items: flex-start;
  margin: 0;
`

export const P = styled.p`
  color: #E44646;
`
export const CreatePlatformAdminButton = styled(StrokedButton)``

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  margin: 0;
`
