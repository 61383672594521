/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, ErrorContainer, TextError } from '../../pages/edit-role-family/styled'
import BrainQuadrant from 'views/components/BrainQuadrant'
import Select from 'views/components/UI/Select'
import { FieldArray } from 'formik'


class X {
  [key: string]: any,
}
const ProfileInfoForm = ({ props }: any) => {
  const { handleChange, values, setFieldValue, alertLevelText, errors, touched } = props
  const selectOptionFilter = alertLevelText.map((p: any) => {
    const filter = new X
    filter.value = p.id;
    filter.label = p.name;
    return filter;
  });
  return (
    <Container>

      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15, marginTop: 50, alignItems: 'baseline' }}>
        <h2 style={{ fontSize: 32 }}>Perfil ideal Betesa</h2>
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 30, justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <label style={{ fontWeight: 'bold', marginBottom: 10 }} htmlFor="">Selecciona nivel de alerta</label>
          <Select
            style={{ width: '50%' }}
            label="Nivel"
            name="ideal_profile_data"
            options={selectOptionFilter}
            onChange={(e: any) => {
              handleChange('ideal_profile_data.alert_level_range')(e.toString())
            }}
            value={values?.ideal_profile_data?.alert_level_range}
          />
        </div>
      </div>
      <FieldArray name="ideal_profile_data">
        {({ insert, remove, push }) => (
          <BrainQuadrant setFieldValue={setFieldValue} values={values} />
        )}
      </FieldArray>


    </Container>

  )
}

export default ProfileInfoForm
