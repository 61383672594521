import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cobrandingLogoSelector } from 'ducks/ui'
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding'
import bluesite_black from 'assets/images/bluesiteBlack.png'
import { HeaderFirstPage, Page } from 'views/components/report/styled'
import TableResult from '../resume-table'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/es'
import {
  BetesaTitle,
  FirstPageFooter,
  InfoDate,
  InfoName,
  Title,
  TitleWrapper,
  WrapperContent,
  WrapperTitle,
} from './styled'

interface FirstPageProps {
  introduction?: any
  User?: any
  domination?: string
  AlertLevel?: string
  QuadrantGlobal?: string
  stressorsScore?: any
  emocionalStateString?: string
  positiveAdjectivesValue?: string
  negativeAdjectivesValue?: string
  answerDate: string
}

const FirstPage: FC<FirstPageProps> = ({
  introduction,
  User,
  domination,
  AlertLevel,
  QuadrantGlobal,
  stressorsScore,
  emocionalStateString,
  positiveAdjectivesValue,
  negativeAdjectivesValue,
  answerDate,
}) => {
  dayjs.extend(utc)
  const companyLogo = useSelector(cobrandingLogoSelector)
  const localeDate = dayjs.utc(answerDate).locale('es').format('DD/MM/YYYY')
  const [day, month, year] = localeDate.split('/').map(Number)
  return (
    <Page color="white">
      <HeaderFirstPage>
        <CobrandingLogo logoUrl={companyLogo || ''} style={{ maxWidth: '50%', minHeight: '100%' }} />
      </HeaderFirstPage>
      <TitleWrapper>
        <BetesaTitle>{introduction?.name_type_test}.</BetesaTitle>
      </TitleWrapper>
      <WrapperContent>
        <WrapperTitle>
          <Title>{introduction?.name}</Title>
          <InfoName>{`${User?.first_name} ${User?.last_name}`}</InfoName>
          <InfoDate>{`${day}/${month}/${year}`}</InfoDate>
        </WrapperTitle>
        <TableResult
          domination={domination}
          alertlevel={AlertLevel}
          QuadrantGlobal={QuadrantGlobal}
          Stressors={stressorsScore}
          emotionalText={emocionalStateString}
          positiveValue={positiveAdjectivesValue}
          negativeValue={negativeAdjectivesValue}
        />
      </WrapperContent>
      <FirstPageFooter>
        <img width="auto" height="12px" src={bluesite_black} alt="bluesite-redeban" />
      </FirstPageFooter>
    </Page>
  )
}

export default FirstPage
