import { AxiosResponse } from 'axios'
import axios from 'lib/axios'

interface GetReportsProcessResponse {
  status: number | string
  error?: any
  data?: string[]
}

type User = {
  id: number
}

interface Data {
  all?: boolean
  users?: User[]
  process_id?: number
  for_missing_reports?: boolean
}

export async function getProcessReports(data: Data): Promise<GetReportsProcessResponse> {
  const PATH = `/process/users/reports/`
  try {
    const response: AxiosResponse<GetReportsProcessResponse> = await axios.post(PATH, data, { responseType: data.for_missing_reports ? 'json' : 'blob' })

    if (response.status !== 200) throw new Error('Error getting reports')
    return response.data
  } catch (error) {
    console.error('Error getting reports', error)
    return { status: 'error', error: error }
  }
}

interface GetReportsProcessFilters {
  processId: number
  useBetesa: boolean
  useCompetences: boolean
}

export async function downloadSummaryReportUser({
  processId,
  useBetesa,
  useCompetences,
}: GetReportsProcessFilters): Promise<any> {
  const PATH = `/user/results_summary`

  const body = {
    process_id: processId,
    is_betesa: useBetesa,
    is_competence: useCompetences,
  }

  try {
    const response: AxiosResponse<any> = await axios.post(PATH, body, { responseType: 'arraybuffer' })
    return response.data
  } catch (error) {
    console.error('Error getting reports', error)
    return { status: 'error', error: error }
  }
}

// export async function downloadSummaryReportUser({processId, useBetesa, useCompetences}: GetReportsProcessFilters): Promise<any> {
//   const PATH = `/user/results_summary`;

//   const body = {
//     process_id: processId,
//     is_betesa: useBetesa,
//     is_competence: useCompetences,
//   };

//   try {
//     const response: AxiosResponse<any> = await axios.post(PATH, body);
//     return response.data;
//   } catch (error) {
//     console.error('Error getting reports', error);
//     return { status: 'error', error: error };
//   }
// }
