import styled from 'styled-components'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  gap:30px;
`
export const Title = styled.h2`
  font-size: 32px;
  align-self: flex-start;
`

export const NewContainerItem = styled.div`
  display:flex;
  flex-direction: column;
  gap:15px;
  margin: 20px 0px;
`

export const TextError = styled.p`
  color: red;
  font-weight: 600;
  margin: -4px 0px;
`;

export const NewContainerTittle = styled.label`
  font-weight: bold;
  margin-top: 20px;
`
export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`