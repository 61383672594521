import { createSelector } from '@reduxjs/toolkit'

// TODO: import Store type and add reducer type in Store interface
const getModules = (state: any) => state.modules

export const dataNewQuestionnaire = createSelector(
    getModules,
    (modules) => modules.betesa.dataNewQuestionnaire
)


export const dataNewChapter = createSelector(
    getModules,
    (modules) => modules.betesa.dataNewChapter
)
