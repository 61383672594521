import Modal from "views/components/UI/Modal";
import { H3, H4 } from "views/components/UI/Text";
import { StrokedButton, Button } from "views/components/UI/Buttons";

interface IModalConfirmDeleteEditedRoleFamily {
    visible: boolean;
    onAccept: () => void;
    onCancel: () => void;
}

const ModalConfirmDeleteEditedRoleFamily = (props: IModalConfirmDeleteEditedRoleFamily) => {
    const { visible, onAccept, onCancel } = props;

    return (
        <Modal 
            show={visible}
            setShow={() => {}}   
            styleContent={{padding: '2rem 2rem 0rem 2rem'}}     
            hideCloseButton    
        >
            <H4 color='red'>Si decides retroceder después de editar una familia de rol, perderás la información que hayas modificado.</H4>
            <H3  margin='32px 0px'  textAlign='center'>¿Estás seguro de que deseas retroceder?</H3>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <StrokedButton onClick={onCancel}>Cancelar</StrokedButton>
                <Button onClick={onAccept}>Aceptar</Button>
            </div>
        </Modal>
    )
}

export default ModalConfirmDeleteEditedRoleFamily;