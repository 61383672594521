import axios from 'axios'
import { IUserPerformanceBody } from 'views/screens/Platform-administrator/Analytics/constants/types'

const setUsersPerformance = async (body: IUserPerformanceBody) => {
  const usersPerformance = await axios
    .post(`/performance/create`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return usersPerformance
}

export default setUsersPerformance
