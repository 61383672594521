import styled from 'styled-components';

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 794px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
`;

export const DownloadReportOver = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  color: #000;
  z-index: 999;
`;