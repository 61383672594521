import { FC } from 'react'
import RegisterFormContainer from '../../containers/Forms/Register'
import { AuthLayout } from 'views/layouts'
import { ContainerForm } from './styled'


const Register: FC = () => {
  return (
    <AuthLayout>
        <ContainerForm>
          <RegisterFormContainer />
        </ContainerForm>
    </AuthLayout>
  )
}

export default Register
