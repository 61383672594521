import React from 'react'
import { Link } from 'react-router-dom';
import { MainLayout } from 'views/layouts'
import Checkbox from '@mui/material/Checkbox';
import { Container, Title, Paragraph} from '../../pages/StartTest/styled'
export default function index() {
  return (
    <MainLayout>
      <Container>
    <Title>Idioma de la prueba</Title>
    <div style={{ textAlign: 'justify' }}>
        <Paragraph >
        Selecciona el idioma en el que quieres realizar la prueba
        </Paragraph>
    </div>
    <div style={{ marginTop: 50, justifyContent: 'flex-start', display:'flex', flexDirection: 'row', gap:5 }}>
    <div style={{ marginTop: 0, justifyContent: 'space-between', display:'flex', flexDirection: 'row', gap:5 }}>
      <div style={{ display:'flex', flexDirection: 'row', gap:5 }}>
      <Checkbox  />
        <Paragraph>Español</Paragraph>
      </div>
      <div style={{ display:'flex', flexDirection: 'row', gap:5 }}>
      <Checkbox  />
        <Paragraph>Inglés</Paragraph>
      </div>
    </div>
    
    </div>
    <div style={{ marginTop: 50, justifyContent: 'flex-end', display:'flex'}}>
        <Link to="/test/start">
        <button style={{
                background: ' #C6016B',
                width: 162,
                height: 48,
                borderRadius: 8,
                color: '#fff',
                border: 'none',
              }}>Iniciar prueba</button>
        </Link>
    </div>
    </Container>
    </MainLayout>
  )
}
