import * as Yup from 'yup'

export const createAdminPlatformValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'El nombre debe tener máximo 50 caracteres')
    .required('El nombre es requerido'),
  lastName: Yup.string()
    .max(50, 'El apellido debe tener máximo 50 caracteres')
    .required('El apellido es requerido'),
  email: Yup.string().email('¡El correo no es válido!'),
  isSuperPlatformAdmin: Yup.boolean().required('El rol es requerido'),
})


export const updateAdminPlatformValidationSchema = Yup.object().shape({
  name: Yup.string()
  .trim() // Elimina espacios al inicio y al final
  .min(2, 'El nombre debe tener al menos 2 caracteres')
  .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, 'El nombre solo debe contener letras y espacios')
  .required('El nombre es requerido'),

  lastName: Yup.string()
  .trim()
  .min(2, 'El apellido debe tener al menos 2 caracteres')
  .matches(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/, 'El apellido solo debe contener letras y espacios')
  .required('El apellido es requerido'),

  email: Yup.string()
  .trim()
  .email('El email no es válido')
  .required('El correo es requerido'),

  documentType: Yup.string()
  .trim()
  .required('El tipo de documento es requerido'),

  document: Yup.string()
  .trim()
  .min(6, 'El documento debe tener al menos 6 caracteres')
  .matches(/^\S+$/, 'El documento no debe contener espacios')
  .required('El documento es requerido'),
});