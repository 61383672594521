import { SagaIterator } from '@redux-saga/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import {
  setError,
  setLoading,
  setStepOne,
  setStepTwo,
  setStepThree,
  setStepFour,
  setStepFive,
  setStepOneTrigger,
  setStepTwoTrigger,
  setStepThreeTrigger,
  setStepFourTrigger,
  setStepFiveTrigger,
  setStepError,
  setCleanStepsErrors,
} from 'ducks/createProcess'
import {
  BetesaIdealProfileValues,
  createProcessService,
  updateProcess,
} from 'services/common/process/createProcess/create-process-service'
import { StepTwo } from 'types/common'
import { IdealProfileBetesa } from 'services/common/process/createProcess/create-process-service'

interface DataStepFour {
  ideal_profile: {
    process_id: number | any
    competences_ideal_profile?: CompetencesIdealProfile[]
    betesa_ideal_profile?: IdealProfileBetesa
  }
}

const getStepOne = (state: any) => state.createProcess.process.stepOne
const getStepTwo = (state: any) => state.createProcess.process.stepTwo
const getStepThree = (state: any) => state.createProcess.process.stepThree
const getStepFive = (state: any) => state.createProcess.process.stepFive

function* setStepOneSagas(action: any): any {
  const processId = action.payload.processId
  const state = yield select()
  const userId = state.user.userId
  try {
    yield put(setLoading(true))
    const dataStepOne = {
      process_data: {
        type_process_id: action.payload.processType,
        name: action.payload.processName,
        participant_report: action.payload.automaticReports,
        development_plan: action.payload.permitDevelopmentPlan,
        level_id: action.payload.level,
        language: action.payload.testLanguage,
        company_id: action.payload.companyId,
        subscription_company_id: action.payload.inventoryId,
        admins: action.payload.admins,
        user_id: userId,
        integrationIdentifier: action.payload.profileId,
      },
    }

    if (processId) {
      delete dataStepOne.process_data.company_id
      delete dataStepOne.process_data.subscription_company_id
      delete dataStepOne.process_data.user_id
      delete dataStepOne.process_data.integrationIdentifier
      yield call(updateProcess, processId, dataStepOne)
      yield put(setStepOne(action.payload))
      // clean all next steps
      yield put(setStepTwo({} as any))
      yield put(setStepThree({} as any))
      yield put(setStepFour({} as any))
      yield put(setStepFive({} as any))
      yield put(setError(false))
    } else {
      const response = yield call(createProcessService, dataStepOne)
      const dataToSave: any = action.payload

      if (response.status === 'error') {
        throw new Error(response.error)
      }
      dataToSave.processId = response.process[0].id
      dataToSave.step = response.process[0].step
      dataToSave.inventoryTypeId = action.payload.inventoryId
      yield put(setStepOne(dataToSave))
      yield put(setStepThree({} as any))
      yield put(setStepFour({} as any))
      yield put(setStepFive({} as any))
      yield put(setError(false))
    }
  } catch (err: any) {
    yield put(setError(err.message))
  } finally {
    yield put(setLoading(false))
  }
}

function* setStepTwoSagas(action: PayloadAction<StepTwo>): any {
  const nameRoleFamily = action.payload?.roleFamilyName ? action.payload.roleFamilyName : undefined
  const stepTwo = yield select(getStepTwo)

  const dataStepTwo: any = {
    family_role: {
      process_id: action.payload.processId,
      role_family: action.payload.isRoleFamilySelected,
    },
  }
  if (nameRoleFamily) dataStepTwo.family_role.role_family_name = nameRoleFamily

  try {
    if (Object.keys(stepTwo).length > 0) {
      delete dataStepTwo.family_role.process_id
      yield call(updateProcess, action.payload.processId, dataStepTwo)
      yield put(setStepTwo(action.payload))
    } else {
      yield call(createProcessService, dataStepTwo)
      yield put(setStepTwo(action.payload))
    }
  } catch (err: any) {
    yield put(setError(err.message))
    yield put(setLoading(false))
  } finally {
    yield put(setLoading(false))
  }
}

interface ActionStepThree {
  onlineCourse: boolean
  processId?: number
  useBetesaTest: boolean
  useSkillsTest: boolean
}

interface DataStepThree {
  test_selection: {
    process_id?: number
    betesa_test: boolean
    competences_test: boolean
    online_course: boolean
  }
}

function* setStepThreeSagas(action: PayloadAction<ActionStepThree>): any {
  const stepThree = yield select(getStepThree)
  const dataStepThree: DataStepThree = {
    test_selection: {
      process_id: action.payload.processId as number,
      betesa_test: action.payload.useBetesaTest,
      competences_test: action.payload.useSkillsTest,
      online_course: action.payload.onlineCourse,
    },
  }

  try {
    if (Object.keys(stepThree).length > 0) {
      yield put(setLoading(true))
      if (action.payload.processId) {
        delete dataStepThree.test_selection.process_id
        yield call(updateProcess, action.payload.processId, dataStepThree)
        yield put(setStepThree(action.payload))
      }
    } else {
      yield put(setLoading(true))
      yield call(createProcessService, dataStepThree)
      yield put(setStepThree(action.payload))
    }
  } catch (err: any) {
    yield put(setError(err.message))
    yield put(setLoading(false))
  } finally {
    yield put(setLoading(false))
  }
}

interface CompetencesIdealProfile {
  skill_id: number
  weight: number
}

interface MaskBetesaIdealProfile {
  betesaSkills: number[]
  alertLevelId: number[]
  profileValues: BetesaIdealProfileValues[]
}

interface ActionStepFour {
  processId: number
  competencesIdealProfile?: CompetencesIdealProfile[]
  betesaIdealProfile?: MaskBetesaIdealProfile
  isEditingRoleFamily?: boolean
}

function* setStepFourSagas(action: PayloadAction<ActionStepFour>): any {
  yield put(setLoading(true))

  const stepOneData = yield select(getStepOne)

  const dataStepFour: DataStepFour = {
    ideal_profile: {
      process_id: action.payload.processId || stepOneData.processId,
    },
  }

  if (action.payload.betesaIdealProfile) {
    dataStepFour.ideal_profile.betesa_ideal_profile = {
      betesa_skills: action.payload.betesaIdealProfile.betesaSkills,
      alert_level_range: action.payload.betesaIdealProfile.alertLevelId,
      profile_values: action.payload.betesaIdealProfile.profileValues,
    }

    // if alertLevelId is empty imn action.payload.betesaIdealProfile
    // then delete alert_level_range from dataStepFour.ideal_profile.betesa_ideal_profile
    if (action.payload.betesaIdealProfile.alertLevelId.length === 0) {
      delete dataStepFour.ideal_profile.betesa_ideal_profile.alert_level_range
    }

    if (action.payload.betesaIdealProfile.profileValues.length === 0) {
      delete dataStepFour.ideal_profile.betesa_ideal_profile.profile_values
    }

    if (
      action.payload.betesaIdealProfile.betesaSkills.length === 0 ||
      action.payload.betesaIdealProfile.betesaSkills[0] === null ||
      action.payload.betesaIdealProfile.betesaSkills[0] === undefined
    ) {
      delete dataStepFour.ideal_profile.betesa_ideal_profile.betesa_skills
    }
  }

  if (action.payload.competencesIdealProfile) {
    dataStepFour.ideal_profile.competences_ideal_profile = action.payload.competencesIdealProfile
  }

  try {
    if (action.payload.isEditingRoleFamily) {
      delete dataStepFour.ideal_profile.process_id

      if (dataStepFour.ideal_profile.betesa_ideal_profile) {
        const dataStepFourBetesa: any = {
          ideal_profile: {
            betesa_ideal_profile: dataStepFour.ideal_profile.betesa_ideal_profile,
          },
        }
        const responseBetesa = yield call(updateProcess, action.payload.processId, dataStepFourBetesa)
        if (responseBetesa.status !== 'OK') {
          throw new Error(responseBetesa.error)
        }
      }

      if (dataStepFour.ideal_profile.competences_ideal_profile) {
        const dataStepFourCompetences: any = {
          ideal_profile: {
            competences_ideal_profile: dataStepFour.ideal_profile.competences_ideal_profile,
          },
        }
        const responseCompetences = yield call(updateProcess, action.payload.processId, dataStepFourCompetences)
        if (responseCompetences.status !== 'OK') {
          throw new Error(responseCompetences.error)
        }
      }

      const dataStepFourToSave: any = {
        ...action.payload,
        processId: stepOneData.processId,
      }

      yield put(setStepFour(dataStepFourToSave))
    } else {
      try {
        if (dataStepFour.ideal_profile.betesa_ideal_profile) {
          const dataStepFourBetesa: DataStepFour = {
            ideal_profile: {
              process_id: action.payload.processId,
              betesa_ideal_profile: dataStepFour.ideal_profile.betesa_ideal_profile,
            },
          }

          const responseCreateProcess = yield call(createProcessService, dataStepFourBetesa)

          if (responseCreateProcess.status !== 'OK') {
            throw new Error(responseCreateProcess.error)
          }

          const dataStepFourToSave: any = {
            ...action.payload,
            processId: stepOneData.processId,
          }

          yield put(setStepFour(dataStepFourToSave))
        }

        if (dataStepFour.ideal_profile.competences_ideal_profile) {
          const dataStepFourCompetences: DataStepFour = {
            ideal_profile: {
              process_id: action.payload.processId,
              competences_ideal_profile: dataStepFour.ideal_profile.competences_ideal_profile,
            },
          }

          const responseCreateProcess = yield call(createProcessService, dataStepFourCompetences)

          if (responseCreateProcess.status !== 'OK') {
            throw new Error(responseCreateProcess.error)
          }

          const dataStepFourToSave: any = {
            ...action.payload,
            processId: stepOneData.processId,
          }

          yield put(setStepFour(dataStepFourToSave))
        }
      } catch (err: any) {
        yield put(setError(err.message))
        yield put(setLoading(false))
        alert(err.message)
      }
    }
  } catch (err: any) {
    yield put(setError(err.message))
    yield put(setLoading(false))
  } finally {
    yield put(setLoading(false))
  }
}

/* eslint-disable */

interface ActionStepFive {
  users: {
    process_id: number
    betesa_test_id?: number
    competences_test_id?: number
    users_id: number[]
  }
}

function* setStepFiveSagas(action: PayloadAction<ActionStepFive>): any {
  const stepOne = yield select(getStepOne)
  const inventoryId = stepOne.inventoryTypeId
  const stepFive = yield select(getStepFive)

  const { process_id, users_id } = action.payload.users

  const usersIdsFiltered = users_id.filter((item, index) => users_id.indexOf(item) === index)

  const dataStepFive: any = {
    users: {
      users_id: usersIdsFiltered,
      subscription_company_id: inventoryId,
    },
  }

  try {
    if (Object.keys(stepFive).length > 0) {
      yield call(updateProcess, process_id, dataStepFive)
    } else {
      yield call(createProcessService, dataStepFive)
      yield put(setLoading(false))
    }
  } catch (err: any) {
    yield put(setError(err))
    yield put(setStepError({ step: 'stepFive', error: { message: err.message, error: true } }))
    yield put(setLoading(false))
  } finally {
    yield put(setCleanStepsErrors())
  }
}

export function* createProcessSagas(): SagaIterator {
  yield all([takeLatest(setStepOneTrigger.trigger, setStepOneSagas)])
  yield all([takeLatest(setStepTwoTrigger.trigger, setStepTwoSagas)])
  yield all([takeLatest(setStepThreeTrigger.trigger, setStepThreeSagas)])
  yield all([takeLatest(setStepFourTrigger.trigger, setStepFourSagas)])
  yield all([takeLatest(setStepFiveTrigger.trigger, setStepFiveSagas)])
}
