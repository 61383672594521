import { FC } from 'react'
import bluesite_black from 'assets/images/bluesiteBlack.png'
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding'
import {
  FirstPageFooter,
  HeaderFirstPage,
  InfoDate,
  InfoName,
  Page,
  PrincipalTitle,
  TitleWrapper,
  WrapperInfo,
} from 'views/components/report/styled'
import TableResult from '../../skills-resume-table'
import { cobrandingLogoSelector } from 'ducks/ui'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/es'

interface FirstPageProps {
  resume: any
  answerDate: string
}

const FirstPage: FC<FirstPageProps> = ({ resume, answerDate }) => {
  dayjs.extend(utc)
  const { t } = useTranslation()
  const cobradingLogo = useSelector(cobrandingLogoSelector)
  const localeDate = dayjs.utc(answerDate).locale('es').format('DD/MM/YYYY')
  const [day, month, year] = localeDate.split('/').map(Number)

  return (
    <Page color="white">
      <HeaderFirstPage>
        <CobrandingLogo logoUrl={cobradingLogo || ''} style={{ maxWidth: '50%', minHeight: '100%' }} />
      </HeaderFirstPage>
      <TitleWrapper>
        <PrincipalTitle>Nivel de Desarrollo de</PrincipalTitle>
        <PrincipalTitle>{t('global.common.skillText')}</PrincipalTitle>
      </TitleWrapper>
      <WrapperInfo>
        <InfoName>
          {resume?.first_name ?? ''} {resume?.last_name ?? ''}
        </InfoName>
        <InfoDate>{`${day}/${month}/${year}`}</InfoDate>
      </WrapperInfo>
      <TableResult data={resume} />
      <FirstPageFooter>
        <img width="auto" height="12px" src={bluesite_black} alt="bluesite-redeban" />
      </FirstPageFooter>
    </Page>
  )
}

export default FirstPage
