import axios from "lib/axios";
import { COMPETENCE_PATH } from "../constants/constants";
import { Competence } from "../store";

export const fetchGetCompetenceById = async (competenceId: number): Promise<Competence> => {
  try {
    const competence = await axios.get(`/${COMPETENCE_PATH}/${competenceId}`)
    return competence.data.data;
  } catch (error) {
    throw new Error('Error fetching competence by id');
  }
}
