import { useState, useEffect } from 'react'
import { MainLayout } from 'views/layouts'

import CardDevelopmentPlan from 'views/components/Cards/DevelopmentPlan'
import { DevelopmentPlanActions, GetListProcess, GetListTest } from 'views/screens/Evaluated-User/store'
import Select from 'views/components/UI/Select'
import {
  Container,
  ContainerCardsPlan,
  ContainerCardsPlanText,
  ContainerPlan,
  ContainerPlanText,
  ContainerTitleSelect,
  Divider,
  Grid,
  GridContainer,
  GridItem,
  Row,
  TextDelete,
  TextView,
  TitlePrincipal,
} from './styled'
import {
  fetchDevelopmentPlan,
  fetchDevelopmentPlanCompetences,
} from 'views/screens/Evaluated-User/services/fetch-development-plan'
import { useSelector } from 'react-redux'
import { userSelector } from 'ducks/user'
import DownloadPlan from '../../components/download-plan'
import { H1, H2, H3, H5, P } from 'views/components/UI/Text'
import {
  fetchDevelopmentPlanBetesa,
  fetchSelectedCompetenceBetesa,
  fetchFeedBack,
} from 'views/screens/Evaluated-User/services/fetch-development-plan-betesa'
import { GetCompetenciasBetesa } from 'views/screens/Evaluated-User/store/entities-development-plan-betesa'
import { Button, StrokedButton } from 'views/components/UI/Buttons'
import DeleteIcon from 'assets/icons/DeleteIcon'
import Modal from 'views/components/UI/Modal'
import { NavigationModal, ActionsContainer, ModalContent, ItemNavigation } from '../../pages/Home/styled'
import LeftWithCircleIcon from 'assets/icons/LeftWithCircleIcon'
import RightWithCircleIcon from 'assets/icons/RightWithCircleIcon'
import { GetCompetenciasSkills } from 'views/screens/Evaluated-User/store/entities-development-plan-skills'
import { fetchDevelopmentPlanSkills } from 'views/screens/Evaluated-User/services/fetch-development-plan-skills'
import { BRAIN_QUADRANTS, SELECTED_QUADRANTS } from 'constants/global.constants'
import { useNotification } from 'lib/context/notification.context'
import useCompanyLogo from 'hooks/cobranding/get-company-logo/get-company-logo-hook'
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding'
import { cobrandingLogoSelector } from 'ducks/ui'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const DevelopmentPlan = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { userName, flag, body } = location?.state || {}

  const [developmentPlanData, setDevelopmentPlanData] = useState<GetListProcess[]>(body ? [body] : [])
  const [testData, setTestData] = useState<GetListTest[]>(body?.testData)

  const [competenceDataBetesa, setCompetenceDataBetesa] = useState<GetCompetenciasBetesa[] | null>(null)
  const [addedCompetences, setAddedCompetences] = useState<GetCompetenciasBetesa[]>([])

  const [dataSkills, setDataSkills] = useState<GetCompetenciasSkills[]>([])
  const [addedCompetenciasSkills, setAddedCompetenciasSkills] = useState<GetCompetenciasSkills[]>([])
  const [selectedCompetenceForModalSkills, setSelectedCompetenceForModalSkills] =
    useState<GetCompetenciasSkills | null>(null)
  const [selectedCompetenceIndexAllSkills, setSelectedCompetenceIndexAllSkills] = useState<number | null>(null)

  const [selectedProcess, setSelectedProcess] = useState(body?.nameProcess || '')
  const [selecteActionId, setSelectedActionId] = useState<number | null>(null)
  const [dataAction, setDataAction] = useState<DevelopmentPlanActions[] | null>(null)

  const [selectedProcessId, setSelectedProcessId] = useState<number | null>(body?.processId)
  const [selectedTypePlanLabel, setSelectedTypePlanLabel] = useState<string | null>(body?.testData[0].typeLabel || '')
  const [selectedTestId, setSelectedTestId] = useState<number>(body?.testData[0].testId || 0)
  const [selectedUserTestId, setSelectedUserTestId] = useState<number>(body?.testData[0].userTestId || 0)
  const [selectedType, setSelectedType] = useState<string | null>(body?.testData[0].typeTest || '')
  const [selectedQuadrant, setSelectedQuadrant] = useState<string>('FI')
  const { getCompanyLogo } = useCompanyLogo()
  const companyLogo = useSelector(cobrandingLogoSelector)

  const [hasCalledGetLogo, setHasCalledGetLogo] = useState(false)

  const [selectedCompetenceForModal, setSelectedCompetenceForModal] = useState<GetCompetenciasBetesa | null>(null)

  const [selectedCompetenceIndexAll, setSelectedCompetenceIndexAll] = useState<number | null>(null)
  const [selectedCompetenceIndexAdded, setSelectedCompetenceIndexAdded] = useState<number | null>(null)
  const [viewAll, setViewAll] = useState<boolean>(true)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showFinalBetesaResult, setShowFinalBetesaResult] = useState<boolean>(false)
  const [showFinalSkillsResult, setShowFinalSkillsResult] = useState<boolean>(false)
  const { getWarning } = useNotification()
  const { userId } = useSelector(userSelector)

  const processOptions = developmentPlanData?.map((data) => ({
    label: data.nameProcess,
    value: data.nameProcess,
  }))

  const handleSelectChangeProcess = (selectedOption: string) => {
    setSelectedProcess(selectedOption)
    const selectedData = developmentPlanData.find((data) => data.nameProcess === selectedOption)
    if (selectedData) {
      setSelectedProcessId(selectedData.processId)
      setTestData(selectedData.testData)
    }

    setSelectedTypePlanLabel(null)
  }

  const handleTypePlanSelect = (label: 'betesa' | 'skills') => {
    setSelectedTypePlanLabel(label)

    if (label === 'betesa') {
      setShowFinalSkillsResult(false)
    } else if (label === 'skills') {
      setShowFinalBetesaResult(false)
    }

    const selectedTest = testData.find((test) => {
      if (test.typeTest === 'Betesa Premium' && label === 'betesa') return true
      if (test.typeTest === 'Competencias' && label === 'skills') return true

      return false
    })

    if (selectedTest) {
      setSelectedTestId(selectedTest.testId)
      setSelectedUserTestId(selectedTest.userTestId)
      setSelectedType(selectedTest.typeTest)
    }
  }

  const handleQuadrantChange = (selectedQuadrantLabel: string) => {
    const newQuadrant = BRAIN_QUADRANTS.find((quad) => quad.label === selectedQuadrantLabel)
    setSelectedQuadrant(newQuadrant ? newQuadrant.label : 'FI')
    setAddedCompetences([])
  }

  const mappedBrainQuadrants = BRAIN_QUADRANTS.filter((quad) => SELECTED_QUADRANTS.includes(quad.value)).map((quad) => {
    const quadrantLabel = quad.label
    return { label: quadrantLabel, value: quadrantLabel }
  })

  const openModal = () => {
    setShowModal(true)
  }

  const handleNext = () => {
    if (viewAll) {
      if (
        selectedCompetenceIndexAll !== null &&
        competenceDataBetesa &&
        selectedCompetenceIndexAll < competenceDataBetesa.length - 1
      ) {
        const nextIndex = selectedCompetenceIndexAll + 1
        const nextCompetence = competenceDataBetesa[nextIndex]
        setSelectedCompetenceForModal(nextCompetence)
        setSelectedCompetenceIndexAll(nextIndex)
        setSelectedActionId(nextCompetence.id)
      }
    } else {
      if (selectedCompetenceIndexAdded !== null && selectedCompetenceIndexAdded < addedCompetences.length - 1) {
        const nextIndex = selectedCompetenceIndexAdded + 1
        const nextCompetence = addedCompetences[nextIndex]
        setSelectedCompetenceForModal(nextCompetence)
        setSelectedCompetenceIndexAdded(nextIndex)
        setSelectedActionId(nextCompetence.id)
      }
    }
  }

  const handlePrevious = () => {
    if (viewAll) {
      if (selectedCompetenceIndexAll !== null && competenceDataBetesa && selectedCompetenceIndexAll > 0) {
        const prevIndex = selectedCompetenceIndexAll - 1
        const prevCompetence = competenceDataBetesa[prevIndex]
        setSelectedCompetenceForModal(prevCompetence)
        setSelectedCompetenceIndexAll(prevIndex)
        setSelectedActionId(prevCompetence.id)
      }
    } else {
      if (selectedCompetenceIndexAdded !== null && selectedCompetenceIndexAdded > 0) {
        const prevIndex = selectedCompetenceIndexAdded - 1
        const prevCompetence = addedCompetences[prevIndex]
        setSelectedCompetenceForModal(prevCompetence)
        setSelectedCompetenceIndexAdded(prevIndex)
        setSelectedActionId(prevCompetence.id)
      }
    }
  }
  const handleAddCompetenceBetesa = (selectedCompetence: GetCompetenciasBetesa) => {
    if (!addedCompetences.find((competence) => competence.id === selectedCompetence.id)) {
      setAddedCompetences([...addedCompetences, selectedCompetence])
    }
  }

  const handleDeleteCompetenceBetesa = (competenceToRemove: GetCompetenciasBetesa) => {
    setAddedCompetences(addedCompetences.filter((competence) => competence.id !== competenceToRemove.id))
  }

  const displayAddedCompetences = () => {
    return addedCompetences.map((competence, index) => (
      <Grid key={index}>
        <GridItem>
          <P>{competence.name_spa}</P>
        </GridItem>
        <GridItem>
          <TextView
            onClick={() => {
              setSelectedCompetenceForModal(competence)
              setSelectedCompetenceIndexAdded(addedCompetences.indexOf(competence))
              setViewAll(false)
              openModal()
              setSelectedActionId(competence.id)
            }}
          >
            Ver
          </TextView>
        </GridItem>
        <GridItem
          style={{ cursor: 'pointer' }}
          width="auto"
          justifyContent="flex-end"
          onClick={() => handleDeleteCompetenceBetesa(competence)}
        >
          <DeleteIcon />
          <TextDelete style={{ marginLeft: '8px' }}>Eliminar</TextDelete>
        </GridItem>
      </Grid>
    ))
  }

  const saveAddedCompetences = () => {
    const addedCompetenciasSBetesaIds = addedCompetences.map((competence) => competence.id)
    const dataToSend = {
      user_test_id: selectedUserTestId,
      development_plan: addedCompetenciasSBetesaIds,
    }

    fetchSelectedCompetenceBetesa(dataToSend)
      .then(() => {
        if (flag) {
          navigate(-1)
          return
        }
        setShowFinalBetesaResult(true)
      })
      .catch((error) => {
        setShowFinalBetesaResult(false)
        console.error(error)
      })
  }

  const handleAddCompetenciaSkills = (selectedCompetence: GetCompetenciasSkills) => {
    if (!addedCompetenciasSkills.find((competence) => competence.id === selectedCompetence.id)) {
      setAddedCompetenciasSkills([...addedCompetenciasSkills, selectedCompetence])
    }
  }

  const handleDeleteCompetenciaSkills = (competenceToRemove: GetCompetenciasSkills) => {
    setAddedCompetenciasSkills(addedCompetenciasSkills.filter((competence) => competence.id !== competenceToRemove.id))
  }

  const handleNextSkills = () => {
    if (viewAll) {
      if (
        selectedCompetenceIndexAllSkills !== null &&
        dataSkills &&
        selectedCompetenceIndexAllSkills < dataSkills.length - 1
      ) {
        const nextIndex = selectedCompetenceIndexAllSkills + 1
        const nextCompetence = dataSkills[nextIndex]
        setSelectedCompetenceForModalSkills(nextCompetence)
        setSelectedCompetenceIndexAllSkills(nextIndex)
        setSelectedActionId(nextCompetence.id)
      }
    } else {
      if (
        selectedCompetenceIndexAdded !== null &&
        addedCompetenciasSkills &&
        selectedCompetenceIndexAdded < addedCompetenciasSkills.length - 1
      ) {
        const nextIndex = selectedCompetenceIndexAdded + 1
        const nextCompetence = addedCompetenciasSkills[nextIndex]
        setSelectedCompetenceForModalSkills(nextCompetence)
        setSelectedCompetenceIndexAdded(nextIndex)
        setSelectedActionId(nextCompetence.id)
      }
    }
  }

  const handlePreviousSkills = () => {
    if (viewAll) {
      if (selectedCompetenceIndexAllSkills !== null && selectedCompetenceIndexAllSkills > 0) {
        const prevIndex = selectedCompetenceIndexAllSkills - 1
        const prevCompetence = dataSkills[prevIndex]
        setSelectedCompetenceForModalSkills(prevCompetence)
        setSelectedCompetenceIndexAllSkills(prevIndex)
        setSelectedActionId(prevCompetence.id)
      }
    } else {
      if (selectedCompetenceIndexAdded !== null && selectedCompetenceIndexAdded > 0) {
        const prevIndex = selectedCompetenceIndexAdded - 1
        const prevCompetence = addedCompetenciasSkills[prevIndex]
        setSelectedCompetenceForModalSkills(prevCompetence)
        setSelectedCompetenceIndexAdded(prevIndex)
        setSelectedActionId(prevCompetence.id)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if ((!hasCalledGetLogo && selectedProcessId?.toString()) || '') {
        setHasCalledGetLogo(true)
        getCompanyLogo({ processId: selectedProcessId?.toString() || '' })
      }
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyLogo, selectedProcessId, hasCalledGetLogo])

  const displayAddedCompetencesSkills = () => {
    return addedCompetenciasSkills.map((competence, index) => (
      <Grid key={index}>
        <GridItem>
          <P>{competence.name_spa}</P>
        </GridItem>
        <GridItem>
          <TextView
            onClick={() => {
              setSelectedCompetenceForModalSkills(competence)
              setSelectedCompetenceIndexAdded(addedCompetenciasSkills.indexOf(competence))
              setViewAll(false)
              openModal()
              setSelectedActionId(competence.id)
            }}
          >
            Ver
          </TextView>
        </GridItem>
        <GridItem
          style={{ cursor: 'pointer' }}
          width="auto"
          justifyContent="flex-end"
          onClick={() => handleDeleteCompetenciaSkills(competence)}
        >
          <DeleteIcon />
          <TextDelete style={{ marginLeft: '8px' }}>Eliminar</TextDelete>
        </GridItem>
      </Grid>
    ))
  }
  const saveAddedCompetencesSkills = () => {
    const addedCompetenciasSkillsIds = addedCompetenciasSkills.map((competence) => competence.id)

    const dataToSend = {
      user_test_id: selectedUserTestId,
      development_plan: addedCompetenciasSkillsIds,
    }
    fetchSelectedCompetenceBetesa(dataToSend)
      .then(() => {
        if (flag) {
          navigate(-1)
          return
        }
        setShowFinalSkillsResult(true)
        setShowFinalBetesaResult(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    if (flag) return

    fetchDevelopmentPlan(userId)
      .then((data: any) => {
        const filteredData = data.filter((item: any) => item.developmentPlan === true)
        setDevelopmentPlanData(filteredData)
      })
      .catch((error) => {
        if (error.response.status === 404)
          getWarning('No tienes pruebas contestadas, para poder crear un plan de desarrollo')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  useEffect(() => {
    const fetchData = async () => {
      if (selecteActionId) {
        const data = await fetchDevelopmentPlanCompetences(selecteActionId)

        setDataAction(null)
        setDataAction((prevData) => (prevData ? [...prevData, data] : [data]))
      }
    }

    fetchData()
  }, [selecteActionId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCompetenceDataBetesa(null)
        const data = await fetchDevelopmentPlanBetesa(selectedTestId.toString(), selectedQuadrant)
        setCompetenceDataBetesa(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [selectedTestId, selectedQuadrant])

  useEffect(() => {
    if (testData && selectedTestId) {
      const fetchData = async () => {
        await fetchDevelopmentPlanSkills(selectedTestId.toString()).then((data: any) => {
          setDataSkills(data)
        })
      }

      fetchData()
    }
  }, [testData, selectedTestId])

  useEffect(() => {
    if (selectedTestId && selectedTypePlanLabel === 'betesa') {
      fetchFeedBack(selectedUserTestId)
        .then((res: any) => {
          if (res.data.status === 'OK') {
            setShowFinalBetesaResult(true)
          } else {
            setShowFinalBetesaResult(false)
          }
        })
        .catch(() => {
          setShowFinalBetesaResult(false)
        })
    } else if (selectedTestId && selectedTypePlanLabel === 'skills') {
      fetchFeedBack(selectedUserTestId)
        .then((res: any) => {
          if (res.data.status === 'OK') {
            setShowFinalSkillsResult(true)
          } else {
            setShowFinalSkillsResult(false)
          }
        })
        .catch(() => {
          setShowFinalBetesaResult(false)
        })
    }
  }, [selectedTestId, selectedTypePlanLabel, selectedUserTestId])

  useEffect(() => {}, [selectedTypePlanLabel])

  useEffect(() => {}, [selectedTestId])

  return (
    <MainLayout cobrandingLogoUrl={companyLogo || ''}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingTop: '.3rem' }}>
        {companyLogo && <CobrandingLogo logoUrl={companyLogo} hideMobile />}
      </div>
      <Container>
        {flag && userName && <H1 margin="20px 0px">Plan de desarrollo - {userName}</H1>}
        {!flag && (
          <ContainerPlan>
            <ContainerPlanText>
              <TitlePrincipal>Plan de desarrollo</TitlePrincipal>
            </ContainerPlanText>
            <Select
              label="Proceso"
              options={processOptions || []}
              value={selectedProcess}
              onChange={handleSelectChangeProcess}
              hideNeverChoice
            />
          </ContainerPlan>
        )}
        {selectedProcess && (
          <>
            {!flag && (
              <>
                {' '}
                <Row width="100%" flexDirection="column">
                  <ContainerCardsPlanText>
                    <TitlePrincipal> Selecciona la prueba a la que deseas agregar el plan de desarrollo</TitlePrincipal>
                  </ContainerCardsPlanText>
                </Row>
                <ContainerCardsPlan>
                  {testData.map((test, index) => {
                    let label: 'betesa' | 'skills' = 'betesa'
                    let sublabel = ''
                    if (test.typeTest === 'Betesa Premium') {
                      label = 'betesa'
                      sublabel = 'Talentos naturales'
                    } else if (test.typeTest === 'Competencias') {
                      label = 'skills'
                      sublabel = 'Soft skills'
                    }

                    return (
                      <CardDevelopmentPlan
                        style={{ width: '100%' }}
                        key={index}
                        selected={label === selectedTypePlanLabel}
                        onSelected={() => handleTypePlanSelect(label)}
                        title={
                          test.typeTest === 'Betesa Premium' ? `Betesa - ${sublabel}` : t('global.common.skillText')
                        }
                        type={label}
                        disabled={
                          test.domination === 'No interpretable' ||
                          (test.typeTest === 'Betesa Premium' && !test.domination)
                        }
                      />
                    )
                  })}
                </ContainerCardsPlan>
                {showFinalBetesaResult && !showFinalSkillsResult && (
                  <DownloadPlan skillParam={selectedUserTestId} typeTest="betesa" processIdParam={selectedProcessId} />
                )}
                {showFinalSkillsResult && !showFinalBetesaResult && (
                  <DownloadPlan skillParam={selectedUserTestId} typeTest="skills" processIdParam={selectedProcessId} />
                )}
              </>
            )}
            {(!showFinalBetesaResult || !showFinalSkillsResult) && selectedTypePlanLabel && selectedType && (
              <>
                {selectedTypePlanLabel &&
                  ((selectedTypePlanLabel === 'betesa' && !showFinalBetesaResult) ||
                    (selectedTypePlanLabel === 'skills' && !showFinalSkillsResult)) && (
                    <>
                      <Row margin="50px 0px 0px 0px">
                        <ContainerTitleSelect>
                          <H2>Competencias a desarrollar</H2>
                        </ContainerTitleSelect>
                        {selectedTypePlanLabel === 'betesa' && (
                          <Row justifyContent="flex-end" alignItems="center" width="100%" margin="12px 0px">
                            <P
                              style={{ display: window.innerWidth > 992 ? 'flex' : 'none' }}
                              margin="0px 10px"
                              fontWeight={700}
                            >
                              Cuadrante
                            </P>
                            <Select
                              width={window.innerWidth > 992 ? '25%' : '90%'}
                              label="Cuadrante"
                              options={mappedBrainQuadrants}
                              value={selectedQuadrant}
                              onChange={handleQuadrantChange}
                              hideNeverChoice
                            />
                          </Row>
                        )}
                      </Row>
                      {selectedTypePlanLabel === 'betesa' ? (
                        <Row>
                          <P>Selecciona máximo tres talentos a mejorar en tu plan de desarrollo</P>
                        </Row>
                      ) : (
                        <Row>
                          <P>Selecciona máximo tres competencias a mejorar en tu plan de desarrollo</P>
                        </Row>
                      )}
                      <GridContainer>
                        <P fontWeight={700}>Nombre</P>
                        <Divider />
                        {selectedTypePlanLabel === 'betesa' && (
                          <>
                            {competenceDataBetesa && competenceDataBetesa.length > 0 ? (
                              <>
                                {competenceDataBetesa.map((item) => (
                                  <Grid key={item.id}>
                                    <P>{item.name_spa}</P>
                                    <GridItem justifyContent="flex-end">
                                      <TextView
                                        onClick={() => {
                                          setSelectedCompetenceForModal(item)
                                          setSelectedCompetenceIndexAll(competenceDataBetesa.indexOf(item))
                                          setViewAll(true)
                                          openModal()
                                          setSelectedActionId(item.id)
                                        }}
                                      >
                                        Ver
                                      </TextView>
                                    </GridItem>
                                    <GridItem>
                                      <Row justifyContent="flex-end">
                                        <StrokedButton
                                          style={{ alignSelf: 'flex-end' }}
                                          onClick={() => handleAddCompetenceBetesa(item)}
                                          disabled={addedCompetences.includes(item) || addedCompetences.length >= 3}
                                        >
                                          Agregar
                                        </StrokedButton>
                                      </Row>
                                    </GridItem>
                                  </Grid>
                                ))}
                                {addedCompetences.length > 0 && (
                                  <Row margin="50px 0px 20px 0px" flexDirection="column">
                                    <Row width="100%" flexDirection="column">
                                      <H2>Competencias agregadas</H2>
                                      <Divider />
                                    </Row>
                                    {displayAddedCompetences()}
                                  </Row>
                                )}
                                <Row width="100%" justifyContent="flex-end">
                                  <Button
                                    style={{ alignSelf: 'flex-end', marginTop: '28px' }}
                                    onClick={saveAddedCompetences}
                                    disabled={addedCompetences.length < 1}
                                    type="button"
                                  >
                                    Guardar
                                  </Button>
                                </Row>
                              </>
                            ) : (
                              <P>No hay competencias a desarrollar en este cuadrante.</P>
                            )}
                          </>
                        )}
                        {selectedTypePlanLabel === 'skills' && (
                          <>
                            {dataSkills?.map((competenciaSkills, index) => (
                              <Grid key={index}>
                                <GridItem>
                                  <P>{competenciaSkills.name_spa}</P>
                                </GridItem>
                                <GridItem justifyContent="flex-end">
                                  <TextView
                                    onClick={() => {
                                      setSelectedCompetenceForModalSkills(competenciaSkills)
                                      setSelectedCompetenceIndexAllSkills(dataSkills.indexOf(competenciaSkills))
                                      setViewAll(true)
                                      openModal()
                                      setSelectedActionId(competenciaSkills.id)
                                    }}
                                  >
                                    Ver
                                  </TextView>
                                </GridItem>
                                <GridItem>
                                  <Row justifyContent="flex-end">
                                    <StrokedButton
                                      style={{ alignSelf: 'flex-end' }}
                                      onClick={() => handleAddCompetenciaSkills(competenciaSkills)}
                                      disabled={
                                        addedCompetenciasSkills.includes(competenciaSkills) ||
                                        addedCompetenciasSkills.length >= 3
                                      }
                                    >
                                      Agregar
                                    </StrokedButton>
                                  </Row>
                                </GridItem>
                              </Grid>
                            ))}
                            {addedCompetenciasSkills.length > 0 && (
                              <Row margin="50px 0px 20px 0px" flexDirection="column">
                                <Row width="100%" flexDirection="column">
                                  <H2>Competencias agregadas</H2>
                                  <Divider />
                                </Row>
                                {displayAddedCompetencesSkills()}
                              </Row>
                            )}
                            <Row width="100%" justifyContent="flex-end">
                              <Button
                                style={{ alignSelf: 'flex-end', marginTop: '28px' }}
                                onClick={saveAddedCompetencesSkills}
                                disabled={addedCompetenciasSkills.length < 1}
                                type="button"
                              >
                                Guardar
                              </Button>
                            </Row>
                          </>
                        )}
                      </GridContainer>
                      <></>
                    </>
                  )}
                {selectedCompetenceForModal ? (
                  <Modal
                    hideCloseButton
                    show={showModal}
                    setShow={(e: boolean) => setShowModal(e)}
                    styleContent={{ height: 'auto', width: '50%', borderRadius: '14px', padding: '30px' }}
                  >
                    <ModalContent>
                      <Row height="auto" width="100%" justifyContent="flex-end" alignItems="flex-end">
                        <H5
                          onClick={() => setShowModal(false)}
                          style={{ cursor: 'pointer', color: '#2D749C', alignSelf: 'flex-end', marginTop: '-10px' }}
                        >
                          X
                        </H5>
                      </Row>
                      <ActionsContainer>
                        <ActionsContainer>
                          {dataAction && dataAction.length > 0 ? (
                            dataAction?.map((data, i) => (
                              <div key={i}>
                                <H3>{data.name}</H3>
                                <P>{data.description}</P>
                              </div>
                            ))
                          ) : (
                            <P>No hay información para esta competencia</P>
                          )}
                        </ActionsContainer>
                      </ActionsContainer>
                      <NavigationModal>
                        {viewAll
                          ? selectedCompetenceIndexAll !== null &&
                            selectedCompetenceIndexAll > 0 && (
                              <ItemNavigation onClick={handlePrevious}>
                                <LeftWithCircleIcon />
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Anterior
                                </P>
                              </ItemNavigation>
                            )
                          : selectedCompetenceIndexAdded !== null &&
                            selectedCompetenceIndexAdded > 0 && (
                              <ItemNavigation onClick={handlePrevious}>
                                <LeftWithCircleIcon />
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Anterior
                                </P>
                              </ItemNavigation>
                            )}
                        {viewAll
                          ? competenceDataBetesa &&
                            selectedCompetenceIndexAll !== null &&
                            selectedCompetenceIndexAll < competenceDataBetesa.length - 1 && (
                              <ItemNavigation width="100%" justifyContent="flex-end" onClick={handleNext}>
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Siguiente
                                </P>
                                <RightWithCircleIcon />
                              </ItemNavigation>
                            )
                          : selectedCompetenceIndexAdded !== null &&
                            selectedCompetenceIndexAdded < addedCompetences.length - 1 && (
                              <ItemNavigation width="100%" justifyContent="flex-end" onClick={handleNext}>
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Siguiente
                                </P>
                                <RightWithCircleIcon />
                              </ItemNavigation>
                            )}
                      </NavigationModal>
                    </ModalContent>
                  </Modal>
                ) : null}
                {selectedCompetenceForModalSkills ? (
                  <Modal
                    hideCloseButton
                    show={showModal}
                    setShow={(e: boolean) => setShowModal(e)}
                    styleContent={{ height: 'auto', width: '50%', borderRadius: '14px', padding: '30px' }}
                  >
                    <ModalContent>
                      <Row height="auto" width="100%" justifyContent="flex-end" alignItems="flex-end">
                        <H5
                          onClick={() => setShowModal(false)}
                          style={{ cursor: 'pointer', color: '#2D749C', alignSelf: 'flex-end', marginTop: '-10px' }}
                        >
                          X
                        </H5>
                      </Row>
                      <ActionsContainer>
                        {dataAction && dataAction.length > 0 ? (
                          dataAction?.map((data, i) => (
                            <div key={i}>
                              <H3>{data.name}</H3>
                              <P>{data.description}</P>
                            </div>
                          ))
                        ) : (
                          <P>No hay información para esta competencia</P>
                        )}
                      </ActionsContainer>
                      <NavigationModal>
                        {viewAll
                          ? selectedCompetenceIndexAllSkills !== null &&
                            selectedCompetenceIndexAllSkills > 0 && (
                              <ItemNavigation onClick={handlePreviousSkills}>
                                <LeftWithCircleIcon />
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Anterior
                                </P>
                              </ItemNavigation>
                            )
                          : selectedCompetenceIndexAdded !== null &&
                            selectedCompetenceIndexAdded > 0 && (
                              <ItemNavigation onClick={handlePreviousSkills}>
                                <LeftWithCircleIcon />
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Anterior
                                </P>
                              </ItemNavigation>
                            )}
                        {viewAll
                          ? dataSkills &&
                            selectedCompetenceIndexAllSkills !== null &&
                            selectedCompetenceIndexAllSkills < dataSkills.length - 1 && (
                              <ItemNavigation width="100%" justifyContent="flex-end" onClick={handleNextSkills}>
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Siguiente
                                </P>
                                <RightWithCircleIcon />
                              </ItemNavigation>
                            )
                          : selectedCompetenceIndexAdded !== null &&
                            selectedCompetenceIndexAdded < addedCompetenciasSkills.length - 1 && (
                              <ItemNavigation width="100%" justifyContent="flex-end" onClick={handleNextSkills}>
                                <P margin="0px 10px" color="#2D749C" fontWeight={700}>
                                  Siguiente
                                </P>
                                <RightWithCircleIcon />
                              </ItemNavigation>
                            )}
                      </NavigationModal>
                    </ModalContent>
                  </Modal>
                ) : null}
              </>
            )}
          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default DevelopmentPlan
