import styled from 'styled-components'

export const CardInfoWithBorderTitle = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
`

export const CardTitleWithColor = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  background: #e6eef3;
  color: #212b36;
  width: 99%;
  border-radius: 10px 10px 0 0;
  padding: 3px;
`

export const BlueText = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
`

export const StressorsList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 1024px) {
    list-style: inside;
    padding: 0;
    margin: 0;
    padding: 10px;
  }
`

export const StressorText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #212b36;
  font-size: 16px;

  @media (max-width: 1024px) {
    font-size: 14px;
    margin-left: -8px;
  }
`

export const StressorsScore = styled.p`
  padding: 0;
  margin: 0;
  width: auto;
  color: #000;
  font-weight: 700;
`

export const SelfPerceptionsWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 20px;

@media (max-width: 1024px) {
  padding: 10px;
}
`