import React, { FC } from 'react'
import { Page } from 'views/components/report/styled'
import Headpage from '../../header'
import FooterPage from '../../footer-page'
import skillsDimensionsImg from 'assets/images/skillsDimensions.png'
import { DescriptionText, DimensionImg, Title, WrapperContent, Subtitle } from './styled'
import { useTranslation } from 'react-i18next'

interface DescriptionPageProps {
  currentPage: number
  totalPages: number
  resume: any
  generalTextByDimensions: any
  answerDate: string
}

const DescriptionPage: FC<DescriptionPageProps> = ({
  currentPage,
  totalPages,
  resume,
  generalTextByDimensions,
  answerDate,
}) => {
  const { t } = useTranslation()
  const findTextByType = (typeName: string) => {
    if (Array.isArray(generalTextByDimensions)) {
      const foundItem = generalTextByDimensions.find(
        (item: { type_general_text_name: string }) => item.type_general_text_name === typeName
      )
      return foundItem ? foundItem.text : 'No hay datos para mostrar'
    }
    return 'No hay datos para mostrar'
  }
  return (
    <Page>
      <Headpage isBetesa={false} />
      <WrapperContent>
        <Title>Descripción de las Dimensiones</Title>
        <DescriptionText>{findTextByType('Texto descripción general dimensiones')}</DescriptionText>
        <DimensionImg
          src={skillsDimensionsImg}
          alt={`Imagen de las dimensiones de las ${t('global.common.skillText')}`}
        />
        <Subtitle>¿Qué evalúa la dimensión cognitiva?</Subtitle>
        <DescriptionText>{findTextByType('Texto dimensión cognitiva')}</DescriptionText>
        <Subtitle>¿Qué evalúa la dimensión emocional?</Subtitle>
        <DescriptionText>{findTextByType('Texto dimensión emocional')}</DescriptionText>
        <Subtitle>¿Qué evalúa la dimensión interactiva?</Subtitle>
        <DescriptionText>{findTextByType('Texto dimensión interactiva')}</DescriptionText>
        <Subtitle>Percentil</Subtitle>
        <DescriptionText>{findTextByType('Percentil')}</DescriptionText>
      </WrapperContent>
      <FooterPage
        page={currentPage + 1}
        totalPages={totalPages}
        name={resume?.first_name}
        lastName={resume?.last_name}
        answerDate={answerDate}
      />
    </Page>
  )
}

export default DescriptionPage
