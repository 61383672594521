import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: auto;
    height: 100%;
    margin-top: 50px;
`;

export const Quadrant = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;
    height: 240px;
    background-color: #FAFCFF;
    margin-bottom: 20px;
`;

export const HeadQuadrant = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30%;
    margin-bottom: 20px;
    padding: 0 5%;
`;

export const BodyQuadrant = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    height: 70%;
    padding: 0 5%;
`;

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: auto;
    padding-top: 10px;
`;