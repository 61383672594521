import Modal from 'views/components/UI/Modal';
import { H3 } from 'views/components/UI/Text';
import { ModalCloseButtonContainer, ModalBottomNavigationContainer, Row, NavigationText,  DescriptionText } from './styled';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseIcon from "assets/icons/CloseIcon";

interface Props {
   data: any,
   onClose: () => void,
   visible: boolean, 
   onViewNextSkill: () => void,
   onViewPreviousSkill: () => void,
   handleAddSkill?: (props:any) => void  
   skillsSelected?: number[]
}

const ModalSkills: React.FC<Props> = ({onClose, visible, data, onViewNextSkill, onViewPreviousSkill}) => {


    return (
      <Modal styleContent={{ width: '40vw', height: '50vh', padding: '30px' }} show={visible} setShow={onClose} hideCloseButton>
        <ModalCloseButtonContainer>
          <CloseIcon onClick={onClose} />
        </ModalCloseButtonContainer>
        <Row justify='space-between'>
            <H3 width='80%' marginTop='-10px'>{ data?.skill || ''}</H3>
        </Row>
        <DescriptionText>{data?.description || ''}</DescriptionText>
        <ModalBottomNavigationContainer>
          <Row>
              <>
                <ArrowCircleLeftOutlinedIcon onClick={onViewPreviousSkill} style={{ color: '#2D749C' }} />
                <NavigationText onClick={onViewPreviousSkill}>Anterior</NavigationText>
              </>
          </Row>
          <Row justify='flex-end'>
                <NavigationText onClick={onViewNextSkill}>Siguiente</NavigationText>
                <ArrowCircleRightOutlinedIcon onClick={onViewNextSkill} style={{ color: '#2D749C' }} />
          </Row>
        </ModalBottomNavigationContainer>
      </Modal>
    );
  };
  
  export default ModalSkills;