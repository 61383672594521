import { FC, useState, useEffect } from 'react';
import { useSelector} from 'react-redux';
import { fetchReportBetesa } from 'services/report';
import { ReportBetesaSchema } from '../../components/report/ReportBetesaSchema';
import { CSSProperties } from 'react';

interface ReportBetesaProps {
  id?: string;
  style?: CSSProperties;
  user_id?: number;
  test_id?: number;
}


const ReportBetesa: FC<ReportBetesaProps> = ({  user_id, test_id }) => {
    const {token} = useSelector((state: any) => state.user);
    const [Betesa, setBetesa] = useState({});
    useEffect(() => {
        if (token && typeof user_id !== "undefined" && typeof test_id !== "undefined") {
          fetchReportBetesa(user_id, test_id)
            .then((res: any) => {
              setBetesa(res.data);
            })
            .catch((err) => {
              console.error(err);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
    return (<ReportBetesaSchema data={Betesa}/>)
}

export default ReportBetesa