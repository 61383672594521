import { FC } from 'react'
import Modal from 'views/components/UI/Modal';
import FormNewSuperAdmin from 'views/components/Forms/newSuperAdmin'
import { H3, P} from 'views/components/UI/Text';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'; 

import {
    ModalHeader,
    ModalBody,
  } from '../styled';

  interface NewSuperAdminProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    onSubmit: (e:any)=>void;
  }
const NewSuperAdmin : FC<NewSuperAdminProps> = ({
    openModal,
    setOpenModal,
    onSubmit,
}) => (    
    <Modal
        hideCloseButton={true}
        show={openModal}
        setShow={setOpenModal}
        styleContent={{
            borderRadius: '10px',
            padding: '20px',
            paddingBottom: 30,
            height:'90%'
        }}
    >
        <ModalHeader>
            <div
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setOpenModal(false);
                } }
            >
                <CloseOutlinedIcon />
            </div>
        </ModalHeader>
        <ModalBody>
            <H3>Agregar super administrador</H3>
            <P margin='10px 0px'>Ingresa el correo electrónico del super administrador a agregar</P>
            <FormNewSuperAdmin onCancel={() => setOpenModal(false)} onSubmit={onSubmit} />
        </ModalBody>
    </Modal>
)
export default NewSuperAdmin