import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import './styles.css';

interface IProps {
    tooltipText?: string;
    style ?: React.CSSProperties;
    marginTop?: string;
    placement?: 'bottom' | 'left' | 'right' | 'top' | undefined;
    margin?: string;
    iconColor?: string;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2D749C',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));


const Index = (props: IProps) => {
    const { tooltipText, style, marginTop, placement, margin, iconColor} = props;
    return (
    <LightTooltip sx={{
        marginLeft: '-10px',
        marginTop: marginTop || '0px',
        margin: margin || '0px',
        '& .MuiTooltip-tooltip': {
            backgroundColor: '#2D749C',
            color: '#fff',
        },
         ...style,
    }} title={tooltipText || ''} placement={placement || 'left'}>
        <Button style={{ backgroundColor: 'transparent'}}>
            <InfoIcon  style={{ color: iconColor || '#2BB5D4', cursor: 'pointer', width: '16px'}} />
        </Button>
    </LightTooltip>
    );
}

export default Index;
