import React from 'react';

interface Props {
    style?: React.CSSProperties;
}

const DownloadIcon = (props: Props) => {
    const { style } = props;
    return (
        <svg style={style} width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2349_7241)">
                <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
                <path d="M3 12.0042V21H21V12" stroke={style?.color || "#2D749C"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.5 11.5L12 16L7.5 11.5" stroke={style?.color || "#2D749C"}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 3V16" stroke={style?.color || "#2D749C"}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2349_7241">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DownloadIcon;


