import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 3%;
`
export const SelectorContainer = styled.div`
  margin-top: -2%;
`
