import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QuestionDragDrop from '../Questions/DragDrop'
import QuestionUniqueOptions from '../Questions/UniqueOptions'
import QuestionMultiOptions from '../Questions/MultipleOptions'
import { setTotalQuestionsPerChapter, setTotalChapters } from 'views/screens/Evaluated-User/store'
const QuestionComponent = () => {
  const { dataQuestion, currentChapter, currentQuestion, previousAnswers } = useSelector(
    (state: any) => state.questions
  )

  const dispatch = useDispatch()
  const commonProps = {
    key: currentQuestion,
    chapter: currentChapter + 1,
    questions: dataQuestion.hasOwnProperty(`chapter_${currentChapter + 1}`)
      ? dataQuestion[`chapter_${currentChapter + 1}`]
      : [],
    previousAnswers,
  }

  const currentType = commonProps.questions[currentQuestion]?.type_question

  useEffect(() => {
    dispatch(setTotalChapters(Object.keys(dataQuestion).length))
    dispatch(setTotalQuestionsPerChapter(commonProps.questions.length))
  })

  if (currentType === 'DRAG_AND_DROP' && commonProps.chapter <= 5) {
    return <QuestionDragDrop {...commonProps} />
  } else if (currentType === 'UNIQUE') {
    return <QuestionUniqueOptions {...commonProps} />
  } else if (currentType === 'MULTIPLE') {
    return <QuestionMultiOptions {...commonProps} />
  } else {
    return null
  }
}

export default QuestionComponent
