import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Container, Head, Title } from './styled';
import { Percentile, percentilesSelector } from './store';
import { MainLayout } from 'views/layouts';
import usePercentiles from './hooks/usePercentiles';
import ContentCardPercentile from './components/content-card-percentile/content-card-percentile';
import { useDispatch } from 'react-redux';
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Percentiles: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getPercentiles } = usePercentiles();
  const percentiles = useSelector(percentilesSelector);
  const [isLoading, setIsLoading] = useState(false);
  const levelName = useLocation()
  const stateParams = levelName.state;

  useEffect(() => {
    setIsLoading(true);
    if (!percentiles.user_name) getPercentiles();
  }, [dispatch, getPercentiles, percentiles]);

  useEffect(() => {
    if (percentiles.user_name) setIsLoading(false);
  }, [percentiles]);

  if (isLoading) return <ModalLoader isLoading={isLoading} />

  return (
    <MainLayout>
      <Container>
        <Head>
          <Title>Respuestas de {percentiles?.user_name} a {t('global.common.skillText')}</Title>
        </Head>

        {percentiles.result?.map((item: Percentile) => (
          <ContentCardPercentile percentilesInfo={item} key={item.id} levelName={stateParams}/>
        ))}

      </Container>
    </MainLayout>
  );
}

export default Percentiles;
