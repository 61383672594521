import styled from 'styled-components'

interface SeparatorProps {
  style?: any
  height?: string
  width?: string
}

const Separator = styled.div<SeparatorProps>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '1px'};
  background: transparent;
  ${({ style }) => style}
`

export default Separator
