import styled, { css } from 'styled-components'

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  height: max-content;

  @media (max-width: 992px) {
  }
  ${iOS &&
  css`
    background-color: white;
    overflow-y: auto;
    margin-top: 50px;
    @media (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      margin-top: 0 !important;
    }
  `}
`

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`
export const Questions = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 21px;
  @media (max-width: 574px) {
    font-size: 3.8vw;
    color: black;
  }
`

export const BtnNext = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 113px;
  height: 48px;
  background: #c6016b;
  color: #ffffff;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  @media (max-width: 1208px) {
    margin-top: 24px;
  }
`
export const CurrentChapterImg = styled.img`
  width: 689px;
  height: 459px;
  @media (max-width: 574px) {
    width: 90vw;
    height: 40vh;
    object-fit: contain;
  }
`

export const BtnGoBack = styled.div`
  color: #2d749c;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
`
export const GridModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
`
export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ModalBody = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
`
export const Row = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #c2c2c2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover {
    background: #c6016b;
  }
`
export const ParagraphChapter = styled.p`
  font-size: 18px;
  @media (max-width: 574px) {
    font-size: 3vw;
    margin-top: 10px;
  }
`
export const ParagraphInstructionsQuestionChapter = styled.p`
  font-size: 18px;
  display: block;
  @media (max-width: 574px) {
    font-size: 3vw;
    display: none;
    margin-top: 10px;
  }
`
export const MobileParagraphChapter = styled.p`
  font-size: 18px;
  text-align: justify;
  @media (min-width: 575px) {
    display: none;
  }
  @media (max-width: 574px) {
    font-size: 3vw;
    margin-top: 10px;
  }
`
export const Title = styled.h1`
  margin-top: 0;
  @media (max-width: 574px) {
    font-size: 4.3vw;
  }
  @media (max-width: 992px) {
  }
`
export const SubTitle = styled.h2`
  margin-top: 0;
  @media (max-width: 574px) {
    font-size: 3.8vw;
  }
`
export const ImgGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ParagraphOption = styled.p`
  color: #1f2124;
  width: 100px;
`
export const ParagraphOptionCard = styled.p`
  margin: 0;
  color: #1f2124;
  line-height: 1.2;
`
