import React from 'react';

const GreenFile: React.FC = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.3226 9.77488L20.4026 3.10821C20.2779 2.97009 20.1258 2.85958 19.9558 2.7838C19.7859 2.70802 19.602 2.66865 19.4159 2.66821H8.74927C8.30627 2.66292 7.86657 2.74496 7.45527 2.90962C7.04398 3.07428 6.66914 3.31835 6.35218 3.62788C6.03522 3.93742 5.78233 4.30636 5.60796 4.71363C5.43359 5.12091 5.34116 5.55854 5.33594 6.00154V26.0015C5.34116 26.4445 5.43359 26.8822 5.60796 27.2895C5.78233 27.6967 6.03522 28.0657 6.35218 28.3752C6.66914 28.6847 7.04398 28.9288 7.45527 29.0935C7.86657 29.2581 8.30627 29.3402 8.74927 29.3349H23.2559C23.6989 29.3402 24.1386 29.2581 24.5499 29.0935C24.9612 28.9288 25.3361 28.6847 25.653 28.3752C25.97 28.0657 26.2229 27.6967 26.3972 27.2895C26.5716 26.8822 26.664 26.4445 26.6693 26.0015V10.6682C26.6684 10.3378 26.5449 10.0194 26.3226 9.77488ZM18.6693 5.33488L23.6559 10.6682H19.6559C19.5163 10.6598 19.3798 10.6236 19.2543 10.5619C19.1288 10.5001 19.0168 10.4139 18.925 10.3084C18.8331 10.2029 18.7632 10.0802 18.7193 9.94737C18.6754 9.81456 18.6584 9.67433 18.6693 9.53488V5.33488Z" fill="#60CC1E"/>
        </svg>
    );
};

export default GreenFile;
