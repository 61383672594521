import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface IProps {
  activeStep: number;
  steps: string[];
}

function Steps(props: IProps) {
  const { activeStep, steps = [] } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep || 0} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default Steps;