import { User } from 'types/common';

export const mergeUsers = (arrayOne: User[], arrayNewUser: User[], idNewUser?: number): User[] => {
  const uniqueUsers: User[] = [];
  const usersWithoutNumericId: User[] = [];

  const usersDocuments = new Map<string, User>();
  const usersEmails = new Map<string, User>();

  const isNumeric = (value: any): value is number => {
    return typeof value === 'number';
  }

  for (const user of arrayNewUser) {
    if (isNumeric(user.id) && !usersDocuments.has(user.document) && !usersEmails.has(user.email)) {
      uniqueUsers.push(user);
      usersDocuments.set(user.document, user);
      usersEmails.set(user.email, user);
    } else {
      usersWithoutNumericId.push(user);
    }
  }

  for (const user of arrayOne) {
    let isUnique = true;

    if (usersDocuments.has(user.document)) {
      isUnique = false;
      const existingUser = usersDocuments.get(user.document);
      if (existingUser?.test_sent !== true && user.test_sent === true) {
        if (existingUser) {
          existingUser.test_sent = true;
        }
      }
    }

    if (usersEmails.has(user.email)) {
      isUnique = false;
      const existingUser = usersEmails.get(user.email);
      if (existingUser?.test_sent !== true && user.test_sent === true) {
        if (existingUser) {
          existingUser.test_sent = true;
        }
      }
    }

    if (isUnique) {
      if (isNumeric(user.id)) {
        uniqueUsers.push(user);
        usersDocuments.set(user.document, user);
        usersEmails.set(user.email, user);
      } else {
        usersWithoutNumericId.push(user);
      }
    }
  }



  const mergedArray = [...uniqueUsers, ...usersWithoutNumericId];

  if (idNewUser && typeof idNewUser === 'number') {
    const index = mergedArray.findIndex((user) => user.id === idNewUser);
    // lo pone de primero
    const user = mergedArray.splice(index, 1);
    mergedArray.unshift(user[0]);
  }

  // si tiene id numerico, lo pone de primero
  if (idNewUser && typeof idNewUser === 'number') {
    const index = mergedArray.findIndex((user) => user.id === idNewUser);
    const user = mergedArray.splice(index, 1);
    mergedArray.unshift(user[0]);
  }
  
  return mergedArray
};
