import axios from "lib/axios";

interface IResponse {
  status: string,
  active_processes: number,
  available_inventories: number,
  total_tests_completed: number,
  all_processes?: Array<{
    process_id: number,
    process_name: string,
    active_process: boolean,
    testId: number,
    test_name: string
  }>,
  total_tests_generated: {
    total: number,
    betesaPercent: number,
    skillPercent: number
  } 
}

export const fetchAllDataHome = async () => {
  const response = await axios.get(`/company/details/platform_admin`).then((resp) => {
    const data: IResponse = resp.data;
    return data;
  }).catch((error) => {
    return error;
  });
  return response;
}

export const fetchProcessById = async (processId: number | number[]) => {
  if (!processId) {
    return;
  }
  const processIds = Array.isArray(processId) ? processId : [processId];
  const queryString = processIds.map(id => `process_id=${id}`).join('&');
  const response = await axios.get(`/company/details/platform_admin/process?${queryString}`).then((resp) => {
    const data: IResponse = resp.data;
    return data;
  }).catch((error) => {
    return error;
  });
  return response;
}
