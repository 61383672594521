import { useState } from 'react'
import Modal from 'views/components/UI/Modal'
import Input from 'views/components/UI/TextInput'
import { H4, H5 } from 'views/components/UI/Text'
import { Button } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'

interface Props {
  isOpen: boolean
  onClose: () => void
  onEdit: (text: string) => void
  text: string
  currentText: number,
  textThreeOptions?: string[]
  setThreeOptions?: (text: string[]) => void
}

const ModalEdit = (props: Props) => {
  const { isOpen, onClose, text, onEdit, textThreeOptions, currentText, setThreeOptions } = props
  const { getSuccess, getWarning } = useNotification()

  const [textEdit, setTextEdit] = useState<string>(text)
  const [threeOptions, setChangeThreeOptions] = useState<string[]>(textThreeOptions || [])

  const handleSave = () => {
    if (textEdit.trim() === '' || threeOptions.some(option => option.trim() === '')) {
      getWarning('No puedes enviar solo espacios en blanco.');
    } else {
      onEdit(textEdit)
      if (setThreeOptions) {
        setThreeOptions(threeOptions)
      }
      getSuccess('Se ha editado el texto correctamente.')
    }
  }


  return (
    <Modal
      show={isOpen}
      setShow={onClose}
      styleContent={{
        width: '75%', justifyContent: 'space-between', alignItems: 'center', paddingTop: '2rem',
        // delete scroll
        overflow: 'hidden'
      }}
    >
      <H4 width='100%' marginBottom='20px' >Modificación de texto de correo electrónico</H4>
      <Input style={{ width: '100%' }} value={textEdit} onChange={(e) => setTextEdit(e.target.value)} type='text' label='' />
      {
        textThreeOptions && currentText === 3 && (
          <>
            <H5 style={{ width: '100%', margin: '20px 0px 10px 0px' }}>Opciones de respuesta</H5>
            {
              threeOptions.map((option, index) => (
                <Input style={{ width: '75%', alignSelf: 'flex-start', marginBottom: '16px', justifyContent: 'center' }} key={index} value={option} onChange={(e) => {
                  const newOptions = [...threeOptions]
                  newOptions[index] = e.target.value
                  setChangeThreeOptions(newOptions)
                }} type='text' label='' />
              ))
            }
          </>

        )

      }
      <Button style={{ alignSelf: 'flex-end' }} onClick={handleSave} >Guardar</Button>
    </Modal>
  )

}

export default ModalEdit
