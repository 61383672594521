import React, { FC } from 'react'

import {
  ModalSaveContainer,
  ModalSaveText,
  ModalBetesaContainer,
  ModalTitle,
  ModalCompetenceContainer,
  ModalText,
  ModalIdealContainer,
  Cross,
  CrossContainer,
  Quadrant,
  TitleQuadrant,
  TextQuadrant,
  IconQuadrant,
  DescriptionContainer,
  TopContainer,
} from './styled'

import Modal from 'views/components/UI/Modal'
import { INineBoxResponse } from 'views/screens/Platform-administrator/Analytics/constants/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  data: INineBoxResponse
  show: boolean
  processName: string
  close: (value: boolean) => void
  options: {
    betesa: boolean
    competences: boolean
  }
}

const Bullet = () => <span style={{ color: 'black', marginLeft: '-6px' }}> • </span>

type QuadrantValues = { min: number; max: number; priority: number }
type ProfileObject = Record<string, QuadrantValues>

const IdealProfileModal: FC<IProps> = ({ show, close, options, data, processName }) => {
  const { t } = useTranslation()
  const { betesa, competences } = options

  const profileValues = data?.betesa_ideal_profile?.profile_values

  const profileObject = profileValues?.reduce((obj, item) => {
    const initials = item.quadrant

    obj[initials] = {
      min: item.min,
      max: item.max,
      priority: item.priority,
    }

    return obj
  }, {} as ProfileObject)

  const alertLevelRange = data?.betesa_ideal_profile?.alert_level_range

  const numberToLetterMap: Record<string, string> = {
    1: 'E',
    2: 'EI',
    3: 'EE-II',
    4: 'IE',
    5: 'I',
  }
  let alerts = null
  if (alertLevelRange?.length) {
    alerts = {
      alertMin: numberToLetterMap[alertLevelRange[0].toString()],
      alertMax: numberToLetterMap[alertLevelRange[1].toString()],
    }
  }

  return (
    <Modal styleContent={{ minWidth: '50%' }} show={show} setShow={close}>
      <ModalSaveContainer>
        <ModalSaveText>Perfil ideal de pruebas asociadas en {processName}</ModalSaveText>
        {betesa ? (
          <ModalBetesaContainer>
            {competences ? (
              <ModalTitle style={{ marginTop: '20px' }}>Betesa</ModalTitle>
            ) : (
              <DescriptionContainer style={{ flexDirection: 'row' }}>
                <ModalTitle>Descripción general: </ModalTitle>
                <ModalText style={{ width: '75%', margin: 'auto', lineHeight: '22px' }}>
                  Tiene a su cargo uno o varios procesos específicos de áreas como marketing, innovación o diseño de
                  producto y lidera a una o varias personas.
                </ModalText>
              </DescriptionContainer>
            )}
            <ModalIdealContainer>
              <ModalTitle>
                Nivel de alerta: {alerts?.alertMin} - {alerts?.alertMax}
              </ModalTitle>
              <CrossContainer>
                <Cross>
                  <Quadrant style={{ top: '18%', left: '20%' }}>
                    <IconQuadrant style={{ top: '0%', left: '-25%' }}>FI</IconQuadrant>
                    <TitleQuadrant>Prioridad {profileObject?.FI?.priority}</TitleQuadrant>
                    <TextQuadrant>
                      Rango: {profileObject?.FI?.min} - {profileObject?.FI?.max}
                    </TextQuadrant>
                  </Quadrant>
                  <Quadrant style={{ top: '18%', right: '10%' }}>
                    <IconQuadrant style={{ top: '0%', right: '0%' }}>FD</IconQuadrant>
                    <TitleQuadrant>Prioridad {profileObject?.FD?.priority}</TitleQuadrant>
                    <TextQuadrant>
                      Rango: {profileObject?.FD?.min} - {profileObject?.FD?.max}
                    </TextQuadrant>
                  </Quadrant>
                  <Quadrant style={{ bottom: '-5%', left: '20%' }}>
                    <TitleQuadrant>Prioridad {profileObject?.BI?.priority}</TitleQuadrant>
                    <TextQuadrant>
                      Rango: {profileObject?.BI?.min} - {profileObject?.BI?.max}
                    </TextQuadrant>
                    <IconQuadrant style={{ bottom: '36%', left: '-25%' }}>BI</IconQuadrant>
                  </Quadrant>
                  <Quadrant style={{ bottom: '-5%', right: '10%' }}>
                    <TitleQuadrant>Prioridad {profileObject?.BD?.priority}</TitleQuadrant>
                    <TextQuadrant>
                      Rango: {profileObject?.BD?.min} - {profileObject?.BD?.max}
                    </TextQuadrant>
                    <IconQuadrant style={{ bottom: '35%', right: '0%' }}>BD</IconQuadrant>
                  </Quadrant>
                </Cross>
              </CrossContainer>
            </ModalIdealContainer>
          </ModalBetesaContainer>
        ) : null}
        {competences ? (
          <ModalCompetenceContainer>
            {betesa ? (
              <ModalTitle>{t('global.common.skillText')}</ModalTitle>
            ) : (
              <DescriptionContainer>
                <TopContainer>
                  <ModalTitle>Descripción general: </ModalTitle>
                  <ModalText style={{ width: '75%', margin: 'auto', lineHeight: '22px' }}>
                    Tiene a su cargo uno o varios procesos específicos de áreas como marketing, innovación o diseño de
                    producto y lidera a una o varias personas.
                  </ModalText>
                </TopContainer>
                <TopContainer>
                  <ModalTitle style={{ marginBottom: '0' }}>Nivel: </ModalTitle>
                  <ModalText style={{ width: '61%', margin: 'auto', lineHeight: '22px' }}>
                    {data?.competences_ideal_profile[0]?.level}
                  </ModalText>
                </TopContainer>
                <TopContainer>
                  <ModalTitle style={{ marginBottom: '0', alignSelf: 'flex-start' }}>Perfil ideal: </ModalTitle>
                  <TopContainer style={{ flexDirection: 'column', width: '700px', margin: '0' }}>
                    {data?.competences_ideal_profile?.length &&
                      data?.competences_ideal_profile?.map((item, index) => (
                        <ModalText
                          key={`ideal-profile-${item.weight}-${index}`}
                          style={{ width: '61%', marginLeft: '-40px', lineHeight: '22px' }}
                        >
                          <Bullet />
                          {item.skill_name}: Superior a {item.weight}
                        </ModalText>
                      ))}
                  </TopContainer>
                </TopContainer>
              </DescriptionContainer>
            )}
            {betesa ? (
              <>
                {data?.competences_ideal_profile?.length &&
                  data?.competences_ideal_profile?.map((item, index) => (
                    <ModalText key={`ideal-profile-betesa-${index}-${item.weight}`}>
                      <Bullet />
                      {item.skill_name}: Superior a {item.weight}
                    </ModalText>
                  ))}
              </>
            ) : null}
          </ModalCompetenceContainer>
        ) : null}
      </ModalSaveContainer>
    </Modal>
  )
}

export default IdealProfileModal
