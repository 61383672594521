import { useCallback } from "react"
import { fetchCreateNewTeamMap } from "../Services"
import { useNavigate } from "react-router-dom"
import { platformAdminRoutes } from "router/routes"
import { useNotification } from "lib/context/notification.context"
import { fetchGetAllBetesaUsers } from "../Services/fetchGetAllBetesaUsers"
import { fetchAddUsersTeamMap } from "../Services/fetchAddUsersTeamMap"
import { fetchGetTeamMap } from "../Services/fetchGetTeamMap"
import { fetchHistoryTeamMap } from "../Services/fetchAllHistoryTeamMap"
import { fetchDeleteTeamMap } from "../Services/fetchDeleteTeamMap"

export const useTeamMap = () => {
  const navigate = useNavigate()
  const { getError, getSuccess } = useNotification()
  const createNewTeamMap = useCallback(async (mapName: string) => {
    try {
      const body = { name: mapName }
      const response = await fetchCreateNewTeamMap(body)
      const teamMapId = response.data.teamMapId
      const fullObjectSavedUsers: any = []
      navigate(platformAdminRoutes.TeamDevelopmentUsers, { state: { fullObjectSavedUsers, mapName, teamMapId } })
    } catch {
      getError('No se pudo crear el mapa de betesa')
    }

  }, [navigate, getError])

  const getAllBetesaUsers = useCallback(async (page: number, filter: any) => {
    try {
      const { data: response } = await fetchGetAllBetesaUsers(page, filter)
      return {
        users: response.data.users,
        total_pages: response.data.total_pages
      }
    } catch {
      getError('No se pudo obtener los usuarios de betesa')
    }
  }, [getError])

  const getTeamMap = useCallback(async (teamMapId: number) => {
    try {
      const { data } = await fetchGetTeamMap(teamMapId)
      return data
    } catch {
      getError('No se pudo obtener el mapa de betesa')
    }
  }, [getError])

  const addUsersTeamMap = useCallback(async (usersTestIds: number[], teamMapId: number) => {
    try {
      const body = {
        team_map_id: teamMapId,
        users_test_ids: usersTestIds
      }
      await fetchAddUsersTeamMap(body)
    } catch {
      getError('No se pudo crear el mapa de betesa')
    }
  }, [getError])

  const getHistoryTeamMap = useCallback(async (page: number, search: string) => {
    try {
      return await fetchHistoryTeamMap(page, search)
    } catch {
      getError('No se pudo obtener el historial de mapas de betesa')
    }
  }, [getError])

  const deleteTeamMap = useCallback(async (teamMapId: number, isForBackButton?: boolean) => {
    try {
      await fetchDeleteTeamMap(teamMapId, isForBackButton)
      getSuccess('Mapa de betesa eliminado correctamente')
    } catch {
    }
  }, [getSuccess])

  return {
    createNewTeamMap,
    getAllBetesaUsers,
    addUsersTeamMap,
    getTeamMap,
    getHistoryTeamMap,
    deleteTeamMap,
  }
}