import React from 'react'

import Modal from '../Modal'
import Loader from '../Loader'

interface IProps {
  isLoading: boolean
  text?: string
  style?: React.CSSProperties
}

const ModalLoader = ({ isLoading, text, style }: IProps) => {
  return (
    <Modal onlyContent show={isLoading} setShow={() => {}}>
      <Loader style={style} text={text} />
    </Modal>
  )
}

export default ModalLoader
