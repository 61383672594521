import { useEffect, useState } from 'react'
import { useParams, } from 'react-router-dom'
import { MainLayout } from 'views/layouts'

import { useNotification } from 'lib/context/notification.context'
import { putFamilyRolProfileValue } from 'services/rolFamily/fetchPutBasicInfo'
import { Form, Formik } from 'formik'
import ProfileInfo from '../../../components/profile-info-form'
import { generalDataSelector } from 'ducks/auth'
import { useSelector } from 'react-redux'
import BackButton from 'views/components/UI/BackButton/back-button'
import { validationIdealProfileSchema } from '../../../schemas'
import Footer from 'views/components/UI/Footer/footer'
import { Button } from 'views/components/UI/Buttons'
import { STATUS_OK } from 'constants/global.constants'

const EditIdealProfile = ({ information, handleForm }: any) => {
  const roleFamily: any = useParams()
  const { getError, getSuccess } = useNotification();
  const { data } = useSelector(generalDataSelector);
  const { alertLevelText } = data;
  const [idealProfile, setIdealProfile] = useState({
    ideal_profile_data: {
      alert_level_range: [0, 0],
      profile_values: [{
        profile_value_id: 0,
        quadrant: '',
        priority: 0,
        min: 1,
        max: 100,
      }]
    }
  })

  const handleSubmit = async (values: any) => {
    const profileValues = values?.ideal_profile_data?.profile_values.map((value: any) => {
      return {
        id: value.profile_value_id,
        quadrant: value.quadrant,
        priority: value.priority,
        min: value.min,
        max: value.max,
      }
    })

    const familyData = {
      ideal_profile_data: {
        alert_level_range: Number(values?.ideal_profile_data?.alert_level_range),
        profile_values: profileValues
      }
    }
    await putFamilyRolProfileValue(familyData, roleFamily.id).then((response: any) => {
      if (!response.ok && response.message) getError('No se pudo guardar, verifica que los campos estén llenos')
      if (response?.status === STATUS_OK) handleForm(0)
      getSuccess('Datos guardados correctamente')
    }).catch(() => {
      getError('No se pudo guardar, verifica que los campos esten llenos')
    })
  }

  const hasError = (errors: any) => {
    return Object.keys(errors).length > 0;
  }

  useEffect(() => {
    setIdealProfile({
      ideal_profile_data: {
        alert_level_range: information[0]?.alert_level_range,
        profile_values: information
      }
    })
  }, [information])
  return (
    <MainLayout>
      <Formik
        initialValues={idealProfile}
        validationSchema={validationIdealProfileSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue, values, errors }) => (
          <Form id="form-family-rol-settings-skill">

            <ProfileInfo props={{ handleChange, values, setFieldValue, alertLevelText }} />
            <Footer>
              <BackButton type='button' onClick={() => handleForm(0)} text='Cancelar' />
              <Button type="submit" disabled={hasError(errors)}>Guardar</Button>
            </Footer>

          </Form>
        )}
      </Formik>
    </MainLayout>

  )
}

export default EditIdealProfile
