import styled from 'styled-components'

interface IQuadrantTagContainer {
  alignITems?: string
}

interface IQuadrantTag {
  show?: boolean
}

interface IBodyQuadrant {
  left?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
  padding-bottom: 20%;
`

export const QuadrantsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 280px;
`

export const BodyQuadrant = styled.div<IBodyQuadrant>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  direction: ${(props) => (props.left ? 'rtl' : 'ltl')};
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
`

export const QuadrantTagContainer = styled.div<IQuadrantTagContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 8%;
  align-items: ${(props) => props.alignITems || 'flex-start'};
  position: relative;
`
export const QuadrantTag = styled.div<IQuadrantTag>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 25px;
  background-color: ${(props) => (props.show ? '#C6016B' : '#81acc4')};
  border-radius: 20px;
  color: white;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-shadow: 0 1px #666;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const AbsoluteContainer = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #d1e6f1;
  opacity: 0.5;
  margin-top: -7%;

  @media (max-width: 1366px) {
    margin-top: -10%;
  }
`

export const ShowName = styled.div<IBodyQuadrant>`
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 6px;

  &::${(props) => (props.left ? 'after' : 'before')} {
    content: ' • ';
    color: black;
  }
`

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  width: 80%;
  height: 90%;
`
