import axios from 'axios'
import { INineBoxUsers } from 'views/screens/Platform-administrator/Analytics/constants/types'

const getNineBoxUsers = async (body: INineBoxUsers, limit: number, page: number) => {
  const nineBoxUsers = await axios
    .post(`/nine-box/users?limit=${limit}&page=${page}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return nineBoxUsers
}

export default getNineBoxUsers
