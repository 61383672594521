export interface IDocumentType {
  id: number
  name: 'Documento de identidad' | 'T.I.' | 'Documento de extranjería' | 'Pasaporte'
}

export type DocumentType = 'Documento de identidad' | 'T.I.' | 'Documento de extranjería' | 'Pasaporte'

export interface IRoleType {
  id: number
  name: 'special_admin' | 'super_admin' | 'platform_admin' | 'super_platform_admin' | 'user'
}

export interface ICompanyType {
  id: number
  name: 'Corporativo' | 'Orientación Vocacional'
}

export interface ISubscriptionsType {
  id: number
  name: 'Silver' | 'Gold'
}

export interface IProcessType {
  id: number
  name: 'Contratacion' | 'Evaluacion'
}

export interface ICategoryTestType {
  id: number
  name: 'Betesa Premium' | 'Competencias'
}

export interface IGroup {
  id: number
  name: string
}

export interface ILevel {
  id: number
  name: 'Estratégico' | 'Táctico' | 'Operativo'
}

export interface Level {
  id: number
  name: string
}

export interface IListAdmin {
  id: number
  first_name: string
  last_name: string
}

export interface Skill {
  id: number
  name: string
  weight?: number
}

export enum BrainQuadrants {
  FI = 1,
  BI = 2,
  BD = 3,
  FD = 4,
}

export type Company = {
  id: number
  contact_name: string
  contact_email: string
  name: string
  idOperativo: number
  logo: string
  operative: string
  phone: string
  caracter: string
  idCaracter: number
  allow_integration: boolean
}

export type CompanyInfo = {
  id: number
  contact_name: string
  contact_email: string
  name: string
  logo: string
  phone: string
  caracter: string
  idCaracter: number
  allow_integration: boolean
}

export interface IdealProfileScores {
  FI: number
  FD: number
  BI: number
  BD: number
  Color?: string
}

export interface Inventory {
  name: string
  type: string
  credits: number
  tests_used: number
  tests_available: string
  start_date: string
  expiration_date: string
  subscriptionId: number
  subscriptionName: string
  state: boolean
  companyId: number
  suscription_company_id: number
  usedCredits: number
  total_page: number
}

export type IGeneralData = {
  data: {
    document_type: IDocumentType[]
    role_type: IRoleType[]
    type_company: ICompanyType[]
    subscription: ISubscriptionsType[]
    type_process: IProcessType[]
    category: ICategoryTestType[]
    group: IGroup[]
    level: ILevel[]
    list_admin: IListAdmin[]
  }
}

export type BetesaScore = {
  FI: number
  BI: number
  BD: number
  FD: number
}

export interface IUser {
  first_name: string
  last_name: string
  document: string
  document_type_id: number
  email: string
  role: string
  company_id: number
  birth_date?: string
  personId: number
  userId: number
  active: boolean
  process_ids?: number[]
  company_name?: string
  test_types?: string[]
  id?: number
}

export type IUsersInProcess = {
  id?: number
  test_types?: string[]
  userId: number
  personId: number
  processId: number
  first_name: string
  last_name: string
  document: string
  email: string
}

export interface CreateUserBatch {
  company_id: number
  document: string
  document_type: number
  email: string
  first_name: string
  last_name: string
  role_type: number
  id?: number
  reason?: string
}

export interface User {
  id?: number
  document: string
  email: string
  first_name: string
  last_name?: string
  userId?: number
  company_name?: string
  test_types?: string
  test_sent?: boolean
  ative?: boolean
}

// CREATE PROCESS

export interface StepOne {
  processId?: number
  processName: string
  companyId: number
  processType: number
  inventoryTypeId: string
  inventoryUniqName: string
  level: string | null
  levelId?: number | null
  admins: number[]
  automaticReports: boolean
  testLanguage: 'es' | 'en' | string
  permitDevelopmentPlan: boolean
  subscriptionCompanyId: number
  profileId?: number
  showInputId?: boolean
}

export interface StepTwo {
  processId: number
  isRoleFamilySelected: boolean
  roleFamilyName?: string | null
}

export type TypeTests = 'betesa' | 'skills'

export interface StepThree {
  processId?: number
  onlineCourse?: boolean
  useBetesaTest?: boolean
  useSkillsTest?: boolean
}

export interface BetesaStepFourData {
  betesaSkills: number[]
  alertLevelId: number[]
  profileValues: BetesaIdealProfile[]
}
export interface BetesaIdealProfile {
  quadrant: 'FI' | 'FD' | 'BI' | 'BD'
  priority: number
  min: number
  max: number
}

interface SkillIdealProfile {
  skillId: number
  weight: number
}

export interface StepFour {
  processId: number
  betesaIdealProfile?: BetesaStepFourData
  competencesIdealProfile?: SkillIdealProfile[]
  isNewProcess?: boolean
  isEditingRoleFamily?: boolean
}

export interface UsersAdded {
  docomentType: number
  document: string
  email: string
  name: string
  lastName?: string
  reason?: string
}

export interface StepFive {
  processId: number
  betesaTestId?: number
  competencesTestId?: number
  usersIds?: number[] | [] | any
  acceptTerms?: boolean
  userListAdded?: UsersAdded[]
  userListFailed?: UsersAdded[]
  testTypes?: string
  emailTemplate?: {
    textOne: string
    textTwo: string
    textThree: string
    textFour: string
    textThreeOptions: string[]
    date: string
  }
}

export interface LevelType {
  value: number
  label: string
}

export interface Admin {
  id: number
  admin: boolean
  company_id: number
  user_id: number
  created_at: string
  updated_at: string
}

export interface RoleFamily {
  id: number
  category: string | 'Competencias' | 'Betesa Premium'
  level: string
  name: string
  description: string
  weight?: number
  skill?: string
}

export interface InventoriesAvailables {
  suscription_company_id: number
  companyId: number
  credits: number
  name: string
  subscriptionId: number
  subscriptionName: string
  usedCredits: number
}

export interface BetesaSkill {
  id: number
  name: string
  company_id: number
  quadrant_id: number
  behavior: Behavior[]
}

export interface Behavior {
  id: number
  description: string
}

export interface CreateBetesaSkill {
  name: string
  company_id: number
  quadrant_id: number
  behaviorsDescription: string[]
}

export interface UpdateBetesaSkill {
  id: number
  name: string
  company_id: number
  quadrant_id: number
  behaviorsDescription: string[]
}

export interface AlertLevel {
  id: string
  name: string
}

// ROLE FAMILY

export type RoleFamilies = {
  id: number
  name: string
  level: string
  category: string
  description: string
}

export type TypicalPositions = {
  id: number
  name: string
}

export type FamilySkills = {
  id: number
  name: string
  weight: number
}

export type CompetenceSkills = {
  id: number
  skill_id?: number
  skill?: string
  skill_name?: string
  name?: string
  weight: number
  test_category_id: number
}

export interface SkillWithMinRecord {
  key?: number
  skill_id: number
  weight: number
}

export type IdealProfile = {
  alert_level_range: number[]
  ideal_profile_id: number
  max: number
  min: number
  priority: number
  profile_value_id: number
  quadrant: 'FI' | 'BI' | 'BD' | 'FD'
  test_id: number
}

export interface DetailRoleFamily {
  role_families: RoleFamilies[]
  typical_positions: TypicalPositions[]
  family_skills: FamilySkills[]
  competence_skills: CompetenceSkills[]
  betesa_skills: []
  ideal_profile: IdealProfile[]
}

export interface DetailRoleFamilyResponse {
  status: 'error' | 'OK'
  data?: {
    role_families: RoleFamilies[]
    typical_positions: TypicalPositions[]
    family_skills: FamilySkills[]
    competence_skills: CompetenceSkills[] | []
    betesa_skills: []
    ideal_profile: IdealProfile[] | []
  }
}

export interface Skill {
  category_id: number
  cognitive_description: string
  description: string
  descriptions_id: number
  emotional_description: string
  group_id: number
  group_name: string
  id: number
  interactive_description: string
  language: string
  level_id: number
  level_name: string
  skill: string
  type_category_id: number
}

export type ListSkills = Skill[]

type IBehavior = {
  id: number
  description: string
}

export interface HomologationSkillsSelected {
  id: number
  quadrant_id: number
  name: string
  company_id: number
  behavior: IBehavior[]
}

type AlertLevelIds = [number, number]

type IProfileValues = {
  quadrant: string
  priority: number
  min: number
  max: number
}

type BetesaSkills = {
  id: number
  name: string
  quadrant: string
}

interface CompetenceIdealProfile {
  skill_id: number
  weight: number
}

export interface DataForFinishProcess {
  amount_of_tests: number
  betesa_test: boolean | null
  company_id: number
  competences_test: boolean | null
  created_at: string
  description: string
  development_plan: boolean
  id: number
  language: string
  level: string | null
  level_name: string | null
  level_id: number
  name: string
  online_course: boolean | null
  participant_report: boolean
  role_family: boolean | null
  role_family_name: string | null
  sent: boolean
  step: number
  subscription_company_id: number
  subscription_company_name: string
  type_process_id: number
  updated_at: string
  admins: number[]
  betesa_ideal_profile: {
    alert_level_range: number[]
    profile_values: IProfileValues[]
  }
  betesa_skills?: BetesaSkills[]
  competences_ideal_profile: CompetenceIdealProfile[]
}

export const ALERT_LEVELS: Record<string, AlertLevelIds> = {
  'E - EI': [1, 2],
  'E - EE:II': [1, 3],
  'E - IE': [1, 4],
  'E - I': [1, 5],
  'EI - EE:II': [2, 3],
  'EI - IE': [2, 4],
  'EI - I': [2, 5],
  'EE:II - IE': [3, 4],
  'EE:II - I': [3, 5],
  'IE - I': [4, 5],
}

export interface IDataprocessInfo {
  status: string
  isUnlimited: boolean
  active_processes: number
  available_inventories?: number
  total_inventories?: number
  total_tests_completed: number
  all_processes: Array<{
    process_id: number
    process_name: string
    active_process: boolean
    testId: number
    test_name: string
  }>
  total_tests_generated: {
    total: number
    betesaPercent: number
    skillPercent: number
  }
}

export interface ProcessSelected {
  companyName: string
  testLanguage: string
  testType: string
  companyId: number
  userTestId: number
  processId?: number
}

export type TextByStep = {
  [key: string]: string
}

export interface UserDataCreateUser {
  firstName: string
  lastName: string
  document: string
  email: string
  documentType: string
  roleType: 1 | 2 | 3 | 4
  companyId?: number
  birthdate?: string
  noSendEmail?: boolean
}
