import React, { useState } from "react";
import {
  Container,
  MasterSquare,
  SquareRadius,
  NameQuadrantsContainer,
} from "./styled";
import RadioPattern from "assets/images/RadioPattern";

interface Quadrant {
  id: number;
  name: string;
  label: string;
  color: string;
}

interface IProps {
  onChange?: (value: string) => void;
  value: string;
}

const QUADRANTS: Quadrant[] = [
  {
    id: 1,
    name: "FI",
    label: "Frontal Izquierdo",
    color: "#DBF0CE",
  },
  {
    id: 2,
    name: "FD",
    label: "Frontal Derecho",
    color: "#EEDBFE",
  },
  {
    id: 3,
    name: "BI",
    label: "Basal Izquierdo",
    color: "#D1E6F1",
  },
  {
    id: 4,
    name: "BD",
    label: "Basal Derecho",
    color: "#E6ABCB",
  },
];

const SelectableBrainRadius = ({ onChange, value }: IProps) => {
  const [selectedQuadrant, setSelectedQuadrant] = useState<string | null>(
    value || null
  );

  const handleQuadrantClick = (quadrantName: string) => {
    setSelectedQuadrant(quadrantName);
    if (onChange) {
        onChange(quadrantName);
    }

  };

  return (
    <Container
      style={{
        backgroundImage: `url(${RadioPattern})`,
      }}
    >
      <NameQuadrantsContainer>
        {QUADRANTS.map((quadrant) => (
          <div style={{ width: "50%" }} key={quadrant.id}>
            <span>{quadrant.label}</span>
          </div>
        ))}
      </NameQuadrantsContainer>
      <MasterSquare>
        {QUADRANTS.map((quadrant) => (
          <SquareRadius
            onClick={() => handleQuadrantClick(quadrant.name)}
            key={quadrant.id}
            color={
              selectedQuadrant === null
                ? quadrant.color
                : selectedQuadrant === quadrant.name
                ? quadrant.color
                : "#5658674D"
            }
          />
        ))}
      </MasterSquare>
    </Container>
  );
};

export default SelectableBrainRadius;