import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 40px 0px 20px 0px;
`

export const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
`


interface IRow {
  width?: string
  height?: string
  margin?: string
  padding?: string
  justifyContent?: string
  alignItems?: string
}

export const Row = styled.div<IRow>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0px'};
  padding: ${({ padding }) => padding || '0px'};
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #C2C2C2;
`

interface IProps {
  width?: string;
  height?: string;
  maxHeigth?: string;
  padding?: string;
  margin?: string;
  justifyContent?: string;
  alignItems?: string;
  float?: string
  alignSelf?: string;
  maxWidth?: string;
  backgroundColor?: string;
  shadow?: string;
  flexDirection?: string;
}

export const BlueContainer = styled.div<IProps>`
    overflow: hidden;
    display: flex;
    align-self: ${props => props.alignSelf ? props.alignSelf : 'center'};
    width: ${props => props.width ? props.width : '100%'};
    max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
    height: ${props => props.height ? props.height : '100%'};
    max-height: ${props => props.maxHeigth ? props.maxHeigth : '100%'};
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${props => props.padding ? props.padding : '10px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
    float: ${props => props.float ? props.float : 'none'};
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'rgb(45, 116, 156)'};
    box-shadow: ${props => props.shadow ? props.shadow : '0px 2px 10px rgba(0, 0, 0, 0.1)'};
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.02);
    }
`;

