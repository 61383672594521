import { AxiosResponse } from 'axios';
import axios from 'lib/axios';

type RoleFamily = {
    id: number
    name: string
    level: string
    category: string
    description: string
}

interface SkillsResponse {
    status: string | 'success' | 'error' | 'OK'
    roleFamilies: RoleFamily[]
    total_pages:number
}

interface Filters {
    level: string
}

const getRoleFamilyByFilters = async (filters: Filters, page?: number, limit?: number): Promise<SkillsResponse> => {
    const paginationQuery = page ? `&page=${page} &limit=${limit || 10}` : '';
    let PATH = `${process.env.REACT_APP_MAIN_API}/role_family`

    if (filters) {
        if (filters.level) {
            PATH += `?search=${filters.level}${paginationQuery}`;
        }
        try {
            const response: AxiosResponse<SkillsResponse> = await axios.get(PATH);
            return response.data;
        } catch (error) {
            console.error('Error getting roleFamilies', error);
            return { status: 'ERROR', roleFamilies: [], total_pages: 0 };
        }
    } else {
        return { status: 'ERROR', roleFamilies: [], total_pages: 0 };
    }
}

export default getRoleFamilyByFilters
