import { createSelector } from '@reduxjs/toolkit'

const getUi = (state: any) => state.ui

export const uiSelector = createSelector(getUi, (ui) => ui)

export const loadingSelector = createSelector(getUi, (ui) => ui.loading)

export const errorSelector = createSelector(getUi, (ui) => ui.error)

export const cobrandingLogoSelector = createSelector(
  getUi,
  (ui) => ui.cobrandingLogo
)

export const companyNameSelector = createSelector(
  getUi,
  (ui) => ui.companyName
)

export const isReadyCompetencesSelector = createSelector(
  getUi,
  (ui) => ui.isReadyCompetences
)

export const totalPagesDimensionCompetencePdfSelector = createSelector(
  getUi,
  (ui) => ui.totalPagesDimensionCompetencePdf
)

export const interpretationSelector = createSelector(
  getUi,
  (ui) => ui.interpretation
)
