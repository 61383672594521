import axios from 'axios'
import { IResultsSkills } from 'types'

/**
 * @description service to get an specific skill result by user and test id
 * @param token: string
 * @param testId: Number - id of the test
 * @param userId: Number - id of the user
 */

type Response = {
  data: IResultsSkills
}



const getSkillResult = async (token: string, userId: number, testId: number) => {
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/competence_result/user/${userId}/test/${testId}`

  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }

  try {
    const response: Response = await axios.get(apiUrl, config)
    return response.data
  } catch (error) {
    console.error('Error getting skills results', error)
    return error
  }
}

export default getSkillResult
