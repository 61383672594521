import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "views/components/UI/Modal";
import { H2, P, BoldBlueText } from "views/components/UI/Text";
import { Button } from 'views/components/UI/Buttons';
import { useNotification } from "lib/context/notification.context";
import InputRadio from "views/components/UI/InputRadio/input-radio";
import { Row } from "../styled";
import { platformAdminRoutes } from "router/routes";

interface ModalProps {
    show: boolean;
    setShow: (value: boolean) => void;
    setPriorityQuadrant: (value: string) => void;
    priorityQuadrant: string
    topTwoQuadrantsSelected: any
}

const styleContent: React.CSSProperties = {
    width: '40%',
    padding: '2rem',
}

interface ContentChoiceThePriorityProps {
    topTwoQuadrantsSelected: string[];
    setShow: (value: boolean) => void;
    setPriorityQuadrant: (value: string) => void;
}

const ContentChoiceThePriority = ({topTwoQuadrantsSelected, setPriorityQuadrant,setShow}: ContentChoiceThePriorityProps) => {
    const { getSuccess, getWarning } = useNotification();
    const navigate = useNavigate()

    const [quadrantSelected, setQuadrantSelected] = useState<string>('')

    function handleSelectQuadrant(quadrant: string) {
        if (!quadrant) {
            setQuadrantSelected('')
        } else {
            setQuadrantSelected(quadrant)
        }
    }

    function handleConfirmPriority() {
            if (quadrantSelected || quadrantSelected === '') {
                setPriorityQuadrant(quadrantSelected)
                getSuccess('Se seleccionó el cuadrante ' + quadrantSelected)
                setShow(false)
                navigate(platformAdminRoutes.IdealProfileBetesa, { state: { priorityQuadrant: quadrantSelected }})
            } else {
                getWarning('Debes seleccionar un cuadrante')
                setPriorityQuadrant('')
                setShow(false)
            }
    }

    return (
        <div>
            <H2 textAlign='center'>¿A qué cuadrante deseas darle mayor prioridad?</H2>
            <P margin='16px 0px'>Selecciona el cuadrante al que deseas darle mayor prioridad.</P>
            {
                topTwoQuadrantsSelected.map((quadrant: string) => (
                    <Row key={quadrant}  margin='8px 0px'>
                        <InputRadio 
                            label={`Cuadrante ${quadrant}`}
                            value={quadrant}
                            onChange={() => handleSelectQuadrant(quadrant) }
                            checked={quadrantSelected === quadrant}
                        />
                    </Row>
                ))
            }
            <Row  margin='8px 0px'>
                <InputRadio 
                    label='Ninguno de los anteriores'
                    value=''
                    onChange={() => handleSelectQuadrant('') }
                    checked={quadrantSelected === ''}
                />
             </Row>
            
            <Row justifyContent="flex-end" style={{flex:1, alignItems: 'flex-end', marginTop: '8px'}}>
                <Button disabled={quadrantSelected === undefined} type="button" onClick={handleConfirmPriority} width='100px'>Siguiente</Button>
            </Row>
        </div>
    )
}

interface ContentConfirmPriorityProps {
    priorityQuadrant: string;
    setPriorityQuadrant: (value: string) => void;
    setShow: (value: boolean) => void;
}

const ContentConfirmPriority = ({priorityQuadrant, setShow, setPriorityQuadrant}: ContentConfirmPriorityProps) => {
    const { getSuccess } = useNotification();
    const navigate = useNavigate()

    function handleConfirmPriority() {
            getSuccess('Se seleccionó el cuadrante ' + priorityQuadrant)
            setShow(false)
            navigate(platformAdminRoutes.IdealProfileBetesa, { state: { priorityQuadrant }})

}

    return (
        <>
            <H2 width='100%' textAlign='center'>{`¿Deseas darle mayor prioridad al cuadrante ${priorityQuadrant}?`}</H2>
            <P margin='16px 0px'>{`Asociaste la mayoría de las competencias al cuadrante ${priorityQuadrant}.`}</P>
            <P>{`¿Deseas darle la prioridad #1 al cuadrante ${priorityQuadrant}?`}</P>
            <Row justifyContent="space-between" style={{flex:1, alignItems: 'flex-end', marginTop: '8px'}}>
                <BoldBlueText onClick={() => setPriorityQuadrant('')} style={{marginBottom: '2px'}}>NO</BoldBlueText>
                <Button width='100px' onClick={handleConfirmPriority}>SI</Button>
            </Row>
        </>
    )
}

const ModalPriority = (props: ModalProps) => {
    const { show, setShow, priorityQuadrant, setPriorityQuadrant ,topTwoQuadrantsSelected,} = props;

    return (
        <Modal show={show} setShow={setShow} styleContent={styleContent} >
            {
                priorityQuadrant === '' ? <ContentChoiceThePriority topTwoQuadrantsSelected={topTwoQuadrantsSelected} setShow={setShow}  setPriorityQuadrant={setPriorityQuadrant} /> : <ContentConfirmPriority priorityQuadrant={priorityQuadrant} setShow={setShow}  setPriorityQuadrant={setPriorityQuadrant}/>
            }
        </Modal>
    )

}

export default ModalPriority;