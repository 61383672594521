import { getProcessSelected, isBetesaTestSelector, userTestIdSelector } from 'ducks/evaluatedUser';
import { cobrandingLogoSelector, companyNameSelector } from 'ducks/ui';
import useCompanyLogo from 'hooks/cobranding/get-company-logo/get-company-logo-hook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'views/components/UI/Buttons';
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding';
import ModalLoader from 'views/components/UI/ModalLoader';
import { MainLayout } from 'views/layouts';

import { Checkbox } from '@mui/material';

import {
    fetchInformedConsentUser, fetchUserConsented
} from '../../services/fetch-home-user-evaluated';
import { setBetesaResultId, setCurrentChapter } from '../../store';
import { CallToActionTestDiv, Container, Paragraph, Title } from './styled';

function GeneralTexts() {
  const { t } = useTranslation()
  const { getCompanyLogo } = useCompanyLogo()
  const processSelected = useSelector(getProcessSelected)
  const userTestId = useSelector(userTestIdSelector)
  const isBetesaTest = useSelector(isBetesaTestSelector)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cobradingLogo = useSelector(cobrandingLogoSelector)
  const companyName = useSelector(companyNameSelector)
  const [acceptConsent, setAcceptConsent] = useState<boolean>(false)
  const [alreadyAccepted, setAlreadyAccepted] = useState<boolean>(false)
  const [hasCalledGetLogo, setHasCalledGetLogo] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const updateInformedConsentUser = async (newConsent: boolean) => {
    await fetchInformedConsentUser(userTestId, newConsent)
  }

  useEffect(() => {
    setTimeout(() => {
      if (!hasCalledGetLogo && processSelected.processId) {
        setHasCalledGetLogo(true)
        getCompanyLogo({ processId: processSelected.processId })
      }
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getCompanyLogo, processSelected.processId, hasCalledGetLogo])

  useEffect(() => {
    setIsLoading(true)
    fetchUserConsented(userTestId, isBetesaTest).then((response) => {
      setIsLoading(false)
      if (response?.status === 'success') {
        setAlreadyAccepted(response.data.userTest[0].informed_consent)
        if (response.data.betesaResult) {
          const completeChapter: number =
            response.data.betesaResult.completed_chapter === 'N/A' ? 0 : response.data.betesaResult.completed_chapter
          const betesaResultsId = response.data.betesaResult.id
          dispatch(setBetesaResultId(betesaResultsId))
          dispatch(setCurrentChapter(completeChapter + 1))
          if (response.data.userTest[0]?.informed_consent)
            navigate(isBetesaTest ? '/test/test-talents' : '/test/test-skills')
        } else {
          dispatch(setCurrentChapter(1))
        }
      }
    })
  }, [userTestId, isBetesaTest, dispatch, navigate])

  useEffect(() => {
    if (alreadyAccepted && processSelected) {
      const route = processSelected.testType === 'Competencias' ? '/test/test-skills' : '/test/test-talents'
      navigate(route)
    }
  }, [alreadyAccepted, navigate, processSelected])

  const handleClick = () => {
    if (!acceptConsent) {
      return
    }
    updateInformedConsentUser(acceptConsent)
    const route = processSelected.testType === 'Competencias' ? '/test/test-skills' : '/test/test-talents'

    navigate(route)
  }

  return (
    <MainLayout cobrandingLogoUrl={cobradingLogo}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {cobradingLogo && <CobrandingLogo logoUrl={cobradingLogo} hideMobile />}
      </div>
      <Container style={{ paddingBottom: '7%' }}>
        <Title>{t('test.startTestInfo.introduction.title')}</Title>
        <Paragraph dangerouslySetInnerHTML={{ __html: t('test.startTestInfo.introduction.text', { companyName }) }} />

        <Title>{t('test.startTestInfo.informedConsent.title')}</Title>
        <Paragraph dangerouslySetInnerHTML={{ __html: t('test.startTestInfo.informedConsent.text',{ companyName }) }} />

        <CallToActionTestDiv>
          <div style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'row', gap: 5, marginLeft: 0 }}>
            <Checkbox
              value={acceptConsent}
              onClick={() => {
                const newConsent = !acceptConsent
                setAcceptConsent(newConsent)
              }}
            />
            <Paragraph style={{ width: '100%' }}>{t('test.startTestInfo.acceptConsent')}</Paragraph>
          </div>
          <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button disabled={!acceptConsent} onClick={handleClick}>
              Siguiente
            </Button>
          </div>
        </CallToActionTestDiv>
      </Container>
      <ModalLoader isLoading={isLoading} />
    </MainLayout>
  )
}

export default GeneralTexts
