import styled from 'styled-components'

import imageBackground from '../../../assets/images/cute-girl-login.png'

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const ContainerForm = styled.div`
  padding-top: 2rem;
  width: 60%;
  height: 120vh;
  background-color: '#FAFCFF';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContainerImage = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${imageBackground});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    display: none;
    width: 0;
  }
  
`
