import axios from 'axios'

interface Company {
  company: CompanyDetails;
} 

interface CompanyDetails {
  id: number;
  name: string;
}

interface IFindUserResponse  {
  status: string;
  data: Company;
}

interface IData  {
  name?: string;
  operativeId?: number;
  typeCompanyId?: number;
  contactName?: string;
  contactEmail?: string;
  phone?: string;
  logo?: Blob | null;
  hasIntegration?: string;
}

/**
 * @description service to edit a company
 * @param data
 * @param idCompany
 */
const editCompany = async ( data: IData, idCompany: number) => {

  const { name, typeCompanyId, contactName, contactEmail, phone, operativeId, logo, hasIntegration } = data

  const body = new FormData()
    if (name) body.append('name', name)
    if (typeCompanyId) body.append('type_company_id', typeCompanyId.toString())
    if (contactName) body.append('contact_name', contactName)
    if (contactEmail) body.append('contact_email', contactEmail)
    if (phone) body.append('phone', phone)
    if (operativeId) body.append('operative_id', operativeId.toString())
    if (logo) body.append('logo', logo, 'logo.png')
    if (hasIntegration) body.append('has_integration', hasIntegration)


  const res: IFindUserResponse = await axios.put(`/company/${idCompany}`, body).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res
}

export default editCompany