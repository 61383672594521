import { useNameLevelAlert } from 'hooks/useNameLevelAlert';
import { useNameQuadrant } from 'hooks/useNameQuadrant';
import {
  ContainerTable,
  Subtitle,
  HeadingTable,
  TableCell,
  InnerTable,
  InnerTableCell,
  TopInnerTableRow,
  BottomInnerTableRow,
  CustomTableCell,
} from '../../styled';
import {
  ContainerT,
  PreviewTable,
} from './first-page/styled';
const TableResult = ({ domination, alertlevel, QuadrantGlobal, Stressors, emotionalText,/*  positiveValue, negativeValue */ }: any) => {
  // const isBothValuesZero = negativeValue === 0 && positiveValue === 0;
  // const greaterValue = positiveValue > negativeValue ? positiveValue : negativeValue;
  const parsedLevel = useNameLevelAlert(alertlevel)
  const parsedQuadrant = useNameQuadrant(domination)

  return (
    <ContainerT>
      <ContainerTable>
        <Subtitle>Resumen de Resultados</Subtitle>

        <PreviewTable style={{ textAlign: 'center' }}>
          <tbody>
            <tr>
              <HeadingTable>Estructura de Pensamiento</HeadingTable><TableCell>{`${parsedQuadrant}`}</TableCell>
            </tr>

            <tr>
              <HeadingTable>Puntajes de Desarrollo</HeadingTable>
              <CustomTableCell >
                <InnerTable style={{
                  borderLeftStyle: 'hidden',
                  borderRightStyle: 'hidden'
                }}>
                  <TopInnerTableRow>
                    <InnerTableCell>FI</InnerTableCell>
                    <InnerTableCell>BI</InnerTableCell>
                    <InnerTableCell>BD</InnerTableCell>
                    <InnerTableCell>FD</InnerTableCell>
                  </TopInnerTableRow>
                  <BottomInnerTableRow>
                    <InnerTableCell>{QuadrantGlobal?.FI}</InnerTableCell>
                    <InnerTableCell>{QuadrantGlobal?.BI}</InnerTableCell>
                    <InnerTableCell>{QuadrantGlobal?.BD}</InnerTableCell>
                    <InnerTableCell>{QuadrantGlobal?.FD}</InnerTableCell>
                  </BottomInnerTableRow>
                </InnerTable>
              </CustomTableCell>
            </tr>

            <tr>
              <HeadingTable>Nivel de Alerta</HeadingTable><TableCell>{`${parsedLevel}`}</TableCell>
            </tr>

            <tr>
              <HeadingTable>Estado Emocional</HeadingTable><TableCell style={{ textTransform: 'capitalize' }}>{emotionalText}</TableCell>
            </tr>

            <tr>
              <HeadingTable>Exposición a Estresores</HeadingTable><TableCell>{`${Stressors}/100`}</TableCell>
            </tr>
          </tbody>
        </PreviewTable>
      </ContainerTable >
    </ContainerT>
  )
}

export default TableResult
