/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  AddUsersButton,
  UsersInputsContainer,
  UsersModalText,
  UsersModalTitle,
  ResetButton,
  DeleteButtonContainer,
  DeleteButtonIcon,
  DeleteButtonText,
  UsersGridContainer,
  StandardButton,
  ButtonContainer,
  ButtonDisabled,
  SearchButton,
  MagnifyingGlass,
} from './styled'
import Modal from 'views/components/UI/Modal'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import getNineBoxUsers from 'services/competence/getNineBoxUsers'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { INineBoxUsersResponse, RNineBox, User } from 'views/screens/Platform-administrator/Analytics/constants/types'
import { useNotification } from 'lib/context/notification.context'

import IconDeleteRed from '../../assets/iconDeleteRed.png'
import { TextField } from '@mui/material'
import { BackButton } from 'views/components/UI/Buttons'

interface IProps {
  show: boolean
  close: (value: boolean) => void
  setSelectedUsers: (value: User[]) => void
  showEvaluatedModal: (value: boolean) => void
  setNineBoxCareer: (value: boolean) => void
  cleanSession: () => void
  processId: string | undefined
  historyAddedUsers: number[]
  sessionData: RNineBox
  fullObjectSavedUsers: User[]
}

const headers = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'full_name',
    name: 'Nombre',
  },
  {
    key: 'button',
    name: '',
  },
]

const headersAddedUsers = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'full_name',
    name: 'Nombre',
  },
  {
    key: 'button',
    name: '',
  },
]

const CareerUsersModal: FC<IProps> = ({
  show,
  close,
  processId,
  setSelectedUsers,
  showEvaluatedModal,
  setNineBoxCareer,
  cleanSession,
  sessionData,
  fullObjectSavedUsers,
}) => {
  const { getError } = useNotification()

  const [users, setUsers] = useState<INineBoxUsersResponse>({} as INineBoxUsersResponse)
  const [page, setPage] = useState(1)
  const [inputs, setInputs] = useState({
    document: '',
    email: '',
    name: '',
  })
  const [inputsAddedUsers, setInputsAddedUsers] = useState({
    document: '',
    email: '',
    full_name: '',
  })
  const [searchCriteria, setSearchCriteria] = useState({
    document: '',
    email: '',
    name: '',
  })
  const [searchCriteriaAddedUsers, setSearchCriteriaAddedUsers] = useState({
    document: '',
    email: '',
    full_name: '',
  })
  const [addedUsers, setAddedUsers] = useState<User[]>([] as User[])
  const [addedUsersPage, setAddedUsersPage] = useState(1)
  const usersAddedPerPage = 5

  const limit = 5

  useEffect(() => {
    if (users?.users && (fullObjectSavedUsers.length > 0 || sessionData?.users?.length > 0)) {
      if (fullObjectSavedUsers.length > 0) {
        setAddedUsers(fullObjectSavedUsers)
      } else if (sessionData?.users?.length > 0) {
        const added = users.users.filter((user: User) => sessionData?.users.includes(user.user_id))
        setAddedUsers(added)
      }
    }
  }, [fullObjectSavedUsers, sessionData])

  useEffect(() => {
    if (!processId) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const filteredCriteria = Object.fromEntries(Object.entries(searchCriteria).filter(([_, v]) => v !== ''))

    const body = {
      process_id: Number(processId),
      ...filteredCriteria,
    }

    getNineBoxUsers(body, limit, page).then(
      (response) => {
        if (response) {
          setUsers(response)
        } else {
          setUsers({} as INineBoxUsersResponse)
        }
      },
      (error) => {
        console.error('Error cargando los usuarios: ', error)
        getError('Ha ocurrido un error al cargar los usuarios')
      }
    )
  }, [processId, page, searchCriteria])

  const handleAddUser = (user: User) => {
    setAddedUsers((prevState) => {
      const userExists = prevState.some((existingUser) => existingUser.user_id === user.user_id)
      if (userExists) {
        return prevState
      } else {
        return [...prevState, user]
      }
    })
  }

  const handleDeleteUser = (user: User) => {
    setAddedUsers((prevState) => prevState.filter((item) => item.user_id !== user.user_id))
  }

  const Button = ({ user }: { user: User }) => {
    const added = addedUsers.some((addedUser) => addedUser.user_id === user.user_id)
    return (
      <AddUsersButton disabled={added} onClick={() => handleAddUser(user)}>
        Agregar
      </AddUsersButton>
    )
  }

  const DeleteButton = ({ user }: { user: User }) => {
    return (
      <DeleteButtonContainer onClick={() => handleDeleteUser(user)}>
        <DeleteButtonIcon src={IconDeleteRed} />
        <DeleteButtonText>Eliminar</DeleteButtonText>
      </DeleteButtonContainer>
    )
  }

  const dataArray = users?.users?.map((item) => ({
    user_id: item.user_id,
    full_name: item.full_name,
    document: item.document,
    email: item.email,
    button: <Button user={item} />,
  }))

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleInputChangeAddedUsers = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputsAddedUsers((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearchUsers = () => {
    if (!inputs.document.trim() && !inputs.email.trim() && !inputs.name.trim()) return

    setPage(1)

    setSearchCriteria({
      document: inputs.document,
      email: inputs.email,
      name: inputs.name,
    })
  }

  const handleResetSearch = () => {
    setInputs({
      document: '',
      email: '',
      name: '',
    })
    setSearchCriteria({
      document: '',
      email: '',
      name: '',
    })
  }

  const handleSearchAddedUsers = () => {
    if (!inputsAddedUsers.document.trim() && !inputsAddedUsers.email.trim() && !inputsAddedUsers.full_name.trim())
      return

    setAddedUsersPage(1)

    setSearchCriteriaAddedUsers({
      document: inputsAddedUsers.document,
      email: inputsAddedUsers.email,
      full_name: inputsAddedUsers.full_name,
    })
  }

  const handleResetSearchAddedUsers = () => {
    setInputsAddedUsers({
      document: '',
      email: '',
      full_name: '',
    })
    setSearchCriteriaAddedUsers({
      document: '',
      email: '',
      full_name: '',
    })
    setAddedUsersPage(1)
  }

  const handleSaveUsers = () => {
    setSelectedUsers(addedUsers)
    close(false)
    showEvaluatedModal(true)
  }

  const filteredUsers = addedUsers.filter((user) => {
    return Object.entries(searchCriteriaAddedUsers).every(([key, value]) => {
      if (!value) return true
      return (
        user[key as keyof User] &&
        String(user[key as keyof User])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    })
  })

  const indexOfLastUser = addedUsersPage * usersAddedPerPage
  const indexOfFirstUser = indexOfLastUser - usersAddedPerPage
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser)

  const dataUsersAdded = currentUsers.map((user) => ({
    ...user,
    button: <DeleteButton user={user} />,
  }))

  const handleGoBack = () => {
    setNineBoxCareer(false)
    cleanSession()
    close(false)
  }

  return (
    <Modal styleContent={{ minWidth: '80%', minHeight: '90%' }} show={show} setShow={close}>
      <UsersModalTitle>Usuarios</UsersModalTitle>
      <UsersInputsContainer>
        <UsersModalText style={{ marginRight: '30px' }}>Buscar por:</UsersModalText>
        <TextField
          type="number"
          value={inputs.document}
          name="document"
          onChange={handleInputChange}
          label="Número de documento"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchUsers()
            }
          }}
        />
        <TextField
          type="text"
          value={inputs.email}
          name="email"
          onChange={handleInputChange}
          label="Email"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchUsers()
            }
          }}
        />
        <TextField
          type="text"
          value={inputs.name}
          name="name"
          onChange={handleInputChange}
          label="Nombre"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchUsers()
            }
          }}
        />
        <SearchButton onClick={handleSearchUsers} style={{ fontSize: 14 }}>
          <MagnifyingGlass src={IconMagnifying} />
        </SearchButton>

        {searchCriteria.document || searchCriteria.email || searchCriteria.name ? (
          <ResetButton onClick={handleResetSearch}>
            <HighlightOffIcon />
          </ResetButton>
        ) : null}
      </UsersInputsContainer>
      <UsersGridContainer>
        <BluesiteTableComponent
          data={dataArray}
          totalPages={users?.total_pages}
          headers={headers}
          searchBar={false}
          handlePagination={setPage}
          currentPage={page}
          searchLabel=""
        />
      </UsersGridContainer>
      <UsersModalTitle style={{ marginTop: '-40px' }}>Usuarios agregados</UsersModalTitle>
      <UsersInputsContainer>
        <UsersModalText style={{ marginRight: '30px' }}>Buscar por:</UsersModalText>
        <TextField
          type="number"
          value={inputsAddedUsers.document}
          name="document"
          onChange={handleInputChangeAddedUsers}
          label="Número de documento"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchAddedUsers()
            }
          }}
        />
        <TextField
          type="text"
          value={inputsAddedUsers.email}
          name="email"
          onChange={handleInputChangeAddedUsers}
          label="Email"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchAddedUsers()
            }
          }}
        />
        <TextField
          type="text"
          value={inputsAddedUsers.full_name}
          name="full_name"
          onChange={handleInputChangeAddedUsers}
          label="Nombre"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchAddedUsers()
            }
          }}
        />
        <SearchButton onClick={handleSearchAddedUsers} style={{ fontSize: 14 }}>
          <MagnifyingGlass src={IconMagnifying} />
        </SearchButton>

        {inputsAddedUsers.document || inputsAddedUsers.email || inputsAddedUsers.full_name ? (
          <ResetButton onClick={handleResetSearchAddedUsers}>
            <HighlightOffIcon />
          </ResetButton>
        ) : null}
      </UsersInputsContainer>
      <UsersGridContainer>
        <BluesiteTableComponent
          data={dataUsersAdded}
          totalPages={Math.ceil(filteredUsers.length / usersAddedPerPage)}
          headers={headersAddedUsers}
          searchBar={false}
          handlePagination={setAddedUsersPage}
          currentPage={addedUsersPage}
          searchLabel=""
        />
      </UsersGridContainer>
      <ButtonContainer style={{ justifyContent: 'space-between' }}>
        <BackButton style={{ marginLeft: '30px' }} onClick={handleGoBack} />
        {addedUsers?.length ? (
          <StandardButton style={{ marginBottom: '30px' }} onClick={handleSaveUsers}>
            Siguiente
          </StandardButton>
        ) : (
          <ButtonDisabled style={{ marginBottom: '30px' }}>Siguiente</ButtonDisabled>
        )}
      </ButtonContainer>
    </Modal>
  )
}

export default CareerUsersModal
