import { AxiosResponse } from 'axios';
import axios from 'lib/axios';
import { Admin } from 'types/common'

interface GetAdminsResponse {
  status: 'OK' | 'ERROR';
  error?: any;
  data: Admin[];
}

async function getAdminsByCompany(companyId: number): Promise<GetAdminsResponse> {
  const PATH = `/company/admins/${companyId}`;
  try {
    const response: AxiosResponse<GetAdminsResponse> = await axios.get(PATH);
    return response.data;
  } catch (error) {
    console.error('Error getting admins by company', error);
    return { status: 'ERROR', error: error, data: [] };
  }
}

export default getAdminsByCompany;
