import axios from 'lib/axios'
import { UPDATE_INVENTORY } from './constants'

export const fetchEditInventoryStatus = async (subscription_company_id: number, data: any) => {
  const responseData = await axios
    .put(`${UPDATE_INVENTORY}/${subscription_company_id}`, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })

  return responseData
}
