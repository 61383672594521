import axios from 'axios'

export const fetchBetesaChapter = async (user_test_id: number) => {
  const url = `/betesa_result/next_chapter/${user_test_id}`
  try {
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}
