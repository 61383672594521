import { FC } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import TitleDiv from 'views/components/UI/TitleDiv'
import {
  Card,
  ModulesContainer,
  CardDescriptionSection
}
 from './styled'
const Home: FC = () => {
  const navigate = useNavigate()
  return (
        <MainLayout>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>Configuración de textos de Betesa Premium corporativo</h2>
          </TitleDiv>
            <ModulesContainer>
              <Link to='/modules/talents/texts/generals'>
                <Card>
                  <CardDescriptionSection>
                    <span style={{ fontWeight: 'bold', color:'#1F2124', fontSize:20 }}>Generales</span>
                  </CardDescriptionSection>
                </Card>
              </Link>
              <Link to='/modules/talents/texts/alert-level'>
                <Card>
                  <CardDescriptionSection>
                    <span style={{ fontWeight: 'bold' , color:'#1F2124', fontSize:20 }}>Nivel de alerta</span>
                  </CardDescriptionSection>
                </Card>
              </Link>
              <Link to='/modules/talents/texts/quadrant'>
                <Card onClick={()=>{navigate('/modules/talents/texts/quadrant')}}>
                  <CardDescriptionSection>
                    <span style={{ fontWeight: 'bold', color:'#1F2124', fontSize:20 }}>Textos por cuadrante</span>
                  </CardDescriptionSection>
                </Card>
              </Link>
            </ModulesContainer>
        </MainLayout>
  )}
export default Home
