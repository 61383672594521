import { createSlice } from '@reduxjs/toolkit'
import { Company } from './entities'

export const initialState = {
  companyId: 0,
}

export type InitialState = typeof initialState


const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyId(state: Company, action) {
      state.companyId = action.payload
    },
  },
})

export const { setCompanyId, } = companySlice.actions


export const companyReducer = companySlice.reducer
