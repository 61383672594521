import axios from 'lib/axios'
import { AddUsersToTeamMapBody } from '../Entities'

export const fetchAddUsersTeamMap = async (body: AddUsersToTeamMapBody) => {
  try {
    const response = await axios.post('/team_map/add_users', body)
    return response.data
  } catch (error) {
    throw new Error('Error creating new team map')
  }
}

