import { useSelector } from 'react-redux'
import { Cabecera } from '../styled'
import styles from './header.module.css'

import HeaderCobrandingLogo from 'views/components/UI/Logo/logo-cobranding-header'
import { cobrandingLogoSelector } from 'ducks/ui'
import { SKILLS_HEADER, TALENTS_HEADER } from '../constants'

interface Props {
  isBetesa?: boolean
  marginTop?: number
}

const Headpage = ({ isBetesa, marginTop }: Props) => {
  const cobradingLogo = useSelector(cobrandingLogoSelector)

  return (
    <Cabecera style={{ marginTop: `${marginTop}px` }}>
      <div className="title_header" style={{ width: !isBetesa ? '70%' : '' }}>
        {isBetesa ? <p>{TALENTS_HEADER}</p> : <p>{SKILLS_HEADER}</p>}
      </div>
      <div className={styles.logoHeader}>
        <HeaderCobrandingLogo logoUrl={cobradingLogo || ''} />
      </div>
    </Cabecera>
  )
}

export default Headpage
