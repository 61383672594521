import axios from 'lib/axios'

interface Iprops {
    first_name: string,
    last_name: string,
    document: string,
    email: string,
    document_type: string,
    role_type: 1 | 2 | 3 | 4,
    company_id?: number,
    birth_date?: string,
    no_send_email?: boolean,
}
 
export default async function createUser (props: Iprops) {
  
  const { document, first_name: firstName, last_name: lastName, document_type: documentType, role_type: roleType, company_id: companyId, birth_date: birthDate, email, no_send_email: noSendEmail} = props

  const path = `/person`
    
  const body: any = {
        "first_name": firstName,
        "last_name": lastName,
        "document": document,
        "email": email,
        "document_type": documentType,
        "role_type": roleType
    }

    if (companyId) {
        body.company_id = companyId
    }

    if (birthDate) {
        body.birth_date = birthDate
    }

    if (noSendEmail === true) {
        body.no_send_email = noSendEmail
    }

    try {
      const response = await axios.post(path, body);
      return response.data;
    } catch (error: any) {
      return {status: 'error', message: error.response.data.message, error: error}
    }
}
