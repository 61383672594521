import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { InitialState, CleanColorOptionsAction, Item, AnsweredQuestion } from './entities';
import { RootState } from 'lib/redux'

const initialState: InitialState = {
  currentQuestion: 0,
  dataQuestion: {},
  testStarted: false,
  showChapterInfo: true,
  previousAnswers: {},
  currentChapter: 0,
  dataToSend: {},
  chapter: 0,
  dropped: {},
  question: {},
  optionQuestion: {},
  multipleOptionsQuestion: {},
  currentSelectedOption: 0,
  allSelectedOptions: {},
  totalQuestionsPerChapter: 0,
  totalChapters: 0,
  betesaResultId: 0,
  colorOptions: {},
  answeredQuestions: [] as AnsweredQuestion[],
}

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload
    },
    setDataQuestion: (state, action) => {
      state.dataQuestion = action.payload
    },
    setQuestion: (state, action) => {
      state.question = action.payload
    },
    setOptionQuestion: (state, action) => {
      state.optionQuestion = action.payload
    },
    setMultipleOptionsQuestion: (state, action) => {
      state.multipleOptionsQuestion = action.payload
    },
    setTotalQuestionsPerChapter: (state, action) => {
      state.totalQuestionsPerChapter = action.payload
    },
    setCurrentSelectedOption: (state, action) => {
      state.currentSelectedOption = action.payload
    },
    setAllSelectedOptions: (state, action) => {
      state.allSelectedOptions = action.payload
    },
    setTestStarted: (state, action) => {
      state.testStarted = action.payload
    },
    setShowChapterInfo: (state, action) => {
      state.showChapterInfo = action.payload
    },
    setPreviousAnswers: (state, action) => {
      state.previousAnswers = action.payload
    },
    setCurrentChapter: (state, action) => {
      state.currentChapter = action.payload
    },
    setTotalChapters: (state, action) => {
      state.totalChapters = action.payload
    },
    setDataToSend: (state, action) => {
      state.dataToSend = action.payload
    },
    setChapter: (state, action) => {
      state.chapter = action.payload
    },
    setDropped: (state, action) => {
      state.dropped = action.payload
    },
    setBetesaResultId: (state, action) => {
      state.betesaResultId = action.payload
    },
    setColorOptions: (state, action) => {
      state.colorOptions = action.payload
    },
    setAnsweredQuestions: (state, action) => {
      state.answeredQuestions = action.payload
    },
    cleanPreviousAnswers: (state) => {
      state.previousAnswers = {}
    },
    cleanDataToSend: (state) => {
      state.dataToSend = {}
    },
    cleanAllSelectedOptions: (state) => {
      state.allSelectedOptions = {}
    },
    cleanColorOptions: (state, action: PayloadAction<CleanColorOptionsAction | undefined>) => {
      const { payload } = action
      if (payload) {
        const { id, item }: { id: string; item: Item } = payload
        if (id && item && state.colorOptions[id]) {
          state.colorOptions[id] = state.colorOptions[id].filter((option) => {
            return (
              option.columnOne !== item.columnOne ||
              (option.columnTwo && item.columnTwo && option.columnTwo.id !== item.columnTwo?.id)
            )
          })
        }
      } else {
        state.colorOptions = {}
      }
    },
  },
})

export const {
  setCurrentQuestion,
  setDataQuestion,
  setQuestion,
  setOptionQuestion,
  setTotalQuestionsPerChapter,
  setTestStarted,
  setShowChapterInfo,
  setPreviousAnswers,
  setCurrentChapter,
  setTotalChapters,
  setDataToSend,
  setChapter,
  setDropped,
  setCurrentSelectedOption,
  setAllSelectedOptions,
  setMultipleOptionsQuestion,
  setBetesaResultId,
  setAnsweredQuestions,
  cleanPreviousAnswers,
  cleanDataToSend,
  cleanAllSelectedOptions,
  setColorOptions,
  cleanColorOptions,
} = questionsSlice.actions

export const selectCurrentQuestion = (state: any) => state.questions?.currentQuestion
export const selectDataQuestion = (state: any) => state.questions?.dataQuestion
export const selectQuestion = (state: any) => state.questions?.question
export const selectOptionQuestion = (state: any) => state.questions?.optionQuestion
export const selectTestStarted = (state: any) => state.questions?.testStarted
export const selectShowChapterInfo = (state: any) => state.questions?.showChapterInfo
export const selectPreviousAnswers = (state: any) => state.questions?.previousAnswers
export const selectCurrentChapter = (state: RootState) => state.questions.currentChapter
export const selectTotalChapters = (state: any) => state.questions?.totalChapters
export const selectDataToSend = (state: any) => state.questions?.dayaToSend
export const selectChapter = (state: any) => state.questions?.chapter
export const selectDropped = (state: any) => state.questions?.dropped
export const selectTotalQuestionsPerChapter = (state: any) => state.questions?.totalQuestionsPerChapter
export const selectCurrentSelecetedOption = (state: any) => state.questions?.currentSelectedOption
export const selectAllSelecetedOptions = (state: any) => state.questions?.allSelectedOptions
export const selectMultipleOptionsQuestion = (state: any) => state.questions?.multipleOptionsQuestion
export const selectCleanPreviousAnswers = (state: any) => state.questions?.cleanPreviousAnswers
export const selectCleanDataToSend = (state: any) => state.questions?.cleanDataToSend
export const selectBetesaResultId = (state: any) => state.questions?.betesaResultId
export const selectColorOptions = (state: any) => state.questions?.colorOptions
export const selectCleanColorOptions = (state: any) => state.questions?.cleanColorOptions
export const betesaResultIdSelector = (state: RootState) => state.questions.betesaResultId
export const answeredQuestionsSelector = (state: RootState) => state.questions.answeredQuestions

export const questionReducer = questionsSlice.reducer
