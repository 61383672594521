import styled, { StyledComponent } from 'styled-components'

export const ContainerActionItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin: 0;
  width: 100%;
`

interface ActionWrapperProps {
  isDisable: boolean
}

interface ActionWrapperProps {
  isDisable: boolean
}

const Action = styled.span<ActionWrapperProps>`
  font-weight: 700;
  color: #c6016b;
  font-size: 14px;
  margin-left: 10px;
`

export const InactiveAction: StyledComponent<'span', any, ActionWrapperProps> = styled(Action)`
  color: ${(props) => (props.isDisable ? '#C2C2C2' : '#C6016B')};
`

export const ActivateAction: StyledComponent<'span', any, ActionWrapperProps> = styled(Action)`
  color: ${(props) => (props.isDisable ? '#C2C2C2' : '#00A7E1')};
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  margin: 0;
  width: 100%;
`

export const PendingAction: StyledComponent<'span', any, ActionWrapperProps> = styled(Action)`
  color: ${(props) => (props.isDisable ? '#C2C2C2' : '#C2C2C2')};
  cursor: default;
`
