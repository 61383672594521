import styled from 'styled-components';


const Container = styled.div`
    cursor: pointer;
`;

interface IProps {
    width?: string;
    height?: string;
    onClick?: () => void;
}

const PolarAreaIcon = (props: IProps) => {
    const { width, height, onClick } = props;


    return (
        <Container onClick={onClick}>
            <svg width={width || '40px'} height={height || '40px'} viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.2194 30.2982C35.9755 26.6152 38.4361 21.3046 38.4902 15.6309L17.7149 15.6309L17.6167 36.1772C23.2964 36.2313 28.4611 33.9813 32.2194 30.2982Z" fill="#C6016B" fillOpacity="0.5" />
                <path opacity="0.8" d="M5.15618 27.9071C8.32037 31.0427 12.8644 33.0601 17.6692 33.0369L17.6765 15.5311L0.000768849 15.6141C0.0240447 20.4238 1.99197 24.7696 5.15618 27.9071Z" fill="#D0CFCF" />
                <path opacity="0.8" d="M9.91036 7.81416C7.91491 9.7598 6.66546 12.6336 6.63672 15.6309L17.6789 15.5625L17.7258 4.65102C14.7084 4.62247 11.907 5.86852 9.91036 7.81416Z" fill="#E8E8E8" />
                <path opacity="0.8" d="M27.9991 5.41468C25.3755 2.81468 21.6076 1.0656 17.6237 1.0848L17.6176 15.6765L32.2739 15.6077C32.2546 11.6196 30.6228 8.01621 27.9991 5.41468Z" fill="#D0CFCF" />
            </svg>

        </Container>
    );
};

export default PolarAreaIcon;


