import React from "react";
import MainLayout from "views/layouts/Main";

// Components


const Index = () => {
    return (
        <MainLayout>
        <div>Skills</div>
        </MainLayout>
    );
    };

export default Index;