import styled from 'styled-components'

export const Container = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: column; 
  gap: 16px;
  img{
    width: 128px;
    height: 128px;
  }
  @media (min-width:992px) {
    padding-top: 20px;
  }
  @media (max-width:992px) {
    padding-top: 10px;
    margin-top:0;
  }
`
export const NotFoundDiv = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin:0 auto;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  background: #C6016B;
  color: white;
  gap:5px;
  font-weight: bold;
  border: none;
  padding:11px 22px;
  border-radius: 8px;
  margin-top:10px;
  width: 110px;
  height: 48px;
`

export const NotFoundLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #FAFCFF;
`
