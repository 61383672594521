import styled from 'styled-components'

export const NewContainer = styled.div`
  display:flex;
  flex-direction: column;
  gap:30px;
  padding-top: 40px;
  padding-bottom: 40px;
`
export const Title = styled.h2`
  font-size: 32px;
  align-self: flex-start;
`

export const NewContainerItem = styled.div`
  display:flex;
  flex-direction: column;
  gap:15px;
`
export const NewContainerTittle = styled.label`
  font-weight: bold;
  padding-top: 20px;
`
export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`
export const NewContainerItemRow = styled.div`
  display:flex;
  flex-direction: row;
  gap:15px;
  padding-top: 30px;
  padding-bottom: 30px;
`