import axios from 'lib/axios'
import { GET_SKILLS_TABLE, GET_GROUP } from '../constants'

interface IFilters {
  dimension?: number
  group?: number
  competence?: number
  result?: number
  level?: number
}

export const fetchGetSkillTableAction = async (page_number: number, filters?: IFilters) => {
  let PATH = `${GET_SKILLS_TABLE}?page=${page_number}`

  if (filters) {
    if (filters.group) {
      PATH += `&group_id=${filters.group}`
    }
    if (filters.dimension) {
      PATH += `&dimension_id=${filters.dimension}`
    }
    if (filters.competence) {
      PATH += `&competence_id=${filters.competence}`
    }
    if (filters.result) {
      PATH += `&result=${filters.result}`
    }
    if (filters.level) {
      PATH += `&level_id=${filters.level}`
    }
  }
  try {
    const response = await axios.get(PATH)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}

export const fetchGetGroupTextAction = async () => {
  try {
    const response = await axios.get(`${GET_GROUP}`)
    return response.data.group
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}
