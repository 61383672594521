import axios from "lib/axios";

type IProcess = {
    process_name: string
    process_id: number
}

type Data = {
    processes: IProcess[]
}

interface IResponse  {
    status: string | 'success' | 'OK' | 'error'
    data: Data
}

const getProcessByCompany = async ( companyId: number,  platform_admin_id?: any) => {
  let apiUrl = `/company/process/${companyId}?sent=true`;

  if (platform_admin_id) {
    apiUrl += `&platform_admin_id=${platform_admin_id}`;
  }
  try {
    const response: IResponse = await axios.get(apiUrl).then((res) => {
      return res.data
    }).catch((err) => {
      console.error('error on get process by company service', err)
    })
    return response
  } catch (error) {
    console.error('error on get process by company services', error)
  }
}

export default getProcessByCompany
