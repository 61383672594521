import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 1340px) {
    text-align: center;
  }

  H2 {
    font-size: 1.5rem;

    font-weight: 700;
    color: #2d749c;
  }
`

export const ErrorIcon = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
`

export const ErrorContainer = styled.div`
  margin-top: 60px;
`
