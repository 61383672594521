import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 0;
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 20px 0px;
    z-index: 2;
`;

export const ModalCloseButtonContainer = styled.div`
    display: flex;
    width: 98%;
    justify-content: flex-end;
    margin-bottom: 10px;
    z-index: 2;
`;

export const ModalBottomNavigationContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    z-index: 2;
`;

export const DescriptionText = styled.p`
    font-family: 'Public Sans', sans-serif;
    color: #1F2124;
`

export const SubtitleText = styled.p`
    font-family: 'Public Sans', sans-serif;
    color: #1F2124;
    font-size: 16px;
    font-weight: 600;
    margin: 6px 0px;
    
`
export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const NavigationText = styled.p`
    font-family: 'Public Sans', sans-serif;
    color: #2D749C;
    font-size: 16px;
    font-weight: 600;
    margin: 2px 6px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;