import styled from 'styled-components'

export const CardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead th {
    text-align: center;
    background: #e6eef3;
    border: 1px solid #919eab;
    border-left: none;
  }
  thead th:nth-child(5) {
    background: #e6eef3;
    border: 1px solid #919eab;
    border-right: none;
  }
  thead th:nth-child(1) {
    background: #e6eef3;
    text-align: left;
    padding-left: 3%;
    width: 35%;
    border: 1px solid #919eab;
    border-left: none;
  }
  tbody th {
    background: #e6eef3;
    text-align: left;
    padding-left: 3%;
    width: 35%;
    border: 1px solid #919eab;
    border-left: none;
    border-bottom: none;
  }
  tbody td {
    padding: 0.5% 1.4%;
    text-align: center;
    border: 1px solid #919eab;
    border-bottom: none;
  }
  tbody td:nth-child(5) {
    padding: 0;
    margin: 0;
    text-align: center;
    border: 1px solid #919eab;
    border-right: none;
  }

  @media (max-width: 1024px) {
    tbody td {
      padding: 0.4rem 0.3rem;
    }
  }
`

export const CellWithRowGroup = styled.td`
  text-align: center;
  border: 1px solid #919eab;
  border-bottom: none;
  :nth-child(4) {
    text-align: center;
    border: 1px solid #919eab;
    border-right: none;
    border-bottom: none;
  }
`
