import axios from 'axios'
 const FetchPercentils = async (token: any, userId:number, testId:number) => {
  const apiUrl =`${process.env.REACT_APP_MAIN_API}/percentiles/${userId}?report=true&byCompetence=true&test_id=${testId}`

  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }

  try {
      const dataResp =  await axios.get(
      `${apiUrl}`,
      config
    ).then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
    return dataResp
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

export default FetchPercentils 
