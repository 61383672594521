import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 16px;
  width: calc(100% - 112px);
  max-width: 595px;
  padding: 0 56px;
`

export const TitleGroup = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #c6016b;
  letter-spacing: -0.05em;
  margin-left: 24px;
  margin: 8px 0;
  text-align: center;
  line-height: 1;
`

export const WrapperSubtitles = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Subtitle = styled.span`
  font-family: 'Spartan-SemiBold';
  letter-spacing: -0.05em;
  color: #2d749c;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
`;

export const ScoreTable = styled.table`
  width: 100%;
  align-self: center;
`;

export const ThText = styled.th`
  width: 30%;
  text-align: left;
  font-family: 'Lato-Regular';
  font-size: 12px;
`;

export const TdWrapper = styled.td`
  padding: 4px 0 4px 8px !important;
`
