import { useState, useEffect } from 'react';
import { Question, Option} from '../../store/entities';

export const getCurrentChapterTypeQuestion = (dataQuestion: any, currentChapterNumber: number | null): string | null => {
    if (!dataQuestion || !currentChapterNumber) return null

    const questions = dataQuestion[`chapter_${currentChapterNumber}`] || []
    const firstQuestion = questions[0]
    return firstQuestion ? firstQuestion.type_question.trim() : null
}

export const getCurrentChapterNumber = (dataQuestion: any, currentChapter: number) : number | null => {
  if (!dataQuestion) return null

  const chapterKeys = Object.keys(dataQuestion);
  const sortedChapterKeys = chapterKeys.sort((a, b) => {
    const chapterNumberA = parseInt(a.split('_')[1], 10);
    const chapterNumberB = parseInt(b.split('_')[1], 10);
    return chapterNumberA - chapterNumberB;
  });

  const currentChapterKey = sortedChapterKeys[currentChapter];
  if (currentChapterKey) {
    const chapterNumber = parseInt(currentChapterKey.split('_')[1], 10);
    return chapterNumber;
  }

  return null;
};

export const useTotalQuestions = (currentChapterNumber: number | null, currentChapterQuestions: Question[] | null) => {
  const [totalQuestions, setTotalQuestions] = useState(0);
  useEffect(() => {
    if (!currentChapterNumber || !currentChapterQuestions) return;
    setTotalQuestions(currentChapterQuestions.length);
  }, [currentChapterNumber, currentChapterQuestions]);

  return totalQuestions;
};



export const calculateQuadrantWeight = (optionIndex: number) => {
  switch(optionIndex) {
      case 1: 
          return 4;
      case 2:
          return 3;
      case 3:
          return 2;
      default:
          return 0; 
  }
}

export const mapNumberToCard = (number: any) => {
  switch(number) {
    case 1: return 'cardOne';
    case 2: return 'cardTwo';
    case 3: return 'cardThree';
    case 4: return 'cardFour';
    default: return '';
  }
};


export function transformData(data: Record<string, Option>) {
  const newArr = Object.values(data).map(item => {
      return {
        question_id: item.question_id,
        answer_id: item.id
      }
  });
  
  return newArr;
}

export function extractColumnTwo(array: any) {
  return array.map((item: { columnTwo: any; }) => item.columnTwo);
}