import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ReportBetesa from 'views/screens/report'
import ReportSkills from 'views/screens/report/skill'
import NotFound from 'views/screens/NotFound'
import EvaluatedUser from 'views/screens/super-admin/info-evaluated-user'
import TestResults from 'views/screens/Modules/TestResults'
import Percentiles from 'views/screens/super-admin/percentiles'
import Home from 'views/screens/super-admin/home'
import Companies from 'views/screens/Companies'
import NewCompany from 'views/screens/Companies/New'
import UpdateCompany from 'views/screens/Companies/Update'
import Company from 'views/screens/Companies/Company'
import Users from 'views/screens/Users'
import EvaluatedUserPerCompany from 'views/screens/super-admin/evaluated-user-company'
import EvaluatedUserPerCompanyDetail from 'views/screens/super-admin/evaluated-user-company-detail'
import TestResultsSkills from 'views/components/TestResultsSkills'
import AdminPlatformPerCompany from 'views/screens/super-admin/admin-platform-company/admin-platform-company'
import CreateGeneralText from 'views/screens/Modules/GeneralTexts/New'
import InterpretationIndex from 'views/screens/super-admin/betesa-interpretations'
import Interpret from 'views/screens/super-admin/betesa-interpretations/interpretation-detail'
import ReinterpretationBetesa from 'views/screens/super-admin/betesa-interpretations/reinterpretation-betesa-detail'
import ListBetesaInterpretationByUser from 'views/screens/super-admin/betesa-interpretations/list-betesa-by-user'
import SkillsPercentils from 'views/screens/Modules/Skills/Percentils'
import UpdateSkillPercentil from 'views/screens/Modules/Skills/Percentils/Update'

import TalentsQuestionnaireSettings from 'views/screens/Modules/betesa-talents/pages/questionnaires/Settings'
import TalentsQuestionnaireSettingsInfo from 'views/screens/Modules/betesa-talents/pages/questionnaires/Settings/info'
import TalentsQuestionnaireSettingsQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/Settings/questions'
import TalentsChapterQuestionnaire from 'views/screens/Modules/betesa-talents/pages/questionnaires/chapter'
import TalentsCreateChapter from 'views/screens/Modules/betesa-talents/pages/questionnaires/chapter/create-chapter-questionnaire'
import TalentsQuestionnaireQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/questions'
import CreateTalentsQuestionnaireQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/questions/create'
import UpdateTalentsQuestionnaireQuestions from 'views/screens/Modules/betesa-talents/pages/questionnaires/questions/update'

import UserSuspend from 'views/screens/Users/Suspension'

//INVENTORY
import Inventory from 'views/screens/super-admin/inventory'
import NewInventory from 'views/screens/inventory/new'
import UpdateInventory from 'views/screens/inventory/update'

import { superAdminRoutes } from 'router/routes'
import RoleFamilyRoutes from 'views/screens/super-admin/role-family/routes/role-family.routes'
import EditChapter from 'views/screens/Modules/betesa-talents/pages/questionnaires/chapter/edit-chapter/edit-chapter'
import TestResultsBetesa from 'views/components/TestResultsBetesa'
import PreviewGenerateReport from 'views/components/preview-generate-report/preview-generate-report'

const SuperAdminRouter: FC = () => (
  <Routes>
    <Route path="/login" element={<Navigate to="/" replace />} />
    <Route path={superAdminRoutes.Home} element={<Home />} />
    <Route path={superAdminRoutes.InfoEvaluatedUser} element={<EvaluatedUser />} />
    <Route path={superAdminRoutes.ListBetesaUninterpreted} element={<InterpretationIndex />} />
    <Route path={superAdminRoutes.InterpretationBetesa} element={<Interpret />} />
    <Route path={superAdminRoutes.TestResult} element={<TestResults />} />
    <Route path={superAdminRoutes.Percentiles} element={<Percentiles />} />
    <Route path={superAdminRoutes.Companies} element={<Companies />} />
    <Route path={superAdminRoutes.CompanyInfo} element={<Company />} />
    <Route path={superAdminRoutes.CreateCompany} element={<NewCompany />} />
    <Route path={superAdminRoutes.EvaluatedUserByCompany} element={<EvaluatedUserPerCompany />} />
    <Route path={superAdminRoutes.InfoEvaluatedUserByCompany} element={<EvaluatedUserPerCompanyDetail />} />
    <Route path={superAdminRoutes.ResultsSkills} element={<TestResultsSkills />} />
    <Route path={superAdminRoutes.ResultsBetesa} element={<TestResultsBetesa />} />
    <Route path={superAdminRoutes.PlatformAdminsByCompany} element={<AdminPlatformPerCompany />} />
    <Route path={superAdminRoutes.UpdateCompany} element={<UpdateCompany />} />
    <Route path={superAdminRoutes.Users} element={<Users />} />
    <Route path={superAdminRoutes.UsersByCompany} element={<Users />} />
    <Route path={superAdminRoutes.SuspendUser} element={<UserSuspend />} />
    <Route path={superAdminRoutes.ChaptersQuestionaire} element={<TalentsChapterQuestionnaire />} />
    <Route path={superAdminRoutes.CreateChapterQuestionaire} element={<TalentsCreateChapter />} />
    <Route path={superAdminRoutes.EditChapter} element={<EditChapter />} />
    <Route path={superAdminRoutes.EditChapter} element={<EditChapter />} />
    <Route path={superAdminRoutes.QuestionsTalentQuestionaire} element={<TalentsQuestionnaireQuestions />} />
    <Route path={superAdminRoutes.CreateQuestionQuestionaire} element={<CreateTalentsQuestionnaireQuestions />} />
    <Route path={superAdminRoutes.UpdateQuestionQuestionaire} element={<UpdateTalentsQuestionnaireQuestions />} />
    <Route path={superAdminRoutes.SettingQuestionaire} element={<TalentsQuestionnaireSettings />} />
    <Route path={superAdminRoutes.EditBasicInfoQuestionaire} element={<TalentsQuestionnaireSettingsInfo />} />
    <Route path={superAdminRoutes.QuestionsQuestionaire} element={<TalentsQuestionnaireSettingsQuestions />} />
    <Route path={superAdminRoutes.PercentilsSkills} element={<SkillsPercentils />} />
    <Route path={superAdminRoutes.UpdatePercentilsSkills} element={<UpdateSkillPercentil />} />
    <Route path={superAdminRoutes.CreateGeneralText} element={<CreateGeneralText />} />
    <Route path={`${superAdminRoutes.RoleFamily}/*`} element={<RoleFamilyRoutes />} />
    <Route path={superAdminRoutes.ReinterpretationBetesa} element={<ReinterpretationBetesa />} />
    <Route path={superAdminRoutes.ListBetesaInterpretedByUser} element={<ListBetesaInterpretationByUser />} />
    <Route path={superAdminRoutes.Inventory} element={<Inventory />} />
    <Route path={superAdminRoutes.NewInventory} element={<NewInventory />} />
    <Route path={superAdminRoutes.UpdateInventory} element={<UpdateInventory />} />
    <Route path="/report" element={<ReportBetesa />} />
    <Route path="/report-skill" element={<ReportSkills />} />
    <Route path="/reports-download" element={<PreviewGenerateReport />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default SuperAdminRouter
