import {
  borders,
  bottom,
  color,
  flexbox,
  position,
  space,
  layout,
  LayoutProps,
  SpaceProps,
  FlexboxProps,
  ColorProps,
  BordersProps,
  PositionProps,
} from 'styled-system'
import styled from 'styled-components'

export type BlockProps = LayoutProps & SpaceProps & FlexboxProps & ColorProps & BordersProps & PositionProps

export const Block = styled.div<BlockProps>`
  ${flexbox}
  ${layout}
  ${color}
  ${space}
  ${position}
  ${bottom}
  ${borders}
`
