import { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'views/components/UI/Modal'
import Loader from 'views/components/UI/Loader/Loader'
import Select from 'views/components/UI/Select'
import { H4, SubtitleBold, P } from 'views/components/UI/Text'
import Tooltip from 'views/components/Tooltip'
import { StrokedButton } from 'views/components/UI/Buttons'
import SearchBar from 'views/components/Searchbar'
import { Row, Divider, GridItem, ModalBody, ModalContainer, GridModal, ModalHeader } from './styled'
import { useNotification } from 'lib/context/notification.context'
import { rootProcessSelector } from 'ducks/createProcess'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { generalDataSelector } from 'ducks/auth'
import { Skill } from 'types/common'
// services
import getSkillsByFilters from 'services/common/skills/getByFilters'
import { useTranslation } from 'react-i18next';

interface IModalAddSkills {
  open: boolean
  setOpen: (open: boolean) => void
  onAddNewSkill: (skill: Skill) => void
  skillsSelected?: number[]
}

interface Filters {
  levelId: number
  groupId?: number
  name?: string
}

const ModalAddSkills = (props: IModalAddSkills) => {
  const { t } = useTranslation()
  const { open, setOpen, onAddNewSkill, skillsSelected } = props
  const { getError } = useNotification()
  const { process } = useSelector(rootProcessSelector)
  const level: number = process.stepOne.level
  const { data: generalData } = useSelector(generalDataSelector)
  const groupOptions = useMemo(
    () =>
      generalData?.group
        ?.filter((group: any) => group.id !== 1)
        .map((group: any) => ({ label: group.name, value: group.id })) || [],
    [generalData]
  )

  const [loading, setLoading] = useState(false)
  const [searchFilters, setSearchFilters] = useState<Filters>({ levelId: level })
  const [skills, setSkills] = useState<Skill[]>([])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      getSkillsByFilters(searchFilters, 1, 10, searchFilters.name)
        .then((response) => {
          setSkills(response.skills)
        })
        .catch(() => {
          getError(t('global.common.getSkillsErrorMessage'))
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters, searchFilters.name, searchFilters.groupId, getError])

  const handleSearch = useCallback(
    (key: string, value: any) => {
      setSearchFilters({ ...searchFilters, [key]: value })
    },
    [searchFilters]
  )

  return (
    <Modal
      hideCloseButton={true}
      show={open}
      setShow={setOpen}
      styleContent={{
        width: '80%',
        height: '80%',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpen(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <H4>Competencias</H4>
          <GridModal>
            <GridItem width="60%">
              <SearchBar
                label="Buscar competencia"
                value={searchFilters?.name || ''}
                onChange={(e) => {
                  handleSearch('name', e.target.value)
                }}
              />
            </GridItem>
            <GridItem flexDirection="row" width="90%" justify="flex-end">
              <SubtitleBold style={{ margin: '0px 16px 0px 0px' }}>Grupos de {t('global.common.skillText')}:</SubtitleBold>
              <Select
                width="220px"
                label="Grupos"
                value={searchFilters?.groupId || ''}
                // onChange={(e) => setSearchFilters({ ...searchFilters, groupId: e })}
                onChange={(e) => {
                  if (e) {
                    handleSearch('groupId', e)
                  } else {
                    const filtersWithoutGroup = { ...searchFilters }
                    delete filtersWithoutGroup.groupId
                    setSearchFilters(filtersWithoutGroup)
                  }
                }}
                options={groupOptions}
                hideNeverChoice
              />
            </GridItem>
          </GridModal>
          {!loading ? (
            <>
              <Divider style={{ marginTop: '14px', width: '95%' }} />
              <GridModal>
                {skills.map((item, index) => {
                  return (
                    <>
                      <GridItem width="90%" key={index}>
                        <Row>
                          <P margin="0px">{item.skill}</P>
                          <Tooltip tooltipText={item.description} />
                        </Row>
                      </GridItem>
                      <GridItem flexDirection="row" width="90%" justify="flex-end">
                        <Row width="50%" justify="flex-end">
                          <StrokedButton
                            disabled={skillsSelected?.includes(item.id) || false}
                            type="button"
                            onClick={() => onAddNewSkill(item)}
                          >
                            Agregar
                          </StrokedButton>
                        </Row>
                      </GridItem>
                    </>
                  )
                })}
              </GridModal>
            </>
          ) : (
            <Loader />
          )}
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}

export default ModalAddSkills
