export const HEADERS = [
  {
    key: 'first_name',
    name: 'Nombre',
    editable: true,
  },
  {
    key: 'last_name',
    name: 'Apellido',
    editable: true,
  },
  {
    key: 'document',
    name: 'Documento',
    editable: true,
  },
  {
    key: 'email',
    name: 'Correo',
    editable: true,
    validator: true,
  },
  {
    key: 'active',
    name: 'Activo',
  },
  {
    key: 'complete',
    name: 'Pruebas contestadas',
  },
  {
    key: 'edit',
    name: '',
  },
]
