import React, { useState, useEffect } from 'react'
import { Container, Content } from './styled'
import { useSelector } from 'react-redux'
import SideBar from 'views/components/Sidebar'
import SizeValidator from '../../components/SizeValidator/index'

interface MainLayoutProps {
  children: React.ReactNode
  cobrandingLogoUrl?: string
  style?: React.CSSProperties
}

const MainLayout = ({ children, cobrandingLogoUrl }: MainLayoutProps): JSX.Element => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { role } = useSelector((state: any) => state.auth)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (screenWidth < 992) {
      setSidebarOpen(false)
    } else {
      setSidebarOpen(true)
    }
  }, [screenWidth])

  return (
    <Container>
      <SideBar isOpen={sidebarOpen} setIsOpen={(e) => setSidebarOpen(e)} cobrandingLogoUrl={cobrandingLogoUrl} />
      {screenWidth < 992 && role !== 'user' ? (
        <SizeValidator />
      ) : (
        <Content isSidebarOpen={sidebarOpen}>{children}</Content>
      )}
    </Container>
  )
}

export default MainLayout
