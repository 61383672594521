import React, { useState } from 'react'
import {
  Container,
  Content
} from './styled'
import Sidebar from 'views/components/Sidebar'

const TestLayout = ({ children, cobrandingLogoUrl }: any): JSX.Element => {
  const [sidebarClosed, setSidebarClosed] = useState(false)
  return (
    <Container>
      <Sidebar
        isOpen={sidebarClosed}
        setIsOpen={e => setSidebarClosed(e)}
        cobrandingLogoUrl={cobrandingLogoUrl}
      />
      <Content isSidebarOpen={sidebarClosed}>
        {children}
      </Content>
    </Container>
  )
}

export default TestLayout
