import axios from 'lib/axios'

const getQuadrantResults = async (id: any, dominance: string) => {
  const quadrantResults = await axios
    .get(`/betesa_results/users_list/${id}?dominance=${dominance}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return quadrantResults
}

export default getQuadrantResults
