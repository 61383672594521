import { useCallback, useState } from 'react';
import { downloadSummaryReportUser } from 'services/common/reports/getProcessReport/get-process-reports-service';

interface GetReportsProcessFilters {
  processId: number;
  useBetesa: boolean;
  useCompetence: boolean;
  processName: string;
}

const useReportDownloaderByExcel = () => {
  const [loading, setLoading] = useState(false);

  const fetchGetReportsByExcel = useCallback(async (data: GetReportsProcessFilters) => {
    const { processId, useBetesa, useCompetence, processName } = data;
    setLoading(true);
    try {
      const downloadReport = await downloadSummaryReportUser({
        processId,
        useBetesa,
        useCompetences: useCompetence,
      });

      if (downloadReport) {
        const blob = new Blob([downloadReport], { type: 'xlsx' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `reportes-${processName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error al obtener los informes: ', error);
      // Manejo de errores: podrías mostrar un mensaje de error al usuario o realizar alguna acción específica
    } finally {
      setLoading(false);
    }
  }, []);

  return { fetchGetReportsByExcel, loading };
};

export default useReportDownloaderByExcel;
