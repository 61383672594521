import React, { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from 'views/screens/Evaluated-User/pages/Home'
import NotFound from 'views/screens/NotFound'
import StartTest from 'views/screens/Evaluated-User/pages/StartTest'
import Analytics from 'views/screens/Evaluated-User/pages/Analytics'
import InformedConsent from 'views/screens/Evaluated-User/pages/Informed-Consent'
import DownloadResults from 'views/screens/Evaluated-User/pages/DownloadResults'
import DevelopmentPlan from 'views/screens/Evaluated-User/pages/DevelopmentPlan'
import QuestionsSkills from 'views/screens/Evaluated-User/pages/QuestionsSkills'
import QuestionsTalents from 'views/screens/Evaluated-User/pages/QuestionTalents'
import PreviewGenerateReport from 'views/components/preview-generate-report'

const PlatformAdministratorRouter: FC = () => (
    <Routes>
        <Route
            path="/login"
            element={<Navigate to="/" replace />}
        />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/analitycs" element={<Analytics />} />
        <Route path="/test/start" element={<StartTest />} />
        <Route path="/test/language" element={<InformedConsent />} />
        <Route path="/test/test-skills" element={<QuestionsSkills />} />
        <Route path="/test/test-talents" element={<QuestionsTalents />} />
        <Route path="/download-results" element={<DownloadResults />} />
        <Route path="/development-plan" element={<DevelopmentPlan />} />
        <Route path="/reports-download" element={<PreviewGenerateReport />} />
    </Routes>

)

export default PlatformAdministratorRouter;
