import styled from "styled-components";

export const CompetenceNameHeadText = styled.th`
  width: 20%;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const WrapperTitles = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  padding: 0 24px;
`;

export const Row = styled.div`
  padding: 4px 0;
`;

export const ContainerTd = styled.td`
  padding: 8px 15px;
`;
