import axios from 'axios'

import { CompanyInfo } from 'types/common'

interface ICompanyInfoResponse {
  status: string
  data: CompanyInfo[]
}

/**
 * @description service to get company info
 * @param companyId
 */
const getCompanyInfo = async (companyId: number) => {
  const res: ICompanyInfoResponse = await axios
    .get(`/company/info/${companyId}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export default getCompanyInfo
