import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import { Link } from 'react-router-dom'
import { Card, ModulesContainer, CardIconSection, Icon, CardDescriptionSection, CardTitle } from './styled'
import Betesa from '../../components/assets/Betesa.png'
import Competencias from '../../components/assets/competencias.png'
import Generales from '../../components/assets/generales.png'
import Familia from '../../components/assets/rolFamily.png'
import { superAdminRoutes } from 'router/routes'
import { useTranslation } from 'react-i18next'


const Modules: FC = () => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 35,
          marginTop: 50,
          alignItems: 'baseline',
        }}
      >
        <h2 style={{ fontSize: 32, marginBottom: 0 }}>Módulos</h2>
        <p style={{ marginBottom: 0 }}>Selecciona un módulo para empezar a configurarlo</p>
      </div>

      <ModulesContainer>
        <Link to="/modules/talents">
          <Card>
            <CardIconSection>
              <Icon style={{ marginTop: 5 }} src={Betesa} alt="modulo" />
            </CardIconSection>
            <CardDescriptionSection>
              <CardTitle>Betesa - Talentos Naturales</CardTitle>
            </CardDescriptionSection>
          </Card>
        </Link>
        <Link to="/modules/skills">
          <Card>
            <CardIconSection>
              <Icon src={Competencias} alt="modulo" />
            </CardIconSection>
            <CardDescriptionSection>
              <CardTitle>{t('global.common.skillText')}</CardTitle>
            </CardDescriptionSection>
          </Card>
        </Link>


      </ModulesContainer>
      <hr style={{ border: '1px solid #919EAB', marginTop: 20, marginBottom: 20 }} />
      <p style={{ color: '#1F2124', fontWeight: 'bold', marginBottom: 20 }}>Pre - Configuración</p>
      <Link to={superAdminRoutes.RoleFamily}>
        <Card>
          <CardIconSection>
            <Icon style={{ marginTop: 4 }} src={Familia} alt="Familia de rol" />
          </CardIconSection>
          <CardDescriptionSection>
            <CardTitle>Familia de rol</CardTitle>
          </CardDescriptionSection>
        </Card>
      </Link>
      
      <hr style={{ border: '1px solid #919EAB', marginTop: 20, marginBottom: 20 }} />
      <p style={{ color: '#1F2124', fontWeight: 'bold', marginBottom: 20 }}>Textos</p>
      <Link to="/modules/texts/create">
        <Card>
          <CardIconSection>
            <Icon style={{ marginLeft: 10, marginTop: 10 }} src={Generales} alt="generales" />
          </CardIconSection>
          <CardDescriptionSection>
            <CardTitle>Generales</CardTitle>
          </CardDescriptionSection>
        </Card>
      </Link>
    </MainLayout>
  )
}
export default Modules
