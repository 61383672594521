import React, { FC } from 'react';

interface RollBackIconProps {
    width?: string;
    height?: string;
    color?: string;
}

const RollBackIcon: FC<RollBackIconProps> = ({
    width = '24',
    height = '24',
    color = '#C2C2C2'
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5802_136603)">
                <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
                <path d="M2.90918 3.36328V6.99964H6.54556" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 12C2 17.5229 6.47715 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C8.299 2 5.06755 4.01056 3.33839 6.99905" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.0026 6L12.002 12.0044L16.2417 16.2441" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_5802_136603">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default RollBackIcon;
