import axios from 'lib/axios'
import { ACTION_TABS, DEVELOPMENT_PLAN_TABS } from '../constants'
import { DevelopmentPlanResponse } from '../store'

export type BodyEditBetesaTalent = {
  name_spa: string
  category?: string
  description_spa?: string
}

export const fetchCreateBetesaTalent = async (data: any) => {
  const responseData = await axios
    .post(`/${DEVELOPMENT_PLAN_TABS.create}`, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return responseData
}

export const fetchEditBetesaTalent = async (id: number, data: BodyEditBetesaTalent) => {
  const responseData = await axios
    .put(`/${DEVELOPMENT_PLAN_TABS.create}/${id}`, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return responseData
}

export const fetchCreateBetesaAction = async (data: any) => {
  await axios
    .post(`/${ACTION_TABS.create}`, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const fetchGetActionsById = async (
  id: number,
  page?: number,
  search?: string
): Promise<DevelopmentPlanResponse> => {
  try {
    let url = `/${DEVELOPMENT_PLAN_TABS.action}/${id}`

    if (page) {
      url += `?page=${page}`
    }
    if (search) {
      url += `&search=${search}`
    }

    const response = await axios.get(url)

    return response.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const fetchAllActions = async (limit: number, page: number, search?: string | undefined) => {
  let apiUrl = `/development_plan?limit=${limit}&page=${page}&betesa=true`
  if (search) {
    apiUrl = apiUrl + `&search=${search}`
  }
  const response = await axios
    .get(apiUrl)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return response
}

export const fetchUpdateBetesaAction = async (body: any, id: number) => {
  const res = await axios
    .put(`/${ACTION_TABS.update}/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export const fetchUpdateChapterBetesa = async (body: any, id: number) => {
  const res = await axios
    .put(`/chapter/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}
