import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UpdateTag, Row, GridModal, ModalContainer, ModalHeader, ModalBody, BtnGoBack, Title, Strong } from './styled'
import Modal from 'views/components/UI/Modal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import { fetchGetActionsById } from '../../../services/fetch-betesa-talents'
import { DevelopmentPlan } from '../../../store'

interface ActionDetailModalProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  actionId: number
}

const ActionDetailModal: FC<ActionDetailModalProps> = ({ openModal, setOpenModal, actionId }) => {
  const navigate = useNavigate()
  const [actionDetail, setActionDetail] = useState({} as DevelopmentPlan)

  useEffect(() => {
    if (actionId === 0) return
    fetchGetActionsById(actionId).then(
      (data) => {
        setActionDetail(data.development_plan)
      },
      (error) => {
        console.error('Función de rechazo llamada: ', error)
        setActionDetail({} as DevelopmentPlan)
      }
    )
  }, [actionId])

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '45%',
        height: '80%',
        borderRadius: '10px',
        padding: '20px',
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>
        <ModalBody>
          <Title>{actionDetail?.name}</Title>
          <p>{actionDetail?.description}</p>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Strong>Acciones de desarrollo</Strong>
            <UpdateTag
              onClick={() => {
                navigate(`/modules/talents/actions/update?id=${actionId}`)
              }}
            >
              Editar acciones de plan de desarrollo
            </UpdateTag>
          </div>
          <GridModal>
            {!!actionDetail?.actions ? (
              actionDetail.actions.map((action: any, index) => {
                return <p key={`${index}-${action.description}`}>{action.description}</p>
              })
            ) : (
              <p>No hay acciones creadas</p>
            )}
            <Row>
              <BtnGoBack
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                <ArrowCircleLeftOutlinedIcon /> Anterior
              </BtnGoBack>
              <BtnGoBack
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Siguiente <ArrowCircleRightOutlinedIcon />
              </BtnGoBack>
            </Row>
          </GridModal>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}

export default ActionDetailModal
