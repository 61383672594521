import React, { useState} from 'react';
import {MainLayout} from 'views/layouts/';
import { useNavigate } from 'react-router-dom';

// components
import { Container, ButtonContainer } from './styled'; 
import { H2, P } from 'views/components/UI/Text';
import { Button } from 'views/components/UI/Buttons';
import SelectableBrainRadius from 'views/components/Graphics/SelectableBrainRadius';
// routes
import { platformAdminRoutes } from 'router/routes';
const TalentsToDevelop = () => {
    const navigate = useNavigate();
    const [quadrantSelected, setQuadrantSelected] = useState<string>('');

    return (
        <MainLayout>
            <Container>
                <H2>Talentos a desarrollar</H2>
                <P margin='16px 0px'>Selecciona el cuadrante a desarrollar </P>
                <SelectableBrainRadius
                    onChange={(value) => setQuadrantSelected(value)}
                    value={quadrantSelected}
                />
                <ButtonContainer> 
                    <Button onClick={() => navigate(platformAdminRoutes.SelectTalentsToDevelop, {state:{quadrantSelected}})} disabled={quadrantSelected === ''}>Guardar</Button>
                </ButtonContainer>
             </Container>   
        </MainLayout>
    );
};

export default TalentsToDevelop;