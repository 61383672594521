/* eslint-disable */

import { FC, useState, useMemo, useCallback } from 'react'
import { MainLayout } from 'views/layouts'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TitleDiv from 'views/components/UI/TitleDiv'
import { P } from 'views/components/UI/Text';
import { BackButton, Button } from 'views/components/UI/Buttons';
import {  HEADERS_ACTION, HEADERS_ADDED_USERS, User,} from '../constants';
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import UploadModal from '../upload-modal/upload-modal';
import { stepFiveSelector, getLinkExcelUsersFailed, getTestTypes} from 'ducks/createProcess';
import DownloadIcon from 'assets/icons/DownloadIcon';
import { AnchorSection } from '../styled';
import { platformAdminRoutes } from 'router/routes';
import { Row } from 'views/components/UI/Row';
import { DEFAULT_LIMIT } from 'constants/global.constants';
import { setUserListFailed} from 'ducks/createProcess'

const NotUploaded: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testTypes = useSelector(getTestTypes);
  const userListFailed = useSelector(stepFiveSelector).userListFailed;
  const urlDownloadFailedUsers = useSelector(getLinkExcelUsersFailed);

  const userList = userListFailed?.map((user: any) => {
    const { document, document_type, email, firstName, lastName, reason } = user.user;

    return {
      document,
      documentType: document_type,
      email,
      firstName,
      lastName,
      reason,
    }
  })

  const [pageUsersAdded, setPageUsersAdded] = useState(1); 
  const [pageUserList, setPageUserList] = useState(1);
  const [ addedUsers, setAddedUsers ] = useState<User[]>([])

  const [ openUploadModal, setOpenUploadModal] = useState(false)
  const [ uploadedbyFile, setUploadedbyFile] = useState(false)


  const paginatedAddedUsers = useMemo(() => {
    if (addedUsers.length === 0) return [];

    const firstIndex = (pageUsersAdded - 1) * DEFAULT_LIMIT;
    const lastIndex = firstIndex + DEFAULT_LIMIT;

    return addedUsers.slice(firstIndex, lastIndex);
  }, [addedUsers, pageUsersAdded]);

  const handlePaginationUsersAdded = useCallback((page: number) => {
    setPageUsersAdded(page);
  }, []);


  const paginateUserList = useMemo(() => {
    if (userList.length === 0) return [];

    const firstIndex = (pageUserList - 1) * DEFAULT_LIMIT;
    const lastIndex = firstIndex + DEFAULT_LIMIT;

    return userList.slice(firstIndex, lastIndex);
  }, [userList]);

  const handlePaginationUserList = useCallback((page: number) => {
    setPageUserList(page);
  }, []);



  return (
    <MainLayout>
      <TitleDiv> 
        <h2 style={{ fontSize: 32 }}>Usuarios no creados</h2>        
        <AnchorSection href={urlDownloadFailedUsers} download>
            <Row gap='10px' justifyContent='none' alignItems='center'>
                <DownloadIcon/> <span style={{ color: '#2D749C', fontWeight: 700 }}> Descargar archivo</span>
            </Row>
            </AnchorSection> 
      </TitleDiv>
      <P>Descarga el excel con estos datos,  corrígelos y sube el archivo nuevamente en la sección de agregar usuarios.</P>
    {
      addedUsers.length ? (
        <BluesiteTableComponent
          headers={HEADERS_ADDED_USERS}
          searchBar={false}
          searchLabel=''
          totalPages={Math.ceil(addedUsers.length / DEFAULT_LIMIT)}
          currentPage={pageUsersAdded}
          data={paginatedAddedUsers}
          setOpenModal={setOpenUploadModal}
          handlePagination={handlePaginationUsersAdded}
        />
      ) : (
        <BluesiteTableComponent
          headers={HEADERS_ACTION}
          searchBar={false}
          searchLabel=''
          totalPages={Math.ceil(userList.length / DEFAULT_LIMIT)}
          currentPage={pageUserList}
          data={paginateUserList}
          setOpenModal={setOpenUploadModal}
          handlePagination={handlePaginationUserList}
        />
      )
    }
      <UploadModal 
        openModal={openUploadModal}
        setOpenModal={setOpenUploadModal}
        setAddedUsers={(e) => {
        
          const usersWithTestType = e.map((user: any) => {
            return {
              ...user,
              test_types: testTypes,
              documentType: user.document_type
            }
          })
          setAddedUsers(usersWithTestType)
        }}
        setUploadedbyFile={(e) => {
          setUploadedbyFile(e)
          dispatch(setUserListFailed([]))
        }}
        showStepTwo
      />   
     <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '2rem'}}>
      <BackButton onClick={() => navigate(-1)}/>
        {
          addedUsers.length ? <Button onClick={() => navigate(platformAdminRoutes.Resume, { state: { data: addedUsers, uploadedbyFile } })} style={{ marginLeft: 10 }}>Cargar usuarios</Button> : null
        }
     </div>
    </MainLayout>
  )
}

export default NotUploaded
