/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { NewContainer, TextArea, NewContainerItem, NewContainerTittle, Title, RowContainer } from './styled'
import { useNotification } from 'lib/context/notification.context'
import Input from 'views/components/UI/TextInput'
import { Button } from 'views/components/UI/Buttons'
import { useFormik } from 'formik'
import { fetchGetCompetenceById } from '../services/fetch-competences'
import { Competence, CompetenceUpdate } from '../store'
import { fetchUpdateCompetence } from 'services/common/skills/update'
import Loader from 'views/components/UI/Loader/Loader'
import Select from 'views/components/UI/Select'
import { useSelector } from 'react-redux'
import { generalDataSelector } from 'ducks/auth'
import { EMPTY_STRING, STATUS_OK } from 'constants/global.constants'

export default function Index() {
  const navigate = useNavigate() 
  const [params, setParams] = useState<{ id?: any, level?: any}>({})
  const [idSkill, setIdSkill] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [competenceDetail, setCompetenceDetail] = useState<Competence>({} as Competence)
  const [groupOptions, setGroupOptions] = useState<any[]>([])
  const [levelOptions, setLevelOptions] = useState<any[]>([])
  const { getError, getSuccess } = useNotification()
  const { data: generalData } = useSelector(generalDataSelector)

  const formik = useFormik({
    initialValues: {
      skill: '',
      level_id: 0,
      group_id: 0,
      description: '',
      emotional_description: '',
      cognitive_description: '',
      interactive_description: '',
    },
    validateOnChange: true,
    onSubmit: (values) => {
      const body: CompetenceUpdate = {
        skill: values.skill,
        level_id: values.level_id,
        group_id: values.group_id,
        description: values.description,
        competence_descriptions: {
          id: competenceDetail.descriptions_id,
          emotional: !values.emotional_description ? EMPTY_STRING : values.emotional_description,
          cognitive: !values.cognitive_description ? EMPTY_STRING : values.cognitive_description,
          interactive: !values.interactive_description ? EMPTY_STRING : values.interactive_description,
        },
      }

      fetchUpdateCompetence(idSkill, body)
        .then((response: any) => {
          if (response.data.status === STATUS_OK) {
            getSuccess('Competencia modificada con exito')
            navigate(-1)
          }
        })
        .catch(() => {
          getError('Error al modificar la competencia')
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
  })

  const isDisabled = () => {
    return (
      formik.values.skill === '' ||
      (competenceDetail.skill === formik.values.skill &&
        (competenceDetail.description || '') === formik.values.description &&
        competenceDetail.level_id === formik.values.level_id &&
        competenceDetail.group_id === formik.values.group_id &&
        (competenceDetail.emotional_description || '') === formik.values.emotional_description &&
        (competenceDetail.cognitive_description || '') === formik.values.cognitive_description &&
        (competenceDetail.interactive_description || '') === formik.values.interactive_description)
    )
  }

  useEffect(() => {
    if (idSkill) {
      fetchGetCompetenceById(idSkill)
        .then((competence: Competence) => {
          setCompetenceDetail(competence)
          setIsLoading(false)
          formik.setValues({
            skill: competence.skill,
            level_id: competence.level_id,
            group_id: competence.group_id,
            description: competence.description,
            emotional_description: !competence.emotional_description ? '' : competence.emotional_description,
            cognitive_description: !competence.cognitive_description ? '' : competence.cognitive_description,
            interactive_description: !competence.interactive_description ? '' : competence.interactive_description,
          })
        })
        .catch(() => {
          getError('Error al obtener la competencia')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSkill])

  useEffect(() => {
    const levelOptionsData = generalData.level
      .map((level: any) => {
        if (level.id === 1 || level.name === 'Betesa' || level.name === 'Estratégico-Táctico') {
          return EMPTY_STRING
        }
        return {
          value: level.id,
          label: level.name,
        }
      })
      .filter((option: any) => option !== EMPTY_STRING)

    const groupOptionsData = generalData.group
      .map((group: any) => {
        if (group.id === 1 || group.name === 'Betesa') {
          return EMPTY_STRING
        }
        return {
          value: group.id,
          label: group.name,
        }
      })
      .filter((option: any) => option !== EMPTY_STRING)

    setGroupOptions(groupOptionsData)
    setLevelOptions(levelOptionsData)
  }, [generalData])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setParams(Object.fromEntries(searchParams))
  }, [])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      const paramNumber = parseInt(params.id)
      const level = params.level as any

      if (level) {
        formik.setFieldValue('level_id', level === '' ? null : level)
      }
      setIdSkill(paramNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, formik.values])

  return (
    <MainLayout>
      <NewContainer>
        <Title>Editar competencia</Title>
        {!isLoading ? (
          <form onSubmit={formik.handleSubmit} style={{ paddingTop: 10 }}>
            <NewContainerItem>
              <NewContainerTittle>Nombre</NewContainerTittle>
              <Input
                name="name"
                type="text"
                label="Competencia"
                placeholder="Competencia"
                style={{ width: '48%' }}
                value={formik.values.skill}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  formik.handleChange('skill')(event.target.value)
                }}
                helperText={formik.errors.skill}
              />
            </NewContainerItem>
            <RowContainer>
              <Select
                name="level"
                label="Nivel"
                hideNeverChoice
                options={levelOptions}
                value={formik.values.level_id}
                onChange={(value) => {
                  formik.setFieldValue('level_id', value === '' ? null : value)
                }}
              />
              <Select
                name="group"
                label="Grupo"
                options={groupOptions}
                value={formik.values.group_id}
                onChange={(value) => {
                  formik.setFieldValue('group_id', value === '' ? null : value)
                }}
              />
            </RowContainer>
            <NewContainerItem>
              <NewContainerTittle>Descripción general</NewContainerTittle>
              <TextArea
                name="description"
                placeholder="Descripción general"
                value={formik.values.description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  formik.handleChange('description')(event.target.value)
                }}
              />
            </NewContainerItem>
            <NewContainerItem>
              <NewContainerTittle>Descripción cognitiva</NewContainerTittle>
              <TextArea
                name="cognitive_description"
                placeholder="Descripción cognitiva"
                value={formik.values.cognitive_description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  formik.setFieldValue('cognitive_description', event.target.value)
                }}
              />
            </NewContainerItem>
            <NewContainerItem>
              <NewContainerTittle>Descripción emocional</NewContainerTittle>
              <TextArea
                name="emotional_description"
                placeholder="Descripción emocional"
                value={formik.values.emotional_description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  formik.setFieldValue('emotional_description', event.target.value)
                }}
              />
            </NewContainerItem>
            <NewContainerItem>
              <NewContainerTittle>Descripción interactiva</NewContainerTittle>
              <TextArea
                name="interactive_description"
                placeholder="Descripción interactiva"
                value={formik.values.interactive_description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  formik.setFieldValue('interactive_description', event.target.value)
                }}
              />
            </NewContainerItem>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <p
                onClick={() => {
                  navigate(-1)
                }}
                style={{
                  color: '#2D749C',
                  fontSize: 14,
                  fontWeight: 700,
                  cursor: 'pointer',
                }}
              >
                Cancelar
              </p>
              {isDisabled() ? (
                <Button type="button" disabled={isDisabled()}>
                  Guardar
                </Button>
              ) : (
                <Button type="submit">Guardar</Button>
              )}
            </div>
          </form>
        ) : (
          <Loader />
        )}
      </NewContainer>
    </MainLayout>
  )
}
