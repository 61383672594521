import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { MainLayout } from 'views/layouts';
import TitleDiv from 'views/components/UI/TitleDiv';
import { ContainerRow, BtnGoBack, InputGridDiv, ErrorContainer } from './styled';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from 'views/components/UI/Buttons';
import Input from 'views/components/UI/TextInput';
// router
import { specialAdminRoutes } from 'router/routes';
// services 
import editQuestionnaire from 'services/common/betesa/editQuestionnaire';

interface FormValues {
  nameQuestionnaire: string;
}

const EditBasicInfoQuestionnaire: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // redux state
  const { token } = useSelector((state: any) => state.user);

  // router state
  const { questionnaireName, questionnaireId } = location.state; //  questionnaireId, 

  const initialValues: FormValues = {
    nameQuestionnaire: questionnaireName || '',
  };

  const validationSchema = Yup.object({
    nameQuestionnaire: Yup.string().trim().required('El nombre del cuestionario es requerido'),
  });

  const handleSubmit = (values: FormValues) => {
    editQuestionnaire(token, { id: parseInt(questionnaireId), skill: values.nameQuestionnaire })
      .then(() => {
        navigate(specialAdminRoutes.QuestionnairesBetesa);
      })
  };

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Información básica {questionnaireName}</h2>
      </TitleDiv>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <InputGridDiv>
              <Field
                label="Nombre del cuestionario"
                as={Input}
                name="nameQuestionnaire"
                type="text"
              />
              <ErrorContainer>
                <ErrorMessage name="nameQuestionnaire" />
              </ErrorContainer>
            </InputGridDiv>
            <ContainerRow>
              <BtnGoBack onClick={() => navigate(-1)}>Cancelar</BtnGoBack>
              <Button type="submit" disabled={isSubmitting || Object.keys(errors).length > 0}>Guardar</Button>
            </ContainerRow>
          </Form>
        )}
      </Formik>
    </MainLayout>
  );
};

export default EditBasicInfoQuestionnaire;
