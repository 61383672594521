import axios from 'axios'

/**
 * @description service to get betesa uninterpreted
 * @param token
 */

type IUninterpretedTestBetesa = {
  user_name: string
  company_name: string
  betesa_result_id: number
}

interface IFindUserResponse {
  status: string | 'success' | 'error'
  uninterpretedBetesas: IUninterpretedTestBetesa[]
  total_pages: number
  total_results: number
}

/**
 * @description service to get betesa uninterpreted
 * @param token
 * @returns results of the request without interpretation
 */
const getAllUninterpretedBetesas = async (token: string, page: number, search?: string) => {
  let apiUrl = `${process.env.REACT_APP_MAIN_API}/uninterpreted_betesas?page=${page}&limit=7`

  if (search) {
    apiUrl += `&search=${search}`
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response: IFindUserResponse = await axios
    .get(apiUrl, config)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.error('error on get all uninterpreted betesa', err)
      return err
    })
  return response
}

export default getAllUninterpretedBetesas
