import * as Yup from 'yup'

export const StepOneSchema = Yup.object().shape({
  processType: Yup.number()
    .required('El tipo de proceso es requerido')
    .test('is-valid', 'Debe seleccionar un tipo de proceso', (value) => value === 1 || value === 2),
  processName: Yup.string()
    .trim()
    .min(3, 'El nombre del proceso debe tener al mínimo 3 caracteres')
    .test(
      'no-whitespace',
      'El nombre del proceso no puede contener solo espacios en blanco',
      (value: any) => value && value.trim().length > 0
    )
    .required('El nombre del proceso es requerido'),
  inventoryType: Yup.string().required('El tipo de inventario es requerido'),
  level: Yup.string().required('El nivel es requerido'),
  automaticReports: Yup.boolean().required('La entrega automática de informes es requerida'),
  testLanguage: Yup.string().required('El idioma del reporte es requerido'),
  permitDevelopmentPlan: Yup.boolean().required('El plan de desarrollo es requerido'),
  showInputId: Yup.boolean(),
  profileId: Yup.string().when('showInputId', {
    is: true,
    then: Yup.string().trim().required('Profile ID es requerido'),
    otherwise: Yup.string().notRequired(),
  }),
})
