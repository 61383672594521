import TitleDiv from 'views/components/UI/TitleDiv'
import Input from 'views/components/UI/TextInput'
import Checkbox from '@mui/material/Checkbox'
import Select from 'views/components/UI/Select'
import { Level } from 'types/common'
import {
  FormContainer,
  LanguageRow,
  LanguageRowItem,
  Paragraph,
  TextArea,
  SelectDiv,
  ErrorContainer,
  TextError,
  ContainerInputs,
} from './styled'
import { useTranslation } from 'react-i18next'

const BasicInfoForm = ({ props }: any) => {
  const { t } = useTranslation()
  const { handleChange, values, errors, touched, category, levels } = props
  const levelOptions = levels
    ?.filter((item: Level) => {
      return item.name !== 'Betesa' && item.name !== 'Estratégico-Táctico'
    })
    .map((item: Level) => {
      return {
        value: item.id,
        label: item.name,
      }
    })

  return (
    <>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Información básica de familia de rol</h2>
      </TitleDiv>
      <FormContainer>
        <SelectDiv>
          <ContainerInputs>
            <Input onChange={handleChange} type="text" name="name" label="Nombre" value={values?.name} />
            {errors.name && touched.name && (
              <ErrorContainer>
                <TextError>{errors.name}</TextError>
              </ErrorContainer>
            )}
          </ContainerInputs>
          <ContainerInputs>
            <Select
              style={{ width: '50%' }}
              label="Nivel"
              name="level_id"
              options={!!levelOptions && levelOptions}
              onChange={(e: any) => {
                handleChange('level_id')(e.toString())
              }}
              value={values?.level_id}
              hideNeverChoice
            />
            {errors.level_id && touched.level_id && (
              <ErrorContainer>
                <TextError>{errors.level_id}</TextError>
              </ErrorContainer>
            )}
          </ContainerInputs>
        </SelectDiv>
        <LanguageRow>
          <LanguageRowItem>
            <Paragraph>Pruebas a realizar: </Paragraph>
          </LanguageRowItem>
          {category.length >= 1 ? (
            category?.map(({ name, id, value }: any) => {
              return (
                <LanguageRowItem key={id}>
                  <Checkbox name={`${name}`} checked={values[`${value}`]} />
                  <Paragraph>{name === 'Betesa Premium' ? 'Betesa' : t('global.common.skillText')}</Paragraph>
                </LanguageRowItem>
              )
            })
          ) : (
            <Paragraph>{values.competence ? 'Competencias' : values.premium_betesa && 'Betesa'}</Paragraph>
          )}
        </LanguageRow>
        <TextArea
          name="description"
          onChange={(e: any) => {
            handleChange('description')(e)
          }}
          value={values.description}
          placeholder="Descripción"
        />
        {errors.description && touched.description && (
          <ErrorContainer>
            <TextError>{errors.description}</TextError>
          </ErrorContainer>
        )}
      </FormContainer>
    </>
  )
}

export default BasicInfoForm
