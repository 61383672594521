import {
  Container,
  ScaleContainer,
  Scale,
  ItemScale,
  ItemScaleText,
  LegendContainer,
  ItemLegend,
  TextLegend,
} from './styled'
import BrainMetrics from 'views/components/Graphics/BrainMetrics'
import RectangleSkew from 'assets/icons/RectangleSkew'
import { H2 } from 'views/components/UI/Text'
interface LegendProps {
  useIdealProfile?: boolean
}

const Legend = ({ useIdealProfile }: LegendProps) => {
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <ItemLegend>
        <RectangleSkew color="#C6016B" />
        <TextLegend>Resultado del usuario</TextLegend>
      </ItemLegend>
      {useIdealProfile && (
        <ItemLegend>
          <RectangleSkew />
          <TextLegend>Perfil ideal</TextLegend>
        </ItemLegend>
      )}
    </div>
  )
}
interface Result {
  FI: number
  BI: number
  BD: number
  FD: number
}
interface IdealProfile {
  FI: number
  FD: number
  BI: number
  BD: number
  Color?: string
}

interface ChildhoodAndYouth {
  FI: number
  FD: number
  BI: number
  BD: number
}
interface IProps {
  result: Result
  idealProfile?: IdealProfile
  hideLegend?: boolean
  showPolarArea?: boolean
  dominantQuadrant?: string
  childhood?: ChildhoodAndYouth
}

const BrainMetricsContainer = (props: IProps) => {
  const { result, idealProfile, hideLegend, showPolarArea, dominantQuadrant, childhood } = props
  const { FI, BI, BD, FD } = result

  return (
    <Container>
      {dominantQuadrant !== 'No interpretable' ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <ScaleContainer>
                <Scale>
                  <p>0</p>
                  <p>40</p>
                  <p style={{ marginLeft: '6px' }}>60</p>
                  <p style={{ marginRight: '-6px' }}>80</p>
                  <p>100</p>
                  <p>+100</p>
                </Scale>
                <Scale>
                  <ItemScale color="#F02401" style={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }}>
                    <ItemScaleText color="#F02401">Bajo</ItemScaleText>
                  </ItemScale>
                  <ItemScale color="#FF8402">
                    <ItemScaleText color="#FF8402">Prom. Bajo</ItemScaleText>
                  </ItemScale>
                  <ItemScale color="#674531">
                    <ItemScaleText color="#674531">Prom. Alto</ItemScaleText>
                  </ItemScale>
                  <ItemScale color="#2C9430">
                    <ItemScaleText color="#2C9430">Alto</ItemScaleText>
                  </ItemScale>
                  <ItemScale color="#9E9E9E" style={{ borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }}>
                    <ItemScaleText color="#9E9E9E">Muy alto</ItemScaleText>
                  </ItemScale>
                </Scale>
              </ScaleContainer>
              <BrainMetrics
                dominantQuadrant={dominantQuadrant}
                FI={FI}
                BI={BI}
                BD={BD}
                FD={FD}
                idealProfile={idealProfile}
                childhood={childhood}
                showPolarArea={showPolarArea || false}
              />
            </div>
          </div>
          <LegendContainer>{!hideLegend && <Legend useIdealProfile={idealProfile !== undefined} />}</LegendContainer>
        </>
      ) : (
        <H2>Prueba no interpretada</H2>
      )}
    </Container>
  )
}

export default BrainMetricsContainer
