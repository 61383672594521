import styled from 'styled-components';

const Container = styled.div`
    cursor: pointer;
`;

interface IProps {
    width?: string;
    height?: string;
    onClick?: () => void;
}

const PlusWithCircle = (props: IProps) => {
    const { width, height, onClick } = props;


    return (
        <Container onClick={onClick}>
            <svg width={width || '24px'} height={height || '24px'}  fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z" stroke="#2D749C" strokeWidth="1.5" strokeLinejoin="round"/>
                <path d="M12 8V16" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8 12H16" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </Container>
    );
};

export default PlusWithCircle;





