import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { EMPTY_STRING } from 'constants/global.constants'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { SETTINGS_HEADERS } from '../../constants'
import { BluesiteTableWrapper, Container, ManageAdminText } from './styles.styled'
import { Subtitle } from '../../styles.styled'
import useProcess from '../../hooks/useProcess'
import { useSelector, useDispatch } from 'react-redux'
import {
  allPlatformAdminsSelector,
  processDetailSelector,
  setAdminIdsToSave,
  setTableChanged,
  totalPagesSelector,
} from '../../store'
import { useLocation } from 'react-router-dom'
import { useNotification } from 'lib/context/notification.context'
const PlatformAdminsCredentials = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { getAllPlatformAdmins } = useProcess()
  const processId = location.state?.processId as number
  const allPlatformAdmins = useSelector(allPlatformAdminsSelector)
  const processDetail = useSelector(processDetailSelector)
  const totalPages = useSelector(totalPagesSelector)
  const userId = useSelector((state: any) => state.user.userId)
  const { role } = useSelector((state: any) => state.auth)
  const [currentPage, setCurrentPage] = useState(1)
  const [adminIds, setAdminIds] = useState<Set<number>>(new Set())
  const [initialState, setInitialState] = useState<Set<number>>(new Set())

  const { getWarning } = useNotification()

  useEffect(() => {
    const inProcessAdminIDs = allPlatformAdmins ? allPlatformAdmins
      .filter((admin) => admin.inprocessid === true && admin.userId != null)
      .map((admin) => admin.userId) as number[] : []

    setAdminIds(new Set(inProcessAdminIDs))
    setInitialState(new Set(inProcessAdminIDs))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlatformAdmins])

  useEffect(() => {
    getAllPlatformAdmins(currentPage, processId)
  }, [currentPage, getAllPlatformAdmins, processId])

  useEffect(() => {
    dispatch(setAdminIdsToSave([...new Set(adminIds)]))
  }, [adminIds, dispatch])

  useEffect(() => {
    const hasNewIds = Array.from(adminIds).some((id) => !initialState.has(id))
    const hasRemovedIds = Array.from(initialState).some((id) => !adminIds.has(id))

    const isDirty = hasNewIds || hasRemovedIds

    dispatch(setTableChanged(isDirty))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminIds])

  const handleCheckboxChange = useCallback(
    (action: SetStateAction<Set<number>>) => {
      setAdminIds((prev) => {
        const newIds = typeof action === 'function' ? action(prev) : action
        if (!newIds.has(userId)) {
          if (role !== 'super_platform_admin') getWarning('No puedes eliminarte a ti mismo de la lista de administradores.')
          return new Set([...newIds, userId])
        }

        return newIds
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAdminIds, userId]
  )

  return (
    <Container>
      <Subtitle>Permisos de administradores</Subtitle>
      <ManageAdminText>
        Selecciona a los administradores que tendrán acceso a los resultados del proceso {processDetail?.processName}.
      </ManageAdminText>
      <BluesiteTableWrapper>
        <BluesiteTableComponent
          data={allPlatformAdmins?.filter((admin) => admin.status_name === 'Active') || []}
          headers={SETTINGS_HEADERS}
          currentPage={currentPage}
          selectedItems={adminIds}
          setSelectedItems={handleCheckboxChange}
          searchBar={false}
          checks={true}
          searchLabel={EMPTY_STRING}
          totalPages={totalPages}
          handlePagination={(page: number) => {
            setCurrentPage(page)
          }}
          hideMainCheckbox={true}
        />
      </BluesiteTableWrapper>
    </Container>
  )
}

export default PlatformAdminsCredentials
