import * as Yup from 'yup';

export const validationEditBasicInfoSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  level_id: Yup.string().when('Competencias', {
    is: true,
    then: Yup.string().required('"El level es requerida"'),
    otherwise: Yup.string().nullable(),
  }),
  Competencias: Yup.boolean(),
  description: Yup.string().required("La descripción es requerida")
})

export const validationEditJobPositionSchema = Yup.object().shape({
  typical_positions: Yup.array()
    .min(1, "Al menos un cargo de competencia debe ser seleccionado"),
})

export const validationIdealProfileSchema = Yup.object().shape({
  ideal_profile_data: Yup.object().shape({
    alert_level_range: Yup.number().min(1, "El valor mínimo es 1").required("El ID del nivel de alerta es requerido"),
    profile_values: Yup.array()
      .of(
        Yup.object().shape({
          quadrant: Yup.string().required("El cuadrante es requerido"),
          priority: Yup.number().min(1, "El valor mínimo es 1").required("La prioridad es requerida"),
          min: Yup.number().min(10, "El valor mínimo es 1").required("El valor mínimo es requerido"),
          max: Yup.number().min(10, "El valor mínimo es 1").required("El valor máximo es requerido"),
        })
      )
      .min(1, "Al menos un valor de perfil ideal debe ser seleccionado"),
  }),
})

export const validationCreateRoleFamilySchema = [
  Yup.object().shape({
    name: Yup.string()
      .required("El nombre es requerido")
      .min(3, "El nombre debe tener al menos 3 caracteres")
      .max(50, "El nombre debe tener máximo 50 caracteres"),
    "Betesa Premium": Yup.boolean()
      .when('Competencias', {
        is: false,
        then: Yup.boolean().required("El tipo de prueba es requerido"),
      }),
    Competencias: Yup.boolean().when('Betesa Premium', {
      is: false,
      then: Yup.boolean().required("El tipo de prueba es requerido"),
    }),
    level_id: Yup.number().when('Competencias', {
      is: true,
      then: Yup.number().required("El nivel es requerido"),
    }),
    description: Yup.string().required("La descripción es requerida"),
  }, [['Betesa Premium', 'Competencias']]),
  Yup.object().shape({
    family_skills_data: Yup.array()
      .of(
        Yup.string()
        .required("Se requiere por lo menos una habilidad asociada a la familia de rol")
        .min(3, "La habilidad debe tener al menos 3 caracteres")
        .max(250, "La habilidad debe tener máximo 250 caracteres")
        )
      .min(1, "Debes escribir al menos una habilidad")
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    competence_skills: Yup.array()
      .of(
        Yup.object().shape({
          skill_id: Yup.number().required("El ID de la habilidad es requerido"),
          weight: Yup.string().required("El peso de la habilidad es requerido"),
        })
      ).required("La prioridad es requerida")
  }),
  Yup.object().shape({
    ideal_profile_data: Yup.object().shape({
      alert_level_id: Yup.string().min(1, "Escoja un nivel de alerta").required("El nivel de alerta es requerido"),
      profile_values: Yup.array()
        .of(
          Yup.object().shape({
            quadrant: Yup.string().required("El cuadrante es requerido"),
            priority: Yup.number().min(1, "El valor mínimo es 1").required("La prioridad es requerida"),
            min: Yup.number().min(10, "El valor mínimo es 1").required("El valor mínimo es requerido"),
            max: Yup.number().min(10, "El valor mínimo es 1").required("El valor máximo es requerido"),
          })
        )
        .min(1, "Al menos un valor de perfil ideal debe ser seleccionado"),
    }),
  }),
  Yup.object().shape({}),
  Yup.object().shape({}),
]
