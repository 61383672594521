import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Title = styled.h3`
  margin: 0 0 10px 0;
`;

export const ModalBody = styled.div`
  width: 75%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Form = styled.form`
  width: 100%;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
  align-items: center;
`;

export const AddButton = styled.div`
  display:flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  cursor:pointer;
  gap:5px;
`
export const AddIcon = styled.svg`
  width:24px;
  height:24px;
  color:#2D749C;
  fill:#2D749C;
`
export const GridModal = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;
