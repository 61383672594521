import { H2 } from "views/components/UI/Text"
import { ItemDashboardProps } from "../../../store"
import { ContainerItem } from "./styles.styled"


const ItemDashboard = ({ text, quantity, showDivider, color, onClick }: ItemDashboardProps) => {
  return (
    <ContainerItem
      showDivider={showDivider}
      onClick={onClick && onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <H2 style={{ color: color ? color : '#2D749C' }}>{quantity}</H2>
      <p style={{ color: color ? color : 'rgb(121, 122, 124' }}>{text}</p>
    </ContainerItem>
  )
}

export default ItemDashboard
