import React, { useState } from 'react'
import { Container } from './styled'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { BetesaResultsResponse } from 'views/containers/TeamMap/store/entities'

interface Props {
  results: BetesaResultsResponse
  handlePagination: (page: number) => void
}

//Main component
const TeamMapGrid = (props: Props) => {
  const { results, handlePagination } = props
  const { users } = results
  const [currentPage, setCurrentPage] = useState(1)

  const headers = [
    {
      key: 'full_name',
      name: 'Nombre',
    },
    {
      key: 'name',
      name: 'Cargo',
    },
    {
      key: 'FI',
      name: 'FI',
    },
    {
      key: 'BI',
      name: 'BI',
    },
    {
      key: 'BD',
      name: 'BD',
    },
    {
      key: 'FD',
      name: 'FD',
    },
    {
      key: 'domination',
      name: 'DOM',
    },
    {
      key: 'alert_level',
      name: 'ALERTA',
    },
    {
      key: 'quadrant_develop',
      name: 'PLAN',
    },
  ]

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    handlePagination(page)
  }

  return (
    <Container>
      <BluesiteTableComponent
        data={users}
        totalPages={results.total_pages}
        headers={headers}
        searchBar={false}
        handlePagination={handlePageChange}
        currentPage={currentPage}
        searchLabel=""
      />
    </Container>
  )
}

export default TeamMapGrid
