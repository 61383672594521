export const theme: any = {
  colors: {
    primarys: {
      primary100: '#D1E6F1',
      primary200: '#387394',
      primary300: '#0B3D58',
      primary400: '#212B31',
    },
    secondarys: {
      secondary100: '##E6ABCB',
      secondary200: '#C86199',
      secondary300: '#8F215D',
      secondary400: '#31051D',
    },
    complementarys: {
      complementary100: '#EEDBFE',
      complementary200: '#C27BFF',
      complementary300: '#6A0CBB',
      complementary400: '#2B064B',
    },
    information: {
      information100: '#DDF3F8',
      information200: '#78D6EB',
      information300: '#16869F',
      information400: '#065A6C',
    },
    success: {
      success100: '#DBF0CE',
      success200: '#9ED47D',
      success300: '#459F0E',
      success400: '#28590A',
    },
    warning: {
      warning100: '#FFEEDA',
      warning200: '#FFCE94',
      warning300: '#C67C26',
      warning400: '#764204',
    },
    error: {
      error100: '#F5E0E0',
      error200: '#F1AEAE',
      error300: '#C91B1B',
      error400: '#621111',
    },
    grays: {
      gray100: '#FFFFFF',
      gray200: '#F4F6F8',
      gray300: '#DFE3E8',
      gray400: '#C4CDD5',
      gray500: '#919EAB',
      gray600: '#637381',
      gray700: '#454F5B',
      gray800: '#1F2124',
      gray900: '#24212D',
    },
    states: {
      active: '#24212D',
      hover: '#387394', // no definido
      focus: '#0B3D58', // no definido
      disabled: '#24212D',
    },
    text: '#24212D',
    label: '#797A7C',
    background: '#FAFCFF',

    //* ****** estilos viejos de plantilla para eliminar ******* *//
    main: '#440381',
    accent: '#D72638',
    // error: '#ff0000',
    white: '#fff',
    black: '#000',
  },
  // tipografias
  fonts: {
    primary: 'Inter',
    secondary: 'Public Sans',
  },
  // tamaños de tipografias
  fontSizes: {
    small: '12px',
    medium: '14px',
    large: '16px',
    xlarge: '18px',
  },
  // espaciado entre elementos
  spacing: {
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '32px',
  },
}
