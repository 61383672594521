export const HEADERS = [
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'name',
    name: 'Nombre',
  }
]
export const OPTIONS = [
  { value: 'C.C.', label: 'Documento de identidad' },
  { value: 'C.E.', label: 'Cédula de extranjería' },
  { value: 'Pasaporte', label: 'Pasaporte' },
  { value: 'T.I.', label: 'Tarjeta de identidad' },
]

export const COMPANY_PATH = "/company";
export const COMPANIES_UPDATE_PATH = "/companies/update";

export const ADMIN_PLATFORM_TABS = {
  create: 'create',
  update: 'status/update',
  delete: 'delete',
}

