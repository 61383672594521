/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Container,
  GridContainer,
  ColumnGridSubContainer,
  Grid3X3,
  ItemGrid,
  TextBold,
  BodyItemGrid,
  HeaderContainer,
  Header,
  Title,
  StandardButton,
  SubHeader,
  ChecksContainer,
  UsersButton,
  SubContainer,
  NameContainer,
  ToolTipContainer,
  ToolTipTitle,
  Footer,
  ModalSaveContainer,
  ModalSaveText,
  ButtonDisabled,
  BottomButtonsContainer
} from './styled'
import { P } from 'views/components/UI/Text/'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import RoundedCheck from 'views/components/UI/InputRadio'
import iconInfo from '../../../components/assets/iconInfo.png'
import BackButton from 'views/components/UI/BackButton'
import Modal from 'views/components/UI/Modal'
import { TextField } from '@mui/material'
import CareerPlan from '../CareerPlan'
import IdealProfileModal from './idealProfileModal'
import HistoryNineBox from '../HistoryNineBox'
import getNineBoxAnalytics from 'services/competence/GetNineBoxAnalytics'
import {
  INineBoxAnalytics,
  INineBoxResponse,
  ISavedResponse,
  User9Box,
  RNineBox,
  User,
} from 'views/screens/Platform-administrator/Analytics/constants/types'
import { useNotification } from 'lib/context/notification.context'
import saveNineBox from 'services/competence/saveNineBox'
import getSavedNineBox from 'services/competence/getSavedNineBox'
import {
  NoDataContainer,
  NoDataIcon,
  NoDataSubContainer,
  NoDataText,
  TextContainer,
} from 'views/screens/Platform-administrator/Analytics/styled'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import UsersModal from './usersModal'
import updateNineBox from 'services/competence/updateNineBox'
import TooltipComponent from 'views/components/Tooltip'
import CloseIcon from 'assets/icons/CloseIcon'
import { useTranslation } from 'react-i18next'

const Bullet = () => <span style={{ color: 'black' }}> • </span>

export interface INineBox {
  processId: string
  checksIdeal: { betesa: boolean; competences: boolean }
  processName: string
  textsNinebox?: any[]
}

const NineBox: FC<INineBox> = ({ processId, checksIdeal, processName, textsNinebox }) => {
  const { t } = useTranslation()
  const [typeNineBox, setTypeNineBox] = useState<'potential' | 'career' | '' | string>('potential')
  const [checks, setChecks] = useState({ betesa: checksIdeal.betesa, competences: checksIdeal.competences })
  const [showModal, setShowModal] = useState(false)
  const [saveInput, setSaveInput] = useState('')
  const [showIdealProfileModal, setShowIdealProfileModal] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [nineBoxData, setNineBoxData] = useState<INineBoxResponse>({} as INineBoxResponse)
  const [boxes, setBoxes] = useState<User9Box[][]>(Array.from({ length: 9 }, () => []))
  const [savedNineBox, setSavedNineBox] = useState<ISavedResponse>({} as ISavedResponse)
  const [savedNineBoxPage, setSavedNineBoxPage] = useState(1)
  const [disabledCheckBetesa, setDisabledCheckBetesa] = useState(false)
  const [disabledCheckCompetences, setDisabledCheckCompetences] = useState(false)
  const [triggerApiCall, setTriggerApiCall] = useState(0)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [search, setSearch] = useState('' as string)
  const [loading, setLoading] = useState(false)
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [savedUsers, setSavedUsers] = useState<number[]>([])
  const [fullObjectSavedUsers, setFullObjectSavedUsers] = useState<User[]>([] as User[])
  const [refreshSavedNineBox, setRefreshSavedNineBox] = useState(false)
  const [triggerEffect, setTriggerEffect] = useState(false)
  const [isCareerPlan, setIsCareerPlan] = useState(false)
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_, forceRender] = useState(false)
  const historySelectedNineBox = useRef({} as RNineBox)
  const historyCareerNineBox = useRef({} as RNineBox)
  const cleanSelectedHistory = useRef<any>(() => {})

  const { getError, getSuccess } = useNotification()

  const clearHistorySelectedNineBox = () => {
    historyCareerNineBox.current = {} as RNineBox
    forceRender((prevState) => !prevState)
  }

  cleanSelectedHistory.current = () => {
    setTimeout(() => {
      historySelectedNineBox.current = {} as RNineBox
    }, 4000)
  }

  const handleSelectedHistory = (data: RNineBox) => {
    if (data?.career_plan) {
      historyCareerNineBox.current = data
    } else {
      historySelectedNineBox.current = data
    }
    setSavedNineBoxPage(1)
    setShowHistory(false)
    setTimeout(() => {
      setTriggerEffect((trigger) => !trigger)
    }, 0)
  }

  const updateChecks = (data: any) => {
    let newBetesa = true
    let newCompetences = true

    if (!data?.betesa_ideal_profile?.profile_values?.length) {
      newBetesa = false

      if (isInitialRender) {
        setDisabledCheckBetesa(true)
      }
    }
    if (!data?.competences_ideal_profile?.length) {
      newCompetences = false

      if (isInitialRender) {
        setDisabledCheckCompetences(true)
      }
    }

    if (newBetesa !== checks.betesa || newCompetences !== checks.competences) {
      setChecks((prevChecks) => ({
        ...prevChecks,
        betesa: newBetesa,
        competences: newCompetences,
      }))
    }
  }

  useEffect(() => {
    if (nineBoxData?.user_9box?.length) {
      setFullObjectSavedUsers(
        nineBoxData?.user_9box?.map((user) => {
          return { user_id: user.user_id, full_name: user.full_name, document: user.document, email: user.email }
        })
      )
    }
  }, [nineBoxData])

  useEffect(() => {
    if (!checksIdeal.betesa && !checksIdeal.competences) return

    if (typeNineBox === 'potential') {
      setLoading(true)
      let body: INineBoxAnalytics

      if (historySelectedNineBox?.current.id) {
        setSavedUsers(historySelectedNineBox.current.users)
        body = {
          process_id: Number(historySelectedNineBox.current.process_id),
          is_betesa: historySelectedNineBox.current.is_betesa,
          is_competences: historySelectedNineBox.current.is_competences,
          users: historySelectedNineBox.current.users,
          career_plan: false,
        }
      } else {
        body = {
          process_id: Number(processId),
          is_betesa: checks?.betesa,
          is_competences: checks?.competences,
          users: savedUsers,
          career_plan: false,
        }
        if (savedUsers && savedUsers.length === 0) {
          const { users, ...bodyWithoutUsers } = body
          body = bodyWithoutUsers
        }
      }

      getNineBoxAnalytics(body).then(
        (res) => {
          if (res?.data) {
            setNineBoxData(res.data)

            const newBoxes: User9Box[][] = Array.from({ length: 9 }, () => [])
            res?.data.user_9box?.forEach((user: User9Box) => {
              newBoxes[user.nine_box - 1].push(user)
            })

            cleanSelectedHistory.current()
            setBoxes(newBoxes)
            updateChecks(res?.data)
            setIsInitialRender(false)
            setLoading(false)
          }
        },
        (error) => {
          console.error('Error buscando los 9box: ', error)
          getError('Ha ocurrido un error al cargar los 9box')
          setLoading(false)
        }
      )
    }
  }, [typeNineBox, processId, triggerApiCall, savedUsers, triggerEffect])

  const limit = 5

  useEffect(() => {
    getSavedNineBox(Number(processId), limit, savedNineBoxPage, search).then(
      (res) => {
        setSavedNineBox(res)
      },
      (error) => {
        console.error('Error buscando los 9box: ', error)
        getError('Ha ocurrido un error al cargar los 9box')
      }
    )
  }, [typeNineBox, processId, savedNineBoxPage, search, refreshSavedNineBox])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveInput(e.target.value)
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target

    if (
      (name === 'betesa' && !checks.competences && !checked) ||
      (name === 'competences' && !checks.betesa && !checked)
    ) {
      return
    }

    setTriggerApiCall((prevState) => prevState + 1)

    setChecks((prevChecks) => ({
      ...prevChecks,
      [name]: checked,
    }))
  }

  const handleSave = () => {
    setShowModal(true)
  }

  const onSave = async () => {
    const inputValue = saveInput

    const matchedBox = await getSavedNineBox(Number(processId), 5, 1, inputValue)

    if (!!matchedBox) {
      const matchedId = matchedBox.nine_boxes[0].id
      const body = {
        name: saveInput,
        is_betesa: checks?.betesa,
        is_competences: checks?.competences,
        career_plan: false,
        users: nineBoxData?.user_9box?.map((user) => user.user_id),
      }

      updateNineBox(matchedId, body).then(
        (res) => {
          setRefreshSavedNineBox((prev) => !prev)
          if (res.status === 'OK') {
            getSuccess('9box actualizado correctamente')
          }
        },
        (error) => {
          console.error('Error actualizando el 9box: ', error)
          getError('Ha ocurrido un error al actualizar el 9box')
        }
      )
      setShowModal(false)
      return
    }

    saveNineBox({
      name: saveInput,
      process_id: Number(processId),
      is_betesa: checks?.betesa,
      is_competences: checks?.competences,
      career_plan: false,
      users: nineBoxData?.user_9box?.map((user) => user.user_id),
    }).then(
      (res) => {
        setRefreshSavedNineBox((prev) => !prev)
        if (res.status === 'OK') {
          getSuccess('9box guardado correctamente')
        }
      },
      (error) => {
        console.error('Error guardando el 9box: ', error)
        getError('Ha ocurrido un error al guardar el 9box')
      }
    )

    setShowModal(false)
  }

  const handleClickIdealProfile = () => {
    setShowIdealProfileModal(true)
  }

  const handleClickHistory = () => {
    setShowHistory(true)
  }

  const handleCloseHistory = (value: boolean) => {
    setShowHistory(value)
  }

  const handleHistorySearch = (input: string) => {
    setSavedNineBoxPage(1)
    setSearch(input)
  }

  const toolTipMessage = (user: User9Box) => {
    return (
      <ToolTipContainer>
        {user?.betesa_adjustment_level ? (
          <ToolTipTitle>Nivel de ajuste Betesa: {user.betesa_adjustment_level}-{user.adjustment_level_score > 100 ? 100 : user.adjustment_level_score}% </ToolTipTitle>
        ) : null}
        {user?.competences_adjustment_level ? (
          <ToolTipTitle>Nivel de ajuste {t('global.common.skillText')}: {user.competences_adjustment_level}-{user.competences_score_data  > 100 ? 100 : user.competences_score_data}% </ToolTipTitle>
        ) : null}
        {user?.performance ? <ToolTipTitle>Desempeño: {user.performance / 10}</ToolTipTitle> : null}
      </ToolTipContainer>
    )
  }


  const handleUsersModal = () => {
    setShowUsersModal(true)
  }

  if (
    !nineBoxData?.betesa_ideal_profile?.profile_values?.length &&
    !nineBoxData?.competences_ideal_profile?.length &&
    !loading
  ) {
    return (
      <NoDataContainer>
        <NoDataSubContainer>
          <NoDataIcon src={iconInfo} />
          <TextContainer>
            <NoDataText>No hay un perfil ideal en este proceso</NoDataText>
          </TextContainer>
        </NoDataSubContainer>
      </NoDataContainer>
    )
  }

  if (nineBoxData?.user_9box?.length === 0 && !loading) {
    return (
      <NoDataContainer style={{ marginTop: '-12px' }}>
        <NoDataSubContainer>
          <NoDataIcon src={iconInfo} />
          <TextContainer>
            <NoDataText>Los usuarios no han contestado la prueba</NoDataText>
          </TextContainer>
        </NoDataSubContainer>
      </NoDataContainer>
    )
  }

  if (loading) {
    return <ModalLoader isLoading={loading} />
  }

  return (
    <Container>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <RoundedCheck
          checked={typeNineBox === 'potential'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTypeNineBox(e.target.defaultValue)
            setIsCareerPlan(false)
          }}
          value="potential"
          label="Para potencial"
          tooltipText="Este 9BOX incluye el nivel de ajuste de las pruebas aplicadas en el proceso."
        />
        <RoundedCheck
          checked={typeNineBox === 'career'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTypeNineBox(e.target.defaultValue)
          }}
          value="career"
          label="Para plan carrera"
          tooltipText="Este 9BOX incluye las pruebas aplicadas en el proceso más un puntaje de desempeño individual que tú debes ingresar."
        />
      </div>
      <>
        <HistoryNineBox
          show={showHistory}
          close={handleCloseHistory}
          data={savedNineBox}
          handlePagination={setSavedNineBoxPage}
          search={handleHistorySearch}
          historySelectedNineBox={handleSelectedHistory}
          setSavedInput={setSaveInput}
          setIsCareerPlan={setIsCareerPlan}
          setTypeNineBox={setTypeNineBox}
          savedNineBoxPage={savedNineBoxPage}
        />
      </>
      {typeNineBox === 'potential' && !isCareerPlan ? (
        <>
          <UsersModal
            show={showUsersModal}
            close={setShowUsersModal}
            processId={processId}
            setSavedUsers={setSavedUsers}
            setFullObjectSavedUsers={setFullObjectSavedUsers}
            fullObjectSavedUsers={fullObjectSavedUsers}
          />
          <IdealProfileModal
            show={showIdealProfileModal}
            options={checks}
            data={nineBoxData}
            close={setShowIdealProfileModal}
            processName={processName}
          /> 

          <Modal styleContent={{ width: '530px', height: '240px' }} show={showModal} setShow={setShowModal} hideCloseButton>
            <ModalSaveContainer>
              <BottomButtonsContainer>
                <ModalSaveText>Guardar 9Box</ModalSaveText> 
                <CloseIcon 
                  onClick={() => setShowModal(false)}  
                  color='#000'
                />
              </BottomButtonsContainer>
              <TextField
                type="text"
                value={saveInput}
                onChange={handleInputChange}
                label="Nombre de 9Box"
                sx={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029', width: '510px' }}
              />
              {saveInput.length ? (
                <BottomButtonsContainer>
                  <BackButton style={{width: 'auto',padding: '24px 0px'}} text='Cancelar'  type='button' onClick={() => setShowModal(false)} />
                  <StandardButton style={{ width: '15%', alignSelf: 'flex-end' }} onClick={onSave}>
                  Guardar
                </StandardButton>
                </BottomButtonsContainer>
              ) : (
                < BottomButtonsContainer>
                  <BackButton style={{width: 'auto',padding: '24px 0px'}} text='Cancelar'  type='button' onClick={() => setShowModal(false)} />
                  <ButtonDisabled style={{  alignSelf: 'flex-end' }}>Guardar</ButtonDisabled>
                </BottomButtonsContainer>
                
              )}
            </ModalSaveContainer>
          </Modal>
          <HeaderContainer>
            <Header>
              <Title>Selecciona las variables para ubicar al usuario evaluado en una de las cajas de 9BOX</Title>
              {checks.betesa || checks.competences ? (
                <StandardButton onClick={handleClickIdealProfile}>Ver perfil ideal</StandardButton>
              ) : (
                <ButtonDisabled>Ver perfil ideal</ButtonDisabled>
              )}
            </Header>
            <SubHeader>
              <ChecksContainer>
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    style={{
                      marginRight: '50px',
                    }}
                    control={
                      <Checkbox
                        checked={checks.betesa}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e)}
                        name="betesa"
                        disabled={disabledCheckBetesa}
                      />
                    }
                    label="Betesa"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checks.competences}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e)}
                        name="competences"
                        disabled={disabledCheckCompetences}
                      />
                    }
                    label={t('global.common.skillText')}
                  />
                </FormGroup>
              </ChecksContainer>
              <UsersButton onClick={handleUsersModal}>Administrar usuarios</UsersButton>
            </SubHeader>
          </HeaderContainer>
          <SubContainer>
            <ColumnGridSubContainer width="1%">
              <TextBold color="#C6016B" transform="rotate(-90deg)">
                Potencial
              </TextBold>
            </ColumnGridSubContainer>
            <ColumnGridSubContainer width="99%">
              <GridContainer>
                <Grid3X3>
                  <ItemGrid gridArea="top-left">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">6. Potencial por identificar</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 6)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[5].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                  <ItemGrid gridArea="top-center">
                    <ItemGrid gridArea="middle-right" background='#FBF1F6'>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <TextBold margin='0px -10px 0px 0px' color="#2D749C">2. Alto potencial</TextBold>
                        <TooltipComponent 
                          tooltipText={ textsNinebox?.find((text) => text.box === 2)?.description_box || '' }
                          placement='top'
                          iconColor='#2D749C'
                        />
                      </div>
                      <BodyItemGrid>
                        {boxes[1].map((user) => (
                          <NameContainer key={user.user_id}>
                            <P>
                              <Bullet />
                              {user.full_name}
                            </P>
                            <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                          </NameContainer>
                        ))}
                      </BodyItemGrid>
                    </ItemGrid>
                  </ItemGrid>

                  <ItemGrid gridArea="top-right" >
                    <ItemGrid gridArea="middle-right" background='#FBF1F6'>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <TextBold margin='0px -10px 0px 0px' color="#2D749C">1. Potencial Top</TextBold>
                        <TooltipComponent 
                          tooltipText={ textsNinebox?.find((text) => text.box === 1)?.description_box || '' }
                          placement='top'
                          iconColor='#2D749C'
                        />
                      </div>
                      <BodyItemGrid>
                        {boxes[0].map((user) => (
                          <NameContainer key={user.user_id}>
                            <P>
                              <Bullet />
                              {user.full_name}
                            </P>
                            <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                          </NameContainer>
                        ))}
                      </BodyItemGrid>
                    </ItemGrid>
                  </ItemGrid>

                  <ItemGrid gridArea="middle-left">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">7. Colaborador especialista</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 7)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[6].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                  <ItemGrid gridArea="middle-center">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">5. Potencial Latente</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 5)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[4].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                  <ItemGrid gridArea="middle-right" >
                  <ItemGrid gridArea="middle-right" background='#FBF1F6'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">3. Potencial Superior</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 3)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[2].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                  </ItemGrid>
                  <ItemGrid gridArea="bottom-left">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">9. Potencial deficiente</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 9)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[8].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                  <ItemGrid gridArea="bottom-center">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">8. Bajo potencial</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 8)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[7].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P >
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                  <ItemGrid gridArea="bottom-right">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <TextBold margin='0px -10px 0px 0px' color="#2D749C">4. Potencial moderado</TextBold>
                      <TooltipComponent 
                        tooltipText={ textsNinebox?.find((text) => text.box === 4)?.description_box || '' }
                        placement='top'
                        iconColor='#2D749C'
                      />
                    </div>
                    <BodyItemGrid>
                      {boxes[3].map((user) => (
                        <NameContainer key={user.user_id}>
                          <P>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <TooltipComponent tooltipText={toolTipMessage(user) as any} placement='top' />
                        </NameContainer>
                      ))}
                    </BodyItemGrid>
                  </ItemGrid>
                </Grid3X3>
              </GridContainer>
            </ColumnGridSubContainer>
          </SubContainer>
          <Footer>
            <UsersButton onClick={handleClickHistory}>Consultar histórico</UsersButton>
            <StandardButton onClick={handleSave}>Guardar</StandardButton>
          </Footer>
          {loading && <ModalLoader isLoading={loading} />}
        </>
      ) : (
        <CareerPlan
          processId={processId}
          processName={processName}
          checksIdeal={checksIdeal}
          setIsCareerPlan={setIsCareerPlan}
          setShowHistory={setShowHistory}
          savedNineBox={savedNineBox}
          setRefreshSavedNineBox={setRefreshSavedNineBox}
          historySelectedNineBox={historyCareerNineBox.current}
          clearHistorySelectedNineBox={clearHistorySelectedNineBox}
          triggerEffect={triggerEffect}
        />
      )}
    </Container>
  )
}

export default NineBox
