export const TALENTS_HEADER = 'Bluesite. | Betesa.™'

export const SKILLS_HEADER = 'Bluesite. | 3D Skills.™'

export const FIRST_NAME = 'Andrea Carolina'

export const LAST_NAME = 'Fonseca Rodriguez'

export const FAKE_PERCENTIL = 70
export const FAKE_IDEAL_PROFILE = 80

export interface Competency {
    competence: string
    score: number
    percentilValue: number
    competence_score:number
  }
  
export interface Group {
    competences: Competency[]
    group_name: string
  }
export  interface HeaderProps {
    isSkills?: boolean
    name:string
  }

  export const calculateTotalGroupsPages = (groups: any[]) => {
    let totalGroups = 0;
  
    for (const group of groups) {
      if (group.group_name) {
        totalGroups += 1;
      }
    }
  
    return totalGroups;
  };
  
  export const calculateTotalIdealProfilePages = (data: any) => {
    const groups = Object.values(data).filter((entry: any) => 'group_name' in entry);
    const singleElementGroups: any[] = [];
    const smallGroups: any[] = [];
    const mediumGroups: any[] = [];
    const largeGroups: any[] = [];
  
    groups.forEach((entry: any) => {
        const competencesCount = entry.competences.length;
        if (competencesCount > 5) {
            largeGroups.push(entry);
        } else if (competencesCount < 3) {
            mediumGroups.push(entry);
        } else if (competencesCount === 1) {
            singleElementGroups.push(entry);
        } else {
            smallGroups.push(entry);
        }
    });
  
    const totalPages = groups.length;
  
    return totalPages;
  };
