import axios from 'lib/axios'

const getBrainResults = async (id: any) => {
  const brainResults = await axios
    .get(`/betesa_results/users/ideal_profile/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return brainResults
}

export default getBrainResults
