import { FC, useState, useEffect } from 'react'
import { MainLayout } from 'views/layouts'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonCancel, ButtonEdit, ButtonSave, ButtonsContainer, CheckContainer, Row, TextProcess } from './styled'
import { BackButton } from 'views/components/UI/Buttons'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { ActionViewInfoUserByCompany } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import Select from 'views/components/UI/Select'
import { useNotification } from 'lib/context/notification.context'
import getProcessByCompany from 'services/companies/getProcessByCompany'
import getUserByProcess from 'services/companies/getUsersByProcess/getUserByProcess'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { HEADERS } from './constants'
import { Check } from '@mui/icons-material'
import updateUser from 'services/companies/updateUser'
import { useTranslation } from 'react-i18next'

interface UserProps {
  complete?: boolean
  active?: boolean
}

const UsersByCompany: FC = () => {
  const { getError, getWarning, getSuccess } = useNotification()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const companyId = location?.state?.companyId
  const companyName = location?.state?.companyName
  const [users, setUsers] = useState<any[] | []>([])
  const [process, setProcess] = useState<any[] | []>([])
  const [processSelected, setProcessSelected] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [searchParams, setSearchParams] = useState<string>('')
  const [triggerApi, setTriggerApi] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false)

  useEffect(() => {
    getProcessByCompany(parseInt(companyId)).then((response: any) => {
      if (response.status === 'OK') {
        const data = response.data.processes.map((item: any) => {
          return {
            value: item.process_id,
            label: item.process_name,
          }
        })

        setProcess(data)
      } else {
        getError('error obteniendo procesos por compañía')
        setProcess([])
      }
    })
  }, [companyId, getError])

  const filterUniqueUsers = (usersFilter: any[]) => {
    const uniqueUserIds: { [id: string]: boolean } = {}
    const uniqueUsers: any[] = []

    for (const user of usersFilter) {
      const userKey = `${user.id}_${user.person_id}`

      if (!uniqueUserIds[userKey]) {
        uniqueUserIds[userKey] = true
        uniqueUsers.push(user)
      }
    }

    return uniqueUsers
  }

  useEffect(() => {
    if (searchParams !== '') return

    if (processSelected === 0) {
      getUserByProcess({ company_id: companyId, page: currentPage }).then((response: any) => {
        setLoading(false)

        if (response?.status === 'OK') {
          const uniqueUsers = filterUniqueUsers(response?.data.users)
          setUsers(uniqueUsers)
          setTotalPages(response?.data.total_pages)
          setTableLoading(false)
        } else {
          setLoading(false)
          setTableLoading(false)
          setUsers([])
          setTotalPages(0)
        }
      })
    } else {
      getUserByProcess({ process_id: processSelected, company_id: companyId, page: currentPage }).then(
        (response: any) => {
          setLoading(false)

          if (response?.status === 'OK') {
            const uniqueUsers = filterUniqueUsers(response?.data.users)
            setUsers(uniqueUsers)
            setTotalPages(response?.data.total_pages)
            setTableLoading(false)
          } else {
            setLoading(false)
            setTableLoading(false)
            setUsers([])
            setTotalPages(0)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSelected, companyId, triggerApi])

  useEffect(
    () => {
      if (searchParams === '') {
        setTriggerApi((prev) => !prev)
        return
      }

      if (processSelected === 0) {
        getUserByProcess({ company_id: companyId, searchParams, page: currentPage }).then((response: any) => {
          if (response?.status === 'OK') {
            const uniqueUsers = filterUniqueUsers(response?.data.users)
            setUsers(uniqueUsers)
            setTotalPages(response?.data.total_pages)
            setTableLoading(false)
          } else {
            setUsers([])
            setTotalPages(0)
            setTableLoading(false)
          }
        })
      } else {
        getUserByProcess({ process_id: processSelected, company_id: companyId, searchParams, page: currentPage }).then(
          (response: any) => {
            if (response?.status === 'OK') {
              const uniqueUsers = filterUniqueUsers(response?.data.users)
              setUsers(uniqueUsers)
              setTotalPages(response?.data.total_pages)
              setTableLoading(false)
            } else {
              setUsers([])
              setTotalPages(0)
              setTableLoading(false)
            }
          }
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams, triggerSearch]
  )

  useEffect(() => {
    if (searchParams !== '') {
      setTriggerSearch((prev) => !prev)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSelected])

  const handlePagination = (page: number) => {
    setTriggerApi((prev) => !prev)
    setCurrentPage(page)
    setTriggerSearch((prev) => !prev)
  }

  const handleSearch = (e: any) => {
    setSearchParams(e)
    setCurrentPage(1)
  }

  const AnsweredTestsCheck = (props: UserProps) => {
    const { complete } = props

    return (
      <CheckContainer completeTest={complete}>
        <Check sx={{ color: complete ? '#449f0f' : '#C2C2C2' }} />
      </CheckContainer>
    )
  }

  const IsActiveCheck = (props: UserProps) => {
    const { active } = props

    return (
      <CheckContainer completeTest={active} activeColumn={true}>
        <Check sx={{ color: active ? '#449f0f' : '#C2C2C2' }} />
      </CheckContainer>
    )
  }

  const arrayUsers = users.map((user) => {
    return {
      ...user,
      first_name: user.first_name,
      last_name: user.last_name,
      document: user.document,
      email: user.email,
      active: <IsActiveCheck active={user.active} />,
      complete: <AnsweredTestsCheck complete={user.complete} />,
      edit: (
        isEditing: boolean,
        onEdit: () => void,
        onSave: (rowIndex: number) => void,
        onCancel: () => void,
        rowIndex: number
      ) => {
        if (isEditing) {
          return (
            <ButtonsContainer>
              <ButtonSave onClick={() => onSave(rowIndex)}>Guardar</ButtonSave>
              <ButtonCancel onClick={onCancel}>Cancelar</ButtonCancel>
            </ButtonsContainer>
          )
        }
        return <ButtonEdit onClick={onEdit}>Editar</ButtonEdit>
      },
    }
  })

  const handleSave = (index: any, data: any) => {
    setTableLoading(true)
    updateUser(data.id, data.person_id, data.first_name, data.last_name, data.document, data.email).then(
      (response: any) => {
        if (response === 'Email or document already exists') {
          getWarning(t('global.notifications.updateUserError'))
          setTableLoading(false)
          return
        }
        if (response?.status === 200) {
          getSuccess(t('global.notifications.updateUserSuccess'))
          setTriggerSearch((prev) => !prev)
        }
      }
    )
  }

  return !loading ? (
    <MainLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 35,
          alignItems: 'center',
        }}
      >
        <h2>Usuarios evaluados - {companyName}</h2>
      </div>

      <Row width="40%">
        {process.length ? (
          <>
            <TextProcess>Visualizar</TextProcess>
            <Select
              hideNeverChoice={false}
              value={processSelected}
              label="Procesos"
              options={process}
              onChange={(e) => {
                if (typeof e === 'number') {
                  setProcessSelected(e)
                } else {
                  setProcessSelected(parseInt(e))
                }
              }}
            />
          </>
        ) : (
          <></>
        )}
      </Row>

      <div style={{ marginTop: '20px' }}>
        <BluesiteTableComponent
          onSave={handleSave}
          editable={true}
          ActionComponent={ActionViewInfoUserByCompany}
          totalPages={totalPages}
          handlePagination={handlePagination}
          currentPage={currentPage}
          extraDataAction={{ companyId }}
          data={arrayUsers}
          headers={HEADERS}
          searchBar={true}
          searchFields={['user_name']}
          labelInput="Nombre"
          searchLabel="Buscar usuario por:"
          isAlignLeft={false}
          setSearchParam={handleSearch}
          onSearch={handleSearch}
          loading={tableLoading}
        />
      </div>
      <BackButton onClick={() => navigate(-1)} />
    </MainLayout>
  ) : (
    <ModalLoader isLoading={loading} />
  )
}

export default UsersByCompany
