import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from 'ducks/user'
import getProcesses from 'services/companies/getProcesess'
import { getProcessResume, getUsersByProcess } from 'services/common/process/createProcess/create-process-service'
import { Pagination, ProcessItem } from './types'
import { useTranslation } from 'react-i18next'

interface FiltersFetchProcesses {
  sent?: boolean
  limit?: number
  page?: number
  userId?: number
  processId?: number | number[]
}

export const useFetchProcesses = () => {
  const { t } = useTranslation()
  const { companyId } = useSelector(userSelector)
  const [listProcess, setListProcess] = useState<ProcessItem[]>([])
  const [loadingListProcesses, setLoading] = useState<boolean>(false)
  const [paginationListProcess, setPagination] = useState<Pagination>({
    totalPages: 1,
    totalResults: 0,
  })

  const fetchProcesses = useCallback(
    (filters: FiltersFetchProcesses) => {
      const { sent, limit, page, processId, userId } = filters
      if (loadingListProcesses) return
      setLoading(true)
      getProcesses({ companyId, sent: sent, limit, page, processId, userId})
        .then((res: any) => {
          const responseFormatted: any = res.data.map((processitem: any) => {
            if (processitem.categories?.includes('Competencias')) {
              processitem.categories = processitem.categories.replace('Competencias', t('global.common.skillText'));
            }

            return processitem;
          });

          setPagination({
            totalPages: res.total_pages,
            totalResults: res.total_results,
          })
          setListProcess(responseFormatted)
          setLoading(false)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
        })
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId]
  )

  return { listProcess, loadingListProcesses, fetchProcesses, paginationListProcess }
}

export const useFetchGetResumeProcess = (processId: number) => {
  const { companyId } = useSelector(userSelector)
  const [listProcessTestResume, setProcessTest] = useState<any>({})
  const [listProcessUsersResume, setProcessUsers] = useState<any>({})
  const [loadingProcessResume, setLoadingProcessResume] = useState<boolean>(false)

  const fetchProcessResume = useCallback(() => {
    if (loadingProcessResume) return

    
    getProcessResume(processId)
      .then((response: any) => {
        setProcessTest(response.data.processTest[0])
        setProcessUsers(response.data.processUsers[0])
        setLoadingProcessResume(false)
      })
      .catch((error) => {
        console.error(error)
        setLoadingProcessResume(false)
      })
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  return { listProcessTestResume, listProcessUsersResume, loadingProcessResume, fetchProcessResume }
}

interface FilterGetUsersByProcess {
  limit?: number
  page?: number
  search?: string
  complete?: boolean
}

export const useFetchGetUsersByProcess = (processId: number, filters?: FilterGetUsersByProcess) => {
  const [listUsersByProcess, setUsers] = useState<any>([])
  const [loadingUsersByProcess, setLoadingUsers] = useState<boolean>(false)

  const [totalResults, setTotalResults] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [usersWithReminderState, setUsersWithReminderState] = useState<any>([])

  const fetchUsers = useCallback(() => {
    if (loadingUsersByProcess) return

    getUsersByProcess(processId, filters)
      .then((response: any) => {
        const updatedUsers = response.data.map((user: any) => {
          const isReminderSent = user.test_reminder !== null;
          return {
            ...user,
            isReminderSent,
          };
        });
        setUsers(response.data)
        setTotalResults(response.total_results)
        setTotalPages(response.total_pages)
        setLoadingUsers(false)
        setUsersWithReminderState(updatedUsers);
      })
      .catch((error) => {
        console.error('Error geting user by processes', error)
        setLoadingUsers(false)
        setUsers([])
      })
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.search, filters?.page])

  return { listUsersByProcess, totalResults, totalPages, loadingUsersByProcess, fetchUsers, usersWithReminderState }
}
