import axios from 'axios'

/**
 * @description service to development actions
 * @param token
 */

const fetchCompetenceById = async (token: string, id: number) => {

  const apiUrl = `${process.env.REACT_APP_MAIN_API}/skill/${id}`

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(apiUrl, config).then((res) => {
    return res.data
  }).catch((err) => {
    return err
  })

  return response
}

export default fetchCompetenceById
