import axios from 'lib/axios'
import { UPDATE_USER_TEST, UPDATE_BETESA, GET_COBRANDING } from './constants'

export const fetchUpdateUserTestAction = async (userTest: number | number[], data: any) => {
  try {
    const body = {
      access_report: data,
      user_test_id: userTest
    }
    const response = await axios.post(UPDATE_USER_TEST, body)
    return response.data
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}

export const updateGeneralData = async (user_test_id: number, data: any) => {
  const PATH = `${process.env.REACT_APP_MAIN_API}${UPDATE_BETESA}${user_test_id}`
  try {
    const response = await axios.put(PATH, data)
    return response.data
  } catch (error) {
    console.error(error)
    return error
  }
}


export const getLogoUrl = async (processId: number) => {
  const PATH = `${process.env.REACT_APP_MAIN_API}${GET_COBRANDING}?processId=${processId}`
  try {
    const response = await axios.get(PATH)
    return response.data
  } catch (error) {
    console.error(error)
    return error
  }
}
