import styled from 'styled-components'



export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 40px;
`
export const Content = styled.div`
  width: calc(80vw - 350px);
  left: 250px;
  height:100vh;
  overflow-y: visible;
`
export const CompanyCard = styled.div`
  background: #FFFFFF;
  border:none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 47px 70px 70px 47px;
`
export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`
export const InformationCompany = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  width: 50%;
  align-items: center;
  gap: 50px;

`

interface ILabelDiv {
  alignItems?: string;
  justifyContent?: string;
  width?: string;
  height?: string;
  marginTop?: string;

}

export const FormContainerItem = styled.div<ILabelDiv>`
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap:30px;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: ${(props) => props.alignItems || 'center'};
  margin-top: ${(props) => props.marginTop || '0px'};
`
export const IconSvgProfile = styled.svg`
  color:#2D749C;
  fill:#2D749C;
  width: 24px;
  height: 24px;
`


export const LabelDiv = styled.div<ILabelDiv>`
width: 38%;
display: flex;
flex-direction: row;
gap:20px; 
align-items: ${(props) => props.alignItems || 'center'};
justify-content: ${(props) => props.justifyContent || 'flex-start'};
`

export const Label = styled.label`
  font-size: 14px;
  color: #1F2124;
  font-weight: 600;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`

export const BluesiteTable = styled.table`
  width: 100%;
  text-align: left;
   border-collapse: collapse;
`
export const TableDivider = styled.hr`
  border: 1px solid #1F2124;
  width: 100%;
`
export const TableHeaders = styled.thead`
  margin-bottom: 30px;
  border-bottom: 1px solid #1F2124;
  padding: 0.3em;
  justify-content: space-between;
`
export const ActionsCell = styled.th`
  justify-content: flex-end;
  width: 25%;
`
export const TableBody = styled.tbody`
  border-top: 1px solid ;
  padding-top: 30px;
  justify-content: space-between;
`
export const TableSelect = styled.select`
  width: 63px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border:none;
  margin:10px;
  padding:10px;
`
export const OptionSelect = styled.option`
  padding:10px;
`
export const UpdateLink = styled.a`
  display:flex;
  align-items: center;
  justify-content: center;
  color:#E44646;
  font-weight: bold;
  cursor:pointer;
`

export const ActionIcon = styled.svg`
  fill: #E44646;
  clip-rule: evenodd;
  fill-rule: evenodd; 
  stroke-miterlimit:2; 
  width:24px;
  height: 24px;
  margin-right: 5px;
`

interface IRow {
  height?: string;
  width?: string;
  justifyContent?: string;
  alignItems?: string;
}

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'center'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row; 
  width: 100%;
  gap:40px;
  margin-top: 40px;
  
`
export const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row; 
  width: 85%;
  height: 92px;
  padding: 12px;
  cursor: pointer;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`