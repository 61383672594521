import { FC, useState, useEffect } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate, useLocation } from 'react-router-dom'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import Radio from '@mui/material/Radio'
import {
  fetchGetSkillTextTypeAction,
  fetchGetDimensionTextAction,
  fetchGetScoreTextAction,
  fetchGeSillsAction,
  updateSkillTextAction,
  createSkillTextAction,
} from './services/fetch_skills_texts'
import { handleFetchError, BACK_PAGE } from './constants'
import { Option } from './store/entities'
import { useNotification } from 'lib/context/notification.context'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  ActionDiv,
  InputGrid,
  LanguageRow,
  LanguageRowItem,
  Paragraph,
  FormContainer,
  SaveButton,
  InputContainer,
} from './styled'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { useSelector } from 'react-redux'
import { generalDataSelector } from 'ducks/auth'

const Home: FC = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const skill = queryParams.get('skill')
  const result = queryParams.get('result')
  const dimensionId = queryParams.get('dimensionId')
  const newResult = queryParams.get('new')
  const levelFromParams = queryParams.get('level')

  const { getWarning, getSuccess } = useNotification()
  const [textTypeOptions, setTextTypeOptions] = useState<Option[]>([])
  const [selectedTextType, setSelectedTextType] = useState(skill)
  const [dimensionTypeSelect, setDimensionTypeSelect] = useState(dimensionId)
  const [dimensionTypeOptions, setDimensionTypeOptions] = useState([])
  const [scoreTypeSelect, setScoreTypeSelect] = useState(result)
  const [scoreTypeOptions, setScoreTypeOptions] = useState([])
  const [descriptionText, setDescriptionText] = useState('')
  const [idDescriptionText, setIdDescriptionText] = useState(0)
  const [selectedLenguage, setSelectedLenguage] = useState('Español')
  const [selectedLevel, setSelectedLevel] = useState('')
  const [loading, setLoading] = useState(false)
  const { data: generalData } = useSelector(generalDataSelector)

  const { level } = generalData

  let levelOptions = []
  if (level) {
    levelOptions = level
      .filter((item: any) => item.name !== 'Betesa' && item.name !== 'Estratégico-Táctico')
      .map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
  }

  useEffect(() => {
    const fetchData = () => {
      if (!skill && !selectedLevel) return
      setLoading(true)
      const categoryId = 2

      fetchGetSkillTextTypeAction({ categoryId, levelId: Number(selectedLevel), limit: 100 })
        .then((responseCompetencie) => {
          if (!responseCompetencie) {
            throw new Error('Error in the request')
          }

          const filteredResponse = responseCompetencie.filter((item: any) => item.skill !== 'Betesa ')
          const textType = filteredResponse.map((item: { id: number; skill: string }, index: number) => ({
            value: item.id,
            label: item.skill,
            key: `option_${index}`,
            id: item.id,
          }))
          setTextTypeOptions(textType)
          if (skill) {
            setSelectedTextType(skill)
          }
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          handleFetchError(error)
        })
    }
    fetchData()
  }, [selectedLevel, skill])

  useEffect(() => {
    const fetchDataDimension = () => {
      if (selectedTextType) {
        fetchGetDimensionTextAction()
          .then((response) => {
            if (!response) {
              throw new Error('Error in the request')
            }
            const filteredResponse = response.filter((item: any) => item.name !== 'Betesa')
            const textType = filteredResponse.map((item: any) => ({
              value: item.id,
              label: item.name,
              id: item.id,
            }))
            setDimensionTypeOptions(textType)
            if (dimensionId) {
              setDimensionTypeSelect(dimensionId)
            }
          })
          .catch((error) => {
            handleFetchError(error)
          })
      }
    }
    fetchDataDimension()
  }, [selectedTextType, dimensionId])

  useEffect(() => {
    const fetchDataScore = () => {
      fetchGetScoreTextAction()
        .then((response) => {
          if (!response) {
            throw new Error('Error in the request')
          }
          const textType = response.map((item: any) => ({
            value: item.name,
            label: item.name,
          }))
          setScoreTypeOptions(textType)
          if (result) {
            setScoreTypeSelect(result)
          }
        })
        .catch((error) => {
          handleFetchError(error)
        })
    }
    fetchDataScore()
  }, [result])

  useEffect(() => {
    if (selectedTextType && dimensionTypeSelect && scoreTypeSelect) {
      const fetchSelectedData = () => {
        fetchGeSillsAction(Number(selectedTextType), Number(dimensionTypeSelect), scoreTypeSelect)
          .then((response) => {
            if (!response) {
              setDescriptionText('')
              setIdDescriptionText(0)
            }
            if (response.id) {
              setDescriptionText(response.text)
              setIdDescriptionText(response.id)
            }
          })
          .catch((error) => {
            handleFetchError(error)
          })
      }
      fetchSelectedData()
    }
  }, [selectedTextType, dimensionTypeSelect, scoreTypeSelect])

  const handleLanguageChange = (language: string) => {
    setSelectedLenguage(language)
  }

  const handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const body = {
      id: idDescriptionText,
      score: scoreTypeSelect,
      text: descriptionText,
      typeId: selectedTextType,
      dimensionId: dimensionTypeSelect,
    }

    let actionPromise
    let successMessage: string
    if (idDescriptionText) {
      actionPromise = updateSkillTextAction(body)
      successMessage = 'Texto cualitativo actualizado correctamente'
    } else {
      actionPromise = createSkillTextAction(body)
      successMessage = 'Texto cualitativo creado correctamente'
    }
    actionPromise
      .then(() => {
        getSuccess(successMessage)
        navigate(BACK_PAGE)
      })
      .catch((error) => {
        console.error(error)
        getWarning('Error al actualizar el cuadrante')
      })
  }

  const handleLevelChange = (selectedOption: any) => {
    setSelectedTextType('')
    setSelectedLevel(selectedOption)
  }

  const handleCompetenciaChange = (selectedOption: any) => {
    if (selectedOption === 0) {
      setDescriptionText('')
      setSelectedTextType('')
      setDimensionTypeSelect('')
      setScoreTypeSelect('')
    } else {
      setSelectedTextType(selectedOption)
    }
  }

  const handleDimensionChange = (selectedOption: any) => {
    setDescriptionText('')
    setDimensionTypeSelect(selectedOption)
  }

  const handleResultadoChange = (selectedOption: any) => {
    setDescriptionText('')
    setScoreTypeSelect(selectedOption)
  }

  useEffect(() => {
    const h2Element = document.querySelector('h2')
    if (h2Element) {
      if (newResult === 'true') {
        h2Element.textContent = 'Según resultado'
      } else {
        h2Element.textContent = 'Cualitativos'
      }
    }
  }, [newResult])

  const handleEditorChange = (event: any, editor: { getData: () => any }) => {
    const data = editor.getData()
    setDescriptionText(data)
  }

  return (
    <MainLayout>
      {loading && <ModalLoader isLoading={loading} />}
      <form onSubmit={handleUpdate}>
        <TitleDiv>
          <h2 style={{ fontSize: 32 }}>{newResult === 'true' ? 'Según resultado' : 'Cualitativos'}</h2>
        </TitleDiv>
        <FormContainer>
          <LanguageRow>
            <LanguageRowItem>
              <Paragraph>Idioma del texto</Paragraph>
            </LanguageRowItem>
            <LanguageRowItem>
              <Radio
                checked={selectedLenguage === 'Español'}
                disabled={true}
                onChange={() => handleLanguageChange('Español')}
              />
              <Paragraph>Español</Paragraph>
            </LanguageRowItem>
          </LanguageRow>
          <InputGrid>
            {!skill && !result && !dimensionId ? (
              <Select
                label="Nivel"
                options={levelOptions}
                value={selectedLevel ?? ''}
                onChange={handleLevelChange}
                disabled={Boolean(levelFromParams)}
                hideNeverChoice
              />
            ) : null}
            <Select
              label="Competencia"
              options={textTypeOptions}
              value={selectedTextType ?? ''}
              onChange={handleCompetenciaChange}
              disabled={Boolean(skill) || !Boolean(selectedLevel)}
              hideNeverChoice
            />
            <Select
              label="Dimensión"
              options={dimensionTypeOptions}
              value={dimensionTypeSelect ?? ''}
              onChange={handleDimensionChange}  
              disabled={Boolean(dimensionId)}
              hideNeverChoice
            />
            <Select
              label="Resultado"
              options={scoreTypeOptions}
              value={scoreTypeSelect ?? ''}
              onChange={handleResultadoChange}
              disabled={Boolean(result)}
              hideNeverChoice
            />
          </InputGrid>
          <InputContainer>
            <CKEditor editor={ClassicEditor} data={descriptionText} onChange={handleEditorChange} />
          </InputContainer>
          <ActionDiv>
            <span
              style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }}
              onClick={() => {
                navigate(-1)
              }}
            >
              Cancelar
            </span>
            <SaveButton
              type="submit"
              disabled={!selectedTextType || !descriptionText || !dimensionTypeSelect || !scoreTypeSelect}
              descriptionText={descriptionText}
            >
              Guardar
            </SaveButton>
          </ActionDiv>
        </FormContainer>
      </form>
    </MainLayout>
  )
}
export default Home
