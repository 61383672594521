import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/es'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts/'
import {
  Container,
  ChartContainer,
  SubContainerChart,
  RowContainer,
  CardTitle,
  InfoUserContainer,
  ReportContainer,
  CardTable,
  TableBody,
  TableHead,
  NumberRow,
  TableList,
  HeadLeftCell,
  HeadRightCell,
  LeftCell,
  RightCell,
  CardTitleWithColor,
  CardInfoWithBorderTitle,
  Subtitle,
  WrapperQuadrantList,
  CountStressorsContainer,
  BlueText,
  WrapperInfo,
  InfoText,
  ScoreTitle,
  CardInfoWithBorder,
  RowTextWrapper,
  ScoreSectionWrapper,
  ScoreWrapperInfo,
  InfoHalfRow,
  RowCardsWrapper,
  BackButtonWrapper,
  HeaderButtonsWrapper,
  AdjustmentLevelText,
} from './styled'
import { H2, H5, H4, P } from 'views/components/UI/Text'
import { StrokedButton, BackButton, Button } from 'views/components/UI/Buttons'
import CardDownloadResults from 'views/components/Cards/DownloadResults'
import BrainMetricsContainer from 'views/containers/BrainMetrics'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { useNotification } from 'lib/context/notification.context'
import getBetesaResult from 'services/results/betesa-results'
import sendReportToUser from 'services/reports/sendReportResultToUserEvaluated/sendReportToUser'
import { useNameLevelAlert } from 'hooks/useNameLevelAlert'
import { useNameQuadrant } from 'hooks/useNameQuadrant'
import { useSendExamToUsers } from 'hooks/process/useSendExamToUsers/useSendExamToUsers'
import { platformAdminRoutes, superAdminRoutes } from 'router/routes'
import { NO_INTERPRETABLE_TEXT } from 'constants/global.constants'
import {
  fetchUpdateUserTestAction,
  getLogoUrl,
  updateGeneralData,
} from 'views/screens/Platform-administrator/constants/fetch_process'
import { IdealProfileScores } from 'types/common'
import useReports from 'hooks/use-reports'
import { fetchFeedBack } from 'views/screens/Evaluated-User/services/fetch-development-plan-betesa'
import { useTranslation } from 'react-i18next'

const ResultsBetesa = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location: any = useLocation()
  const { handleSendExamToUsers } = useSendExamToUsers()
  const { getError, getSuccess, getWarning } = useNotification()
  const { token } = useSelector((state: any) => state.user)
  const { role } = useSelector((state: any) => state.auth)
  dayjs.extend(utc)
  const testId = location.state?.testId
  const processId = location.state.processId ? location.state.processId : location.state.process_id
  const userId = location.state?.userId
  const userName = location.state?.userName
  const companyId = location.state?.companyId
  const betesaResultId = location.state?.betesaResultId

  const [testData, setTestData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [participation, setParticipation] = useState<boolean>(false)
  const [isSendingReport, setIsSendingReport] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const [disablePlanButton, setDisablePlanButton] = useState<boolean>(false)

  const userTestId = testData?.test_info?.user_test_id ? testData?.test_info?.user_test_id : ''
  const processName = testData?.test_info?.process_name ? testData?.test_info?.process_name : ''

  const { handleReportDownload } = useReports()

  useEffect(() => {
    if (!userTestId) return

    fetchFeedBack(userTestId)
      .then((res: any) => {
        if (res.data.status === 'OK') {
          setDisablePlanButton(true)
        }
      })
      .catch(() => {
        setDisablePlanButton(false)
      })
  }, [userTestId])

  const getIdealProfile = useCallback((): IdealProfileScores | undefined => {
    const profileValues = testData?.ideal_profile?.profile_values
    if (!profileValues || profileValues[0]?.min <= 0) return

    const idealProfileData: IdealProfileScores = ['FI', 'FD', 'BI', 'BD'].reduce((acc: any, quadrant) => {
      acc[quadrant] = profileValues.find((item: any) => item.quadrant === quadrant)?.min || 0
      return acc
    }, {} as IdealProfileScores)

    if (idealProfileData.FI) {
      return idealProfileData
    }
  }, [testData])

  const getResults = async () => {
    try {
      const response = await getBetesaResult(testId, userId)
      if (response.status === 'OK') {
        if (typeof response.data === 'string') {
          getError('El usuario no ha contestado la prueba betesa')
          setLoading(false)
        } else {
          setTestData(response.data)
          setLoading(false)
        }
      } else {
        getError('Error al obtener los resultados')
        setLoading(false)
      }
    } catch (error) {
      getError('Error al obtener los resultados de betesa')
    }
  }

  useEffect(() => {
    if (token && !loading) {
      setLoading(true)
      getResults()
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const handleSendReport = () => {
    if (isSendingReport) return
    setIsSendingReport(true)
    sendReportToUser({
      userId,
      companyId,
    })
      .then((res) => {
        if (res.status === 'OK' || res.status === 'success') {
          setParticipation(true)
          fetchUpdateUserTestAction(testData?.test_info.user_test_id, true)
          getSuccess('Reporte enviado correctamente')
        } else {
          getError('Error al enviar el reporte: ' + res.message)
          setIsSendingReport(false)
        }
      })
      .finally(() => {
        setIsSendingReport(false)
      })
      .catch((error) => {
        getError('Error al intentar enviar el reporte: ' + error)
        setIsSendingReport(false)
      })
  }

  const handleResendTest = async () => {
    const usersIds = [userId]
    const logoUrl = await getLogoUrl(processId)
    const bodyUpdate = {
      domination: false,
      adjustment_level_id: false,
      adjustment_level_score: false,
      completed_chapter: false,
    }
    const updateTest = await updateGeneralData(testData?.test_info.user_test_id, bodyUpdate)
    if (updateTest.status === 'OK') {
      const dataToSend = {
        process_id: processId,
        user_ids: usersIds,
        company_id: companyId,
        type: 'test_resend',
        count: 1,
        email_template: {
          logo: logoUrl.cobranding.logo,
          date: dayjs.utc().add(7, 'day').format('DD/MM/YYYY'),
        },
      }

      setTimeout(() => {
        handleSendExamToUsers(dataToSend as any)
      }, 2000)
      setDisabledButton(true)
    }
  }

  const parsedLevel = useNameLevelAlert(testData?.score_detail?.alert_level)
  const parsedQuadrant = useNameQuadrant(testData?.test_info?.domination)

  const isUninterpretable = parsedQuadrant === NO_INTERPRETABLE_TEXT
  const handleDisabledClick = () => {
    getWarning('El usuario tiene configurada la descarga de reporte automático o ya se autorizó el acceso')
  }

  const handlePlanDisabledClick = () => {
    getWarning(t('global.notifications.planAlreadyCreated'))
  }

  const handleCreatePlan = () => {
    const body = {
      companyId,
      processId,
      userId,
      nameProcess: processName,
      testData: [{ testId, typeTest: 'Betesa Premium', typeLabel: 'betesa', userTestId, domination: 'Interpretable' }],
    }
    navigate(platformAdminRoutes.DevelopmentPlan, {
      state: {
        body,
        userName,
        flag: true,
      },
    })
  }
  console.log('ROLE', role)
  return (
    <MainLayout>
      {!loading && testData ? (
        <Container>
          <RowContainer justifyContent="space-between">
            <H2>Betesa - {userName || ''}</H2>
            <HeaderButtonsWrapper>
              {role !== 'platform_admin' || role !== 'super_platform_admin' ? (
                <StrokedButton
                  disabled={disablePlanButton}
                  onClick={handleCreatePlan}
                  onDisabledClick={handlePlanDisabledClick}
                >
                  Crear plan de desarrollo
                </StrokedButton>
              ) : null}
              {role === 'platform_admin' || role === 'super_platform_admin' ? null : (
                <Link to={`${superAdminRoutes.ReinterpretationBetesa}?id=${betesaResultId}`}>
                  <StrokedButton onClick={() => {}}>Interpretar</StrokedButton>
                </Link>
              )}
              {role === 'platform_admin' || (role === 'super_platform_admin' && isUninterpretable) ? (
                <StrokedButton
                  onClick={handleSendReport}
                  disabled={
                    testData?.test_info.participant_report || participation || testData?.test_info.access_report
                  }
                  onDisabledClick={handleDisabledClick}
                >
                  Autorizar acceso a usuario
                </StrokedButton>
              ) : (
                <Button onClick={handleResendTest} disabled={disabledButton}>
                  Reenviar prueba
                </Button>
              )}
            </HeaderButtonsWrapper>
          </RowContainer>
          <RowContainer>
            <WrapperInfo>
              <H4>Datos de la Prueba</H4>
              <RowContainer>
                <Subtitle>Empresa:</Subtitle>
                <InfoText>{testData?.test_info?.company_name}</InfoText>

                <Subtitle>Nombre del Proceso:</Subtitle>
                <InfoText>{testData?.test_info?.process_name}</InfoText>

                <Subtitle>Fecha de realización:</Subtitle>
                <InfoText>{dayjs.utc(testData?.test_info?.created_at).locale('es').format('DD/MM/YYYY')}</InfoText>
              </RowContainer>
            </WrapperInfo>
          </RowContainer>
          <InfoUserContainer>
            <ScoreSectionWrapper>
              <ScoreWrapperInfo>
                <ScoreTitle>Puntaje de pruebas</ScoreTitle>
                <CardInfoWithBorder>
                  <RowTextWrapper>
                    <Subtitle>Cuadrante dominante:</Subtitle> <InfoText>{parsedQuadrant}</InfoText>
                  </RowTextWrapper>
                  <RowTextWrapper>
                    <Subtitle>Puntajes por cuadrante:</Subtitle>
                    {!isUninterpretable ? (
                      <WrapperQuadrantList>
                        <li>FI: {testData?.score_detail?.development_scores?.FI_raw_score}</li>
                        <li>BI: {testData?.score_detail?.development_scores?.BI_raw_score}</li>
                        <li>BD: {testData?.score_detail?.development_scores?.BD_raw_score}</li>
                        <li>FD: {testData?.score_detail?.development_scores?.FD_raw_score}</li>
                      </WrapperQuadrantList>
                    ) : (
                      <P margin="12px 0px">{NO_INTERPRETABLE_TEXT}</P>
                    )}
                  </RowTextWrapper>
                  <RowTextWrapper>
                    <Subtitle>Nivel de alerta:</Subtitle>{' '}
                    <InfoText>{!isUninterpretable ? parsedLevel : NO_INTERPRETABLE_TEXT}</InfoText>
                  </RowTextWrapper>
                  <RowTextWrapper>
                    <Subtitle>Nivel de ajuste:</Subtitle>{' '}
                    <AdjustmentLevelText>
                      {!isUninterpretable ? (
                        <>
                          {testData?.adjustment_level} {testData?.adjustment_level_score ? '-' : ''}{' '}
                          {testData?.adjustment_level_score}
                          {testData?.adjustment_level_score ? '%' : ''}
                        </>
                      ) : (
                        <>{NO_INTERPRETABLE_TEXT}</>
                      )}
                    </AdjustmentLevelText>
                  </RowTextWrapper>
                  <RowTextWrapper>
                    <Subtitle>Nivel:</Subtitle> <InfoText>{testData?.test_info?.level}</InfoText>
                  </RowTextWrapper>
                </CardInfoWithBorder>
              </ScoreWrapperInfo>
              <ReportContainer>
                {!isUninterpretable ? (
                  <CardDownloadResults
                    title="Betesa - Talentos naturales"
                    type="betesa"
                    onClick={() => handleReportDownload(userId, testId, processId, 0, 'betesa')}
                    user_id={userId}
                    test_id={testId}
                    user_test_id={userTestId}
                    processId={processId}
                    planToDownload={disablePlanButton}
                    userName={userName}
                  />
                ) : null}
              </ReportContainer>
            </ScoreSectionWrapper>
            {!isUninterpretable ? (
              <ChartContainer>
                <SubContainerChart style={{ transform: 'scale(0.85)' }}>
                  <BrainMetricsContainer
                    showPolarArea
                    result={{
                      FI: testData?.score_detail?.development_scores?.FI_raw_score,
                      BI: testData?.score_detail?.development_scores?.BI_raw_score,
                      BD: testData?.score_detail?.development_scores?.BD_raw_score,
                      FD: testData?.score_detail?.development_scores?.FD_raw_score,
                    }}
                    childhood={testData?.childhood_and_youth}
                    idealProfile={getIdealProfile()}
                    dominantQuadrant={testData?.score_detail?.thought_structure}
                  />
                </SubContainerChart>
                <SubContainerChart></SubContainerChart>
              </ChartContainer>
            ) : null}
            <RowCardsWrapper>
              <InfoHalfRow>
                <CardInfoWithBorderTitle>
                  <CardTitleWithColor>
                    <H5>Estresores</H5>
                  </CardTitleWithColor>
                  <TableBody>
                    <CountStressorsContainer>
                      {testData.stressors_score?.length ? (
                        testData.stressors_score.map((item: any) => (
                          <BlueText key={item}> {!isUninterpretable ? `${item.score}/100` : 'N/A'} </BlueText>
                        ))
                      ) : (
                        <BlueText>N/A</BlueText>
                      )}
                    </CountStressorsContainer>
                  </TableBody>
                  <div style={{ height: '70%', padding: 20 }}>
                    {!testData?.stressors?.length && <P>No se encontraron estresores</P>}
                    {isUninterpretable ? <>N/A</> : testData?.stressors?.map((item: any) => <P key={item}>{item}</P>)}
                  </div>
                </CardInfoWithBorderTitle>
              </InfoHalfRow>
              <InfoHalfRow>
                <CardInfoWithBorderTitle>
                  <CardTitle>
                    <H5 margin="10px 0px">
                      Estado emocional: {!isUninterpretable ? testData?.emotional_state?.text : 'N/A'}
                    </H5>
                  </CardTitle>
                  <CardTable>
                    <TableHead style={{ background: '#E6EEF3', borderColor: '#E6EEF3' }}>
                      <tr>
                        <th style={{ borderColor: '#E6EEF3' }}>Adjetivos positivos</th>
                        <th style={{ borderColor: '#E6EEF3' }}>Adjetivos negativos</th>
                      </tr>
                    </TableHead>
                    <TableBody>
                      <NumberRow>
                        <HeadLeftCell>
                          {!isUninterpretable ? testData?.emotional_state?.positive_adjectives?.score : 'N/A'}
                        </HeadLeftCell>
                        <HeadRightCell>
                          {!isUninterpretable ? testData?.emotional_state?.negative_adjectives?.score : 'N/A'}
                        </HeadRightCell>
                      </NumberRow>
                      <tr>
                        <LeftCell>
                          {!isUninterpretable ? (
                            <TableList style={{ listStyle: 'none' }}>
                              {testData?.emotional_state?.positive_adjectives?.adjectives?.map((item: any) => (
                                <li key={item}>{item}</li>
                              ))}
                            </TableList>
                          ) : (
                            <P>N/A</P>
                          )}
                        </LeftCell>
                        <RightCell>
                          {!isUninterpretable ? (
                            <TableList style={{ listStyle: 'none' }}>
                              {testData?.emotional_state?.negative_adjectives?.adjectives?.map((item: any) => (
                                <li key={item}>{item}</li>
                              ))}
                            </TableList>
                          ) : (
                            <P>N/A</P>
                          )}
                        </RightCell>
                      </tr>
                    </TableBody>
                  </CardTable>
                </CardInfoWithBorderTitle>
              </InfoHalfRow>
            </RowCardsWrapper>
          </InfoUserContainer>
        </Container>
      ) : (
        <ModalLoader isLoading={loading} />
      )}
      <BackButtonWrapper>
        <BackButton onClick={() => navigate(-1)} />
      </BackButtonWrapper>
    </MainLayout>
  )
}

export default ResultsBetesa
