import React, { FC } from 'react'
import { Container } from './styles.styled'
import { ButtonBack } from '../../styles.styled'
import { Button } from 'views/components/UI/Buttons'
import { isDirtySelector, tableChangedSelector } from '../../store'
import { useSelector } from 'react-redux'

interface FooterButtonsProps {
  showConfirmModal: (e: boolean) => void
  onSave: () => void
}

const FooterButtons: FC<FooterButtonsProps> = ({ showConfirmModal, onSave }) => {
  const isDirty = useSelector(isDirtySelector)
  const tableChanged = useSelector(tableChangedSelector)

  const handleBackButtonClick = () => {
    if (isDirty || tableChanged) showConfirmModal(true)
    else window.history.back()
  }

  const handleSave = () => {
    onSave()
  }

  return (
    <Container>
      <ButtonBack onClick={handleBackButtonClick}>Atrás</ButtonBack>
      <Button type="submit" disabled={!tableChanged} onClick={handleSave}>
        Guardar
      </Button>
    </Container>
  )
}

export default FooterButtons
