import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import { Link } from 'react-router-dom'
import {
  Card,
  ModulesContainer,
  CardDescriptionSection
}
  from './styled'
import TitleDiv from 'views/components/UI/TitleDiv'
const Home: FC = () => {
  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Configuración módulo Betesa</h2>
      </TitleDiv>
      <ModulesContainer>
        <Link to="/modules/talents/questionnaire">
          <Card >
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Cuestionarios</span>

            </CardDescriptionSection>
          </Card>
        </Link>
        <Link to="/modules/talents/actions">
          <Card >
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Acciones de desarrollo</span>

            </CardDescriptionSection>
          </Card>
        </Link>
        <Link to="/modules/talents/texts">
          <Card >
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Textos</span>

            </CardDescriptionSection>
          </Card>
        </Link>
      </ModulesContainer>
    </MainLayout>
  )
}
export default Home
