import { FC, useEffect, useState, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import { StrokedButton, Button } from 'views/components/UI/Buttons';
import { ContainerRow, BtnGoBack, SubTitleRow, ModalContent, ModalButtonsContainer, TextCancel } from './styled'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { superAdminRoutes } from 'router/routes'
import { ActionEditAndDisassociateQuestions } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import Modal from 'views/components/UI/Modal'
import { H2, P } from 'views/components/UI/Text'
import DisassociateIcon from "assets/icons/DIsassociate";
import { headers, steps } from '../constants'

// SERVICES
import getChapters from 'services/common/chapters/get'
import getQuestionsByFilter from 'services/common/questions/getByFilters'
import deleteQuestionSkillsById from 'services/common/questions/delete'

const AssociateQuestionsChapterBetesa: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // router state
  const { questionnaireId, chapterId } = location.state

  // STATE
  const [chapterSelected, setChapterSelected] = useState<any | null>(null)
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState<number | null>(null)
  const [chaptersOptions, setChaptersOptions] = useState<any[] | []>([])
  const [questionsData, setQuestionsData] = useState<any[] | []>([])
  const [totalPages, setTotalPages] = useState(0)
  const [idQuestionToDissasociate, setIdQuestionToDissasociate] = useState<number | null>(null)
  // modal state
  const [showModal, setShowModal] = useState<boolean>(false)


  useEffect(() => {

    if (chapterId) {
      setChapterSelected(chapterId)
    }
    if (questionnaireId) {
      setCurrentQuestionnaireId(parseInt(questionnaireId))
    }
  }, [chapterId, questionnaireId])


  // Function to dissasociate a question
  function handleDissasociateQuestion() {

    if (idQuestionToDissasociate) {
      deleteQuestionSkillsById(idQuestionToDissasociate)
        .then((res: any) => {
          if (res.status === 'OK' || res.status === 'success') {
            setShowModal(false)
            setIdQuestionToDissasociate(null)
            window.location.reload();
          } else {
            console.error('error deleting an question', res)
          }
        })
    }
  }



  useEffect(() => {
    if (!currentQuestionnaireId || !chapterSelected) {
      return;
    }

    const filtersChapters = {
      typeCategoryTestId: currentQuestionnaireId,
      chapterId: parseInt(chapterSelected),
    };

    getChapters(filtersChapters)
      .then((res) => {
        if (res.status === 'OK') {
          const formattedDataChapters = res.chapters
            .filter((chapter: any) => chapter.id !== 0 && chapter.id !== 1)
            .map((chapter: any) => ({
              value: chapter.id,
              label: chapter.name
            }));

          setChaptersOptions(formattedDataChapters);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [currentQuestionnaireId, chapterSelected]);

  const getFilteredQuestions = useCallback((page = 1, limit = 10) => {
    if (chapterSelected !== null && currentQuestionnaireId !== null) {
    getQuestionsByFilter({ chapterId: chapterSelected, typeCategoryTestId: currentQuestionnaireId }, limit, page).then(({ questions, total_pages }) => {
      setQuestionsData(questions)
      setTotalPages(total_pages);
    })
  }
  }, [chapterSelected, currentQuestionnaireId])

  useEffect(() => {
    getFilteredQuestions()
  }, [getFilteredQuestions])

  

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Preguntas betesa premium corporativo</h2>
      </TitleDiv>
      <SubTitleRow>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={2} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </SubTitleRow>
      <div style={{ width: '50%', margin: 10 }}>
        {
          chaptersOptions.length > 0 && chapterSelected && (
            <div style={{ width: '100%', margin: '40px 0px 10px 0px' }}>
              <Select
                hideNeverChoice={true}
                disabled={false}
                label="Capítulo"
                options={chaptersOptions}
                value={chapterSelected}
                onChange={(e: any) => {
                  setChapterSelected(e)
                }}
              />
            </div>
          )
        }
      </div>
      <SubTitleRow style={{ marginBottom: 'px', marginTop: '10px' }}>
        <h4 style={{ fontSize: 20 }}>Preguntas</h4>
        <StrokedButton onClick={() =>
          navigate(superAdminRoutes.CreateQuestionQuestionaire,
            { state: { chapterId: chapterSelected, questionnaireId: currentQuestionnaireId } }
          )}
        >
          Nueva pregunta
        </StrokedButton>
      </SubTitleRow>
      <BluesiteTableComponent extraDataAction={{
        questionnaireId,
        onDissasociate: (e: number) => {
          setShowModal(true)
          setIdQuestionToDissasociate(e)
        }
      }} ActionComponent={ActionEditAndDisassociateQuestions} data={questionsData} handlePagination={getFilteredQuestions} totalPages={totalPages} currentPage={1} headers={headers} searchBar={true}  searchLabel='' />
      <ContainerRow>
        <BtnGoBack onClick={() => navigate(superAdminRoutes.ChaptersQuestionaire, { state: { questionnaireId: currentQuestionnaireId } })}>Ir a capítulos</BtnGoBack>
        <Button onClick={() => navigate(superAdminRoutes.ChaptersQuestionaire, { state: { questionnaireId: currentQuestionnaireId } })} >Guardar</Button>
      </ContainerRow>
      <Modal setShow={setShowModal} show={showModal} children={
        <ModalContent>
          <DisassociateIcon width='80px' height='80px' />
          <H2 margin='20px 0px'>¿Estás seguro de que deseas desasociar esta pregunta?</H2>
          <P>Si eliminas esta pregunta no podrás volver a acceder a ella</P>
          <ModalButtonsContainer>
            <TextCancel onClick={() => setShowModal(false)}>Cancelar</TextCancel>
            <Button type='button' onClick={handleDissasociateQuestion}>Desasociar</Button>
          </ModalButtonsContainer>
        </ModalContent>
      } />
    </MainLayout>
  )
}
export default AssociateQuestionsChapterBetesa
