import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'lib/redux'

const getEvaluatedUser = (state: any) => state.evaluatedUser

export const getProcessSelected = createSelector(getEvaluatedUser, (evaluatedUser) => evaluatedUser.processSelected)

export const getHaveBetesaInterpreted = createSelector(
  getEvaluatedUser,
  (evaluatedUser) => evaluatedUser.haveBetesaInterpreted
)

export const getHaveSkillFinished = createSelector(getEvaluatedUser, (evaluatedUser) => evaluatedUser.haveSkillFinished)

export const userTestIdSelector = createSelector(
  getEvaluatedUser,
  (evaluatedUser) => evaluatedUser.processSelected.userTestId
)

export const isBetesaTestSelector = (state: RootState) => state.evaluatedUser.isBetesaTest

export const dataHomeGeneralSelector = (state: RootState) => state.dataHomeReducer.dataHome
