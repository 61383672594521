/* eslint-disable @typescript-eslint/no-unused-vars */
import { current } from '@reduxjs/toolkit';
import React from 'react';
import Styled from 'styled-components';

const PaginationContainer = Styled.div`
  display: flex;
  justify-content: center;
`;

interface IPageButton {
  active: boolean;
}

const PageButton = Styled.button<IPageButton>`
  background-color: ${(props: IPageButton) => (props.active ? '#2BB5D4' : 'transparent')};
  color: ${(props: IPageButton) => (props.active ? 'white' : '#1F2124')};
  height: 32px;
  width: 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`;

interface IProps {
    totalPages: number;
    currentPage
    : number;
    onPageChange: (page: number) => void;
}

export default function Pagination({ totalPages, currentPage, onPageChange }: IProps) {
  const pageButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    pageButtons.push(
      <PageButton active={currentPage === i} key={i} onClick={() => onPageChange(i)}>
        {i}
      </PageButton>
    );
  }

  return (
    <PaginationContainer>
      {pageButtons}
    </PaginationContainer>
  );
}
