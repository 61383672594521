/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Slider } from '@mui/material';
import { Container, Quadrant, HeadQuadrant, BodyQuadrant, SelectContainer } from './styled';
import { H6, H3 } from 'views/components/UI/Text';
import Select from 'views/components/UI/Select';
import WhiteContainer from 'views/components/common/WhiteContainer';
import { INIT_SCALE, MAX_SCALE, QUADRANTS_NAMES, QUADRANT_VALUES, SCALE, MIN_DISTANCE, OPPOSITE_QUADRANTS, INDEX_OPPOSITE, PRIORITY_OPPOSITE,  QuadrantType } from './constants';

const BrainQuadrant = ({ setFieldValue, values }: any) => {

  const setMarks = (min: number, max: number) => {
    const marks = [];
    for (let i = min; i <= max; i += SCALE) {
      marks.push({ value: i, label: i });
    }
    return marks;
  }

  const valuetext = (value: number) => {
    return `${value}`
  };
  const handlelogic = (value: any, index: string, name: QuadrantType) => {
    const indexOpposite = INDEX_OPPOSITE[index];
    const quadrantOpposite = OPPOSITE_QUADRANTS[name];
    const priorityOpposite = PRIORITY_OPPOSITE[value]
    const quadrantValues = values.ideal_profile_data?.profile_values.filter((q: any) => q?.priority === value)
    if (quadrantValues.length < 1) {
      setFieldValue(`ideal_profile_data.profile_values.${index}`, {
        ...values.ideal_profile_data?.profile_values[index],
        quadrant: `${name}`,
        priority: value,
      })
      setFieldValue(`ideal_profile_data.profile_values.${indexOpposite}`, {
        ...values.ideal_profile_data?.profile_values[indexOpposite],
        quadrant: `${quadrantOpposite}`,
        priority: priorityOpposite,
      })
    } else {
      setFieldValue(`ideal_profile_data.profile_values.${index}`, {
        ...values.ideal_profile_data?.profile_values[index],
        quadrant: `${name}`,
        priority: 0,
      })
      setFieldValue(`ideal_profile_data.profile_values.${indexOpposite}`, {
        ...values.ideal_profile_data?.profile_values[indexOpposite],
        quadrant: `${quadrantOpposite}`,
        priority: 0,
      })
    }
    
  };

  return (
    <Container>
      {QUADRANTS_NAMES?.map((name: any, index: any) => (
        <Quadrant key={`${name}.${index}`}>
          <WhiteContainer>
            <HeadQuadrant>
              <H3>{name}</H3>
              <SelectContainer>
                <Select
                  hideNeverChoice={true}
                  onChange={(e: any) => {
                    handlelogic(e, index, name)
                  }}
                  label='Prioridad'
                  options={QUADRANT_VALUES}
                  value={
                    values.ideal_profile_data?.profile_values[index]?.priority ? values.ideal_profile_data?.profile_values[index]?.priority : '0'
                  }
                />
              </SelectContainer>
            </HeadQuadrant>
            <BodyQuadrant>
              <H6>Rango esperado</H6>
              <Box sx={{ width: '100%' }}>
                <Slider
                  getAriaLabel={() => 'Minimum distance shift'}
                  min={INIT_SCALE}
                  max={MAX_SCALE}
                  value={[values.ideal_profile_data?.profile_values[index]?.min, values.ideal_profile_data?.profile_values[index]?.max]}
                  onChange={(
                    event: Event,
                    newValue: number | number[],
                    activeThumb: number,
                  ) => {
                    if (!Array.isArray(newValue)) {
                      return;
                    }
                
                    if (newValue[1] - newValue[0] < MIN_DISTANCE) {
                      if (activeThumb === 0) {
                        const clamped = Math.min(newValue[0], 110 - MIN_DISTANCE);
                        setFieldValue(`ideal_profile_data.profile_values.${index}`, {
                          ...values.ideal_profile_data?.profile_values[index],
                          min: clamped,
                          max: clamped + MIN_DISTANCE,
                        })
                      } else {
                        const clamped = Math.max(newValue[1], MIN_DISTANCE);
                        setFieldValue(`ideal_profile_data.profile_values.${index}`, {
                          ...values.ideal_profile_data?.profile_values[index],
                          min: clamped,
                          max: clamped - MIN_DISTANCE,
                        })
                      }
                    } else {
                      setFieldValue(`ideal_profile_data.profile_values.${index}`, {
                        ...values.ideal_profile_data?.profile_values[index],
                        min: newValue[0],
                        max: newValue[1],
                      })
                    }
                   /*  setFieldValue(`ideal_profile_data.profile_values.${index}`, {
                      ...values.ideal_profile_data?.profile_values[index],
                      min: e.target.value[0],
                      max: e.target.value[1],
                    }) */
                  }}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  disableSwap
                  marks={setMarks(INIT_SCALE, MAX_SCALE)}
                  sx={{
                    '& .MuiSlider-rail': {
                      color: '#2D749C',
                    },
                    '& .MuiSlider-track': {
                      color: '#2D749C',
                    },
                    '& .MuiSlider-thumb': {
                      color: '#fff',
                      backgroundColor: '#2D749C',
                    },
                    '& .MuiSlider-valueLabelLabel': {
                      fontWeight: 'bold',
                      color: '#fff',
                    },
                    '& .MuiSlider-valueLabelCircle': {
                      color: '#fff',
                    },
                  }}
                />
              </Box>
            </BodyQuadrant>
          </WhiteContainer>
        </Quadrant>
      ))}
    </Container>
  );
}

export default BrainQuadrant;
