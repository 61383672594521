import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import ListItemText from '@mui/material/ListItemText'
import { TagStyled } from './styled'

type IItemOption = {
  value: string | number
  label: string
}

interface IsSelectProps {
  label: string
  error?: string | undefined
  value: Array<string | number> | null
  onChange?: (value: Array<string | number>) => void
  width?: string
  maxWidth?: string
  options: IItemOption[]
  style?: React.CSSProperties
  onBlur?: () => void
  disabled?: boolean
  limitTags?: number
  noChip?: boolean
  showCheckboxes?: boolean
}

export default function MultiSelect(props: IsSelectProps) {
  const {
    label,
    error,
    value,
    options,
    onChange,
    onBlur,
    width,
    disabled,
    limitTags,
    noChip,
    maxWidth,
    showCheckboxes = true,
  } = props

  const handleChange = (event: any, newValue: IItemOption[] | null) => {
    if (onChange) {
      onChange(newValue ? newValue.map((option) => option.value) : [])
    }
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={disabled}
      id="multi-select"
      options={options}
      value={options.filter((option) => (value ? value.includes(option.value) : false))}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      onBlur={onBlur}
      limitTags={limitTags ? limitTags : undefined}
      renderTags={
        noChip
          ? (tag) => {
              return (
                <TagStyled>
                  {tag
                    .filter((option) => option.value !== 0)
                    .map((option, index) => (
                      <>{index === tag.length - 1 ? option.label : `${option.label}, `}</>
                    ))}
                </TagStyled>
              )
            }
          : undefined
      }
      renderOption={(properties, option, { selected }) => {
        if (!showCheckboxes) {
          return (
            <li {...properties} key={option.value}>
              <ListItemText primary={option.label} />
            </li>
          )
        }
        return (
          <li {...properties}>
            <Checkbox checked={selected} />
            <ListItemText primary={option.label} />
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Buscar"
          error={!!error}
          helperText={error}
          sx={{
            '.MuiFormHelperText-root': {
              position: 'absolute',
              bottom: -24,
              borderRadius: '16px',
            },
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
          }}
        />
      )}
      sx={{
        backgroundColor: '#FFF',
        width: width || '100%',
        minWidth: '240px',
        maxWidth: maxWidth || '300px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        borderRadius: '16px',
      }}
    />
  )
}
