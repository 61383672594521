

export type QuadrantType = typeof QUADRANTS[keyof typeof QUADRANTS];

export interface SelectOption {
    value: string;
    label: string;
}

export interface IdealprofileItem {
  quadrant: string;
  priority: number;
  min: number;
  max: number;
}

export interface QuadrantData {
  quadrant: 'FI' | 'FD' | 'BI' | 'BD',
  priority: number,
  min: number,
  max: number,
  alert_level_range?: any
}

export interface BrainQuadrantProps {
  onChange: (value: any) => void,
  defaultQuadrant?: string,
  quadrantData?: QuadrantData[],
}


export const QUADRANTS = {
    FRONT_LEFT: 'FI',
    FRONT_RIGHT: 'FD',
    BASAL_LEFT: 'BI',
    BASAL_RIGHT: 'BD',
} as const;

export const OPPOSITE_QUADRANTS: Record<QuadrantType, QuadrantType> = {
  [QUADRANTS.FRONT_LEFT]: QUADRANTS.BASAL_RIGHT,
  [QUADRANTS.FRONT_RIGHT]: QUADRANTS.BASAL_LEFT,
  [QUADRANTS.BASAL_LEFT]: QUADRANTS.FRONT_RIGHT,
  [QUADRANTS.BASAL_RIGHT]: QUADRANTS.FRONT_LEFT,
};

export const OPTIONS_SELECT: SelectOption[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
];

export const QUADRANT_INITIALS: Record<QuadrantType, string> = {
  'FI': 'FI',
  'FD': 'FD',
  'BI': 'BI',
  'BD': 'BD',
};

export const INITIALS_TO_QUADRANTS: Record<string, QuadrantType> = {
  'FI': 'FI',
  'FD': 'FD',
  'BI': 'BI',
  'BD': 'BD',
};
