import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface IProps{
  label: string;
  checked: boolean;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
}

function CheckboxLabels(props: IProps) {
  const {label, onChange, style, checked} = props;

  return (
    <FormGroup style={style}>
      <FormControlLabel 
        control={
           <Checkbox 
            checked={checked}
            onChange={() => {
               onChange(label);
            }}
            sx={{
              borderRadius: 20,
              '&.Mui-checked': {
              },
            }}
           />
          } 
        label={label || 'Not label provided'} 
      />
    </FormGroup>
  );
}

export default CheckboxLabels;