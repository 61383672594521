
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from 'views/layouts';
import {  
  TextArea,
  NewContainerItem,
  NewContainerItemRow,
  NewContainerTittle,
  Title,
} from './styled';
import { useNotification } from 'lib/context/notification.context';
import createNewSkill from 'services/common/skills/create';
import { useSelector } from 'react-redux';
import { generalDataSelector } from 'ducks/auth';
import Input from 'views/components/UI/TextInput';
import { Button } from 'views/components/UI/Buttons';
import Select from 'views/components/UI/Select';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  skill: Yup.string().required('Nombre de la competencia es requerido'),
  description: Yup.string().required('Descripción general es requerida'),
  language: Yup.string().required('Idioma es requerido'),
  groupId: Yup.number().min(1, 'Grupo es requerido'),
  levelId: Yup.number().min(1, 'Nivel es requerido'),
  competence_descriptions: Yup.object().shape({
    cognitive: Yup.string().required('Descripción cognitiva es requerida'),
    emotional: Yup.string().required('Descripción emocional es requerida'),
    interactive: Yup.string().required('Descripción interactiva es requerida'),
  }),
});

export default function Index() {
  const { getError, getSuccess } = useNotification();
  const navigate = useNavigate();
  const { data: generalData } = useSelector(generalDataSelector);
  const [groupOptions, setGroupOptions] = useState<any[]>([])
  const [levelOptions, setLevelOptions] = useState<any[]>([])

  
  const formik = useFormik({
    initialValues: {
      skill: '',
      description: '',
      language: 'es',
      active: true,
      categoryId: 0,
      groupId: 0,
      levelId: 0,
      typeCategoryId: 2,
      competence_descriptions: {
        emotional: '',
        cognitive: '',
        interactive: '',
      },
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        skill,
        description,
        language,
        active,
        groupId,
        levelId,
        competence_descriptions:{
          emotional,
          cognitive,
          interactive,
        }
      } = values;
      if( !skill || !description  || !emotional || !cognitive || !interactive){
        return  getError('No fue posible crear la competencia, verifica que los campos esten llenos');
      }
      const bodyData = {
        skill,
        description,
        language,
        active,
        categoryId:2, //competencias - skills
        groupId: groupId,
        levelId: levelId,
        typeCategoryId: 2, //competencias - skills
        competence_descriptions:{
          emotional:emotional,
          cognitive:cognitive,
          interactive:interactive,
        }
      };
      createNewSkill(bodyData)
        .then(() => {
          getSuccess('Competencia creada correctamente');
          navigate(-1);  
        })
        .catch((err) => {
          getError('No fue posible crear la competencia, verifica que los campos esten llenos');
          console.error('error on create skill', err);
        });
    },
  });

  const handleSelectChange = (name: string) => (
    selectedValue: string | number
  ) => {
    const event = {
      target: {
        name, 
        value: selectedValue, 
      },
    };
    formik.handleChange(event);
  };
  useEffect(() => {
    const levelOptionsData = generalData.level.map((level: any) => {
      if (level.id === 1 || level.name === 'Betesa') {
        return null;
      }
      return {
        value: level.id,
        label: level.name,
      }
    }).filter((option: any) => option !== null);


    const groupOptionsData = generalData.group.map((group: any) => {
      if (group.id === 1 || group.name === 'Betesa') {
        return null;
      }
      return {
        value: group.id,
        label: group.name,
      }
    }).filter((option: any) => option !== null);

    setGroupOptions(groupOptionsData)
    setLevelOptions(levelOptionsData)

  }, [generalData.group, generalData.level])

  return (
    <MainLayout>
    <Title>Nueva competencia</Title>

          <form onSubmit={formik.handleSubmit}>
            <NewContainerItem>
              <NewContainerTittle>Nombre</NewContainerTittle>
              <Input
                name="skill"
                type="text"
                label="Competencia"
                placeholder="competencia"
                style={{ width: '50%' }}
                value={formik.values.skill}
                onChange={formik.handleChange}
              />
            </NewContainerItem>
            <NewContainerItemRow>
            <Select
              label="Nivel"
              name="levelId"
              options={levelOptions}
              value={formik.values.levelId}
              onChange={handleSelectChange("levelId")} 
              error={formik.errors.levelId}
              hideNeverChoice
            />
            <Select
              label="Grupo"
              name="groupId" 
              options={groupOptions}
              value={formik.values.groupId}
              onChange={handleSelectChange("groupId")}
              error={formik.errors.groupId}
              hideNeverChoice
            />
          </NewContainerItemRow>
            <NewContainerItem>
              <NewContainerTittle>Descripción general</NewContainerTittle>
              <TextArea
                name="description"
                placeholder="Descripción general"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </NewContainerItem>
            <NewContainerItem>
              <NewContainerTittle>Descripción cognitiva</NewContainerTittle>
              <TextArea
                name="competence_descriptions.cognitive"
                placeholder="Descripción cognitiva"
                value={formik.values.competence_descriptions.cognitive}
                onChange={formik.handleChange}
              />
            </NewContainerItem>
            <NewContainerItem>
              <NewContainerTittle>Descripción emocional</NewContainerTittle>
              <TextArea
                name="competence_descriptions.emotional"
                placeholder="Descripción emocional"
                value={formik.values.competence_descriptions.emotional}
                onChange={formik.handleChange}
              />
            </NewContainerItem>
            <NewContainerItem>
              <NewContainerTittle>Descripción interactiva</NewContainerTittle>
              <TextArea
                name="competence_descriptions.interactive"
                placeholder="Descripción interactiva"
                value={formik.values.competence_descriptions.interactive}
                onChange={formik.handleChange}
              />
            </NewContainerItem>
            <div 
            style={{ 
              display: 'flex', 
              flexDirection: 'row', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              paddingTop:40,
              paddingBottom:40, 
            }}>
            <p 
              onClick={()=>{ navigate(-1)}} 
              style={{ 
                color: '#2D749C', 
                fontSize: 14, 
                fontWeight: 700, 
                cursor: 'pointer' 
              }}>
              Cancelar
            </p>
            <Button 
              type="submit"
            >
              Guardar
            </Button>
          </div>

          
          </form>
    </MainLayout>
  );
}