import styled from "styled-components";

export const Title = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #c6016b;
  letter-spacing: -0.05em;
  margin: 0;
  margin-bottom: 8px;
  text-align: justify;
  line-height: 2;
`

export const WrapperContent = styled.div`
  width: calc(100% - 48px);
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DescriptionText = styled.span`
  font-family: 'Lato-Regular';
  font-size: 10px;
  letter-spacing: -0.05em;
  margin-bottom: 8px;
  text-align: justify;
  line-height: 1.5;
`;

export const DescriptionDiv = styled.div`
  padding: 0;
  margin: 0;

  & > p {
    padding: 0 0 0 4px;
    margin: 1px 0;
    font-family: 'Lato-Regular';
    letter-spacing: -0.05em;
    text-align: justify;
    line-height: 1;
    font-size: 10px !important;
  } 

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: 'Lato-Regular';
    letter-spacing: -0.05em;
    text-align: justify;
    line-height: 1;
    font-size: 10px !important;
  }

  li {
    font-family: 'Lato-Regular';
    letter-spacing: -0.05em;
    text-align: justify;
    line-height: 1.1;
    font-size: 10px !important;
    padding: 0 0 0 15px;
    position: relative;
    padding-left: 20px;
    margin: 2px 0px 0px 0px;
    width: auto !important;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }

  ul:nth-of-type(1) li::before {
    background-color: #DD7AAF; 
  }

  ul:nth-of-type(2) li::before {
    background-color: #1380A6; 
  }

  ul:nth-of-type(3) li::before {
    background-color: #BDBBBB; 
  }
`;

export const DimensionImg = styled.img`
  width: 40%;
  align-self: center;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 1;
`;

export const Subtitle = styled.span`
  font-family: 'Spartan-SemiBold';
  color: #2d749c;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  margin-bottom: 8px;
  letter-spacing: -0.7px;
`;

export const DimensionText = styled.span`
  font-family: 'Spartan-SemiBold';
  width: auto;
  color: #1e1e1e;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
`
