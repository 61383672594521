import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { platformAdminRoutes } from 'router/routes'
import { ItemProcessContainer, Badge, Row, TextEdit, TextItalicProcess } from './styled'
import { P } from 'views/components/UI/Text'
import { IProcess } from './types'
import { useNotification } from 'lib/context/notification.context'
import { useFinishProcess } from 'hooks/process/useFinishProcess/useFinishProcess'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'

import FinishIcon from 'assets/icons/FinishIcon'
import PlusWithCircle from 'assets/icons/PlusWithCircle'

export const ItemSendedProcess = ({ name, processId }: IProcess) => {
  const navigate = useNavigate()
  const { getError } = useNotification()
  const { handleFinishProcess } = useFinishProcess()

  const [loading, setLoading] = useState(false)

  const handleAddUsers = useCallback(() => {
    if (!loading) {
      setLoading(true)

      getProcessDetail(processId)
        .then((response: any) => {
          setLoading(false)
          handleFinishProcess(response.process)
          navigate(platformAdminRoutes.CreateUsers, { state: { id: processId, isSendedProcess: true } })
        })
        .catch(() => {
          getError('Ha ocurrido un error al cargar este proceso')
          setLoading(false)
        })
    }
  }, [navigate, processId, getError, handleFinishProcess, loading])

  return (
    <div>
      <ItemProcessContainer>
        <P>{name}</P>
        <Badge>Enviado</Badge>
      </ItemProcessContainer>
      <Row width="45%" margin="-2px 0px 4px 0px">
        <PlusWithCircle />
        <TextEdit onClick={handleAddUsers}>Administrar usuarios</TextEdit>
      </Row>
    </div>
  )
}

export const ItemDraftProcesses = ({ name, processId, onFinishProcess }: IProcess) => {
  return (
    <div>
      <ItemProcessContainer>
        <TextItalicProcess>{name}</TextItalicProcess>
        <Badge bgColor="#C2C2C2">Borrador</Badge>
      </ItemProcessContainer>
      <Row width="30%" margin="-14px 0px 0px 0px">
        <FinishIcon />
        <TextEdit onClick={() => onFinishProcess && onFinishProcess(processId)}>Terminar</TextEdit>
      </Row>
    </div>
  )
}
