import styled from 'styled-components'
import {IText } from '../../store'

export const ModulesQuestionContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  display: grid;
  grid-template-columns : repeat(2, 1fr);;
  gap: 30px;
  margin-top: 40px;
`

export const TextArea = styled.textarea<IText>`
font-family: 'Inter', sans-serif;
font-style: normal;
margin-top:30px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

font-weight: ${(props) => props.fontWeight || 400};
margin: ${(props) => props.margin || '0px'};
font-size: ${(props) => props.fontSize || '16px'};
line-height: 26px;
letter-spacing: 0.00735em;
color: ${(props) => props.color || '#303030'};
@media (max-width: 768px) {
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 540px) {
  font-size: 12px;
  line-height: 22px;
}

`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`

export const InvertedContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`