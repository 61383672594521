export const headers = [
    {
      key: 'group',
      name: 'Grupo',
    },
    {
      key: 'dimensionName',
      name: 'Dimensión',
    },
    {
      key: 'skill',
      name: 'Competencia',
    },
    {
      key: 'text',
      name: 'Texto de pregunta',
    },
  ]