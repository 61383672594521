import { FC,  useEffect, useState } from 'react';
import { MainLayout } from 'views/layouts'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import useSuspension from 'hooks/company/useSuspendPlatformAdmin/useSuspendPlatformAdmin'
import { ActionSuspendUserAdminPlatform } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import ModalSuspendUser from './modal-supend-user';
import { DEFAULT_LIMIT } from 'constants/global.constants';

const STATUS = {
  active: 1,
  aproveSuspend: 2,
  rejectSuspend: 3,
}



const headers = [
  {
    key: 'company_name',
    name: 'Compañia',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'user_name',
    name: 'Nombre',
  },
]

const viewUrl = "/company";
const updateUrl = "/companies/update";

const UserCompanies: FC = () => {

  const { fetchAdminsRequestedForSuspension, adminsRequestedForSuspensionData, makeSuspendAdminUser, paginationListAdmins } = useSuspension();
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [userIdSelected, setUserIdSelected] = useState<number | undefined>();
  const [requesIdSelected, setRequestIdSelected] = useState<number | undefined>();

  async function fetchData() {
    await fetchAdminsRequestedForSuspension({
      limit: DEFAULT_LIMIT,
      request_status: STATUS.active,
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAdminsRequestedForSuspension]);

  const handleSuspendUserByUserId = async (userId: number) => {
    const dataSupend = {
        userId,
        suspensionRequestId: requesIdSelected,
        statusRequest: STATUS.aproveSuspend,
    }
    await makeSuspendAdminUser([dataSupend]);
    fetchData();
    setIsOpenModal(false);
  }

  const handleActivateUserByUserId = async (userId: number) => {
    const dataSupend = {
        userId,
        suspensionRequestId: requesIdSelected,
        statusRequest: 3,
    }
    await makeSuspendAdminUser([dataSupend]);
    fetchData();
  }


  const handlePagination = (page: number) => {
    fetchAdminsRequestedForSuspension({
      limit: DEFAULT_LIMIT,
      page,
      request_status: STATUS.active,
    });
    setCurrentPage(page);
  }

  const handleSearch = (search: string) => {
    fetchAdminsRequestedForSuspension({
      limit: DEFAULT_LIMIT,
      search,
      request_status: STATUS.active,
    });
  }


  return (
    <MainLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 15,
          alignItems: 'baseline', 
        }}
      >
        <h2 style={{ fontSize: 32 }}>Solicitudes de suspensión de usuarios</h2>
      </div>
      <BluesiteTableComponent 
        data={adminsRequestedForSuspensionData as any}
        headers={headers} 
        searchBar={true} 
        viewUrl={viewUrl} 
        currentPage={currentPage} 
        totalPages={paginationListAdmins?.totalPages}
        handlePagination={handlePagination}
        updateUrl={updateUrl} 
        searchLabel=''
        ActionComponent={ActionSuspendUserAdminPlatform}
        onSearch={handleSearch}
        extraDataAction={{
          onSuspendUserAdminPlatform: (e: any) => {
            setUserIdSelected(e.user_id);
            setRequestIdSelected(e.request_id);
            setTimeout(() => {
              setIsOpenModal(true);
            }, 100);
          },
          onActivateUserAdminPlatform: (e: any) => {
            setUserIdSelected(e.user_id);
            setRequestIdSelected(e.request_id);
            setTimeout(() => {
              handleActivateUserByUserId(e.user_id);
            }, 500);
          },
        }}
      />
      <ModalSuspendUser
        isOpen={isOpenModal}
        handleClose={() => setIsOpenModal(false)}
        handleSuspendUser={(e: number) => handleSuspendUserByUserId(e)}
        userId={userIdSelected as number}
      />
    </MainLayout>

  )
}

export default UserCompanies
