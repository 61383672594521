import React, { FC } from 'react';

interface PendingIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const PendingIcon: FC<PendingIconProps> = ({
  width = '24',
  height = '24',
  color = '#C2C2C2'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke={color} />
      <text x="12" y="14" fill={color} fontSize="12" textAnchor="middle" dominantBaseline="middle">
        ?
      </text>
    </svg>
  );
};

export default PendingIcon;
