import { Page } from '../styled'
import HeadPage from './header'
import CompetenceContent from './competencies/group-content'
import FooterPage from './footer-page'
import { RangeScoreImg } from './competencies/range-score-img'
import { Container, Subtitle, WrapperSubtitles } from './competencies/group-content/styled'
import { Competence, Group, GroupPageProps } from '../store'

const GroupPage: React.FC<GroupPageProps> = ({
  groups,
  name,
  lastName,
  percentil,
  totalPages,
  page = 1,
  answerDate,
}) => {
  const competences = Object.values(groups).flatMap((group) => (group as Group).competences ?? [])
  const competencesSorted = [...competences].sort((a: Competence, b: Competence) => {
    if (b.competence_score === a.competence_score) {
      return a.competence.localeCompare(b.competence, 'es', { sensitivity: 'accent' })
    } else {
      return b.competence_score - a.competence_score
    }
  })

  return (
    <Page>
      <HeadPage isBetesa={false} />
      <RangeScoreImg />
      <Container>
        <WrapperSubtitles>
          <Subtitle>Competencia</Subtitle>
          <Subtitle>Percentil</Subtitle>
        </WrapperSubtitles>
        {competencesSorted.map((competence: Competence, index: number) => (
          <CompetenceContent
            key={`${index}-${competence.competence}`}
            competence={competence}
            percentilData={percentil}
          />
        ))}
      </Container>
      <FooterPage answerDate={answerDate} name={name} lastName={lastName} totalPages={Number(totalPages)} page={page} />
    </Page>
  )
}

export default GroupPage
