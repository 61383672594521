import React from 'react';

interface IProps {
    style?: React.CSSProperties;
    margin?: string;
}
const EditIcon: React.FC = (props: IProps) => {
    const { style } = props;

    return (
        <svg style={style} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1480_51279)">
                <path d="M24.5 0H0.5V24H24.5V0Z" fill="white" fillOpacity="0.01" />
                <path d="M4 21H22" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 13.36V17H9.6586L20 6.65405L16.3476 3L6 13.36Z" stroke="#2D749C" strokeWidth="1.5" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1480_51279">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditIcon;








