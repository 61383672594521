import React, { FC } from 'react'

import { BackButtonStyle } from './styled'

interface BackButtonProps {
  text: string
  type: 'button' | 'submit' | 'reset'
  onClick: () => void
  style?: React.CSSProperties
}

const BackButton: FC<BackButtonProps> = ({
  text,
  type = 'button',
  onClick,
  style
}) => {
  const handleClick = () => {
    onClick()
  }
  return (
    <BackButtonStyle
      style={style}
      type={type}
      onClick={handleClick}
    >
      {text}
    </BackButtonStyle>
  )
}

export default BackButton
