import axios from 'lib/axios'
import { AxiosResponse } from 'axios'

type IUsers = {
  id: number
  user_name: string
  company_name: string
  process_id: number
  process_name: string
}

type Data = {
  users: IUsers[]
}

interface IFilters {
  process_id?: number | string
  company_id: number
  limit?: number
  page?: number
  searchParams?: string
}

interface IResponse {
  users: Data
  status: string | 'success' | 'OK' | 'error'
  total_results: number
  total_pages: number
}

/**
 * @description service to get process by company user
 * @param token
 */
const getUserByProcess = async (filters: IFilters) => {
  const { company_id: companyId } = filters

  let PATH = `/company/process/users/${companyId}`

  const queryParams = new URLSearchParams()

  if (filters.process_id) queryParams.append('process_id', filters.process_id.toString())
  if (filters.limit) queryParams.append('limit', filters.limit.toString())
  if (filters.page) queryParams.append('page', filters.page.toString())
  if (filters.searchParams) queryParams.append('search', filters.searchParams.toString())

  PATH = `${PATH}?${queryParams.toString()}`

  try {
    const response: AxiosResponse<IResponse> = await axios
      .get(PATH)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.error('error on get process by company', err)
      })

    return response
  } catch (error) {
    console.error('error on get process by company', error)
  }
}

export default getUserByProcess
