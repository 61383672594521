// endpoint que obtiene la lista competencias
export const MOCK_ENDPOINT = {
  status: 'OK',
  data: {
    skills: [
      {
        id: 1,
        name: 'Analisis estructural',
        description: 'Esta es una descripcion de prueba para analisis estructural.',
        actions: [
          { id: 1, name: 'Realizar un análisis de carga en la estructura.' },
          { id: 2, name: 'Identificar los puntos de mayor tensión en la estructura.' },
          { id: 3, name: 'Calcular la resistencia de los materiales utilizados en la estructura.' },
          { id: 4, name: 'Evaluar la estabilidad sísmica de la estructura.' },
          { id: 5, name: 'Identificar los puntos débiles de la estructura y proponer soluciones.' },
          { id: 6, name: 'Realizar pruebas de carga para validar la resistencia de la estructura.' },
          { id: 7, name: 'Analizar los resultados de las pruebas y hacer recomendaciones.' },
          { id: 8, name: 'Diseñar estructuras más resistentes y estables.' },
          { id: 9, name: 'Identificar la capacidad de carga máxima de la estructura.' },
          { id: 10, name: 'Determinar las cargas de diseño que deben soportar las estructuras.' },
          { id: 11, name: 'Evaluar el comportamiento de las estructuras ante cargas dinámicas.' },
          { id: 12, name: 'Realizar estudios de patología de las estructuras para determinar su estado de deterioro.' },
        ],
      },
      {
        id: 2,
        name: 'Autoconocimiento',
        description: 'El autoconocimiento es el viaje al interior del ser humano',
        actions: [
          { id: 1, name: 'Reflexionar sobre los valores personales y la identidad.' },
          { id: 2, name: 'Identificar las emociones y pensamientos que influyen en el comportamiento.' },
          { id: 3, name: 'Evaluar las fortalezas y debilidades personales.' },
          { id: 4, name: 'Buscar oportunidades para mejorar y desarrollar habilidades.' },
          { id: 5, name: 'Practicar la autoaceptación y el amor propio.' },
          { id: 6, name: 'Aprender a establecer límites y expresar las necesidades de manera efectiva.' },
          { id: 7, name: 'Desarrollar habilidades sociales y de comunicación.' },
          { id: 8, name: 'Buscar apoyo y retroalimentación de amigos y familiares.' },
          { id: 9, name: 'Identificar las situaciones que generan estrés o ansiedad.' },
          { id: 10, name: 'Desarrollar habilidades para manejar el estrés y la ansiedad.' },
          { id: 11, name: 'Aprender a reconocer y regular las emociones.' },
          { id: 12, name: 'Establecer metas personales y trabajar para alcanzarlas.' },
        ],
      },
      {
        id: 3,
        name: 'Capacidad numérica',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.',
        actions: [
          { id: 1, name: 'Realizar cálculos aritméticos y algebraicos con rapidez y precisión.' },
          { id: 2, name: 'Interpretar y analizar datos numéricos para tomar decisiones informadas.' },
          { id: 3, name: 'Resolver problemas matemáticos y aplicar fórmulas para encontrar soluciones.' },
          { id: 4, name: 'Utilizar herramientas informáticas para realizar cálculos y análisis numéricos.' },
          { id: 5, name: 'Realizar estimaciones y aproximaciones de números y medidas.' },
          { id: 6, name: 'Comprender y aplicar conceptos matemáticos avanzados, como cálculo y estadística.' },
          { id: 7, name: 'Desarrollar y utilizar modelos matemáticos para simular situaciones reales.' },
          { id: 8, name: 'Realizar análisis de tendencias y proyecciones numéricas.' },
          {
            id: 9,
            name: 'Utilizar herramientas de visualización de datos para presentar información numérica de manera clara.',
          },
          { id: 10, name: 'Realizar análisis de riesgo financiero y económico.' },
          { id: 11, name: 'Interpretar y analizar gráficos y tablas numéricas.' },
          {
            id: 12,
            name: 'Aplicar conceptos matemáticos en diferentes áreas, como ciencias naturales, ingeniería y economía.',
          },
        ],
      },
      {
        id: 4,
        name: 'Toma de decisiones',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.',
        actions: [
          { id: 1, name: 'Identificar el problema y definir el objetivo de la toma de decisiones.' },
          { id: 2, name: 'Recopilar información relevante y analizarla de manera objetiva.' },
          { id: 3, name: 'Evaluar las posibles alternativas y los riesgos asociados a cada una.' },
          { id: 4, name: 'Seleccionar la mejor alternativa y desarrollar un plan de acción.' },
          { id: 5, name: 'Implementar el plan de acción y realizar un seguimiento de los resultados.' },
          { id: 6, name: 'Evaluar el éxito del plan de acción y hacer ajustes si es necesario.' },
          { id: 7, name: 'Buscar retroalimentación y opiniones de otros para tener una perspectiva diferente.' },
          { id: 8, name: 'Tomar decisiones en situaciones de incertidumbre y ambigüedad.' },
          {
            id: 9,
            name: 'Desarrollar habilidades de resolución de conflictos para tomar decisiones en situaciones difíciles.',
          },
          { id: 10, name: 'Considerar las consecuencias a largo plazo de las decisiones.' },
          { id: 11, name: 'Mantener la integridad y ética al tomar decisiones importantes.' },
          { id: 12, name: 'Aprender de errores pasados para tomar mejores decisiones en el futuro.' },
        ],
      },
      {
        id: 5,
        name: 'Comunicación efectiva',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.',
        actions: [
          { id: 1, name: 'Escuchar activamente y comprender los puntos de vista de los demás.' },
          { id: 2, name: 'Expresar ideas de manera clara y concisa.' },
          { id: 3, name: 'Utilizar el lenguaje corporal para reforzar el mensaje y la postura.' },
          { id: 4, name: 'Adaptar el estilo de comunicación según la audiencia y el contexto.' },
          { id: 5, name: 'Utilizar preguntas efectivas para obtener información y aclarar dudas.' },
          { id: 6, name: 'Evitar la comunicación defensiva y ser abierto a la retroalimentación.' },
          { id: 7, name: 'Utilizar ejemplos y analogías para ilustrar conceptos complejos.' },
          { id: 8, name: 'Utilizar el humor y la empatía para conectar con los demás.' },
          { id: 9, name: 'Ser claro y preciso al dar instrucciones y delegar tareas.' },
          { id: 10, name: 'Utilizar herramientas de comunicación como correo electrónico y chat de manera efectiva.' },
          { id: 11, name: 'Ser consciente de las barreras de comunicación y buscar maneras de superarlas.' },
          { id: 12, name: 'Dar y recibir retroalimentación de manera constructiva para mejorar la comunicación.' },
        ],
      },
    ],
  },
}
export const MOCK_COMPETENCE_LIST = {
  status: 'OK',
  data: {
    skills: [
      {
        id: 1,
        name: 'Analisis estructural',
        description: 'Esta es una descripcion de prueba para analisis estructural.',
        actions: [
          { id: 1, name: 'Realizar un análisis de carga en la estructura.' },
          { id: 2, name: 'Identificar los puntos de mayor tensión en la estructura.' },
          { id: 3, name: 'Calcular la resistencia de los materiales utilizados en la estructura.' },
          { id: 4, name: 'Evaluar la estabilidad sísmica de la estructura.' },
          { id: 5, name: 'Identificar los puntos débiles de la estructura y proponer soluciones.' },
          { id: 6, name: 'Realizar pruebas de carga para validar la resistencia de la estructura.' },
          { id: 7, name: 'Analizar los resultados de las pruebas y hacer recomendaciones.' },
          { id: 8, name: 'Diseñar estructuras más resistentes y estables.' },
          { id: 9, name: 'Identificar la capacidad de carga máxima de la estructura.' },
          { id: 10, name: 'Determinar las cargas de diseño que deben soportar las estructuras.' },
          { id: 11, name: 'Evaluar el comportamiento de las estructuras ante cargas dinámicas.' },
          { id: 12, name: 'Realizar estudios de patología de las estructuras para determinar su estado de deterioro.' },
        ],
      },
      {
        id: 2,
        name: 'Autoconocimiento',
        description: 'El autoconocimiento es el viaje al interior del ser humano',
        actions: [
          { id: 1, name: 'Reflexionar sobre los valores personales y la identidad.' },
          { id: 2, name: 'Identificar las emociones y pensamientos que influyen en el comportamiento.' },
          { id: 3, name: 'Evaluar las fortalezas y debilidades personales.' },
          { id: 4, name: 'Buscar oportunidades para mejorar y desarrollar habilidades.' },
          { id: 5, name: 'Practicar la autoaceptación y el amor propio.' },
          { id: 6, name: 'Aprender a establecer límites y expresar las necesidades de manera efectiva.' },
          { id: 7, name: 'Desarrollar habilidades sociales y de comunicación.' },
          { id: 8, name: 'Buscar apoyo y retroalimentación de amigos y familiares.' },
          { id: 9, name: 'Identificar las situaciones que generan estrés o ansiedad.' },
          { id: 10, name: 'Desarrollar habilidades para manejar el estrés y la ansiedad.' },
          { id: 11, name: 'Aprender a reconocer y regular las emociones.' },
          { id: 12, name: 'Establecer metas personales y trabajar para alcanzarlas.' },
        ],
      },
      {
        id: 3,
        name: 'Capacidad numérica',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.',
        actions: [
          { id: 1, name: 'Realizar cálculos aritméticos y algebraicos con rapidez y precisión.' },
          { id: 2, name: 'Interpretar y analizar datos numéricos para tomar decisiones informadas.' },
          { id: 3, name: 'Resolver problemas matemáticos y aplicar fórmulas para encontrar soluciones.' },
          { id: 4, name: 'Utilizar herramientas informáticas para realizar cálculos y análisis numéricos.' },
          { id: 5, name: 'Realizar estimaciones y aproximaciones de números y medidas.' },
          { id: 6, name: 'Comprender y aplicar conceptos matemáticos avanzados, como cálculo y estadística.' },
          { id: 7, name: 'Desarrollar y utilizar modelos matemáticos para simular situaciones reales.' },
          { id: 8, name: 'Realizar análisis de tendencias y proyecciones numéricas.' },
          {
            id: 9,
            name: 'Utilizar herramientas de visualización de datos para presentar información numérica de manera clara.',
          },
          { id: 10, name: 'Realizar análisis de riesgo financiero y económico.' },
          { id: 11, name: 'Interpretar y analizar gráficos y tablas numéricas.' },
          {
            id: 12,
            name: 'Aplicar conceptos matemáticos en diferentes áreas, como ciencias naturales, ingeniería y economía.',
          },
        ],
      },
      {
        id: 4,
        name: 'Toma de decisiones',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.',
        actions: [
          { id: 1, name: 'Identificar el problema y definir el objetivo de la toma de decisiones.' },
          { id: 2, name: 'Recopilar información relevante y analizarla de manera objetiva.' },
          { id: 3, name: 'Evaluar las posibles alternativas y los riesgos asociados a cada una.' },
          { id: 4, name: 'Seleccionar la mejor alternativa y desarrollar un plan de acción.' },
          { id: 5, name: 'Implementar el plan de acción y realizar un seguimiento de los resultados.' },
          { id: 6, name: 'Evaluar el éxito del plan de acción y hacer ajustes si es necesario.' },
          { id: 7, name: 'Buscar retroalimentación y opiniones de otros para tener una perspectiva diferente.' },
          { id: 8, name: 'Tomar decisiones en situaciones de incertidumbre y ambigüedad.' },
          {
            id: 9,
            name: 'Desarrollar habilidades de resolución de conflictos para tomar decisiones en situaciones difíciles.',
          },
          { id: 10, name: 'Considerar las consecuencias a largo plazo de las decisiones.' },
          { id: 11, name: 'Mantener la integridad y ética al tomar decisiones importantes.' },
          { id: 12, name: 'Aprender de errores pasados para tomar mejores decisiones en el futuro.' },
        ],
      },
      {
        id: 5,
        name: 'Comunicación efectiva',
        description:
          'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.',
        actions: [
          { id: 1, name: 'Escuchar activamente y comprender los puntos de vista de los demás.' },
          { id: 2, name: 'Expresar ideas de manera clara y concisa.' },
          { id: 3, name: 'Utilizar el lenguaje corporal para reforzar el mensaje y la postura.' },
          { id: 4, name: 'Adaptar el estilo de comunicación según la audiencia y el contexto.' },
          { id: 5, name: 'Utilizar preguntas efectivas para obtener información y aclarar dudas.' },
          { id: 6, name: 'Evitar la comunicación defensiva y ser abierto a la retroalimentación.' },
          { id: 7, name: 'Utilizar ejemplos y analogías para ilustrar conceptos complejos.' },
          { id: 8, name: 'Utilizar el humor y la empatía para conectar con los demás.' },
          { id: 9, name: 'Ser claro y preciso al dar instrucciones y delegar tareas.' },
          { id: 10, name: 'Utilizar herramientas de comunicación como correo electrónico y chat de manera efectiva.' },
          { id: 11, name: 'Ser consciente de las barreras de comunicación y buscar maneras de superarlas.' },
          { id: 12, name: 'Dar y recibir retroalimentación de manera constructiva para mejorar la comunicación.' },
        ],
      },
    ],
  },
}

export const MOCK_ALL_PROCESS_HOME_DATA = {
  status: 'OK',
  active_processes: 1,
  available_inventories: 48,
  total_tests_completed: 2,
  all_processes: [
    {
      process_id: 2,
      process_name: 'iOS Developer',
      active_process: true,
      testId: 5,
      test_name: 'Test Project Manager Competencias',
    },
    {
      process_id: 3,
      process_name: 'Project Manager',
      active_process: false,
      testId: 4,
      test_name: 'Test Project Manager Betesa',
    },
  ],
  total_tests_generated: {
    total: 6,
    betesaPercent: 50,
    skillPercent: 50,
  },
}

export const MOCK_HOME_PROCESS_BY_ID = {
  status: 'OK',
  active_processes: 1,
  total_inventories: 165,
  total_tests_completed: 1,
  all_processes: [
    {
      process_id: 2,
      process_name: 'iOS Developer',
      active_process: true,
      testId: 5,
      test_name: 'Test Project Manager Competencias',
    },
    {
      process_id: 3,
      process_name: 'Project Manager',
      active_process: false,
      testId: 4,
      test_name: 'Test Project Manager Betesa',
    },
  ],
  total_tests_generated: {
    total: 3,
    betesaPercent: 0,
    skillPercent: 100,
  },
}

export const HEADERS_USERS_WITH_TESTS_COMPLETED = [
  { name: 'Documento', key: 'document' },
  { name: 'Correo', key: 'email' },
  { name: 'Nombre', key: 'name' },
  { name: 'Fecha de envío', key: 'dateSend' },
  { name: 'Fecha de respuesta', key: 'answer_date' },
]

export const MOCK_USERS_WITH_TESTS_COMPLETED = [
  {
    document: '123456789',
    email: 'fake@user.com',
    name: 'Fake User',
    date_send: '12/12/2020',
    date_completed: '12/12/2020',
    id: 1,
  },
  {
    document: '0987654321',
    email: 'alsofake@user.com',
    name: 'Also Fake User',
    date_send: '12/12/2020',
    date_completed: '12/12/2020',
    id: 2,
  },
]

export const MOCK_USERS_TEAM_DEVELOPMENT = {
  status: 'OK',
  total_pages: 1,
  total_results: 5,
  users: [
    {
      document: '123456789',
      email: 'test1@mail.com',
      full_name: 'John Doe',
      user_id: 1,
    },
    {
      document: '123456789',
      email: 'test2@mail.com',
      full_name: 'John Doe',
      user_id: 2,
    },
    {
      document: '123456789',
      email: 'test3@mail.com',
      full_name: 'John Doe',
      user_id: 3,
    },
    {
      document: '9999999',
      email: 'test4@mail.com',
      full_name: 'John Doe',
      user_id: 4,
    },
    {
      document: '123456789',
      email: 'test5@mail.com',
      full_name: 'Jane Doe',
      user_id: 5,
    },
    /* {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 6,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 7,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 8,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 9,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 10,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 11,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 12,
    }, */
  ],
}

export const MOCK_BETESA_RESULTS = {
  status: 'OK',
  total_pages: 1,
  total_results: 5,
  users: [
    {
      id: 1,
      name: 'Manager',
      BD: '95',
      BI: '84',
      FD: '77',
      FI: '63',
      alert_level: 'EI',
      domination: 'BI',
      full_name: 'John Doe',
      quadrant_develop: 'FI',
      user_id: 1,
    },
    {
      id: 2,
      name: 'Manager',
      BD: '90',
      BI: '82',
      FD: '73',
      FI: '68',
      alert_level: 'EI',
      domination: 'BI',
      full_name: 'John Doe',
      quadrant_develop: 'FI',
      user_id: 2,
    },
    {
      id: 3,
      name: 'Manager',
      BD: '80',
      BI: '94',
      FD: '77',
      FI: '64',
      alert_level: 'EI',
      domination: 'BI',
      full_name: 'John Doe',
      quadrant_develop: 'FI',
      user_id: 3,
    },
    {
      id: 4,
      name: 'Manager',
      BD: '99',
      BI: '82',
      FD: '79',
      FI: '66',
      alert_level: 'EI',
      domination: 'BI',
      full_name: 'John Doe',
      quadrant_develop: 'FI',
      user_id: 4,
    },
    {
      id: 5,
      name: 'Manager',
      BD: '98',
      BI: '86',
      FD: '75',
      FI: '65',
      alert_level: 'EI',
      domination: 'BI',
      full_name: 'John Doe',
      quadrant_develop: 'FI',
      user_id: 5,
    },
    /* {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 6,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 7,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 8,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 9,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 10,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 11,
    },
    {
      document: '123456789',
      email: 'test@mail.com',
      full_name: 'John Doe',
      user_id: 12,
    }, */
  ],
}

export const MOCK_HISTORY_TEAM_MAP = {
  status: 'OK',
  total_pages: 1,
  total_results: 5,
  data: [
    {
      name: 'Team Map 1',
      created_at: '2020-12',
      id: 1,
    },
    {
      name: 'Team Map 2',
      created_at: '2020-12',
      id: 2,
    },
    {
      name: 'Team Map 3',
      created_at: '2020-12',
      id: 3,
    },
    {
      name: 'Team Map 4',
      created_at: '2020-12',
      id: 4,
    },
    {
      name: 'Team Map 5',
      created_at: '2020-12',
      id: 5,
    },
  ],
}
