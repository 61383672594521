import { useTranslation } from 'react-i18next'
import { ContainerTable, Subtitle, HeadingTable, TableCell, PreviewTable, ContainerT, TableFooter } from '../styled'

const TableResult = ({ data }: any) => {
  const { t } = useTranslation()
  const margin = data?.data?.length < 8 ? '48px' : '0'
  return (
    <ContainerT>
      <ContainerTable>
        <Subtitle marginBottom={margin}>Resumen de Resultados</Subtitle>

        <PreviewTable cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <HeadingTable style={{ width: '50%' }}>Grupo de { t('global.common.skillText') }</HeadingTable>
              <HeadingTable>Mediana</HeadingTable>
            </tr>
            {data?.data &&
              data?.data.map(({ groupName, average }: any) => (
                <tr key={`${groupName}-${average}`}>
                  <HeadingTable>{groupName}</HeadingTable>
                  <TableCell>{Math.ceil(average)}</TableCell>
                </tr>
              ))}
          </tbody>
        </PreviewTable>
        <TableFooter marginTop={margin}>
          <Subtitle style={{ textAlign: 'center', display: 'inline', fontSize: 16 }}>
            Puntuación mediana:{' '}
            <p style={{ color: '#98B06F', fontSize: 20, display: 'inline' }}>{Math.ceil(data?.totalAverage)}</p>
          </Subtitle>
        </TableFooter>
      </ContainerTable>
    </ContainerT>
  )
}
export default TableResult
