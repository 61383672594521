import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

interface IDataItemProps {
    width?: string;
}

export const DataItem = styled.div<IDataItemProps>`
    display: flex;
    flex-direction: column;
    width : ${props => props.width ? props.width : '100%'};
`;

// text with left dot


export const TextWidthDotContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -10px;
`;

export const TextDot = styled.p`
    color: #797A7C;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-left: 10px;
`;

export const Dot = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #797A7C;
`;

export const ItemInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0px;
`;

export const TextTitle = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #212B36;
`;

export const TextLabel = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #797A7C;
`;

export const FooterContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: flex-end;
    justify-content: space-between ;
    margin-top: 20px;
`;

export const BackButton = styled.div`
    display: flex;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100px;

    // hover
    &:hover {
        cursor: pointer;
        opacity: 0.8;
        transform: scale(1.01);
    }
`;

export const TextBackButton = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #2D749C;
    margin-left: 10px;
    margin-top: 12px;
`;

export const HeadContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: center;
    padding: 0px 6px;
`;

interface IRowProps {
    width?: string;
    justify?: string;
    height?: string;
    margin?: string;
}

export const Row = styled.div<IRowProps>`
    display: flex;
    flex-direction: row;
    width:  ${props => props.width ? props.width : '100%'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    height: ${props => props.height ? props.height : 'auto'};
    align-items: center;
    margin: ${props => props.margin ? props.margin : '0px'};
`;


export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #1F2124;
`;

// GRID

export const GridContainer = styled.div`
    width: 100%; 
`;

export const TitleGrid = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #1F2124;
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    margin: 10px 0px 16px 0px;

`;

export const GridCompetences = styled.div`
    display: grid;
    grid-template-columns: 8fr 2fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 16px;
`;

export const GridMinimunRecord = styled.div`
    display: grid;
    grid-template-columns: 4fr 8fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 20px;
`;

export const GridModal = styled.div`
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 20px;
`;


interface IGridItemProps {
    width?: string;
    justify?: string;
    alignItems?: 'center' | 'flex-start' | 'flex-end',
    flexDirection?: 'row' | 'column'
}

export const GridItem = styled.div<IGridItemProps>`
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    width: ${props => props.width ? props.width : '100%'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
`;

// MODAL

export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
`;

export const BetesaContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    z-index: 5;
`;





