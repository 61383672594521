/* eslint-disable */

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { Container, Content, SelectorsContainer, FooterContent } from './styled'
import Stepper from 'views/components/Stepper'
import { H2, P } from 'views/components/UI/Text'
import InputRadio from 'views/components/UI/InputRadio'
import { Button, BackButton } from 'views/components/UI/Buttons'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import ModalConfirmDeleteEditedRoleFamily from './modal-confirm-delete-edited-rolefamily'
import { platformAdminRoutes } from 'router/routes'
import InfoRoleFamilyContainer from 'views/containers/RoleFamily/infoRoleFamily'
import AlertComponent from 'views/components/Alert'
import {
  stepOneSelector,
  stepTwoSelector,
  setStepTwoTrigger,
  setLoading as setLoadingProcess,
  setStepThreeTrigger,
  setStepFourTrigger,
  stepFourSelector,
} from 'ducks/createProcess'
import { RoleFamily as IRoleFamily, Level as ILevel } from 'types/common'
import { STEPS_NEW_PROCESS } from '../constants'
import { generalDataSelector } from 'ducks/auth'
import getRoleFamilyByFilters from 'services/rolFamily/fetchGetRoleFamilyByLevel'
import lodash from 'lodash'
import { parseQuadrantFullNameToShortName } from 'utils/common'

function RoleFamily() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: generalData } = useSelector(generalDataSelector)
  const stepOneData = useSelector(stepOneSelector)
  const stepTwoData = useSelector(stepTwoSelector)
  const stepFourData = useSelector(stepFourSelector)
  const isRoleFamilySelected = stepTwoData?.isRoleFamilySelected
  const levelOptions = generalData?.level
  const hasEditedRoleFamily = stepFourData?.isEditingRoleFamily

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showModalConfirmDeleteEditedRoleFamily, setShowModalConfirmDeleteEditedRoleFamily] = useState<boolean>(false)

  const [nameRoleFamilySelected, setNameRoleFamilySelected] = useState<string>(stepTwoData?.roleFamilyName || '')
  const [editingRoleFamily, setEditingRoleFamily] = useState(false)
  const [isSelectedRoleFamily, setIsSelectedRoleFamily] = useState<boolean | null>(isRoleFamilySelected || null)
  const [roleFamilies, setRoleFamilies] = useState<IRoleFamily[]>([])
  const [roleFamily, setRoleFamily] = useState<any>()

  useEffect(() => {
    const levelSelected = levelOptions?.find((level: ILevel) => level.id === stepOneData.level)?.name || ''
    getRoleFamilyByFilters({ level: levelSelected }).then((res) => {
      if (res.status === 'OK') {
        setRoleFamilies(res.roleFamilies)
      }
    })
  }, [stepTwoData, stepOneData, generalData, levelOptions])

  useEffect(() => {
    if (!isRoleFamilySelected) {
      setNameRoleFamilySelected('')
      setIsSelectedRoleFamily(null)
    }
  }, [isRoleFamilySelected])

  const handleCreateProcessFromScratch = () => {
    dispatch(setStepTwoTrigger.run({ isRoleFamilySelected: false, processId: stepOneData.processId }))
    navigate(platformAdminRoutes.SelectTestsType)
  }

  const handleCreateProcessWithRoleFamily = () => {
    const profileValueEdited = stepFourData.betesaIdealProfile?.profileValues
      ? stepFourData.betesaIdealProfile?.profileValues.map((item: any) => {
          return {
            quadrant: parseQuadrantFullNameToShortName(item.quadrant),
            priority: item.priority,
            min: item.min,
            max: item.max,
          }
        })
      : roleFamily?.ideal_profile?.map((item: any) => {
          return {
            quadrant: parseQuadrantFullNameToShortName(item.quadrant),
            priority: item.priority,
            min: item.min,
            max: item.max,
          }
        })

    const competencesIdealProfile = stepFourData.competencesIdealProfile
      ? stepFourData.competencesIdealProfile?.map((item: any) => {
          return {
            skill_id: item.skill_id || item.id,
            weight: item.weight,
          }
        })
      : roleFamily?.competence_skills?.map((item: any) => {
          return {
            skill_id: item.skill_id || item.id,
            weight: item.weight,
          }
        })

    const betesaIdealProfile = {
      alertLevelId: stepFourData?.betesaIdealProfile?.alertLevelId || roleFamily?.ideal_profile[0]?.alert_level_range,
      profileValues: profileValueEdited,
      betesaSkills: [],
    }

    if (loading) {
      return
    }
    setLoading(true)

    const dispatchWithDelay = (action: any, delay: any) => {
      lodash.delay(() => {
        dispatch(action)
      }, delay)
    }

    dispatchWithDelay(
      setStepTwoTrigger.run({
        isRoleFamilySelected: true,
        processId: stepOneData.processId,
        roleFamilyName: nameRoleFamilySelected,
      }),
      0
    )
    dispatchWithDelay(
      setStepThreeTrigger.run({
        processId: stepOneData.processId,
        onlineCourse: false,
        useBetesaTest: roleFamily.ideal_profile.length > 1,
        useSkillsTest: roleFamily.competence_skills.length > 1,
      }),
      300
    )

    const dataUpdateStepFour: any = {
      processId: stepOneData.processId,
      isEditingRoleFamily: Object.keys(stepFourData).length > 0,
    }

    if (betesaIdealProfile.alertLevelId) {
      dataUpdateStepFour.betesaIdealProfile = betesaIdealProfile
    }

    if (competencesIdealProfile.length > 0) {
      dataUpdateStepFour.competencesIdealProfile = competencesIdealProfile
    }

    if (Object.keys(dataUpdateStepFour).length) {
      setLoading(false)
      dispatchWithDelay(setStepFourTrigger.run(dataUpdateStepFour), 500)
      navigate(platformAdminRoutes.CreateUsers)
      return
    }
    dispatchWithDelay(setStepFourTrigger.run(dataUpdateStepFour), 500)
    lodash.delay(() => {
      setLoading(false)
      navigate(platformAdminRoutes.CreateUsers)
    }, 1000)
  }

  return (
    <MainLayout>
      <Container>
        <H2 margin="0px 0px 20px 0px">Familia de rol</H2>
        <Stepper activeStep={editingRoleFamily ? 3 : 1} steps={STEPS_NEW_PROCESS} />
        <Content>
          <SelectorsContainer>
            <P marginRight="16px">Voy a usar</P>
            <InputRadio
              label="Familia de rol"
              value="Familia de rol"
              checked={isSelectedRoleFamily === true}
              onChange={() => {
                setIsSelectedRoleFamily(true)
              }}
            />
            <InputRadio
              label="Configuración desde cero"
              value="Configuración desde cero"
              checked={isSelectedRoleFamily === false}
              onChange={() => {
                setIsSelectedRoleFamily(false)
              }}
            />
          </SelectorsContainer>
          {isSelectedRoleFamily && (
            <InfoRoleFamilyContainer
              onEditRoleFamily={(e) => {
                setRoleFamily(e)
              }}
              defaultRole={nameRoleFamilySelected}
              onSelectRole={(e: string) => {
                setNameRoleFamilySelected(e)
              }}
              options={roleFamilies}
              onChangeEditingRoleFamily={(e: boolean) => setEditingRoleFamily(e)}
            />
          )}
          {!isSelectedRoleFamily && <></>}
        </Content>
        {editingRoleFamily !== true && !isSelectedRoleFamily && (
          <FooterContent>
            <BackButton onClick={() => navigate(platformAdminRoutes.CreateProcess)} />
            <Button
              disabled={
                (isRoleFamilySelected === true || isSelectedRoleFamily === null) && isSelectedRoleFamily !== false
              }
              onClick={() => (!isSelectedRoleFamily ? handleCreateProcessFromScratch() : null)}
              style={{
                padding: '10px 20px',
              }}
              type="button"
            >
              {isSelectedRoleFamily ? 'Siguiente' : 'Selección de pruebas'}
            </Button>
          </FooterContent>
        )}
        {isSelectedRoleFamily && !editingRoleFamily && (
          <FooterContent>
            <BackButton
              onClick={() => {
                if (hasEditedRoleFamily || (stepTwoData.roleFamilyName && stepFourData.processId)) {
                  setShowModalConfirmDeleteEditedRoleFamily(true)
                } else {
                  navigate(platformAdminRoutes.CreateProcess, { replace: true })
                }
              }}
            />
            <Button
              disabled={!nameRoleFamilySelected}
              type="submit"
              onClick={handleCreateProcessWithRoleFamily}
              onDisabledClick={() => {
                setShowAlert(true)
                setTimeout(() => {
                  setShowAlert(false)
                }, 3000)
              }}
            >
              Siguiente
            </Button>
          </FooterContent>
        )}
      </Container>
      {showModalConfirmDeleteEditedRoleFamily && (
        <ModalConfirmDeleteEditedRoleFamily
          visible={showModalConfirmDeleteEditedRoleFamily}
          onAccept={() => {
            dispatch(setLoadingProcess(false))
            navigate(platformAdminRoutes.CreateProcess, { replace: true })
          }}
          onCancel={() => setShowModalConfirmDeleteEditedRoleFamily(false)}
        />
      )}
      <ModalLoader isLoading={loading} />
      <AlertComponent message="Primero debes seleccionar una familia de rol" severity="warning" open={showAlert} />
    </MainLayout>
  )
}

export default RoleFamily
