import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  UpdateTag,
  Row, GridModal,
  ModalContainer,
  ModalHeader,
  ModalBody, BtnGoBack, Title, Strong
} from './styled'
import Modal from 'views/components/UI/Modal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { fetchGetActionsById } from '../../services/fetch-skills-talents';
import { DevelopmentPlan } from '../../store';
import { specialAdminRoutes } from 'router/routes';
import { useNotification } from 'lib/context/notification.context';

const ITEMS_PER_PAGE = 10;

interface ActionDetailModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  actionId: number | null;
}

const ActionDetailModal: FC<ActionDetailModalProps> = ({
  openModal,
  setOpenModal,
  actionId
}) => {
  const { getError } = useNotification();
  const navigate = useNavigate();
  const [actionDetail, setActionDetail] = useState({} as DevelopmentPlan);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);


  useEffect(() => {

    if (!loading) {
      setLoading(true);
      if (actionId !== null && actionId > 0) {
        fetchGetActionsById(actionId).then((developmentPlan) => {
          if (developmentPlan) {
            setActionDetail(developmentPlan);
            setLoading(false);
          } else {
            getError('Esta competencia no tiene acciones de desarrollo asociadas')
            setActionDetail({} as DevelopmentPlan);
            setLoading(false);
            setOpenModal(false);
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId])

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '45%',
        height: '80%',
        borderRadius: '10px',
        padding: '20px',
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>
        <ModalBody >
          <Title>{actionDetail?.name}</Title>
          {
            actionDetail?.actions && (
              <>
                <p>{actionDetail?.description}</p>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Strong>Acciones de desarrollo</Strong>
                  <UpdateTag onClick={() => {
                    navigate(`${specialAdminRoutes.UpdateBetesaAction}?id=${actionId}`)
                  }}>Editar acciones de plan de desarrollo</UpdateTag>
                </div>
              </>
            )
          }
          <GridModal style={{ height: '100%' }}>
            {actionDetail?.actions ? (
              actionDetail.actions.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE).map((action: any, index) => {
                return (
                  <p key={`${index}-${action.description}`}>{action.description}</p>
                )
              })
            ) : <h3>Esta competencia no tiene acciones asociadas.</h3>}
          </GridModal>

          {
            actionDetail?.actions?.length > 0 && (
              <Row style={{
                justifyContent: currentPage === 0 ? 'flex-end' : 'space-between',
              }} >
                {currentPage > 0 && (
                  <BtnGoBack onClick={() => { setCurrentPage(currentPage - 1) }}><ArrowCircleLeftOutlinedIcon /> Anterior</BtnGoBack>
                )}
                {currentPage < Math.ceil(actionDetail?.actions?.length / ITEMS_PER_PAGE) - 1 && (
                  <BtnGoBack onClick={() => { setCurrentPage(currentPage + 1) }}>Siguiente <ArrowCircleRightOutlinedIcon /></BtnGoBack>
                )}
              </Row>
            )
          }
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}

export default ActionDetailModal
