import { StepThree } from "types/common"

export const getCountQuestionnaires = (stepThree: StepThree): number => {
    const { useBetesaTest, useSkillsTest } = stepThree;

    return Number(useBetesaTest) + Number(useSkillsTest);
}


export const handleSaveLikeDraft = (getWarning: any, navigate: any) => {
    getWarning('Proceso guardado en borradores, podrás continuar la creación del mismo en cualquier momento')
    navigate('/')
}


export function deleteUsersDuplicate(users: any) {
    return users.reduce((acc: any, current: any) => {
        const x = acc.find((item: any) => item.document === current.document);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}