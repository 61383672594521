import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  padding: 5% 2%;
  gap: 30px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
`

export const SelectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: center;
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
`

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  padding-left: 20px;
  gap: 20px;
  min-height: 60px;
`

export const TextViewMore = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #c6016b;
  cursor: pointer;
`

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const VioletLink = styled.a`
  color: #c6016b;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`

export const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
`

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ModalBody = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
`

export const GridModal = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 10px;
  width: 100%;
  margin-top: 20px;
`

export const AnchorSection = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`

export const ResetButton = styled.div`
  // margin-left: 30px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const SearchButton = styled.div`
  display: flex;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const MagnifyingGlass = styled.img`
  width: 20px;
  height: 20px;
`

export const ButtonEdit = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  padding-left: 88px;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

export const ButtonSave = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  border: solid 1px #2d749c;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonCancel = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #d20000;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`
