import styled from 'styled-components'

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100px;

  &:hover {
    opacity: 0.8;
  }
`

export const TextButton = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #2d749c;
  margin-left: 5px;
  margin-bottom: 5px;
`
