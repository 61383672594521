import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 3.5% 0%;
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
    margin-bottom: 5%;
`;

export const InforCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 80vh;
    justify-content: space-between;
`;

interface IContainerRow {
    justifyContent?: string;
    margin?: string;
}

export const ContainerRow = styled.div<IContainerRow>`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: ${props => props.margin ? props.margin : '4px 0px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
`;

export const ContainerTestsPerformed = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 3% 0%;
`;