import { IdealProfileBetesa, IdealProfileSkills, ProcessDetail } from './entities'
import { User } from 'views/screens/super-admin/admin-platform-company/store'

export interface ProcessDetailState {
  data: {
    processDetail: ProcessDetail
    idealProfileBetesa: IdealProfileBetesa[]
    idealProfileSkills: IdealProfileSkills[]
    allPlatformAdmins: User[]
    adminIdsToDelete: number[]
    adminIdsToAdd: number[]
    adminIdsToSave: number[]
  }
  ui: {
    loading: boolean
    error: boolean
    isUpdated: boolean
    isDirty: boolean
    saveChanges: boolean
    tableChanged: boolean
  }
  pagination: {
    page: number
    totalPages: number
  }
}

export const processDetailState: ProcessDetailState = {
  data: {
    processDetail: {
      processName: '',
      processCreatedAt: '',
      processType: '',
      subscriptionName: '',
      levelName: '',
      username: '',
      hasAutoDeliveryReport: false,
      hasCreateDevelopmentPlan: false,
      hasBetesaTest: false,
      hasCompetencesTest: false,
      roleFamilyName: '',
    },
    idealProfileBetesa: [
      {
        alert_level: '',
        quadrant: 'BI',
        priority: 0,
        min: 0,
        max: 0,
      },
    ],
    idealProfileSkills: [
      {
        skillName: '',
        skillWeight: 0,
      },
    ],
    allPlatformAdmins: [],
    adminIdsToDelete: [],
    adminIdsToAdd: [],
    adminIdsToSave: [],
  },
  ui: {
    loading: false,
    error: false,
    isUpdated: false,
    isDirty: false,
    saveChanges: false,
    tableChanged: false,
  },
  pagination: {
    page: 1,
    totalPages: 0,
  },
}
