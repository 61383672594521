import { SKILLS_NAME } from 'constants/global.constants'

export const dataCompetences = [
  { value: 'Actitud de servicio', label: 'Actitud de servicio' },
  { value: 'Administración de información', label: 'Administración de información' },
  { value: 'Agudeza', label: 'Agudeza' },
]

export const dataUsers = [
  { value: '1', label: 'Usuario 1' },
  { value: '2', label: 'Usuario 2' },
  { value: '3', label: 'Usuario 3' },
  { value: '4', label: 'Usuario 4' },
  { value: '5', label: 'Usuario 5' },
  { value: '6', label: 'Usuario 6' },
  { value: '7', label: 'Usuario 7' },
  { value: '8', label: 'Usuario 8' },
  { value: '9', label: 'Usuario 9' },
  { value: '10', label: 'Usuario 10' },
  { value: '11', label: 'Usuario 11' },
  { value: '12', label: 'Usuario 12' },
  { value: '13', label: 'Usuario 13' },
  { value: '14', label: 'Usuario 23' },
  { value: '15', label: 'Usuario 3' },
  { value: '16', label: 'Usuario 4' },
  { value: '17', label: 'Usuario 5' },
  { value: '18', label: 'Usuario 6' },
  { value: '19', label: 'Usuario 7' },
  { value: '20', label: 'Usuario 83' },
  { value: '21', label: 'Usuario 9' },
  { value: '22', label: 'Usuario 10' },
  { value: '23', label: 'Usuario 11' },
  { value: '24', label: 'Usuario 12' },
]

export const optionsData = [
  {
    name: `Mapa ${SKILLS_NAME}`,
    value: '1',
  },
  {
    name: 'Mapa de equipo Betesa',
    value: '2',
  },
  {
    name: '9Box',
    value: '3',
  },
]

export const usersTest = [
  {
    id: 1,
    name: 'Juan',
    lastName: 'Perez',
  },
  {
    id: 2,
    name: 'Pedro',
    lastName: 'Gomez',
  },
  {
    id: 3,
    name: 'Maria',
    lastName: 'Martinez',
  },
  {
    id: 4,
    name: 'Jose',
    lastName: 'Perez',
  },
  {
    id: 5,
    name: 'Luis',
    lastName: 'Gomez',
  },
  {
    id: 6,
    name: 'Luisa',
    lastName: 'Perez',
  },
  {
    id: 7,
    name: 'Luis',
    lastName: 'Marquez',
  },
  {
    id: 8,
    name: 'Ochoa',
    lastName: 'Perez',
  },
  {
    id: 9,
    name: 'Nolverto',
    lastName: 'Vasquez',
  },
  {
    id: 10,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
  {
    id: 11,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
  {
    id: 12,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
  {
    id: 13,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
  {
    id: 14,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
  {
    id: 15,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
  {
    id: 16,
    name: 'Mario',
    lastName: 'Gonzalez',
  },
]
