import axios from 'lib/axios';
import { AxiosResponse } from 'axios';

export interface TextItem {
    id: number,
    description_box: string,
    language: string,
    name_box: string,
    action: string,
    total_score_box: string 
}
type TextType = 'potencial' | 'plan_carrera'
export interface IResponse {
    status: 'success' | 'error',
    data: TextItem[]
}

export const getNineboxTextsServices = async (textType: TextType) => {
    const PATH = `${process.env.REACT_APP_MAIN_API}/nine-box/description?text_type=${textType}`

    try {
        const response: AxiosResponse<IResponse> = await axios.get(PATH).then((res) => {
            return res.data
        }).catch((err) => {
            console.error('error on get ninebox texts', err)
        })
        return response
    } catch (error) {
        console.error('error on get ninebox texts', error)
    }
};
