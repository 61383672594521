import styled from 'styled-components'

export const ErrorContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 12px;
  color: red;
`;

export const TextError = styled.span`
  color: red;
  text-align: left!important;
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 5px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  padding-bottom: 40px;
`

export const LanguageRow = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
`

export const LanguageRowItem = styled.div`
  display: flex;
  flex-direction: row;
  gap:5px;
`

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
`

export const Paragraph = styled.p`
  color: #1F2124;
  font-size:18px;

  @media (max-width: 574px)  {
    font-size: 2.5vw;
  }
`

export const TextArea = styled.textarea`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top:30px;
  width: 100%;
  height: 120px;
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid rgba(145, 158, 171, 0.2);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const ContainerInputs = styled.div`
  display: flex; 
  flex-direction: column;
  width: 100%;
`
