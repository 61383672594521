import React from 'react'
import { useNotification } from 'lib/context/notification.context'
import { ButtonUpload } from './styled'
import { MAX_FILE_SIZE_LOGO } from 'constants/global.constants'

interface IProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    value: string
}

function Index(props: IProps) {
    const { onChange, value } = props
    const { getError } = useNotification();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE_LOGO) { 
                getError("El tamaño del logo no debe exceder los 600 kilobytes.");
                return;
            }
            onChange(e);
        }
    };

    return (
        <ButtonUpload>
            <input
                style={{ opacity: 0, position: 'absolute', maxWidth: 200, height: 70 }}
                type="file"
                id="photo"
                name="photo"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                value={value}
            />
            <svg width="30" height="37" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.65135 0.916992C1.33006 0.916992 1.02194 1.04462 0.794754 1.2718C0.567571 1.49899 0.439941 1.80711 0.439941 2.1284C0.439941 2.44968 0.567571 2.75781 0.794754 2.98499C1.02194 3.21218 1.33006 3.33981 1.65135 3.33981H18.611C18.9323 3.33981 19.2405 3.21218 19.4676 2.98499C19.6948 2.75781 19.8224 2.44968 19.8224 2.1284C19.8224 1.80711 19.6948 1.49899 19.4676 1.2718C19.2405 1.04462 18.9323 0.916992 18.611 0.916992H1.65135ZM11.3361 25.7105C11.2943 26.0145 11.1386 26.2914 10.9005 26.4851C10.6624 26.6787 10.3597 26.7748 10.0535 26.7539C9.74726 26.733 9.4604 26.5966 9.25086 26.3724C9.04132 26.1481 8.92473 25.8526 8.92463 25.5457V9.08672L4.12262 13.8823L3.98694 14.0002C3.7411 14.1829 3.43517 14.2657 3.13072 14.232C2.82627 14.1982 2.54592 14.0503 2.3461 13.8182C2.14628 13.586 2.04184 13.2868 2.05378 12.9807C2.06573 12.6746 2.19319 12.3844 2.4105 12.1685L9.33974 5.24575C9.46069 5.13981 9.60192 5.05958 9.75485 5.00993L9.88407 4.97278C9.96425 4.95571 10.046 4.94705 10.128 4.94694L10.2249 4.95017L10.3379 4.96309L10.5318 5.01155L10.6788 5.07292L10.7385 5.10523L10.8839 5.20214L11.0002 5.30228L17.8519 12.1669L17.9698 12.3042C18.142 12.5378 18.2246 12.8253 18.2027 13.1146C18.1807 13.4039 18.0557 13.6757 17.8503 13.8807L17.7146 13.9986C17.4811 14.1707 17.1935 14.2534 16.9042 14.2314C16.6149 14.2095 16.3431 14.0844 16.1382 13.879L11.3474 9.08187V25.5457L11.3361 25.7088V25.7105Z"
                    fill="#2D749C"
                />
            </svg>
        </ButtonUpload>
    )
}

export default Index
