/**
 * This file is generated by plopfile
 * avoid modifications and DO NOT remove the comments
 * comments above is used by plopfile to localize the line which  id going to generate the code
 */

// append_reducer_import
import { combineReducers } from '@reduxjs/toolkit'
import { userReducer as user } from 'ducks/user'
import { authReducer as auth } from 'ducks/auth'
import { modulesReducer as modules } from 'ducks/modules'
import { uiReducer as ui } from 'ducks/ui'
import { companyReducer as company } from 'views/screens/Companies/store'
import { createProcessReducer as createProcess } from 'ducks/createProcess'
import { percentilesReducer as percentiles } from 'views/screens/super-admin/percentiles/store'
import { evaluatedUserReducer as evaluatedUser } from 'ducks/evaluatedUser'
import { competencesQuestionsReducer as competencesQuestions } from 'views/screens/Evaluated-User/pages/QuestionsSkills/store'
import { questionReducer as questions } from 'views/screens/Evaluated-User/store'
import dataHomeReducer from 'ducks/evaluatedUser/selectors/dataHome'
import { usersReducer as users } from 'views/screens/Users/store/slice'
import { processDetailReducer as processDetail } from 'views/screens/Platform-administrator/Process/WatchSettings/store/slices'

export const rootReducer = combineReducers({
  user,
  auth,
  modules,
  createProcess,
  ui,
  company,
  percentiles,
  evaluatedUser,
  competencesQuestions,
  questions,
  dataHomeReducer,
  users,
  processDetail
})
