import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLoginSagas } from '../../../ducks/user'

// Components
import LoginForm from '../../components/Forms/Login'
import Logo from '../../components/UI/Logo'
import { P, H2 } from '../../components/UI/Text'
import { useNotification } from 'lib/context/notification.context'
// selector
import { errorSelector } from '../../../ducks/user'
// actions
import { setError } from 'ducks/user'

import Separator from 'views/components/UI/Separator/Index'
import { useTranslation } from 'react-i18next'
import { Container } from './styled'
import { IOS_DEVICE } from 'constants/global.constants'

const iOS = IOS_DEVICE.test(navigator.userAgent)

const LoginFormContainer: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const error = useSelector(errorSelector)

  const { getWarning } = useNotification()

  const handleResize = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (iOS) {
      window.addEventListener('resize', handleResize)

      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const handleLogin = (values: any): void => {
    dispatch(userLoginSagas.run({ email: values.email.toLowerCase(), password: values.password }))
  }

  useEffect(() => {
    dispatch(setError(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      if (error.code === '404') {
        getWarning(t('global.notifications.loginEmailErrorMessage'))
      } else if (error.code === '421') {
        getWarning(t('global.notifications.loginInactiveUserErrorMessage'))
      } else if (error.code === '420') {
        getWarning(t('global.notifications.loginEmailNotMainErrorMessage'))
      } else if (error.code === '401') {
        getWarning(t('global.notifications.loginEmailOrPasswordErrorMessage'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, getWarning])

  return (
    <>
      <Container>
        <Logo width="75%" />
        <Separator height="1rem" />
        <H2 marginTop="16px">{t('global.common.welcome')}</H2>
        <Separator height="2rem" />
        <P>{t('global.common.credentialsText')}</P>
        <Separator height="2rem" />
        <LoginForm onSubmit={(e) => handleLogin(e)} />
      </Container>
    </>
  )
}

export default LoginFormContainer
