import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { createSagasTrigger } from 'lib/redux/sagas'
// import jwt_decode from 'jwt-decode'

export const initialState = {
  betesa: {
    dataNewQuestionnaire: {},
    dataNewChapter: {},
  },
}

export type InitialState = typeof initialState
type SetChapter = PayloadAction<any>

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setDataNewQuestionnaire: (state, action: SetChapter) => {
      state.betesa.dataNewQuestionnaire = action.payload
    },
    cleanDataNewQuestionnaire: (state) => {
      state.betesa.dataNewQuestionnaire = {}
    },
    setDataNewChapter: (state, action: SetChapter) => {
      state.betesa.dataNewChapter = action.payload
    },
    cleanDataNewChapter: (state) => {
      state.betesa.dataNewChapter = {}
    }
  },
})

export const { setDataNewQuestionnaire, cleanDataNewQuestionnaire , setDataNewChapter, cleanDataNewChapter} = modulesSlice.actions


export const modulesReducer = modulesSlice.reducer
