import styled from "styled-components";

interface WrapperProps {
  align?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  width: 90%;
  height: auto;
  margin: 15px;
  justify-content: ${(props) => (props.align ? props.align : "center")};
  align-items: center;
`;
