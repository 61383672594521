/* eslint-disable @typescript-eslint/no-shadow */
export const handleDragEnter = (e: React.DragEvent, setDragging: (dragging: boolean) => void) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  
  export const handleDragLeave = (e: React.DragEvent, setDragging: (dragging: boolean) => void) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  
  export const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  export const handleDrop = (
    e: React.DragEvent,
    fileInputRef: React.RefObject<HTMLInputElement>,
    handleFiles: (files: FileList) => void,
    setDragging: (dragging: boolean) => void,
    setFileExists: (fileExists: boolean) => void
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0 && fileInputRef.current) {
      if (!e.dataTransfer.files[0].name) setFileExists(false)
      else setFileExists(true)
      fileInputRef.current.files = e.dataTransfer.files;
      handleFiles(e.dataTransfer.files);
    }
  };

export const isValidNameOrLastName = (text: string) => {
  return /^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s'-]+$/u.test(text);
};


export const isValidEmail = (email: string) => {

  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/u.test(email);
};
