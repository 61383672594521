import styled from 'styled-components'

export const ModulesQuestionContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns : repeat(2, 1fr);;
  gap: 30px;
  margin-top: 40px;
`

export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`
