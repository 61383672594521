import axios from 'lib/axios'

interface IGetCompanyLogo {
  token?: string
  processId: string
  isBase64?: boolean
}

const getCompanyLogo = async ({ processId, isBase64, token }: IGetCompanyLogo) => {
  const PATH = `${process.env.REACT_APP_MAIN_API}/cobranding`

  try {
    const response = await axios.get(PATH, {
      params: {
        processId: processId,
        isBase64: isBase64 ? isBase64 : false,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Error getting company logo', error)
    return { status: 'error', company_logo: '' }
  }
}

export default getCompanyLogo
