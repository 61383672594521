import styled from 'styled-components'
// impoerta la tipografia de material ui version 5
import { Typography } from '@mui/material'

interface IText {
  margin?: string
  weight?: number
  color?: string
  fontSize?: string
  fontWeight?: number
  disabled?: boolean
}

export const H1 = styled(Typography).attrs({
  variant: 'h1',
})`
  && {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.00735em;
    color: #000000;
  }
`
interface IH2 {
  margin?: string
}

export const H2 = styled(Typography).attrs({
  variant: 'h2',
})<IH2>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.00735em;
    color: #303030;
    margin: ${(props) => props.margin};

    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 36px;
    }

    @media (max-width: 540px) {
      font-size: 20px;
      line-height: 32px;
    }

  }
`

export const H3 = styled(Typography).attrs({
  variant: 'h3', 
})`
  && {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.00735em;
    color: #000000;
  }
`

export const H4 = styled(Typography).attrs({
  variant: 'h4',
})`
  && {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.00735em;
    color: #000000;
  }
`



export const H5 = styled(Typography).attrs({
  variant: 'h5',
})<IText>`
  && {
    // INTER FONT FAMILY
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.00735em;
    color: ${(props) => props.color || '#1F2124'};
    margin: ${(props) => props.margin};
  }
`

export const H6 = styled(Typography).attrs({
  variant: 'h6',
})`
  && {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.00735em;
    color: #000000;
  }
`

// componente p
export const P = styled(Typography).attrs({
  variant: 'body1',
})<IText>`
  && {
    font-family: 'Inter', sans-serif;
    font-weight: ${(props) => props.fontWeight || 400};
    margin: ${(props) => props.margin};
    font-size: ${(props) => props.fontSize || '16px'};
    line-height: 26px;
    letter-spacing: 0.00735em;
    color: ${(props) => (props.disabled ? '#808080' : props.color || '#303030')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 540px) {
    font-size: 12px;
    line-height: 22px;
  }
`;

// componente span
export const Span = styled(Typography).attrs({
  variant: 'body2',
})<IText>`
  && {
    margin: ${(props) => props.margin};
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.00735em;
    color: ${(props) => props.color || '#303030'};
  }
`

interface ISubtitleBold {
  fontSize?: string
  margin?: string
}

export const SubtitleBold = styled.p<ISubtitleBold>`
  font-family: 'Public Sans';
  font-size: ${(props) => props.fontSize || '16px'};
  font-style: normal;
  font-weight: 700;
  color: #1F2124;
  font-size: 16px;
  line-height: 20px;
  margin: ${(props) => props.margin};
  margin-right: 6px;
`

export const BoldBlueText = styled.p`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  color: #2D749C;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`