import React, { useState } from 'react'
import { MainLayout } from 'views/layouts'
import { ButtonContainer, Container, StandardButton, Title, TopContainer } from './styled'
import NewMapModal from './Components/NewMapModal'
import { useNavigate } from 'react-router-dom'
import { platformAdminRoutes } from 'router/routes'
import { useTeamMap } from './Hooks/useTeamMap'

const TeamDevelopment = () => {
  const navigate = useNavigate()
  const { createNewTeamMap } = useTeamMap()
  const [showNewMapModal, setShowNewMapModal] = useState(false)
  const [mapName, setMapName] = useState('')

  const handleHistory = () => {
    navigate(platformAdminRoutes.TeamDevelopmentHistory)
  }

  const handleNewMap = async () => {
    await createNewTeamMap(mapName)
    setShowNewMapModal(false)
  }

  return (
    <MainLayout>
      <Container>
        <NewMapModal
          show={showNewMapModal}
          setShow={setShowNewMapModal}
          onSave={handleNewMap}
          setMapName={setMapName}
        />
        <TopContainer>
          <Title>Desarrollo de equipos</Title>
          <ButtonContainer>
            <StandardButton onClick={() => setShowNewMapModal(true)}>Crear nuevo mapa</StandardButton>
            <StandardButton onClick={handleHistory}>Consultar históricos</StandardButton>
          </ButtonContainer>
        </TopContainer>
      </Container>
    </MainLayout>
  )
}

export default TeamDevelopment
