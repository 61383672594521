import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { MainLayout } from 'views/layouts'
import { TextArea, ModulesQuestionContainer } from './styled'
import { Button } from '../../../../../../components/UI/Buttons'
import Input from '../../../../../../components/UI/TextInput'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import { fetchCreateBetesaTalent } from '../../../services/fetch-betesa-talents'
import { Action } from '../../../store'
import { QUADRANT_OPTIONS } from '../../../constants'
import { useNotification } from 'lib/context/notification.context'
import { specialAdminRoutes } from 'router/routes'
import { Response } from '../../../store'
import { Box, Step, StepLabel, Stepper } from '@mui/material'
const stepsNames: any = ['Información básica', 'Acciones de desarrollo']
const CreateTalent: FC = () => {
  const navigate = useNavigate()
  const { getError, getSuccess, getWarning } = useNotification()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  const newTalentSchema = Yup.object().shape({
    name: Yup.string().required('El nombre del nuevo talento es obligatorio'),
    quadrant: Yup.string().required('Debes asignar un cuadrante'),
    description: Yup.string().required('El nombre del contacto es obligatorio'),
  })

  const onSubmit = (values: Action) => {
    fetchCreateBetesaTalent({
      name_spa: values.name,
      category: values.quadrant,
      description_spa: values.description,
    })
      .then((res: Response) => {
        if (res.status === 'OK' || res.status === 'success') {
          getSuccess('Talento creado correctamente')
          navigate(`${specialAdminRoutes.UpdateBetesaAction}?id=${res.data[0]?.id}`)
        } else {
          console.error('Error creating super admin')
          getWarning('No fue posible crear la cuenta')
        }
      })
      .catch(() => {
        getError('No se pudo crear el talento')
      })
  }

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Nuevo talento</h2>
      </TitleDiv>
      <Box sx={{ width: '100%', marginTop: 5 }}>
        <Stepper activeStep={0} alternativeLabel>
          {stepsNames?.map((label: any, index: any) => (
            <Step key={`${label}${index}`}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Formik
        validateOnChange={true}
        validationSchema={newTalentSchema}
        onSubmit={(values, { resetForm }) => {
          if (!isUploading) {
            setIsUploading(true)
            setButtonDisabled(true)
            onSubmit({
              name: values.name,
              quadrant: values.quadrant,
              description: values.description,
              competenceId: values.competenceId,
            })
            setTimeout(() => {
              setButtonDisabled(false)
              resetForm()
              setIsUploading(false)
            }, 1000)
          }
        }}
        initialValues={{
          name: '',
          quadrant: '',
          description: '',
          competenceId: 0,
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, errors }) => (
          <>
            <ModulesQuestionContainer>
              <Input
                value={values.name}
                type="text"
                name="name"
                label="Nombre"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Select
                label="Cuadrante asociado"
                options={QUADRANT_OPTIONS}
                value={values.quadrant}
                hideNeverChoice={true}
                onChange={(e) => {
                  handleChange('quadrant')(e.toString())
                }}
              />
            </ModulesQuestionContainer>
            <TextArea
              placeholder="Descripción general del talento"
              onChange={({ target }) => setFieldValue('description', target.value)}
              value={values.description}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 35,
                marginTop: 40,
                alignItems: 'baseline',
              }}
            >
              <span
                style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancelar
              </span>

              <Button
                style={{
                  background: ' #C6016B',
                  width: 162,
                  height: 52,
                  padding: '0 20px',
                  borderRadius: 8,
                  color: '#fff',
                  border: 'none',
                }}
                disabled={isSubmitting || Object.keys(errors).length > 0 || buttonDisabled}
                type="submit"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </div>
          </>
        )}
      </Formik>
    </MainLayout>
  )
}
export default CreateTalent
