export const testTranslations = {
  es: {
    startTestInfo: {
      acceptConsent: 'Aceptar consentimiento',
      introduction: {
        title: 'Introducción',
        text:
          '<p>Est&aacute;s iniciando las pruebas psicom&eacute;tricas de Bluesite. Seg&uacute;n la cantidad de pruebas asignadas por {{ companyName }}, podr&aacute;s tomar entre 20 minutos y una hora y media en completar todos los cuestionarios.&nbsp;</p>' +
          '<p><br></p>' +
          '<p>Tratamiento de Datos Personales: en cumplimiento con las disposiciones de la Ley 1581 de 2012 y su Decreto Reglamentario 1377 de 2013 que regulan lo relacionado con el derecho de Habeas Data en la legislaci&oacute;n que regula a Bluesite S.A.S., los datos que proporciones son de car&aacute;cter confidencial y su destinaci&oacute;n ser&aacute; usada exclusivamente para el an&aacute;lisis y procesamiento de informaci&oacute;n relacionada con las pruebas psicom&eacute;tricas de Bluesite. Al iniciar los cuestionarios est&aacute;s aceptando que tus datos sean tratados por Bluesite S.A.S.</p>',
      },
      informedConsent: {
        title: 'Consentimiento informado',
        text:
          '<p>Es importante que leas la siguiente informaci&oacute;n de forma completa y cuidadosa antes de responder las pruebas psicom&eacute;tricas asignadas. Si continuas, est&aacute;s indicando que entiendes y aceptas la siguiente informaci&oacute;n:&nbsp;</p>' +
          '<p><br></p>' +
          '<p>Las pruebas contenidas en Bluesite son modelos de diagn&oacute;stico e identificaci&oacute;n de competencias blandas y talentos naturales. Son ampliamente utilizadas en el mundo organizacional para diversos procesos, especialmente aquellos relacionados con Talento Humano. Es por esto que tus resultados podr&iacute;an ser utilizados para, aunque no limit&aacute;ndose a, los siguientes escenarios:</p>' +
          '<p><br></p>' +
          '<p>Procesos de selecci&oacute;n</p>' +
          '<p>Procesos de mentor&iacute;a o coaching</p>' +
          '<p>Procesos de desarrollo de competencias blandas</p>' +
          '<p>Evaluaci&oacute;n de desempe&ntilde;o</p>' +
          '<p><br></p>' +
          '<p>Dependiendo del proceso en el que te encuentres, tus resultados pueden ser compartidos con otras personas involucradas en el mismo. Tus resultados no ser&aacute;n compartidos con terceros que no est&eacute;n involucrados o que no sean funcionarios de Bluesite S.A.S. En ese sentido, mantenemos la confidencialidad de tus resultados, entendiendo que se trata de informaci&oacute;n sensible para la legislaci&oacute;n que nos rige.&nbsp;</p>' +
          '<p><br></p>' +
          '<p>La aplicaci&oacute;n de las pruebas de Bluesite no tiene ning&uacute;n efecto psicol&oacute;gico negativo conocido.</p> ',
      },
    },
  },
  en: {
    startTestInfo: {
      acceptConsent: 'Accept consent',
      introduction: {
        title: 'Introduction',
        text:
          '<p>You are entering the Bluesite psychometric tests. Depending on the number of tests assigned by {{ companyName }}, it may take between 20 minutes to an hour and a half to complete all the questionnaires.</p>' +
          '<p><br></p>' +
          "<p>Processing of Personal Data: in compliance with the provisions of Law 1581 of 2012 and its Regulatory Decree 1377 of 2013 that regulate matters related to the right of Habeas Data in the legislation that regulates Bluesite S.A.S. the data you provide is confidential and its destination will be used exclusively for the analysis and processing of information related to Bluesite's psychometric tests. By starting the tests you are accepting that your data is processed by Bluesite S.A.S.</p>",
      },
      informedConsent: {
        title: 'Informed Consent',
        text:
          '<p>It is important that you read the following information completely and carefully before taking any assigned psychometric test. By continuing, you are indicating that you understand and accept the following information:</p>' +
          '<p><br></p>' +
          '<p>The tests contained in Bluesite are models for the diagnosis and identification of soft skills and natural talents. They are widely used in the organizational world for various processes, especially those related to HR. This is why your results could be used for, but not limited to, the following scenarios:</p>' +
          '<p><br></p>' +
          '<p>Selection processes</p>' +
          '<p>Mentoring or coaching processes</p>' +
          '<p>Development of soft skills</p>' +
          '<p>Performance evaluation</p>' +
          '<p><br></p>' +
          '<p>Depending on the process you are in, your results can be shared with other people involved in it. Your results will not be shared with third parties that are not involved or that are not employees of Bluesite S.A.S. In this sense, we maintain the confidentiality of your results, understanding that it is sensitive information for the legislation that governs us.</p>' +
          '<p><br></p>' +
          '<p>The application of the Bluesite tests does not have any known negative psychological effects.</p> ',
      },
    },
  },
}
