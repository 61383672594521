import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const Subtitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-left: 48px;
`

export const ButtonBack = styled.div`
  color: #2d749c;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c6016b;
  color: white;
  font-weight: bold;
  border: none;
  padding: 14px 22px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
`

export const NoBetesaContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
