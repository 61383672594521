import axios from 'axios'
import { INineBoxAnalytics } from 'views/screens/Platform-administrator/Analytics/constants/types'

const saveNineBox = async (body: INineBoxAnalytics) => {
  const savedNineBox = await axios
    .post(`/nine-box/create`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return savedNineBox
}

export default saveNineBox
