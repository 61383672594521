import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { BetesaTitle, Container, LoaderContainer, WrapperTitle } from './styled'
import { MainLayout } from 'views/layouts'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import getAllUninterpretedBetesas from 'services/common/betesa/getBetesaUninterpreted'
import { ACTIONS, HEADER, SEARCH_LABEL } from './constants'
import InterpretationButton from './components/interpretation-button/interpretation-button'
import Loader from 'views/components/UI/Loader'

type UninterpretedBetesas = {
  user_name: string
  company_name: string
  betesa_result_id: number
}

const BetesaInterpretations: FC = () => {
  const { token } = useSelector((state: any) => state.user)
  const [betesas, setBetesas] = useState<UninterpretedBetesas[] | []>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParam, setSearchParam] = useState('')

  useEffect(() => {
    if (searchParam.length !== 0) return

    setIsLoading(true)
    getAllUninterpretedBetesas(token, page).then((res) => {
      setBetesas(res.uninterpretedBetesas)
      setTotalPages(res.total_pages)
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, searchParam])

  const handleSearch = (search: string) => {
    setSearchParam(search)
    if (search.length === 0) return

    getAllUninterpretedBetesas(token, page, search).then((res) => {
      setBetesas(res.uninterpretedBetesas)
      setTotalPages(res.total_pages)
    })
  }

  return (
    <MainLayout>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Container>
          <WrapperTitle>
            <BetesaTitle>Betesas por interpretar</BetesaTitle>
          </WrapperTitle>
          <BluesiteTableComponent
            ActionComponent={InterpretationButton}
            data={betesas}
            headers={HEADER}
            actions={ACTIONS}
            searchBar={true}
            searchLabel={SEARCH_LABEL}
            currentPage={page}
            handlePagination={setPage}
            totalPages={totalPages}
            fetchSearchData={handleSearch}
            onSearch={(e) => handleSearch(e)}
          />
        </Container>
      )}
    </MainLayout>
  )
}

export default BetesaInterpretations
