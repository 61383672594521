import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0px;
    overflow: hidden;
`;

export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 20px 0px;
    z-index: 2;
    bottom: 0;
`;