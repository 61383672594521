import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  margin: 0 0 10px 0;
  text-align: center;
`;

export const ModalBody = styled.div`
  width: 95%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const LargeText = styled.p`
    font-weight: 400;
    font-size: 21px;
    color: #303030;
    line-height: 40px;
`;

export const ContainerButtons = styled.div`
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`
export const Action = styled.span`
  color: #2D749C;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`
