/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect, useCallback } from 'react'
import ListTable from 'views/components/ListTable'
import { Container, ContainerActions, TextViewMore } from '../../pages/edit-role-family/styled'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable/bluesite-table'
import { useSelector } from 'react-redux';
import { tokenSelector } from 'ducks/user';
import fetchCompetences from 'services/competence/fechtCompetences'
import { HEADERS, ADDED_HEADERS, TypeCompetence, ActionComponentProps } from '../../constants/constants'
import { H4 } from 'views/components/UI/Text';
import { Button } from 'views/components/UI/Buttons';
import { useNotification } from 'lib/context/notification.context';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
function RenderStepContent(activeStep: any) {
  switch (activeStep.subStep) {
    case 'Betesa Premium':
      return <ListTable subStep={activeStep.subStep} />
    case 'Competencias':
      return <ListTable subStep={activeStep.subStep} />
    default:
      return <div>Not Found</div>;
  }
}



const DiagnosticInfoForm = ({ props }: any) => {
  const { values } = props
  const filterData = values?.level_id;
  const { getWarning, getSuccess } = useNotification()
  const token = useSelector(tokenSelector);
  const [competencesAdded, setCompetencesAdded] = useState<TypeCompetence[] | []>([]);
  const [currentAddedSkillsPage, setCurrentAddedSkillsPage] = useState(1);
  const [skillsData, setSkillsData] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState('');
  const [totalPages, setTotalPages] = useState(0)
  const [activeSearch, setActiveSearch] = useState(false)
  const [actionId, setActionId] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (id: any) => {
    setActionId(id);
    setOpenModal(true);
  }

  const handleAdd = (skill: any, skill_id: any, weight?: any, description?: any) => {
    const add = competencesAdded.filter((object: any) => object.skill === skill)
    if (add.length > 0) {
      getWarning(`${skill} ya fue seleccionada`);
    } else {
      setCompetencesAdded([...competencesAdded, { skill, skill_id, description, weight }]);
      getSuccess(`${skill} se agregó con éxito`);
    }
  }

  const handleRemove = (skill: any, skill_id: any, weight?: any, description?: any) => {
    const remove = competencesAdded.filter((object: any) => object.skill !== skill)
    getWarning(`${skill} se eliminó de la lista de competencias agregadas`);
    setCompetencesAdded(remove)
  }


  const handleAddedSkillsPagination = (page: number, limit = 5) => {
    setCurrentAddedSkillsPage(page);
  };

  const ActionComponent: FC<ActionComponentProps> = ({ id, skill }) => {
    const matchedSkill = skillsData.find((s: any) => s.id === id);
    const description = matchedSkill?.description || '';
    return (
      <ContainerActions>
        <TextViewMore onClick={() => handleOpenModal(id)}>
          Ver
        </TextViewMore>
        <Button onClick={() => handleAdd(skill, id, undefined, description)} type='button'>Agregar</Button>
      </ContainerActions>
    );
  };
  const RemoveComponent: FC<ActionComponentProps> = ({ id, skill }) => {
    const matchedSkill = skillsData.find((s: any) => s.id === id);
    const description = matchedSkill?.description || '';
    return (
      <ContainerActions>
        <DeleteForeverOutlinedIcon
          style={{
            color: 'red',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleRemove(skill, id, undefined, description)
          }}
        />
      </ContainerActions>
    );
  };


  const handleShowAllSkills = useCallback((page = 1, limit = 5) => {
    if (token) {
      fetchCompetences(page, limit, filterData).then(({ skills, total_pages }) => {
        setSkillsData(skills);
        setTotalPages(total_pages);
      })
    }
  }, [token, filterData])

  const fetchSearchData = useCallback((searchValue: any, page = 1, limit = 5) => {
    setSearchParam(searchValue);
    if (searchValue === "") {
      handleShowAllSkills(page, limit);
    } else {
      fetchCompetences(page, limit, searchValue).then(({ skills, total_pages }) => {
        setSkillsData(skills);
        setTotalPages(total_pages);
      })
    }
  }, [token, handleShowAllSkills]);


  useEffect(() => {
    if (searchParam.length > 0) {
      fetchSearchData(searchParam);
    }
  }, [searchParam, fetchSearchData]);

  useEffect(() => {
    if (searchParam.length <= 0) {
      handleShowAllSkills()
    }
  }, [searchParam, handleShowAllSkills]);

  return (
    <Container>
      <H4 margin='24px 0px 24px 0px'>Competencias</H4>
      <BluesiteTableComponent
        ActionComponent={ActionComponent}
        data={skillsData}
        headers={HEADERS}
        searchBar={true}
        totalPages={totalPages}
        handlePagination={handleShowAllSkills}
        currentPage={1}
        setSearchParam={setSearchParam}
        handleSearchFunction={() => fetchSearchData(searchParam)}
        searchLabel=""
        isAlignLeft={true}
      />
      {competencesAdded.length > 0 ?
        <>
          <H4 margin='24px 0px 24px 0px'>Competencias agregadas</H4>
          <BluesiteTableComponent
            ActionComponent={RemoveComponent}
            data={competencesAdded.slice((currentAddedSkillsPage - 1) * 5, currentAddedSkillsPage * 5)}
            headers={ADDED_HEADERS}
            searchBar={false}
            totalPages={Math.ceil(competencesAdded.length / 5)}
            handlePagination={handleAddedSkillsPagination}
            currentPage={currentAddedSkillsPage}
            setSearchParam={setSearchParam}
            handleSearchFunction={() => fetchSearchData(searchParam)}
            searchLabel=""
          />
        </>

        : null}
    </Container>
  )
}

export default DiagnosticInfoForm
