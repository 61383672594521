import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 60px;
    min-height: 90vh;
`;

interface IRow {
    width?: string
    margin?: string
    justifyContent?: string
    alignItems?: string
  }
  
  export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: ${(props: IRow) => props.alignItems || 'center'};
    justify-content: ${(props: IRow) => props.justifyContent || 'flex-start'};
    gap: 16px;
    margin-top: 10px 0px;
    width: ${(props: IRow) => props.width || '100%'};
    margin: ${(props: IRow) => props.margin || '0px'};
  `;