import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProcessSelected } from 'types/common'

export const initialState = {
  processSelected: {
    companyName: '',
    testLanguage: '',
    testType: '',
    companyId: 0,
    userTestId: 0,
  },
  haveBetesaInterpreted: false,
  haveSkillFinished: false,
  isBetesaTest: false,
}

export type InitialState = typeof initialState
type SetProcessSelected = PayloadAction<ProcessSelected>

const evaluatedUserSlice = createSlice({
  name: 'evaluatedUser',
  initialState,
  reducers: {
    setProcessSelected: (state, action: SetProcessSelected) => {
      state.processSelected = action.payload
    },
    clearProcessSelected: (state) => {
      state.processSelected = initialState.processSelected
    },
    setHaveBetesaInterpreted: (state, action: PayloadAction<boolean>) => {
      state.haveBetesaInterpreted = action.payload
    },
    setHaveSkillFinished: (state, action: PayloadAction<boolean>) => {
      state.haveSkillFinished = action.payload
    },
    setUserTestId: (state, action: PayloadAction<number>) => {
      state.processSelected.userTestId = action.payload
    },
    setIsBetesaTest: (state, action: PayloadAction<boolean>) => {
      state.isBetesaTest = action.payload
    }
  },
})

export const { setProcessSelected, clearProcessSelected, setHaveBetesaInterpreted, setUserTestId, setHaveSkillFinished, setIsBetesaTest } = evaluatedUserSlice.actions

export const evaluatedUserReducer = evaluatedUserSlice.reducer
