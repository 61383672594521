import { useState } from 'react'
import { Container } from './styled'
import EditSkillBetesaRoleFamily from '../betesa-skills/edit'
import { RoleFamily, CompetenceSkills } from 'types/common'

interface IProps {
  infoRoleFamily?: any
  onSaved: () => void
  onCancel: () => void
}

const EditRoleFamily = (props: IProps) => {
  const { onCancel, infoRoleFamily, onSaved } = props
  const databaseRoleFamilies: RoleFamily[] = infoRoleFamily.role_families

  const [roleFamilies, setRoleFamilies] = useState<RoleFamily[]>(databaseRoleFamilies)

  const onAddNewSkill = (skill: CompetenceSkills) => {
    const newSkill = {
      id: skill.id,
      name: skill.skill || skill.name,
      weight: undefined,
    }

    if (!roleFamilies.find((item: RoleFamily) => item.id === newSkill.id)) {
      setRoleFamilies([newSkill, ...roleFamilies] as RoleFamily[])
    }
  }

  return (
    <Container>
      <EditSkillBetesaRoleFamily
        onFinishEdit={onSaved}
        data={infoRoleFamily}
        onCancel={onCancel}
        onAddNewSkill={onAddNewSkill}
      />
    </Container>
  )
}

export default EditRoleFamily
