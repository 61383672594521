import styled from 'styled-components'

export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`
export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  width: 85%;
  height: 92px;
  padding: 12px;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const Icon = styled.img`
  max-width: 60px;
`

export const UploadSizeRecomended = styled.p`
  width: 174px;
  color: #797a7c;
  font-weight: 100;
  font-size: 16px;
`
export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  align-self: flex-end;
  padding-left: 30%;
  /* margin-bottom: 20px; */
`

export const TextViewMore = styled.p`
  // public sans font
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #c6016b;
  cursor: pointer;
  margin-right: 10px;
`

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 5px;
`
