import { useState, useEffect } from 'react';
import { getNineboxTextsServices, TextItem} from 'services/common/nineboxTexts/get-ninebox-texts-services'; // Please replace the path accordingly.

// This represents the state of our hook.
interface UseNineboxTextsState {
    textsNinebox: TextItem[] | null;
    loading: boolean;
    error: any;
}

type TextType = 'potencial' | 'plan_carrera';

const useNineboxTexts = (textType: TextType): UseNineboxTextsState => {
    const [textsNinebox, setData] = useState<TextItem[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: any = await getNineboxTextsServices(textType);
                // Check if the status is 'success' and set the data or error accordingly.
                if (response && response.status === 'OK') {
                    setData(response.data);
                } else {
                    setError(new Error('Failed to fetch ninebox texts.'));
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [textType]);

    return { textsNinebox, loading, error };
}

export default useNineboxTexts;
