
import React from 'react';

interface IProps {
    width?: string;
    height?: string;
}

const DeleteIcon = (props: IProps) => {
    const { width, height } = props;

    return (
        <svg width={width || '24'} height={height || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4307_37360)">
                <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
                <path d="M4.5 5V22H19.5V5H4.5Z" stroke="#E44646" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M10 10V16.5" stroke="#E44646" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14 10V16.5" stroke="#E44646" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 5H22" stroke="#E44646" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 5L9.6445 2H14.3885L16 5H8Z" stroke="#E44646" strokeWidth="1.5" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_4307_37360">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default DeleteIcon;
