import styled from "styled-components";

export const ErrorContainer = styled.div`
margin-top: 0px;
margin-bottom: 12px;
color: red;
`;

export const TextError = styled.span`
    color: red;
    text-align: left!important;
`;

export const ValidationsContainer = styled.div`
    margin-top: 12px;
    margin-bottom: 12px;
`;

export const ItemValidation = styled.div`
 display: flex;
 flex-direction: row;
 margin-bottom: 6px;
`;

interface IRow {
    margin?: string
  }
  
export const Row = styled.div<IRow>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin: ${(props) => props.margin || 'none'};
`

export const styleInput = {
    width: '100%',
    height: '50px',
    marginBottom: '10px',
  }
  
export  const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `
  
export const HaveAccount = styled.a`
    color: #212b31;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;
  `
  
  

