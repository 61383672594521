import { memo } from 'react'
import styled from 'styled-components'
// import convert64GrayScale from "utils/convert64GrayScale";
interface CobrandingLogoProps {
  logoUrl: string
  hideMobile?: boolean
  style?: React.CSSProperties
  isGrayScale?: boolean
  marginTop?: string
  imageStyle?: React.CSSProperties
}

interface CobrandingContainerProps {
  hideMobile?: boolean
  marginTop?: string
}

const Container = styled.div`
  height: 30px;
  @media (max-width: 992px) {
    display: ${(props: CobrandingContainerProps) => (props.hideMobile ? 'none' : 'flex')};
  }
  @media (min-width: 992px) {
    height: 45px;
    margin-top: ${(props: CobrandingContainerProps) => props.marginTop || '0'};
  }
`

const ImageLogo = styled.img`
  width: 100%;
  height: 100%;
  min-width: 40px;
  object-fit: contain;
  min-height: 30px;
`

const CobrandingLogo = (props: CobrandingLogoProps) => {
  const { logoUrl, hideMobile, style, imageStyle, marginTop } = props

  return (
    <Container hideMobile={hideMobile} style={style} marginTop={marginTop}>
      <ImageLogo style={imageStyle} src={logoUrl || ''} alt="logo" />
    </Container>
  )
}

export default memo(CobrandingLogo)
