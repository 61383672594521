import { all, call } from 'redux-saga/effects'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { userSagas } from 'ducks/user/sagas'
import { createProcessSagas } from 'ducks/createProcess/sagas'
import { SagaIterator } from 'redux-saga'


export default function* rootSaga(): SagaIterator {
  try {
    yield all([
      call(routinePromiseWatcherSaga),
      call(createProcessSagas),
      call(userSagas),
    ])
  } catch {
    return null
  }
}

