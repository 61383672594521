import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns : repeat(3, 1fr);;
  gap: 16px;
`
export const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row; 
  width: 256px;
  height: 92px;
  padding: 12px;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const Icon = styled.img`
  max-width: 60px;
`
export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`

export const UploadSizeRecomended = styled.p`
  width:174px;
  color: #797A7C;
  font-weight: 100;
  font-size: 16px;
`
export const InputGridDiv = styled.div`
margin-top: 40px;
width: 100%;
display: flex;
flex-direction: column;
gap:10px;
`
export const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
`
export const InputContainer = styled.div`
  width: 100%;
`;

export const Error = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
`;


export const BtnGoBack = styled.div `
    color: #2D749C;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
`
export const LabelContainer = styled.div`
  margin: 10px 0px;
`;

export const Label = styled.label`
  font-weight: bold;
`
export const OptionContainerItem = styled.div`
  display:flex; 
  flex-direction:row; 
  gap:20px; 
  align-items:center;
`
export const OptionContainerLabel = styled.span`
  font-weight:bold; 
  color:black;
  min-width: 6%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextQuadrantSelect = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1F2124;
  margin: 0 10px;
`

export const TypeQuestionContainer = styled.div`
  display:flex;
  width: 50%;
  
  // max media 992px
  @media (max-width: 992px) {
    width: 100%;
  }
`


export const TextError = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
`