import { useCallback, useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom'
import { StrokedButton } from 'views/components/UI/Buttons'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import TitleDiv from 'views/components/UI/TitleDiv'
import Modal from 'views/components/UI/Modal'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  Row,
  GridModal,
  ModalContainer,
  ModalBody,
  BtnGoBack,
  SaveButton,
  ContainerActionItem,
  DeleteTag,
  UpdateTag,
} from './styled'

import fetchDeleteActionById from 'services/developmentActions/fetchDeleteActionById/fetchDeleteActionById'
import { specialAdminRoutes } from 'router/routes'
import { fetchGetActionsById } from '../../../services/fetch-betesa-talents'
import { Action } from '../../../store'
import EditOneAction from '../edit-one-action/edit-one-action'
import { Box, Step, StepLabel, Stepper } from '@mui/material'
const stepsNames: any = ['Información básica', 'Acciones de desarrollo']

type ActionType = {
  step: number
  data: {
    id: number
    description: string
  }
}

const EditActions = () => {
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [actions, setActions] = useState<Action[]>([])
  const [actionId, setDevActionId] = useState(0)
  const [actionName, setActionName] = useState('')
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [step, setStep] = useState<ActionType>({
    step: 1,
    data: {
      id: 0,
      description: '',
    },
  })
  const searchParams = new URLSearchParams(window.location.search)
  const developmentPlanId = searchParams.get('id')

  const getAllSkillsBank = useCallback(
    (currentPage = 1) => {
      if (search.length) return
      fetchGetActionsById(Number(developmentPlanId), currentPage).then((data) => {
        setActions(data.development_plan.actions)
        setActionName(data.development_plan.name)
        setTotalPages(data.total_pages)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [developmentPlanId]
  )

  useEffect(() => {
    if (!search) return

    fetchGetActionsById(Number(developmentPlanId), page, search).then((data) => {
      setActions(data.development_plan.actions)
      setActionName(data.development_plan.name)
      setTotalPages(data.total_pages)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    getAllSkillsBank()
  }, [getAllSkillsBank, step])

  const hangleModalButton = (id: number) => {
    setOpenModal(true)
    setDevActionId(id)
  }
  const handleDeleteButton = () => {
    setOpenModal(false)
    fetchDeleteActionById(actionId).then(() => {
      setActions(actions.filter((action: any) => action.id !== actionId))
    })
  }

  const handlePagination = (currentPage: number) => {
    setPage(currentPage)
    getAllSkillsBank(currentPage)
  }

  const handleSearch = (searchCriteria: string) => {
    setSearch(searchCriteria)
    if (searchCriteria.length === 0) {
      getAllSkillsBank()
    }
  }

  const headers = [
    {
      key: 'description',
      name: 'Acciones',
    },
  ]
  const ActionComponent = (props: any) => {
    const { id, description = '' } = props
    return (
      <ContainerActionItem>
        <UpdateTag
          onClick={() =>
            setStep({
              step: 2,
              data: {
                id: id,
                description: description,
              },
            })
          }
        >
          Editar
        </UpdateTag>
        <DeleteTag onClick={() => hangleModalButton(id)}>Eliminar</DeleteTag>
      </ContainerActionItem>
    )
  }

  if (step.step === 1) {
    return (
      <MainLayout>
        <TitleDiv>
          <h2 style={{ fontSize: 32 }}>Editar acción de desarrollo {actionName}</h2>
          <StrokedButton
            type="button"
            onClick={() => {
              navigate(`${specialAdminRoutes.NewBetesaAction}?developmentPlanId=${developmentPlanId}`)
            }}
          >
            Nueva acción
          </StrokedButton>
        </TitleDiv>
        <Box sx={{ width: '100%', margin: '40px 0px 40px 0px' }}>
          <Stepper activeStep={1} alternativeLabel>
            {stepsNames?.map((label: any, index: any) => (
              <Step key={`${label}${index}`}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <BluesiteTableComponent
          data={actions}
          currentPage={page}
          handlePagination={handlePagination}
          totalPages={totalPages}
          headers={headers}
          searchBar={true}
          onSearch={handleSearch}
          fetchSearchData={handleSearch}
          ActionComponent={ActionComponent}
          searchLabel=""
        />
        <Modal
          hideCloseButton={true}
          show={openModal}
          setShow={setOpenModal}
          styleContent={{
            width: '560px',
            height: '280px',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#FFFFFF',
          }}
        >
          <ModalContainer>
            <ModalBody>
              <DeleteOutlineOutlinedIcon />
              <h3 style={{ textAlign: 'center' }}>¿Seguro que deseas eliminar esta acción?</h3>
              <GridModal>
                <p style={{ width: '100%', marginTop: 0, color: '#303030', textAlign: 'center' }}>
                  Si eliminas esta acción no podrás volver a acceder a ella
                </p>

                <Row>
                  <BtnGoBack
                    onClick={() => {
                      setOpenModal(false)
                    }}
                  >
                    Cancelar
                  </BtnGoBack>
                  <SaveButton onClick={handleDeleteButton}>Eliminar</SaveButton>
                </Row>
              </GridModal>
            </ModalBody>
          </ModalContainer>
        </Modal>
      </MainLayout>
    )
  } else if (step.step === 2) {
    return (
      <>
        <EditOneAction
          action={step}
          handleStep={() =>
            setStep({
              step: 1,
              data: {
                id: 0,
                description: '',
              },
            })
          }
        />
      </>
    )
  }
  return <div></div>
}
export default EditActions
