import styled from "styled-components";


export const Container = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const HeadContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: center;
    padding: 0px 6px;
`;

interface IRowProps {
    width?: string;
    justify?: string;
    height?: string;
    margin?: string;
}

export const Row = styled.div<IRowProps>`
    display: flex;
    flex-direction: row;
    width:  ${props => props.width ? props.width : '100%'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    height: ${props => props.height ? props.height : 'auto'};
    align-items: center;
    margin: ${props => props.margin ? props.margin : '0px'};
`;


export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #1F2124;
`;

// GRID

export const GridContainer = styled.div`
    width: 100%; 
`;

export const TitleGrid = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #1F2124;
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    margin: 10px 0px 16px 0px;

`;

// grid 2 columns the first one is 8 and the second one is 2

export const GridCompetences = styled.div`
    display: grid;
    grid-template-columns: 8fr 2fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 16px;
`;

// grid 2 columns the first one is 4fr and the second one is 8fr

export const GridMinimunRecord = styled.div`
    display: grid;
    grid-template-columns: 4fr 8fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 20px;
`;

// grid 2 columns the first one is 6fr and the second one is 6fr

export const GridModal = styled.div`
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 20px;
`;


interface IGridItemProps {
    width?: string;
    justify?: string;
    alignItems?: 'center' | 'flex-start' | 'flex-end',
    flexDirection?: 'row' | 'column'
}

export const GridItem = styled.div<IGridItemProps>`
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    width: ${props => props.width ? props.width : '100%'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
`;

// MODAL

export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
`;







