import styled from 'styled-components'


export const Container = styled.div`
  padding-bottom: 100px;
  padding-top: 20px;
`
export const Content = styled.div`
  width: calc(80vw - 350px);
  left: 250px;
  height: 100vh;
  overflow-y: visible;
`
export const CompanyCard = styled.div`
  background: #ffffff;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 47px 70px 70px 47px;
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
`
export const FormContainerItem = styled.div`
  display: flex; 
  flex-direction: row;
  gap: 50px;
  align-items: center;
  text-align: start;
  justify-content: flex-end;
  margin-bottom: 16px;
`
export const IconSvgProfile = styled.svg`
  color: #2d749c;
  fill: #2d749c;
  width: 24px;
  height: 24px;
`
export const LabelDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 0px;
`

export const Label = styled.label`
  font-size: 14px;
  color: #1f2124;
  font-weight: 600;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`
export const BluesiteTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`
export const TableDivider = styled.hr`
  border: 1px solid #1f2124;
  width: 100%;
`
export const TableHeaders = styled.thead`
  margin-bottom: 30px;
  border-bottom: 1px solid #1f2124;
  padding: 0.3em;
  justify-content: space-between;
`
export const ActionsCell = styled.th`
  justify-content: flex-end;
  width: 25%;
`
export const TableBody = styled.tbody`
  border-top: 1px solid;
  padding-top: 30px;
  justify-content: space-between;
`
export const TableSelect = styled.select`
  width: 63px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: none;
  margin: 10px;
  padding: 10px;
`
export const OptionSelect = styled.option`
  padding: 10px;
`
export const UpdateLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e44646;
  font-weight: bold;
  cursor: pointer;
`

export const ActionIcon = styled.svg`
  fill: #e44646;
  clip-rule: evenodd;
  fill-rule: evenodd;
  stroke-miterlimit: 2;
  width: 24px;
  height: 24px;
  margin-right: 5px;
`

export const TextError = styled.p`
  color: #e44646;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 40px;
  margin-top: 40px;
`
export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  width: 85%;
  height: 92px;
  padding: 12px;
  cursor: pointer;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
`
