import styled from 'styled-components'

//chargest
interface IGridItemProps {
  flexDirection?: string;
  margin?: string;
  height?: string;
}
interface IDividerProps {
  margin?: string;
}
interface IColorProps {
  color?: string;
}

interface IvisibleProps {
  disable?: boolean;
}
export const ErrorContainer = styled.div`
margin-top: 0px;
margin-bottom: 12px;
color: red;
`;

export const TextError = styled.span`
    color: red;
    text-align: left!important;
`;

export const ContainerItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 5px;
`

export const Divider = styled.div<IDividerProps>`
  width: 100%;
  height: 1px;
  background-color: #1F2124;
  margin: ${props => props.margin ? props.margin : '0px'};
`;
export const GridMinimumRecord = styled.div`
    display: grid;
    grid-template-columns: 6fr 6fr;
    width: 100%;
    padding: 0% 0%;
`;


export const MinimumRecordContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`

export const GridItem = styled.div<IGridItemProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
  width: 100%;
  height: ${props => props.height ? props.height : '60px'};
  padding: 0% 0%;
  margin: ${props => props.margin ? props.margin : '0px'};
`;
export const GridContainer = styled.div`
    width: 100%;
    padding: 0% 0%;
    margin-top: 20px;
`;
export const GridCompetence = styled.div`
    display: grid;
    //grid-template-columns: 9fr 3fr;
    width: 100%;
    padding: 0% 0%;
`;
export const TitleGrid = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #1F2124;
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    margin: 10px 0px 1px 0px;

`;

export const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap:10px;
   padding-bottom: 40px;
`
export const LanguageRow = styled.div`
   display: flex;
   flex-direction: row;
   gap:10px;
`
export const LanguageRowItem = styled.div`
   display: flex;
   flex-direction: row;
   gap:5px;
`
export const SelectDiv = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
   gap: 30px;
`
export const Paragraph = styled.p`
   color: #1F2124; 
   font-size:18px;
  @media (max-width: 574px)  {
    font-size: 2.5vw;
  }
`
export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 40px;
` 

// ACTIONS  

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-end;
  padding-left: 40%;
  /* margin-bottom: 20px; */
`

export const TextViewMore = styled.p`
// public sans font
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  color: #2D749C;
  cursor: pointer;
  :active{
    scale:.9
  }
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2D749C;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`

export const SaveButton = styled.button<IColorProps>`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: ${props => props.color ? props.color : '#C6016B'};
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
    background: #C6016B;
  }`

export const CancelarButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  border-radius: 8px;
  background: none;
  border: none;
  color: #2D749C;
  font-size:14; 
  font-weight: 700;
  cursor: 'pointer';
  font-weight: bold;
  :active{
    scale:.0.9;
  }`


export const ContainerInputs = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    `


export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns : repeat(3, 1fr);;
  gap: 16px;
  @media (max-width: 980px)  {
    grid-template-columns : repeat(2, 1fr);;
  }
  @media (max-width: 680px)  {
    grid-template-columns : repeat(1, 1fr);;
  }
`
export const Card = styled.div<IvisibleProps>`
  display: ${props => props.disable ? 'none' : 'flex'};
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  flex-direction: row; 
  width: 85%;
  height: 92px;
  padding: 12px;
  cursor: pointer;
  
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const Icon = styled.img`
  max-width: 60px;
`

export const UploadSizeRecomended = styled.p`
  width:174px;
  color: #797A7C;
  font-weight: 100;
  font-size: 16px;
`
