import styled from 'styled-components'

export const GridDiv = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`

export const Row = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
`

export const CancelModalButton = styled.p`
  color: #2D749C;
  font-weight: 700;
  cursor: pointer;
`

export const NegativeCircleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: '20px 0px';
`;