import axios from 'axios'


/**
 * @description service to get betesa uninterpreted
 * @param token
 */

type IndexInfo = {
    numberOfTests: number,
    numberOfCompanies: number,
    numberOfCompanyUsers: number,
    numberOfResponsibleCompanies: number,
    numberOfBetesas: number,
    numberOfUninterpretedBetesas: number,
    numberOfCompetencies: number,
}


interface IFindIndexData  {
    status: string | 'success' | 'error'
    data: {
        indexInfo: IndexInfo
    }
}

/**
 * @description service to get betesa uninterpreted
 * @param token
 * @returns results of the request without interpretation
 */
const getAllIndexSuperAdmin = async (userId: number ) => {

    const apiUrl = `${process.env.REACT_APP_MAIN_API}/dashboard/${userId}`

    const response: IFindIndexData = await axios.get(apiUrl).then((res) => {
      return res.data
    }).catch((error) => {
      return { ok: false, message: error.message };
    })
    return response
}

export default getAllIndexSuperAdmin
