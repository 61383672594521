import React, { FC } from 'react'
import Modal from 'views/components/UI/Modal'
import { BottomButtonsContainer, CloseWrapper, ConfirmationMessageText, ModalNewMapContainer } from './styled'
import CloseIcon from 'assets/icons/CloseIcon'
import BackButton from 'views/components/UI/BackButton'
import { StandardButton } from 'views/components/Graphics/Ninebox/styled'

interface SaveModalConfirmationProps {
  show: boolean
  setShow: (show: boolean) => void
  onSave: () => void
  isLoading?: boolean
}

export const SaveModalConfirmation: FC<SaveModalConfirmationProps> = ({ show, setShow, onSave, isLoading }) => {
  const handleSave = () => {
    onSave()
  }

  const handleClose = () => {
    setShow(false)
  }
  return (
    <Modal styleContent={{ width: '530px', height: '240px' }} show={show} setShow={setShow} hideCloseButton>
      <ModalNewMapContainer>
        <CloseWrapper>
          <CloseIcon onClick={handleClose} color="#000" />
        </CloseWrapper>
        <ConfirmationMessageText>
          Si estás de acuerdo con estos cambios, se descargará un resumen de los resultados de tus usuarios con la
          configuración anterior.
        </ConfirmationMessageText>
        <ConfirmationMessageText>¿Deseas aceptar los cambios?</ConfirmationMessageText>
        <BottomButtonsContainer>
          <BackButton
            style={{ width: 'auto', padding: '24px 0px' }}
            text="Cancelar"
            type="button"
            onClick={() => setShow(false)}
          />
          <StandardButton style={{ background: isLoading ? 'rgb(194, 194, 194)' : '#c6016b' }} onClick={handleSave}>
            {isLoading ? 'Cargando...' : 'Aceptar'}
          </StandardButton>
        </BottomButtonsContainer>
      </ModalNewMapContainer>
    </Modal>
  )
}

export default SaveModalConfirmation
