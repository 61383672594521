import { useNotification } from 'lib/context/notification.context'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProcessResume, updateAdmins } from 'services/common/process/createProcess/create-process-service'
import {
  setIdealProfileBetesa,
  setIdealProfileSkills,
  setProcessDetail,
  setAllPlatformAdmins,
  setTotalPages,
  UpdateProcessDetailsBody,
  adminIdsToSaveSelector,
} from '../store'
import { fetchIdealProfileTest, fetchUpdateProcessDetails } from '../services/fetchProcess'
import { fetchGePlatformAdmins } from 'views/screens/super-admin/admin-platform-company/services/fetch-admin-platform'
import { USER_ROLE_ID } from 'constants/global.constants'
import { userSelector } from 'ducks/user'
import { User } from 'views/screens/super-admin/admin-platform-company/store'

const useProcess = () => {
  const dispatch = useDispatch()
  const { getSuccess, getError } = useNotification()
  const { companyId } = useSelector(userSelector)
  const adminIdsToSave = useSelector(adminIdsToSaveSelector)

  const saveAdminIds = useCallback(
    async (processId: number) => {
      try {
        await updateAdmins(processId, adminIdsToSave)
        getSuccess('Se han guardado los cambios')
      } catch (error) {
        getError('Ha ocurrido un error al guardar los cambios')
      }
    },
    [adminIdsToSave, getError, getSuccess]
  )

  const getProcessById = useCallback(
    async (id: number) => {
      try {
        const response = await getProcessResume(id, true)
        dispatch(setProcessDetail(response.data))

        if ((response.data.hasBetesaTest || response.data.hasCompetencesTest) && response.data.testids.length > 0) {
          response.data.testids.map(async (test: any) => {
            const idealProfile = await fetchIdealProfileTest(test.test_id, test.category_id)
            if (idealProfile && test.category_id === 1) {
              dispatch(setIdealProfileBetesa(idealProfile))
            } else if (idealProfile && test.category_id === 2) {
              dispatch(setIdealProfileSkills(idealProfile))
            }
          })
        }
      } catch {
        getError('Ha ocurrido un error al cargar este proceso')
      }
    },
    [dispatch, getError]
  )

  const getAllPlatformAdmins = useCallback(
    async (page: number, processId: number) => {
      try {
        const platformUsersBody = {
          role: [USER_ROLE_ID.platform_admin],
          companyId,
          statusInfo: true,
          process_id: processId,
        }

        const limit = 30

        const platformUsers = await fetchGePlatformAdmins(platformUsersBody, page, true, true, limit)
        const usersFullName = platformUsers.users?.map((user: User) => {
          return {
            ...user,
            id: user.userId,
            idStatus: user.status_id,
            name: `${user.first_name} ${user.last_name}`,
            process_id: user.userId,
          }
        })

        if (platformUsers.total_pages === 0) {
          dispatch(setTotalPages(1))
        } else {
          dispatch(setTotalPages(platformUsers.total_pages))
          dispatch(setAllPlatformAdmins(usersFullName))
        }
      } catch {
        getError('Ha ocurrido un error al cargar los administradores')
      }
    },
    [dispatch, getError, companyId]
  )

  const updateProcessDetails = useCallback(
    async (processId: number, updateProcessBody: UpdateProcessDetailsBody) => {
      try {
        await fetchUpdateProcessDetails(processId, updateProcessBody)
        getSuccess('Se ha actualizado la información del proceso')
      } catch {
        getError('Ha ocurrido un error al cargar los administradores')
      }
    },
    [getError, getSuccess]
  )

  return {
    getProcessById,
    getAllPlatformAdmins,
    updateProcessDetails,
    saveAdminIds,
  }
}

export default useProcess
