import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSagasTrigger } from 'lib/redux/sagas/trigger'
import jwt_decode from 'jwt-decode'

export const initialState = {
  companyId: 0,
  userId: '',
  token: '',
  email: '',
  userName: '',
  loading: false,
  error: false,
}

export type InitialState = typeof initialState
type SetUser = PayloadAction<{ token: string; email: string; companyId: number; userName: string }>

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: SetUser) {
      state.token = action.payload.token
      state.email = action.payload.email
      state.companyId = action.payload.companyId
      state.userName = action.payload.userName

      let userData: any = {}

      if (action.payload.token) {
        try {
          userData = jwt_decode(action.payload.token)
        } catch (error) {
          console.error('Error decodificando el token:', error)
          userData = {}
        }
      }
      state.userId = userData.id || ''
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    logout(state) {
      state.token = ''
      state.email = ''
      state.userId = ''
      state.companyId = 0
      state.userName = ''
      state.error = false
      state = initialState
    },
  },
})

export const { setUser, setLoading, setError, logout } = userSlice.actions

export const userLoginSagas = createSagasTrigger<{ email: string; password: string }>('user_login_sagas')

export const userReducer = userSlice.reducer
