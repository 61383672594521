import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    @media (max-width: 768px) {
        transform: scale(0.8);
    }

    @media (max-width: 560px) {
        transform: scale(0.7);
        margin-top: -60px;
    }

    @media (max-width: 480px) {
        transform: scale(0.6);
        margin-top: -80px;
    }

    @media (max-width: 420px) {
        transform: scale(0.55);
        margin-top: -100px;
        margin-left: 16px;
    }

    @media (max-width: 380px) {
        transform: scale(0.5);
        margin-top: -140px;
        margin-left: 10px;
    }
`;

export const ScaleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
`;

export const Scale = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
`;

interface IScaleItem {
    color: string;
}

export const ItemScale = styled.div<IScaleItem>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    border: ${(props: IScaleItem) => `1.5px solid ${props.color}`};
    color: ${(props: IScaleItem) => props.color};
    height: 26px;
    overflow: hidden;
`;

interface IItemScaleText {
    color: string;
}

export const ItemScaleText = styled.p<IItemScaleText>`
    // inter family
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    margin: 0;
    color: ${(props: IItemScaleText) => props.color};
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;


export const LegendContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0rem;
`;

export const ItemLegend = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
`;


export const TextLegend = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    margin: 0;
    margin-left: 10px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1F2124;
`;