import styled from "styled-components";


interface IProps {
    width?: string;
    height?: string;
    maxHeigth?: string;
    padding?: string;
    margin?: string;
    justifyContent?: string;
    alignItems?: string;
    float?: string
    alignSelf?: string;
    maxWidth?: string;
    backgroundColor?: string;
    shadow?: string;
    flexDirection?: string;
    overflow?: string;
}


const WhiteContainer = styled.div<IProps>`
    overflow: ${props => props.overflow ? props.overflow : 'hidden'};
    display: flex;
    align-self: ${props => props.alignSelf ? props.alignSelf : 'center'};
    width: ${props => props.width ? props.width : '100%'};
    max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
    height: ${props => props.height ? props.height : '100%'};
    max-height: ${props => props.maxHeigth ? props.maxHeigth : '100%'};
    margin: ${props => props.margin ? props.margin : '0'};
    padding: ${props => props.padding ? props.padding : '10px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
    float: ${props => props.float ? props.float : 'none'};
   
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
    box-shadow: ${props => props.shadow ? props.shadow : '0px 2px 10px rgba(0, 0, 0, 0.1)'};
    border-radius: 10px;
`;




export default WhiteContainer;
