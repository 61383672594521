/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  UsersInputsContainer,
  UsersModalText,
  UsersModalTitle,
  ResetButton,
  UsersGridContainer,
  StandardButton,
  ButtonContainer,
  ButtonDisabled,
  SearchButton,
  MagnifyingGlass,
} from './styled'
import Modal from 'views/components/UI/Modal'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { User, User9Box } from 'views/screens/Platform-administrator/Analytics/constants/types'

import Select from 'views/components/UI/Select'

import { TextField } from '@mui/material'
import setUsersPerformance from 'services/competence/setUsersPerformance'
import { useNotification } from 'lib/context/notification.context'

interface IProps {
  show: boolean
  close: (value: boolean) => void
  setEvaluatedUsers: (value: number[]) => void
  setNineBoxCareer: (value: boolean) => void
  usersSelected: User[]
  processId: string
  cleanSession: () => void
  historyUsersPerformance: User9Box[]
  sessionGrades: Record<number, number>
}

const headers = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'full_name',
    name: 'Nombre',
  },
  {
    key: 'button',
    name: '',
  },
]

const grades = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
]

const EvaluateUsersModal: FC<IProps> = ({
  show,
  close,
  usersSelected,
  setEvaluatedUsers,
  setNineBoxCareer,
  processId,
  cleanSession,
  historyUsersPerformance,
}) => {
  const { getError } = useNotification()
  const [page, setPage] = useState(1)
  const [inputs, setInputs] = useState({
    document: '',
    email: '',
    full_name: '',
  })
  const [searchCriteria, setSearchCriteria] = useState({
    document: '',
    email: '',
    full_name: '',
  })

  const [userGrades, setUserGrades] = useState<Record<number, number>>({})
  const usersAddedPerPage = 5

  useEffect(() => {
    if (historyUsersPerformance.length > 0) {
      const newGrades = historyUsersPerformance.reduce((acc, userPerformance) => {
        acc[userPerformance.user_id] = userPerformance.performance / 10
        return acc
      }, {} as Record<number, number>)
      setUserGrades(newGrades)
    }
  }, [historyUsersPerformance])

  useEffect(() => {
    const newGrades = { ...userGrades }
    for (const userId in newGrades) {
      if (!usersSelected.some((user) => user.user_id.toString() === userId)) {
        delete newGrades[userId]
      }
    }
    setUserGrades(newGrades)
  }, [usersSelected])

  const EvaluateButton = ({ user }: { user: User }) => {
    const handleGradeChange = (value: string | number) => {
      const grade = typeof value === 'string' ? parseInt(value) : value
      setUserGrades({ ...userGrades, [user.user_id]: grade })
    }

    const selectedGrade = userGrades[user.user_id] || ''
    return (
      <div style={{ width: '180px' }}>
        <Select
          options={grades}
          value={selectedGrade}
          onChange={handleGradeChange}
          label="Desempeño"
          hideNeverChoice={true}
        />
      </div>
    )
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSearchUsers = () => {
    if (!inputs.document.trim() && !inputs.email.trim() && !inputs.full_name.trim()) return

    setPage(1)

    setSearchCriteria({
      document: inputs.document,
      email: inputs.email,
      full_name: inputs.full_name,
    })
  }

  const handleResetSearch = () => {
    setInputs({
      document: '',
      email: '',
      full_name: '',
    })
    setSearchCriteria({
      document: '',
      email: '',
      full_name: '',
    })
  }

  const handleSaveUsers = (data: any) => {
    setEvaluatedUsers(data.map((user: any) => user.user_id))
    close(false)
    setNineBoxCareer(true)
  }

  const filteredUsers = usersSelected.filter((user) => {
    return Object.entries(searchCriteria).every(([key, value]) => {
      if (!value) return true
      return (
        user[key as keyof User] &&
        String(user[key as keyof User])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    })
  })

  const indexOfLastUser = page * usersAddedPerPage
  const indexOfFirstUser = indexOfLastUser - usersAddedPerPage
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser)

  const dataUsersToEvaluate = currentUsers.map((user) => ({
    ...user,
    button: <EvaluateButton user={user} />,
  }))

  const handleSavePerformance = () => {
    cleanSession()
    const users = usersSelected.map((user) => {
      return {
        user_id: user.user_id,
        score: userGrades[user.user_id],
      }
    })
    const body = {
      process_id: Number(processId),
      users: users,
    }
    handleSaveUsers(users)

    setUsersPerformance(body).then(
      () => {},
      (error) => {
        console.error('Error al guardar la performance: ', error)
        getError('Ha ocurrido un error guardar la performance de los usuarios')
      }
    )
  }

  const allUsersHaveGrades = Object.keys(userGrades).length === usersSelected.length

  return (
    <Modal styleContent={{ minWidth: '80%', minHeight: '90%' }} show={show} setShow={close}>
      <UsersModalTitle>Usuarios</UsersModalTitle>
      <UsersInputsContainer>
        <UsersModalText style={{ marginRight: '30px' }}>Buscar por:</UsersModalText>
        <TextField
          type="number"
          value={inputs.document}
          name="document"
          onChange={handleInputChange}
          label="Número de documento"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchUsers()
            }
          }}
        />
        <TextField
          type="text"
          value={inputs.email}
          name="email"
          onChange={handleInputChange}
          label="Email"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchUsers()
            }
          }}
        />
        <TextField
          type="text"
          value={inputs.full_name}
          name="full_name"
          onChange={handleInputChange}
          label="Nombre"
          sx={{
            boxShadow: '0px 3px 6px #00000029',
            width: '220px',
            marginRight: '40px',
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchUsers()
            }
          }}
        />
        <SearchButton onClick={handleSearchUsers} style={{ fontSize: 14 }}>
          <MagnifyingGlass src={IconMagnifying} />
        </SearchButton>

        {inputs.document || inputs.email || inputs.full_name ? (
          <ResetButton onClick={handleResetSearch}>
            <HighlightOffIcon />
          </ResetButton>
        ) : null}
      </UsersInputsContainer>
      <UsersGridContainer>
        <BluesiteTableComponent
          data={dataUsersToEvaluate}
          totalPages={Math.ceil(filteredUsers.length / usersAddedPerPage)}
          headers={headers}
          searchBar={false}
          handlePagination={setPage}
          currentPage={page}
          searchLabel=""
        />
      </UsersGridContainer>
      <ButtonContainer>
        {allUsersHaveGrades ? (
          <StandardButton onClick={handleSavePerformance}>Guardar</StandardButton>
        ) : (
          <ButtonDisabled>Guardar</ButtonDisabled>
        )}
      </ButtonContainer>
    </Modal>
  )
}

export default EvaluateUsersModal
