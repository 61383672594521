import axios from 'lib/axios'
import { SEARCH_TABS } from '../constants'
import { Response } from '../store'

export const fetchSearchUser = async (data: any): Promise<Response> => {
  try {
    const response = await axios.post(`/${SEARCH_TABS.create}`, data)
    return response.data
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
