export const HEADERS = [
    {
      key: 'skill',
      name: 'Nombre',
    },
];

export interface CompetencesIdealProfile {
    skill_id: number;
    skill_name: string;
    weight: number;
}