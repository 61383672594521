import Niñez from 'views/components/assets/child.png'
import TiempoLibre from 'views/components/assets/chapter-2.png'
import VidaLaboral from 'views/components/assets/vidaLaboral.png'
import Autopercepcion from 'views/components/assets/Autopercepcion.png'
import EntornosDeProductividad from 'views/components/assets/EntornosDeProductividad.png'
import EstadoEmocional from 'views/components/assets/EstadoEmocional.png'
import Estresores from 'views/components/assets/Estresores.png'

export const data = [
  {
    chapterImg: Niñez,
    chapterName: 'Niñez y juventud',
    chapterDescription:
      'En esta sección queremos entender mejor cómo recuerdas tu época de niñez y juventud. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
    instructions:
      'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
  },
  {
    chapterImg: TiempoLibre,
    chapterName: 'Tiempo libre',
    chapterDescription:
      'En esta sección queremos conocerte cuando no estás trabajando. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
    instructions:
      'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
  },
  {
    chapterImg: VidaLaboral,
    chapterName: 'Vida laboral',
    chapterDescription:
      'En esta sección queremos conocerte cuando estás trabajando. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible',
    instructions:
      'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
  },
  {
    chapterImg: Autopercepcion,
    chapterName: 'Autopercepción',
    chapterDescription:
      'En esta sección queremos entender mejor cómo te percibes a ti mismo. Puede haber preguntas en las que no te sea fácil priorizar las opciones. No te preocupes, contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
    instructions:
      'En esta sección encontrarás una serie de enunciados, cada uno con 4 elementos asociados. Deberás ordenarlos arrastrando de derecha a izquierda la opción que más se parece a ti hasta la que menos se parece.',
  },
  {
    chapterImg: EntornosDeProductividad,
    chapterName: 'Entornos de productividad',
    chapterDescription:
      'En esta sección queremos conocer aquellos escenarios donde eres más productivo porque alcanzas mejor foco para desarrollar tu trabajo. Contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
    instructions:
      'En esta sección encontrarás una afirmación. Debes elegir la respuesta que más se acerque a tu forma de pensar',
  },
  {
    chapterImg: EstadoEmocional,
    chapterName: 'Estado emocional',
    chapterDescription:
      'En esta sección queremos conocer cómo te has sentido el último año. Contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
    instructions:
      'En esta sección encontrarás una afirmación. Debes elegir la respuesta que más se acerque a tu forma de pensar',
  },
  {
    chapterImg: Estresores,
    chapterName: 'Estresores',
    chapterDescription:
      'En esta sección queremos entender tu vivencia de aquellas situaciones que pueden ser estresantes para ti, positivas o negativas. Contesta como mejor puedas hacerlo y de la forma más auténtica posible.',
    instructions: 'Indica cuál de los siguientes eventos te ha sucedido en el último año (elije una o varias opciones)',
  },
]

export const questionsOptions = [
  {
    id: 1,
    name: 'Lo más parecido a mí',
    weight: 4,
    active: false,
  },
  {
    id: 2,
    name: 'Parecido a mí',
    weight: 3,
    active: false,
  },
  {
    id: 3,
    name: 'Poco parecido a mí',
    weight: 2,
    active: false,
  },
  {
    id: 4,
    name: 'Nada parecido a mí',
    weight: 0,
    active: false,
  },
]

export const quadrants = {
  FI: 0,
  FD: 0,
  BI: 0,
  BD: 0,
}
export const colorMap = {
  cardOne: '#DBF0CE',
  cardTwo: '#D1E6F1',
  cardThree: '#FFEEDA',
  cardFour: '#EEDBFE',
} 

export function getColor(index:number) {
  switch(index) {
      case 1:
          return '#DBF0CE';
      case 2:
          return '#D1E6F1';
      case 3:
          return '#FFEEDA';
      case 4:
          return '#EEDBFE';
      default:
          return '';
  }
}