import { QuadrantData } from 'views/containers/BrainQuadrant/constants'

export const SETTINGS_HEADERS = [
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'first_name',
    name: 'Nombre',
  },
  {
    key: 'last_name',
    name: 'Apellido',
  },
]

export const CARTESIAN_DATA: QuadrantData[] = [
  {
    quadrant: 'FI',
    priority: 1,
    min: 1,
    max: 1,
  },
  {
    quadrant: 'FD',
    priority: 2,
    min: 2,
    max: 2,
  },
  {
    quadrant: 'BI',
    priority: 3,
    min: 3,
    max: 3,
  },
  {
    quadrant: 'BD',
    priority: 4,
    min: 4,
    max: 4,
  },
]

export const MOCK_PLATFORM_ADMINS = [
  {
    id: 1,
    userId: 1,
    document: '123456789',
    email: 'juan.perez@gmail.com',
    first_name: 'Juan',
    last_name: 'Perez',
    status_id: 1,
  },
  {
    id: 2,
    userId: 2,
    document: '123456789',
    email: 'juan.perez@gmail.com',
    first_name: 'Pedro',
    last_name: 'Perez',
    status_id: 2,
  },
  {
    id: 3,
    userId: 3,
    document: '123456789',
    email: 'juan.perez@gmail.com',
    first_name: 'Carlos',
    last_name: 'Perez',
    status_id: 3,
  },
];