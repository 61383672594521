import axios from 'axios'

interface IOption {
    id: number,
    text: string,
    weight: number,
    quadrant: string,
    question_id: number,
}

interface IQuestion {
    id: number,
    text: string,
    active: boolean,
    is_betesa?: boolean,
    reverse_order: boolean,
    options?: IOption[]
    levelId: number,
    groupId: number,
    dimensionId: number,
    skillId: number,
    skill: string,
}


type IResponse = {
    status: string,
    question: IQuestion
}

/** 
 * @description service to get a skill question by id
 */
const getQuestionSkillsById = async (idQuestion: number | string) => {


    const apiUrl = `${process.env.REACT_APP_MAIN_API}/question/${idQuestion}`


    const response: IResponse = await axios.get(apiUrl).then((res) => {
        return res.data
    }).catch((error) => {
        return { ok: false, message: error.message };
    })

    return response
  
}

export default getQuestionSkillsById
