export const STEPS_NEW_PROCESS = ['Información básica', 'Familia de rol', 'Selección de pruebas', 'Perfil ideal', 'Usuarios'];

export const BRAIN_QUADRANTS_DOUBLE = [
    {
        value: 'FD',
        label: 'FD'
    },
    {
        value: 'FI',
        label: 'FI'
    },
    {
        value: 'BD',
        label: 'BD'
    },
    {
        value: 'BI',
        label: 'BI'
    },
    {
        value: 'DI',
        label: 'DI'
    },
    {
        value: 'DB',
        label: 'DB'
    },
    {
        value: 'DF',
        label: 'DF'
    },
    {
        value: 'DD',
        label: 'DD'
    }
]

export const BETESA_DEFAULT_CATEGORY_ID = 1;