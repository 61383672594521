import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import HorizontalSelector from 'views/components/Selectors/HorizontalSelector'
import BrainMap from 'views/components/Graphics/BrainMap'
import TeamMapGrid from '../Grids/TeamMapGrid'
import { Container, SelectorContainer } from './styled'
import getBetesaResults from 'services/competence/getBetesaResults'
import { useNotification } from 'lib/context/notification.context'
import iconInfo from '../../../views/components/assets/iconInfo.png'
import {
  NoDataContainer,
  NoDataIcon,
  NoDataSubContainer,
  NoDataText,
  TextContainer,
} from 'views/screens/Platform-administrator/Analytics/styled'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { BetesaResultsResponse } from './store/entities'
import getQuadrantResults from 'services/competence/getQuadrantResults'
import getTalentsResults from 'services/competence/getTalentsResults'
import getPlanResults from 'services/competence/getPlanResults'
import getBrainResults from 'services/competence/getBrainResults'
import BrainGraphicContainer from './BrainGraphicContainer'

const optionsData = [
  {
    name: 'Resumen',
    value: '1',
  },
  {
    name: 'Dominancia',
    value: '2',
  },
  {
    name: 'Talentos',
    value: '3',
  },
  {
    name: 'Gráfico',
    value: '4',
  },
  {
    name: 'Plan',
    value: '5',
  },
]

export interface TeamMapProps {
  betesaId?: string
  usersByQuadrant?: QuadrantResults
  talents?: boolean
}

export interface QuadrantResultsResponse {
  id: number
  full_name: string
  name: string
  user_id: number
  domination: string
  alert_level: string
  quadrant_develop: string
  FI: string
  BI: string
  BD: string
  FD: string
}

export interface QuadrantResults {
  fi: QuadrantResultsResponse[]
  bi: QuadrantResultsResponse[]
  bd: QuadrantResultsResponse[]
  fd: QuadrantResultsResponse[]
}

export interface BrainResultsResponse {
  FI: number
  BI: number
  BD: number
  FD: number
}

const TeamMap: FC<TeamMapProps> = ({ betesaId }) => {
  const { getError } = useNotification()

  const [selectedOption, setSelectedOption] = useState<string>('1')
  const [betesaResults, setBetesaResults] = useState<BetesaResultsResponse>({} as BetesaResultsResponse)
  const [page, setPage] = useState<number>(1)
  const [usersQuadrants, setUsersQuadrants] = useState<QuadrantResults>({} as QuadrantResults)
  const [talentQuadrants, setTalentQuadrants] = useState<QuadrantResults>({} as QuadrantResults)
  const [planQuadrants, setPlanQuadrants] = useState<QuadrantResults>({} as QuadrantResults)
  const [brainResponse, setBrainResponse] = useState<BrainResultsResponse>({} as BrainResultsResponse)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (betesaId === '') {
      setLoading(false)
      return
    }
    getBetesaResults(betesaId, page).then(
      (response) => {
        if (response?.data) {
          setBetesaResults(response.data)
        }
        setLoading(false)
      },
      () => {
        getError('Ha ocurrido un error al cargar las pruebas betesa')
        setLoading(false)
      }
    )
  }, [betesaId, getError, page])

  useLayoutEffect(() => {
    setLoading(true)
  }, [selectedOption])

  useEffect(() => {
    if (betesaId === '') {
      return
    }

    const fetchQuadrantResults = async () => {
      try {
        const fi = await getQuadrantResults(betesaId, 'FI')
        if (fi?.data) {
          setUsersQuadrants((prevState) => ({ ...prevState, fi: fi.data }))
        }
        const bi = await getQuadrantResults(betesaId, 'BI')
        if (bi?.data) {
          setUsersQuadrants((prevState) => ({ ...prevState, bi: bi.data }))
        }
        const bd = await getQuadrantResults(betesaId, 'BD')

        if (bd?.data) {
          setUsersQuadrants((prevState) => ({ ...prevState, bd: bd.data }))
        }
        const fd = await getQuadrantResults(betesaId, 'FD')
        if (fd?.data) {
          setUsersQuadrants((prevState) => ({ ...prevState, fd: fd.data }))
        }
      } catch (error) {
        getError('Ha ocurrido un error al cargar los resultados de los cuadrantes')
      }
    }

    fetchQuadrantResults()
  }, [betesaId, getError])

  useEffect(() => {
    if (betesaId === '') {
      return
    }

    const fetchTalentResults = async () => {
      try {
        const fi = await getTalentsResults(betesaId, 'FI')
        if (fi?.data) {
          setTalentQuadrants((prevState) => ({ ...prevState, fi: fi.data }))
        }
        const bi = await getTalentsResults(betesaId, 'BI')
        if (bi?.data) {
          setTalentQuadrants((prevState) => ({ ...prevState, bi: bi.data }))
        }
        const bd = await getTalentsResults(betesaId, 'BD')
        if (bd?.data) {
          setTalentQuadrants((prevState) => ({ ...prevState, bd: bd.data }))
        }
        const fd = await getTalentsResults(betesaId, 'FD')
        if (fd?.data) {
          setTalentQuadrants((prevState) => ({ ...prevState, fd: fd.data }))
        }
      } catch (error) {
        getError('Ha ocurrido un error al cargar los resultados de los cuadrantes')
      }
    }

    fetchTalentResults()
  }, [betesaId, getError])

  useEffect(() => {
    if (betesaId === '') {
      return
    }

    const fetchPlanResults = async () => {
      try {
        const fi = await getPlanResults(betesaId, 'FI')
        if (fi?.data) {
          setPlanQuadrants((prevState) => ({ ...prevState, fi: fi.data }))
        }
        const bi = await getPlanResults(betesaId, 'BI')
        if (bi?.data) {
          setPlanQuadrants((prevState) => ({ ...prevState, bi: bi.data }))
        }
        const bd = await getPlanResults(betesaId, 'BD')
        if (bd?.data) {
          setPlanQuadrants((prevState) => ({ ...prevState, bd: bd.data }))
        }
        const fd = await getPlanResults(betesaId, 'FD')
        if (fd?.data) {
          setPlanQuadrants((prevState) => ({ ...prevState, fd: fd.data }))
        }
      } catch (error) {
        getError('Ha ocurrido un error al cargar los resultados de los cuadrantes')
      }
    }

    fetchPlanResults()
  }, [betesaId, getError])

  useEffect(() => {
    if (betesaId === '') {
      return
    }
    getBrainResults(betesaId).then(
      (response) => {
        if (response?.data) {
          setBrainResponse(response.data)
        }
      },
      () => {
        getError('Ha ocurrido un error al cargar los promedios de los usuarios')
      }
    )
  }, [betesaId, getError])

  return (
    <Container>
      {Object.keys(betesaResults).length > 0 ? (
        <SelectorContainer>
          <HorizontalSelector items={optionsData} selected={selectedOption} setSelected={setSelectedOption} />
          {selectedOption === '1' && <TeamMapGrid results={betesaResults} handlePagination={setPage} />}
          {selectedOption === '2' && <BrainMap usersByQuadrant={usersQuadrants} />}
          {selectedOption === '3' && <BrainMap usersByQuadrant={talentQuadrants} talents />}
          {selectedOption === '4' && (
            <div
              style={{
                marginTop: '20px',
                width: '100%',
              }}
            >
              <div>
                {Object.keys(brainResponse).length > 0 ? (
                  <BrainGraphicContainer  
                    FI={brainResponse.FI} 
                    FD={brainResponse.FD} 
                    BD={brainResponse.BD}
                    BI={brainResponse.BI} 
                    /> 
                ) : (
                  <NoDataContainer>
                    <NoDataSubContainer>
                      <NoDataIcon src={iconInfo} />
                      <TextContainer>
                        <NoDataText>Error al cargar los datos del perfil ideal</NoDataText>
                      </TextContainer>
                    </NoDataSubContainer>
                  </NoDataContainer>
                )}
              </div>
            </div>
          )}
          {selectedOption === '5' && <BrainMap usersByQuadrant={planQuadrants} />}
        </SelectorContainer>
      ) : !betesaId ? (
        <NoDataContainer>
          <NoDataSubContainer>
            <NoDataIcon src={iconInfo} />
            <TextContainer>
              <NoDataText>No hay datos de Betesa en este proceso</NoDataText>
            </TextContainer>
          </NoDataSubContainer>
        </NoDataContainer>
      ) : !loading ? (
        <NoDataContainer>
          <NoDataSubContainer>
            <NoDataIcon src={iconInfo} />
            <TextContainer>
              <NoDataText>Los usuarios no han contestado la prueba</NoDataText>
            </TextContainer>
          </NoDataSubContainer>
        </NoDataContainer>
      ) : (
        <ModalLoader isLoading={loading} />
      )}
    </Container>
  )
}

export default TeamMap
