import axios from 'axios'

/**
 * @description service to get all skills
 * @param token
 */
/* 
type ISkill = {
    id: number
    skill: string
    description: string
    language: string
    category_id: number
    group_id: number
    level_id: number
}

/* ß */
/* interface IFindUserResponse  {
    status: string | 'success' | 'error'
    skills: ISkill[]
}
 */


const getAllSkills = async ( limit:number, page:number, categoryId: number | string) => {

  const response = await axios.get(`${process.env.REACT_APP_MAIN_API}/skill?page=${page}&limit=${limit}&category_id=${categoryId}`).then((res) => {
    return res.data
  }).catch((error) => {
    return { ok: false, message: error.message };
  })

  return response
}

export default getAllSkills
