import styled from 'styled-components'

export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns : repeat(3, 1fr);;
  gap: 16px;
  @media (max-width: 980px)  {
    grid-template-columns : repeat(2, 1fr);;
  }
  @media (max-width: 680px)  {
    grid-template-columns : repeat(1, 1fr);;
  }
`
export const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row; 
  width: 256px;
  height: 92px;
  padding: 12px;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const Icon = styled.img`
  max-width: 60px;
`

export const UploadSizeRecomended = styled.p`
  width:174px;
  color: #797A7C;
  font-weight: 100;
  font-size: 16px;
`