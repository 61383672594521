import axios from 'axios'
import { DEFAULT_LIMIT } from 'constants/global.constants'
/**
 * @description service to development actions
 * @param token
 */

const fetchCompetences = async (
  page?: number | string,
  limit?: number | string,
  search?: string,
  filter: any | undefined = undefined
) => {
  const searchQuery = search ? `&search=${search}` : ''
  const apiUrl = !filter
    ? `/skill?page=${page}&limit=${limit || DEFAULT_LIMIT}${searchQuery}`
    : `/skill?page=${page}&limit=${limit || DEFAULT_LIMIT}&level_id=${filter}${searchQuery}`
  const response = await axios
    .get(apiUrl)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return response
}

export default fetchCompetences
