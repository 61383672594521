import { FC } from 'react'
import {
  Card,
  ModulesContainer,
  CardDescriptionSection
}
  from '../../pages/edit-role-family/styled'
import TitleDiv from 'views/components/UI/TitleDiv'
import { MainLayout } from 'views/layouts'

interface EditConfigOptionsProps {
  handleForm?: any,
  information?: any
}

const EditConfigCards: FC<EditConfigOptionsProps> = ({
  handleForm,
}) => {
  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Configuración de familia de rol</h2>
      </TitleDiv>
      <ModulesContainer>
        <Card onClick={() => handleForm(1)}>
          <CardDescriptionSection>
            <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Información básica</span>

          </CardDescriptionSection>
        </Card>
        <Card onClick={() => handleForm(2)}>
          <CardDescriptionSection>
            <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Habilidades asociadas</span>

          </CardDescriptionSection>
        </Card>
        
        <Card onClick={() => handleForm(3)}>
          <CardDescriptionSection>
            <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Cargos típicos</span>

          </CardDescriptionSection>
        </Card>
      </ModulesContainer>
    </MainLayout>
  )
}

export default EditConfigCards
