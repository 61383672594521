import styled from 'styled-components'

export const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
`;

export const UserTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 40%;
`;


export const GridDiv = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`
export const AddButton = styled.div`
  display:flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  cursor:pointer;
  gap:5px;
`
export const AddIcon = styled.svg`
  width:24px;
  height:24px;
  color:#2D749C;
  fill:#2D749C;
`
export const AddText = styled.p`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #2D749C;
`
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #C2C2C2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
    background: #C6016B;
  }
   
`;
export const GridModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;
    margin-top: 20px;
    align-items: center;
`;
export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
`;
export const RowStart = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: flex-start;
`;
export const RowEnd = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: flex-end;
`;