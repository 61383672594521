import { EMPTY_STRING, PLATFORM_ADMIN_ROLE_ID, USER_ROLE_ID } from 'constants/global.constants'
import { Formik, FormikProps } from 'formik'
import { useNotification } from 'lib/context/notification.context'
import { FC, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button } from 'views/components/UI/Buttons'
import Modal from 'views/components/UI/Modal'
import Input from 'views/components/UI/TextInput'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { createAdminPlatformValidationSchema } from '../../schemas/create-admin-platform.schema'
import { fetchCreatePlatformAdmin } from '../../services/fetch-admin-platform'
import { CreateAdminPlatform, CreateUser } from '../../store'
import {
  ButtonWrapper,
  ContainerInputs,
  Form,
  GridModal,
  ModalBody,
  ModalContainer,
  ModalHeader,
  Title,
} from './styled'
import InputRadio from 'views/components/UI/InputRadio'
import { Row } from 'views/components/UI/Row'
import { P } from 'views/components/UI/Text'

interface CreateUserModalProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  handleCloseClick: () => void
  onFinished: () => void
  companyId: number
}

const CreateUserModal: FC<CreateUserModalProps> = ({
  openModal,
  setOpenModal,
  handleCloseClick,
  onFinished,
  companyId,
}) => {
  const { getError, getSuccess } = useNotification()
  const [isLoading, setIsLoading] = useState(false)

  const isDisabled = (allErrors: any) => {
    return Object.keys(allErrors).length > 0 || isLoading
  }

  const initialValues = {
    name: EMPTY_STRING,
    lastName: EMPTY_STRING,
    email: EMPTY_STRING,
    id_status: PLATFORM_ADMIN_ROLE_ID,
    isSuperPlatformAdmin: false,
  }

  const formikRef = useRef<FormikProps<CreateAdminPlatform>>(null)

  const handleOnSubmit = async (values: CreateAdminPlatform, onReset: any) => {
    setIsLoading(true)

    const body: CreateUser = {
      first_name: values.name,
      last_name: values.lastName,
      email: values.email,
      document_type: 1,
      document: uuidv4(),
      role_type: values.isSuperPlatformAdmin ? USER_ROLE_ID.super_platform_admin : USER_ROLE_ID.platform_admin,
      company_id: companyId,
      id_status: initialValues.id_status,
    }

    try {
      const response = await fetchCreatePlatformAdmin(body)
      if (response.ok === false || response.status === 'error') {
        getError(response.message ?? 'No se pudo crear el administrador')
      } else if (response.status === 'OK') {
        onFinished()
        onReset()
        getSuccess('Administrador creado correctamente')
        setOpenModal(true)
      }
    } catch {
      setIsLoading(false)
      getError('No se pudo crear el administrador')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={!openModal}
      setShow={setOpenModal}
      hideCloseButton={true}
      styleContent={{
        width: '30%',
        height: 'auto',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              formikRef.current?.resetForm()
              handleCloseClick()
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <Title>Agregar administrador</Title>
          <GridModal>
            <p style={{ width: '100%', marginTop: 0 }}>Ingresa el correo electrónico del administrador a agregar</p>
            <Formik
              initialValues={initialValues}
              validationSchema={createAdminPlatformValidationSchema}
              validateOnChange={true}
              innerRef={formikRef}
              onSubmit={async (values, { resetForm }) => {
                await handleOnSubmit(values, resetForm)
              }}
            >
              {({ values, handleChange, errors, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <ContainerInputs>
                    <Input
                      name="name"
                      value={values.name}
                      label="Nombre"
                      type="text"
                      onChange={handleChange}
                      helperText={errors.name}
                    />
                    <Input
                      name="lastName"
                      value={values.lastName}
                      label="Apellido"
                      type="text"
                      onChange={handleChange}
                      helperText={errors.lastName}
                    />
                    <Input
                      value={values.email}
                      onChange={handleChange}
                      type="text"
                      name="email"
                      label="Correo"
                      helperText={errors.email}
                    />
                    <Row justifyContent="space-between" style={{ padding: '0' }}>
                      <P width="100%" style={{ paddingTop: '8px' }}>
                        ¿Es administrador líder?
                      </P>
                      <Row justifyContent="flex-end" style={{ padding: '0' }}>
                        <InputRadio
                          checked={values.isSuperPlatformAdmin === true}
                          label="Sí"
                          value="Sí"
                          onChange={() => setFieldValue('isSuperPlatformAdmin', true)}
                          name="isSuperPlatformAdmin"
                        />
                        <InputRadio
                          checked={values.isSuperPlatformAdmin === false}
                          label="No"
                          value="No"
                          onChange={() => setFieldValue('isSuperPlatformAdmin', false)}
                          name="isSuperPlatformAdmin"
                        />
                      </Row>
                    </Row>
                    <ButtonWrapper>
                      <Button type="submit" disabled={isDisabled(errors)}>
                        {isLoading ? 'Creando...' : 'Agregar'}
                      </Button>
                    </ButtonWrapper>
                  </ContainerInputs>
                </Form>
              )}
            </Formik>
          </GridModal>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}

export default CreateUserModal
