import { EMPTY_STRING } from 'constants/global.constants';
import { Formik, FormikProps } from 'formik';
import { useSearchUsers } from 'hooks/users/useSearchUser/useSearchUser';
import { useUpdateUser } from 'hooks/users/useUpdateUser/useUpdateUser';
import { useNotification } from 'lib/context/notification.context';
import { FC, useEffect, useRef } from 'react';
import { Button } from 'views/components/UI/Buttons';
import Modal from 'views/components/UI/Modal';
import Input from 'views/components/UI/TextInput';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { updateAdminPlatformValidationSchema } from '../../schemas/create-admin-platform.schema';
import {
    ButtonWrapper, ContainerInputs, Form, GridModal, ModalBody, ModalContainer, ModalHeader, Title
} from './styled';

interface CreateUserModalProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  handleCloseClick: () => void
  idUserSelected: number
}

type FormValues = {
  name: string
  lastName: string
  birthday: string
}

const EditPlatformAdminModal: FC<CreateUserModalProps> = ({
  openModal,
  setOpenModal,
  handleCloseClick,
  idUserSelected,
}) => {
  const { getError, getSuccess, getWarning } = useNotification()
  const { search: searchUser, results: resultsSearch } = useSearchUsers()
  const { updateUserHandler } = useUpdateUser()

  const isDisabled = (allErrors: any) => {
    return Object.keys(allErrors).length > 0
  }

  useEffect(() => {
    searchUser({ userId: idUserSelected, disableFilterByCompany: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUserSelected])

  const initialValues = {
    name: resultsSearch?.[0]?.first_name || EMPTY_STRING,
    lastName: resultsSearch?.[0]?.last_name || EMPTY_STRING,
    email: resultsSearch?.[0]?.email || EMPTY_STRING,
    document: resultsSearch?.[0]?.document || EMPTY_STRING,
    documentType: resultsSearch?.[0]?.document_type_id || EMPTY_STRING,
  }

  const formikRef = useRef<FormikProps<FormValues>>(null)

  return (
    <Modal
      show={openModal}
      setShow={setOpenModal}
      hideCloseButton={true}
      styleContent={{
        width: '30%',
        height: 'auto',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
      }}
    >
      {resultsSearch?.[0] && (
        <ModalContainer>
          <ModalHeader>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                formikRef.current?.resetForm()
                handleCloseClick()
              }}
            >
              <CloseOutlinedIcon />
            </div>
          </ModalHeader>

          <ModalBody>
            <Title>Editar administrador</Title>
            <GridModal>
              <Formik
                initialValues={initialValues}
                validationSchema={updateAdminPlatformValidationSchema}
                validateOnChange={true}
                innerRef={formikRef as any}
                onSubmit={(values, { resetForm }) => {
                  const body: any = {
                    userId: idUserSelected,
                    firstName: values.name,
                    lastName: values.lastName,
                    email: values.email,
                  }

                  updateUserHandler(body)
                    .then((res) => {
                      if (res.status === 'success') {
                        getSuccess('Se ha editado el usuario correctamente')
                        resetForm()
                        handleCloseClick()
                      }
                    })
                    .catch(() => {
                      getError('Ha ocurrido un error al editar el usuario')
                    })
                }}
              >
                {({ values, handleChange, errors, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <ContainerInputs>
                      <Input
                        name="name"
                        value={values.name}
                        label="Nombre"
                        type="text"
                        onChange={handleChange}
                        helperText={errors.name}
                      />
                      <Input
                        name="lastName"
                        value={values.lastName}
                        label="Apellido"
                        type="text"
                        onChange={handleChange}
                        helperText={errors.lastName}
                      />
                      <Input
                        name="email"
                        value={values.email}
                        label="Correo"
                        type="text"
                        onChange={handleChange}
                        helperText={errors.email}
                      />
                      <ButtonWrapper>
                        <Button
                          type="submit"
                          disabled={isDisabled(errors)}
                          onDisabledClick={() => {
                            if (errors.name) {
                              getWarning(`El nombre es requerido`)
                            }

                            if (errors.lastName) {
                              getWarning(`El apellido es requerido`)
                            }

                            if (errors.email) {
                              getWarning(`El correo es requerido y debe tener un formato válido`)
                            }
                          }}
                        >
                          Guardar
                        </Button>
                      </ButtonWrapper>
                    </ContainerInputs>
                  </Form>
                )}
              </Formik>
            </GridModal>
          </ModalBody>
        </ModalContainer>
      )}
    </Modal>
  )
}

export default EditPlatformAdminModal
