import { AxiosResponse } from 'axios';
import axios from 'lib/axios';

interface ISubscription {
    subscriptionId: number;
    subscriptionName: string;
    name: string;
    credits: number;
    companyId: number;
    usedCredits: number;
    suscription_company_id: number;
}

interface GetInventoriesResponse {
  status: 'OK' | 'ERROR';
  error?: any;
  data: {
    subscriptionCompany?: ISubscription[];
  };
  total?: number;
  total_pages?: number;
}

interface Filters {
  companyId: number;
  status?: boolean;
  page?: number; 
  limit?: number;
}

async function getInventoriesByCompany(filters: Filters): Promise<GetInventoriesResponse> {
  const { companyId, status, page, limit } = filters;
  const queryParams = new URLSearchParams();

  if (status) {
    queryParams.append('status', String(status));
  }

  if (page) {
    queryParams.append('page', String(page));
  }

  if (limit) {
    queryParams.append('limit', String(limit));
  }

  const PATH = `/subscription/${companyId}?${queryParams.toString()}`;

  try {
    const response: AxiosResponse<GetInventoriesResponse> = await axios.get(PATH);
    return response.data;
  } catch (error) {
    console.error('Error getting inventories by company', error);
    return { status: 'ERROR', error, data: {} };
  }
}


export default getInventoriesByCompany;
