import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Login from 'views/screens/Login'
import Register from 'views/screens/Register'
import Forgot from 'views/screens/Forgot'
import GenerateReportsPdf from 'views/screens/Evaluated-User/pages/generate-reports-pdf'

const PublicRouter: FC = () => {
  const isAuthenticated = false

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path='/generate-user-pdf' element={<GenerateReportsPdf />} />
      {!isAuthenticated && <Route path="*" element={<Navigate to="/" />} />}
    </Routes>
  )
}

export default PublicRouter
