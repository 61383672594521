import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import Box from '@mui/material/Box'
import Stepper from 'views/components/Stepper'
import Select from 'views/components/UI/Select'
import MultiSelect from 'views/components/UI/SelectMultiple/select-multiple'
import Input from 'views/components/UI/TextInput'
import { P } from 'views/components/UI/Text'
import { Button, BackButton } from 'views/components/UI/Buttons'
import { InputGrid, Row, Container, Error } from './styled'
import InputRadio from 'views/components/UI/InputRadio'
import ModalLoader from 'views/components/UI/ModalLoader'
import { useNotification } from 'lib/context/notification.context'
import { platformAdminRoutes } from 'router/routes'
import { setStepOneTrigger } from 'ducks/createProcess'
import { STEPS_NEW_PROCESS } from './constants'
import { generalDataSelector } from 'ducks/auth'
import { userSelector } from 'ducks/user'
import { stepOneSelector, loadingCreateProcessSelector } from 'ducks/createProcess'
import { StepOne, LevelType, InventoriesAvailables } from 'types/common'
import { StepOneSchema } from './validation-schemas'
import getInventoriesByCompany from 'services/companies/getInventories'
import useAdminsByCompany from 'hooks/company/useGetAdminsByCompany/useGetAdminsByCompany'
import { parseInventoryNameToId, parseInventoryIdToName } from './main'
import { BETESA_DEFAULT_CATEGORY_ID } from './constants'
import getCompanyInfo from 'services/companies/getCompanyInfo/getCompanyInfo'
import { v4 as uuidv4 } from 'uuid'

function CreateProcess() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { adminsByCompany, loading: loadingAdmins } = useAdminsByCompany()
  const { getError } = useNotification()

  const { data: generalData } = useSelector(generalDataSelector)
  const levelTypes = generalData?.level
    ?.filter((item: any) => item.id !== BETESA_DEFAULT_CATEGORY_ID)
    .map((item: any) => ({ value: item.id, label: item.name, id: item.id }))

  const { companyId } = useSelector(userSelector)
  const stepOneData = useSelector(stepOneSelector)
  const loadingCreateProcess = useSelector(loadingCreateProcessSelector)
  const {
    processType,
    processName,
    inventoryTypeId,
    inventoryUniqName,
    level,
    admins,
    automaticReports,
    testLanguage,
    permitDevelopmentPlan,
    processId,
    profileId,
    showInputId,
  }: StepOne = stepOneData

  const [loading, setLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [inventoriesAvailables, setInventoriesAvailables] = useState<InventoriesAvailables[]>([])
  const [levels, setLevels] = useState<LevelType[]>([])
  const [IdInventory, setIdInventory] = useState<number | null>(null)
  const [allowIntegration, setAllowIntegration] = useState(false)

  const dataUser = useSelector((state: any) => state.user)

  useEffect(() => {
    getCompanyInfo(companyId)
      .then((response) => {
        if (response.status === 'OK') {
          setAllowIntegration(response.data[0].allow_integration)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener la información de la compañía')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])

  const inventoryTypes = Array.isArray(inventoriesAvailables)
    ? inventoriesAvailables.map((item: any) => ({ value: item.subscriptionName, label: item.subscriptionName }))
    : []

  const adminOptions =
    !loadingAdmins && adminsByCompany && adminsByCompany.length > 0
      ? [
          { value: 0, label: 'Todos' },
          ...adminsByCompany
            .filter((item: any) => item.user_id !== dataUser.userId)
            .map((item: any) => ({ value: item.user_id, label: item.full_name })),
        ]
      : []

  const { handleSubmit, handleChange, values, setFieldValue, errors, isSubmitting, setValues } = useFormik({
    initialValues: {
      processType: processType || 0,
      processName: processName || '',
      inventoryType: inventoryUniqName || '',
      level: level || '',
      admins: admins || [],
      automaticReports: automaticReports,
      testLanguage: testLanguage || 'ES',
      userId: dataUser.userId,
      permitDevelopmentPlan: permitDevelopmentPlan,
      profileId: profileId || '',
      showInputId: showInputId || false,
      showCustomIdOption: showInputId || false,
    },
    validationSchema: StepOneSchema,
    onSubmit: async (valuesForm, { setSubmitting }) => {
      try {
        setSubmitting(true)
        const selectedLevelObject = levels.find((levelData) => String(levelData.value) === valuesForm.level.toString())
        const levelName = selectedLevelObject ? selectedLevelObject.label : ''
        const filteredAdmins = valuesForm.admins.filter((admin: any) => admin !== 0)
        const dataStepOneToDispatch: any = {
          ...valuesForm,
          admins: filteredAdmins,
          profileId: valuesForm.profileId === '' ? uuidv4() : valuesForm.profileId,
          inventoryType: parseInventoryNameToId(inventoriesAvailables, valuesForm.inventoryType as any),
          companyId,
          inventoryUniqName: valuesForm.inventoryType,
          inventoryId: IdInventory,
          inventoryTypeId: IdInventory,
          levelName: levelName,
        }

        if (processId) {
          dataStepOneToDispatch.processId = processId
        }
        dispatch(setStepOneTrigger.run({ ...dataStepOneToDispatch }))
      } catch (error) {
        console.error('Error submiting the step one', error)
        setFieldValue('processName', '')
        getError('Ha ocurrido un error al intentar crear el paso 1 del proceso')
      } finally {
        setSubmitting(false)
        navigate(platformAdminRoutes.PreconfigProcess)
      }
    },
  })

  const handleInventoryType = useCallback(
    (inventoryName: string | number) => {
      if (inventoriesAvailables.length === 0) return

      const inventoryTypeIdSelected =
        typeof inventoryName === 'string'
          ? inventoriesAvailables.find((item: any) => item.subscriptionName === inventoryName)?.subscriptionId
          : inventoriesAvailables.find((item: any) => item.suscription_company_id === inventoryName)?.subscriptionId

      const inventoryIdSelected: number =
        typeof inventoryName === 'number'
          ? inventoryName
          : inventoriesAvailables.find((item: InventoriesAvailables) => item.subscriptionName === inventoryName)
              ?.suscription_company_id || 0

      setIdInventory(inventoryIdSelected)

      switch (inventoryTypeIdSelected) {
        case 1:
          setLevels(levelTypes?.filter((item: any) => item.id === 4))
          break
        case 2:
          setLevels(levelTypes?.filter((item: any) => item.id === 2 || item.id === 3))
          break
        case 3:
        case 4:
          setLevels(levelTypes?.filter((item: any) => item.id === 2 || item.id === 3 || item.id === 4))
          break
        default:
          alert('No se encontró el tipo de inventario')
          break
      }
    },
    [levelTypes, inventoriesAvailables]
  )

  useEffect(() => {
    if (loading) return
    setLoading(true)
    getInventoriesByCompany({ companyId, status: true })
      .then((response) => {
        if (response.status === 'OK') {
          setInventoriesAvailables(response.data?.subscriptionCompany as InventoriesAvailables[])
          setLoading(false)
        } else {
          getError('Esta compañía no tiene inventarios disponibles')
          setInventoriesAvailables([])
          setLoading(false)
        }
      })
      .catch(() => {
        getError('Ha ocurrido un error al intentar obtener los inventarios disponibles')
        setInventoriesAvailables([])
      })
      .finally(() => {
        setLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryTypeId, processName])

  useEffect(() => {
    setValues({
      processType: processType || 0,
      processName: processName || '',
      inventoryType: inventoryUniqName || parseInventoryIdToName(inventoriesAvailables, inventoryTypeId as any) || '',
      level: level || '',
      admins: admins || [],
      automaticReports: automaticReports,
      testLanguage: testLanguage || 'ES',
      userId: dataUser.userId,
      permitDevelopmentPlan: permitDevelopmentPlan,
      profileId: profileId || '',
      showCustomIdOption: showInputId || false,
      showInputId: showInputId || false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    processType,
    processName,
    inventoryTypeId,
    level,
    admins,
    automaticReports,
    testLanguage,
    permitDevelopmentPlan,
    setValues,
    inventoryUniqName,
    stepOneData,
    inventoriesAvailables,
    profileId,
    showInputId,
  ])

  useEffect(() => {
    if (inventoryUniqName && inventoriesAvailables.length > 0) {
      handleInventoryType(inventoryUniqName)
    } else if (inventoryTypeId && inventoriesAvailables.length > 0) {
      handleInventoryType(inventoryTypeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryTypeId, loadingCreateProcess, inventoryUniqName, inventoriesAvailables])

  return (
    <MainLayout>
      {!loadingCreateProcess && !loading ? (
        <Container>
          <h2>Configuración del proceso</h2>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={0} steps={STEPS_NEW_PROCESS} />
          </Box>
          {inventoriesAvailables?.length > 0 ? (
            <>
              <form onSubmit={handleSubmit}>
                <Row margin="20px 0px 8px 0px">
                  <P>¿Es un proceso de selección o desarrollo?</P>
                  <InputRadio
                    checked={values.processType === 1}
                    label="Selección"
                    value="Seleccion"
                    onChange={() => setFieldValue('processType', 1)}
                    name="processType"
                    tooltipText="Esta opción permite crear un proceso de selección de personal, en el cual se evaluarán las competencias de los candidatos a un cargo específico."
                  />
                  <InputRadio
                    checked={values.processType === 2}
                    label="Desarrollo"
                    value="Desarrollo"
                    onChange={() => setFieldValue('processType', 2)}
                    name="processType"
                    tooltipText="Esta opción permite crear un proceso de desarrollo de personal, en el cual se evaluarán las competencias de los empleados de una organización."
                  />
                </Row>
                {showErrors && errors.processType && <Error>{errors.processType}</Error>}
                <InputGrid style={{ marginBottom: '20px' }}>
                  <Input
                    value={values.processName}
                    onChange={handleChange}
                    type="text"
                    name="processName"
                    label="Nombre del proceso"
                    helperText={(showErrors && errors?.processName) || ''}
                  />
                  <Select
                    label="Inventario a utilizar"
                    options={inventoryTypes}
                    value={values.inventoryType}
                    onChange={(e) => {
                      setFieldValue('inventoryType', e)
                      handleInventoryType(e)
                    }}
                    name="inventoryType"
                    error={(showErrors && (errors.inventoryType as string)) || ''}
                    hideNeverChoice
                  />
                  <Select
                    width="98%"
                    label="Nivel"
                    options={levels}
                    value={values.level}
                    onChange={(e) => {
                      setFieldValue('level', e)
                    }}
                    name="level"
                    error={(showErrors && (errors.level as string)) || ''}
                    hideNeverChoice
                    disabled={values.inventoryType === ''}
                  />
                  <Row>
                    <MultiSelect
                      maxWidth="none"
                      width="100%"
                      label="Administradores que podrán ver este proceso"
                      options={adminOptions}
                      value={values.admins}
                      onChange={(e) => {
                        const newValues = e
                        const currentValues = values.admins

                        if (newValues.includes(0) && !currentValues.includes(0)) {
                          setFieldValue(
                            'admins',
                            adminOptions.map((user) => user.value)
                          )
                        } else if (!newValues.includes(0) && currentValues.includes(0)) {
                          setFieldValue('admins', [])
                        } else if (newValues.length < currentValues.length) {
                          setFieldValue(
                            'admins',
                            newValues.filter((value: number | string) => value !== 0)
                          )
                        } else {
                          setFieldValue('admins', newValues)
                        }
                      }}
                      error={(showErrors && (errors.admins as string)) || ''}
                    />
                  </Row>
                </InputGrid>
                <Row>
                  <P width="45%">¿Entrega automática de los reportes a los participantes?</P>
                  <InputRadio
                    checked={values.automaticReports}
                    label="Si"
                    value="Si"
                    onChange={() => setFieldValue('automaticReports', true)}
                    name="automaticReports"
                  />
                  <InputRadio
                    checked={values.automaticReports === (false as any)}
                    label="No"
                    value="No"
                    onChange={() => setFieldValue('automaticReports', false)}
                    name="automaticReports"
                  />
                </Row>
                {showErrors && errors.automaticReports && (
                  <div style={{ margin: '12px 0px' }}>
                    <Error>{errors.automaticReports}</Error>
                  </div>
                )}
                <Row>
                  <P width="45%">¿Permitir creación de plan de desarrollo por usuario?</P>
                  <InputRadio
                    checked={values.permitDevelopmentPlan}
                    label="Si"
                    value="Si"
                    onChange={() => setFieldValue('permitDevelopmentPlan', true)}
                    name="permitDevelopmentPlan"
                  />
                  <InputRadio
                    checked={values.permitDevelopmentPlan === (false as any)}
                    label="No"
                    value="No"
                    onChange={() => setFieldValue('permitDevelopmentPlan', false)}
                    name="permitDevelopmentPlan"
                  />
                </Row>
                {showErrors && errors.permitDevelopmentPlan && (
                  <div style={{ margin: '12px 0px' }}>
                    <Error>{errors.permitDevelopmentPlan}</Error>
                  </div>
                )}
                {allowIntegration ? (
                  <>
                    <Row style={{ marginTop: '10px', marginBottom: '-8px' }}>
                      <P width="45%">Integración:</P>
                    </Row>
                    <Row>
                      <P width="45%">¿Quieres activar este proceso para integración?</P>
                      <InputRadio
                        checked={values.showCustomIdOption}
                        label="Si"
                        value="Si"
                        onChange={() => setFieldValue('showCustomIdOption', true)}
                        name="showInputId"
                      />
                      <InputRadio
                        checked={!values.showCustomIdOption}
                        label="No"
                        value="No"
                        onChange={() => setFieldValue('showCustomIdOption', false)}
                        name="showInputId"
                      />
                    </Row>
                  </>
                ) : null}
                {values.showCustomIdOption ? (
                  <>
                    <Row>
                      <P width="45%">¿Tienes un identificador de perfil personalizado?</P>
                      <InputRadio
                        checked={values.showInputId}
                        label="Si"
                        value="Si"
                        onChange={() => setFieldValue('showInputId', true)}
                        name="showInputId"
                      />
                      <InputRadio
                        checked={!values.showInputId}
                        label="No"
                        value="No"
                        onChange={() => setFieldValue('showInputId', false)}
                        name="showInputId"
                      />
                    </Row>
                    {values.showInputId && (
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Input
                          value={values.profileId}
                          onChange={handleChange}
                          style={{ width: '10%', marginLeft: '30px' }}
                          type="number"
                          name="profileId"
                          label="Id del perfil"
                          helperText={(showErrors && errors?.profileId) || ''}
                        />
                      </div>
                    )}
                  </>
                ) : null}
                <Row
                  margin="16px 0px"
                  justifyContent="space-between"
                  style={{ height: `${values.showInputId ? '244px' : '300px'}`, alignItems: 'flex-end' }}
                >
                  <BackButton onClick={() => navigate(platformAdminRoutes.Home)} />
                  <Button
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    onDisabledClick={() => {
                      setShowErrors(true)
                      getError('Por favor, completa los campos requeridos')
                    }}
                    type="submit"
                  >
                    Siguiente
                  </Button>
                </Row>
              </form>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <h3 style={{ color: 'rgb(198, 1, 107)' }}>Esta compañía no tiene inventarios asociados disponibles</h3>
            </div>
          )}
        </Container>
      ) : (
        <ModalLoader isLoading={loadingCreateProcess} />
      )}
    </MainLayout>
  )
}

export default CreateProcess
