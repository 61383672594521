import { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import { userSelector } from 'ducks/user'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { H2 } from 'views/components/UI/Text'
import { ActionEditDraftProcess } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { useNotification } from 'lib/context/notification.context'
import { Container } from './styled'
import Loader from 'views/components/UI/Loader/Loader'
import { useFinishProcess } from 'hooks/process/useFinishProcess/useFinishProcess'
import { useFetchProcesses } from 'hooks/process/useGetProcess/useGetProcess'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'
import { DEFAULT_LIMIT } from 'constants/global.constants'

const HEADERS_DRAFT_PROCESSES = [
  { name: 'Nombre del proceso', key: 'name' },
  { name: 'Pruebas a aplicar', key: 'categories' },
  { name: 'Cantidad total de usuarios', key: 'count_users' },
]

const DraftProcesses = () => {
  const { getError } = useNotification()
  const { companyId } = useSelector(userSelector)
  const { handleFinishProcess } = useFinishProcess()
  const { listProcess, fetchProcesses, loadingListProcesses, paginationListProcess } = useFetchProcesses()
  const dataSelector = useSelector((state: any) => state.user.userId)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const onFinishProcess = useCallback(
    (processId: number) => {
      if (!loading) {
        setLoading(true)
        getProcessDetail(processId)
          .then((response: any) => {
            setLoading(false)
            handleFinishProcess(response.process)
          })
          .catch(() => {
            getError('Ha ocurrido un error al cargar este proceso')
            setLoading(false)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, getError]
  )

  useEffect(() => {
    fetchProcesses({ sent: false, limit: DEFAULT_LIMIT, page, userId: dataSelector })
  }, [dataSelector, fetchProcesses, page])

  useEffect(() => {
    if (paginationListProcess) {
      setTotalPages(paginationListProcess.totalPages)
    }
  }, [paginationListProcess])

  const handlePagination = useCallback(
    (pageProcess: number) => {
      setPage(pageProcess)
      fetchProcesses({ sent: false, limit: 10, page: pageProcess })
    },
    [fetchProcesses]
  )

  return (
    <MainLayout>
      <Container>
        <H2 margin="16px 0px">Procesos en borrador</H2>
        {!loadingListProcesses ? (
          <>
            {listProcess?.length > 0 ? (
              <BluesiteTable
                data={listProcess}
                headers={HEADERS_DRAFT_PROCESSES}
                searchBar={false}
                searchLabel=""
                ActionComponent={ActionEditDraftProcess}
                totalPages={totalPages}
                currentPage={page}
                handlePagination={handlePagination}
                extraDataAction={{
                  onFinishProcess,
                }}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                }}
              >
                <h3 style={{ color: 'rgb(198, 1, 107)' }}>Esta compañía no tiene procesos en borrador</h3>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </MainLayout>
  )
}

export default DraftProcesses
