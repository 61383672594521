import { setCobrandingLogo, setCompanyName } from 'ducks/ui'
import { useDispatch } from 'react-redux'
import fetchCompanyLogo from 'services/cobranding/get-company-logo/get-company-logo'

interface UseCompanyLogoProps {
  processId: string
  isBase64?: boolean
  token?: string
}

const useCompanyLogo = () => {
  const dispatch = useDispatch()
  const getCompanyLogo = async ({ processId, isBase64, token }: UseCompanyLogoProps) => {
    await fetchCompanyLogo({ processId, isBase64, token }).then((response) => {
      dispatch(setCompanyName(response.companyName))
      dispatch(setCobrandingLogo(response.cobranding.logo))
    })
  }

  return { getCompanyLogo }
}

export default useCompanyLogo
