import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNotification } from 'lib/context/notification.context'
import { getFamilyRolInformation } from 'services/rolFamily/fetchGetRolFamily'
import EditConfigOptions from '../../components/edit-config-options/edit-config-options'

const EditRoleFamily: FC = () => {
  const { id }: any = useParams()
  const { getError } = useNotification();
  const [familyRol, setFamilyRol] = useState({})
  const [step, setStep] = useState(0)

  useEffect(() => {
    getFamilyRolInformation(id).then((res: any) => {
      if (!res.ok && res.message) getError(res.message)
      if (res?.status === 'OK') setFamilyRol(res?.data)
    }).catch((error: any) => {
      getError(error.message)
    })
  }, [getError, id, step])

  if (!familyRol) {
    return <>cargando</>
  }

  return (
    <>
      {familyRol &&
        <EditConfigOptions
          familyRol={familyRol}
          step={step}
          setStep={setStep}
        />
      }
    </>
  )
}

export default EditRoleFamily
