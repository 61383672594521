/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
// Components
import { ContainerItems, GridContainer, GridCompetence, GridItem, TitleGrid, Divider, MinimumRecordContainer, GridMinimumRecord, ErrorContainer, TextError } from '../../pages/edit-role-family/styled';
import { H2, P } from 'views/components/UI/Text';

// import Select from 'views/components/UI/Select';
import Input from 'views/components/UI/TextInput';
import { FieldArray } from 'formik';

const ChargesList = [
    {
        id: 1,
        name: 'Cargo 1',
    },
    {
        id: 2,
        name: 'Cargo 2',
    },
    {
        id: 3,
        name: 'Cargo 3',
    },
    {
        id: 4,
        name: 'Cargo 4',
    },
    {
        id: 5,
        name: 'Cargo 5',
    },
    {
        id: 6,
        name: 'Cargo 6',
    }
]

const ChargesList2 = [
    {
        id: 1,
        name: 'Cargo 1',
    },
    {
        id: 2,
        name: 'Cargo 2',
    },
    {
        id: 3,
        name: 'Cargo 3',
    },
    {
        id: 4,
        name: 'Cargo 4',
    },
    {
        id: 5,
        name: 'Cargo 5',
    },
    {
        id: 6,
        name: 'Cargo 6',
    }
]



const JobPositionForm = ({ props, typicalCharges, category }: any) => {
    const { setFieldValue, typeTestValue = [], activeStep = 0, values, errors, touched } = props
    return (
        <MinimumRecordContainer>
            <H2>Cargos típicos</H2>


            <GridContainer>
                <GridMinimumRecord>
                    <GridItem>
                        <TitleGrid></TitleGrid>
                    </GridItem>
                    <GridItem>
                        <TitleGrid>Nombre del cargo</TitleGrid>
                    </GridItem>
                </GridMinimumRecord>
                <Divider margin='10px 0px 16px 0px' />
            </GridContainer>
            <GridCompetence>
                <FieldArray name={`${typicalCharges}`}>
                    {({ insert, remove, push }) => (
                        <>
                            {
                                // errors?.typicalCharges && touched?.typicalCharges ? (
                                // <ErrorContainer>
                                // <TextError>{errors.typicalCharges}</TextError>
                                // </ErrorContainer>
                                // ) : null
                            }
                            {
                                ChargesList?.map((charge: any, index: any) => {
                                    return <ContainerItems key={`${charge?.id}.${index}`}>
                                        <GridItem>
                                            <P>{charge?.name}</P>
                                        </GridItem>
                                        <GridItem height='80px'>
                                            <Input
                                                name={`${typicalCharges}.${index}`}
                                                type="text"
                                                label='Nombre del cargo'
                                                onChange={(e: any) => {
                                                    setFieldValue(`${typicalCharges}.${index}.name`, e.target.value);
                                                }}
                                                value={
                                                    values[`${typicalCharges}`] && values[`${typicalCharges}`][index]
                                                        ? values[`${typicalCharges}`][index]?.name
                                                        : ''
                                                }
                                            />
                                        </GridItem>
                                    </ContainerItems>
                                })
                            }
                        </>
                    )}
                </FieldArray>
            </ GridCompetence>

        </MinimumRecordContainer>
    )
}

export default JobPositionForm;
