import axios from 'axios'

/**
 * @description service to get process detail by user
 * @param token
 */

interface ITest {
    process_name: string,
    process_description: string,
    test_id: number,
    test_name: string,
    category_name: string,
    type_test_id: number,
    betesa_test_id: number | null,
}


type IUserDetail = {
    user_name: string,
    user_birth_date: string,
    user_document: string,
    process_id: number,
    process_name: string,
}

type CompaniesHistory = {
    company_name: string,
    company_id: number,
    process_name: string,
}

type Data = {
    user_detail: IUserDetail,
    companies_history: CompaniesHistory[],
    tests: ITest[],
}


interface IResponse  {
    status: 'error' | 'OK'
    data: Data
}

const  getProcessDetailByUser = async (token: string, userId: number ) => {
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/company/process/user/detail/${userId}`

  const config = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`,
    }, 
  }

    const response: IResponse = await axios.get(apiUrl, config).then((res) => {
      return res.data
    }).catch((err) => {
      return err
    })
    return response
}

export default getProcessDetailByUser
