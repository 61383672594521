import axios from 'axios'

export const putFamilyRolBasicInfo = async (data: any, id: any) => {
  const res = await axios.put(`/role_family/basic_info/update/${id}`, data).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}

export const putFamilyRolFamilySkill = async (data: any, id: any) => {

  const res = await axios.put(`/role_family/family_skills/update/${id}`, data).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}

export const putFamilyRolFamilyPositions = async (data: any, id: any) => {

  const res = await axios.put(`/role_family/positions/update/${id}`, data).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}

export const putFamilyRolFamilyCompetenceSkill = async (data: any, id: any) => {

  const res = await axios.put(`/role_family/competence_skill/update/${id}`, data).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}

export const putFamilyRolProfileValue = async (data: any, id: any) => {

  const res = await axios.put(`/role_family/profile_value/update/${id}`, data).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}


