import { Link } from 'react-router-dom'
import { Container, ImageContainer, TextContainer, Image, TitleContainer, ActionContainer, Title } from './styled'
import { P } from 'views/components/UI/Text'
import { useNotification } from 'lib/context/notification.context'
import { useNavigate } from 'react-router-dom'

import noImg from 'assets/images/no-image.png'
import { useTranslation } from 'react-i18next'

interface IProps {
  image: string
  title: string
  link: string
  params?: any
  disabled?: boolean
}

function CardTestType(props: IProps) {
  const { t } = useTranslation()
  const { image, title, link, params, disabled } = props
  const { getWarning } = useNotification()
  const navigate = useNavigate()

  const disabledColor = '#C2C2C2' 

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    if (!link || disabled) {
      if (title === 'Betesa - Talentos Naturales' || title === "Betesa - Talentos naturales")  {
        getWarning('No se ha interpretado o finalizado la prueba')
      } 

      if (title === t('global.common.skillText')) {
        getWarning('El usuario no ha finalizado la prueba')
      }

      
    } else {
      navigate(props.link, { state: params })
    }
  }

  return (
    <Container>
      <ImageContainer style={{background: disabled ? disabledColor : '#81ACC4' }}>{image ? <Image disabled={disabled} src={image} /> : <Image disabled={disabled} src={noImg} />}</ImageContainer>
      <TextContainer style={{background: disabled ? disabledColor : '#FBFBFB' }}>
        <TitleContainer>
          <Title style={{color: disabled? 'gray' : 'black'}}>{title || 'Inserte titulo'}</Title>
        </TitleContainer>
        <ActionContainer>
          <Link to={props.link} state={{ ...params }} onClick={handleClick}>
            <P fontWeight={700} margin="8px 0px 0px 0px" color={disabled? 'gray' : '#C6016B'}>
              Ver detalles
            </P>
          </Link>
        </ActionContainer>
      </TextContainer>
    </Container>
  )
}

export default CardTestType
