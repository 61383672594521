import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const HeaderContent = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
`;

export const RoleFamilyContentData = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;



export const Row = styled.div`
    display: flex;
    flex-direction: row;    
    width: 100%;
    margin: 10px 0px;
`;

export const RoleFamilyContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;


interface IRoleFamilyDataItemProps {
    width?: string;
}

export const Column = styled.div<IRoleFamilyDataItemProps>`
    display: flex;
    flex-direction: column;
    width : ${props => props.width ? props.width : '100%'};
`;

// text with left dot


export const TextWidthDotContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;

    & p {
        margin: 4px 0;
    }
`;

export const TextDot = styled.p`
    color: #797A7C;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-left: 10px;
`;

export const Dot = styled.div`
    width: 6px;
    height: 6px;
    min-width: 6px;
    margin: 12px 2px 2px 2px;
    border-radius: 50%;
    background-color: #797A7C;
`;

// edit role family

export const EditRoleFamilyContainer = styled.div`
    display: flex;
    flex-direction: row;
    color: #2D749C;
    cursor: pointer;
`;


export const BetesaQuadrantsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0% 20%;
    margin-top: 30px;
`;

export const TextTitleAlertBetesa = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #1F2124;
`;
