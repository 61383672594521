import { useState } from 'react';
import createUserService from 'services/common/users/create-user'
import { UserDataCreateUser } from 'types/common';

interface ServiceCreateUser {
  first_name: string,
  last_name: string,
  document: string,
  email: string,
  document_type: string,
  role_type: 1 | 2 | 3 | 4,
  company_id?: number,
  birth_date?: string,
  no_send_email?: boolean,
}

interface UseCreateUserResult {
  createUser: (userData: UserDataCreateUser) => Promise<any>;
  loadingCreateUser: boolean;
  errorCreateUser: string | null;
}

const useCreateUser = (): UseCreateUserResult => {
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [errorCreateUser, setError] = useState<string|null>(null);

  const createUser = async (userData: UserDataCreateUser): Promise<any> => {
    setLoadingCreateUser(true);
    setError(null);

    const userInfo: ServiceCreateUser = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      document: userData.document,
      email: userData.email,
      document_type: userData.documentType,
      role_type: userData.roleType,
    };

    if (userData.companyId) {
      userInfo.company_id = userData.companyId
    }

    if (userData.birthdate) {
      userInfo.birth_date = userData.birthdate
    }

    if (userData.noSendEmail === true) {
      userInfo.no_send_email = userData.noSendEmail
    }

    try {
      const response = await createUserService(userInfo);
      setLoadingCreateUser(false);
      return response;
    } catch (e) {
      setLoadingCreateUser(false);
      setError('Error creating new user.');
      return null;
    }
  };

  return { createUser, loadingCreateUser, errorCreateUser };
};

export default useCreateUser;
