import axios from 'axios'
import { INineBoxAnalytics } from 'views/screens/Platform-administrator/Analytics/constants/types'

const updateNineBox = async (id: number, body: INineBoxAnalytics) => {
  const updatedNineBox = await axios
    .put(`/nine-box/update/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return updatedNineBox
}

export default updateNineBox
