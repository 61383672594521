import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import TitleDiv from 'views/components/UI/TitleDiv'
import { StrokedButton, Button } from 'views/components/UI/Buttons';
import { ContainerRow, BtnGoBack, SubTitleRow, ContainerActionItem } from './styled';
import { H2 } from 'views/components/UI/Text'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { P } from 'views/components/UI/Text';
import { CHAPTER_HEADERS, steps} from '../constants'
import getChapters from 'services/common/chapters/get'

// routes 
import { superAdminRoutes } from 'router/routes'

type IChapter = {
  id: number,
  name: string,
  number: string,
  description: string,
}

const Chapters: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()


  // local state
  const [chapters, setChapters] = useState<IChapter[] | []>([])
  const [currentQuestionnaireId, setCurrentQuestionnaireId] = useState<number | null>(null)

  // router state
  const { questionnaireId } = location.state
  useEffect(() => {
    
    if (questionnaireId !== null) {
      setCurrentQuestionnaireId(parseInt(questionnaireId))
    }

    if (questionnaireId) {
      getChapters({ typeCategoryTestId: parseInt(questionnaireId) }).then((res) => {
        if (res.status === 'OK') {
          const chaptersFormatted = res.chapters.filter((chapter: any) => chapter.id !== 1) // remove chapter because is skill (competencias)
          setChapters(chaptersFormatted)
        }
      })
    }
  }, [ questionnaireId])

  const ActionComponent = (props: any) => {
    const { id } = props;

    return (
      <div style={{display: 'flex' , flexDirection: 'column'}}>
        <ContainerActionItem onClick={() => {
        navigate(`${superAdminRoutes.QuestionsTalentQuestionaire}`,
          { state: { chapterId: id, questionnaireId: currentQuestionnaireId } })
      }}>
        <P style={{
          fontFamily: 'Public Sans',
          fontWeight: 700,
          color: '#2D749C',
          fontSize: '14px',
          margin: '16px 10px 0px 10px',
        }}>Asociar preguntas</P>
      </ ContainerActionItem>
      <ContainerActionItem onClick={() => {
        navigate(`${superAdminRoutes.EditChapter}`,
          { state: { chapterId: id, questionnaireId: currentQuestionnaireId } })
      }}>
        <P style={{
          fontFamily: 'Public Sans',
          fontWeight: 700,
          color: '#2D749C',
          fontSize: '14px',
          margin: '16px 10px 0px 10px',
        }}>Editar Capítulos </P>
      </ ContainerActionItem>
      </div>

    );
  };

  return (
    <MainLayout>
      <TitleDiv>
        <H2 style={{ fontSize: 32, marginBottom: 40 }}>Capítulos</H2>
      </TitleDiv>
      {
        chapters.length === 0 && (
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={1} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        )
      }
      <SubTitleRow>
        <h4 style={{ fontSize: 20 }}>{chapters.length === 0 ? 'Capítulos' : ''}</h4>
        <StrokedButton onClick={() => navigate(superAdminRoutes.CreateChapterQuestionaire,
          { state: { questionnaireId: currentQuestionnaireId } })
        } >
          Nuevo capítulo
        </StrokedButton>
      </SubTitleRow>

      {/* <ChaptersGrid data={chapters} questionnaireId={currentQuestionnaireId} /> */}
      <BluesiteTableComponent ActionComponent={ActionComponent} data={chapters} headers={CHAPTER_HEADERS} searchBar={false} currentPage={1} questionnaireId={currentQuestionnaireId} searchLabel='' />
      <ContainerRow>
        <BtnGoBack onClick={() => navigate('/modules/talents/questionnaire')}>Cancelar</BtnGoBack>
        <Button onClick={() => navigate(`/modules/talents/questionnaire`)}>Guardar</Button>
      </ContainerRow>
    </MainLayout>
  )
}

export default Chapters
