import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { BackButton } from 'views/components/UI/Buttons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import EditIcon from 'assets/icons/EditCoin'

import { StrokedButton } from 'views/components/UI/Buttons'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { generalDataSelector } from 'ducks/auth'
import { IconSvgProfile, EditLinkWrapper } from './styled'
import getInventoryByCompany from 'services/inventory/getByCompany'
import { fetchEditInventoryStatus } from 'services/inventory/updateInventory'
import { DEFAULT_LIMIT } from 'constants/global.constants'


const HEADERS = [
  {
    key: 'name',
    name: 'Nombre',
  },
  {
    key: 'type',
    name: 'Tipo',
  },
  {
    key: 'credits',
    name: 'Pruebas adquiridas',
  },
  {
    key: 'tests_used',
    name: 'Pruebas usadas',
  },
  {
    key: 'tests_available',
    name: 'Pruebas por usar',
  },
  {
    key: 'start_date',
    name: 'Fecha de activación',
  },
  {
    key: 'expiration_date',
    name: 'Fecha de vencimiento',
  },
  {
    key: 'status',
    name: 'Estado',
  },
];


const ActionComponent: FC = (inventoryItem: any) => {

  return (
    <Link to={`/inventory/update?companyId=${inventoryItem.id}`} key={`edit-${inventoryItem.id}`}>
      <EditLinkWrapper>
        <IconSvgProfile viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <EditIcon />
        </IconSvgProfile>
        <label className="link-editar">Editar</label>
      </EditLinkWrapper>
    </Link>
  )
}


const Inventory: FC = () => {
  const navigate = useNavigate()
  dayjs.extend(utc)
  const { data } = useSelector(generalDataSelector)
  const typesSubscription = data.subscription

  const [idCurrentCompany, setIdCurrentCompany] = useState<any>(null)
  const [inventory, setInventory] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)



  function getInventories(page: number, limit: number) {
    getInventoryByCompany(parseInt(idCurrentCompany), page, limit)
      .then((response: any) => {
        if (response?.status === 'error') {
          setInventory(null)
          return
        }
        if (response?.status === 'success') {
          setTotalPages(response.total_pages)
          const res = response.data

          const inventoriesFormated = res.map((item: any) => {
            const formatter = new Date();
            const today = dayjs(formatter).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
            const expiration = item.expiration_date;
            const updateData = {
              start_date: today,
              expiration_date: expiration,
            };
            if (
              today > expiration &&
              item.state !== false
            ) {
              fetchEditInventoryStatus(Number(item.id), updateData);
            }
            return {
              id: item.id,
              name: item.name,
              type: _.find(typesSubscription, { id: item.subscription_id })?.name,
              credits: parseInt(item.credits) > 500000000 ? '∞' : item.credits,
              tests_used: item.tests_used,
              tests_available:
                parseInt(item.credits) > 500000000 ? '∞' : parseInt(item.credits) - parseInt(item.tests_used),
              start_date: dayjs.utc(item.start_date).format('DD/MM/YYYY'),
              expiration_date: dayjs.utc(item.expiration_date).format('DD/MM/YYYY'),
              status: item.tests_used >= item.credits ? 'Inactivo' : item.state ? 'Activo' : 'Inactivo',
            }
          })

          const sortedInventory = _.orderBy(inventoriesFormated, [
            (item) => (item.state === true ? 0 : 1),
            (item) => (item.state === true ? dayjs.utc(item.expiration_date).valueOf() : 0),
          ])
          setInventory(sortedInventory)
        }
      })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const idCompany = searchParams.get('companyId')
    if (idCompany !== null) setIdCurrentCompany(parseInt(idCompany))
  }, [])

  useEffect(() => {
    if (idCurrentCompany) {
      const page = 1
      getInventories(page, DEFAULT_LIMIT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCurrentCompany, typesSubscription])

  const handlePagination = (page: number) => {
    setCurrentPage(page)
    getInventories(page, DEFAULT_LIMIT)
  }

  return (
    <MainLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 35,
          alignItems: 'center',
        }}
      >
        <h5>Inventarios</h5>
        <StrokedButton onClick={() => navigate(`/inventory/new?companyId=${idCurrentCompany}`)} type="button">
          Nuevo inventario
        </StrokedButton>
      </div>
      <div style={{ width: '100%', minHeight: '70vh' }}>
        <BluesiteTable
          data={inventory}
          headers={HEADERS}
          searchBar={false}
          searchLabel=''
          totalPages={totalPages}
          currentPage={currentPage}
          handlePagination={handlePagination}
          ActionComponent={ActionComponent}
        />
      </div>
      <BackButton onClick={() => navigate(-1)} />
    </MainLayout>
  )
}

export default Inventory
