import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { MainLayout } from 'views/layouts'
import { TextArea } from './styled'
import { Button } from 'views/components/UI/Buttons'
import TitleDiv from 'views/components/UI/TitleDiv'
import { fetchCreateSkillsAction } from '../../services/fetch-skills-talents';
import { BACK_PAGE } from '../constants';
import { useNotification } from 'lib/context/notification.context';

const CreateAction: FC = () => {
  const navigate = useNavigate();
  const { getError, getSuccess } = useNotification();
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  const searchParams = new URLSearchParams(window.location.search);
  const developmentPlanId = searchParams.get('developmentPlanId');

  const newActionSchema = Yup.object().shape({
    description: Yup.string().required('El nombre del contacto es obligatorio'),
  })

  const onSubmit = (description: string) => {
    fetchCreateSkillsAction({
      development_plan_id: developmentPlanId,
      description_spa: description,
    }).then(() => {
      getSuccess('Acción de desarrollo creada correctamente')
    }).catch((error) => {
      getError(error.message)
    })
  }

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Nueva acción de desarrollo</h2>
      </TitleDiv>
      <Formik
        validateOnChange={true}
        validationSchema={newActionSchema}
        onSubmit={(values, { resetForm }) => {
          if (!isUploading) {
            setIsUploading(true)
            setButtonDisabled(true)
            onSubmit(values.description)
            setTimeout(() => {
              setButtonDisabled(false)
              navigate(BACK_PAGE)
              resetForm()
              setIsUploading(false)
            }, 2000)
          }
        }} initialValues={{
          description: '',
        }}

      >{({ values, handleSubmit, isSubmitting, setFieldValue, errors }) => (
        <>
          <TextArea placeholder="Descripción" onChange={({ target }) => setFieldValue('description', target.value)} value={values.description} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 35,
              marginTop: 40,
              alignItems: 'baseline',
            }}
          >
            <span style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }} onClick={() => { navigate(BACK_PAGE) }} >Cancelar</span>

            <Button style={{
              background: ' #C6016B',
              width: 162,
              height: 52,
              padding: '0 20px',
              borderRadius: 8,
              color: '#fff',
              border: 'none',
            }} disabled={isSubmitting || Object.keys(errors).length > 0 || buttonDisabled} type="submit" onClick={handleSubmit}>
              Guardar
            </Button>
          </div>
        </>
      )}
      </Formik>

    </MainLayout>
  )
}
export default CreateAction
