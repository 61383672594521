import styled from 'styled-components'

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
`

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px 20px;
`

export const ModalContent = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  min-width: 300px;
  max-width: 80%;
  min-height: 200px;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
`
