import axios from 'axios'
import { BetesaScore } from 'types/common'

type Answer = {
  answer: string
  score: number
}
interface IItemDetailResult {
  chapter_name: string
  scores: BetesaScore
}

interface IFindUserResponse {
  status: string
  data: {
    betesa_user_detail: {
      user_name: string
      user_id: number
      user_test_id: number
      age: {
        years: number
        months: number
        days: number
      }
      company_name: string
      document_type_name: string
      document: string
      domination: string
      type_process_id: number
    }
    betesa_result_detail: {
      chapters: IItemDetailResult[]
      stressors_answers: Answer[]
      self_perception_scores: BetesaScore[]
      current_total_sumary: BetesaScore
    },
    self_perception_items: string[],
  }
}

/**
 * @description service to get one result by id of test
 * @param token
 */
const getOneResultBetesa = async (token: string, id: number) => {
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/uninterpreted_betesa/${id}`

  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  // post get
  const response: IFindUserResponse = await axios
    .get(apiUrl, config)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.error('error on get all uninterpreted betesa', err)
      return err
    })
  return response
}

export default getOneResultBetesa
