import styled from 'styled-components';


interface IContainer {
    margin?: string;
    bgColor?: string;
    width?: string;
}

export const Container = styled.div<IContainer>`
    display: flex;
    flex-direction: row;
    width: 360px;
    height: 106px;
    background-color: ${(props) => props.bgColor || '#FFFFFF'};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
`;


export const ImageContainer = styled.div<IContainer>`
    width: 120px;
    height: 100%;
    background-color: ${(props) => props.bgColor || '#81ACC4'};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IImage {
    disabled?: boolean;
}

export const Image = styled.img<IImage>`
    width: 85%;
    height: 85%;
    // filter gray
    filter: ${(props) => props.disabled ? 'grayscale(100%)' : 'grayscale(0%)'};
`;

export const TextContainer = styled.div`
    width: 70%;
    height: 100%;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #FBFBFB;
`;

export const TitleContainer = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const  Title = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin: 0px;
`;


export const ActionContainer = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
