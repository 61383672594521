import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNotification } from 'lib/context/notification.context'
import { userSelector } from 'ducks/user'
import { getTestTypes } from 'ducks/createProcess'
import getUsers from 'views/screens/Platform-administrator/services'
import { IUser } from 'types/common'
import _ from 'lodash'

interface SearchFilters {
  documentFilter?: string
  documentsArray?: string[]
  nameFilter?: string
  emailFilter?: string
  processId?: number
  userId?: number
  page?: string
  limit?: string
  status?: boolean
  disableFilterByCompany?: boolean
  companyId?: number
  role?: number[]
  processFinished?: boolean
}

interface Pagination {
  total_pages: number
  total_results: number
}

export const useSearchUsers = () => {
  const [results, setResults] = useState<IUser[]>([])
  const [paginationUsers, setPagination] = useState({ total_pages: 0, total_results: 0 } as Pagination)
  const { companyId: companyIdUser } = useSelector(userSelector)
  const testTypes = useSelector(getTestTypes)
  const { getWarning } = useNotification()

  const search = ({
    documentFilter,
    nameFilter,
    emailFilter,
    userId,
    processId,
    page,
    limit,
    status,
    disableFilterByCompany,
    documentsArray,
    companyId,
    role,
    processFinished,
  }: SearchFilters) => {
    if (
      !documentFilter &&
      !nameFilter &&
      !emailFilter &&
      !page &&
      !limit &&
      !userId &&
      !processId &&
      !status &&
      !disableFilterByCompany &&
      !documentsArray &&
      !companyId &&
      !role
    ) {
      getWarning('Debe ingresar al menos un filtro de búsqueda')
      return
    } else {
      let filters: any = {
        statusInfo: status === false ? false : true,
      }

      if (!disableFilterByCompany) {
        filters = { ...filters, companyId: companyIdUser }
      }

      if (companyId) {
        filters = { ...filters, companyId: companyId }
      }

      if (role) {
        filters = { ...filters, role }
      }

      if (documentFilter) {
        filters = { ...filters, document: documentFilter }
      }

      if (documentsArray) {
        filters = { ...filters, documentsArray }
      }

      if (nameFilter) {
        filters = { ...filters, search: nameFilter }
      }

      if (emailFilter) {
        filters = { ...filters, email: emailFilter }
      }

      if (userId) {
        filters = { ...filters, userId: userId }
      }

      if (processId) {
        filters = { ...filters, process_id: processId }
      }

      if (processFinished === false) {
        // filters = { ...filters, sent_tests: processFinished }

      }

      if (page) {
        filters = { ...filters, page: page }
      }

      if (limit) {
        filters = { ...filters, limit: limit }
      }

      getUsers(filters).then((response) => {
        if (response.status === 'OK') {
          response.users = response.users.map((user: IUser) => {
            user.id = user.userId
            user.test_types = testTypes
            return user
          })

          // unique users by userId
          const filteredUniqUsers = (response.users = _.uniqBy(response.users, 'userId'))

          setResults(filteredUniqUsers)
          setPagination({ total_pages: response.total_pages, total_results: response.total_results })
        } else {
          setResults([])
          setPagination({ total_pages: 0, total_results: 0 })
          getWarning('No se encontraron usuarios con estos terminos de busqueda')
        }
      })
    }
  }

  function clearSearch() {
    setResults([])
  }

  return { results, search, paginationUsers, clearSearch }
}
