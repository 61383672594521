import styled from 'styled-components'

interface Props {
  disabled: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

export const ModalNewMapContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;
`

export const ModalNewMapText = styled.div`
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

export const BottomButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StandardButton = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c6016b;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonDisabled = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c2c2c2;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`

export const AddUsersButton = styled.div<Props>`
  display: flex;
  height: 11px;
  width: 30px;
  padding: 9px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: ${(props) => (props.disabled ? '1px solid #c2c2c2' : '1px solid #2d749c')};
  color: ${(props) => (props.disabled ? '#c2c2c2' : '#2d749c')};
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
`

export const UsersModalText = styled.div`
  color: #1f2124;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const UsersModalTitle = styled.div`
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 10px 16px;
  margin-top: 50px;
`

export const ResetButton = styled.div`
  margin-left: 30px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const TableButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 20px;
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const DeleteButtonIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`

export const DeleteButtonText = styled.div`
  color: #e44646;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`

export const UsersGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  // height: 100%;
  padding: 2% 1% 0 1%;
`

export const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const MagnifyingGlass = styled.img`
  width: 20px;
  height: 20px;
`

export const SearchButton = styled.div`
  display: flex;
  padding: 14px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const UsersContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 0%;
`

export const HistoryButton = styled.div`
  display: flex;
  height: 11px;
  width: 30px;
  padding: 9px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #2d749c;
  color: #2d749c;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 56px;
  border-radius: 0 8px 8px 0;
  background-color: #2d749c;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const Title = styled.div`
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
`

export const ModalDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const UsersButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
`
