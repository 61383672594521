import CartesianAxisBrainQuadrant from 'views/components/CartesianAxisBrainQuadrant'
import {
  BetesaBoldText,
  Container,
  AlertLevelText,
  ProfileIdeaInfoWrapper,
  ProfileIdealGraphWrapper,
  BoldText,
  List,
  ListItem,
  WrapperInfoCompetences,
  BetesaEditText,
} from './styles.styled'
import { InfoWrapper, NoBetesaContainer, Subtitle } from '../../styles.styled'
import { InfoText, Text, WrapperInfoItem } from '../GeneralInfo/styles.styled'
import { idealProfileBetesaSelector, idealProfileSkillsSelector, processDetailSelector } from '../../store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { platformAdminRoutes } from 'router/routes'
import { FC } from 'react'

interface TestInfoAndIdealProfileProps {
  processId: number
  processName: string
}

const TestInfoAndIdealProfile: FC<TestInfoAndIdealProfileProps> = ({ processId, processName }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const processDetail = useSelector(processDetailSelector)
  const hasRoleFamilyName = processDetail?.roleFamilyName !== null && processDetail?.roleFamilyName !== ''
  const hasCompetencesTestText = processDetail?.hasCompetencesTest ? t('global.common.skillText') : ''
  const hasBetesaTestText = processDetail?.hasBetesaTest ? 'Betesa' : ''
  const comaText = processDetail?.hasCompetencesTest && processDetail?.hasBetesaTest ? ', ' : ''
  const idealProfileBetesa = useSelector(idealProfileBetesaSelector)
  const idealProfileSkills = useSelector(idealProfileSkillsSelector)
  const formatIdealProfile = idealProfileBetesa.map((item) => {
    return {
      quadrant: item.quadrant,
      min: item.min,
      max: item.max,
      priority: item.priority,
    }
  })

  const handleEditProfileBetesaButtonClick = () => {
    const betesaTestId = processDetail?.testids?.find((item) => item.category_id === 1).test_id
    navigate(platformAdminRoutes.EditIdealProfileBetesa, {
      state: { idealProfileBetesa, betesaTestId, processId, processName },
    })
  }

  return (
    <Container>
      <Subtitle>Información de pruebas y perfiles ideales</Subtitle>
      <InfoWrapper>
        <WrapperInfoItem>
          <Text>Pruebas aplicadas:</Text>
          <Text>Fue creada con:</Text>
        </WrapperInfoItem>
        <WrapperInfoItem>
          <InfoText>
            {hasBetesaTestText}
            {comaText}
            {hasCompetencesTestText}
          </InfoText>
          {hasRoleFamilyName ? (
            <InfoText>{processDetail?.roleFamilyName}</InfoText>
          ) : (
            <InfoText>Configuración desde cero</InfoText>
          )}
        </WrapperInfoItem>
      </InfoWrapper>

      {processDetail?.hasBetesaTest && idealProfileBetesa.length > 0 ? (
        <>
          <InfoWrapper>
            <WrapperInfoItem>
              <BetesaBoldText>Perfil ideal Betesa:</BetesaBoldText>
            </WrapperInfoItem>
            <BetesaEditText onClick={handleEditProfileBetesaButtonClick}>Editar perfil ideal</BetesaEditText>
          </InfoWrapper>
          <ProfileIdeaInfoWrapper>
            <AlertLevelText>Nivel de alerta: {idealProfileBetesa[0].alert_level}</AlertLevelText>
            <ProfileIdealGraphWrapper>
              <CartesianAxisBrainQuadrant
                data={formatIdealProfile}
                isReport={false}
                style={{
                  height: '80%',
                  width: '100%',
                }}
              />
            </ProfileIdealGraphWrapper>
          </ProfileIdeaInfoWrapper>
        </>
      ) : processDetail?.hasBetesaTest ? (
        <NoBetesaContainer>
          <InfoWrapper>
            <WrapperInfoItem>
              <BoldText>Perfil ideal Betesa:</BoldText>
            </WrapperInfoItem>
          </InfoWrapper>
          <InfoText>No se configuró perfil ideal para este proceso</InfoText>
        </NoBetesaContainer>
      ) : null}

      {processDetail?.hasCompetencesTest && idealProfileSkills.length > 0 ? (
        <InfoWrapper>
          <WrapperInfoItem>
            <BoldText>Perfil ideal {t('global.common.skillText')}:</BoldText>
          </WrapperInfoItem>
          <WrapperInfoCompetences>
            <List>
              {idealProfileSkills.map((item) => (
                <ListItem key={item.skillName}>
                  {item.skillName}: Superior a {item.skillWeight}
                </ListItem>
              ))}
            </List>
          </WrapperInfoCompetences>
        </InfoWrapper>
      ) : processDetail?.hasCompetencesTest ? (
        <NoBetesaContainer>
          <InfoWrapper>
            <WrapperInfoItem>
              <BoldText>Perfil ideal {t('global.common.skillText')}:</BoldText>
            </WrapperInfoItem>
          </InfoWrapper>
          <InfoText>No se configuró perfil ideal para este proceso</InfoText>
        </NoBetesaContainer>
      ) : null}
    </Container>
  )
}

export default TestInfoAndIdealProfile
