import React, { useRef, useEffect } from 'react';

function RotatedImage({ src, category }:any) {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    function rotateImage(image:any, angle:any, callback:any) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return;
  
      const width = image.width;
      const height = image.height;
  
      angle = (angle * Math.PI) / 180; 
  
      canvas.width = width;
      canvas.height = height;
      context.save();
      context.translate(width / 2, height / 2);
      context.rotate(angle);
      context.drawImage(image, -width / 2, -height / 2, width, height);
      context.restore();
  
      canvas.toBlob((blob) => callback(blob));
    }

    if (!imgRef.current) return;

    const img = new Image();
    img.src = src;

    img.onload = function () {
      let rotationAngle;
      switch (category) {
        case 'FI':
          rotationAngle = 270;
          break;
        case 'BI':
          rotationAngle = 180;
          break;
        case 'BD':
          rotationAngle = 90;
          break;
        default:
          rotationAngle = 0;
          break;
      }

      rotateImage(img, rotationAngle, (rotatedImageBlob: Blob) => {
        if (!imgRef.current) return;
        const rotatedImageDataURL = URL.createObjectURL(rotatedImageBlob);
        imgRef.current.src = rotatedImageDataURL;
      });
    };
  }, [src, category]);

  return (
    <img ref={imgRef} alt="Dominance" />
  );
}

export default RotatedImage