import styled from 'styled-components';


export const BackButtonStyle = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  border-radius: 8px;
  background: none;
  border: none;
  color: #2D749C;
  font-size:14; 
  font-weight: 700;
  cursor: 'pointer';
  font-weight: bold;
  cursor: pointer;
`
