import styled from 'styled-components'

import imageBackground from '../../../assets/images/login-img.png'

export const Container = styled.div`
  width: 100%;
  height: 140vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const ContainerForm = styled.div`
  margin-top: 8rem;
  width: 50%;
  height: 100%;
  background-color: '#FAFCFF';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 4rem;
    overflow-y: scroll;
  }
`

export const ContainerImage = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${imageBackground});
  background-size: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  @media (max-width: 1024px) {
    display: none;
    width: 0;
  }
`
