import { AlertLevelOptionWrapper, Container, ErrorContainer, TextError, Title, TitleWrapper } from './styled'
import BrainQuadrant from 'views/components/BrainQuadrant'
import Select from 'views/components/UI/Select'
import { FieldArray } from 'formik'
import { ALERT_LEVELS } from 'types/common'

const ProfileInfo = ({ props }: any) => {
  const { handleChange, values, setFieldValue, errors } = props

  const alertLevelLabels = Object.keys(ALERT_LEVELS);

  const alertLevelOptions = Object.values(ALERT_LEVELS).map((value: number[], index) => {
    return {
      value: `${value}`,
      label: alertLevelLabels[index],
    };
  });

  return (
    <Container>
      <TitleWrapper>
        <Title>Perfil ideal Betesa</Title>
      </TitleWrapper>

      <AlertLevelOptionWrapper>
        <label style={{ fontWeight: 'bold', marginBottom: 10 }} >
          Selecciona nivel de alerta
        </label>
        <Select
          style={{ width: '50%' }}
          label="Nivel de alerta"
          name="alert_level_range"
          options={alertLevelOptions}
          hideNeverChoice
          onChange={handleChange('ideal_profile_data.alert_level_id')}
          value={values?.ideal_profile_data?.alert_level_id}
        />
        {(errors?.ideal_profile_data?.alert_level_range) && (
          <ErrorContainer>
            <TextError>{errors.ideal_profile_data?.alert_level_range}</TextError>
          </ErrorContainer>
        )}
      </AlertLevelOptionWrapper>

      <FieldArray name="ideal_profile_data">
        {() => (
          <BrainQuadrant setFieldValue={setFieldValue} values={values} />
        )}
      </FieldArray>


    </Container>

  )
}

export default ProfileInfo
