import styled from 'styled-components'

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 0 !important;
  @media (max-width: 768px) {
    margin-top:20px;
    height: max-content;
  }
  @media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
      padding-top:20%
  }
  @media only screen 
    and (device-width: 375px) 
    and (device-height: 667px) 
    and (-webkit-device-pixel-ratio: 2) {
      height: 100vh;
    }
  @media only screen
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
      height: 100vh;
    }

  @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      height: 100vh;
    } 
  @media only screen 
    and (width: 390px) 
    and (height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
      height: 100vh;
      
     }
  @media only screen  
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 
      height: 100vh;
    }
`