import styled from 'styled-components';


const Container = styled.div`
    cursor: pointer;
`;

interface IProps {
    width?: string;
    height?: string;
    onClick?: () => void;
    color?: string;
}

const RectangleSkew = (props: IProps) => {
    const { width, height, onClick, color } = props;

    return (
        <Container onClick={onClick}>
           <svg width={width || '41px'} height={height || '39px'} viewBox="0 0 41 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.1839 0.980469L6.64295 4.0125L1 35.7647L39.49 38.2913L34.1839 0.980469Z" stroke={color || "rgb(45, 116, 156)" } strokeWidth="1.21648"/>
           </svg>
        </Container>
    );
};

export default RectangleSkew;



