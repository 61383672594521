import styled from 'styled-components'

export const LinkDiv = styled.div`
  display:flex; 
  flex-direction:row; 
  justify-content:center; 
  margin-bottom:30px;
  margin-top:36px; 
  align-items: center;
`

const ViewMoreDiv = ({children}:any): JSX.Element => {
  return (
        <LinkDiv>
            {children}
          </LinkDiv>
  )}
export default ViewMoreDiv

