import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import Modal from 'views/components/UI/Modal'
import { ModalContainer, ModalHeader, RowEnd, GridModal, ModalBody, Form } from './styled';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Input from 'views/components/UI/TextInput'
import { Button } from 'views/components/UI/Buttons';
import Select from 'views/components/UI/Select'
import { useNotification } from 'lib/context/notification.context';
import Loader from 'views/components/UI/Loader/Loader';
import { generalDataSelector } from 'ducks/auth';
import {  userSelector } from 'ducks/user';
import useCreateUser from 'hooks/users/useCreateUser/useCreateUser';
import { UserDataCreateUser } from 'types/common';
import { PLATFORM_ADMIN_ROLE_ID } from 'constants/global.constants';
import { validationSchema, ModalAddAdminProps } from './constants';

const Error = styled.p`
    color: red;
    font-size: 12px;
    margin: -18px 0px -12px 6px;
`;

const ModalAddAdmin = (props: ModalAddAdminProps) => {
    const { isOpen, setOpenModal, onCreateSuccess } = props;
    const { companyId } = useSelector(userSelector);
    const { getError, getSuccess, getWarning } = useNotification();
    const { data:generalData } = useSelector(generalDataSelector)
    const { createUser, loadingCreateUser} = useCreateUser();
    const [documenTypeOptions, setDocumentTypeOptions] = useState<any>([]);

    useEffect(() => {
        if (generalData?.document_type) {
            const documentTypes = generalData.document_type.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name 
                }
            })
            setDocumentTypeOptions(documentTypes)
        }
        
    }, [generalData?.document_type])

    const formik = useFormik({
        initialValues: {
          name: '',
          lastName: '',
          mail: '',
          documentType: '',
          identification: '',
        },
        validationSchema,
        onSubmit: (values) => {
            const userInfo: UserDataCreateUser = {
                firstName: values.name,
                lastName: values.lastName,
                document: values.identification,
                email: values.mail,
                documentType: values.documentType,
                roleType: PLATFORM_ADMIN_ROLE_ID,
                companyId: companyId,
            }
           
         createUser(userInfo).then((responseCreateUser) => {
            if (responseCreateUser.status === 'success') {
                getSuccess('Usuario creado correctamente')
                formik.resetForm();
                onCreateSuccess();
            } else {
              getError(`Error al crear el usuario: ${responseCreateUser?.message}`)
            }}).catch((error) => {
                getError(`Error al crear el usuario: ${error}`)
            })
        }
      });
      
    return(
        <Modal
            hideCloseButton={true}
            show={isOpen}
            setShow={setOpenModal}
            styleContent={{
            width: '550px',
            height: '100%',
            borderRadius: '10px',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#FFFFFF',
            }}
      >
        <ModalContainer>
          <ModalHeader>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenModal(false)
              }}
            >
              <CloseOutlinedIcon />
            </div>
          </ModalHeader>
            {
                !loadingCreateUser ? (
                    <ModalBody>
                        <h3>Agregar administrador</h3>
                        <GridModal>
                        <Form onSubmit={formik.handleSubmit}>
                            <p style={{ width: '100%', marginTop: 0 }}>Ingresa el correo electrónico del administrador a agregar</p>
                            <Input 
                                name="name" 
                                value={formik.values.name} 
                                label="Nombre" type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.name ? formik.errors.name : ""}
                            />
                            <Input 
                                name="lastName" 
                                value={formik.values.lastName} 
                                label="Apellido" 
                                type="text" 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.lastName ? formik.errors.lastName : ""}
                                />
                            <Input
                                name="mail"
                                value={formik.values.mail}
                                label="Correo electrónico"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.mail ? formik.errors.mail : ""}
                            />
                            <Select
                                width="98%"
                                name="documentType"
                                options={documenTypeOptions}
                                value={formik.values.documentType}
                                label="Tipo de documento"
                                onChange={(e) =>formik.setFieldValue('documentType', e)}
                                hideNeverChoice
                            />
                            { formik.touched.documentType && formik.errors.documentType && <Error>{formik.errors.documentType}</Error>}
                            <Input
                                name="identification"
                                value={formik.values.identification}
                                label="Numero de documento"
                                type="text"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={formik.touched.identification ? formik.errors.identification : ""}
                            />
                            <RowEnd>
                            <Button     
                                onClick={formik.handleSubmit} 
                                disabled={formik.isSubmitting || Object.keys(formik.errors).length > 0}
                                onDisabledClick={() => getWarning('Debe completar todos los campos')}
                                margin='16px 0px'
                            >Agregar</Button>
                            </RowEnd>
                        </Form>
                        </GridModal>
                    </ModalBody>
                ) : (
                    <>
                        <Loader />
                        <p>Creando el usuario...</p>
                    </>
                )
            }
        </ModalContainer>
      </Modal>
    )

}

export default ModalAddAdmin;