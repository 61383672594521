import axios from 'axios'

const apiBase = `${process.env.REACT_APP_MAIN_API}/company/user`

/**
 * @description service to find user
 * @param token
 */

export type ICompanies = {
    id: number
    name: string
}


interface IFindUserResponse  {
    status: string
    companies: ICompanies[]
    total_pages:number
}




const getAllCompaniesById = async ( page = 1, limit = 10, search?: string) => {



    let apiUrl = `${apiBase}` // api with id super admin

    if (page && limit) {
        apiUrl = `${apiUrl}?page=${page}&limit=${limit || 10}`
    }
    if(search){
      apiUrl = `${apiUrl}&search=${search}`
    }
    const response: IFindUserResponse = await axios.get(apiUrl).then((res) => {
      return res.data
    }).catch((error) => {
      return { ok: false, message: error.message };
    })
    return response
}

export default getAllCompaniesById
