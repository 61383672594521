import styled from 'styled-components';

interface IContainerProps {
    isChecked: boolean;
}

export const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: row;
    width: 360px;
    height: 106px;
    background-color: #FFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    border: ${props => props.isChecked ? '1px solid #387394;' : 'none'};
    margin-right: 20px;

    &:hover {
        transform: scale(1.01);
        transition: 0.3s;
    }
`;

export const ImageContainer = styled.div`
    width: 30%;
    height: 100%;
    background-color: #81ACC4;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Image = styled.img`
    width: 90%;
    height: 90%;
`;

export const TextContainer = styled.div`
    width: 70%;
    height: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #FBFBFB;
`;

export const TitleContainer = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const ActionContainer = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
`;

export const CheckCircle = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #C86199;
    display: flex;
    justify-content: center;
    align-items: center;
`;
