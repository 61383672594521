import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
// components
import { MainLayout } from 'views/layouts'
import {
  Container,
  CompanyCard,
  FormContainer,
  FormContainerItem,
  IconSvgProfile,
  LabelDiv,
  Label,
  TextError,
  LoaderContainer,
} from './styled'
import Input from '../../../components/UI/TextInput'
import { P } from '../../../components/UI/Text'
import { Button } from 'views/components/UI/Buttons'
import Select from 'views/components/UI/Select'
import RoundedCkeck from 'views/components/UI/InputRadio'
import LoopIcon from '@mui/icons-material/Loop'
import Loader from 'views/components/UI/Loader'
// selectors
import { generalDataSelector } from 'ducks/auth'
// services
import editCompany from 'services/companies/editCompany'
import getOneCompany from 'services/companies/getOneCompany'
// types
import { IListAdmin } from 'types/common'
import { useNotification } from 'lib/context/notification.context'
import { MAX_FILE_SIZE_LOGO } from 'constants/global.constants'

type IGetCompany = {
  name: string
  operative: string
  contactName: string
  contactEmail: string
  phone: string
  logo: string
  typeCompany: string
  typeCompanyId: number
}
const EditCompany: FC = () => {
  const navigate = useNavigate()
  const { getError, getSuccess } = useNotification()
  const { data: generalData } = useSelector(generalDataSelector) // general data
  const listAdmin = generalData?.list_admin

  // local state
  const [params, setParams] = useState<{ companyId?: any }>({})
  const [idCompany, setIdCompany] = useState<number>()

  const [operatives, setOperatives] = useState<IListAdmin[] | []>([]) // list of operatives
  const [imageBlob, setImageBlob] = useState<any>(null)
  const [hasIntegration, setHasIntegration] = useState<string>('')
  const [companiesData, setCompaniesData] = useState<IGetCompany>({
    name: '',
    operative: '',
    contactName: '',
    contactEmail: '',
    phone: '',
    logo: '',
    typeCompany: '',
    typeCompanyId: 0,
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setParams(Object.fromEntries(searchParams))

    if (listAdmin) setOperatives(listAdmin)
  }, [listAdmin])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      const companyId = parseInt(params.companyId)
      setIdCompany(companyId)
    }
  }, [params])

  useEffect(() => {
    if (idCompany) {
      getOneCompany(idCompany).then((res: any) => {
        if (res.status === 'OK' || res.status === 'success') {
          setCompaniesData({
            name: res?.data.name,
            operative: res?.data.idOperativo,
            contactName: res?.data.contact_name,
            contactEmail: res?.data.contact_email,
            phone: res.data.phone,
            logo: res.data.logo,
            typeCompany: res.data.caracter,
            typeCompanyId: res.data.idCaracter,
          })
          setHasIntegration(res.data.allow_integration === true ? 'true' : 'false')
        } else {
          console.error('Error creating super admin')
        }
      })
    }
  }, [idCompany])

  type ICompany = {
    id: number
    name: string
    operativeId: number
    contactName: string
    contactEmail: string
    phone: string
    typeCompanyId: number
    logo?: Blob | null
    hasIntegration: string
  }

  const handleImage = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      if (file.size > MAX_FILE_SIZE_LOGO) { 
        getError('El tamaño del logo no debe exceder los 600 kilobytes.')
        return
      } else {
        setImageBlob(file)

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
          setCompaniesData({ ...companiesData, logo: reader.result as string })
        }
      }
    }

  }

  function handleEditCompany(values: ICompany): any {
    if (imageBlob !== null) {
      values.logo = imageBlob
    }

    values.typeCompanyId = companiesData.typeCompanyId
    values.operativeId = parseInt(companiesData.operative)
    values.hasIntegration = hasIntegration
    if (idCompany) {
      const dataCompany = {
        ...values,
      }

      editCompany(dataCompany, idCompany)
        .then((res) => {
          if (res.status === 'OK' || res.status === 'success') {
            getSuccess('Compañia modificada')
            navigate('/companies')
          } else {
            console.error('error editing company ')
            getError('No fue posible modificar la compañia')
          }
        })
        .catch((err) => {
          console.error('error editing company ', err)
        })
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('El nombre de la empresa es obligatorio'),
    contactName: Yup.string().required('El nombre de contacto es obligatorio'),
    contactEmail: Yup.string().email('El email ingresado no es válido').required('El email de contacto es obligatorio'),
    phone: Yup.string()
      .required('El teléfono es obligatorio')
      .matches(/^[0-9+]+$/, 'El teléfono debe contener solo números o "+", al inicio ')
      .min(7, 'El teléfono debe tener al menos 7 caracteres')
      .max(16, 'El teléfono debe tener máximo 16 caracteres'),
    hasIntegration: Yup.string().required('El campo es obligatorio'),
  })

  return (
    <MainLayout>
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 35,
            alignItems: 'center',
          }}
        >
          {companiesData.name !== '' ? <h2 style={{ fontSize: 30 }}>Información - {companiesData.name}</h2> : null}
          {companiesData.operative !== '' && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '30%', gap: 10 }}>
              Operativo:
              <Select
                label="Operativo"
                options={
                  operatives.length > 0
                    ? operatives.map((item: any) => ({
                        value: item.id,
                        label: `${item.first_name + ' '}${item.last_name}`,
                      }))
                    : []
                }
                value={companiesData.operative || ''}
                hideNeverChoice
                onChange={(e) => {
                  setCompaniesData({ ...companiesData, operative: e })
                }}
              />
            </div>
          )}
        </div>

        <CompanyCard>
          {companiesData.name !== '' ? (
            <Formik
              initialValues={{
                name: companiesData.name,
                contactName: companiesData.contactName,
                contactEmail: companiesData.contactEmail,
                phone: companiesData.phone,
                hasIntegration: hasIntegration,
              }}
              validationSchema={validationSchema}
              onSubmit={(values: any) => {
                handleEditCompany(values)
              }}
            >
              {({ errors, touched, values, handleChange, handleSubmit }) => (
                <Form>
                  <FormContainer>
                    <div
                      style={{
                        height: '200px',
                        width: 200,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={companiesData ? companiesData.logo : ''}
                        style={{ width: 200, height: 200, objectFit: 'contain' }}
                        alt="company logo"
                      />
                      <div
                        style={{
                          cursor: 'pointer',
                          marginBottom: '40px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <label
                          htmlFor="photo"
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginTop: '8px' }}
                          title="Cambiar imagen"
                        >
                          <span style={{ color: '#00A6FF', whiteSpace: 'nowrap' }}>Cambiar imagen</span>
                          <LoopIcon style={{ color: '#00A6FF', marginLeft: '5px' }} />
                          <input
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              width: 200,
                              height: 70,
                              cursor: 'pointer',
                              top: 0,
                              left: 0,
                              zIndex: 1,
                            }}
                            type="file"
                            id="photo"
                            name="photo"
                            accept="image/png, image/jpeg"
                            onChange={(e) => handleImage(e)}
                            value=""
                          />
                        </label>
                      </div>
                    </div>
                    <FormContainerItem>
                      <LabelDiv style={{ marginRight: 40 }}>
                        <IconSvgProfile xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                          <path d="M16 4h8v19h-24v-19h8v-2c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v2zm7 1h-22v17h22v-17zm-3 4v1h-16v-1h16zm-5-6.5c0-.133-.053-.26-.146-.354-.094-.093-.221-.146-.354-.146h-5c-.133 0-.26.053-.354.146-.093.094-.146.221-.146.354v1.5h6v-1.5z" />
                        </IconSvgProfile>
                        <Label>Nombre</Label>
                      </LabelDiv>
                      <Input
                        value={values.name}
                        onChange={(e) => handleChange('name')(e.target.value)}
                        type="text"
                        name="name"
                        label="Nombre de la empresa"
                        style={{ width: '60%' }}
                      />
                    </FormContainerItem>
                    {errors.name && touched.name ? (
                      <div
                        style={{
                          width: '70%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '-8px',
                          marginBottom: '20px',
                        }}
                      >
                        <TextError>{errors.name.toLocaleString()}</TextError>
                      </div>
                    ) : null}
                    <FormContainerItem>
                      <LabelDiv style={{ marginRight: 30 }}>
                        <IconSvgProfile xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                          <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                        </IconSvgProfile>
                        <Label>Contacto</Label>
                      </LabelDiv>
                      <Input
                        value={values.contactName}
                        type="text"
                        onChange={(e) => handleChange('contactName')(e.target.value)}
                        name="name-company"
                        label="Nombre del contacto"
                        style={{ width: '60%' }}
                      />
                      {errors.contactName && touched.contactName ? (
                        <div
                          style={{
                            width: '70%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '-8px',
                            marginBottom: '20px',
                          }}
                        >
                          <TextError>{errors.contactName as string}</TextError>
                        </div>
                      ) : null}
                    </FormContainerItem>
                    <FormContainerItem>
                      <LabelDiv style={{ marginRight: 60 }}>
                        <IconSvgProfile xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                          <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                        </IconSvgProfile>
                        <Label>Email</Label>
                      </LabelDiv>
                      <Input
                        value={values.contactEmail}
                        onChange={(e) => handleChange('contactEmail')(e.target.value)}
                        type="email"
                        name="email"
                        label="Email"
                        style={{ width: '60%' }}
                      />
                    </FormContainerItem>
                    {errors.contactEmail && touched.contactEmail ? (
                      <div
                        style={{
                          width: '70%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '-8px',
                          marginBottom: '20px',
                        }}
                      >
                        <TextError>{errors.contactEmail as string}</TextError>
                      </div>
                    ) : null}
                    <FormContainerItem>
                      <LabelDiv style={{ marginRight: 35 }}>
                        <IconSvgProfile xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                          <path d="M18 24h-12c-1.104 0-2-.896-2-2v-20c0-1.104.896-2 2-2h12c1.104 0 2 .896 2 2v20c0 1.104-.896 2-2 2zm1-5.083h-14v3.083c0 .552.449 1 1 1h12c.552 0 1-.448 1-1v-3.083zm-7 3c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm7-17h-14v13h14v-13zm-1-3.917h-12c-.551 0-1 .449-1 1v1.917h14v-1.917c0-.551-.448-1-1-1zm-4.5 1.917h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5s-.224.5-.5.5z" />
                        </IconSvgProfile>
                        <Label htmlFor="">Teléfono</Label>
                      </LabelDiv>
                      <Input
                        value={values.phone}
                        onChange={(e) => handleChange('phone')(e.target.value)}
                        type="tel"
                        name="phone"
                        label="Teléfono"
                        style={{ width: '60%' }}
                        pattern="[0-9\+\-]*"
                      />
                    </FormContainerItem>
                    {errors.phone && touched.phone ? (
                      <div
                        style={{
                          width: '70%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '-8px',
                          marginBottom: '20px',
                        }}
                      >
                        <TextError>{errors.phone as string}</TextError>
                      </div>
                    ) : null}
                    <FormContainerItem>
                      <LabelDiv style={{ marginLeft: '10px' }}>Carácter</LabelDiv>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <RoundedCkeck
                          label="Corporativo"
                          value={companiesData.typeCompany}
                          checked={companiesData.typeCompanyId === 1}
                          onChange={() => setCompaniesData({ ...companiesData, typeCompanyId: 1 })}
                        />
                        <RoundedCkeck
                          label="Vocacional"
                          value={companiesData.typeCompany}
                          checked={companiesData.typeCompanyId === 2}
                          onChange={() => setCompaniesData({ ...companiesData, typeCompanyId: 2 })}
                        />
                      </div>
                    </FormContainerItem>
                    {/* <FormContainerItem>
                      <LabelDiv style={{ marginLeft: '10px' }}>Integración</LabelDiv>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <RoundedCkeck
                          label="Si"
                          value={hasIntegration}
                          checked={hasIntegration === 'true'}
                          onChange={() => setHasIntegration('true')}
                        />
                        <RoundedCkeck
                          label="No"
                          value={hasIntegration}
                          checked={hasIntegration === 'false'}
                          onChange={() => setHasIntegration('false')}
                        />
                      </div>
                    </FormContainerItem> */}
                  </FormContainer>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: 30,
                      alignItems: 'center',
                    }}
                  >
                    <P
                      onClick={() => {
                        navigate(-1)
                      }}
                      style={{ color: '#2D749C', fontSize: 14, fontWeight: 700, cursor: 'pointer' }}
                    >
                      Cancelar
                    </P>
                    <Button type="submit" onClick={() => handleSubmit()}>
                      Guardar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </CompanyCard>
      </Container>
    </MainLayout>
  )
}

export default EditCompany
