import { useState, useCallback, useEffect } from 'react';
import MainLayout from 'views/layouts/Main';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, StepsContainer, ConfigsContainer, ButtonsContainer } from './styled';
import { H2 } from 'views/components/UI/Text';
import Steps from 'views/components/Stepper'
import { Button, BackButton, StrokedButton } from 'views/components/UI/Buttons';
import BrainQuadrant from 'views/containers/BrainQuadrant';
import Tooltip from 'views/components/Tooltip'
import Select from 'views/components/UI/Select';
import { STEPS_NEW_PROCESS } from '../constants';
import { platformAdminRoutes } from 'router/routes';
import { useNotification } from 'lib/context/notification.context';
import { stepOneSelector, stepThreeSelector, stepFourSelector, setStepFourTrigger, getBetesaSkillIds, setStepFour } from 'ducks/createProcess';
import { ALERT_LEVELS, BetesaIdealProfile, BetesaStepFourData } from 'types/common';
import { NEW_PROCESS_TRUE } from 'constants/global.constants';

function transformToNumberArray(input: string): number[] {
  const stringArray = input.replace(/,$/, "").split(",");
  const numberArray = stringArray.map((str) => parseInt(str, 10));
  return numberArray;
}

function validateProfileValues(profileValues: any[]): boolean {
  for (const profile of profileValues) {
    if (profile.min < 10 || profile.max < 20) {
      return false;
    }
  }

  return true;
}

function IdealProfileBetesa() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getSuccess, getWarning } = useNotification();
  const location = useLocation();

  const priorityQuadrant = location.state?.priorityQuadrant || '' as string;
  const stepOneData = useSelector(stepOneSelector);
  const stepThreeData = useSelector(stepThreeSelector);
  const dataStepFour = useSelector(stepFourSelector);
  const betesaSkills = useSelector(getBetesaSkillIds)

  const alertLevelLabels = Object.keys(ALERT_LEVELS);

  const alertLevelOptions = Object.values(ALERT_LEVELS).map((value: number[], index) => {
    return {
      value: `${value}`,
      label: alertLevelLabels[index],
    };
  });
  const [alertLevel, setAlertLevel] = useState<number[] | string>([])
  const [betesaIdealProfile, setBetesaIdealProfile] = useState<BetesaIdealProfile[]>(dataStepFour?.betesaIdealProfile?.profileValues || [])

  const useSkillsTest = stepThreeData.useSkillsTest

  const handleNextStep = useCallback(() => {
    if (!alertLevel) {
      getSuccess('Debes seleccionar un nivel de alerta')
      return
    }

    if (!validateProfileValues(betesaIdealProfile)) {
      getSuccess('El valor minimo de cada cuadrante debe ser 10 y el maximo debe ser 20')
      return
    }

    const alertLevelArray = typeof alertLevel === 'string' ? transformToNumberArray(alertLevel as string) : alertLevel

    const dataStepFourToSave = {
      processId: stepOneData.processId,
      betesaIdealProfile: {
        betesaSkills,
        alertLevelId: alertLevelArray || [],
        profileValues: betesaIdealProfile
      },
      isNewProcess: NEW_PROCESS_TRUE
    }

    try {
      if (useSkillsTest) {
        dispatch(setStepFour(dataStepFourToSave));
        dispatch(setStepFourTrigger.run(dataStepFourToSave));
        getSuccess('Perfil ideal de betesa guardado correctamente')
      } else {
        dispatch(setStepFourTrigger.run(dataStepFourToSave));
      }
    } catch (error) {
      console.error('error saving the skills and ideal profile', error)
    } finally {
      if (useSkillsTest) {
        navigate(platformAdminRoutes.ProcessSkills)
      } else {
        navigate(platformAdminRoutes.CreateUsers)
      }
    }
  }, [dispatch, navigate, useSkillsTest, betesaSkills, alertLevel, betesaIdealProfile, stepOneData.processId, getSuccess]);

  const handleSkipStep = useCallback(() => {

    const dataStepFourToSave = {
      processId: stepOneData.processId,
      betesaIdealProfile: {
        betesaSkills: [],
        alertLevelId: [],
        profileValues: []
      },
    }

    if (useSkillsTest) {
      dispatch(setStepFour(dataStepFourToSave));
      dispatch(setStepFourTrigger.run(dataStepFourToSave));
      navigate(platformAdminRoutes.ProcessSkills)
    } else {
      dispatch(setStepFourTrigger.run(dataStepFourToSave));
      setTimeout(() => {
        navigate(platformAdminRoutes.CreateUsers)
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, useSkillsTest, stepOneData.processId, dispatch]);


  useEffect(() => {
    const betesaIdealProfileStore: BetesaStepFourData = dataStepFour?.betesaIdealProfile as BetesaStepFourData;

    if (betesaIdealProfileStore) {
      const alertLevelStore = betesaIdealProfileStore?.alertLevelId
      const profileValues = betesaIdealProfileStore?.profileValues
      setAlertLevel(alertLevelStore)
      setBetesaIdealProfile(profileValues)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <Container>
        <H2>Determina el Perfil ideal para Betesa</H2>
        <StepsContainer>
          <Steps steps={STEPS_NEW_PROCESS} activeStep={3} />
        </StepsContainer>
        <ConfigsContainer>
          <div style={{ width: '30%', marginLeft: '-10px' }}>
            <Select
              label='Nivel de alerta'
              options={alertLevelOptions as any}
              value={alertLevel}
              onChange={(e) => {
                setAlertLevel(e as any);
              }}
              hideNeverChoice
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Tooltip
              tooltipText='Si decides saltar este paso no podrás utilizar 9Box para este proceso'
              marginTop='8px'
              style={{
                marginRight: '-12px',
                marginBottom: '0px',
                transform: 'scale(1.2)',
              }}
            />
            <StrokedButton type='button' onClick={handleSkipStep}>Saltar este paso</StrokedButton>
          </div>
        </ConfigsContainer>
        <BrainQuadrant
          defaultQuadrant={priorityQuadrant || ''}
          onChange={e => setBetesaIdealProfile(e)}
          quadrantData={betesaIdealProfile}
        />
        <ButtonsContainer>
          <Button disabled={(!alertLevel?.length || betesaIdealProfile?.length === 0) || !validateProfileValues(betesaIdealProfile)} type='submit' onClick={handleNextStep} onDisabledClick={() => {
            if (!alertLevel?.length) {
              getWarning('Debes seleccionar un nivel de alerta')
            }
            if (betesaIdealProfile?.length === 0) {
              getWarning('Debes seleccionar un perfil ideal')
            }

            if (!validateProfileValues(betesaIdealProfile)) {
              getWarning('El valor mínimo de cada cuadrante debe ser 10 y el mínimo del máximo debe ser 20')
            }

          }}>{useSkillsTest ? 'Configurar siguiente prueba' : 'Agregar usuarios'}</Button>
          <BackButton onClick={() => navigate(platformAdminRoutes.HomologationSkills, { state: { isTurnBack: true } })} />
        </ButtonsContainer>
      </Container>
    </MainLayout>
  );
}

export default IdealProfileBetesa;
