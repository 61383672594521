/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import { SelectStyled } from './styled'
import { Checkbox, ListItemText } from '@mui/material'

type IItemOption = {
  value: string | number
  label: string
}

interface IsSelectProps {
  label: string
  name?: string
  error?: string | undefined
  touched?: any
  value: string | number | null | number[] | string[] | undefined
  defaultValue?: string | number | null
  onChange?: (value: string) => void
  width?: string
  options: IItemOption[] | [] | number[]
  style?: React.CSSProperties
  onBlur?: () => void
  showLabel?: boolean
  changeById?: boolean
  disabled?: boolean
  hideNeverChoice?: boolean
  multiple?: boolean
  checkBox?: boolean
  arrayToCheck?: string[]
  onClick?: () => void
}

export default function Select(props: IsSelectProps) {
  const {
    label,
    name,
    error,
    touched,
    value,
    options,
    onChange,
    defaultValue,
    onBlur,
    width,
    disabled,
    hideNeverChoice,
    multiple,
    checkBox,
    arrayToCheck,
    onClick
  } = props

  const optionsWithDefault = [{ value: defaultValue, label: defaultValue }, ...options]
  const allOptions: any = defaultValue ? optionsWithDefault : options
  const handleSelectClick = () => {
    if (onClick) {
      onClick();
    }
  }
  return (
    <>
      <FormControl style={{ width: width || '100%' }} onClick={handleSelectClick}>
        <InputLabel id="select-label">{label || 'Inserte label'}</InputLabel>
        <SelectStyled
          disabled={disabled === true ? true : false}
          labelId="main-select-label"
          id="main-select"
          multiple={multiple}
          value={value}
          name={name}
          label={label || 'Inserte label'}
          sx={{ width: '100%', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange && onChange(e.target.value)}
          onBlur={onBlur}
        >
          {!hideNeverChoice && (
            <MenuItem key="all" value="0">
              <em>Todos</em>
            </MenuItem>
          )}
          {allOptions.map((option: any, index: number) => (
            <MenuItem key={index} value={option.value}>
              {checkBox ? (
                <>
                  <Checkbox checked={arrayToCheck && arrayToCheck.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </>
              ) : (
                option.label
              )}
            </MenuItem>
          ))}
        </SelectStyled>
        {error && <p style={{ color: 'red', fontSize: '12px', margin: '0px 10px' }}>{error}</p>}
      </FormControl>
    </>
  )
}
