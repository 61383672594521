import { AxiosResponse } from 'axios'
import axios from 'lib/axios'

interface Params {
  testId: number
  userId: number
}

interface ResultItem {
  overall_score: number
  user_test_id: number
  user_id: number
  skill_id: number
  skill_name: string
}

interface Response {
  status: string
  user_result: ResultItem[]
}

export const fetchResultsSkills = async ({ testId, userId }: Params) => {
  const PATH = `${process.env.REACT_APP_MAIN_API}/competence_result/user/${userId}/test/${testId}`

  try {
    const response: AxiosResponse<Response> = await axios.get(PATH)
    return response.data
  } catch (error) {
    throw new Error('Error fetching')
  }
}
