import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 350px;
  border-radius: 8px;
  height: 52px;
  width: 100%;
`

export const SearchButton = styled.button`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: #2d749c;
  color: white;
  padding: 0rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;
  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`
