import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container, DashboardContainer, Row, BlueContainer } from './styled'
import { H2, H4, P } from 'views/components/UI/Text'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import WhiteContainer from 'views/components/common/WhiteContainer'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import SearchBar from 'views/components/Searchbar/search-bar'
import { useNotification } from 'lib/context/notification.context'
import { ActionViewDownloadReportProcessCreated } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { platformAdminRoutes } from 'router/routes'
import { userSelector } from 'ducks/user'
import { useFetchGetResumeProcess, useFetchGetUsersByProcess } from 'hooks/process/useGetProcess/useGetProcess'
import { useGetReports } from 'hooks/process/useGetReports/useGetReports'
import sendReportToUser from 'services/reports/sendReportResultToUserEvaluated/sendReportToUser'
import AddUserIcon from 'assets/icons/AddUserIcon'
import { DEFAULT_LIMIT } from 'constants/global.constants'
import { UniqueUserIds, FilterGetUsersByProcess } from '../store'
import { HEADERS_USERS_WITH_TESTS_COMPLETED } from '../../Mocks/mocks-platform-admin'
import { fetchUpdateUserTestAction } from '../../constants/fetch_process'
import { StrokedButton } from 'views/components/UI/Buttons'
import ItemDashboard from './components/ItemDashboard'

type ParticipationMapType = Record<number, boolean>

const DetailProcessDelivered = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const processId = location.state?.id as number
  const { companyId } = useSelector(userSelector)
  const { getError, getSuccess, getWarning } = useNotification()
  const [participation, setParticipation] = useState<boolean>(false)
  const [userIds, setUserIds] = useState<Set<number>>(new Set())
  const [search, setSearch] = useState('')
  const [filterGetUsers, setFilterGetUsers] = useState<FilterGetUsersByProcess>({
    limit: DEFAULT_LIMIT,
    complete: true,
  })
  const [participationMap, setParticipationMap] = useState<ParticipationMapType>({})
  const { listProcessUsersResume, fetchProcessResume } = useFetchGetResumeProcess(processId)
  const { listUsersByProcess, totalPages, fetchUsers } = useFetchGetUsersByProcess(processId, filterGetUsers)
  const { loadingGetReports, reportsResult, handleGetReports } = useGetReports(processId)
  const {
    name,
    total_users: totalUsers,
    total_users_completed: totalUsersCompleted,
    total_users_uncompleted: totalUsersUncompleted,
  } = listProcessUsersResume
  const uniqueUserIds: UniqueUserIds = {}
  dayjs.extend(utc)

  const dataAllUsers = listUsersByProcess.reduce((accumulator: any[], user: any) => {
    if (!uniqueUserIds[user.userId]) {
      uniqueUserIds[user.userId] = true

      accumulator.push({
        id: user.userId,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        document: user.document,
        dateSend: dayjs.utc(user.shipping_date).format('DD/MM/YYYY'),
        answer_date: user.answer_date ? dayjs.utc(user.answer_date).format('DD/MM/YYYY') : 'Sin respuesta',
      })
    }

    return accumulator
  }, [])

  useEffect(() => {
    if (reportsResult.status === 'success') {
      const { data } = reportsResult
      const dateZip = new Date()
      const dia = dateZip.getDate()
      const mes = dateZip.getMonth() + 1
      const año = dateZip.getFullYear()
      const url = window.URL.createObjectURL(data)

      const a = document.createElement('a')

      a.href = url

      a.download = `${name}_${dia}_${mes}_${año}.zip`

      a.click()

      window.URL.revokeObjectURL(url)

      setUserIds(new Set())
    }
  }, [name, reportsResult])

  useEffect(() => {
    fetchProcessResume()
    fetchUsers()
  }, [fetchProcessResume, fetchUsers])

  useEffect(() => {
    if (reportsResult.status === 'error') {
      getError(reportsResult.error)
    }
  }, [reportsResult, getError, getSuccess])

  const handleSetFilterGetUsers = (searchTerm: string) => {
    setFilterGetUsers({ ...filterGetUsers, search: searchTerm, page: 1 })
  }

  const handlePagination = (page: number) => {
    setFilterGetUsers({ ...filterGetUsers, limit: DEFAULT_LIMIT, page })
  }

  const handleDownloadSingleReport = (userId: number) => {
    const newSetId = new Set([userId])

    handleGetReports({ userIds: newSetId })
  }

  // const handleDownloadMultipleReports = () => {
  //   const newSetIds = new Set(userIds)
  //   handleGetReports({ userIds: newSetIds })
  // }

  const handleSendReportToMultipleUsers = async (selectedUsersIds: number[]) => {
    const userTestIds: number[] = []
    const usersIdData: number[] = []
    const allUsersIds = Array.from(selectedUsersIds)
    try {
      if (!allUsersIds.length) {
        getWarning('Los usuarios tienen configurada la descarga de reporte automática')
        return
      } else {
        setParticipation(true)
        userIds.forEach((user: number) => {
          const userProcesses = listUsersByProcess.filter(
            (obj: { userId: number; processId: number }) => obj.userId === user
          )
          userProcesses.forEach((userTestId: any) => {
            userTestIds.push(userTestId.user_test_id)
          })
          usersIdData.push(user)
        })
        fetchUpdateUserTestAction(userTestIds, true)
        await sendReportToUser({ companyId: companyId, users: usersIdData })
        getSuccess('Se ha concedido el acceso a los usuarios de este proceso')
        const updatedParticipationMap: ParticipationMapType = { ...participationMap }
        selectedUsersIds.forEach((userId) => {
          updatedParticipationMap[userId] = true
        })
        setParticipationMap(updatedParticipationMap)
        setParticipation(false)
      }
    } catch (error: any) {
      getError(`Error al autorizar el acceso a los reportes ${error.message}`)
    }
  }

  const handleWatchSettings = () => {
    navigate(platformAdminRoutes.watchSettings, { state: { processId, name } })
  }

  const filteredUsers = listUsersByProcess.filter((user: any) => !(user.participant_report || user.access_report))
  const isButtonDisabled =
    listUsersByProcess.length === 0 ||
    !filteredUsers ||
    filteredUsers.length < 1 ||
    participation ||
    Array.from(userIds).some((userId) => participationMap[userId])

  return (
    <MainLayout>
      {loadingGetReports && (
        <ModalLoader
          style={{ backgroundColor: 'white', padding: '30px', borderRadius: '30px' }}
          text="Generando reportes..."
          isLoading={loadingGetReports}
        />
      )}
      <Container>
        <Row>
          <H2>{name || ''}</H2>
          <StrokedButton onClick={handleWatchSettings} type="button">
            Ver parametrización
          </StrokedButton>
        </Row>
        <DashboardContainer>
          <WhiteContainer
            padding="28px 8px 28px 8px"
            width="28%"
            height="90px"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <ItemDashboard text="Total de usuarios en el proceso" quantity={totalUsers} />
            <ItemDashboard showDivider text="Usuarios con pruebas completadas" quantity={totalUsersCompleted} />
          </WhiteContainer>
          <BlueContainer padding="28px 8px 28px 8px" width="185px" height="90px" margin="0px 16px">
            <ItemDashboard
              color="#FFF"
              text="Usuarios con pruebas sin completar"
              quantity={totalUsersUncompleted}
              onClick={() => navigate(platformAdminRoutes.UsersWithIncompleteTests, { state: listProcessUsersResume })}
            />
          </BlueContainer>
        </DashboardContainer>
        <Row alignItems="flex-end">
          <H4>Lista de usuarios con pruebas completadas</H4>
          <SearchBar
            label="Buscar por nombre, correo, documento"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              handleSetFilterGetUsers(e.target.value)
            }}
            onClick={() => handleSetFilterGetUsers(search)}
          />
        </Row>
        <Row justifyContent="center" alignItems="space-between" margin="31px 0px">
          {/* <Row justifyContent="flex-start" alignItems="center">
            <StrokedButton
              style={{ marginBottom: '0', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              disabled={!(userIds.size > 0)}
              onClick={handleDownloadMultipleReports}
            >
              <DownloadIcon style={{ marginRight: '10px', color: !(userIds.size > 0) ? '#C2C2C2' : '#2D749C' }} />
              {dataAllUsers.length === userIds.size ? (
                <div>Descargar todos los reportes</div>
              ) : (
                <div>Descargar reportes</div>
              )}
            </StrokedButton>
          </Row> */}
          <Row justifyContent="flex-end" margin="30px 0px 10px 0px" alignItems="start">
            <AddUserIcon disabled={isButtonDisabled} />
            <P
              disabled={isButtonDisabled}
              onClick={() => {
                if (!isButtonDisabled) {
                  handleSendReportToMultipleUsers(Array.from(userIds))
                } else {
                  handleSendReportToMultipleUsers([])
                }
              }}
              style={{ cursor: 'pointer' }}
              margin="0px 6px"
              color="#2D749C"
              fontWeight={700}
              fontSize="14px"
            >
              {dataAllUsers.length === userIds.size ? (
                <>Autorizar acceso a todos los usuarios</>
              ) : (
                <>Autorizar acceso a usuarios</>
              )}
            </P>
          </Row>
        </Row>

        <BluesiteTable
          headers={HEADERS_USERS_WITH_TESTS_COMPLETED}
          data={dataAllUsers}
          searchBar={false}
          searchLabel=""
          totalPages={totalPages}
          currentPage={filterGetUsers.page ? filterGetUsers.page : 1}
          handlePagination={handlePagination}
          ActionComponent={ActionViewDownloadReportProcessCreated}
          checks={true}
          selectedItems={userIds}
          setSelectedItems={(e) => setUserIds(e)}
          extraDataAction={{
            listIdUsers: userIds,
            onDownloadReport: (e: number) => handleDownloadSingleReport(e),
            processId,
          }}
        />
      </Container>
    </MainLayout>
  )
}

export default DetailProcessDelivered
