import axios, { AxiosResponse } from 'axios'

/**
 * @description service to update user
 * @param userId
 */
const updateUser = async (
  userId: number,
  personId: number,
  firstName: string,
  lastName: string,
  document: number,
  email: string
) => {
  const PATH = `/company/user/${userId}`

  const body = {
    personId,
    firstName,
    lastName,
    document,
    email,
  }

  try {
    const response: AxiosResponse = await axios.put(PATH, body)

    return response
  } catch (error) {
    console.error('error on get process by company', error)
    if ((error as any).response.data.message === 'Email or document already exists') {
      return 'Email or document already exists'
    }
  }
}

export default updateUser
