import { Page, ContainerRow, ContainerNameProfile, P } from '../styled'
import Headpage from './header'
import IdealProfileTable from './competencies/ideal-profile-table/ideal.profile-table'
// import { RangeScoreImg } from './competencies/range-score-img'
import { Title } from './competencies/description-page/styled'
import { Subtitle } from './competencies/dimension-detail-page/styled'
import { WrapperTitles } from './competencies/ideal-profile-table/styled'
import FooterPage from './footer-page'

interface GroupContentProps {
  group: any
  percentilData?: any
}

interface GroupPageProps {
  data: any
  name: string
  lastName: string
  page?: number
  totalPages?: number
  percentil?: any
  currentPage?: number
  answerDate: string
}

const GroupContent: React.FC<GroupContentProps> = ({ group, percentilData }) => (
  <>
    <WrapperTitles>
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Subtitle style={{ marginLeft: 0, textAlign: 'end' }}>Competencia</Subtitle>
      </div>
    </WrapperTitles>
    <IdealProfileTable competences={group} percentilData={percentilData} />
  </>
)

const IdealProfilePage: React.FC<GroupPageProps> = ({
  data,
  name,
  lastName,
  totalPages,
  currentPage = 0,
  answerDate,
}) => {
  const processInfo = Array.isArray(data) && data.find((item) => item.process_name)
  const competences = data.reduce((acc: any[], entry: any) => {
    if ('competences' in entry) {
      return [...acc, ...entry.competences]
    }
    return acc
  }, [])

  return (
    <Page>
      <Headpage isBetesa={false} />
      <WrapperTitles>
        {/* <RangeScoreImg width={260} height={26} /> */}
        <Title>Nivel de ajuste al perfil ideal</Title>
        <Subtitle>
          Nivel de Ajuste al Rol de {processInfo.process_name}:{' '}
          <p style={{ color: '#98B06F', fontSize: 20, display: 'inline', textTransform: 'uppercase' }}>
            {processInfo.adjustment_level}
            {processInfo.adjustment_level && processInfo.competences_score_adj_level !== null ? '-' : ''}
            {processInfo.competences_score_adj_level !== null
              ? Math.min(processInfo.competences_score_adj_level, 100)
              : ''}
            {processInfo.competences_score_adj_level !== null ? '%' : ''}
          </p>
        </Subtitle>
      </WrapperTitles>
      <GroupContent group={competences} />
      <ContainerRow style={{ marginTop: 0 }}>
        <ContainerNameProfile style={{ justifyContent: 'space-around' }}>
          <P>Perfil del Evaluado</P>
          <div style={{ width: '30%', paddingRight: 10 }}>
            <div
              style={{
                background: '#2D749C',
                height: 20,
                width: 20,
                borderRadius: '100%',
                margin: 10,
              }}
            ></div>
          </div>
        </ContainerNameProfile>
        <ContainerNameProfile>
          <P>Perfil ideal</P>
          <div style={{ width: '30%', paddingRight: 10 }}>
            <div
              style={{
                background: '#C6016B',
                height: 20,
                width: 20,
                borderRadius: '100%',
                margin: 10,
              }}
            ></div>
          </div>
        </ContainerNameProfile>
      </ContainerRow>
      <FooterPage
        answerDate={answerDate}
        name={name}
        lastName={lastName}
        page={currentPage}
        totalPages={Number(totalPages)}
      />
    </Page>
  )
}

export default IdealProfilePage
