import React, { FC } from 'react'
import { Page } from '../../../styled'
import Headpage from '../../../components/header'
import ScoreProgressGraphic from '../../../components/competencies/score-progress-graphic'
import { ScoreByDimensionProgresGraphic } from '../../../components/competencies/score-progress-graphic/score-progress-graphic'
import FooterPage from '../../../components/footer-page'
import { RangeScoreImg } from '../range-score-img'
import { WrapperContent, WrapperSubtitles, Title, DescriptionText, Subtitle } from './styled'
import { DescriptionDiv, DimensionText } from '../description-page/styled'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'
import { setTotalPagesDimensionCompetencePdf } from 'ducks/ui'

interface DimensionData {
  dimension_name: string
  text: string
  overall_score: number
}

type ItemType = {
  skill_name: string
  skill_id: number
  description_skill: string
  competence_score: number
  dimension_data: DimensionData[]
  interpretive_report: string
}

interface DimensionDetailPageProps {
  dimensionScore: any
  percentil: any
  resume: any
  totalPages: number
  answerDate: string
}

const DimensionDetailPage: FC<DimensionDetailPageProps> = ({
  dimensionScore,
  percentil,
  resume,
  totalPages,
  answerDate,
}) => {
  const dispatch = useDispatch()
  let currentPage = 1
  const findPercentil: any = (id: number) => {
    if (percentil) {
      let percentileProm = 0
      percentil?.result.forEach((ps: any) => {
        if (ps.competence_id === String(id)) {
          percentileProm = ps.percentile_prom
        }
      })
      return percentileProm
    }
  }

  const findDimensionPercentil: any = (id: number, dimensionName: string) => {
    if (percentil) {
      let percentileProm = 0
      percentil?.result.forEach((ps: any) => {
        if (ps.competence_id === String(id)) {
          percentileProm = ps.percentiles_dimensions[dimensionName]
        }
      })
      return percentileProm
    }
  }
  const predefinedOrder = ['Cognitiva', 'Emocional', 'Interactiva']

  const getBulletStyle = (dimensionName: string): string => {
    const bulletColors: { [dimensionName: string]: string } = {
      Cognitiva: '#DD7AAF',
      Emocional: '#1380A6',
      Interactiva: '#BDBBBB',
    }

    return bulletColors[dimensionName] || 'black'
  }

  return (
    <>
      {dimensionScore?.report_data &&
        dimensionScore?.report_data?.sort((a: ItemType, b: ItemType) => {
          if (b.competence_score === a.competence_score) {
            return a.skill_name.localeCompare(b.skill_name, 'es', { sensitivity: 'accent' })
          } else {
            return b.competence_score - a.competence_score
          }
        }) &&
        dimensionScore?.report_data.map((item: ItemType) => {
          currentPage++
          dispatch(setTotalPagesDimensionCompetencePdf(totalPages + currentPage))
          return (
            <Page key={item.skill_id}>
              <Headpage isBetesa={false} />
              <WrapperContent>
                <WrapperSubtitles>
                  <Title>{item?.skill_name}</Title>
                  <RangeScoreImg width={260} height={26} align="end" />
                </WrapperSubtitles>
                <DescriptionText>{item?.description_skill}</DescriptionText>

                <ScoreProgressGraphic
                  progressValue={item?.competence_score}
                  percentilValue={item?.competence_score === 100 ? 100 : findPercentil(item?.skill_id)}
                  justifyContent="center"
                  progresContainerWidth={85}
                />
                <Subtitle>Puntaje por dimensión</Subtitle>
                {predefinedOrder.map((dimensionName, index) => {
                  const dimension = item.dimension_data.find((dim) => dim.dimension_name === dimensionName)
                  if (!dimension) {
                    return null
                  }

                  const bulletColor = getBulletStyle(dimensionName)

                  return (
                    <React.Fragment key={`${dimensionName}-${index}`}>
                      <ul style={{ paddingLeft: 12, margin: 0, listStyle: 'none' }}>
                        <li style={{ margin: '0px 0', position: 'relative' }}>
                          <span
                            style={{
                              backgroundColor: bulletColor,
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '10px',
                              verticalAlign: 'middle',
                            }}
                          ></span>
                          <DimensionText>{dimensionName}</DimensionText>
                        </li>
                      </ul>
                      <DescriptionText>{dimension.text && dimension.text}</DescriptionText>
                      <ScoreByDimensionProgresGraphic
                        progressValue={dimension.overall_score}
                        percentilValue={
                          dimension.overall_score === 100 ? 100 : findDimensionPercentil(item?.skill_id, dimensionName)
                        }
                        backgroundColorBar="#D2E3EC"
                        justifyContent="center"
                        progresContainerWidth={60}
                        isDimensionDetail={true}
                      />
                    </React.Fragment>
                  )
                })}
                <Subtitle>Informe interpretativo</Subtitle>
                <DimensionText>Tus puntuaciones en esta competencia sugieren que:</DimensionText>
                {item?.interpretive_report.length > 0 && (
                  <DescriptionDiv>{parse(item?.interpretive_report.replace(/<br\s*\/?>/gi, ''))}</DescriptionDiv>
                )}
              </WrapperContent>
              <FooterPage
                page={currentPage + 1}
                totalPages={totalPages}
                name={resume?.first_name}
                lastName={resume?.last_name}
                answerDate={answerDate}
              />
            </Page>
          )
        })}
    </>
  )
}

export default DimensionDetailPage
