import { FC } from 'react'
import { TABLE_SCORE_HEADER_TOTAL_SUMMARY } from '../../constants'
import { BetesaScore } from 'types/common'

import { CardTable, CellWithRowGroup } from './styled'

interface IItemDetailResult {
  chapter_name: string
  scores: BetesaScore
}

interface ScoresTableProps {
  chapters: IItemDetailResult[]
  selfPerceptionScores: BetesaScore[]
  totalSummary: BetesaScore
}

const ScoresTable: FC<ScoresTableProps> = ({ chapters, selfPerceptionScores, totalSummary }) => {
  return (
    <CardTable>
      <thead>
        <tr>
          <th>Cuadrantes</th>
          <th>FI</th>
          <th>BI</th>
          <th>BD</th>
          <th>FD</th>
        </tr>
      </thead>
      <tbody>
        {chapters.map((chapter: IItemDetailResult, index: number) => (
          <>
            <tr key={`${chapter.chapter_name}${index}`}>
              <th>{chapter.chapter_name}</th>
              <CellWithRowGroup>{chapter.scores.FI}</CellWithRowGroup>
              <CellWithRowGroup>{chapter.scores.BI}</CellWithRowGroup>
              <CellWithRowGroup>{chapter.scores.BD}</CellWithRowGroup>
              <CellWithRowGroup>{chapter.scores.FD}</CellWithRowGroup>
            </tr>
            {index === 0 && (
              <tr style={{ color: '#C6016B' }}>
                <>
                  <th>{TABLE_SCORE_HEADER_TOTAL_SUMMARY}</th>
                  <CellWithRowGroup>{totalSummary.FI}</CellWithRowGroup>
                  <CellWithRowGroup>{totalSummary.BI}</CellWithRowGroup>
                  <CellWithRowGroup>{totalSummary.BD}</CellWithRowGroup>
                  <CellWithRowGroup>{totalSummary.FD}</CellWithRowGroup>
                </>
              </tr>
            )}
          </>
        ))}

        {selfPerceptionScores.map((score: BetesaScore, index) => (
          <tr key={index}>
            {index === 0 && <th rowSpan={14}>Detalle autopercepción</th>}
            <CellWithRowGroup>{score.FI}</CellWithRowGroup>
            <CellWithRowGroup>{score.BI}</CellWithRowGroup>
            <CellWithRowGroup>{score.BD}</CellWithRowGroup>
            <CellWithRowGroup>{score.FD}</CellWithRowGroup>
          </tr>
        ))}
      </tbody>
    </CardTable>
  )
}

export default ScoresTable
