export const parseQuadrant = (quadrant: string) => {
    switch (quadrant) {
      case 'Frontal Izquierdo':
        return 'FI';
      case 'Frontal Derecho':
        return 'FD';
      case 'Basal Izquierdo':
        return 'BI';
      case 'Basal Derecho':
        return 'BD';
      default:
        return quadrant;
    }
  };