import axios from "lib/axios";

type IProcess = {
    process_name: string
    process_id: number
}

type Data = {
    processes: IProcess[]
}

interface IResponse  {
    status: 'OK' | 'error'
    data: Data,
    total_pages: number,
    total_results: number
}

interface Filters {
    companyId?: number,
    sent?: boolean,
    limit?: number,
    page?: number,
    userId?: number,
    processId?: number | number[]
}
 
const getProcessByCompany = async (filters: Filters) => {
  let PATH = `/process`

  const queryParams = new URLSearchParams();
  if(filters.companyId) queryParams.append("company_id", filters.companyId.toString());
  if (filters.processId) {
    const processIds = Array.isArray(filters.processId) ? filters.processId : [filters.processId];
    processIds.forEach(id => {
      queryParams.append("process_id", id.toString());
    });
  }
  if(typeof filters.sent === 'boolean') queryParams.append("sent", filters.sent.toString());
  if(filters.limit) queryParams.append("limit", filters.limit.toString());
  if(filters.page) queryParams.append("page", filters.page.toString());
  if(filters.userId) queryParams.append("user_id", filters.userId.toString());

  PATH = `${PATH}?${queryParams.toString()}`;
  
  try {
    const response: IResponse = await axios.get(PATH).then((res) => {
      return res.data
    }).catch((err) => {
      console.error('error on get process by company', err)
    })
    return response
  } catch (error) {
    console.error('error on get process by company', error)
  }
}

export default getProcessByCompany
