import { QuadrantData } from 'views/containers/BrainQuadrant/constants'

const transformToCartesianData = (data: {
  [key: string]: { priority: number; min: number; max: number }
}): QuadrantData[] => {
  const quadrantsExpected: Array<'FI' | 'FD' | 'BI' | 'BD'> = ['FI', 'FD', 'BI', 'BD']
  const transformedData = Object.keys(data)
    .filter((key) => quadrantsExpected.includes(key as 'FI' | 'FD' | 'BI' | 'BD'))
    .map((quadrant) => ({
      quadrant: quadrant as 'FI' | 'FD' | 'BI' | 'BD',
      priority: data[quadrant].priority,
      min: data[quadrant].min,
      max: data[quadrant].max,
    }))
  return transformedData
}

export default transformToCartesianData