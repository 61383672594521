import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  padding: 2% 0%;
`

interface IRowContainer {
  justifyContent?: string
  height?: string
}

export const RowContainer = styled.div<IRowContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  width: 100%;
  height: ${(props) => props.height || 'auto'};
`

export const Header = styled.div`
  width: 100%;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerSelectCompetence = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  height: auto;
  margin-top: 1%;
  margin-bottom: 40px;
`

export const GraphicsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 2%;
`

export const TypeGraphicContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 1%;
`

interface ISubContainer {
  width?: string
  height?: string
  padding?: string
  margin?: string
  justifyContent?: string
  alignItems?: string
}

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: ISubContainer) => props.alignItems || 'flex-start'};
  justify-content: ${(props: ISubContainer) => props.justifyContent || 'flex-start'};
  width: ${(props: ISubContainer) => props.width || '100%'};
  height: ${(props: ISubContainer) => props.height || 'auto'};
  margin: ${(props: ISubContainer) => props.margin || '0 1%'};
  padding: ${(props: ISubContainer) => props.padding || 'none'};
`

export const CompetenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-evenly;
  justify-content: flex-start;
  width: 100%;
`

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-evenly;
  justify-content: flex-end;
  width: 100%;
`

export const NoDataContainer = styled.div`
  width: 85%;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 12px 13px 16px;
  border-radius: 8px;
  background-color: #ddf3f8;
`

export const NoDataSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const NoDataText = styled.p`
  font-size: 14px;
  font-family: Public Sans;
  line-height: 22px;
  font-weight: 600;
  color: #065a6c;
`

export const NoDataIcon = styled.img`
  display: flex;
  justify-content: left;
  width: 25px;
  height: 25px;
`

export const TagStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
