import styled from 'styled-components'

export const GridDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BluesiteTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`
export const TableDivider = styled.hr`
  border: 1px solid #1f2124;
  width: 100%;
`
export const TableHeaders = styled.thead`
  margin-bottom: 30px;
  border-bottom: 1px solid #1f2124;
  padding: 0.3em;
  justify-content: space-between;
`
export const ActionsCell = styled.th`
  justify-content: flex-end;
  width: 25%;
`
export const TableBody = styled.tbody`
  border-top: 1px solid;
  padding-top: 30px;
  justify-content: space-between;
`
export const TableSelect = styled.select`
  width: 63px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: none;
  margin: 10px;
  padding: 10px;
`

export const ActionIcon = styled.svg`
  fill: #2d749c;
  clip-rule: evenodd;
  fill-rule: evenodd;
  strokelinejoin: round;
  stroke-miterlimit: 2;
  width: 24px;
  height: 24px;
  margin-right: 5px;
`
export const ContainerActionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
`
export const UpdateTag = styled.span`
font-family: Public Sans;
font-weight: 700;
color: #2D749C;
font-size: 14px;
margin: 16px 10px 0px 10px;
`
export const ViewTag = styled.span`
font-family: Public Sans;
font-weight: 700;
color: #C6016B;
font-size: 14px;
margin: 14px 10px 0px 10px;
`
export const BtnNext = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 113px;
    height: 48px;
    background: #C6016B;
    color: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
    border-radius: 8px;
    cursor: pointer;
`

export const BtnGoBack = styled.div`
    color: #2D749C;
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
`
export const GridModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding-bottom:40px;
    align-items: center;
`;
export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
`;
export const Row = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`;
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #C2C2C2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
    background: #C6016B;
  }`
