import axios from 'lib/axios'

const getBetesaResults = async (id: any, page: number) => {
  const betesaResults = await axios
    .get(`/betesa_results/users/${id}?page=${page}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return betesaResults
}

export default getBetesaResults
