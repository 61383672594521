export const SEARCH = 'user'

export const SEARCH_TABS = {
  create: SEARCH,
  action: SEARCH,
}
export const IDS = [3, 4]

export const HEADER_COMPANY_USERS = [
  {
    key: 'identification',
    name: 'Documento',
  },
  {
    key: 'mail',
    name: 'Email',
  },
  {
    key: 'name',
    name: 'Nombre',
  },
  {
    key: 'action',
    name: 'Acción',
  },
]
export const HEADERS = [
  {
    key: 'first_name',
    name: 'Nombre',
  },
  {
    key: 'role',
    name: 'Tipo de usuario',
  },
  {
    key: 'company_name',
    name: 'Compañía',
  },
]

export const determineRole = (role: any) => {
  switch (role.id) {
    case 3:
      return 'Administrador de plataforma'
    case 4:
      return 'Usuario evaluado'
    case 6:
      return 'Administrador Líder'
    default:
      return 'Error de selección'
  }
}

export const parseRole = (role: any) => {
  switch (role) {
    case 'platform_admin':
      return 'Administrador de plataforma'
    case 'user':
      return 'Usuario evaluado'
    case 'super_admin':
      return 'Super administrador'
    case 'special_admin':
      return 'Usuario especial'
    case 'super_platform_admin':
      return 'Administrador Líder'
    default:
      return 'Rol no identificado'
  }
}
export const data = [
  {
    identification: '12345',
    mail: 'juan@email.com',
    name: 'Juan',
  },
]
