import { IOS_DEVICE } from 'constants/global.constants'
import styled, { css } from 'styled-components'

const iOS = IOS_DEVICE.test(navigator.userAgent)

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #fafcff;

  ${iOS &&
  css`
    height: auto;
  `}
`
interface IContentProps {
  isSidebarOpen: boolean
}

export const Content = styled.div<IContentProps>`
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  transition: margin-left 0.5s ease-in-out;
  margin: 0 auto;
  padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '0 2.5%' : '0 5%')};
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 992px) {
    display: flex;
    box-sizing: border-box;
    padding-top: 60px;
  }
  @media (max-width: 768px) {
    display: block;
    place-items: start;
    height: 100dvh;
  }

  ${iOS &&
  css`
    padding: 0 5%;
  `}
`
