import styled from 'styled-components'

export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns : repeat(3, 1fr);;
  gap: 16px;
`

export const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row; 
  width: 256px;
  height: 92px;
  padding: 12px;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const Icon = styled.img`
  max-width: 60px;
`

export const UploadSizeRecomended = styled.p`
  width:174px;
  color: #797A7C;
  font-weight: 100;
  font-size: 16px;
`

// grid 2 columns with proportion 2:8
export const InputGridDiv = styled.div`
margin-top: 40px;
width: 100%;
display: grid;
grid-template-columns: 2fr 8fr;
grid-gap: 40px;
`
export const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
`
export const SubTitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
  align-items: center;
`
export const BtnGoBack = styled.div `
    color: #2D749C;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
`

export const TextError = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`