import {
  Container,
  ImageContainer,
  TextContainer,
  Image,
  TitleContainer,
  ActionContainer,
  TextDownload,
} from './styled'
import { H4 } from 'views/components/UI/Text'
import noImg from 'assets/images/no-image.png'
import DownloadIcon from 'assets/icons/DownloadIcon'
import brainIconImg from 'assets/images/brain-icon.png'
import boxIconImg from 'assets/images/box-icon.png'
import DownloadPlanCard from './download-plan-card'

interface IProps {
  title: string
  link?: string
  type: 'betesa' | 'skills' | string
  onClick?: (user_id: number, test_id: number, user_test_id: number, type: string) => void
  user_id?: number
  test_id?: number
  user_test_id?: number
  style?: React.CSSProperties
  planToDownload?: boolean
  onClickPlan?: (user_id: number, test_id: number, user_test_id: number, type: string) => void
  processId?: number
  userName?: string
}
function Index(props: IProps) {
  const {
    title,
    type,
    onClick = () => {},
    user_id: userId = 0,
    test_id: testId = 0,
    user_test_id: userTestId = 0,
    style,
    planToDownload = false,
    processId = 0,
    userName,
  } = props

  return (
    <Container style={style}>
      <ImageContainer>
        {type === 'betesa' && <Image src={brainIconImg} />}
        {type === 'skills' && <Image src={boxIconImg} />}
        {type !== 'betesa' && type !== 'skills' && <Image src={noImg} />}
      </ImageContainer>
      <TextContainer>
        <TitleContainer>
          <H4>{title || 'Inserte titulo'}</H4>
        </TitleContainer>
        <ActionContainer>
          <DownloadIcon />
          <TextDownload
            onClick={(event) => {
              event.preventDefault()
              onClick(userId, testId, userTestId, type)
            }}
          >
            Descargar reporte
          </TextDownload>
        </ActionContainer>
        {planToDownload && (
          <DownloadPlanCard userName={userName} skillParam={userTestId} typeTest={type} processIdParam={processId} />
        )}
      </TextContainer>
    </Container>
  )
}

export default Index
