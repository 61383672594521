import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import TitleDiv from 'views/components/UI/TitleDiv'
import { ContainerRow, BtnGoBack, InputGridDiv, TextError, InputContainer } from './styled'
import Input from 'views/components/UI/TextInput'
import { Button } from 'views/components/UI/Buttons'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { dataNewQuestionnaire } from 'ducks/modules/selectors'
import { superAdminRoutes } from 'router/routes'
import { useNotification } from 'lib/context/notification.context';
import { STATUS_OK } from 'constants/global.constants';
// services
import getChapters from 'services/common/chapters/get'

import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { fetchUpdateChapterBetesa } from 'views/screens/Modules/betesa-talents/services/fetch-betesa-talents'

const STEPS = ['Información básica', 'Capítulos', 'Preguntas']



/**
 * 
 * @description Screen to create a new chapter
 */
const EditChapter: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { questionnaireId, chapterId } = location.state

  const { id: idQuestionnaire } = useSelector(dataNewQuestionnaire);

  const { getError, getSuccess } = useNotification();

  // Local state
  const [loading, setLoading] = useState(false)
  const [chaptersCreated, setChaptersCreated] = useState({
    chapterNumber: '0',
    chapterName: '',
    chapterDescription: '',
  })

  const validationSchema = Yup.object({
    chapterNumber: Yup.string().required('El número del capítulo es obligatorio'),
    chapterName: Yup.string().trim().required('El nombre del capítulo es obligatorio'),
    chapterDescription: Yup.string().trim().required('La descripción del capítulo es obligatoria'),
  })


  // EFFECTS
  useEffect(() => {
    if (chapterId) {
      setLoading(true)
      getChapters({ id: parseInt(chapterId) }).then((res) => {
        if (res.status === 'OK') {
          setChaptersCreated({
            chapterNumber: res.chapters[0].number,
            chapterName: res.chapters[0].name,
            chapterDescription: res.chapters[0].description,
          })
          setLoading(false)
        } else {
          setChaptersCreated({
            chapterNumber: '0',
            chapterName: '',
            chapterDescription: '',
          })
          setLoading(false)
        }
      })
    }
  }, [chapterId])





  if (chaptersCreated.chapterNumber === '0') {
    return <ModalLoader isLoading={loading} />
  }

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Editar capítulo</h2>
      </TitleDiv>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={1} alternativeLabel>
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {
        (idQuestionnaire || chaptersCreated !== null) && (
          <Formik
            initialValues={chaptersCreated}
            validationSchema={validationSchema}
            onSubmit={async (values) => {

              if (!loading) {
                setLoading(true)

                if (idQuestionnaire || questionnaireId) {
                  const dataUpdateChapter = {
                    number: values.chapterNumber,
                    name: values.chapterName,
                    description: values.chapterDescription,
                  }
                  await fetchUpdateChapterBetesa(dataUpdateChapter, chapterId).then((response: any) => {
                    if (!response.ok && response.message) getError(response.message)
                    if (response?.status === STATUS_OK) {
                      setLoading(false)
                      getSuccess("Datos guardados con éxito")
                      navigate(superAdminRoutes.ChaptersQuestionaire, { state: { questionnaireId, chapterId: dataUpdateChapter.number } })
                    }
                  }).catch((error: any) => {
                    getError(error.message)
                  })

                }
              }

            }}
          >
            {({ errors, touched, handleChange, values, isSubmitting }) => (
              <Form>
                <InputGridDiv>
                  <InputContainer>
                    <Field
                      value={values.chapterNumber}
                      // handling the change of the input to avoid that the user can type a number that is greater than the number of chapters created
                      onChange={(e: any) => parseInt(e.target.value) > 0 && (
                        chaptersCreated !== null ? parseInt(e.target.value) > parseInt(chaptersCreated.chapterNumber) ? handleChange('chapterNumber')(e) : null : handleChange('chapterNumber')(e)
                      ) ? handleChange('chapterNumber')(e) : null}
                      type="number"
                      label="Numero del capítulo"
                      as={Input}
                      disabled={true}
                    />
                    {errors.chapterNumber && touched.chapterNumber ? (
                      <TextError>{errors.chapterNumber}</TextError>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Field
                      name="chapterName"
                      type="text"
                      label="Nombre del capítulo"
                      as={Input}
                    />
                    {errors.chapterName && touched.chapterName ? (
                      <TextError>{errors.chapterName}</TextError>
                    ) : null}
                  </InputContainer>
                </InputGridDiv>
                <InputContainer>
                  <Field
                    style={{ height: 200 }}
                    name="chapterDescription"
                    type="textarea"
                    label="Descripción del capítulo"
                    as={Input}
                  />
                  {errors.chapterName && touched.chapterName ? (
                    <TextError>{errors.chapterName}</TextError>
                  ) : null}
                </InputContainer>
                <ContainerRow>
                  <BtnGoBack onClick={() => navigate(superAdminRoutes.ChaptersQuestionaire, { state: { questionnaireId } })/*navigate(superAdminRoutes.QuestionnairesBetesa,
                    { state: { questionnaireId } }
                  )*/}>Cancelar</BtnGoBack>
                  <Button disabled={Object.keys(errors).length > 0 || isSubmitting} type="submit">Guardar</Button>
                </ContainerRow>
              </Form>
            )}
          </Formik>
        )
      }
    </MainLayout>
  )
}

export default EditChapter

