import Modal from 'views/components/UI/Modal'
import { Button, ButtonBack, ButtonContainer, Container, SubTitle, SubTitleContainer, Title } from './style'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isDirtySelector, setSaveChanges, tableChangedSelector } from '../../store'
interface ModalProps {
  showConfirmModal: boolean
  setShowConfirmModal: (e: boolean) => void
  saveTable: () => void
}

const ModalConfirm: FC<ModalProps> = ({ showConfirmModal, setShowConfirmModal, saveTable }) => {
  const dispatch = useDispatch()

  const isDirty = useSelector(isDirtySelector)
  const tableChanged = useSelector(tableChangedSelector)

  const handleBackButtonClick = () => {
    setShowConfirmModal(false)
    window.history.back()
  }

  const handleSaveButtonClick = () => {
    if (isDirty) dispatch(setSaveChanges(true))
    if (tableChanged) saveTable()
    setShowConfirmModal(false)
    window.history.back()
  }

  return (
    <Modal styleContent={{ width: '550px' }} show={showConfirmModal} setShow={setShowConfirmModal}>
      <Container>
        <Title>No guardaste los cambios</Title>
        <SubTitleContainer>
          <SubTitle>Has realizado cambios en la página. Si retrocedes ahora, perderás esos cambios.</SubTitle>
          <SubTitle>¿Estás seguro que deseas continuar sin guardar?</SubTitle>
        </SubTitleContainer>
        <ButtonContainer>
          <ButtonBack onClick={handleBackButtonClick}>Ir atrás sin guardar cambios</ButtonBack>
          <Button onClick={handleSaveButtonClick}>Guardar cambios</Button>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default ModalConfirm
