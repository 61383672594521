import { FC, useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from 'views/components/UI/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'views/components/UI/Buttons'
import { H4 } from 'views/components/UI/Text'
import { ModalBody, ModalContainer, ModalHeader, AnchorSection } from '../styled'
import File from 'views/components/assets/csv.png'
import DownloadIcon from 'assets/icons/DownloadIcon'
import FileFolderIcon from 'assets/icons/fileFolderIcon'
import DeleteIcon from 'assets/icons/DeleteIcon'
import GreenFile from 'assets/icons/greenFile'
import { useNotification } from 'lib/context/notification.context'
import { User } from '../constants'
import { platformAdminRoutes } from 'router/routes'
import { Row } from 'views/components/UI/Row'
import { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } from './uploadHandlers'
import * as XLSX from 'xlsx'
import { generalDataSelector } from 'ducks/auth'
import { IDocumentType } from 'types/common'

interface DisclaimerModalProps {
  openModal: boolean
  setOpenModal: (value: boolean) => void
  setAddedUsers: (value: User[]) => void
  setUploadedbyFile: (value: boolean) => void
  setTryUploadAgain?: (value: boolean) => void
  showStepTwo?: boolean
}

type AddedUsers = {
  document_type: number
  document: string
  email: string
  first_name: string
  last_name: string
}

const UploadModal: FC<DisclaimerModalProps> = ({
  openModal,
  setOpenModal,
  setAddedUsers,
  setUploadedbyFile,
  setTryUploadAgain,
  showStepTwo = false,
}) => {
  const { getError } = useNotification()
  const navigate = useNavigate()
  const { data: generalData } = useSelector(generalDataSelector)
  const documentTypeOptions = generalData?.document_type

  const [stepOne, setStepOne] = useState(true)
  const [stepTwo, setStepTwo] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [dragging, setDragging] = useState(false)
  const [fileList, setFileList] = useState<File[]>([])
  const [fileExist, setFileExist] = useState(false)

  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    if (fileList.length > 0) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const firstSheet = workbook.SheetNames[0]
        const sheet = workbook.Sheets[firstSheet]
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
        const usersFromExcel = jsonData.map((user: any, index: number) => {
          return {
            index,
            user: {
              document_type: documentTypeOptions?.find((documentType: IDocumentType) => documentType.name === user[0])
                ?.id,
              document: user[1]?.toString() || '',
              email: user[2],
              first_name: user[3],
              last_name: user[4],
            },
          }
        })

        const filteredUsers = usersFromExcel
          .filter((user: any) => user.user.document_type !== undefined && user.user.document !== '')
          .map((item) => {
            const newUser = { ...item.user }
            ;(Object.keys(newUser) as Array<keyof AddedUsers>).forEach((key) => {
              if (typeof newUser[key] === 'string') {
                if (key === 'document' || key === 'email') {
                  newUser[key] = newUser[key].replace(/\s+/g, '')
                } else {
                  newUser[key] = newUser[key].trim()
                }
              }
            })
            return { ...item, user: newUser }
          })

        setUsers([...filteredUsers, ...users] as User[])
      }

      reader.readAsArrayBuffer(fileList[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  useEffect(() => {
    if (showStepTwo) {
      setStepOne(false)
      setStepTwo(true)
    }
  }, [showStepTwo])

  const handleNext = () => {
    setStepOne(false)
    setStepTwo(true)
  }

  const handleBack = () => {
    setStepOne(true)
    setStepTwo(false)
  }

  const handleFiles = (files: FileList) => {
    setFileList(Array.from(files))
  }

  const clearUsers = () => {
    setUsers([])
  }

  const clearInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setFileList([])
    clearUsers()
    setFileExist(false)
  }

  const handleImport = () => {
    setOpenModal(false)
    clearInput()

    const usersToImport = users.map(({ user }: any) => user)

    if (setTryUploadAgain) {
      setAddedUsers(usersToImport)
      const uploadedByFile = true
      const tryAgain = true
      navigate(platformAdminRoutes.Resume, {
        state: { data: users, uploadedByFile, tryAgain },
      })
    } else {
      setAddedUsers(usersToImport)
    }
    setUploadedbyFile(true)
  }

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '80%',
        height: '60%',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseIcon />
          </div>
        </ModalHeader>

        <ModalBody>
          <H4 style={{ textAlign: 'start' }}>Importar usuarios </H4>
          {stepOne ? (
            <>
              <p>
                Descarga la plantilla y llena la columna A con el tipo de documento, la columna B con el número de
                documento, la columna C con el correo electrónico, la columna D con nombre del usuario y la columna E
                con su apellido.
              </p>
              <Row
                justifyContent="center"
                flexDirection="column"
                style={{
                  boxSizing: 'border-box',
                  alignContent: 'center',
                  background: ' #919EAB1F',
                  borderRadius: 12,
                  padding: 50,
                }}
              >
                <AnchorSection href="https://bluesitestorage.s3.amazonaws.com/plantilla_de_usuarios.xlsx" download>
                  <Row gap="10px" alignItems="center" style={{ alignSelf: 'center' }}>
                    <img src={File} alt="file" />
                    <span>Plantilla de usuarios.xlxs</span>
                  </Row>
                  <Row gap="10px" alignItems="center" style={{ alignSelf: 'center' }}>
                    <DownloadIcon /> <span style={{ color: '#2D749C' }}> Descargar archivo</span>
                  </Row>
                </AnchorSection>
              </Row>
              <Row justifyContent="flex-end" alignItems="center">
                <Button
                  onClick={() => {
                    handleNext()
                  }}
                >
                  Siguiente
                </Button>
              </Row>
            </>
          ) : null}
          {stepTwo ? (
            <>
              <p>Adjunte aquí la plantilla previamente llenada</p>
              <Row gap="10px" flexDirection="column">
                <Row
                  justifyContent="center"
                  flexDirection="column"
                  style={{
                    alignContent: 'center',
                    background: ' #919EAB1F',
                    borderRadius: 12,
                    padding: 50,
                    boxSizing: 'border-box',
                  }}
                >
                  <Row
                    alignItems="center"
                    gap="10px"
                    className={`file-dropzone ${dragging ? 'dragging' : ''}`}
                    onDrop={(e) => handleDrop(e, fileInputRef, handleFiles, setDragging, setFileExist)}
                    onDragOver={handleDragOver}
                    onDragEnter={(e) => handleDragEnter(e, setDragging)}
                    onDragLeave={(e) => handleDragLeave(e, setDragging)}
                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                    style={{ alignSelf: 'center' }}
                  >
                    <FileFolderIcon />
                    <div>
                      <span style={{ fontSize: 20, fontWeight: 'bold', color: ' #1F2124' }}>
                        Seleccione los archivos
                      </span>
                      <p>Arrastre los archivos aquí o haga click para buscarlos en su computador</p>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".xlsx, .xlsm, .csv"
                      onChange={(e) => {
                        if (e.target.files) {
                          const file = e.target.files[0]
                          const fileExtension = file?.name.split('.').pop()?.toLowerCase()

                          if (!file.name) setFileExist(false)
                          else setFileExist(true)

                          if (fileExtension !== 'xls' && fileExtension !== 'xlsx' && fileExtension !== 'csv') {
                            getError('Solo se pueden subir archivos de Excel (.xls, .xlsx, csv)')
                            return
                          }
                          handleFiles(e.target.files)
                        }
                      }}
                      className="file-input"
                      style={{ display: 'none' }}
                    />
                  </Row>
                </Row>
                {fileList.length > 0 ? (
                  <Row
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      boxSizing: 'border-box',
                      alignContent: 'center',
                      background: ' #919EAB1F',
                      borderRadius: 12,
                      padding: 10,
                    }}
                  >
                    <Row gap="10px" alignItems="center" style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <GreenFile />
                      {fileList.map((file, index) => (
                        <Row flexDirection="column" gap="5px" style={{ paddingTop: 0, paddingBottom: 0 }} key={index}>
                          <span>{file.name}</span>
                          <span>{file.size} bytes</span>
                        </Row>
                      ))}
                    </Row>
                    <div onClick={clearInput}>
                      <DeleteIcon />
                    </div>
                  </Row>
                ) : null}
                <Row justifyContent="space-between" alignItems="center">
                  <span
                    style={{ color: '#2D749C', fontWeight: 'bold' }}
                    onClick={() => {
                      handleBack()
                    }}
                  >
                    Atrás
                  </span>
                  <Button
                    disabled={!fileExist}
                    onClick={() => {
                      handleImport()
                    }}
                  >
                    Importar
                  </Button>
                </Row>
              </Row>
            </>
          ) : null}
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}
export default UploadModal
