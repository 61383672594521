import { FC, CSSProperties } from 'react'
import {
  ProgressGraphicContainer,
  ProgressContainer,
  ProgressBar,
  ProgressValue,
  DataDivider,
  PercentilData,
  PercentilValue,
  UserScore,
  ExpectedScore,
  UserScoreValue,
  ExpectedScoreValue,
  GapContainer,
} from './styled'

interface ScoreProgressGraphicProps {
  progressValue?: number
  percentilValue?: number
  dimensionCognitive?: boolean
  justifyContent?: string
  style?: CSSProperties
  progresContainerWidth?: number
  adjustLevel?: boolean
  idealScore?: number
  userScore?: number
  backgroundColorBar?: string
  isDimensionDetail?: boolean
}

const ScoreProgressGraphic: FC<ScoreProgressGraphicProps> = ({
  progressValue = 0,
  percentilValue,
  justifyContent = 'normal',
  progresContainerWidth,
  adjustLevel,
  idealScore = 0,
  userScore = 0,
  backgroundColorBar = '#738C9A',
}) => {
  const getProgressBarColor = () => {
    if (progressValue >= 0 && progressValue <= 40) {
      return '#F02401'
    } else if (progressValue >= 41 && progressValue <= 60) {
      return '#FF8402'
    } else if (progressValue >= 61 && progressValue <= 80) {
      return '#674531'
    } else if (progressValue >= 81) {
      return '#2C9430'
    } else {
      return 'Valor fuera de rango'
    }
  }

  return (
    <ProgressGraphicContainer justifyContent={justifyContent}>
      <ProgressContainer
        backgroundColorbar={backgroundColorBar}
        style={progresContainerWidth ? { width: `${progresContainerWidth}%` } : undefined}
      >
        {!adjustLevel ? (
          <ProgressBar width={progressValue}>
            <ProgressValue border={getProgressBarColor()} color={getProgressBarColor()}>
              {Math.ceil(progressValue)}
            </ProgressValue>
          </ProgressBar>
        ) : (
          <div style={{ position: 'relative' }}>
            <UserScore width={idealScore}>
              <UserScoreValue />
            </UserScore>
            <ExpectedScore width={userScore + 7}>
              <ExpectedScoreValue />
            </ExpectedScore>
            <GapContainer style={{ position: 'absolute', left: `calc(((${idealScore}% + ${userScore}%) / 2) + 8px)` }}>
              {Math.ceil(Math.abs(idealScore - userScore))}
            </GapContainer>
          </div>
        )}
      </ProgressContainer>
      {percentilValue && percentilValue !== 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DataDivider />
          <PercentilData>
            <PercentilValue>{percentilValue}</PercentilValue>
          </PercentilData>
        </div>
      )}
    </ProgressGraphicContainer>
  )
}

export const ScoreByDimensionProgresGraphic: FC<ScoreProgressGraphicProps> = ({
  progressValue = 0,
  percentilValue,
  justifyContent = 'normal',
  progresContainerWidth,
  backgroundColorBar = '#D2E3EC',
  isDimensionDetail = false,
}) => {
  const getProgressValueBgColor = () => {
    if (progressValue >= 0 && progressValue <= 40) {
      return '#FFD6CF'
    } else if (progressValue >= 41 && progressValue <= 60) {
      return '#FFE0BF'
    } else if (progressValue >= 61 && progressValue <= 80) {
      return '#C9AB98'
    } else if (progressValue >= 81) {
      return '#E2FFF1'
    }
  }
  return (
    <ProgressGraphicContainer justifyContent={justifyContent}>
      <ProgressContainer
        backgroundColorbar={backgroundColorBar}
        style={progresContainerWidth ? { width: `${progresContainerWidth}%` } : undefined}
      >
        <ProgressBar backgroundColorbar={backgroundColorBar} width={progressValue}>
          <ProgressValue border={getProgressValueBgColor()} backgroundColor={getProgressValueBgColor()}>
            {progressValue}
          </ProgressValue>
        </ProgressBar>
      </ProgressContainer>
      {!isDimensionDetail && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PercentilData>
            <PercentilValue>{percentilValue}</PercentilValue>
          </PercentilData>
        </div>
      )}
    </ProgressGraphicContainer>
  )
}

export default ScoreProgressGraphic
