import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { userSelector } from 'ducks/user'
import { StrokedButton } from 'views/components/UI/Buttons'
import { GetDataHome } from '../../store'
import {
  Container,
  ProcessContainer,
  TextBoldProcess,
  Row,
  ContainerItemProcess,
  ContainerButtonCreateProcess,
} from './styled'
import { H2 } from 'views/components/UI/Text'
import { fetchHomeUserEvaluated } from '../../services/fetch-home-user-evaluated'
import useReports from 'hooks/use-reports'
import WhiteContainer from 'views/components/common/WhiteContainer'
import DownloadResultCard from 'views/components/Cards/DownloadResults'
import { useTranslation } from 'react-i18next'

function DownloadResults() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userId } = useSelector(userSelector)
  const { handleReportDownload } = useReports()
  const [betesaValidation, setBetesaValidation] = useState<boolean>(false)
  const [competenceValidation, setCompetenceValidation] = useState<boolean>(false)
  const [dataDownloadResults, setDataDownloadResults] = useState<GetDataHome[] | null>(null)
  const processId = dataDownloadResults ? dataDownloadResults[0].process_id : 0

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetchHomeUserEvaluated()
        response = response.filter(
          (item: any) =>
            item.participant_report === true ||
            item.type_category.some((category: any) => category.access_report === true)
        )
        setDataDownloadResults(response)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchDataValidation = async () => {
      try {
        const competenciasFoundItem = dataDownloadResults?.some((item: any) => {
          return (
            item.participant_report === true ||
            item.type_category.some((category: any) => {
              return category.access_report === true && category.category_test_name === 'Competencias'
            })
          )
        })

        const betesaFoundItem = dataDownloadResults?.some((item: any) => {
          return (
            item.participant_report === true ||
            item.type_category.some((category: any) => {
              return category.access_report === true && category.category_test_name === 'Betesa Premium'
            })
          )
        })

        setCompetenceValidation(competenciasFoundItem || false)

        setBetesaValidation(betesaFoundItem || false)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchDataValidation()
  }, [dataDownloadResults])

  return (
    <MainLayout>
      <Container>
        <H2 margin="30px 0px">Descarga de resultados</H2>
        <ProcessContainer>
          {dataDownloadResults && dataDownloadResults.length > 0 && (
            <ContainerItemProcess>
              {dataDownloadResults.map((item) => (
                <WhiteContainer
                  key={item.process_id}
                  height="100%"
                  padding={window.innerWidth < 992 ? '0px' : '0px'}
                  shadow={window.innerWidth < 992 ? 'none' : '0px 2px 10px rgba(0, 0, 0, 0.1)'}
                >
                  <Row margin="30px 0px" width="100%" justifyContent="flex-start" flexDirection="column" height="100%">
                    <Row justifyContent="center" width="100%" alignItems="center">
                      <TextBoldProcess>
                        {' '}
                        {item.process_name} - {item.company_name}
                      </TextBoldProcess>
                    </Row>
                    <Row
                      margin="10px 0px 0px 0px"
                      width="100%"
                      alignItems="space-between"
                      justifyContent="space-between"
                      flexDirection="column"
                      height="100%"
                    >
                      <div>
                        {item.type_category.map((type, index) => (
                          <Row justifyContent="center" margin="10px 0px" key={index}>
                            {type.category_test_name === 'Betesa Premium' && betesaValidation
                              ? type.isFinished &&
                              type.domination &&
                              type.domination !== 'No interpretable' && (
                                <DownloadResultCard
                                  title="Betesa - Talentos naturales"
                                  type="betesa"
                                  onClick={() =>
                                    handleReportDownload(userId, type.test_id, processId, type.user_test_id, 'betesa')
                                  }
                                  user_id={userId}
                                  test_id={type.test_id}
                                  user_test_id={type.user_test_id}
                                  style={{ width: '90%' }}
                                />
                              )
                              : type.category_test_name !== 'Betesa Premium' &&
                              competenceValidation &&
                              type.isFinished && (
                                <DownloadResultCard
                                  title={t('global.common.skillText')}
                                  type="skills"
                                  onClick={() =>
                                    handleReportDownload(userId, type.test_id, processId, type.user_test_id, 'skills')
                                  }
                                  user_id={userId}
                                  test_id={type.test_id}
                                  user_test_id={type.user_test_id}
                                  style={{ width: '90%' }}
                                />
                              )}
                          </Row>
                        ))}
                      </div>
                      <ContainerButtonCreateProcess>
                        {item.development_plan === true ? (
                          <StrokedButton onClick={() => navigate('/development-plan')}>
                            Crear plan de desarrollo
                          </StrokedButton>
                        ) : null}
                      </ContainerButtonCreateProcess>
                    </Row>
                  </Row>
                </WhiteContainer>
              ))}
            </ContainerItemProcess>
          )}
        </ProcessContainer>
      </Container>
    </MainLayout>
  )
}

export default DownloadResults
