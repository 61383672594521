import { FC, useCallback, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { Button } from 'views/components/UI/Buttons'
import { ContainerActions, TextViewMore, TextEdit, EditContainer } from './styled'
import EditIcon from 'assets/icons/EditCoin'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { useSelector } from 'react-redux'
import { getFamilyRol } from 'services/rolFamily'
import { tokenSelector } from 'ducks/user'
import ActionDetailModal from './components/detail-modal-form/detail-modal'
import { ROLE_FAMILY_CREATE_PATH, ROLE_FAMILY_SETTINGS_PATH } from './constants'
import { superAdminRoutes } from 'router/routes'
import { useNotification } from 'lib/context/notification.context'
import { useTranslation } from 'react-i18next'

const RoleFamily: FC = () => {
  const { t } = useTranslation()
  const token = useSelector(tokenSelector)
  const { getWarning } = useNotification()
  const [familyId, setFamilyId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [actions, setActions] = useState({
    data: [],
    total_pages: 0,
  })
  const [searchParam, setSearchParam] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const getAllDevelopmentActions = useCallback(
    (page = 1, limit = 10) => {
      setCurrentPage(page);
      getFamilyRol(token, limit, page).then(({ roleFamilies, total_pages }) => {
        const formattedRoleFamilies = roleFamilies.map((familyRole: any) => ({
          ...familyRole,
          category: familyRole.category && familyRole.category.includes('Competencias')
            ? familyRole.category.replace('Competencias', t('global.common.skillText'))
            : familyRole.category,
        }));
        setActions({
          data: formattedRoleFamilies,
          total_pages: total_pages,
        });
      });
    },
    [token, t]
  );

  const ActionComponent = (item: any) => {
    const { id } = item

    const setActionAndOpenModal = () => {
      setFamilyId(id)
      setOpenModal(true)
    }
    return (
      <ContainerActions>
        <TextViewMore
          onClick={() => {
            setActionAndOpenModal()
          }}
        >
          Ver
        </TextViewMore>
        <Link to={`${superAdminRoutes.RoleFamily}/${ROLE_FAMILY_SETTINGS_PATH}/${id}`}>
          <EditContainer>
            <EditIcon />
            <TextEdit>Editar</TextEdit>
          </EditContainer>
        </Link>
      </ContainerActions>
    )
  }

  const headers = [
    {
      key: 'name',
      name: 'Nombre',
    },
    {
      key: 'level',
      name: 'Nivel',
    },
    {
      key: 'category',
      name: 'Categoria',
    },
  ]
  const fetchSearchData = useCallback(
    (searchValue: any, page = 1, limit = 10) => {
      setSearchParam(searchValue)
      if (searchValue === '') {
        getAllDevelopmentActions(page, limit)
      } else {
        getFamilyRol(token, limit, page, searchValue).then(({ roleFamilies, total_pages }) => {
          setActions({
            data: roleFamilies,
            total_pages: total_pages,
          })
        })
      }
    },
    [token, getAllDevelopmentActions]
  )

  useEffect(() => {
    if (searchParam.length > 0) {
      fetchSearchData(searchParam)
    }
  }, [searchParam, fetchSearchData])

  useEffect(() => {
    if (searchParam.length <= 0) {
      getAllDevelopmentActions()
    }
  }, [searchParam, getAllDevelopmentActions])

  const handleNextRoleFamily = () => {
    const currentFamilyId = familyId
    const listFamilyIds = actions.data.map((family: any) => family.id)

    const currentFamilyIndex = listFamilyIds.indexOf(currentFamilyId)
    const nextFamilyIndex = currentFamilyIndex + 1

    if (nextFamilyIndex < listFamilyIds.length) {
      setFamilyId(listFamilyIds[nextFamilyIndex])
    } else {
      getWarning('No hay más familias de rol')
    }
  }

  const handlePrevRoleFamily = () => {
    const currentFamilyId = familyId
    const listFamilyIds = actions.data.map((family: any) => family.id)

    const currentFamilyIndex = listFamilyIds.indexOf(currentFamilyId)
    const prevFamilyIndex = currentFamilyIndex - 1

    if (prevFamilyIndex >= 0) {
      setFamilyId(listFamilyIds[prevFamilyIndex])
    }
  }

  return (
    <MainLayout>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 35,
          marginTop: 50,
          alignItems: 'center',
        }}
      >
        <h2 style={{ fontSize: 32 }}>Familia de rol</h2>
        <Link to={`${superAdminRoutes.RoleFamily}/${ROLE_FAMILY_CREATE_PATH}`}>
          <Button>Nueva familia</Button>
        </Link>
      </div>
      <BluesiteTableComponent
        data={actions.data}
        headers={headers}
        searchBar={true}
        ActionComponent={ActionComponent}
        searchLabel="Buscar familia por:"
        totalPages={actions.total_pages}
        handlePagination={getAllDevelopmentActions}
        setSearchParam={setSearchParam}
        handleSearchFunction={() => fetchSearchData(searchParam)}
        onSearch={fetchSearchData}
        currentPage={currentPage}
      />
      <ActionDetailModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        actionId={familyId}
        onNextRoleFamily={handleNextRoleFamily}
        onPrevRoleFamily={handlePrevRoleFamily}
      />
    </MainLayout>
  )
}
export default RoleFamily
