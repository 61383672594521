import { AxiosResponse } from 'axios';
import axios from 'lib/axios';
import {FiltersGetAdminsRequestedForSuspension, RequestForSuspensionResponse, GetAdminsRequestedForSuspensionResponse} from 'types/services'


async function requestForSuspension(usersId: number[], companyId: number): Promise<RequestForSuspensionResponse> {
  const PATH = `/suspension_request/create`;

  try {
    const response: AxiosResponse<RequestForSuspensionResponse> = await axios.post(PATH, { users_id: usersId, company_id: companyId });
    return response.data;
  } catch (error) {
    console.error('Error suspending one admin user', error);
    return { status: 'ERROR', error: error, data: [] };
  }
}


async function getAdminsRequestedForSuspension(filters: FiltersGetAdminsRequestedForSuspension): Promise<GetAdminsRequestedForSuspensionResponse> {
  const PATH = '/suspension_request';
  const { page, limit} = filters;
  
    try {
      const response: AxiosResponse<GetAdminsRequestedForSuspensionResponse> = await axios.get(PATH, {
        params: {
          page: page || 1,
          limit: limit || 10,
          search: filters.search || undefined,
          request_status: filters.request_status || undefined,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error getting admins requested for suspension', error);
      return { status: 'ERROR', error: error, requests: [], total_results: 0, total_pages: 0 };
    }

}


  export interface BodySuspendAdminUser {
    userId: number;
    suspensionRequestId?: number
    statusRequest: number
    }


  async function suspendAdminUser(data: BodySuspendAdminUser[]): Promise<RequestForSuspensionResponse> {
    const PATH = `/suspension_request/user/status/update`;

    const body = {
        users: data.map((user) => {
            return {
                user_id: user.userId,
                suspension_request_id: user.suspensionRequestId,
                request_status: user.statusRequest
            }
        }
        )
    }


    try {
        const response: AxiosResponse<any> = await axios.put(PATH, body);
        return response.data;
    } catch (error) {
        console.error('Error suspending one admin user', error);
        return { status: 'ERROR', error: error, data: [] };
    }
    }




export {
    requestForSuspension,
    getAdminsRequestedForSuspension,
    suspendAdminUser
}
