export const headers = [
    {
      key: 'type_question',
      name: 'Tipo de pregunta',
    },
    {
      key: 'text',
      name: 'Texto de pregunta',
    },
  ]

export const steps = ['Información básica', 'Capítulos', 'Preguntas']

export const CHAPTER_HEADERS = [
    {
      key: 'name',
      name: 'Nombre',
    },
]

export const TEST_HEADERS = [
    {
      key: 'skill',
      name: 'Nombre',
    },
  ]