import React, { FC, useState } from 'react'
import Modal from 'views/components/UI/Modal'
import { BottomButtonsContainer, ButtonDisabled, ModalNewMapContainer, ModalNewMapText, StandardButton } from './styled'
import CloseIcon from 'assets/icons/CloseIcon'
import { TextField } from '@mui/material'
import BackButton from 'views/components/UI/BackButton'

interface NewMapModalProps {
  show: boolean
  setShow: (show: boolean) => void
  onSave: () => void
  setMapName: (newMapName: string) => void
}

export const NewMapModal: FC<NewMapModalProps> = ({ show, setShow, onSave, setMapName }) => {
  const [newMapName, setNewMapName] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const trimmedValue = value.trim();

    if (trimmedValue.length === 0) {
      return;
    }

    setMapName(value);
    setNewMapName(value);
  };

  const handleSave = () => {
    onSave()
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
    setNewMapName('')
  }
  return (
    <Modal styleContent={{ width: '530px', height: '240px' }} show={show} setShow={setShow} hideCloseButton>
      <ModalNewMapContainer>
        <BottomButtonsContainer>
          <ModalNewMapText>Crear nuevo mapa</ModalNewMapText>
          <CloseIcon onClick={handleClose} color="#000" />
        </BottomButtonsContainer>
        <TextField
          type="text"
          value={newMapName}
          onChange={handleChange}
          label="Nombre de mapa"
          sx={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029', width: '510px' }}
        />
        {newMapName.length ? (
          <BottomButtonsContainer>
            <BackButton
              style={{ width: 'auto', padding: '24px 0px' }}
              text="Cancelar"
              type="button"
              onClick={handleClose}
            />
            <StandardButton style={{ width: '15%', alignSelf: 'flex-end' }} onClick={handleSave}>
              Guardar
            </StandardButton>
          </BottomButtonsContainer>
        ) : (
          <BottomButtonsContainer>
            <BackButton
              style={{ width: 'auto', padding: '24px 0px' }}
              text="Cancelar"
              type="button"
              onClick={() => setShow(false)}
            />
            <ButtonDisabled style={{ alignSelf: 'flex-end' }}>Guardar</ButtonDisabled>
          </BottomButtonsContainer>
        )}
      </ModalNewMapContainer>
    </Modal>
  )
}

export default NewMapModal
