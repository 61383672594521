import styled from 'styled-components'

interface IRow {
  margin?: string
  width?: string
  justifyContent?: string
  alignItems?: string
  flexDirection?: string
  gap?: string
}

export const Row = styled.div<IRow>`
display: flex;
flex-direction: ${(props) => props.flexDirection || 'row'};
justify-content: ${(props) => props.justifyContent || 'center'};
align-items: ${(props) => props.alignItems || 'flex-start'};
width: ${(props) => props.width || '100%'};
padding-top: 20px;
padding-top: 20px;
margin: ${(props) => props.margin || 'none'};
gap: ${(props) => props.gap || 'none'};
`
