import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { MainLayout } from 'views/layouts'
import { Link } from 'react-router-dom'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import TitleDiv from 'views/components/UI/TitleDiv'
import Select from 'views/components/UI/Select'
import { StrokedButton } from 'views/components/UI/Buttons'

import { generalDataSelector } from 'ducks/auth'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconMagnifying from 'assets/icons/iconMagnifying.png'
import getAllQuestionSkills from 'services/common/questions/getAll'
import getSkillByFilter from 'services/common/skills/getByFilters'
import getQuestionsByFilter from 'services/common/questions/getByFilters'
import { ActionEditSkillQuestion } from 'views/components/UI/BluesiteTable/Actions/GridActions'

import { useNotification } from 'lib/context/notification.context'
import { headers } from './constants'
import { MagnifyingGlass, SearchButton } from './styled'

type Tskill = {
  questionSkills: object[]
  totalPages: number
}

const Questions: FC = () => {
  const { getWarning, getError } = useNotification()
  const { data: generalData } = useSelector(generalDataSelector)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const [skillOptions, setSkillOptions] = useState<any[]>([])
  const [filterByLevel, setFilterByLevel] = useState<string>('')
  const [filterByGroup, setFilterByGroup] = useState<string>('')
  const [filterBySkill, setFilterBySkill] = useState<string>('')
  const [filterByDimension, setFilterByDimension] = useState<string>('')
  const [triggerApi, setTriggerApi] = useState<boolean>(false)

  const [questionPagesData, setQuestionPagesData] = useState<Tskill>({
    questionSkills: [],
    totalPages: 0,
  })

  const [optionsLevelGroupDimensions, setFilterLevelGroupDimensions] = useState<any>({
    levelOptions: [],
    groupOptions: [],
    dimensionOptions: [],
  })

  function handleGetSkillByFilter(groupId?: number | undefined, levelId?: number | undefined) {
    if (groupId !== 0 && levelId !== 0) {
      getSkillByFilter({ groupId: groupId, levelId: levelId }, 1, 100).then((response) => {
        if (response.status === 'OK') {
          const skillsFormated = response.skills.map((skill: any) => {
            return {
              value: skill.id,
              label: skill.skill,
            }
          })
          setSkillOptions(skillsFormated)
        } else {
          getWarning('No se encontraron datos. intenta con otros términos')
        }
      })
    }
  }

  function checkFilter(obj: any) {
    const keys = Object.keys(obj)

    for (let i = 0; i < keys.length; i++) {
      const value = obj[keys[i]]

      if (typeof value === 'number' && value > 0) {
        return true
      }

      if (typeof value === 'string' && value.trim() !== '') {
        return true
      }
    }

    return false
  }

  const handleGetQuestionsByFilter = (page: number) => {
    setCurrentPage(page)
    const groupId = filterByGroup === '0' ? '' : filterByGroup
    const typeCategoryTestId = filterBySkill === '0' ? '' : filterBySkill
    const levelId = filterByLevel === '0' ? '' : filterByLevel
    const dimensionId = filterByDimension === '0' ? '' : filterByDimension
    const emptyRequest = checkFilter({ groupId, typeCategoryTestId, dimensionId, levelId })
    const categoryId = 2
    if (!emptyRequest && !page) {
      getError('Debes seleccionar mínimo un valor para realizar la consulta')
    }

    if (optionsLevelGroupDimensions.dimensionOptions.length > 0) {
      getQuestionsByFilter({ categoryId, groupId, typeCategoryTestId, dimensionId, levelId }, 10, page)
        .then(({ questions, total_pages }) => {
          setQuestionPagesData({
            questionSkills: questions,
            totalPages: total_pages,
          })
        })
        .catch(() => {
          getWarning('No se encontraron coincidencias con tu busqueda. intenta con otros términos')
        })
    }
  }

  useEffect(() => {
    handleGetQuestionsByFilter(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillOptions, filterByLevel, filterByGroup, filterBySkill, filterByDimension])

  useEffect(() => {
    const getAllQuestions = async (page = 1, limit = 10, categoryId = 2) => {
      await getAllQuestionSkills(limit, page, categoryId).then(({ questions, total_pages }) => {
        setQuestionPagesData({
          questionSkills: questions,
          totalPages: total_pages,
        })
      })
    }
    getAllQuestions()
    const levelOptionsData = generalData.level
      .map((level: any) => {
        if (level.id === 1 || level.name === 'Betesa' || level.name === 'Estratégico-Táctico') {
          return null
        }
        return {
          value: level.id,
          label: level.name,
        }
      })
      .filter((option: any) => option !== null)

    const groupOptionsData = generalData.group
      .map((group: any) => {
        if (group.id === 1 || group.name === 'Betesa') {
          return null
        }
        return {
          value: group.id,
          label: group.name,
        }
      })
      .filter((option: any) => option !== null)

    const dimensionOptionsData = generalData.dimension
      .map((dimension: any) => {
        if (dimension.name === 'Betesa' || dimension.name === 'betesa') {
          return null
        }
        return {
          value: dimension.id,
          label: dimension.name,
        }
      })
      .filter((option: any) => option !== null)

    setFilterLevelGroupDimensions({
      levelOptions: levelOptionsData,
      groupOptions: groupOptionsData,
      dimensionOptions: dimensionOptionsData,
    })
  }, [generalData.dimension, generalData.group, generalData.level, triggerApi])

  const handleResetFilters = () => {
    setFilterByLevel('')
    setFilterByGroup('')
    setFilterBySkill('')
    setFilterByDimension('')
    setSkillOptions([])
    setTriggerApi((prev) => !prev)
  }

  const handlePagination = (page: number) => {
    setCurrentPage(page)
    handleGetQuestionsByFilter(page)
  }

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Preguntas por competencia</h2>
      </TitleDiv>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 35, alignItems: 'center', gap: 35 }}>
        <span>Buscar por:</span>
        <Select
          label="Nivel"
          options={optionsLevelGroupDimensions.levelOptions.length > 0 ? optionsLevelGroupDimensions.levelOptions : []}
          value={filterByLevel}
          onChange={(e) => {
            setFilterByLevel(e)
            handleGetSkillByFilter(parseInt(filterByGroup), parseInt(e))
            setFilterBySkill('')
          }}
        />
        <Select
          label="Grupo"
          options={optionsLevelGroupDimensions.groupOptions.length > 0 ? optionsLevelGroupDimensions.groupOptions : []}
          value={filterByGroup}
          onChange={(e) => {
            setFilterByGroup(e)
            handleGetSkillByFilter(parseInt(e), parseInt(filterByLevel))
            setFilterBySkill('')
          }}
        />
        <Select
          disabled={filterByGroup && filterByLevel ? false : true}
          label="Competencia"
          options={skillOptions.length > 0 ? skillOptions : []}
          value={filterBySkill}
          onChange={(e) => setFilterBySkill(e)}
        />
        <Select
          label="Dimensión"
          options={
            optionsLevelGroupDimensions.dimensionOptions.length > 0 ? optionsLevelGroupDimensions.dimensionOptions : []
          }
          value={filterByDimension}
          onChange={(e) => setFilterByDimension(e)}
        />
        <div>
          <HighlightOffIcon
            style={{ cursor: 'pointer', color: 'gray', marginLeft: '-10px', marginRight: '-10px' }}
            onClick={() => {
              handleResetFilters()
            }}
          />
        </div>

        <SearchButton onClick={() => handleGetQuestionsByFilter(1)} style={{ fontSize: 14 }}>
          <MagnifyingGlass src={IconMagnifying} />
        </SearchButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 35,
          marginTop: 40,
          alignItems: 'baseline',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>Preguntas</span>
        <Link to="/modules/skills/questions/new">
          <StrokedButton>Agregar pregunta</StrokedButton>
        </Link>
      </div>
      <BluesiteTableComponent
        ActionComponent={ActionEditSkillQuestion}
        data={questionPagesData.questionSkills}
        headers={headers}
        searchBar={false}
        totalPages={questionPagesData.totalPages}
        handlePagination={handlePagination}
        currentPage={currentPage}
        searchLabel=""
      />
    </MainLayout>
  )
}
export default Questions
