import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafcff;
  padding-top: .3%;
  padding-bottom: 4%;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`
export const Title = styled.h2`
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: 574px) {
    font-size: 22px;
    line-height: 32px;
  }
`
export const Paragraph = styled.p`
  color: #1f2124;
  font-size: 18px;
  text-align: justify;
  width: 100%;
  @media (max-width: 574px) {
    font-size: 3vw;
  }
`
export const List = styled.ul`
  color: #1f2124;
  font-size: 18px;
  @media (max-width: 574px) {
    font-size: 2.5vw;
  }
`
export const CallToActionTestDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 574px) {
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
`
