/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'ducks/user/reducer'
import { setAuth } from 'ducks/auth/reducer'

import getSumary from 'services/inventory/summary'
import { tokenSelector } from 'ducks/user'
import { useNotification } from 'lib/context/notification.context'
import {
  SideBar,
  SidebarHeader,
  HeaderContentItemCollapseIcon,
  SidebarHeaderContent,
  HeaderContentItem,
  ResponsiveHeader,
  ResponsiveHeaderContainer,
  SidebarContent,
  LinksContainer,
  LinkItem,
  LinkItemTwo,
  LinkIconTwo,
  OptionItem,
  Anchor,
  CollapseIcon,
  LinkIcon,
  LogOut,
  LinkLogOut,
  LinkLogOutIcon,
  SubContent,
  LinkSubContent,
  Line,
  Divider,
  LinkItemSubContent,
  Welcome,
  RightSectionHeader,
  TeamDevelopmentIcon,
  LinkIconThree,
  LinkItemThree,
  Badge,
} from './styled'
import LogoFullBluesite from 'assets/images/logos/logo-full'
import LogoShortBluesite from 'assets/images/logos/logo-short'
import IconTeamDevelopment from '../../components/assets/team_icon.png'
import _ from 'lodash'

// routes
import { superAdminRoutes, platformAdminRoutes } from 'router/routes'
import { cleanProcess } from 'ducks/createProcess'
import HeaderCobrandingLogo from 'views/components/UI/Logo/logo-cobranding-header'

interface IProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  cobrandingLogoUrl?: string
}

function Sidebar(props: IProps) {
  const { cobrandingLogoUrl } = props

  const { getSuccess, getError, getWarning } = useNotification()
  const navigate = useNavigate()
  const token = useSelector(tokenSelector)
  const userName = useSelector((state: any) => state.user.userName)
  const { role } = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()
  const dataHome = useSelector((state: any) => state.dataHomeReducer.dataHome)
  const hasCompetenceFinished = dataHome?.some((entry: any) => {
    if (entry.type_category) {
      return entry.type_category.some(
        (category: any) => category.category_test_name === 'Competencias' && category.isFinished
      )
    }
    return false
  })

  function isAvailableReport(data: any[]) {
    const isAvailable = _.some(data, (item) => {
      const typeCategory = item.type_category || []
      return _.some(typeCategory, (category) => {
        return category.access_report || item.participant_report
      })
    })
    return isAvailable
  }

  const atLeastOneTrueAccessReport = isAvailableReport(dataHome)
  const atLeastOneTrueDevelopment = dataHome?.some((item: any) => item.development_plan === true)

  const atLeastOneTrueReport = isAvailableReport(dataHome)
  const atLeastOneTrueDomain = dataHome?.some((item: any) => {
    return item.type_category.some((category: any) => {
      return category.category_test_name === 'Betesa Premium' && category.domination
    })
  })

  const isCompetenceFinished = hasCompetenceFinished
  const isAnalitycsEnabled = atLeastOneTrueDomain
  const isDevelopmentPlanEnabled = atLeastOneTrueDevelopment
  const isDevelopmentReportEnabled = atLeastOneTrueReport
  const isTrueAccessReport = atLeastOneTrueAccessReport

  const { isOpen, setIsOpen } = props
  const [open, setOpen] = useState<boolean>(window.innerWidth < 992 ? false : isOpen)
  const handleInventory = () => {
    getSumary(token)
      .then((res: any) => {
        getSuccess('Inventario descargado con éxito')
        return res
      })
      .catch(() => {
        getError('No fue posible realizar la descarga, intenta en otro momento')
      })
  }

  const handleLinkClick = (param: string) => {
    if (!isDevelopmentPlanEnabled && param === 'plan') {
      getWarning('No tienes acceso a crear un Plan de desarrollo')
    } else if (!isDevelopmentReportEnabled && param === 'report') {
      getWarning('No tienes acceso a esta información')
    } else if (!isAnalitycsEnabled && param === 'betesaAnalitycs') {
      getWarning('Podrás acceder cuando el administrador te otorgue el permiso')
    } else if (!hasCompetenceFinished && param === 'competenciaValidate') {
      getWarning('Podrás acceder una vez contestes tus pruebas')
    }
  }

  function handleLogOut() {
    dispatch(logout())
    dispatch(
      setAuth({
        isAuth: false,
        role: '',
      })
    )
    navigate('/')
    localStorage.clear()
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setIsOpen(open)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <ResponsiveHeaderContainer>
        <ResponsiveHeader>
          <CollapseIcon isOpen={open} onClick={() => handleOpen()}>
            <HeaderContentItem
              width="30"
              height="31"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.177734" width="16" height="16" rx="1" fill="#F3F7FF" />
              <path
                d="M3.75 4.92773C3.33579 4.92773 3 4.59195 3 4.17773C3 3.76352 3.33579 3.42773 3.75 3.42773L12.25 3.42773C12.6642 3.42773 13 3.76352 13 4.17773C13 4.59195 12.6642 4.92773 12.25 4.92773L3.75 4.92773Z"
                fill="#2D749C"
              />
              <path
                d="M3.75 8.92773C3.33579 8.92773 3 8.59195 3 8.17773C3 7.76352 3.33579 7.42773 3.75 7.42773L12.25 7.42773C12.6642 7.42773 13 7.76352 13 8.17773C13 8.59195 12.6642 8.92773 12.25 8.92773H3.75Z"
                fill="#2D749C"
              />
              <path
                d="M3.75 12.9277C3.33579 12.9277 3 12.5919 3 12.1777C3 11.7635 3.33579 11.4277 3.75 11.4277H12.25C12.6642 11.4277 13 11.7635 13 12.1777C13 12.5919 12.6642 12.9277 12.25 12.9277H3.75Z"
                fill="#2D749C"
              />
            </HeaderContentItem>
          </CollapseIcon>
          <RightSectionHeader>
            {!open && <LogoShortBluesite />}
            {cobrandingLogoUrl && <HeaderCobrandingLogo logoUrl={cobrandingLogoUrl} />}
          </RightSectionHeader>
        </ResponsiveHeader>
      </ResponsiveHeaderContainer>
      <SideBar isOpen={open}>
        <SidebarHeader>
          <SidebarHeaderContent>
            {window.innerWidth > 992 && (
              <div style={{ width: '95%' }}>{open ? <LogoFullBluesite /> : <LogoShortBluesite />}</div>
            )}
            <CollapseIcon isOpen={open} onClick={() => setOpen(!open)}>
              {window.innerWidth < 992 && (
                <div style={{ marginBottom: '-24px' }}>{open ? <LogoFullBluesite /> : null}</div>
              )}
              <HeaderContentItemCollapseIcon
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                isOpen={open}
              >
                <rect rx="3" fill="#F3F7FF" />
                <path
                  d="M4.5 6.5C4.5 5.94772 4.94772 5.5 5.5 5.5C6.05228 5.5 6.5 5.94772 6.5 6.5V17.5C6.5 18.0523 6.05228 18.5 5.5 18.5C4.94772 18.5 4.5 18.0523 4.5 17.5V6.5Z"
                  fill="#2D749C"
                />
                <g clipPath="url(#clip0_1665_33199)">
                  <path
                    d="M14.625 16.0625L11.375 12.3125L14.625 8.5625"
                    stroke="#2D749C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1665_33199">
                    <rect width="15" height="13" fill="white" transform="translate(19.5 4.5) rotate(90)" />
                  </clipPath>
                </defs>
              </HeaderContentItemCollapseIcon>
            </CollapseIcon>
          </SidebarHeaderContent>
        </SidebarHeader>
        <SidebarContent>
          <Welcome isOpen={open}>¡Hola, {userName}!</Welcome>
          <LinksContainer>
            <NavLink to="/">
              <Tooltip title="Inicio">
                <LinkItem>
                  <LinkIcon isOpen={open} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clip-rule="evenodd">
                    <path d="M22 11.414v12.586h-20v-12.586l-1.293 1.293-.707-.707 12-12 12 12-.707.707-1.293-1.293zm-6 11.586h5v-12.586l-9-9-9 9v12.586h5v-9h8v9zm-1-7.889h-6v7.778h6v-7.778z" />
                  </LinkIcon>
                  <Anchor isOpen={open}>Inicio</Anchor>
                </LinkItem>
              </Tooltip>
            </NavLink>
            {/* Super Admin */}
            {role === 'super_admin' || role === 'special_admin' ? (
              <>
                <NavLink to={superAdminRoutes.Companies}>
                  <Tooltip title="Compañías">
                    <LinkItem>
                      <LinkIcon
                        isOpen={open}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M16 4h8v19h-24v-19h8v-2c0-.552.448-1 1-1h6c.552 0 1 .448 1 1v2zm7 1h-22v17h22v-17zm-3 4v1h-16v-1h16zm-5-6.5c0-.133-.053-.26-.146-.354-.094-.093-.221-.146-.354-.146h-5c-.133 0-.26.053-.354.146-.093.094-.146.221-.146.354v1.5h6v-1.5z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Compañías</Anchor>
                    </LinkItem>
                  </Tooltip>
                </NavLink>
                <NavLink to={superAdminRoutes.Users}>
                  <Tooltip title="Usuarios">
                    <LinkItem>
                      <LinkIcon isOpen={open} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17.997 18h-.998c0-1.552.06-1.775-.88-1.993-1.438-.332-2.797-.645-3.293-1.729-.18-.396-.301-1.048.155-1.907 1.021-1.929 1.277-3.583.702-4.538-.672-1.115-2.707-1.12-3.385.017-.576.968-.316 2.613.713 4.512.465.856.348 1.51.168 1.908-.49 1.089-1.836 1.4-3.262 1.728-.982.227-.92.435-.92 2.002h-.995l-.002-.623c0-1.259.1-1.985 1.588-2.329 1.682-.389 3.344-.736 2.545-2.209-2.366-4.365-.676-6.839 1.865-6.839 2.492 0 4.227 2.383 1.867 6.839-.775 1.464.824 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.81-2.214c-1.289-.298-2.489-.559-1.908-1.657 1.77-3.342.47-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.325 0 1.269.574 2.175.904 2.925h1.048c-.17-.75-1.466-2.562-.766-3.736.412-.692 1.704-.693 2.114-.012.38.631.181 1.812-.534 3.161-.388.733-.28 1.301-.121 1.648.305.666.977.987 1.737 1.208 1.507.441 1.368.042 1.368 1.48h.997l.002-.463c0-.945-.074-1.492-1.193-1.75zm-22.805 2.214h.997c0-1.438-.139-1.039 1.368-1.48.761-.221 1.433-.542 1.737-1.208.159-.348.267-.915-.121-1.648-.715-1.349-.914-2.53-.534-3.161.41-.682 1.702-.681 2.114.012.7 1.175-.596 2.986-.766 3.736h1.048c.33-.75.904-1.656.904-2.925.001-1.509-.982-2.326-2.247-2.326-1.87 0-3.17 1.787-1.4 5.129.581 1.099-.619 1.359-1.908 1.657-1.12.258-1.194.805-1.194 1.751l.002.463z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Usuarios</Anchor>
                    </LinkItem>
                  </Tooltip>
                </NavLink>
                {role === 'special_admin' && (
                  <NavLink to="/modules">
                    <Tooltip title="Módulo">
                      <LinkItem>
                        <LinkIcon
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z" />
                        </LinkIcon>
                        <Anchor isOpen={open}>Módulos</Anchor>
                      </LinkItem>
                    </Tooltip>
                  </NavLink>
                )}
                <Tooltip title="Descarga de inventarios">
                  <LinkItem onClick={handleInventory}>
                    <LinkIcon isOpen={open} xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                      <path d="M16.965 2.381c3.593 1.946 6.035 5.749 6.035 10.119 0 6.347-5.153 11.5-11.5 11.5s-11.5-5.153-11.5-11.5c0-4.37 2.442-8.173 6.035-10.119l.608.809c-3.353 1.755-5.643 5.267-5.643 9.31 0 5.795 4.705 10.5 10.5 10.5s10.5-4.705 10.5-10.5c0-4.043-2.29-7.555-5.643-9.31l.608-.809zm-4.965-2.381v14.826l3.747-4.604.753.666-5 6.112-5-6.101.737-.679 3.763 4.608v-14.828h1z" />
                    </LinkIcon>
                    <Anchor isOpen={open}>Descarga de inventarios</Anchor>
                  </LinkItem>
                </Tooltip>
                <Tooltip title="Ingreso a plataforma anterior">
                  <a href="https://bluesite.thuoper.com/" target="_blank" rel="noreferrer">
                    <LinkItem>
                      <LinkIcon
                        isOpen={open}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M2.598 9h-1.055c1.482-4.638 5.83-8 10.957-8 6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5c-5.127 0-9.475-3.362-10.957-8h1.055c1.443 4.076 5.334 7 9.902 7 5.795 0 10.5-4.705 10.5-10.5s-4.705-10.5-10.5-10.5c-4.568 0-8.459 2.923-9.902 7zm12.228 3l-4.604-3.747.666-.753 6.112 5-6.101 5-.679-.737 4.608-3.763h-14.828v-1h14.826z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Ingreso a plataforma anterior</Anchor>
                    </LinkItem>
                  </a>
                </Tooltip>
              </>
            ) : (
              ''
            )}
            {/* Admin de plataforma */}
            {role === 'platform_admin' || role === 'super_platform_admin' ? (
              <>
                <Tooltip title="Procesos">
                  <OptionItem>
                    <LinkIcon isOpen={open} xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                      <path d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z" />
                    </LinkIcon>
                    <Anchor isOpen={open}>Procesos</Anchor>
                  </OptionItem>
                </Tooltip>
                <SubContent isOpen={open}>
                  <Line isOpen={open} />
                  <LinkSubContent>
                    <LinkItemSubContent>
                      <Anchor
                        onClick={() => {
                          dispatch(cleanProcess())
                          navigate('/create-process/')
                        }}
                        isOpen={open}
                      >
                        Nuevo proceso
                      </Anchor>
                    </LinkItemSubContent>
                    <NavLink to="/unpublish-process/">
                      <LinkItemSubContent>
                        <Anchor isOpen={open}>Procesos en borrador</Anchor>
                      </LinkItemSubContent>
                    </NavLink>
                    <NavLink to={platformAdminRoutes.ProcessesCreated}>
                      <LinkItemSubContent>
                        <Anchor isOpen={open}>Procesos creados</Anchor>
                      </LinkItemSubContent>
                    </NavLink>
                  </LinkSubContent>
                </SubContent>
                <Tooltip title="Espacio de evaluados">
                  <NavLink to={platformAdminRoutes.EvaluatedUsers}>
                    <LinkItem>
                      <LinkIcon isOpen={open} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M17.997 18h-.998c0-1.552.06-1.775-.88-1.993-1.438-.332-2.797-.645-3.293-1.729-.18-.396-.301-1.048.155-1.907 1.021-1.929 1.277-3.583.702-4.538-.672-1.115-2.707-1.12-3.385.017-.576.968-.316 2.613.713 4.512.465.856.348 1.51.168 1.908-.49 1.089-1.836 1.4-3.262 1.728-.982.227-.92.435-.92 2.002h-.995l-.002-.623c0-1.259.1-1.985 1.588-2.329 1.682-.389 3.344-.736 2.545-2.209-2.366-4.365-.676-6.839 1.865-6.839 2.492 0 4.227 2.383 1.867 6.839-.775 1.464.824 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.81-2.214c-1.289-.298-2.489-.559-1.908-1.657 1.77-3.342.47-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.325 0 1.269.574 2.175.904 2.925h1.048c-.17-.75-1.466-2.562-.766-3.736.412-.692 1.704-.693 2.114-.012.38.631.181 1.812-.534 3.161-.388.733-.28 1.301-.121 1.648.305.666.977.987 1.737 1.208 1.507.441 1.368.042 1.368 1.48h.997l.002-.463c0-.945-.074-1.492-1.193-1.75zm-22.805 2.214h.997c0-1.438-.139-1.039 1.368-1.48.761-.221 1.433-.542 1.737-1.208.159-.348.267-.915-.121-1.648-.715-1.349-.914-2.53-.534-3.161.41-.682 1.702-.681 2.114.012.7 1.175-.596 2.986-.766 3.736h1.048c.33-.75.904-1.656.904-2.925.001-1.509-.982-2.326-2.247-2.326-1.87 0-3.17 1.787-1.4 5.129.581 1.099-.619 1.359-1.908 1.657-1.12.258-1.194.805-1.194 1.751l.002.463z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Espacio de evaluados</Anchor>
                    </LinkItem>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Analíticas">
                  <NavLink to="/analytics">
                    <LinkItem>
                      <LinkIcon
                        isOpen={open}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M0 22h1v-5h4v5h2v-10h4v10h2v-15h4v15h2v-21h4v21h1v1h-24v-1zm4-4h-2v4h2v-4zm6-5h-2v9h2v-9zm6-5h-2v14h2v-14zm6-6h-2v20h2v-20z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Analíticas</Anchor>
                    </LinkItem>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Desarrollo de equipos">
                  <NavLink to={platformAdminRoutes.TeamDevelopment}>
                    <LinkItemThree>
                      <LinkIconThree isOpen={open}>
                        <TeamDevelopmentIcon url={IconTeamDevelopment} />
                      </LinkIconThree>
                      <Anchor isOpen={open}>Desarrollo de equipos</Anchor>
                      <Badge>Nuevo</Badge>
                    </LinkItemThree>
                  </NavLink>
                </Tooltip>
                <Tooltip title="Ingreso a plataforma anterior">
                  <a href="https://bluesite.thuoper.com/" target="_blank" rel="noreferrer">
                    <LinkItem>
                      <LinkIcon
                        isOpen={open}
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                      >
                        <path d="M2.598 9h-1.055c1.482-4.638 5.83-8 10.957-8 6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5c-5.127 0-9.475-3.362-10.957-8h1.055c1.443 4.076 5.334 7 9.902 7 5.795 0 10.5-4.705 10.5-10.5s-4.705-10.5-10.5-10.5c-4.568 0-8.459 2.923-9.902 7zm12.228 3l-4.604-3.747.666-.753 6.112 5-6.101 5-.679-.737 4.608-3.763h-14.828v-1h14.826z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Ingreso a plataforma anterior</Anchor>
                    </LinkItem>
                  </a>
                </Tooltip>

                {/* <Tooltip title="Perfil">
                  <NavLink to={platformAdminRoutes.Administrators}>
                    <LinkItem>
                      <LinkIcon isOpen={open} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
                        // eslint-disable-next-line max-len, max-len, max-len //eliominar esta linea cuando se reactive
                        <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z" />
                      </LinkIcon>
                      <Anchor isOpen={open}>Administradores</Anchor>
                    </LinkItem>
                  </NavLink>
                </Tooltip> */}
              </>
            ) : (
              ''
            )}
            {/* Usuario evaluado */}
            {role === 'user' ? (
              <>
                <Tooltip title="Descarga de resultados">
                  {(isDevelopmentReportEnabled || isTrueAccessReport) &&
                  (isAnalitycsEnabled || isCompetenceFinished) ? (
                    <NavLink to="/download-results">
                      <LinkItem>
                        <LinkIcon
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M16.965 2.381c3.593 1.946 6.035 5.749 6.035 10.119 0 6.347-5.153 11.5-11.5 11.5s-11.5-5.153-11.5-11.5c0-4.37 2.442-8.173 6.035-10.119l.608.809c-3.353 1.755-5.643 5.267-5.643 9.31 0 5.795 4.705 10.5 10.5 10.5s10.5-4.705 10.5-10.5c0-4.043-2.29-7.555-5.643-9.31l.608-.809zm-4.965-2.381v14.826l3.747-4.604.753.666-5 6.112-5-6.101.737-.679 3.763 4.608v-14.828h1z" />
                        </LinkIcon>
                        <Anchor isOpen={open}>Descarga de resultados</Anchor>
                      </LinkItem>
                    </NavLink>
                  ) : (
                    <span>
                      <LinkItemTwo
                        className={
                          (isDevelopmentReportEnabled || isTrueAccessReport) &&
                          (isAnalitycsEnabled || isCompetenceFinished)
                            ? ''
                            : 'disabled'
                        }
                        onClick={() => {
                          if (!isDevelopmentReportEnabled || !isTrueAccessReport) {
                            handleLinkClick('report')
                          } else if (!isAnalitycsEnabled) {
                            handleLinkClick('betesaAnalitycs')
                          } else if (!isCompetenceFinished) {
                            handleLinkClick('competenciaValidate')
                          }
                        }}
                      >
                        <LinkIconTwo
                          className={isDevelopmentReportEnabled || isTrueAccessReport ? '' : 'disabled'}
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M16.965 2.381c3.593 1.946 6.035 5.749 6.035 10.119 0 6.347-5.153 11.5-11.5 11.5s-11.5-5.153-11.5-11.5c0-4.37 2.442-8.173 6.035-10.119l.608.809c-3.353 1.755-5.643 5.267-5.643 9.31 0 5.795 4.705 10.5 10.5 10.5s10.5-4.705 10.5-10.5c0-4.043-2.29-7.555-5.643-9.31l.608-.809zm-4.965-2.381v14.826l3.747-4.604.753.666-5 6.112-5-6.101.737-.679 3.763 4.608v-14.828h1z" />
                        </LinkIconTwo>
                        <Anchor isOpen={open}>Descarga de resultados</Anchor>
                      </LinkItemTwo>
                    </span>
                  )}
                </Tooltip>
                <Tooltip title="Analíticas">
                  {(isTrueAccessReport || isDevelopmentReportEnabled) &&
                  (isAnalitycsEnabled || isCompetenceFinished) ? (
                    <NavLink to="/analitycs">
                      <LinkItem>
                        <LinkIcon
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M0 22h1v-5h4v5h2v-10h4v10h2v-15h4v15h2v-21h4v21h1v1h-24v-1zm4-4h-2v4h2v-4zm6-5h-2v9h2v-9zm6-5h-2v14h2v-14zm6-6h-2v20h2v-20z" />
                        </LinkIcon>
                        <Anchor isOpen={open}>Analíticas</Anchor>
                      </LinkItem>
                    </NavLink>
                  ) : (
                    <span>
                      <LinkItemTwo
                        className={isTrueAccessReport && (isAnalitycsEnabled || isCompetenceFinished) ? '' : 'disabled'}
                        onClick={() => handleLinkClick('betesaAnalitycs')}
                      >
                        <LinkIconTwo
                          className={
                            isTrueAccessReport && (isAnalitycsEnabled || isCompetenceFinished) ? '' : 'disabled'
                          }
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M0 22h1v-5h4v5h2v-10h4v10h2v-15h4v15h2v-21h4v21h1v1h-24v-1zm4-4h-2v4h2v-4zm6-5h-2v9h2v-9zm6-5h-2v14h2v-14zm6-6h-2v20h2v-20z" />
                        </LinkIconTwo>
                        <Anchor isOpen={open}>Analíticas</Anchor>
                      </LinkItemTwo>
                    </span>
                  )}
                </Tooltip>
                <Tooltip title="Plan de desarrollo">
                  {isDevelopmentPlanEnabled && (isAnalitycsEnabled || isCompetenceFinished) ? (
                    <NavLink to="/development-plan">
                      <LinkItem>
                        <LinkIcon
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                        >
                          <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-1-17h-17v13s-.665-1-2-1c-1.104 0-2 .896-2 2s.896 2 2 2h19v-16zm-18-2.68c-.427-.971-1.327-1.325-2-1.32-.661.005-1.568.3-2 1.32v16.178c.394-1.993 2.245-2.881 4-2.401v-13.777zm15 15.68h-12v-10h12v10zm-8-9h-3v8h10v-4h-2v3h-1v-3h-3v3h-1v-7zm7 0h-6v3h6v-3z" />
                        </LinkIcon>
                        <Anchor isOpen={open}>Plan de desarrollo</Anchor>
                      </LinkItem>
                    </NavLink>
                  ) : (
                    <span>
                      <LinkItemTwo
                        className={
                          isDevelopmentPlanEnabled && (isAnalitycsEnabled || isCompetenceFinished) ? '' : 'disabled'
                        }
                        onClick={() => {
                          if (!isDevelopmentPlanEnabled) {
                            handleLinkClick('plan')
                          } else if (!isAnalitycsEnabled) {
                            handleLinkClick('betesaAnalitycs')
                          } else if (!isCompetenceFinished) {
                            handleLinkClick('competenciaValidate')
                          }
                        }}
                      >
                        <LinkIconTwo
                          className={
                            isDevelopmentPlanEnabled && (isAnalitycsEnabled || isCompetenceFinished) ? '' : 'disabled'
                          }
                          isOpen={open}
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-1-17h-17v13s-.665-1-2-1c-1.104 0-2 .896-2 2s.896 2 2 2h19v-16zm-18-2.68c-.427-.971-1.327-1.325-2-1.32-.661.005-1.568.3-2 1.32v16.178c.394-1.993 2.245-2.881 4-2.401v-13.777zm15 15.68h-12v-10h12v10zm-8-9h-3v8h10v-4h-2v3h-1v-3h-3v3h-1v-7zm7 0h-6v3h6v-3z" />
                        </LinkIconTwo>
                        {isOpen ? ' Plan de desarrollo' : ''}
                      </LinkItemTwo>
                    </span>
                  )}
                </Tooltip>
              </>
            ) : (
              ''
            )}
            <Divider />
            <Tooltip title="Cerrar sesión">
              <LogOut onClick={() => handleLogOut()}>
                <LinkLogOutIcon
                  isOpen={open}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M16 10v-5l8 7-8 7v-5h-8v-4h8zm-16-8v20h14v-2h-12v-16h12v-2h-14z" />
                </LinkLogOutIcon>
                <LinkLogOut isOpen={open}>Cerrar sesión</LinkLogOut>
              </LogOut>
            </Tooltip>
          </LinksContainer>
        </SidebarContent>
      </SideBar>
    </>
  )
}

export default Sidebar
