import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-top: 16px;
`;

export const InputGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap:20px;
`
export const TextArea = styled.textarea`
margin-top:30px;  
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;
font-size: 1rem;
`
interface IRow {
  width?: string
  margin?: string
  justifyContent?: string
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props: IRow) => props.justifyContent || 'flex-start'};
  gap: 16px;
  margin-top: 10px 0px;
  width: ${(props: IRow) => props.width || '100%'};
  margin: ${(props: IRow) => props.margin || '0px'};
`;


export const Error = styled.div`
  color: #FF0000;
  font-size: 0.8rem;
  margin-top: -12px;
  margin-left: 5px;
  margin-bottom: 28px;
`;

