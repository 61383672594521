import React, { FC } from 'react'

import { FooterStyle } from './styled'

interface BackButtonProps {
  children: React.ReactNode;
}

const Footer: FC<BackButtonProps> = ({
  children,
}) => {
  return (
    <FooterStyle>
      {children}
    </FooterStyle>
  )
}

export default Footer
