import EditConfigCards from '../edit-config-cards'
import EditBasicInfo from '../../pages/edit-role-family/edit-basic-info/edit-basic-info'
import EditSkills from '../../pages/edit-role-family/edit-skills/edit-skills'
import EditJobPosition from '../../pages/edit-role-family/edit-job-position/edit-job-position'
import EditDiagnostic from '../../pages/edit-role-family/edit-diagnostic/edit-diagnostic'
import EditIdealProfile from '../../pages/edit-role-family/edit-ideal-profile/edit-ideal-profile'

const EditConfigOptions = ({ step, setStep, familyRol }: any) => {
  switch (step) {
    case 0:
      return <EditConfigCards handleForm={setStep} information={familyRol?.role_families} />
    case 1:
      return <EditBasicInfo information={familyRol?.role_families} handleForm={setStep} />
    case 2:
      return <EditSkills information={familyRol?.family_skills} handleForm={setStep} />
    case 3:
      return <EditJobPosition
        typicalCharges='typical_positions'
        information={familyRol?.typical_positions}
        category={familyRol?.role_families.category}
        handleForm={setStep}
      />
    case 4:
      return <EditDiagnostic information={familyRol} handleForm={setStep} />
    case 5:
      return <EditIdealProfile information={familyRol.ideal_profile} handleForm={setStep} />
    default:
      return <div>Not Found</div>;
  }
}

export default EditConfigOptions
