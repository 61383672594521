import axios from 'lib/axios'

const getPlanResults = async (id: any, quadrant: string) => {
  const quadrantResults = await axios
    .get(`/betesa_results/users_list/${id}?quadrant_develop=${quadrant}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return quadrantResults
}

export default getPlanResults
