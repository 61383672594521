import axios from 'axios'

// type IChapter = {
//     id: number,
//     name: string,
//     number: string,
//     description: string,
//}

// interface IResponse  {
//     status: string | 'success' | 'error'
//     chapters: IChapter[]
// }

type IQuestionData = {
    text: string,
    weight?: number,
    quadrant?: string,
    rightful?: boolean
}


interface IData {
    name: string,
    number: string,
    description: string,
    questionData: {
        text: string,
        type_question: string,
        active: boolean,
        type_category_test_id: number,
        dimension_id: number
    },
    optionsData: IQuestionData[]
}

/**
 * @description service to create a new chapter
 * @param token
 */
const createChapter = async (token: string, data: IData) => {

  const apiUrl = `${process.env.REACT_APP_MAIN_API}/chapter`

  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
       Authorization: `Bearer ${token}`,
    }, 
  }


  const body = {
    name: data.name,
    number: data.number,
    description: data.description,
    questionData: {
        text: data.questionData.text,
        type_question: data.questionData.type_question,
        active: data.questionData.active,
        type_category_test_id: data.questionData.type_category_test_id,
        dimension_id: data.questionData.dimension_id
    },
    optionsData: data.optionsData


}

    // post request
    const response = await axios.post(apiUrl, body, config).then((res) => {
        return res.data
    }).catch((error) => {
        console.error('error on create chapter', error)
        return error
    })

    return response
}

export default createChapter
