import React from 'react';

const FileFolrderIcon: React.FC = () => {

    return (
        <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2891 19.6667C12.2891 16.9513 14.4903 14.75 17.2057 14.75H46.7057L58.9974 29.5H100.789C103.505 29.5 105.706 31.7012 105.706 34.4167V98.3333C105.706 101.049 103.505 103.25 100.789 103.25H17.2057C14.4903 103.25 12.2891 101.049 12.2891 98.3333V19.6667Z" stroke="#333333" strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M54.0859 78.668C62.2321 78.668 68.8359 72.0642 68.8359 63.918C68.8359 55.7718 62.2321 49.168 54.0859 49.168C45.9397 49.168 39.3359 55.7718 39.3359 63.918C39.3359 72.0642 45.9397 78.668 54.0859 78.668Z" stroke="#333333" strokeWidth="1.5"/>
            <path d="M66.375 73.75L78.6667 83.5833" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default FileFolrderIcon;


