import styled from "styled-components";

export const Title = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #C6016B;
  margin-bottom: 40px;
`;

export const TitleText = styled(Title)`
  margin-bottom: 8px;
`;

export const AdjustmentLevelTitle = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.7px;
  color: #738C9A;
`;

export const AdjustmentLevelText = styled(AdjustmentLevelTitle)`
  margin-left: 8px;
  text-transform: uppercase;
  color: #98B06F;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: calc(100% - 48px);
`;

export const ContainerScheme = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`

export const SchemaBetesaImg = styled.img`
  width: 100%;
  margin: 0 auto;
`

export const Text = styled.div`
  width: 100%;
  text-align: justify;
  padding-top: 40px;
  font-family: 'Lato-Regular';
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  p {
    width: 100%;
    margin: 12px 0 ! important;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  padding: 0 24px;
`;

export const GraphText = styled(Text)`
  margin: 0 !important;
  padding: 0;
`;


export const GraphTextMargin = styled(Text)`
  margin: 0 0 20px 0 !important;
  padding: 0;
`;

export const Subtitle = styled.h2`
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'Spartan-SemiBold';
  letter-spacing: -0.05em;
  color: #2d749c;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
`

export const SubtitleQuadrant = styled(Subtitle)`
  margin-top: 8px;
  margin-bottom: 8px;
`
export const SubtitleQuadrantMargin = styled(Subtitle)`
  margin-top: 8px;
  margin-bottom: 12px;
`

export const AlertLevelText = styled.span`
  margin-top: 12px;
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AlertLevelTextBold = styled.span`
  margin-left: 8px;
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const EmotionalStateText = styled.span`
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #98B06F;
  text-transform: uppercase;
`;

export const StatesDiv = styled.div`
  width: 50%;
`;

export const QuadrantWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  margin: 0 0 12px 25px;

  li p {
    font-family: 'Lato-Regular';
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
`;

export const QuadrantText = styled.span`
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;


export const ScaleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
    align-items: center;
    justify-content: center;
`;

export const Scale = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
`;

interface IScaleItem {
    color: string;
}

export const ItemScale = styled.div<IScaleItem>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 25%;
    border: ${(props: IScaleItem) => `1.5px solid ${props.color}`};
    color: ${(props: IScaleItem) => props.color};
    height: 22px;
    overflow: hidden;
`;

interface IItemScaleText {
    color: string;
}

export const ItemScaleText = styled.p<IItemScaleText>`
    // inter family
    font-family: 'Inter', sans-serif;
    font-size: 8px;
    margin: 0;
    color: ${(props: IItemScaleText) => props.color } !important ;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center !important;
`;

export const Stressors = styled.p`
  font-family: 'Lato-Regular';
  font-size: 10px !important;
  margin: 0 !important;
  padding: 0;
  font-weight: 700 !important;
  line-height: 1.2 !important;
`
