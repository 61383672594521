import styled from 'styled-components'

export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  @media (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  width: 256px;
  height: 92px;
  padding: 12px;
`

export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const Icon = styled.img`
  max-width: 60px;
`

export const UploadSizeRecomended = styled.p`
  width: 174px;
  color: #797a7c;
  font-weight: 100;
  font-size: 16px;
`

export const EditLinkWrapper = styled.div`
  display: flex;
  float: right;
  color: #2d749c;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  gap: 5px;
`

export const IconSvgProfile = styled.svg`
  color: #2d749c;
  fill: #2d749c;
  width: 24px;
  height: 24px;
`

export const SubtitleText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: #1f2124;
`
export const InputGrid = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  @media (max-width: 980px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 680px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 22px;
  width: 94px;
  height: 48px;
  background: #c6016b;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
`

export const SearchButton = styled.div`
  display: flex;
  padding: 10px 23px;
  // width: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const MagnifyingGlass = styled.img`
  width: 20px;
  height: 20px;
`
