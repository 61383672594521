import axios from 'lib/axios'
import { Routes } from 'constants/global.constants'

import { CreateUser, GetUsers, User } from '../store'
import { ADMIN_PLATFORM_TABS } from '../constants'

export const fetchGePlatformAdmins = async (
  body: GetUsers,
  page?: number,
  isForDetailProcess = false,
  oderByRecents = true,
  limit?: number
): Promise<{ users: User[]; total_pages: number; total_results: number }> => {
  try {
    const query = limit
      ? `${Routes.users}?page=${page}&orderByRecents=${oderByRecents}&isForDetailProcess=${isForDetailProcess}&limit=${limit}`
      : page
      ? `${Routes.users}?page=${page}&orderByRecents=${oderByRecents}&isForDetailProcess=${isForDetailProcess}`
      : `${Routes.users}`

    const response = await axios.post(query, body)
    const { total_pages: totalPage, total_results: totalResults, users } = response.data
    return { users, total_pages: totalPage, total_results: totalResults }
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const fetchCreatePlatformAdmin = async (body: CreateUser): Promise<User> => {
  try {
    const response = await axios
      .post(`${Routes.users}/${ADMIN_PLATFORM_TABS.create}`, body)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        return { ok: false, message: error.response.data.message }
      })
    return response
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}
