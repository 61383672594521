import { useMemo } from 'react';
import {NO_INTERPRETABLE_TEXT} from 'constants/global.constants';

const nameQuadrant = (name:string) => {
    switch(name) {
        case "FI":
            return "Frontal Izquierdo";
        case "FD":
            return "Frontal Derecho";
        case "BI":
            return "Basal Izquierdo";
        case "BD":
            return "Basal Derecho";
        default:
            return NO_INTERPRETABLE_TEXT;
    }

  return name;
};

export const useNameQuadrant = (name:string) => {
  const fullName = useMemo(() => nameQuadrant(name), [name]);
  return fullName;
};