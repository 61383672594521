import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { evaluatedUserIdSelector, setPercentiles, testIdSelector } from "../store"
import { useNotification } from "lib/context/notification.context"
import { fetchPercentiles } from "../services/fetch-percentiles.service"
import { useSelector } from "react-redux"

const usePercentiles = () => {
  const dispatch = useDispatch()
  const testId = useSelector(testIdSelector)
  const userId = useSelector(evaluatedUserIdSelector)
  const { getError } = useNotification()

  const getPercentiles = useCallback(() => {
    fetchPercentiles(userId, testId).then((percentile) => {
      dispatch(setPercentiles(percentile))
    }).catch(() => {
      getError("Error al obtener los percentiles")
    })
  }, [dispatch, getError, userId, testId])

  return {
    getPercentiles
  }
}

export default usePercentiles
