import { useMemo } from 'react';

const nameLevelAlert = (name:string) => {
  if (name === "E") return "Extravertido";
  if (name === "EI") return "Extravertido Introvertido";
  if (name === "EE:II") return "EE:II";
  if (name === "IE") return "Introvertido Extravertido";
  if (name === "I") return "Introvertido";

  return name;
};

export const useNameLevelAlert = (name:string) => {
  const fullName = useMemo(() => nameLevelAlert(name), [name]);
  return fullName;
};
