import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { P } from 'views/components/UI/Text'
import { useNotification } from 'lib/context/notification.context'
import { handleFetchError, BACK_PAGE, defaultTextTypeOptions } from '../constants'
import {
  fetchGetGeneralTextBetesaAction,
  updateGeneralTextBetesaAction,
} from '../services/fetch-betesa-texts'
import { FormContainer, LanguageRow, LanguageRowItem, Paragraph, SelectDiv, SaveButton,/*  TextArea */ } from './styled'
import Radio from '@mui/material/Radio'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import { EMPTY_STRING } from 'constants/global.constants'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const Home: FC = () => {
  const navigate = useNavigate()
  const { getWarning, getSuccess } = useNotification()
  const [descriptionText, setDescriptionText] = useState('')
  const [selectedTextType, setSelectedTextType] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('Español')

  useEffect(() => {
    if (!selectedTextType) return
    fetchGetGeneralTextBetesaAction(selectedTextType)
      .then((item) => {
        if (item.text) {
          setDescriptionText(item.text)
        } else {
          setDescriptionText('')
        }
      })
      .catch(handleFetchError)
  }, [selectedTextType])

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language)
  }

  const handleUpdate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const body = {
      text: descriptionText,
    }

    if (body.text.trim() === EMPTY_STRING) {
      getWarning('Es obligatorio que el campo no este vacio ')
      return false
    }

    updateGeneralTextBetesaAction(body, Number(selectedTextType))
      .then(() => {
        getSuccess('Texto general actualizado correctamente')
        navigate(BACK_PAGE)
      })
      .catch((error) => {
        console.error(error)
        getWarning('Error al actualizar el inventario')
      })
  }

  const handleTextTypeChange = (selectedOption: string) => {
    if (selectedOption === '') {
      setDescriptionText('')
      setSelectedTextType('')
    } else {
      setSelectedTextType(selectedOption)
    }
  }

  const handleEditorChange = (event: any, editor: { getData: () => any }) => {
    const data = editor.getData()
    setDescriptionText(data);
  }

  return (
    <MainLayout>
      <form onSubmit={handleUpdate}>
        <TitleDiv>
          <h2 style={{ fontSize: 32 }}>Generales</h2>
        </TitleDiv>
        <FormContainer>
          <LanguageRow>
            <LanguageRowItem>
              <Paragraph>Idioma del texto</Paragraph>
            </LanguageRowItem>
            <LanguageRowItem>
              <Radio
                checked={selectedLanguage === 'Español'}
                disabled={true}
                onChange={() => handleLanguageChange('Español')}
              />
              <Paragraph>Español</Paragraph>
            </LanguageRowItem>
            {/* <LanguageRowItem>
              <Radio
                checked={selectedLenguage === 'Inglés'}
                disabled={true}
                onChange={() => handleLanguageChange('Inglés')}
              />
              <Paragraph>Inglés</Paragraph>
            </LanguageRowItem> */}
          </LanguageRow>
          <SelectDiv>
            <Select
              style={{ width: '50%' }}
              label="Tipo de texto"
              hideNeverChoice={true}
              options={defaultTextTypeOptions}
              value={selectedTextType}
              onChange={handleTextTypeChange}
            />
          </SelectDiv>
          <CKEditor 
            editor={ClassicEditor} 
            data={descriptionText} 
            onChange={handleEditorChange} 
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30,
              alignItems: 'center',
            }}
          >
            <P
              onClick={() => {
                navigate(-1)
              }}
              style={{ color: '#2D749C', fontSize: 14, fontWeight: 700, cursor: 'pointer' }}
            >
              Cancelar
            </P>
            <SaveButton
              type="submit"
              disabled={!selectedTextType || !descriptionText}
              descriptionText={descriptionText}
            >
              Guardar
            </SaveButton>
          </div>
        </FormContainer>
      </form>
    </MainLayout>
  )
}
export default Home
