import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setStepOne, setStepTwo, setStepThree, setStepFour, setStepFive ,cleanProcess } from 'ducks/createProcess'
import { userSelector } from 'ducks/user';
import { platformAdminRoutes } from 'router/routes';
import { useNotification } from 'lib/context/notification.context';

/* eslint-disable */

interface StepOneData {
  processId: number;
  processName: string;
  companyId: string;
  processType: number;
  inventoryTypeId: number;
  inventoryUniqName: string;
  level: number;
  admins: Array<number>;
  automaticReports: boolean;
  testLanguage: string;
  permitDevelopmentPlan: boolean;
}

interface StepTwoData {
  processId: number;
  isRoleFamilySelected: boolean;
  roleFamilyName: string;
}

interface StepThreeData {
  processId: number;
  onlineCourse: boolean;
  useBetesaTest: boolean;
  useSkillsTest: boolean;
}

interface IdealProfileData {
  alertLevelId?: number;
  profileValues?: string;
  betesaSkills?: string;
  profile_values?: any;
  alert_level_range?: any;
}

interface StepFourData {
  processId: number;
  isNewProcess: boolean;
  betesaIdealProfile?: IdealProfileData | any;
  competencesIdealProfile?: IdealProfileData | any;
  isEditingRoleFamily?: boolean;
}

interface DataForFinishProcess {
  amount_of_tests: number;
  id: number;
  name: string;
  description: string;
  type_process_id: number;
  subscription_company_id: string;
  participant_report: boolean;
  language: string;
  development_plan: boolean;
  role_family: boolean;
  role_family_name: string;
  online_course: boolean;
  betesa_test: boolean;
  competences_test: boolean;
  step: number;
  admins: Array<number>;
  level_id: number;
  betesa_ideal_profile?: IdealProfileData;
  betesa_skills?: string;
  competences_ideal_profile?: IdealProfileData;
  subscription_company_name: string;
  users: number[];
}

interface UseFinishProcessResult {
  handleFinishProcess: (dataForFinish: DataForFinishProcess) => {
    stepOneData: StepOneData;
    stepTwoData: StepTwoData;
    stepThreeData: StepThreeData;
    stepFourData: StepFourData;
  };
}

export const useFinishProcess = (): UseFinishProcessResult => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getError } = useNotification();

  const { companyId } = useSelector(userSelector);

  const handleFinishProcess = useCallback((dataForFinish: DataForFinishProcess) => {
    const { 
      id: processId,
      name: processName, 
      type_process_id: processType,
      subscription_company_id: inventoryTypeId,
      participant_report: automaticReports,
      language: testLanguage,
      development_plan: permitDevelopmentPlan,
      role_family: isRoleFamilySelected,
      role_family_name: roleFamilyName,
      online_course: onlineCourse,
      betesa_test: useBetesaTest,
      competences_test: useSkillsTest,
      step: currentStep,
      admins,
      level_id: levelId,
      betesa_ideal_profile: betesaIdealProfile,
      betesa_skills: betesaSkills,
      competences_ideal_profile: competencesIdealProfile,
      subscription_company_name: inventoryUniqName,
      users,
    } = dataForFinish;

    const StepOneData: StepOneData = { 
      processId,
      processName, 
      companyId,
      processType,
      inventoryTypeId: parseInt(inventoryTypeId),
      inventoryUniqName,
      level: levelId,
      admins,
      automaticReports,
      testLanguage,
      permitDevelopmentPlan,
    }

    const StepTwoData: StepTwoData = {
      processId,
      isRoleFamilySelected: isRoleFamilySelected === true ? true : false,
      roleFamilyName,
    }

    const StepThreeData: StepThreeData = {
      processId,
      onlineCourse,
      useBetesaTest,
      useSkillsTest,
    }

    const StepFourData: StepFourData = {
      processId,
      isNewProcess: false,
      betesaIdealProfile: {
        alertLevelId: betesaIdealProfile?.alert_level_range,
        profileValues: betesaIdealProfile?.profile_values,
        betesaSkills
      },
      competencesIdealProfile,
    }

    if(isRoleFamilySelected) {
      StepFourData.isEditingRoleFamily = true;
    }

    const stepFiveData = {
      processId,
      userdIds: users,
    }
    
    dispatch(cleanProcess());

    dispatch(setStepOne(StepOneData as any));
    dispatch(setStepTwo(StepTwoData));
    dispatch(setStepThree(StepThreeData));
    dispatch(setStepFour(StepFourData));
    dispatch(setStepFive(stepFiveData));

    switch (currentStep) {
      case 1:
        navigate(platformAdminRoutes.CreateProcess);
        break;
      case 2:
        navigate(platformAdminRoutes.PreconfigProcess);
        break;
      case 3:
        navigate(platformAdminRoutes.SelectTestsType);
        break;
      case 4:

        if (isRoleFamilySelected) {
          navigate(platformAdminRoutes.PreconfigProcess);
        }  else {
          if (useBetesaTest) {
            navigate(platformAdminRoutes.HomologationSkills);
          }  
  
          if (useSkillsTest) {
            navigate(platformAdminRoutes.ProcessSkills);
          }
        }

        
        break;
      case 5:
        navigate(platformAdminRoutes.CreateUsers);
        break;
      default:
        getError('Ha ocurrido un error al cargar este proceso');
        break;
    }
    return {
      stepOneData: StepOneData,
      stepTwoData: StepTwoData,
      stepThreeData: StepThreeData,
      stepFourData: StepFourData,
    }
  }, [companyId, dispatch]);

  return { handleFinishProcess };
}