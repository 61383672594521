import styled from 'styled-components'

export const CardTitleWithColor = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  background: #e6eef3;
  color: #212b36;
  width: 99%;
  border-radius: 10px 10px 0 0;
  padding: 3px;
`

export const CardInfoWithBorderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  flex-direction: column;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  /* width: 558px; */
  width: 110%;
`

export const InfoUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (max-width: 1024px) {
    padding: 10px;
  }
`

export const BoldParagraph = styled.span`
  font-weight: bold;
  color: #000;
  @media (max-width: 1024px) {
    font-size: 14px;
    display: inline-block;
  }
`

export const BoldParagraphRose = styled.span`
  font-weight: bold;
  color: #c6016b;
  @media (max-width: 1024px) {
    font-size: 14px;
    display: inline-block;
  }
`

export const InfoText = styled.span`
  font-size: 16px;
  color: #000;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    font-size: 14px;
    display: inline-block;
  }
`
