import styled from 'styled-components';


export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
  padding-bottom: 20px;
`
