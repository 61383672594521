import styled from 'styled-components'

interface IRow {
  width: string
}

interface Props {
  completeTest?: boolean
  activeColumn?: boolean
}

export const Row = styled.div<IRow>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width || '100%'};
`

export const TextProcess = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #303030;
  margin-right: 10px;
`

export const CheckContainer = styled.div<Props>`
  display: flex;
  height: 25px;
  width: 25px;
  margin-left: ${(props) => (props.activeColumn ? '20px' : '60px')};
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: ${(props) => (props.completeTest ? '1px solid #449f0f' : '1px solid #C2C2C2')};
  color: ${(props) => (props.completeTest ? '#449f0f' : '#C2C2C2')};
  background-color: #fff;
`

export const ButtonEdit = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  padding-left: 88px;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

export const ButtonSave = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  border: solid 1px #2d749c;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonCancel = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #d20000;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`
