import { AxiosResponse } from 'axios';
import axios from 'lib/axios';

interface DataResponse {
    id: number;
    credits: number;
}

interface ReturnCreditsResponse {
  status: string;
  data?: DataResponse[];
  error?: any;
}

interface Users {
    userId: number;
    processId: number;
}

interface Props {
    company_id: number;
    users: Users[]
}

async function returnCreditsToInventory(body: Props): Promise<ReturnCreditsResponse> {
  const PATH = '/subscription_company/return/credit';
  try {
    const response: AxiosResponse<ReturnCreditsResponse> = await axios.put(PATH, body);
    return response.data;
  } catch (error) {
    console.error('Error trying return the inventories', error);
    return { status: 'error', error: error};
  } 
}

export default returnCreditsToInventory;
