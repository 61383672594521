export const GET_TYPE_TEXTS = "/type_general_text";
export const GENERAL_TEXT = "/basic_text/general_text";
export const GENERAL_TEXT_BETESA = "/basic_text/general_text_betesa";
export const ALERT_TEXT = "/alert_text";
export const QUADRANTS_TEXT = "/quadrants_text";
export const FITNESS_TEXT = "/fitness_quadrants_text";
export const CHALLENGE_TEXT = "/challenge_quadrants_text"

export const handleFetchError = (text: string) => {
  return text
}

export const defaultTextTypeOptions = [
  {
    value: 1,
    label: 'Introducción al esquema Betesa',
  },
  {
    value: 2,
    label: 'Introducción a los puntajes',
  },
  {
    value: 3,
    label: 'Introducción al Nivel Alerta',
  },
  {
    value: 4,
    label: 'Introducción a la condición anímica reciente',
  },
];

export const BACK_PAGE = -1;
