
import TitleDiv from 'views/components/UI/TitleDiv'
import Input from 'views/components/UI/TextInput'
import Checkbox from '@mui/material/Checkbox';
import Select from 'views/components/UI/Select'
import {
  FormContainer,
  LanguageRow,
  LanguageRowItem,
  Paragraph,
  TextArea,
  SelectDiv,
  ErrorContainer,
  TextError,
  ContainerInputs,
} from './styled'
import { Level } from 'types/common';
import { useTranslation } from 'react-i18next';

const BasicInfo = ({ props }: any) => {
  const { t } = useTranslation()
  const { handleChange, values, errors, touched, handleCheckChange, category, level } = props;

  const levelOptions = level?.filter((item: Level) => { 
    return item.name !== 'Betesa' && item.name !== 'Estratégico-Táctico';
  }).map((item: Level) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  
  return <>
    <TitleDiv>
      <h2 style={{ fontSize: 32 }}>Información basica de familia de rol</h2>
    </TitleDiv>
    <FormContainer>
      <SelectDiv>
        <ContainerInputs>
          <Input
            onChange={handleChange}
            type="text"
            name="name"
            label="Nombre"
            value={values?.name}
          />

          {
            errors.name && touched.name ? (
              <ErrorContainer>
                <TextError>{errors.name}</TextError>
              </ErrorContainer>
            ) : null
          }
        </ContainerInputs>
        <ContainerInputs>
            <Select
              hideNeverChoice
              style={{ width: '50%' }}
              label="Nivel"
              name="level_id"
              options={levelOptions}
              onChange={(e: any) => {
                handleChange('level_id')(e === '0' ? '' : e.toString())
              }}
              value={values?.level_id}
            />
          {(errors.level_id && touched.level_id) && (
            <ErrorContainer>
              <TextError>{errors.level_id}</TextError>
            </ErrorContainer>
          )}
        </ContainerInputs>
      </SelectDiv>
      <LanguageRow>
        <LanguageRowItem>
          <Paragraph>Pruebas a realizar: </Paragraph>
        </LanguageRowItem>
        {category.length >= 1 && category?.map(({ name, id }: any,) => {
          return <LanguageRowItem key={id} >
            <Checkbox
              name={name}
              onChange={(e: any) => {
                handleChange(name)(e)
              }}
              onClick={(event) => handleCheckChange(event)}
              checked={values[name]}
              disabled={values?.level_id=== '4' && name === 'Betesa Premium'}
            />
            <Paragraph>{name === 'Betesa Premium' ? 'Betesas' : t('global.common.skillText')}</Paragraph>
            <ErrorContainer>
              <TextError>{errors.Competencias}</TextError>
            </ErrorContainer>
          </LanguageRowItem>
        })}
      </LanguageRow>
      <TextArea name="description"
        onChange={(e: any) => {
          handleChange("description")(e)
        }}
        value={values.description} placeholder="Descripción" />
      {
        errors.description && touched.description ? (
          <ErrorContainer>
            <TextError>{errors.description}</TextError>
          </ErrorContainer>
        ) : null
      }
    </FormContainer>
  </>
}

export default BasicInfo
