import styled from 'styled-components';

export const Container = styled.div<{ isReportParam: boolean }>`
    display: flex;
    flex-direction: Column;
    align-items: center;
    justify-content: center;
    width: ${props => props.isReportParam ? '250px' : '100%'};
    height:  ${props => props.isReportParam ? '250px' : ''};
    margin-bottom: ${props => props.isReportParam ? '' : '2rem'};
    margin-top: ${props => props.isReportParam ? '10px' : ''};
`;

export const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;
interface IItemContainerProps {
    quadrant: 'FI' | 'FD' | 'BI' | 'BD';
    isReport?: boolean;
}

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    width:   ${(props: IItemContainerProps) => (props.isReport ? '100px': '49%')};
    height:  ${(props: IItemContainerProps) => (props.isReport ? '100px': '100px')};
    border-right: ${(props: IItemContainerProps) => (props.quadrant === 'FI' || props.quadrant === 'BI') ? '1px solid #1F2124' : 'none' };
    border-top: ${(props: IItemContainerProps) =>  (props.quadrant === 'BD'  || props.quadrant === 'BI') ? '1px solid #1F2124' : 'none' };
`;

export const ItemTagContainer = styled.div<{ isReportParam: boolean }>`
    display: flex;
    width: ${props => props.isReportParam ? '' : '60px'};
    height:  ${props => props.isReportParam ? '' : '100%'};
    font-weight: 600;
    color: #fff;
    padding: 1rem 6px 0 6px;
`;

export const CircleContainer = styled.div <{ isReportParam: boolean }>`
    display: flex;
    width: ${props => props.isReportParam ? '' : '30px'};
    height: ${props => props.isReportParam ? '' : '30px'};
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #D1E6F1;
    align-content: ${props => props.isReportParam ? 'center' : ''};
    text-align: ${props => props.isReportParam ? 'center' : ''};
    color: #1F2124;
    font-size: ${props => props.isReportParam ? '10px' : '10px'};
    padding: ${props => props.isReportParam ? '10px' : ''};
`;

export const ItemValueContainer = styled.div <{ isReportParam: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.isReportParam ? '100%' : '100%'};
    height: ${props => props.isReportParam ? '' : '90%'};
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: ${props => props.isReportParam ? '10px' : ''};
    text-align: ${props => props.isReportParam ? 'center' : ''};
    align-content: ${props => props.isReportParam ? 'center' : ''};
    p{
        margin-left: ${props => props.isReportParam ? '' : '1.2rem'};
        color: #C6016B;
        font-weight: 700;
        font-size: ${props => props.isReportParam ? '7px' : '1.2rem'};
        font-family: ${props => props.isReportParam ? 'Spartan-ExtraBold' : ''};
        margin-bottom: ${props => props.isReportParam ? '0px' : ''};
        
    }
    span{
        font-family: ${props => props.isReportParam ? 'Lato-Regular' : ''};
        font-size: ${props => props.isReportParam ? '8px' : ''};
    }
`;

export const TextPriority = styled.p <{ isReportParam: boolean }>`
    font-size: 1.2rem;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #C6016B;
`;
