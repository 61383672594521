import { headers } from '../constants'
import { Link } from 'react-router-dom'
import { EditLinkWrapper, IconSvgProfile } from '../styled'

export const renderLink = (typeCategoryId: any, result: any, dimensionId: any) => {
  const url = `/modules/skills/texts/qualitative?skill=${typeCategoryId}&result=${result}&dimensionId=${dimensionId}`

  return (
    <Link to={url} key={`edit-${typeCategoryId}-${result}-${dimensionId}`}>
      <EditLinkWrapper>
        <IconSvgProfile viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m19 20.25c0-.402-.356-.75-.75-.75-2.561 0-11.939 0-14.5 0-.394 0-.75.348-.75.75s.356.75.75.75h14.5c.394 0 .75-.348.75-.75zm-7.403-3.398 9.124-9.125c.171-.171.279-.423.279-.684 0-.229-.083-.466-.28-.662l-3.115-3.104c-.185-.185-.429-.277-.672-.277s-.486.092-.672.277l-9.143 9.103c-.569 1.763-1.555 4.823-1.626 5.081-.02.075-.029.15-.029.224 0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342 2.137 2.137-3.168 1.046zm.955-1.166 7.651-7.616 2.335 2.327-7.637 7.638z"
            fillRule="nonzero"
          />
        </IconSvgProfile>
        <label style={{ cursor: 'pointer' }} className="link-editar">
          Editar
        </label>
      </EditLinkWrapper>
    </Link>
  )
}

export const modifiedHeaders = headers.map((header) => {
  if (header.key === 'action') {
    return {
      ...header,
      render: renderLink,
    }
  }
  return header
})
