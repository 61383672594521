
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutProps {
  labels: string[];
  totalTests: number;
  type: 'summary' | 'comparison';
  dataset: number[]
}

function drawMultilineText(ctx: any, text: any, x: any, y: any) {
  const lineHeight = 16;
  const lines = text.split(' ');

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const lineY = y + i * lineHeight;
    ctx.fillText(line, x, lineY);
  }
}



export default function DoughnutChart(props: DoughnutProps) {
  const { type, labels, dataset, totalTests } = props;

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;
      const text = type === 'summary' ? 'Total pruebas generadas' : 'Total pruebas contestadas';

      ctx.save();

      ctx.font = 'normal 12px Public Sans';
      ctx.fillStyle = '#707070';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
    

      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;

      drawMultilineText(ctx, text, centerX, centerY - 32);
      ctx.font = 'bold 30px Public Sans';
      ctx.fillStyle = '#212B31';
      ctx.fillText(`${totalTests}`, centerX, centerY + 32);
      ctx.restore();
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Pruebas generadas',
        data: dataset || [],
        backgroundColor: [
          '#2D749C',
          '#212B31',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderWidth: 0,
        cutout: '90%',
      },
    ],
    text: '',
  };

    const options = {
    responsive: true,
    scales: {
      
    },
    plugins: {
      legend: {
        position: "right" as const,
        labels:{
          boxWidth:12,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12,
            lineHeight: 10,
            innerWidth: 10,
          },
        },
        generateLabels: (chart: any) => {
          const originalLabels = chart.legend.options.labels.generateLabels(chart);
          const newLabels = originalLabels.map((label: any) => {
            const words = label.text.split(' ');
            const lines = [];
            let line = '';

            for (let i = 0; i < words.length; i++) {
              const testLine = line + words[i] + ' ';
              const metrics = chart.ctx.measureText(testLine);
              const testWidth = metrics.width;

              if (testWidth > 30 && i > 0) {
                lines.push(line.trim());
                line = words[i] + ' ';
              } else {
                line = testLine;
              }
            }

            lines.push(line.trim());

            return {
              ...label,
              text: lines.join('\n'),
            };
          });

          return newLabels;
        },
      },
      centerText: {
        display: true,
        text: "300" as const,
    },
    },
    
  };

  return (
    <div style={{marginTop: '-3.3rem', }}>
      <Doughnut data={data} options={options} plugins={[textCenter]} />
    </div>
  );
}


