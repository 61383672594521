import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { MainLayout } from 'views/layouts'
import { TextArea, ModulesQuestionContainer } from './styled'
import { Button } from 'views/components/UI/Buttons'
import Select from 'views/components/UI/Select'
import TitleDiv from 'views/components/UI/TitleDiv'
import { fetchCreateSkillsPlan } from '../../services/fetch-skills-talents';
import { generalDataSelector } from 'ducks/auth';
import { Action } from '../../store';
import { useSelector } from 'react-redux';
import { BACK_PAGE } from '../constants';
import { useNotification } from 'lib/context/notification.context';
import getSkillByFilter from 'services/common/skills/getByFilters';
import { EMPTY_STRING } from 'constants/global.constants';

const CreateTalent: FC = () => {
  const navigate = useNavigate();
  const { getError, getSuccess } = useNotification();
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [competencies, setCompetencies] = useState<any[]>([])
  const { data: generalData } = useSelector(generalDataSelector);
  const [optionsLevelGroup, setFilterLevelGroup] = useState({
    levelOptions: []
  })
  const [filterByLevel, setFilterByLevel] = useState<string>('')

  const dimensionsWithoutBetesa = generalData?.dimension?.filter(({ id }: any) => id !== 4).map(({ name }: any) => ({ value: name, label: name }));

  const newTalentSchema = Yup.object().shape({
    dimension: Yup.string().required('Debes asignar una competencia'),
    description: Yup.string().required('El nombre del contacto es obligatorio'),
  })

  function handleGetSkillByFilter(levelId?: number | undefined) {
    if (levelId !== 0) {
      getSkillByFilter({ levelId: levelId }, 1, 100)
        .then((response) => {
          if (response.status === 'OK') {
            const skillsFormated = response.skills.map((skill: any) => {
              return {
                value: skill.id,
                label: skill.skill
              }
            })
            setCompetencies(skillsFormated)
          } else {
            getError('No se encontraron datos. intenta con otros términos')
          }
        })
    }
  }

  const onSubmit = (values: Action) => {
    fetchCreateSkillsPlan({
      name_spa: values.name,
      category: values.quadrant,
      description_spa: values.description,
      dimension: values.dimension,
      type_category_test_id: values.competenceId,
    }).then(() => {
      getSuccess('Acción creada correctamente')
    }
    ).catch(() => {
      getError('No se pudo crear la competencia')
    })
  }

  useEffect(() => {
    const levelOptionsData = generalData.level.map((level: any) => {
      if (level.id === 1 || level.name === 'Betesa' || level.name === 'Estratégico-Táctico') {
        return null;
      }
      return {
        value: level.id,
        label: level.name,
      }
    }).filter((option: any) => option !== null);
    setFilterLevelGroup({
      levelOptions: levelOptionsData
    })
  }, [generalData.level])


  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 28, margin: 0 }}>Plan de desarrollo de competencias por dimensión</h2>
      </TitleDiv>
      <Formik
        validateOnChange={true}
        validationSchema={newTalentSchema}
        onSubmit={(values, { resetForm }) => {
          if (!isUploading) {
            setIsUploading(true)
            setButtonDisabled(true)
            onSubmit({
              name: values.competenceLabel,
              quadrant: 'Competence',
              description: values.description,
              competenceId: values.competenceId,
              dimension: values.dimension,
            })
            setTimeout(() => {
              setButtonDisabled(false)
              navigate(BACK_PAGE)
              resetForm()
              setIsUploading(false)
            }, 2000)
          }
        }} initialValues={{
          name: '',
          quadrant: '',
          description: '',
          competenceId: 0,
          dimension: '',
          competenceLabel: '',
        }}

      >{({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, errors }) => (
        <>
          <ModulesQuestionContainer>
            <Select
              label="Nivel"
              options={optionsLevelGroup.levelOptions.length > 0 ? optionsLevelGroup.levelOptions : []}
              value={filterByLevel}
              hideNeverChoice={true}
              onChange={(e) => {
                setFilterByLevel(e)
                handleGetSkillByFilter(parseInt(e))
              }}
            />
            <Select
              disabled={filterByLevel === EMPTY_STRING}
              label="Competencia asociada"
              options={competencies}
              value={values.competenceId}
              hideNeverChoice={true}
              onChange={e => {
                const selectedId = e.toString();
                const selectedCompetency = competencies.find(comp => comp.value.toString() === selectedId);

                if (selectedCompetency) {
                  setFieldValue('competenceId', selectedId);
                  setFieldValue('competenceLabel', selectedCompetency.label);
                }
              }}
            />
            <Select
              hideNeverChoice
              label="Dimensión"
              options={dimensionsWithoutBetesa}
              value={values.dimension}
              onChange={e => {
                handleChange('dimension')(e.toString())
              }}
            />
          </ModulesQuestionContainer>
          <TextArea placeholder="Descripción" onChange={({ target }) => setFieldValue('description', target.value)} value={values.description} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 35,
              marginTop: 40,
              alignItems: 'baseline',
            }}
          >
            <span style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }} onClick={() => { navigate(-1) }} >Cancelar</span>

            <Button disabled={isSubmitting || Object.keys(errors).length > 0 || buttonDisabled} type="submit" onClick={handleSubmit}>
              Guardar
            </Button>
          </div>
        </>
      )}
      </Formik>

    </MainLayout>
  )
}
export default CreateTalent
