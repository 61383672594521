import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  height: 100%;

  @media (max-width: 992px) {
  }

  @media (max-width: 580px) {
    text-align: center;
    margin-top: 0rem;
  }

  /* @media (max-width: 400px) {
        margin-top: -1rem;
    } */
`

interface IProcessContainer {
  justifyContent?: string
  alignItems?: string
  display?: string
  margin?: string
  padding?: string
  width?: string
}

export const ProcessContainer = styled.div<IProcessContainer>`
  width: 100%;
  height: auto;
  padding: 1% 0%;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  text-align: center;
  overflow: visible;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ContainerItemProcess = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 16px;
  @media (max-width: 992px) {
    width: 100%;
    margin: 4% 0%;
    max-width: 100%;
  }

  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    grid-gap: 16px;
  }
`

export const ContainerButtonCreateProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  margin-top: 30px;
`

interface IRow {
  justifyContent?: string
  alignItems?: string
  display?: string
  margin?: string
  padding?: string
  width?: string
  height?: string
  flexDirection?: string
  backgroundColor?: string
  alignSelf?: string
}

export const Row = styled.div<IRow>`
  width: ${({ width }) => width || 'auto'};
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  align-self: ${({ alignSelf }) => alignSelf || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  height: ${({ height }) => height || 'auto'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
`

export const TextBoldProcess = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #303030;
  margin: 0px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 564px) {
    font-size: 16px;
  }
`
