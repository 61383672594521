import styled from 'styled-components';

export const ButtonUpload = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 54px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
        opacity: 0.8;
        transition: 0.3s;
        transform: scale(1.2);
    }
`;