import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

interface Mark {
  value: number;
  label: string;
}

interface RangeProps {
  onChange?: (rangeValues: number[]) => void;
  initialValue?: number[];
}

const MAX_VALUE = 110;
const MIN_VALUE = 10;
const MIN_RANGE_DISTANCE = 10;

const marks: Mark[] = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 110,
    label: '110',
  },
];

function valuetext(value: number) {
  return `${value}°C`;
}


export default function Range({ onChange, initialValue = [50, 60] }: RangeProps) {
  const [value, setValue] = React.useState<number[]>(initialValue);

  // const handleChange = (
  //   event: Event,
  //   newValue: number | number[],
  //   activeThumb: number,
  // ) => {
  //   if (!Array.isArray(newValue)) {
  //     return;
  //   }

  //   if (newValue[1] - newValue[0] < MIN_RANGE_DISTANCE) {
  //     if (activeThumb === 0) {
  //       const clamped = Math.min(newValue[0], MAX_VALUE - MIN_RANGE_DISTANCE);
  //       setValue([clamped, clamped + MIN_RANGE_DISTANCE]);
  //     } else {
  //       const clamped = Math.max(newValue[1], MIN_RANGE_DISTANCE);
  //       setValue([clamped - MIN_RANGE_DISTANCE, clamped]);
  //     }
  //   } else {
  //     setValue(newValue as number[]);
  //   }

  //   if (onChange) {
  //     onChange(newValue as number[]);
  //   }
  // };

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    let newMin = newValue[0];
    let newMax = newValue[1];

    if (newMax - newMin < MIN_RANGE_DISTANCE) {
      if (activeThumb === 0) {
        newMin = Math.min(newMax - MIN_RANGE_DISTANCE, newMin);
      } else {
        newMax = Math.max(newMin + MIN_RANGE_DISTANCE, newMax);
      }
    }

    newMin = Math.max(MIN_VALUE, newMin);
    newMax = Math.min(MAX_VALUE, newMax);

    setValue([newMin, newMax]);

    if (onChange) {
      onChange([newMin, newMax]);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        getAriaLabel={() => 'Minimum distance shift'}
        min={MIN_VALUE}
        max={MAX_VALUE}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
        marks={marks}
        sx={{
          color: '#2D749C',
          '& .MuiSlider-rail': {
            color: '#2D749C',
          },
          '& .MuiSlider-track': {
            color: '#2D749C',
          },
          '& .MuiSlider-thumb': {
            color: '#fff',
            backgroundColor: '#2D749C',
          },
          '& .MuiSlider-valueLabel': {
            color: '#2D749C',
          },
          '& .MuiSlider-valueLabelCircle': {
            color: '#fff',
          },
        }}
      />
    </Box>
  );
}
