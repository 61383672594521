import axios from 'lib/axios'

interface Iprops {
  userId: number
  firstName: string
  lastName: string
  email?: string
  document?: string
}

const updateProcessUser = async (props: Iprops) => {
  const { firstName, lastName, userId, email, document } = props

  const path = `/person/update`

  const body: any = {
    userId: userId,
    first_name: firstName,
    last_name: lastName,
    document: document,
    email: email,
  }

  try {
    const response = await axios.put(path, body)
    return response.data
  } catch (error: any) {
    return { status: 'error', message: error.response.data.message, error: error }
  }
}

export default updateProcessUser
