import {
  Circle,
} from '../styled';

const AlertCircle = ({ userAlertLevelName }: any) => {
  const namesMap = {
    E: "Extravertido",
    EI: "Extravertido Introvertido",
    'EE-II': "EE:II",
    IE: "Introvertido Extravertido",
    I: "Introvertido",
  };

  return (
    <>
      {Object.entries(namesMap).map(([shortName, displayName]) => (
        <Circle active={shortName === userAlertLevelName ? true : false} key={Math.random().toString(36).substring(7)}>
          <h4 style={{ fontSize:8}}>{displayName}</h4>
        </Circle>
      ))}
    </>
  );
};
export default AlertCircle
