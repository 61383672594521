import styled from 'styled-components'
import { SaveButtonProps } from './store/entities'

export const TextArea = styled.textarea`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 30px;
  width: 100%;
  height: 120px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  /* s1 */

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const ActionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-top: 40px;
  align-items: baseline;
`
export const InputGrid = styled.div`
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
export const LanguageRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
export const LanguageRowItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`
export const Paragraph = styled.p`
  color: #1f2124;
  font-size: 18px;
  @media (max-width: 574px) {
    font-size: 2.5vw;
  }
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const SaveButton = styled.button<SaveButtonProps>`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: ${(props) => {
    const isDisabled = props.disabled
    const hasDescriptionText = props.descriptionText
    if (isDisabled) {
      return '#C2C2C2'
    } else if (hasDescriptionText) {
      return '#C6016B'
    } else {
      return '#C2C2C2'
    }
  }};
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: ${(props) => (props.descriptionText ? 'pointer' : 'default')};
`

export const InputContainer = styled.div`
  margin-top: 30px;
`
