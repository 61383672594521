import axios from 'lib/axios'
import { ACTION_TABS, DEVELOPMENT_PLAN_TABS } from '../Actions/constants'
import { DevelopmentPlan } from '../store'

export const fetchCreateSkillsPlan = async (data: any) => {
  const res = await axios
    .post(`/${DEVELOPMENT_PLAN_TABS.create}`, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export const fetchCreateSkillsAction = async (data: any) => {
  const res = await axios
    .post(`/${ACTION_TABS.create}`, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export const fetchGetActionsById = async (id: number): Promise<DevelopmentPlan> => {
  const res = await axios
    .get(`/${DEVELOPMENT_PLAN_TABS.action}/${id}`)
    .then((response) => {
      return response.data.development_plan
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res as DevelopmentPlan
}

export const fetchUpdateSkillsAction = async (body: any, id: number) => {
  const res = await axios
    .put(`/${ACTION_TABS.update}/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}
