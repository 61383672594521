import { FC } from 'react'
import Modal from 'views/components/UI/Modal'
import { P, H2 } from 'views/components/UI/Text'
import { Button } from 'views/components/UI/Buttons'
import { Row, CancelModalButton, NegativeCircleContainer} from './styled'
import NegativeWithRedCircle from 'assets/icons/NegativeWithRedCircle'
interface ModalSuspendUserProps {
    isOpen: boolean
    handleClose: () => void
    handleSuspendUser: (userId :number) => void
    userId: number
}

const ModalSuspendUser: FC<ModalSuspendUserProps> = ({ isOpen, handleClose, handleSuspendUser, userId }) => {


    return (
        <Modal
            show={isOpen}
            setShow={handleClose}
            styleContent={{
                maxWidth: '600px',
                textAlign: 'center',
            }}
        >
             <NegativeCircleContainer>
                <NegativeWithRedCircle  width='90px' height='90px'/>
             </NegativeCircleContainer>
            <H2>
                ¿Estás seguro que deseas suspender este usuario?
            </H2>
            <P style={{margin: '20px 0px'}}>
                Si suspendes a este usuario no podrá ingresar a su cuenta de Bluesite. Podrás reactivarlo en cualquier momento y su información no se perderá.
            </P>
            <Row>
                <CancelModalButton onClick={handleClose}>Cancelar</CancelModalButton>
                <Button
                    onClick={() => handleSuspendUser(userId)}
                    >
                    Suspender
                    </Button>
            </Row>

        </Modal>
    )
}

export default ModalSuspendUser