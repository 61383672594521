import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import getGeneralData from 'services/common/generalData'
import { setAuth, setGeneralData } from 'ducks/auth'
import { userSelector } from 'ducks/user'
import { logout } from 'ducks/auth'

const useAuthentication = (): boolean => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const checkTokenValidity = async () => {
      if (user && user.token !== '') {
        const decodeToken: any = jwtDecode(user.token) ?? {}

        if (decodeToken.exp < Date.now() / 1000) {
          dispatch(
            setAuth({
              role: '',
              isAuth: false,
            })
          )
          localStorage.clear()
          dispatch(setGeneralData({}))
          dispatch(logout())

          return
        }

        setIsLoading(true)
        try {
          const res: any = await getGeneralData(user.token)

          if (res && res.error === 'Authentication Error') {
            dispatch(logout())
            localStorage.clear()
            return
          }

          if (res && typeof res === 'object') {
            dispatch(
              setGeneralData({
                data: Object.keys(res).length > 0 ? res : {},
              })
            )
          }
        } catch (error) {
          console.error('Error getting general data:', error)
        } finally {
          setIsLoading(false)
        }

        dispatch(
          setAuth({
            role: decodeToken.roleType,
            isAuth: true,
          })
        )
      }
    }

    checkTokenValidity()
  }, [dispatch, user])

  return isLoading
}

export default useAuthentication
