import styled from 'styled-components'

interface Props {
  nineBox?: boolean
  historyPlan?: boolean
  sessionData?: boolean
  disabled?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  overflow: hidden;
`

export const SubContainer = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.nineBox || props.historyPlan || props.sessionData ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 20px;
`

export const Title = styled.div`
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`

export const SubTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 18px;
  width: 85%;
`

export const SubTitles = styled.div`
  font-size: 16px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const SubTitlesMiddle = styled(SubTitles)`
  margin-left: -440px;

  @media (max-width: 1680px) {
    margin-left: -350px;
  }
  @media (max-width: 1600px) {
    margin-left: -330px;
  }
  @media (max-width: 1536px) {
    margin-left: -300px;
  }
  @media (max-width: 1366px) {
    margin-left: -265px;
  }
  @media (max-width: 1280px) {
    margin-left: -250px;
  }
  @media (max-width: 1024px) {
    margin-left: -250px;
  }
`

export const SubTitlesLast = styled(SubTitles)`
  @media (max-width: 1024px) {
    margin-right: -50px;
  }
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 95%;
`

export const UsersButton = styled.div`
  display: flex;
  height: 20px;
  width: 150px;
  padding: 11px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #2d749c;
  color: #2d749c;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
  margin-top: 40px;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const AddUsersButton = styled.div<Props>`
  display: flex;
  height: 11px;
  width: 30px;
  padding: 9px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: ${(props) => (props.disabled ? '1px solid #c2c2c2' : '1px solid #2d749c')};
  color: ${(props) => (props.disabled ? '#c2c2c2' : '#2d749c')};
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const UsersInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
`

export const UsersModalText = styled.div`
  color: #1f2124;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const UsersModalTitle = styled.div`
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 10px 16px;
`

export const UsersSearchButton = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ResetButton = styled.div`
  margin-left: 30px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const DeleteButtonText = styled.div`
  color: #e44646;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`

export const DeleteButtonIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`

export const UsersGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  // height: 100%;
  padding: 2% 1% 0 1%;
`

export const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const StandardButton = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c6016b;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const BottomButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonDisabled = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c2c2c2;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`

export const ButtonEdit = styled.div`
  display: block;
  color: #2d749c;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 700;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`

export const SearchButton = styled.div`
  display: flex;
  padding: 14px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const MagnifyingGlass = styled.img`
  width: 20px;
  height: 20px;
`
