import styled from "styled-components";

interface IDiv {
    margin?: string;
}


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 1200px;
    padding: 3.5%;
`;

export const TopButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0px;
`;

export const InfoUserContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
`;

export const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 43%;
    height: 100%;
    border-radius: 10px;
`;

export const CardInfo = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: white;
    flex-direction: column;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    margin: 20px 0px;
    padding: 20px;
`;


export const SkillsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
`;

export const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 3% 0% 3% 3%;
`;

export const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const SubContainerChart = styled.div`    
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const RowContainer = styled.div<IDiv>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    margin: ${props => props.margin};
    gap: 20px;
`;