import styled from 'styled-components'

export const ModulesContainer = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: row; 
  gap: 16px;
`
export const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row; 
  width: 456px;
  height: 92px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const CardIconSection = styled.div`
  height: 82px;
  width: 70px;
  background: #81ACC4;
  border-radius: 16px 0px 0px 16px;
  padding-top: 12px;
  padding-left: 12px;
`
export const CardDescriptionSection = styled.div`
  margin: 10px;
`
export const CardTitle = styled.div`
  width: 218px;
  height: 48px;
  left: 120px;
  top: 10px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1F2124;
`
export const Icon = styled.img`
  max-width: 60px;
`

export const UploadSizeRecomended = styled.p`
  width:174px;
  color: #797A7C;
  font-weight: 100;
  font-size: 16px;
`