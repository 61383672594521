import { FC, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import TitleDiv from 'views/components/UI/TitleDiv'
import { StrokedButton } from 'views/components/UI/Buttons';
import { cleanDataNewQuestionnaire } from 'ducks/modules'
import { ActionEditQuestionnaire } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import getSkillsByFilter from 'services/common/skills/getByFilters';
import { specialAdminRoutes } from 'router/routes'
import { TEST_HEADERS } from './constants'

const QuestionariesBetesa: FC = () => {
  const dispatch = useDispatch()


  const [questionarys, setQuestionarys] = useState<any>([])
  const [searchParam, setSearchParam] = useState('');
  const [totalPages, setTotalPages] = useState(1)


  const handleShowAll = useCallback((page = 1, limit = 10) => {
    getSkillsByFilter({ categoryId: 1 }, page, limit, searchParam).then((res) => {
      setQuestionarys(res.skills)
      setTotalPages(res.total_pages);
    })
      .catch((err) => {
        console.error(err);
      });
  }, [searchParam])

  useEffect(() => {
    handleShowAll()
    dispatch(cleanDataNewQuestionnaire())
  }, [dispatch, handleShowAll])


  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Cuestionarios - Betesa - Talentos Naturales</h2>
        <Link to={specialAdminRoutes.NewQuestionnaireBetesa}>
          <StrokedButton >
            Nuevo cuestionario
          </StrokedButton>
        </Link>
      </TitleDiv>
      <div style={{ padding: '20px 0px' }}>
        <BluesiteTableComponent
          ActionComponent={ActionEditQuestionnaire}
          data={questionarys}
          headers={TEST_HEADERS}
          currentPage={1}
          searchBar={false}
          totalPages={totalPages}
          setSearchParam={setSearchParam}
          handlePagination={handleShowAll}
          handleSearchFunction={() => handleShowAll()}
          searchLabel='' />
      </div>
    </MainLayout>
  )
}

export default QuestionariesBetesa
