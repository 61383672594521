import React, { useState, useEffect, useCallback } from 'react';
import { Container, Quadrant, HeadQuadrant, BodyQuadrant, SelectContainer } from './styled';
import WhiteContainer from 'views/components/common/WhiteContainer';
import Range from 'views/components/Range';
import { H6, H3 } from 'views/components/UI/Text';
import Select from 'views/components/UI/Select';
import { QUADRANTS, QuadrantType, OPPOSITE_QUADRANTS, OPTIONS_SELECT, QUADRANT_INITIALS, INITIALS_TO_QUADRANTS, BrainQuadrantProps, } from './constants';
import { parseQuadrant } from './main';

const BrainQuadrant: React.FC<BrainQuadrantProps> = ({
  onChange,
  defaultQuadrant = '',
  quadrantData = []
}: BrainQuadrantProps) => {

  const initialPriorities: Record<QuadrantType, number> = {
    [QUADRANTS.FRONT_LEFT]: 0,
    [QUADRANTS.FRONT_RIGHT]: 0,
    [QUADRANTS.BASAL_LEFT]: 0,
    [QUADRANTS.BASAL_RIGHT]: 0,
  };

  const initialRangeValues: Record<QuadrantType, number[]> = {
    [QUADRANTS.FRONT_LEFT]: [10, 10],
    [QUADRANTS.FRONT_RIGHT]: [10, 10],
    [QUADRANTS.BASAL_LEFT]: [10, 10],
    [QUADRANTS.BASAL_RIGHT]: [10, 10],
  };

  const defaultPriorities = quadrantData.length > 0 ? quadrantData.reduce((acc, data) => ({
    ...acc, [parseQuadrant(data.quadrant)]: data.priority
  }), initialPriorities) : initialPriorities;

  const defaultRangeValues = quadrantData.length > 0 ? quadrantData.reduce((acc, data) => ({
    ...acc, [parseQuadrant(data.quadrant)]: [data.min, data.max]
  }), initialRangeValues) : initialRangeValues;

  const [priorities, setPriorities] = useState<Record<QuadrantType, number>>(defaultPriorities);
  const [rangeValues, setRangeValues] = useState<Record<QuadrantType, number[]>>(defaultRangeValues);

  const handleLogic = useCallback((quadrant: QuadrantType, value: string) => {
    const intValue = parseInt(value, 10);
    const inverseValue = 5 - intValue;
    const newPriorities: any = { ...priorities, [quadrant]: intValue, [OPPOSITE_QUADRANTS[quadrant]]: inverseValue };
    const newRangeValues: any = { ...rangeValues };

    Object.keys(newPriorities).forEach((key) => {
      if (key !== quadrant && key !== OPPOSITE_QUADRANTS[quadrant] && (newPriorities[key] === intValue || newPriorities[key] === inverseValue)) {
        newPriorities[key] = newPriorities[key] <= 2 ? newPriorities[key] + 2 : newPriorities[key] - 2;
      }
    });

    setPriorities(newPriorities);

    if (onChange) onChange(
      Object.entries(newPriorities).map(([quadrantKey, priority]) => ({
        quadrant: QUADRANT_INITIALS[quadrantKey as QuadrantType],
        priority,
        min: newRangeValues[quadrantKey] ? newRangeValues[quadrantKey][0] : 0,
        max: newRangeValues[quadrantKey] ? newRangeValues[quadrantKey][1] : 0
      }))
    );
  }, [priorities, rangeValues, onChange]); 

  useEffect(() => {
    if (defaultQuadrant && defaultQuadrant !== '') {
      const defaultQuadrantKey = INITIALS_TO_QUADRANTS[defaultQuadrant];
      handleLogic(defaultQuadrantKey, '1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultQuadrant]);
 

  const handleRangeChange = (quadrant: QuadrantType, newValue: number[]) => {
    const newRangeValues: any = {
      ...rangeValues,
      [quadrant]: newValue,
    };
    setRangeValues(newRangeValues);
  
    if (onChange) {
      onChange(
        Object.entries(priorities).map(([quadrantKey, priority]) => ({
          quadrant: QUADRANT_INITIALS[quadrantKey as QuadrantType],
          priority,
          min: newRangeValues[quadrantKey] ? newRangeValues[quadrantKey][0] : 0,
          max: newRangeValues[quadrantKey] ? newRangeValues[quadrantKey][1] : 0
        }))
      );
    }
  };

  const renderQuadrant = (title: string, quadrantKey: QuadrantType) => (
    <Quadrant>
      <WhiteContainer>
        <HeadQuadrant>
          <H3>{title}</H3>
          <SelectContainer>
            <Select
              value={priorities[quadrantKey]?.toString() || ''}
              onChange={value => handleLogic(quadrantKey, value)}
              label="Prioridad"
              options={OPTIONS_SELECT}
              hideNeverChoice
            />
          </SelectContainer>
        </HeadQuadrant>
        <BodyQuadrant>
          <H6>Rango esperado</H6>
          <Range  
            initialValue={rangeValues[quadrantKey] || [0, 0]} 
            onChange={(e: number[]) => handleRangeChange(quadrantKey, e)}
          />
        </BodyQuadrant>
      </WhiteContainer>
    </Quadrant>
  );

  return (
    <Container>
      {renderQuadrant('Frontal Izquierdo', QUADRANTS.FRONT_LEFT)}
      {renderQuadrant('Frontal Derecho', QUADRANTS.FRONT_RIGHT)}
      {renderQuadrant('Basal Izquierdo', QUADRANTS.BASAL_LEFT)}
      {renderQuadrant('Basal Derecho', QUADRANTS.BASAL_RIGHT)}
    </Container>
  );
};

export default BrainQuadrant;