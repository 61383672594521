import React from 'react';

const InterpretationBetesa: React.FC = () => {

    return (
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3588_98962)">
                <path d="M8.95442 29.0532L11.0206 16.1967L13.1289 25.4518C13.3378 26.3996 14.6277 26.5073 14.9785 25.5928L16.8894 20.603L17.9877 22.8844C17.9877 22.8844 18.3461 23.4993 18.9422 23.4993H21.5833V21.541H19.5708L17.7385 17.6008C17.3954 16.7881 16.2607 16.8058 15.9445 17.6322L14.3249 21.8622L11.8275 10.5646C11.7144 10.0456 11.3004 9.79102 10.8883 9.79102C10.4494 9.79102 10.0124 10.075 9.92617 10.6233L7.85425 23.6756L5.73633 15.6386C5.50058 14.679 4.17617 14.6222 3.87333 15.5759L2.29975 21.541H0.5V23.468H3.06258C3.41333 23.468 3.79283 23.1801 3.8925 22.8355L4.78567 19.8862L7.06075 29.1354C7.31567 30.1792 8.78575 30.1107 8.95442 29.0532ZM46.5 34.4386L41.0739 28.8926C42.0744 27.3455 42.6667 25.4949 42.6667 23.4993C42.6667 18.0924 38.3772 13.7077 33.0833 13.7077C27.7895 13.7077 23.5 18.0924 23.5 23.4993C23.5 28.9063 27.7895 33.291 33.0833 33.291C35.0364 33.291 36.8477 32.6859 38.3637 31.6617L43.7898 37.2077L46.5 34.4386ZM27.3333 23.4993C27.3333 20.2603 29.9132 17.6243 33.0833 17.6243C36.2535 17.6243 38.8333 20.2603 38.8333 23.4993C38.8333 26.7384 36.2535 29.3743 33.0833 29.3743C29.9132 29.3743 27.3333 26.7384 27.3333 23.4993Z" fill="#2D749C" />
            </g>
            <defs>
                <clipPath id="clip0_3588_98962">
                    <rect width="46" height="47" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default InterpretationBetesa;



