import styled from "styled-components";

export const Container = styled.div`
    /* height: 400px; */
    /* width: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;

`;
