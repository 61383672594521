import axios from 'axios'

export const finishBetesaTest = async (user_test_id: number)  => {
  const url = `${process.env.REACT_APP_MAIN_API}/betesa_result/finish/test/${user_test_id}`
  try {
    const response = await axios.put(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};


