/* eslint-disable @typescript-eslint/ban-types */
import { createSlice, PayloadAction} from '@reduxjs/toolkit'
import { createSagasTrigger } from 'lib/redux/sagas/trigger'
import { StepOne, StepTwo, StepThree, StepFour, StepFive, } from 'types/common'

export type InitialState = typeof initialState

type Error = {
  error: boolean
  message: string
}

interface IState {
  process: any
  loading: boolean
  error: boolean
  stepsErrors?: {
    stepOne?: Error,
    stepTwo?: Error,
    stepThree?: Error,
    stepFour?: Error,
    stepFive?: Error,
  }
}

interface EmailTemplate  {
  textOne: string,
  textTwo: string,
  textThree: string,
  textFour: string,
  textThreeOptions: string[],
  date?: string,
  active?: boolean,
  logo?: string
}

export const initialState: IState = {
  process: {
    stepOne: {} as StepOne,
    stepTwo: {} as StepTwo,
    stepThree: {} as StepThree,
    stepFour: {} as StepFour,
    stepFive: {} as StepFive,
    idsBetesaSkillsSelected: [] as number[],
  },
  stepsErrors: {},
  loading: false,
  error: false,
}

type SetStepOne = PayloadAction<StepOne> | any
type SetStepTwo = PayloadAction<StepTwo> 
type SetStepThree = PayloadAction<StepThree | any>
type SetStepFour = PayloadAction<StepFour | any>
type SetStepFive = PayloadAction<StepFive>
type SetEmailTemplate = PayloadAction<EmailTemplate>

const createProcessSlice = createSlice({
  name: 'createProcess',
  initialState,
  reducers: {
    setStepOne(state, action: SetStepOne) {
      state.process.stepOne = action.payload
    },
    setStepTwo(state, action: SetStepTwo) {
      state.process.stepTwo = action.payload
    },
    setStepThree(state, action: SetStepThree) {
      state.process.stepThree = action.payload
    },
    setStepFour(state, action: SetStepFour){
      state.process.stepFour = action.payload
    },
    setStepFive(state, action: SetStepFive){
      state.process.stepFive = action.payload
    },
    setStepFiveEmailTemplate(state, action: SetEmailTemplate){
      state.process.stepFive.emailTemplate = action.payload
    },
    setBetesaSkillSelected(state, action: PayloadAction<number[]>){
      state.process.idsBetesaSkillsSelected = action.payload
    },
    setUserListAdded (state, action: PayloadAction<any>) {
      state.process.stepFive.userListAdded = action.payload
    },
    setUserListFailed (state, action: PayloadAction<any>) {
      state.process.stepFive.userListFailed = action.payload
    },
    setLinkDownloadExcelUsersFailed (state, action: PayloadAction<string>) {
      state.process.stepFive.linkDownloadExcelUsersFailed = action.payload
    },
    setAcceptedTerms(state, action: PayloadAction<boolean>) {
      state.process.stepFive.acceptedTerms = action.payload
    },
    setTestTypes(state, action: PayloadAction<string>) {
      state.process.stepFive.testTypes = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) { 
      state.error = action.payload
    },
    setStepError(state, action: PayloadAction<{step: string, error: Error}>) {
      state.stepsErrors = {
        ...state.stepsErrors,
        [action.payload.step]: action.payload.error
      }
    },
    setCleanStepsErrors(state) {
      state.stepsErrors = {};
    },
    cleanProcess(state) {
      state.process = initialState.process;
      state.loading = false
      state.error = initialState.error;
    }
  },
})

export const { setLoading, setError, cleanProcess, setStepOne, setStepTwo, setStepThree, setTestTypes, setStepError, setCleanStepsErrors,
   setStepFour, setStepFive,setStepFiveEmailTemplate ,setBetesaSkillSelected, setUserListAdded, setUserListFailed ,setAcceptedTerms, setLinkDownloadExcelUsersFailed } = createProcessSlice.actions

export const setStepOneTrigger = createSagasTrigger<SetStepOne>('set_step_one_sagas')
export const setStepTwoTrigger = createSagasTrigger<StepTwo>('set_step_two_sagas')
export const setStepThreeTrigger = createSagasTrigger<StepThree>('set_step_three_sagas')
export const setStepFourTrigger = createSagasTrigger<StepFour>('set_step_four_sagas')
export const setStepFiveTrigger = createSagasTrigger<StepFive>('set_step_five_sagas')

export const createProcessReducer = createProcessSlice.reducer
