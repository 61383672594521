import axios from 'axios'
import { IGeneralData } from 'types/common'

interface IDataResponse {
  status: string
  data: IGeneralData
  error?: string
}

/**
 * @description service to get general data
 *
 */
const getGeneralData = async (token: string) => {
  const apiUrl = `/basic_text`
  // config get request

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    // get request
    const response = await axios
      .get(apiUrl, config)
      .then((res): IDataResponse => {
        return res.data
      })
      .catch((error) => {
        return { status: 'error', data: { error: error?.response?.data } }
      })

    return response.data
  } catch (error) {
    return error
  }
}

export default getGeneralData
