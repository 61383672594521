import WhiteContainer from "views/components/common/WhiteContainer";
import { Row, TextDescriptionBehavior } from './styled'
import { P } from 'views/components/UI/Text';
import EditIcon from "assets/icons/EditCoin";
import DeleteIcon from "assets/icons/DeleteIcon";

const itemActionStyle = {
    cursor: 'pointer',
    margin: '0px 10px',
}

interface IBehaviorItemProps {
    description: string;
    onEdit: () => void;
    onDelete: () => void;
}

const BehaviorItem = (props: IBehaviorItemProps) => {
    const { description, onEdit, onDelete } = props;

    const TextAction = ({text, color}: any) => (<P margin='0px 8px' fontSize='14px' fontWeight={700} color={color}>{text}</P>)

    return (
        <WhiteContainer height="140px" width="94%" padding="2% 3%" justifyContent="space-between" margin="10px 0px">
            <TextDescriptionBehavior>{description}</TextDescriptionBehavior>
            <Row justify="flex-end" width="240px" style={{alignSelf: 'flex-end', padding: '0'}}>
               <Row style={itemActionStyle} onClick={onEdit}>
                    <TextAction color='#2D749C' text='Editar'/>
                    <EditIcon />
               </Row>
                <Row style={itemActionStyle} onClick={onDelete}> 
                    <TextAction color='#E44646' text='Eliminar' />
                    <DeleteIcon />
                </Row>
            </Row>
        </WhiteContainer>
    )
}

export default BehaviorItem;
