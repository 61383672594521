import axios from 'axios'

const getTestByProcess = async (id: number) => {
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/process/test/${id}`

  const response = await axios
    .get(apiUrl)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })

  return response
}

export default getTestByProcess
