/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import Modal from 'views/components/UI/Modal'
import { useNotification } from 'lib/context/notification.context'
import { tokenSelector, userSelector } from 'ducks/user'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
// components
import {
  Container,
  RowContainer,
  Header,
  Body,
  GraphicsContainer,
  SubContainer,
  ContainerSelectCompetence,
  TypeGraphicContainer,
  CompetenceContainer,
  UsersContainer,
  NoDataContainer,
  NoDataText,
  NoDataIcon,
  NoDataSubContainer,
  TextContainer,
} from './styled'
import { H2, P } from 'views/components/UI/Text'
import Radar from 'views/components/Graphics/Radar'
import NineBox from 'views/components/Graphics/Ninebox'
import TeamMap from 'views/containers/TeamMap'
import HorizontalSelector from 'views/components/Selectors/HorizontalSelector'
import getProcessByCompany from 'services/companies/getProcessByCompany'
import getCompetencesByProcess from 'services/competence/getCompetencesByProcess'
import getTestByProcess from 'services/competence/getTestByProcess'
import iconInfo from '../../../components/assets/iconInfo.png'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import useGetTextsNinebox from 'hooks/useGetNineboxTexts/use-get-ninebox-texts'
import MultiSelect from 'views/components/UI/SelectMultiple/select-multiple'
import getRadarData from 'services/competence/getRadarData'
import { optionsData } from './constants'
import getNineBoxAnalytics from 'services/competence/GetNineBoxAnalytics'
import { useTranslation } from 'react-i18next'
import SelectSearch from './selectSearch'

type AllProcess = {
  value: number
  label: string
  weight?: number
}

interface ValueLabel {
  [key: number]: string
}

export interface Score {
  mean: number
  skill_id: number
  users: UserScore[]
}

export interface UserScore {
  full_name: string
  competence_score: number
  user_id: number
}

const Analytics = () => {
  const { t } = useTranslation()
  const { getError } = useNotification()
  const { getWarning } = useNotification()
  const { textsNinebox } = useGetTextsNinebox('potencial')

  const { companyId } = useSelector(userSelector)
  const token = useSelector(tokenSelector)

  const [loading, setLoading] = useState<boolean>(false)

  const [allProcess, setAllProcess] = useState<AllProcess[]>([])
  const [checks, setChecks] = useState({ betesa: false, competences: false })

  const [selectedProcess, setSelectedProcess] = useState<string>('')
  const [processName, setProcessName] = useState<string>('')
  const [selectedGraphic, setSelectedGraphic] = useState<string>('1')
  // Radar state
  const [competences, setCompetences] = useState<AllProcess[]>([])
  const [users, setUsers] = useState<AllProcess[]>([])
  const [selectedCompetence, setSelectedCompetence] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [testIds, setTestIds] = useState({ competenceId: '', betesaId: '' })
  const [resultData, setResultData] = useState<Score[]>([])
  const [radarLabels, setRadarLabels] = useState<string[]>([])
  const [idealProfileData, setIdealProfileData] = useState<number[]>([])
  const dataHome = useSelector((state: any) => state.user.userId)

  // Modal state
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    if (selectedCompetence?.length > 0 && selectedCompetence?.length < 3 && selectedUsers?.length > 0) {
      getWarning(t('global.notifications.addSkillAlmostMessage', { number: 3 }))
    }
  }, [selectedCompetence, selectedUsers])

  const fetchAllProcesses = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getProcessByCompany(companyId, dataHome)
      const allProcessFormatted = response?.data.processes.map((processitem: any) => ({
        value: processitem.process_id,
        label: processitem.process_name,
      }))
      setAllProcess(allProcessFormatted || [])
    } catch (error) {
      getError('Ha ocurrido un error al cargar los datos de esta compañía')
    } finally {
      setLoading(false)
    }
  }, [companyId])

  useEffect(() => {
    fetchAllProcesses()
  }, [fetchAllProcesses])

  useEffect(() => {
    if (selectedProcess === '') return
    getTestByProcess(Number(selectedProcess)).then(
      ({ data }: any) => {
        if (data && data.length > 0) {
          data.forEach((item: any) => {
            if (item.name.startsWith('Competencias')) {
              setTestIds((prevState) => ({ ...prevState, competenceId: item.id }))
            } else if (item.name.startsWith('Betesa')) {
              setTestIds((prevState) => ({ ...prevState, betesaId: item.id }))
            }
          })
          if (testIds.competenceId === '') {
            setTimeout(() => {
              setLoading(false)
            }, 1000)
          }
        }
      },
      (error) => {
        console.error('Error buscando las pruebas: ', error)
        getError('Ha ocurrido un error al cargar las pruebas')
        setLoading(false)
      }
    )
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [selectedProcess])

  useEffect(() => {
    if (selectedProcess === '' || testIds.competenceId.length === 0) {
      return
    }

    getCompetencesByProcess(Number(testIds.competenceId)).then(
      ({ data }: any) => {
        if (data) {
          const competencesFormatted = data.listCompetence.map((competenceItem: any) => ({
            value: competenceItem.competenceId,
            label: competenceItem.skill,
            weight: competenceItem.weight,
          }))
          const usersFormatted = data.listUsers.map((userItem: any) => ({
            value: userItem.userId,
            label: `${userItem.first_name} ${userItem.last_name}`,
          }))
          setCompetences(competencesFormatted)
          setUsers([{ value: 0, label: 'Todos' }, ...usersFormatted])
        }
      },
      (error) => {
        console.error('Error buscando las competencias: ', error)
        getError('Ha ocurrido un error al cargar las competencias')
        setTestIds((prevState) => ({ ...prevState, competenceId: '' }))
        setLoading(false)
      }
    )

    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [testIds, selectedProcess])

  useEffect(() => {
    if (selectedProcess === '' || selectedCompetence.length < 3 || selectedUsers.length === 0) return

    setLoading(true)

    const selectedUsersFiltered = selectedUsers.filter((user: number) => user !== 0)

    getRadarData(Number(testIds.competenceId), selectedUsersFiltered, selectedCompetence).then(
      ({ data }: any) => {
        if (data && data.overall_score && data.overall_score.length > 0) {
          setResultData(data.overall_score)
          const valueLabelMap: ValueLabel = competences.reduce<ValueLabel>((acc, item) => {
            acc[item.value] = item.label
            return acc
          }, {})
          setRadarLabels(data.overall_score.map((item: any) => valueLabelMap[item.skill_id]))
          if (resultData[0]?.users?.length && selectedUsersFiltered?.length !== resultData[0]?.users?.length) {
            getWarning('Seleccionaste usuarios que no han contestado la prueba')
          }
        }
      },
      (error) => {
        console.error('Error buscando los datos del radar: ', error)
        getError('Ha ocurrido un error al cargar los datos del radar')
        setLoading(false)
      }
    )
    setLoading(false)
  }, [selectedProcess, selectedCompetence, selectedUsers, token, testIds, competences])

  useEffect(() => {
    if (selectedCompetence.length === 0) return

    setIdealProfileData(
      selectedCompetence.map((id: number) => {
        const competencesWeight = competences.find((competence) => competence.value === id)
        return competencesWeight ? competencesWeight.weight : null
      })
    )
  }, [competences, selectedCompetence])

  useEffect(() => {
    if (selectedProcess === '') {
      return
    }
    const body = {
      process_id: Number(selectedProcess),
      is_betesa: true,
      is_competences: true,
      career_plan: false,
    }

    getNineBoxAnalytics(body).then(
      ({ data }: any) => {
        if (data) {
          setChecks({ betesa: !!data.betesa_ideal_profile, competences: !!data.competences_ideal_profile })
        }
      },
      (error) => {
        console.error('Error buscando los datos del ninebox: ', error)
      }
    )
  }, [selectedProcess])

  const handleProcessChange = (value: any) => {
    if (!value) return
    setProcessName(allProcess.find((item: any) => item.value === value)?.label || '')
    setLoading(true)
    setTestIds({ competenceId: '', betesaId: '' })
    setCompetences([])
    setUsers([])
    setSelectedProcess(value)
    setSelectedCompetence([])
    setSelectedUsers([])
    sessionStorage.removeItem('history')
    sessionStorage.removeItem('nineBoxName')
  }

  const maxSelection = 6

  const handleCompetenceChange = (value: any) => {
    const selectedValues = value
    if (selectedValues.length <= maxSelection) {
      setSelectedCompetence(selectedValues)
    } else {
      getWarning('No puedes seleccionar más de 6 competencias')
    }
  }

  const handleSelectUsers = (newValues: any) => {
    if (newValues.includes(0) && !selectedUsers.includes(0)) {
      setSelectedUsers(users.map((user) => user.value))
    } else if (!newValues.includes(0) && selectedUsers.includes(0)) {
      setSelectedUsers([])
    } else if (newValues.length < selectedUsers.length) {
      setSelectedUsers(newValues.filter((value: number) => value !== 0))
    } else {
      setSelectedUsers(newValues)
    }
  }

  const BlueTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2bb5d4',
      color: 'white',
      boxShadow: theme.shadows[2],
      fontSize: 14,
    },
  }))

  const userResultData = JSON.parse(JSON.stringify(resultData))
  const idealProfileRadarData = JSON.parse(JSON.stringify(idealProfileData))

  return (
    <MainLayout>
      <Container>
        <Header>
          <H2 marginBottom="40px">Analíticas</H2>
          <RowContainer>
            <P fontWeight={600} marginRight="20px">
              Selecciona el proceso a graficar:
            </P>
            <div style={{ width: '60%' }}>
              <SelectSearch
                disableCloseOnSelect={false}
                showCheckboxes={false}
                noChip={true}
                label="Selecciona el proceso"
                options={allProcess || []}
                value={selectedProcess}
                onChange={handleProcessChange}
                limitTags={1}
                maxWidth="500px"
              />
            </div>
          </RowContainer>
        </Header>

        {selectedProcess !== '' && !loading && (
          <Body>
            <HorizontalSelector
              items={optionsData}
              setSelected={(e) => setSelectedGraphic(e)}
              selected={selectedGraphic}
            />

            <GraphicsContainer>
              {selectedGraphic === '1' && (
                <SubContainer width="100%">
                  {competences && competences.length > 0 ? (
                    <ContainerSelectCompetence>
                      <CompetenceContainer>
                        <div
                          style={{
                            marginTop: '13px',
                            display: 'flex',
                          }}
                        >
                          <P fontWeight={600}>{t('global.common.skillText')}:</P>
                          <div style={{ marginTop: '5px', marginRight: '5px', marginLeft: '5px' }}>
                            <BlueTooltip title="Debes seleccionar mínimo 3 y máximo 6 competencias para poder generar esta gráfica">
                              <InfoIcon
                                style={{
                                  color: '#2BB5D4',
                                  width: '14px',
                                  height: '14px',
                                  cursor: 'pointer',
                                }}
                              />
                            </BlueTooltip>
                          </div>
                        </div>
                        <div style={{ width: '50%' }}>
                          <MultiSelect
                            noChip={true}
                            width="300px"
                            label="Selecciona las competencias"
                            options={competences}
                            value={selectedCompetence}
                            onChange={handleCompetenceChange}
                            limitTags={1}
                          />
                        </div>
                      </CompetenceContainer>
                      <UsersContainer>
                        <div
                          style={{
                            marginTop: '13px',
                          }}
                        >
                          <P fontWeight={600}>Usuarios:</P>
                        </div>
                        <div style={{ width: '50%', marginLeft: '25px', maxHeight: '50px' }}>
                          <MultiSelect
                            noChip={true}
                            width="300px"
                            label="Selecciona los usuarios"
                            options={users}
                            value={selectedUsers}
                            onChange={handleSelectUsers}
                            limitTags={1}
                          />
                        </div>
                      </UsersContainer>
                    </ContainerSelectCompetence>
                  ) : (
                    <NoDataContainer>
                      <NoDataSubContainer>
                        <NoDataIcon src={iconInfo} />
                        <TextContainer>
                          <NoDataText>No hay datos de {t('global.common.skillText')} en este proceso</NoDataText>
                        </TextContainer>
                      </NoDataSubContainer>
                    </NoDataContainer>
                  )}

                  <TypeGraphicContainer>
                    {resultData.length &&
                    resultData[0].users.length &&
                    selectedCompetence.length > 2 &&
                    selectedUsers.length ? (
                      <SubContainer width="60%" margin="auto">
                        <Radar
                          userResultData={userResultData}
                          userResultLabels={radarLabels}
                          idealProfileData={idealProfileRadarData}
                        />
                      </SubContainer>
                    ) : selectedCompetence.length > 2 &&
                      selectedUsers.length &&
                      (!resultData.length || !resultData[0].users.length) ? (
                      <NoDataContainer>
                        <NoDataSubContainer>
                          <NoDataIcon src={iconInfo} />
                          <TextContainer>
                            <NoDataText>Los usuarios que seleccionaste no han contestado la prueba</NoDataText>
                          </TextContainer>
                        </NoDataSubContainer>
                      </NoDataContainer>
                    ) : null}
                  </TypeGraphicContainer>
                </SubContainer>
              )}

              {selectedGraphic === '2' && (
                <SubContainer justifyContent="center" alignItems="center">
                  <TeamMap betesaId={testIds.betesaId} />
                </SubContainer>
              )}

              {selectedGraphic === '3' && (
                <TypeGraphicContainer style={{ marginTop: '0' }}>
                  <SubContainer height="130%" padding="0% 0%">
                    <NineBox
                      processId={selectedProcess}
                      checksIdeal={checks}
                      processName={processName}
                      textsNinebox={textsNinebox || []}
                    />
                  </SubContainer>
                </TypeGraphicContainer>
              )}
            </GraphicsContainer>
          </Body>
        )}
        <Modal
          show={showModal}
          setShow={setShowModal}
          styleContent={{
            width: '80%',
          }}
        >
          <div>
            <H2>Modal</H2>
            <P>Modal contenido</P>
          </div>
        </Modal>
      </Container>
      {loading && <ModalLoader isLoading={loading} />}
    </MainLayout>
  )
}

export default Analytics
