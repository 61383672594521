import axios from 'lib/axios'
import { TEXT_GENERAL_SKILL } from '../constants'

export const fetchGetSillsGeneralAction = async (id?: number) => {
  try {
    const url = id ? `${TEXT_GENERAL_SKILL}?id_type=${id}` : TEXT_GENERAL_SKILL;
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching actions');
  }
}

export const fetchUpdateGeneralTextAction = async (data: any) => {
  const { text, id } = data
  const body = {
    text,
  }
  await axios
    .put(`${TEXT_GENERAL_SKILL}/update/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const fetchCreateGeneralTextAction = async (data: any) => {
  const { text, typeGeneralId } = data
  const body = {
    type_category_test_id: typeGeneralId,
    text
  }
  await axios.post(`${TEXT_GENERAL_SKILL}/create`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}
