import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

export const InputContainer = styled.div`
    width: 48%;
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-;
    margin-top: 10px;
`;

export const Error = styled.span`
    color: red;
    font-size: 12px;
`;
