import { SKILLS_NAME } from 'constants/global.constants'

export const globalTranslations = {
  es: {
    common: {
      welcome: 'Te damos la bienvenida',
      credentialsText: 'Ingresa tus credenciales para iniciar sesión',
      skillText: SKILLS_NAME,
      loginPasswordText: 'Ingresa tu contraseña',
      loginEmailText: 'Ingresa tu correo',
    },
    notifications: {
      addSkillAlmostMessage: `Debes agregar al menos {{ number }} competencias`,
      addSkillsErrorMessage: `No puedes tener menos de {{ number }} competencias`,
      addSkillsErrorMoreThanMessage: `No puedes tener más de {{ number }} competencias`,
      notAddSameSkillErrorMessage: `No puedes agregar la misma competencia dos veces`,
      getSkillsErrorMessage: `No se pudieron obtener las competencias`,
      loginEmailErrorMessage: `El email que has ingresado no coincide. Revisa que sea correcto`,
      loginInactiveUserErrorMessage: `Este usuario no está activo, por favor revisa tu correo y activa tu cuenta`,
      loginEmailNotMainErrorMessage: `El email que has ingresado no es el principal de tu cuenta, por favor intenta con otro`,
      loginEmailOrPasswordErrorMessage: `El email o la contraseña que has ingresado no coinciden, vuelve a intentar`,
      documentAlreadyRegistered: `Este documento no coincide con tu usuario.`,
      userAlreadyActive: `El usuario con este número de documento ya se encuentra activo`,
      emailNotExist: `No existe una cuenta con ese correo electrónico`,
      emailNotMain: `El correo electrónico ingresado no es el principal de la cuenta`,
      userNotActive: `Esta cuenta no está activa. Asegúrate de activarla`,
      createAccountError: 'Ocurrió un error inesperado, por favor intenta más tarde.',
      createAccountSuccess: 'Cuenta creada con éxito',
      sendDataSuccess: 'Datos enviados con éxito',
      updateUserError: 'El email o el documento que ingresaste ya están registrados',
      updateUserSuccess: 'Usuario actualizado con éxito',
      updateUserErrorUnexpected: 'Ocurrió un error inesperado al actualizar el usuario',
      addUsersBeforeContinue: 'Debes agregar al menos un usuario para continuar',
      addedUserSuccess: 'Se agregó el usuario correctamente',
      buyMoreCredits: 'Debes comprar más créditos para agregar más usuarios',
      userDeletedSuccess: 'Se eliminó el usuario seleccionado',
      addedMoreUsersSuccess: 'Se agregaron los usuarios correctamente',
      duplicatedUserError: `Se encontraron {{ duplicatesCount }} usuarios duplicados y no fueron agregados`,
      userDataError: 'Error al obtener los datos del usuario',
      planAlreadyCreated: 'Ya se creó el plan de desarrollo y puedes descargarlo',
      planDownloaded: 'Tu plan de desarrollo se descargó exitosamente',
      planGenerated: 'Tu plan de desarrollo se generó exitosamente',
      userActiveNoRegister: 'Este usuario ya ha sido activado',
      addUserSuccess: 'Usuario agregado con éxito',
      testAlreadySent: 'Las pruebas ya fueron enviadas a los usuarios.',
    },
  },
  en: {
    common: {
      welcome: 'Welcome',
      credentialsText: 'Enter your credentials to log in',
      skillText: SKILLS_NAME,
      loginPasswordText: 'Enter your password',
      loginEmailText: 'Enter your email',
    },
    notifications: {
      addSkillAlmostMessage: `Debes agregar al menos {{ number }} competencias.`,
      addSkillsErrorMessage: `No puedes tener menos de {{ number }} competencias.`,
      addSkillsErrorMoreThanMessage: `No puedes tener más de {{ number }} competencias.`,
      notAddSameSkillErrorMessage: `No puedes agregar la misma competencia dos veces`,
      getSkillsErrorMessage: `No se pudieron obtener las competencias.`,
    },
  },
}
