import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import { Link } from 'react-router-dom'
import TitleDiv from 'views/components/UI/TitleDiv'
import {
  Card,
  ModulesContainer,
  CardDescriptionSection
}
 from './styled'
const Home: FC = () => {
  return (
        <MainLayout>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>Configuración de textos por Competencias</h2>
          </TitleDiv>
            <ModulesContainer>
            <Link to='/modules/skills/texts/general'>
              <Card >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize:20 }}>Generales</span>

                </CardDescriptionSection>
              </Card>
            </Link>
            <Link to='/modules/skills/texts/acording-to-result'>
              <Card >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize:20 }}>Cualitativos</span>

                </CardDescriptionSection>
              </Card>
            </Link>
            </ModulesContainer>
        </MainLayout>
  )}
export default Home
