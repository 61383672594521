import React, { FC } from 'react'
import Modal from 'views/components/UI/Modal'
import { BottomButtonsContainer, ModalDeleteContainer, ModalNewMapContainer, StandardButton } from './styled'
import BackButton from 'views/components/UI/BackButton'
import NegativeWithRedCircle from 'assets/icons/NegativeWithRedCircle'

interface NewMapModalProps {
  show: boolean
  setShow: (show: boolean) => void
  onDelete: (id: number | null) => void
  idToDelete: number | null
}

export const DeleteMapModal: FC<NewMapModalProps> = ({ show, setShow, onDelete, idToDelete }) => {
  const handleDelete = () => {
    onDelete(idToDelete)
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
  }
  return (
    <Modal styleContent={{ width: '530px', height: '280px' }} show={show} setShow={setShow} hideCloseButton>
      <ModalNewMapContainer>
        <ModalDeleteContainer>
          <NegativeWithRedCircle width="90px" height="90px" />
          <h3 style={{ textAlign: 'center' }}>¿Seguro que deseas eliminar este mapa de equipo?</h3>
        </ModalDeleteContainer>
        <BottomButtonsContainer>
          <BackButton
            style={{ width: 'auto', padding: '24px 0px' }}
            text="Cancelar"
            type="button"
            onClick={handleClose}
          />
          <StandardButton style={{ width: '15%', alignSelf: 'flex-end' }} onClick={handleDelete}>
            Eliminar
          </StandardButton>
        </BottomButtonsContainer>
      </ModalNewMapContainer>
    </Modal>
  )
}

export default DeleteMapModal
