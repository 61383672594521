import styled, { css } from 'styled-components'

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 0 !important;
  @media (max-width: 992px) {
    margin-top: 60px;
  }
  ${iOS &&
  css`
    padding-top: 0;
    height: auto;
  `}
`

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  align-items: center;
`
export const Title = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: 574px) {
    font-size: 4vw;
  }
`
export const Paragraph = styled.p`
  color: #1f2124;
  font-size: 18px;
  @media (max-width: 574px) {
    font-size: 3vw;
  }
`
export const Question = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 21px;
  @media (max-width: 574px) {
    font-size: 3.8vw;
    color: black;
  }
`

export const BtnGoBack = styled.div`
  color: #2d749c;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
`
export const GridModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
`
export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ModalBody = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
`
export const Row = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 20px;
`
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #c6016b;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
`
