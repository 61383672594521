import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import { StrokedButton } from 'views/components/UI/Buttons'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import TitleDiv from 'views/components/UI/TitleDiv'

const Home: FC = () => {
  const headers = [
    {
      key:'group',
      name: 'Grupo'
    },
    {
      key:'dimenssion',
      name: 'Dimensión'
    },
    {
      key:'skill',
      name: 'Competencia'
    },
    
  ]
  const data = [
    {
      group: 'Adaptación',
      dimenssion: 'Cógnitiva',
      skill: 'Resolución de problemas',
    },
    {
      group: 'Adaptación',
      dimenssion: 'Emocional',
      skill: 'Resolución de problemas',
    },
    {
      group: 'Adaptación',
      dimenssion: 'Cógnitiva',
      skill: 'Resolución de problemas',
    },
    {
      group: 'Adaptación',
      dimenssion: 'Emocional',
      skill: 'Resolución de problemas',
    },
    {
      group: 'Interactiva',
      dimenssion: 'Cógnitiva',
      skill: 'Resolución de problemas',
    },
    {
      group: 'Interactiva',
      dimenssion: 'Cógnitiva',
      skill: 'Resolución de problemas',
      
      text:'Destino tiempo a definir los problem...'
    },
  ]
  const viewUrl = "/modules/skills/questions/new";
  const updateUrl = "/modules/skills/questions/update";
  return (
        <MainLayout>
          <TitleDiv>
            <h2 style={{ fontSize: 32 }}>Percentiles</h2>
          </TitleDiv>
          <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:15,marginTop:0, alignItems: 'baseline' }}>
            <h5 style={{ fontSize: 22 }}>Percentiles</h5>
              <StrokedButton onClick={()=>{ alert('No funciona aún')}}>Nuevo percentil</StrokedButton>
          </div>    
          <BluesiteTableComponent  data={data} headers={headers} viewUrl={viewUrl} updateUrl={updateUrl} searchBar={true} searchLabel='Buscar por:'/>
        </MainLayout>
  )}
export default Home
