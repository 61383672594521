import styled from 'styled-components';

export const Container = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    height: auto;
`;

export const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
`
export const Title = styled.h1`
  margin-bottom:0; 
  margin-top:0;
  @media (max-width: 574px)  {
    font-size: 4vw;
  }
`
export const Paragraph = styled.p`
   color: #1F2124; 
   font-size:18px;
  @media (max-width: 574px)  {
    font-size: 3vw;
  }
`
export const Question = styled.p `
    font-style: italic;
    font-weight: 400;
    font-size: 21px;
    @media (max-width: 574px)  {
    font-size: 3vw;
  }
`

export const BtnNext = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 113px;
    height: 48px;
    background: #C6016B;
    color: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
    border-radius: 8px;
    cursor: pointer;
`

export const BtnGoBack = styled.div `
    color: #2D749C;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
`
export const GridModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    z-index: 2;
`;
export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
`;
export const Row = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
`;
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #C2C2C2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
    background: #C6016B;
  }`