import styled from 'styled-components'

export const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  height: auto;
  background-color: #fbfbfb;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: auto;
  background-color: #81acc4;
`

export const Image = styled.img`
  width: 85%;
  height: 85%;
  object-fit: contain;
`

export const TextContainer = styled.div`
  width: 95%;
  flex: 1;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const TitleContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`

export const ActionContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  height: 100%;
  padding-top: 12px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const TextDownload = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #2d749c;
  margin: 8px 0px 0px 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 2px;
`
