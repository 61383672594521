import { FC, useCallback, useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom'
import { ContainerActionItem, LinkTo, UpdateTag, ViewTag } from './styled'
import { StrokedButton } from 'views/components/UI/Buttons'
import EditIcon from 'assets/icons/EditCoin'
import TitleDiv from 'views/components/UI/TitleDiv'
import ActionDetailModal from './action-detail-modal/action-detail-modal'
import { fetchAllActions } from '../../services/fetch-betesa-talents'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { HEADERS } from '../../constants'
import { specialAdminRoutes } from 'router/routes'

interface DevelopmentPlansProps {
  id?: number
}

const DevelopmentPlans: FC<DevelopmentPlansProps> = () => {
  const [actions, setActions] = useState([])
  const [actionId, setActionId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [searchParam, setSearchParam] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()

  const getAllDevelopmentActions = useCallback(
    (page = 1, limit = 10) => {
      setCurrentPage(page)
      fetchAllActions(limit, page, searchParam).then(({ development_plans, total_pages }) => {
        setActions(development_plans)
        setTotalPages(total_pages)
      })
    },
    [searchParam]
  )

  useEffect(() => {
    getAllDevelopmentActions()
  }, [getAllDevelopmentActions])

  const ActionComponent = (props: any) => {
    const { id } = props

    const handleActionAndOpenModalClick = () => {
      setActionId(id)
      setOpenModal(true)
    }

    return (
      <ContainerActionItem>
        <ViewTag
          onClick={() => {
            handleActionAndOpenModalClick()
          }}
        >
          Ver acciones de desarrollo
        </ViewTag>
        <LinkTo onClick={() => navigate('/modules/talents/edit', { state: { props } })}>
          <UpdateTag>
            <EditIcon /> Editar talento
          </UpdateTag>
        </LinkTo>
      </ContainerActionItem>
    )
  }

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Acciones de desarrollo por talentos</h2>
        <StrokedButton
          type="button"
          onClick={() => {
            navigate(specialAdminRoutes.NewBetesaTalent)
          }}
        >
          Nuevo talento
        </StrokedButton>
      </TitleDiv>

      <BluesiteTableComponent
        data={actions}
        headers={HEADERS}
        searchBar={true}
        ActionComponent={ActionComponent}
        searchLabel=""
        totalPages={totalPages}
        handlePagination={getAllDevelopmentActions}
        handleSearchFunction={() => getAllDevelopmentActions()}
        currentPage={currentPage}
        setSearchParam={setSearchParam}
        onSearch={setSearchParam}
      />

      <ActionDetailModal openModal={openModal} setOpenModal={setOpenModal} actionId={actionId} />
    </MainLayout>
  )
}

export default DevelopmentPlans
