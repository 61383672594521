import { FC, useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts'
import GeneralInfo from './components/GeneralInfo'
import TestInfoAndIdealProfile from './components/TestInfoAndIdealProfile'
import PlatformAdminsCredentials from './components/PlatformAdminsCredentials/PlatformAdminsCredentials'
import FooterButtons from './components/FooterButtons'
import useProcess from './hooks/useProcess'
import { useLocation } from 'react-router-dom'
import ModalConfirm from './components/ModalConfirm'
import { useDispatch } from 'react-redux'
import { setTableChanged } from './store'
import Loader from 'views/components/UI/Loader'
import { LoaderContainer } from './styles.styled'

const WatchSettings: FC = () => {
  const location = useLocation()
  const processId = location.state?.processId as number
  const processName = location.state?.name
  const { getProcessById, saveAdminIds } = useProcess()

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false)
    }, 500)
    return () => clearTimeout(timeOut)
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    getProcessById(processId)
  }, [processId, getProcessById])

  const handleSave = () => {
    dispatch(setTableChanged(false))
    saveAdminIds(processId)
  }

  return (
    <MainLayout>
      <ModalConfirm
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        saveTable={handleSave}
      />
      {!loading ? (
        <>
          <GeneralInfo />
          <TestInfoAndIdealProfile processId={processId} processName={processName} />
          <PlatformAdminsCredentials />
          <FooterButtons showConfirmModal={setShowConfirmModal} onSave={handleSave} />
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </MainLayout>
  )
}

export default WatchSettings
