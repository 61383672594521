import axios from 'axios'

/**
 * @description service to get an specific betesa result by test id
 * @param testId: Number - id of the test
 */
export const getFamilyRol = async (token: string, testId: number, page = 1, search?:string) => {
  let apiUrl =`${process.env.REACT_APP_MAIN_API}/role_family?page=${page}`
  if(search){
    const addAndOperator = apiUrl.includes('?');
    apiUrl += (addAndOperator ? '&' : '?') + `search=${search}`;
  }
  // config post request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  }

    try {
        const response = await axios.get(apiUrl, config)
        return response.data
        } catch (error) {
        console.error(error)
        return error
    }
}

export const getFamilyRolInformation = async (id: any) => {
  const res = await axios.get(`/role_family/settings/${id}`).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}

