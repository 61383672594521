import { useCallback, useState } from 'react';
import getSkillsService from 'services/common/skills/getByFilters';

const DEFAULT_LIMIT = 1;

interface Skill {
  id: number;
  skill: string;
  description: string;
  language: string;
  category_id: number;
  group_id: number;
  group_name: string;
  level_id: number;
  level_name: string;
  type_category_id: number;
  descriptions_id: number;
  cognitive_description: string;
  emotional_description: string;
  interactive_description: string;
}

interface UseSkillsResult {
  skills: Skill[];
  totalPagesSkills: number;
  loadingSkills: boolean;
  searchValue: string;
  currentPage: number;
  searchSkills: (searchValue: string) => void;
  handleChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  getSkills: (filters: FiltersGetSkills) => Promise<Skill>;
}

interface FiltersGetSkills {
  id?: number;
  search?: string;
  description?: string;
  language?: string;
  active?: boolean;
  category_id?: number;
  group_id?: number;
  level_id?: number;
  type_category_id?: number;
  limit?: number;
  page?: number;
}

const useGetSkills = (): UseSkillsResult => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [totalPagesSkills, setTotalPages] = useState<number>(1);
  const [loadingSkills, setLoadingSkills] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getSkills = useCallback(async (filters: FiltersGetSkills): Promise<Skill> => {
    setLoadingSkills(true);

    const queryParams: FiltersGetSkills = {
      id: filters.id,
      limit: DEFAULT_LIMIT,
      page: filters.page || currentPage,
      ...filters,
    };

    if (searchValue) {
      queryParams.search = searchValue;
    }

    try {
      const skillsResults = await getSkillsService(queryParams);  
      setSkills(skillsResults.skills);
      setTotalPages(skillsResults.total_pages);
      return skillsResults.skills as any; // Retorna las habilidades obtenidas
    } catch (error: any) {
      console.error(`Error trying to get Skills: ${error.message}`);
      throw error;
    } finally {
      setLoadingSkills(false);
    }
  }, [currentPage, searchValue]);

  const searchSkills = useCallback((searchText: string) => {
    setSearchValue(searchText);
    setCurrentPage(1);
  }, []);

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  }, []);

  return {
    skills,
    totalPagesSkills,
    loadingSkills,
    searchValue,
    currentPage,
    searchSkills,
    handleChangePage,
    getSkills,
  };
};

export default useGetSkills; 
