import axios from 'lib/axios'

export const fetchGetTeamMap = async (teamMapId: number) => {
  try {
    const response = await axios.get(`/betesa_results/users/team_map/${teamMapId}`)
    return response.data
  } catch (error) {
    throw new Error('Error fetching team map')
  }
}

