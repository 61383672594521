export const DEVELOPMENT_PLAN = 'development_plan'
export const ACTION_PLAN = 'action_plan'


export const DEVELOPMENT_PLAN_TABS = {
  create: DEVELOPMENT_PLAN,
  action: DEVELOPMENT_PLAN,
}

export const ACTION_TABS = {
  create: ACTION_PLAN,
  action: ACTION_PLAN,
  update: ACTION_PLAN
}


export const BACK_PAGE = -1;

export const QUADRANT_OPTIONS = [
  { value: 'FI', label: 'FI' },
  { value: 'FD', label: 'FD' },
  { value: 'BI', label: 'BI' },
  { value: 'BD', label: 'BD' },
]
export const VIEW_URL = "/company";
export const UPDATE_URL = "/companies/update";

export const HEADERS = [
  {
    key: 'name',
    name: 'Talento',
  },
  {
    key: 'qudrant',
    name: 'Cuadrante',
  },
];

