import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'lib/redux'

const getCompetencesQuestion = (state: RootState) => state.competencesQuestions.questions
const getCompetencesAnswers = (state: RootState) => state.competencesQuestions.competencesAnswers
const getTotalQuestions = (state: RootState) => state.competencesQuestions.totalQuestions;

export const competencesQuestionsSelector = createSelector(
  getCompetencesQuestion,
  (competencesQuestions) => competencesQuestions
)
export const competencesAnswersSelector = createSelector(
  getCompetencesAnswers,
  (competencesAnswers) => competencesAnswers
)

export const totalQuestionsSelector = createSelector(getTotalQuestions, (totalQuestions) => totalQuestions);