import styled from 'styled-components'

import imageBackground from '../../../assets/images/cute-girl-login.png'

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const ContainerForm = styled.div`
  padding-top: 2rem;
  width: 55%;
  height: 100%;
  background-color: '#FAFCFF';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1420px) {
    width: 70%;
  }

  @media (max-width: 1024px) {
    width: 65%;
  }

  @media (max-width: 564px) {
    width: 80%;
  }
`

export const ContainerImage = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${imageBackground});
  background-size: cover;
  background-position: center;
`
