import axios from 'axios'

type User = {
  company_id: number
  document: string
  email: string
  first_name: string
  last_name: string
  personId: number
  role: string
  userId: number
}

interface IUserResponse {
  status: string | 'success' | 'OK' | 'error'
  users: User[]
  total_results: number
  total_pages: number
}

interface IPagination {
  page: number
  limit: number
}
interface IData {
  userId?: number
  role?: number[]
  companyId?: number
  first_name?: string
  last_name?: string
  document?: string
  email?: string
  limit?: number
  page?: number
}

/**
 * @description service to get users
 * @param token
 */
const getUsers = async (token: string, data: IData | undefined, pagination: IPagination) => {
  const { page, limit } = pagination
  let apiUrl = `${process.env.REACT_APP_MAIN_API}/user`

  if (page && limit) {
    apiUrl = `${apiUrl}?page=${page || 1}&limit=${limit || 10}`
  }

  // config get request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  // get request
  const response: IUserResponse = await axios
    .post(apiUrl, { ...data }, config)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      console.error('error on get users', err)
      return err
    })
  return response
}

export default getUsers
