import { FC } from 'react'
import Size from 'views/components/assets/size.svg'
import { Container, NotFoundDiv, SizeValidatorDiv } from './styled'
import { P } from 'views/components/UI/Text'
const Home: FC = () => {
  return (
    <SizeValidatorDiv>
      <Container>
        <NotFoundDiv>
          <img src={Size} alt="logo para representar el cambio de dispositivo para operar la plataforma" />
        </NotFoundDiv>
        <P>Por favor, ingresa a tu usuario Administrador Bluesite desde un computador</P>
      </Container>
    </SizeValidatorDiv>
  )
}
export default Home
