import axios from 'axios'
import { CompetenceData } from '../store/entities-development-plan-betesa'

export const fetchDevelopmentPlanBetesa = async (id: string, quadrant = 'FI') => {
  const url = `${process.env.REACT_APP_MAIN_API}/development_plan/actions/results/${id}?quadrant=${quadrant}`
  try {
    const response = (await axios.get(url)).data.data
    response.id = id
    return response
  } catch (error) {
    throw new Error('Error fetching')
  }
}

export const fetchSelectedCompetenceBetesa = async (data: CompetenceData) => {
  const url = `${process.env.REACT_APP_MAIN_API}/feed_back`
  try {
    const response = await axios.post(url, data)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const fetchFeedBack = async (user_test_id: number) => {
  const url = `${process.env.REACT_APP_MAIN_API}/feed_back/actions/${user_test_id}`
  try {
    const response = await axios.get(url)
    return response
  } catch (error) {
    throw new Error('Error fetching get assignment tests')
  }
}
