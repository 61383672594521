import { AxiosResponse } from 'axios';
import axios from 'lib/axios';
import { Skill } from 'types/common';


interface SkillsResponse {
    status: string | 'success' | 'error' | 'OK'
    skills: Skill[]
    total_pages: number
    total_results?: number
}

interface Filters {
    id?: number
    groupId?: number | string
    levelId?: number | string
    categoryId?: number | string
    typeCategoryTestId?: number | string
    skillId?:number | string
}

const getAllSkills = async (filters?: Filters, page?: number, limit?: number, search?: string, question?: boolean ): Promise<SkillsResponse> => {
    let PATH = `${process.env.REACT_APP_MAIN_API}/skill`
  
    if (!filters) {
      return { status: 'error', skills: [], total_pages: 0 };
    }

        if (filters.levelId) {
            PATH += `?level_id=${filters.levelId}`
        }

        if (filters.groupId) {
            PATH += `${ filters.levelId? '&' : '?' }group_id=${filters.groupId}`
        }

        if (filters.skillId) {
            PATH += `${ filters.groupId && filters.levelId ? '&' : '?' }id=${filters.skillId}`
        }

        if (filters.typeCategoryTestId) {
            PATH += `${ filters.groupId || filters.levelId ? '&' : '?' }type_category_test_id=${filters.typeCategoryTestId}`
        }


        if (filters.id) {
            PATH += `${ filters.groupId || filters.levelId || filters.categoryId ? '&' : '?' }id=${filters.id}`
        }
      

        if(filters.categoryId) {
           PATH += `${ filters.groupId || filters.levelId || filters.typeCategoryTestId || filters.id ? '&' : '?' }category_id=${filters.categoryId}`;
        }

        if (search) {
            PATH += `${ filters.groupId || filters.levelId || filters.categoryId || filters.id ? '&' : '?' }search=${search}`
        }

        const addAndOperator = PATH.includes('?');
        PATH += (addAndOperator ? '&' : '?') + `page=${page || 1 }&limit=${limit || 50}&question=${question || false}`;
        try {
            const response: AxiosResponse<SkillsResponse> = await axios.get(PATH);
            return response.data;
        } catch (error) {
            console.error('Error getting skills', error);
            return { status: 'error', skills: [], total_pages: 0 };
        }
  
}

export default getAllSkills
