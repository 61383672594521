import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2% 0%;
`

interface IRowContainer {
  justifyContent?: string
  height?: string
}

interface Props {
  disabled?: boolean
}

export const RowContainer = styled.div<IRowContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContainerSelect = styled.div`
  width: 35%;
  @media (max-width: 768px) {
    margin-top: 5%;
    width: 100%;
  }
`
export const RowTestContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`
export const RowTestType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 18px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Header = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerSelectCompetence = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-top: 1%;
  margin-bottom: 40px;
`

export const GraphicsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 2%;
`

export const TypeGraphicContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1%;
`
export const CardTest = styled.div<Props>`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 420px;
  height: 73px;
  background: ${(props) => (props.disabled ? '#CCCCCC' : '#FFFFFF')};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`
export const CardTestSelected = styled.div<Props>`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 420px;
  height: 73px;
  background: ${(props) => (props.disabled ? '#CCCCCC' : '#FFFFFF')};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  border: 2px solid #2d749c;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

interface ISubContainer {
  width?: string
  height?: string
  padding?: string
  margin?: string
  justifyContent?: string
  alignItems?: string
}

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: ISubContainer) => props.alignItems || 'flex-start'};
  justify-content: ${(props: ISubContainer) => props.justifyContent || 'flex-start'};
  width: ${(props: ISubContainer) => props.width || '100%'};
  height: ${(props: ISubContainer) => props.height || 'auto'};
  margin: 0 0.5rem;
  padding: ${(props: ISubContainer) => props.padding || 'none'};
  max-width: 600px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 420px) {
    margin-left: -1.3rem;
  }
`

export const ImageContainer = styled.div`
  width: 30%;
  height: auto;
  padding: 10px;
  background-color: #81acc4;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
`

export const TitleTest = styled.h3`
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`
