import styled from "styled-components";

interface ItemCount {
  active?: boolean
}

export const Container = styled.div`
  position: absolute;
  bottom: 0px;
  width: calc(100% - 20px);
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1e1e1e;
  margin: 5px;
  margin-bottom: 0px;
  padding: 4px;
  border-top: 0.5px dashed #505050;
  /*#505050
 */
  p {
    font-family: 'Lato-Regular';
    margin: 5px;
    color: #505050;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: justify;
  }
  .count_page {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .count {
      background: #c6016b;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      margin: 1px;
    }
  }
`

export const Count = styled.div<ItemCount>`
  background: ${(props) => (props.active ? '#C6016B' : ' #e57db5')};
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 1px;
`
