import styled from 'styled-components'

interface PropsContainer {
  isOpen: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
`
export const Content = styled.div`
  width: calc(80vw - 350px);
  left: 250px;
  height:100vh;
  overflow-y: visible;
`
export const SectionContainer = styled.div`
align-items: center;
justify-content: center;
margin-left: 10%;
place-content: center;
`
export const SectionOne = styled.div`
  margin-left: 40px;
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 50px;
  gap: 15px;
  width: 625px;
  height: 128px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
`
export const DownloadButton = styled.p<{ isDisabled?: boolean }>`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.85rem;
  color: ${props => (props.isDisabled ? '#A9A9A9' : '#2D749C')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin-right: 10px;
  margin-left: 6px;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

export const SectionTwo = styled.div`
  display: flex;
  flex-direction: row;
`
export const SectionTwoPair = styled.div`
  display: flex;
  flex-direction: row;
  width: 314px;
  height: 79px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  margin-right: 36px;
`
export const SectionTwoIndividual = styled.div`
  display: flex;
  flex-direction: row;
  width: 173px;
  height: 77px;
  left: 359px;
  top: 1px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
`
export const LinkItem = styled.div`
  height: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-weight: normal;
  :hover {
    background: rgba(230, 171, 203, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 7px solid #c6016b;
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
`
export const LinkLogOut = styled.a<PropsContainer>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  margin-left: 12px;
  text-align: justify;
  font-size: 14px;
  transition: width 0.5s ease-in-out;
  cursor: pointer;
`
export const CardTitle = styled.div`
width: 67px;
height: 18px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 18px;

text-align: left;

color: #1F2124;
margin-bottom: 5px;
`
export const LogOut = styled.div`
  position: absolute;
  height: 30px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  bottom: 41px;
  color: red;
`
export const GridDiv = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`
export const LinkIcon = styled.svg`
  :hover {
    fill: #c6016b;
  }
`
export const LinkLogOutIcon = styled.svg`
  fill: red;
`
export const Anchor = styled.a<PropsContainer>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  margin-left: 12px;
  text-align: justify;
  font-size: 14px;
  transition: width 0.5s ease-in-out;
`
export const Divider = styled.hr`
  border: 1px solid  rgba(145, 158, 171, 0.24);
  background: rgba(145, 158, 171, 0.24);
  border-radius: 19px;
  height: 120px;
`
export const BluesiteTable = styled.table`
  width: 100%;
  text-align: left;
   border-collapse: collapse;
`
export const TableDivider = styled.hr`
  border: 1px solid #1F2124;
  width: 100%;
`
export const ActionsCell = styled.th`
  justify-content: flex-end;
  width: 25%;
`

export const TableSelect = styled.select`
  width: 63px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border:none;
  margin:10px;
  padding:10px;
`
export const OptionSelect = styled.option`
  padding:10px;
`

export const ActionIcon = styled.svg`
  fill: #2D749C;
  clip-rule:evenodd;
  fill-rule:evenodd;
  stroke-miterlimit:2;
  width:24px;
  height: 24px;
  margin-right: 5px;
`

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 34px;
`
export const BoldText = styled.p`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  color: #1F2124;
`
export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px;
`

export const RowContainer = styled.div`
  width:auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`
