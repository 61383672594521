import axios from 'axios'


type OptionQuestion = {
    text: string
    weight?: number,
    quadrant?: string,
    rightful?: boolean,
}

type IData  = {
    text: string,
    typeQuestion: string,
    active: boolean,
    reverseOrder?: boolean,
    typeCategoryTestId: number, 
    chapterId: number,
    dimensionId: number,
    optionsData?: OptionQuestion[],
}

/** 
 * @description service to create a new question
 * @param token
 * @param data
 */
const createNewQuestion = async (token: string, data: IData ) => {
    const { text, typeQuestion, active, reverseOrder, typeCategoryTestId, chapterId, dimensionId, optionsData} = data 

    const apiUrl = `${process.env.REACT_APP_MAIN_API}/question`

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    const body: any = {
        'text': text,
        'type_question': typeQuestion,
        'active': active,
        'type_category_test_id': typeCategoryTestId,
        'chapter_id': chapterId,
        'dimension_id': dimensionId,
    }

    if (reverseOrder) {
        body.reverse_order = reverseOrder
    }

    if (optionsData && optionsData.length > 0) {
        body.options_data = optionsData
    }

    const response = await axios.post(apiUrl, body, { headers }).then((res) => {
        return res
    }).catch((error) => {
        return error
    })

    return response
  
}

export default createNewQuestion
