import axios from 'axios'

/**
 * @description service to development actions
 * @param token
 */

const fetchDeleteActionById = async (actionId: number) => {

  const response = await axios.delete(`/action_plan/${actionId}`).then((res) => {
    return res.data
  }).catch((error) => {
    return { ok: false, message: error.message };
  })

  return response
}

export default fetchDeleteActionById
