import styled from 'styled-components'

interface IRowContainer {
  justifyContent?: string
}
interface IGraphicsContainer {
  width?: string | number
}

interface BackgroundColor {
  color?: string
}
interface ItemCount {
  active?: boolean
}
interface IItemDots {
  rotate?: string
  top?: string
  borderColor?: string
}
interface ILineGraphics {
  rotate?: string
  borderColor?: string
  width?: string
}
interface ILineGraphicsArea {
  width?: string
  right?: string
  top?: string
  bottom?: string
  angle?: string
  height?: string
  Bordercolor?: string
}
export const ContainerGlobal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  width: auto;
  height: 100%;
  padding: 10px;
  margin: auto;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  width: 800px;
  height: 100%;
  padding: 10px;
  margin: auto;
`

export const Page = styled.div<BackgroundColor>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 794px;
  /*border: 0.5px solid;*/
  height: ${(props) => (props.color ? '1115px' : '1113px')};
  min-height: ${(props) => (props.color ? '1115px' : '1113px')};
  padding: 10px;
  margin-top: ${(props) => (props.color ? '7.5px' : '0px')};
  margin-bottom: 5px;
  box-sizing: border-box;
  background: ${(props) => props.color || '#fff'};
  page-break-after: always; // ojo
  .header {
    display: flex;
    width: auto;
    height: 60px;
    margin-top: 60px;
    justify-content: flex-end;
    align-items: center;
  }
  h3 {
    font-family: 'Spartan-ExtraBold';
    line-height: 28px;
    letter-spacing: -0.07em;
    font-size: 45px;
    margin-top: 120px;
    margin-left: 24px;
    color: #2d749c;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 800;
    text-align: justify;
  }
  h1 {
    font-family: 'Spartan-ExtraBold';
    line-height: 62px;
    letter-spacing: -0.05em;
    color: #c6016b;
    margin-left: 24px;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    display: flex;
    align-items: center;
    text-align: justify;
  }
  h5 {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0px;
    /* text-align: center; */
    margin-left: 24px;
    color: #1e1e1e;
    text-align: justify;
  }
  .footer_text {
    color: #1e1e1e;
    margin-top: 220px;
    margin-left: 24px;
  }
  .footer_page {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
    margin: 5px;
    padding: 5px;
  }
  .semaforo {
    display: flex;
    width: 90%;
    height: auto;
    margin: 15px;
    justify-content: center;
    align-items: center;
  }
  p {
    font-family: 'Lato-Regular';
    width: 95%;
    margin-left: 10px;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
  }
`

export const Cabecera = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 38px;
  margin-bottom: 10px;
  .title_header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #c6016b;
    opacity: 0.8;
    width: 45%;
    height: 24px;
    -webkit-border-bottom-right-radius: 40% 100%;
            border-bottom-right-radius: 40% 100%;
    padding: 2px 0;
    p {
      font-family: 'Spartan-ExtraBold', sans-serif;
      font-style: normal;
      -webkit-letter-spacing: -0.07em;
      -moz-letter-spacing: -0.07em;
      -ms-letter-spacing: -0.07em;
      letter-spacing: -0.07em;
      color: white;
      font-size: 17px;
      margin: 0 auto;
      margin-left: 10px;
      text-align: justify;
      text-rendering: optimizeLegibility;
    }
  }
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 90%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1e1e1e;
  margin: 5px;
  margin-bottom: 10px;
  padding: 5px;
  border-top: 0.5px dashed #505050;
  /*#505050
 */
  p {
    font-family: 'Lato-Regular';
    margin: 5px;
    color: #505050;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
  }
  .count_page {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .count {
      background: #c6016b;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      margin: 1px;
    }
  }
`

export const Count = styled.div<ItemCount>`
  background: ${(props) => (props.active ? '#C6016B' : ' #e57db5')};
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 1px;
`

export const Tr = styled.tr`
  padding: 15px;
  border: 1px solid;
`
export const Td = styled.td`
  padding: 15px;
  text-align: center;
  border: 1px solid;
`
export const Td2 = styled.td`
  padding: 15px;
  text-align: left;
  border: 1px solid;
`
export const TdBorder = styled.td`
  padding: 15px;
  border: 1px solid;
`
export const Th = styled.th`
  padding: 15px;
  border: 1px solid;
  background: #f7f7f7;
`
export const SubtitleCaption = styled.caption`
  font-size: 'Spartan-Regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #2d749c;
  margin: 5px;
`

export const Title = styled.h2`
  font-family: 'Spartan-SemiBold';
  /* font-weight: 600; */
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #c6016b;
  letter-spacing: -0.05em;
  margin-left: 24px;
  text-align: justify;
  margin: 15px;
`

export const P = styled.p`
  font-family: 'Lato-Regular';
  margin: 1px;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
`

export const Caption = styled.caption`
  font-family: 'Spartan-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  text-align: left;
  color: #2d749c;
  margin: 10px;
  text-align: justify;
`
export const Table = styled.table`
  border: 1px solid;
  border-collapse: collapse;
  margin: 15px;
  width: 80%;
`

export const ContainerScheme = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin-left: 5px;
  background-color: #fff;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 240px;
  padding: 5px;
  margin: 15px;
`
export const ColumnTextLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 1px solid #738c9a;
  width: 100px;
  height: 80%;
  padding: 5px;
  h4 {
    font-size: 'Spartan-Regular';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #738c9a;
  }
  p {
    font-size: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    margin: 1px;
  }
`
export const ColumnTextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid #738c9a;
  width: 100px;
  height: 80%;
  padding: 5px;
  h4 {
    font-size: 'Spartan-Regular';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #738c9a;
  }
  p {
    font-size: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    margin: 1px;
  }
`
export const ColumnCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  padding: 5px;
  background: #edf1f3;
  border-radius: 50%;
  width: 170px;
  height: 60%;
  border: 1px solid #738c9a;
  text-align: center;
  h4 {
    font-size: 'Spartan-Regular';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #738c9a;
  }
  p {
    font-size: 'Spartan-Regular';
    height: auto;
    width: 90%;
    font-size: 12px;
    line-height: 12px;
    color: #1e1e1e;
    margin: 1px;
  }
`
export const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  border: 0.5px dashed #738c9a;
  margin-left: 24px;
  margin-top: 70px;
  border-radius: 8px;
  padding: 20px;
  .divTable {
    display: flex;
    width: 100%;
  }
  .divTableRow {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
  }
  .divTableHeading {
    background-color: #eee;
    display: table-header-group;
  }
  .divTableHead {
    border: 1px solid #999999;
    padding: 10px 10px;
    text-align: center;
    width: 50%;
    background-color: #f6f6f6;
  }
  .divTableCell {
    border: 1px solid #999999;
    padding: 10px 10px;
    text-align: center;
    width: 50%;
  }
  .divTableHeading {
    background-color: #eee;
    display: table-header-group;
    font-weight: bold;
  }
  .divTableFoot {
    background-color: #eee;
    display: table-footer-group;
    font-weight: bold;
  }
  .divTableBody {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`
export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  background: #738c9a;
  border-radius: 7.56662px;
  color: white;
  width: 80%;
  margin: auto;
  padding: 3px;
`
export const Subtitle = styled.h2`
  font-family: 'Spartan-SemiBold';
  letter-spacing: -0.05em;
  color: #2d749c;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: justify;
`

export const SubtitleGraphic = styled.h2`
  font-family: 'Spartan-SemiBold';
  letter-spacing: -0.05em;
  color: #2d749c;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
`

export const SubtitleTable = styled.h2`
  font-family: 'Spartan-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.05em;
  line-height: 19px;
  color: #2d749c;
  margin: 0px;
  margin-left: 10px;
  text-align: justify;
`
export const ContainerTextTableCenter = styled.div<IGraphicsContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: ${(props) => props.width || '80%'};
  p {
    margin: auto;
    width: auto;
    color: #98b06f;
    font-weight: 700;
  }
  .Subtitle {
    display: flex;
    font-family: 'Lato-Regular';
    margin: 1px;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
`
export const ContainerColumnGraphic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 20px;
`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
`

export const ContainerRow = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  z-index: 1;
  .line {
    width: 100%;
    z-index: -1;
    top: 50%;
    position: absolute;
    height: 1px;
    background-color: #c6016b;
  }
`

export const Circle = styled.div<ItemCount>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 5px;
  background: ${(props) => (props.active ? '#F5EBF1' : '#EAEDEE')};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 1px solid ${(props) => (props.active ? '#C6016B' : '#738C9A')};
  box-shadow: 0px 0px 4px 1px rgba(221, 122, 175, 0.46);
  h4 {
    font-size: 'Spartan';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #817c7c;
    text-align: center;
  }
`

/* border: 0.5px solid #C6016B;
background: #F5EBF1; */

export const TableQuadrants = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 80px 80px 80px 80px;
  grid-row: auto auto;
  /*grid-column-gap: 20px;
  grid-row-gap: 20px;*/
`

export const GraphicsContainer = styled.div<IGraphicsContainer>`
  position: relative;
  width: ${(props) => props.width || '220px'};
  height: ${(props) => props.width || '220px'};
  margin: 10px;
  p {
    font-family: 'Lato-Regular';
    text-align: center;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
  }
  .line {
    position: absolute;
    width: 65%;
    height: 1px;
    border-bottom: 1px solid #f00101;
    z-index: 4;
    left: 10%;
    right: 0;
    top: 55%;
    bottom: 0;
    margin: auto;
    transform-origin: 0 0;
    transform: rotate(5deg);
  }
  .line2 {
    position: absolute;
    width: 30%;
    height: 1px;
    border-bottom: 1px solid #f00101;
    z-index: 4;
    left: 0%;
    right: -8.5%;
    top: 0;
    bottom: 23%;
    margin: auto;
    transform-origin: 0 0;
    transform: rotate(349deg);
  }
  .line3 {
    position: absolute;
    width: 1px;
    height: 42.7%;
    border-right: 1px solid #f00101;
    z-index: 7;
    left: 0%;
    right: 20%;
    top: 22%;
    bottom: 0%;
    margin: auto;
    transform-origin: 0% 0%;
    transform: rotate(25deg);
  }
`

export const LineGraphicsArea = styled.div<ILineGraphicsArea>`
  position: absolute;
  width: ${(props) => props.width || '0'};
  height: 1px;
  border-bottom: 3px solid ${(props) => props.Bordercolor || '#F00101'};
  z-index: 4;
  left: 0;
  right: ${(props) => props.right || '0'};
  top: ${(props) => props.top || '0'};
  bottom: 0;
  margin: auto;
  transform-origin: 0 0;
  transform: rotate(${(props) => props.angle || '0'});
`

export const LineGraphicsArea2 = styled.div<ILineGraphicsArea>`
  position: absolute;
  width: ${(props) => props.width || '1px'};
  height: ${(props) => props.height || '1px'};
  border-right: 3px solid ${(props) => props.Bordercolor || '#F00101'};
  z-index: 4;
  left: 0;
  right: ${(props) => props.right || '0'};
  top: ${(props) => props.top || '0'};
  bottom: ${(props) => props.bottom || '0'};
  margin: auto;
  transform-origin: 0 0;
  transform: rotate(${(props) => props.angle || '0'});
`
export const LineGraphicsFrontLeft = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  color: ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  margin: -30px;
  font-weight: 600;
`
export const LineGraphicsFrontRight = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  color: ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  right: 0px;
  margin: -30px;
  font-weight: 600;
`
export const LineGraphicsBasalRight = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  color: ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: -30px;
  font-weight: 600;
`
export const LineGraphicsBasalLeft = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  color: ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  left: 0px;
  bottom: 0px;
  margin: -30px;
  font-weight: 600;
`

// brakes

export const BrakeFrontLeft = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  color: #000000;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  margin: 60px 0px 0px -120px;
`

export const BrakeFrontRight = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  color: #8800ff;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  margin: 60px -120px 0px 0px;
  right: 0;
`

export const BrakeBasalLeft = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  color: #8800ff;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  margin: 0px 0px 60px -120px;
  bottom: 0;
`

export const BrakeBasalRight = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  color: #8800ff;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  margin: 0px -120px 60px 0px;
  right: 0;
  bottom: 0;
`

export const ContainerNameProfile = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 30px;
  padding: 8px;
  gap: 8px;
  margin: 5px;
  background: #d2e3ec;
  .lineColorProfile {
    color: #c6016b;
    width: 30%;
  }
  .lineColorIdealProfile {
    color: #2d749c;
    width: 30%;
  }
`

export const ContainerDots = styled.div`
  position: absolute;
  z-index: 3;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const ItemDotsContainer = styled.div<IItemDots>`
  width: 35%;
  height: 1px;
  border-bottom: 1px dashed ${(props) => props.borderColor || '#F00101'};
  z-index: 4;
  margin-top: 50px;
  transform: rotate(${(props) => props.rotate || 0}deg);
  margin-top: ${(props) => props.top || 50}px;
  margin-left: 20px;
`

export const RoundedDotedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px dashed #f00101;
  background-color: transparent;
  z-index: 4;
  position: absolute;
`

export const RowContainer = styled.div<IRowContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  width: 100%;
  height: 60px;
`
export const CanvaContainer = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
`

// CATEGORIZERS

export const ContainerCategorizer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 200px;
`

interface ICategorizer {
  background?: string
  color?: string
}

export const Categorizer = styled.div<ICategorizer>`
  border: 1px solid #C6016B;
  border-radius: 20px;
  width: 100%;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background-color: ${(props) => props.background || '#fff'};
  color: ${(props) => props.color || '#000'};
  text-transform: uppercase;
  font-size: 13px;

  @media (max-width: 580px) {
    font-size: 12px;
    min-width: 120px;
    transform: scale(0.8);
  }

`;

export const Infocategorizer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  min-width: 140px;
  max-width: 300px;
  max-height: 200px;
  margin: 0px 0px 20px 0px;
  color: #FFFFFF;
  font-weight: 400;
  background-color: #2D749C;
  border-radius: 10px;
  position: absolute;
  top: -60px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

