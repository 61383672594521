import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-end;
  padding-left: 40%;
`

export const TextBoldViolet = styled.p<{ isDisabled?: boolean, isClicked?: boolean }>`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: ${props => (props.isDisabled || props.isClicked ? '#999999' : '#C6016B')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

interface IRow {
  width?: string;
}

export const RowContainer = styled.div<IRow>`
  width: ${(props: IRow) => props.width || 'auto'};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`

export const TextBoldBlue = styled.p<{ isItemSelected?: boolean; isDisabled?: boolean }>`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.85rem;
  color: ${props => (props.isDisabled ? '#A9A9A9' : '#2D749C')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin-right: 10px;
  margin-left: 6px;
  opacity: ${props => (props.isItemSelected ? 0.5 : 1)};
`;


export const DisassociateText = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  color: #E44646;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`

export const DeleteText = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #E44646;
  cursor: pointer;
  margin-left: 10px;
`

export const ContainerActionItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

interface IRow {
  width?: string;
  marginLeft?: string;
}

export const Row = styled.div<IRow>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: ${(props: IRow) => props.width || '100%'};
    margin-left: ${(props: IRow) => props.marginLeft || '0px'};
`;

export const DisabledBoldText = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.85rem;
  color: #999999;
  cursor: not-allowed;
  margin-left: 6px;
`;


export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`

export const TextInterpretation = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700; 
  color: #C6016B;
  cursor: pointer;
  margin-right: 10px;
`;
