import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  loading: false,
  error: false,
  interpretation: false,
  cobrandingLogo: '',
  companyName: '',
  isReadyCompetences: false,
  totalPagesDimensionCompetencePdf: 0,
}

export type InitialState = typeof initialState

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setInterpretation(state, action) {
      state.interpretation = action.payload
    },
    setCobrandingLogo(state, action) {
      state.cobrandingLogo = action.payload
    },
    setCompanyName(state, action) {
      state.companyName = action.payload
    },
    setIsReadyCompetences(state, action) {
      state.isReadyCompetences = action.payload
    },
    setTotalPagesDimensionCompetencePdf(state, action) {
      state.totalPagesDimensionCompetencePdf = action.payload
    },
  },
})

export const {
  setLoading,
  setError,
  setInterpretation,
  setCompanyName,
  setCobrandingLogo,
  setIsReadyCompetences,
  setTotalPagesDimensionCompetencePdf,
} = uiSlice.actions

export const uiReducer = uiSlice.reducer
