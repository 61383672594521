import { createSlice } from '@reduxjs/toolkit'
import { processDetailState } from './state'

const processDetailSlice = createSlice({
  name: 'user',
  initialState: processDetailState,
  reducers: {
    setProcessDetail(state, action) {
      state.data.processDetail = action.payload
    },
    setIdealProfileBetesa(state, action) {
      state.data.idealProfileBetesa = action.payload
    },
    setIdealProfileSkills(state, action) {
      state.data.idealProfileSkills = action.payload
    },
    setAllPlatformAdmins(state, action) {
      state.data.allPlatformAdmins = action.payload
    },
    setCurrentPage(state, action) {
      state.pagination.page = action.payload
    },
    setTotalPages(state, action) {
      state.pagination.totalPages = action.payload
    },
    setIsDirty(state, action) {
      state.ui.isDirty = action.payload
    },
    setTableChanged(state, action) {
      state.ui.tableChanged = action.payload
    },
    setIsUpdated(state, action) {
      state.ui.isUpdated = action.payload
    },
    setSaveChanges(state, action) {
      state.ui.saveChanges = action.payload
    },
    setAdminIdsToDelete(state, action) {
      state.data.adminIdsToDelete = action.payload
    },
    setAdminIdsToAdd(state, action) {
      state.data.adminIdsToAdd = action.payload
    },
    setAdminIdsToSave(state, action) {
      state.data.adminIdsToSave = action.payload
    },
  },
})

export const {
  setProcessDetail,
  setIdealProfileBetesa,
  setIdealProfileSkills,
  setAllPlatformAdmins,
  setCurrentPage,
  setTotalPages,
  setIsDirty,
  setIsUpdated,
  setSaveChanges,
  setAdminIdsToDelete,
  setAdminIdsToAdd,
  setAdminIdsToSave,
  setTableChanged,
} = processDetailSlice.actions

export const processDetailReducer = processDetailSlice.reducer
