import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  margin-top: 40px;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  margin-left: 40px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 30px;
`

export const StandardButton = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c6016b;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const Title = styled.div`
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
`
