import axios from 'axios'

export const fetchDevelopmentPlanSkills = async (id: string) => {
  const url = `${process.env.REACT_APP_MAIN_API}/development_plan/actions/results/${id}`
  try {
    const response = (await axios.get(url)).data.data
    response.id = id
    return response
  } catch (error) {
    console.error(error)
  }
}
