import axios, { AxiosResponse } from 'axios'
import { Data } from 'views/screens/Evaluated-User/store'
import { BetesaAnswers } from '../entities'

export const postBetesaAnswers = async (data: Data) => {
  const url = `${process.env.REACT_APP_MAIN_API}/betesa_result`
  try {
    const response = await axios.post(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const fetchSendAnswersBetesa = async (answers: BetesaAnswers): Promise<AxiosResponse> => {
  return axios.post('/betesa_result/answer/create', answers).then((res) => res.data)
}
