import { useState, } from 'react';
import {
  TextArea,
  InputContainer,
} from './styled';
import { MainLayout } from 'views/layouts';
import { H2 } from 'views/components/UI/Text';
import TitleDiv from 'views/components/UI/TitleDiv';
import { Button } from 'views/components/UI/Buttons';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { fetchUpdateSkillsAction } from '../../services/fetch-skills-talents';
import { useNotification } from 'lib/context/notification.context';

const EditOneAction = ({action, handleStep}: any) => {
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const { getError, getSuccess } = useNotification();
  const actionId = action?.data?.id
  const editTalentSchema = Yup.object().shape({
    description: Yup.string().trim().required('El nombre del contacto es obligatorio'),
  })
  const initial = {
    description: action?.data?.description ? action.data.description : '',
  }
  const onSubmit = (description: string, resetForm: any) => {
    fetchUpdateSkillsAction(
      {
        description_spa: description,
      }, Number(actionId)).then((res: any ) => {
        if(res.status === 'OK' || res.status === 'success'){
          resetForm()
          getSuccess('La descripción se actualizo correctamente')
          handleStep()
        }else {
          if(!res?.ok) getError(res?.message ? res?.message : 'La descripción no se pudo actualizar correctamente')
        }
    })
  }
  
  return (
    <MainLayout>
      <TitleDiv>
        <H2 style={{ fontSize: 32 }}>Editar acción de desarrollo</H2>
      </TitleDiv>
      <Formik
        validateOnChange={true}
        initialValues={initial}
        validationSchema={editTalentSchema}
        onSubmit={(values, { resetForm }) => {
          if (!isUploading) {
            setIsUploading(true)
            onSubmit(values.description, resetForm)
            setTimeout(() => {
              setIsUploading(false)
            }, 1000)
          }
        }}

      >{({ values, handleBlur, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
        <>
          <InputContainer>
            <TextArea
              color="#1F2124" fontSize="16px"
              placeholder="Texto de la acción de desarrollo"
              value={values.description}
              name="questionText"
              onChange={({ target }) => setFieldValue('description', target.value)}
              onBlur={handleBlur}
            />
          </InputContainer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 35, marginTop: 40, alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }} onClick={() => handleStep()} >Cancelar</span>
            <Button
              type='submit'
              onClick={handleSubmit}
              disabled={isSubmitting || !isValid}
            >
              Guardar
            </Button>
          </div>
        </>
      )}
      </Formik>
    </MainLayout>
  )
}
export default EditOneAction
