export const GET_SKILLS_TABLE = "/skill_general_data"
export const GET_GROUP = "/group"

export const handleFetchError = (text: any) => {
  return text
}

export const BACK_PAGE = -1; 

export const headers = [
  {
    key: 'group',
    name: 'Grupo',
  },
  {
    key: 'dimenssion',
    name: 'Dimensión',
  },
  {
    key: 'skill',
    name: 'Competencia',
  },
  {
    key: 'result',
    name: 'Resultado',
  },
  {
    key: 'action',
    name: '',
  },
]
