import React, { createContext, useContext, useState, useCallback } from 'react'

import { SelectedItemsProviderProps } from '../store/entities'
import { FiltersFetchProcesses, ProcessItem } from '../store/entities'
import { userSelector } from 'ducks/user'
import { useSelector } from 'react-redux'
import { getProcessByCompany } from '../services/fetch_skills_table_user'

const SelectedItemsContext = createContext(new Set())

export const useSelectedItems = () => useContext(SelectedItemsContext)

export const SelectedItemsProvider = ({ children, selectedItems }: SelectedItemsProviderProps) => {
  return <SelectedItemsContext.Provider value={selectedItems}>{children}</SelectedItemsContext.Provider>
}

export const DownloadIcon: React.FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2349_7241)">
        <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
        <path d="M3 12.0042V21H21V12" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M16.5 11.5L12 16L7.5 11.5"
          stroke="#2D749C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M12 3V16" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2349_7241">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const useFetchProcesses = () => {
  const { companyId } = useSelector(userSelector)
  const [listProcess, setListProcess] = useState<ProcessItem[]>([])
  const [loadingListProcesses, setLoading] = useState<boolean>(false)

  const fetchProcesses = useCallback(
    (filters: FiltersFetchProcesses) => {
      const { sent, limit, page } = filters
      if (loadingListProcesses) return
      setLoading(true)
      getProcessByCompany({ companyId, sent: sent, limit, page })
        .then((res: any) => {
          const responseFormatted: any = res.data.map((processitem: any) => ({
            ...processitem,
            categories: processitem.categories || 'No definido',
          }))
          setListProcess(responseFormatted)
          setLoading(false)
        })
        .catch((error: any) => {
          console.error(error)
          setLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId]
  )

  return { listProcess, loadingListProcesses, fetchProcesses }
}
