import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MainLayout from 'views/layouts/Main'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Container,
  StepsContainer,
  GridContainer,
  GridCompetence,
  GridItem,
  TitleGrid,
  Divider,
  Row,
  CompetencesAddedContainer,
  BottomButtonContainer,
} from './styled'
import { H2, H4, H5, P, SubtitleBold } from 'views/components/UI/Text'
import Steps from 'views/components/Stepper'
import { Button, StrokedButton, BackButton } from 'views/components/UI/Buttons'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { ActionEditAndDeleteBetesaSkillSelected } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import SearchBar from 'views/components/Searchbar'
import Pagination from 'views/components/Pagination'
import { platformAdminRoutes } from 'router/routes'
import { getBetesaSkills } from 'views/screens/Platform-administrator/services'
import { userSelector } from 'ducks/user'
import { getBetesaSkillIds, stepFourSelector } from 'ducks/createProcess'
import { setBetesaSkillSelected } from 'ducks/createProcess'
import ModalPriority from './modal-priority'
import { STEPS_NEW_PROCESS } from '../constants'
import { BRAIN_QUADRANTS } from 'constants/global.constants'
import { BetesaSkill } from 'types/common'
import { DEFAULT_LIMIT } from 'constants/global.constants'

const HEADERS = [
  {
    key: 'name',
    name: 'Nombre',
  },
]

function HomologationSkills() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { companyId } = useSelector(userSelector)
  const storeSkillIds = useSelector(getBetesaSkillIds)
  const stepFourData = useSelector(stepFourSelector)
  const isTurnBack = location.state?.isTurnBack

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [showPriorityModal, setShowPriorityModal] = useState(false)

  const [skills, setSkills] = useState<BetesaSkill[]>([])
  const [skillsSelected, setSkillsSelected] = useState<any[]>([])
  const [priorityQuadrant, setPriorityQuadrant] = useState<string>('')
  const [topTwoQuadrantsMostSelected, setTopTwoQuadrantsMostSelected] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const getSkills = useCallback(async () => {
    const queryParams: any = {
      companyId,
      limit: DEFAULT_LIMIT,
      page: currentPage,
    }

    if (searchValue) {
      queryParams.search = searchValue
    }

    const skillsResults = await getBetesaSkills(queryParams)

    setSkills(skillsResults.betesaSkills)
    setTotalPages(skillsResults.total_pages)
  }, [companyId, currentPage, searchValue])

  useEffect(() => {
    if (stepFourData?.betesaIdealProfile?.betesaSkills) {
      const skillsAdded = stepFourData.betesaIdealProfile.betesaSkills
      setSkillsSelected(skillsAdded)
      const skillsIds = skillsAdded.map((skill: { id: number }) => skill.id)
      dispatch(setBetesaSkillSelected(skillsIds))
    }
  }, [stepFourData, dispatch])

  useEffect(() => {
    if (stepFourData?.betesaIdealProfile?.profileValues && !isTurnBack) {
      navigate(platformAdminRoutes.IdealProfileBetesa)
    }
  }, [stepFourData, navigate, isTurnBack])

  useEffect(() => {
    getSkills()
  }, [currentPage, getSkills, searchValue])

  useEffect(() => {
    if (skills.length > 0 && storeSkillIds.length > 0) {
      const skillsAdded = skills.filter((skill) => storeSkillIds.includes(skill.id))
      setSkillsSelected(skillsAdded)
    }
  }, [skills, storeSkillIds])

  const handleAddSkill = (competence: BetesaSkill) => {
    if (!skillsSelected.find((skill) => skill.id === competence.id) && competence.quadrant_id) {
      const skillsAdded = [...skillsSelected, competence]
      setSkillsSelected(skillsAdded)
      setCurrentPage(1)
      const skillsIds = skillsAdded.map((skill) => skill.id)
      dispatch(setBetesaSkillSelected(skillsIds))
    }
  }

  function onEditSkill(skillItem: BetesaSkill) {
    const dataSkillToEdit = {
      id: skillItem.id,
      name: skillItem.name,
      companyId: skillItem.company_id,
      behavior: skillItem.behavior,
      quadrantId: skillItem.quadrant_id,
    }
    navigate(platformAdminRoutes.CreateEditHomologationSkills, { state: { skillData: dataSkillToEdit } })
  }

  const onDeleteSkill = (skillItem: BetesaSkill) => {
    const updatedSkills = skillsSelected.filter((skill) => skill.id !== skillItem.id)
    setSkillsSelected(updatedSkills)
    const skillsIds = updatedSkills.map((skill) => skill.id)
    dispatch(setBetesaSkillSelected(skillsIds))
  }

  const getMostSelectedQuadrant = () => {
    const quadrants = skillsSelected.map((skill) => skill.quadrant_id)

    const quadrantsCount = quadrants.reduce((acc, curr) => {
      switch (curr) {
        case 5:
          acc[4] = acc[4] ? acc[4] + 1 : 1
          acc[3] = acc[3] ? acc[3] + 1 : 1
          break
        case 6:
          acc[1] = acc[1] ? acc[1] + 1 : 1
          acc[4] = acc[4] ? acc[4] + 1 : 1
          break
        case 7:
          acc[3] = acc[3] ? acc[3] + 1 : 1
          acc[4] = acc[4] ? acc[4] + 1 : 1
          break
        case 8:
          acc[1] = acc[1] ? acc[1] + 1 : 1
          acc[2] = acc[2] ? acc[2] + 1 : 1
          break
        default:
          acc[curr] = acc[curr] ? acc[curr] + 1 : 1
          break
      }
      return acc
    }, {})

    const filteredQuadrantsCount = Object.keys(quadrantsCount)
      .filter((key: any) => key >= 1 && key <= 4)
      .reduce((obj: any, key) => {
        obj[key] = quadrantsCount[key]
        return obj
      }, {})

    const counts: any = Object.values(filteredQuadrantsCount)
    const maxCount = Math.max(...counts)
    const isTie = counts.filter((count: number) => count === maxCount).length > 1

    if (isTie) {
      return ''
    }

    const mostSelectedQuadrant = Object.keys(filteredQuadrantsCount).reduce((a, b) =>
      filteredQuadrantsCount[a] > filteredQuadrantsCount[b] ? a : b
    )

    const quadrantName = BRAIN_QUADRANTS.find((quadrant) => quadrant.value === parseInt(mostSelectedQuadrant))?.label
    return quadrantName
  }

  const getTwoMostSelectedQuadrants = () => {
    const quadrants = skillsSelected.map((skill) => skill.quadrant_id)

    const quadrantsCount = quadrants.reduce((acc, curr) => {
      switch (curr) {
        case 5:
          acc[4] = acc[4] ? acc[4] + 1 : 1
          acc[3] = acc[3] ? acc[3] + 1 : 1
          break
        case 6:
          acc[1] = acc[1] ? acc[1] + 1 : 1
          acc[4] = acc[4] ? acc[4] + 1 : 1
          break
        case 7:
          acc[3] = acc[3] ? acc[3] + 1 : 1
          acc[4] = acc[4] ? acc[4] + 1 : 1
          break
        case 8:
          acc[1] = acc[1] ? acc[1] + 1 : 1
          acc[2] = acc[2] ? acc[2] + 1 : 1
          break
        default:
          acc[curr] = acc[curr] ? acc[curr] + 1 : 1
          break
      }
      return acc
    }, {})

    const filteredQuadrantsCount = Object.keys(quadrantsCount)
      .filter((key: any) => key >= 1 && key <= 4)
      .reduce((obj: any, key) => {
        obj[key] = quadrantsCount[key]
        return obj
      }, {})

    const sortedQuadrants = Object.keys(filteredQuadrantsCount).sort(
      (a, b) => filteredQuadrantsCount[b] - filteredQuadrantsCount[a]
    )
    const twoMostSelectedQuadrants = sortedQuadrants.slice(0, 2)
    const quadrantsName = twoMostSelectedQuadrants.map(
      (quadrant) => BRAIN_QUADRANTS.find((q) => q.value === parseInt(quadrant))?.label
    )

    return quadrantsName
  }

  const handleNext = () => {
    const mostSelectedQuadrant = getMostSelectedQuadrant()
    setPriorityQuadrant(mostSelectedQuadrant || '')
    const topQuadrantsMostSelected = getTwoMostSelectedQuadrants()
    setTopTwoQuadrantsMostSelected(topQuadrantsMostSelected)
    setShowPriorityModal(true)
  }

  const handleSkipStep = () => {
    navigate(platformAdminRoutes.IdealProfileBetesa)
  }

  return (
    <MainLayout>
      <Container>
        <>
          <H2>Sube tu diccionario de competencias</H2>
          <StepsContainer>
            <Steps steps={STEPS_NEW_PROCESS} activeStep={3} />
          </StepsContainer>
          <Row justify="flex-end" margin="0px">
            <StrokedButton type="button" onClick={handleSkipStep}>
              Saltar este paso
            </StrokedButton>
          </Row>
          <Row justify="flex-end">
            <StrokedButton onClick={() => navigate(platformAdminRoutes.CreateEditHomologationSkills)}>
              Nueva competencia
            </StrokedButton>
          </Row>
          <H4 margin="-34px 0px 20px 0px">Competencias</H4>
          <SearchBar
            label="Buscar competencia"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
          />
          {skills.length > 0 ? (
            <GridContainer>
              <GridCompetence>
                <GridItem>
                  <TitleGrid>Nombre</TitleGrid>
                </GridItem>
                <GridItem>
                  <TitleGrid>Cuadrante</TitleGrid>
                </GridItem>
              </GridCompetence>
              <Divider margin="2px 0px 16px 0px" />
              <GridCompetence>
                {skills.map((competence, index) => (
                  <>
                    <GridItem key={index}>
                      <P>{competence.name}</P>
                    </GridItem>
                    <GridItem width="140px">
                      <SubtitleBold>
                        {competence.quadrant_id
                          ? BRAIN_QUADRANTS.find((quadrant) => quadrant.value === competence.quadrant_id)?.label
                          : 'Sin cuadrante'}
                      </SubtitleBold>
                    </GridItem>
                    <GridItem>
                      <Row justify="flex-end">
                        <StrokedButton
                          onClick={() => handleAddSkill(competence)}
                          type="button"
                          disabled={
                            !competence.quadrant_id || skillsSelected.find((skill) => skill.id === competence.id)
                          }
                        >
                          Agregar
                        </StrokedButton>
                      </Row>
                    </GridItem>
                  </>
                ))}
              </GridCompetence>
              <div style={{ margin: '40px 0px 0px 0px' }}>
                {totalPages > 1 && (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={(newPage) => {
                      setCurrentPage(newPage)
                      getSkills()
                    }}
                  />
                )}
              </div>
            </GridContainer>
          ) : (
            <H5 margin="40px 0px 20px 0px">No se han encontrado competencias.</H5>
          )}

          {skillsSelected.length > 0 && (
            <CompetencesAddedContainer>
              <H4 margin="24px 0px 24px 0px">Competencias agregadas</H4>
              <BluesiteTable
                data={skillsSelected}
                headers={HEADERS}
                searchBar={false}
                searchLabel="Buscar competencia:"
                totalPages={0}
                currentPage={1}
                limit={10}
                loading={false}
                ActionComponent={ActionEditAndDeleteBetesaSkillSelected}
                extraDataAction={{
                  onDeleteSkill,
                  onEditSkill,
                }}
              />
            </CompetencesAddedContainer>
          )}
        </>
        <BottomButtonContainer>
          <BackButton onClick={() => navigate(platformAdminRoutes.SelectTestsType)} />
          <Button disabled={skillsSelected.length < 1} onClick={handleNext} type="submit">
            Siguiente
          </Button>
        </BottomButtonContainer>
      </Container>
      <ModalPriority
        show={showPriorityModal}
        topTwoQuadrantsSelected={topTwoQuadrantsMostSelected}
        setShow={() => setShowPriorityModal(false)}
        priorityQuadrant={priorityQuadrant}
        setPriorityQuadrant={setPriorityQuadrant}
      />
    </MainLayout>
  )
}

export default HomologationSkills
