import axios from 'axios'
import { IGeneralData } from 'types/common'

interface IDataResponse {
  status: string
  data: IGeneralData
}

/**
 * @description service to get general data
 * @param {string} token
 */
const getGeneralText = async (name: string) => {

  try {

    const response = await axios.get(`/basic_text/general_text?id_type=${name}`).then((res): IDataResponse => {
      return res.data?.data;
    }).catch((error) => {
      return { ok: false, message: error.message };
    });
    return response

  } catch (error) {
    return error
  }
}


export const putGeneralText = async (data: any, id: any) => {
  const res = await axios.put(`/basic_text/general_text/${id}`, data).then((response) => {
    return response.data;
  }).catch((error) => {
    return { ok: false, message: error.message };
  });
  return res

}


export default getGeneralText
