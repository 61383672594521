import axios from 'axios'

interface IOption {
    id: number,
    text: string,
    weight: number,
    quadrant: string,
    question_id: number,
}

interface IQuestion {
    id: number,
    text: string,
    active: boolean,
    is_betesa?: boolean,
    reverse_order: boolean,
    options?: IOption[]
    levelId: number,
    groupId: number,
    dimensionId: number,
    skillId: number,
    skill: string,
}


type IResponse = {
    status: string,
    question: IQuestion
}

/** 
 * @description service to delete a question by id
 */
const deleteQuestionSkillsById = async ( idQuestion: number) => {


    const apiUrl = `${process.env.REACT_APP_MAIN_API}/question/${idQuestion}`

    // get

    const response: IResponse = await axios.delete(apiUrl).then((res) => {
        console.info('res delete questin', res)
        return res.data
    }).catch((error) => {
        return { ok: false, message: error.message };
    })

    return response
  
}

export default deleteQuestionSkillsById
