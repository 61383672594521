import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
    cursor: pointer;
`;

interface IProps {
    width?: string;
    height?: string;
    onClick?: () => void;
    color?: string;
}

const CloseIcon = (props: IProps) => {
    const { width, height, onClick, color } = props;


    return (
        <Container onClick={onClick}>
            <svg width={width || '12px'} height={height || '15px'} viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.40864 7.21719L11.7045 2.06879C12.0962 1.59821 12.0962 0.83524 11.7045 0.364656C11.3127 -0.105927 10.6776 -0.105927 10.2859 0.364656L6 5.52506L1.71414 0.364656C1.3224 -0.105927 0.687256 -0.105927 0.295513 0.364656C-0.0962308 0.83524 -0.0962308 1.59821 0.295513 2.06879L4.59136 7.21719L0.295513 12.3656C0.106383 12.5909 0 12.8977 0 13.2177C0 13.5376 0.106383 13.8444 0.295513 14.0697C0.483098 14.2969 0.738447 14.4247 1.00483 14.4247C1.27121 14.4247 1.52656 14.2969 1.71414 14.0697L6 8.90932L10.2859 14.0697C10.4734 14.2969 10.7288 14.4247 10.9952 14.4247C11.2616 14.4247 11.5169 14.2969 11.7045 14.0697C11.8936 13.8444 12 13.5376 12 13.2177C12 12.8977 11.8936 12.5909 11.7045 12.3656L7.40864 7.21719Z" fill={color || "#2D749C"}/>
            </svg> 
        </Container>
    );
};

export default CloseIcon;