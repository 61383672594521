import firstTokenRequest from 'lib/axios/firstTokenRequest'

type IUser = {
  personId: string
  userId: string
  first_name: string
  last_name: string
  role: string
  email: string
  active: boolean
}

interface IFindUserResponse {
  status: string
  data: IUser
}

const findUser = async () => {
  const response: IFindUserResponse = await firstTokenRequest.get(`/user/one`).then((res) => {
    return res.data
  })

  return response
}

export default findUser
