import React, { useState, useEffect } from 'react'
import Modal from 'views/components/UI/Modal'
import Input from 'views/components/UI/TextInput'
import { Props } from '../../store/entities'
import { useNotification } from 'lib/context/notification.context'
import { handleFetchError } from '../../constants'
import { fetchGetFitnessTextAction, updateFitnessTextAction } from '../../services/fetch-betesa-texts'

import { BtnGoBack, SaveButton, Row, GridModal, ModalContainer, ModalBody } from './styled'

export default function Fitness(props: Props) {
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (props.openFitness) {
      setOpenModal(props.openFitness)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openFitness])

  const { getWarning, getSuccess } = useNotification()
  const [descriptions, setDescriptions] = useState<string[]>([])
  const [idFitness, setIdFitness] = useState<number[]>([])

  useEffect(() => {
    const fetchSelectedData = async () => {
      try {
        const response = await fetchGetFitnessTextAction(props.id)
        if (!response || response.length === 0) {
          throw new Error('Error en la solicitud')
        }
        const descriptionsData = response.map((item: { description: string }) => item.description)
        setDescriptions(descriptionsData)
        setIdFitness(response.map((item: { id: number }) => item.id))
      } catch (error) {
        handleFetchError('No se encontraron datos')
      }
    }
    fetchSelectedData()
  }, [props.id])

  const handleInputChange = (index: number, value: string) => {
    const updatedDescriptions = [...descriptions]
    updatedDescriptions[index] = value
    setDescriptions(updatedDescriptions)
  }

  const handleUpdateFitness = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    try {
      const updatedData = descriptions.map((description, index) => ({
        id: idFitness[index],
        description: description,
      }))

      const body = {
        data: updatedData,
      }

      await updateFitnessTextAction(body)
      getSuccess('Aptitudes de cuadrante actualizados correctamente')
      setOpenModal(false)
    } catch (error) {
      console.error(error)
      getWarning('Error al actualizar el inventario')
    }
  }

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '560px',
        height: '380px',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalBody>
          <h3 style={{ textAlign: 'left', marginTop: 0 }}>Aptitudes del cuadrante</h3>
          <GridModal>
            <Input
              name="name"
              type="text"
              label="Texto"
              value={descriptions[0] || ''}
              onChange={(e) => handleInputChange(0, e.target.value)}
            />
            <Input
              name="name"
              type="text"
              label="Texto"
              value={descriptions[1] || ''}
              onChange={(e) => handleInputChange(1, e.target.value)}
            />
            <Input
              name="name"
              type="text"
              label="Texto"
              value={descriptions[2] || ''}
              onChange={(e) => handleInputChange(2, e.target.value)}
            />
            <Input
              name="name"
              type="text"
              label="Texto"
              value={descriptions[3] || ''}
              onChange={(e) => handleInputChange(3, e.target.value)}
            />
            <Input
              name="name"
              type="text"
              label="Texto"
              value={descriptions[4] || ''}
              onChange={(e) => handleInputChange(4, e.target.value)}
            />

            <Row>
              <BtnGoBack
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Cancelar
              </BtnGoBack>
              <SaveButton onClick={handleUpdateFitness}>Enviar</SaveButton>
            </Row>
          </GridModal>
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}
