import styled from 'styled-components'

interface IGrid {
  border?: boolean
}

interface Ilabel {
  borderTop?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;

  @media (max-width: 1280px) {
    overflow-x: scroll;
  }
`

export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`

export const Scale = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: -10px;
`

interface IScaleItem {
  color: string
}

export const ItemScale = styled.div<IScaleItem>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  border: ${(props: IScaleItem) => `1.5px solid ${props.color}`};
  color: ${(props: IScaleItem) => props.color};
  height: 26px;
  overflow: hidden;
`

interface IItemScaleText {
  color: string
}

export const ItemScaleText = styled.p<IItemScaleText>`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  margin: 0;
  color: ${(props: IItemScaleText) => props.color};
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const LegendContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  right: -460px;
`

export const ItemLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
`

export const TextLegend = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  margin: 0;
  margin-left: 10px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f2124;
`

export const Grid = styled.div`
  display: grid;
  margin-left: 80px;
  width: 53%;
  grid-template-columns: 1fr 1fr;
  gap: 0px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: 0px 3px 5px 2px #ccc;

  @media (max-width: 1351px) {
    margin-left: 40px;
  }
`

export const Label = styled.label<Ilabel>`
  box-sizing: border-box;
  border-top: ${(props) => props.borderTop || 'solid 1px lightgray'};
  border-right: solid 0.1px lightgray;
  padding: 10px;
  color: #212b36;
`

export const Input = styled.input`
  width: 90%;
  height: 80%;
  border-radius: 8px;
`

export const Button = styled.button`
  display: block;
  color: white;
  margin: 30px 140px;
  padding: 11px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #c6016b;
  border: none;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }

  @media (max-width: 1351px) {
    margin: 30px 90px;
  }
`

export const GridTitles = styled.div<IGrid>`
  font-weight: bold;
  background-color: #e6eef3;
  padding: 10px;
  color: #212b36;
  border-right: ${(props) => (props.border ? '1px solid lightgray' : 'none')};
  border-bottom: 1px solid lightgray;
  border-radius: ${(props) => (props.border ? '8px 0 0 0' : '0 8px 0 0')};
`

export const GraphicContainer = styled.div`
  margin-left: 120px;
  margin-top: -20px;

  @media (max-width: 1415px) {
    margin-left: 80px;
  }
  @media (max-width: 1377px) {
    margin-left: 60px;
  }
  @media (max-width: 1351px) {
    margin-left: 40px;
  }
`

export const ButtonEdit = styled.div`
  display: block;
  color: #2d749c;
  justify-content: center;
  align-items: center;
  border: none;
`

export const ButtonEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 100px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(0.5px);
  }

  @media (max-width: 1351px) {
    margin-left: 50px;
  }
`

export const PencilIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

export const LineReferenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`

export const LineReferenceLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LineReferenceLeft = styled.div`
  font-size: 16px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const LineReferenceRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LineReferenceRight = styled.div`
  font-size: 16px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const CircleLeft = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #2d749c;
  margin-right: 10px;
`

export const CircleRight = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c6016b;
  margin-right: 10px;
`
