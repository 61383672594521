export  const parseQuadrantFullNameToShortName = (quadrant: string) => {
    switch (quadrant) {
          case 'Frontal Izquierdo':
            return 'FI';
          case 'Frontal Derecho':
            return  'FD';
          case 'Basal Izquierdo':
            return 'BI';
          case 'Basal Derecho':
            return 'BD';
          default:
            return quadrant;
        }
};

// eslint-disable

interface ITest { 
    process_id: number,
    process_name: string,
    process_description: string,
    test_id: number,
    domination: string;
    test_name: string,
    category_name: string,
    type_test_id: number,
    user_test_id: number,
    completed_test: boolean,
    betesa_test_id: number | null
}

/* eslint-disable */


export const orderTestsByProcess = (tests: ITest[] ) => {
  const testsByProcess = tests.reduce((acc, test) => {
    const { process_id, process_name, process_description, test_id, test_name, category_name, type_test_id, user_test_id, completed_test, betesa_test_id, domination } = test;
    const process = acc.find((process: any) => process.process_id === process_id);
    if (process) {
      process.tests.push({ test_id, test_name, category_name, type_test_id, user_test_id, completed_test, betesa_test_id: test.betesa_test_id, domination, process_name});
    } else {
      acc.push({
        process_id,
        process_name,
        process_description,
        tests: [{ test_id, test_name, category_name, type_test_id, user_test_id, completed_test, betesa_test_id: test.betesa_test_id, domination, process_name }],
      });
    }
    return acc;
  }
  , [] as any);

  return testsByProcess;
}
