import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authSelector, setGeneralData } from 'ducks/auth'
import { setAuth } from 'ducks/auth/reducer'
import { Routes, STORAGE_TOKEN, TOKEN } from 'constants/global.constants'
import { logout, setUser } from 'ducks/user/reducer'
import jwt_decode from 'jwt-decode'
import UserEvaluatedRouter from 'router/private/evaluated-user'
import PlatformAdministratorRouter from 'router/private/platform-admin'
import SuperAdministratorRouter from 'router/private/super-admin/super-admin'
import SpecialAdminRouter from './special-admin/special-admin.routes'

const typeUser = ['super_admin', 'platform_admin', 'super_platform_admin', 'user', 'special_admin']

const PrivateRouter: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { role } = useSelector(authSelector)
  const token = localStorage.getItem(TOKEN) || ''
  const [currentTypeUser, setCurrentTypeUser] = useState('')

  const superAdmin = typeUser[0]
  const platformAdmin = typeUser[1]
  const user = typeUser[3]
  const specialAdmin = typeUser[4]
  const superPlatformAdmin = typeUser[2]

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const path = window.location.pathname
    const tokenUrl = urlParams.get('t')

    if (tokenUrl) {
      dispatch(logout())
      dispatch(setGeneralData({}))
      dispatch(
        setAuth({
          role: '',
          isAuth: false,
        })
      )

      localStorage.clear()

      navigate(`${path}?t=${tokenUrl}`)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!token) {
      return
    }
    const decoded: any = jwt_decode(token)
    const expirationDate = new Date(decoded.exp * 1000)

    if (expirationDate < new Date()) {
      dispatch(
        setAuth({
          isAuth: false,
          role: '',
        })
      )
      dispatch(
        setUser({
          token: '',
          email: '',
          companyId: 0,
          userName: '',
        })
      )
      navigate(Routes.login)
    }

    setCurrentTypeUser(role)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, token])

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === STORAGE_TOKEN) {
        const newValue = event.newValue ? JSON.parse(event.newValue) : null
        if (newValue) {
          const userString = newValue.user
          const userStorage = userString ? JSON.parse(userString) : null
          if (userStorage && userStorage.token === '') {
            dispatch(logout())
            dispatch(setGeneralData({}))
            dispatch(
              setAuth({
                role: '',
                isAuth: false,
              })
            )
            dispatch(
              setUser({
                token: '',
                email: '',
                companyId: 0,
                userName: '',
              })
            )
            navigate(Routes.login)
          }
        }
      }
    }
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {currentTypeUser === specialAdmin && <SpecialAdminRouter />}
      {currentTypeUser === superAdmin && <SuperAdministratorRouter />}
      {(currentTypeUser === platformAdmin || currentTypeUser === superPlatformAdmin) && <PlatformAdministratorRouter />}
      {currentTypeUser === user && <UserEvaluatedRouter />}
    </>
  )
}

export default PrivateRouter
