/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createGlobalStyle } from 'styled-components'

//@ts-ignore
import Inter from '../../assets/fonts/Inter.ttf'
//@ts-ignore
import PublicSans from '../../assets/fonts/PublicSans.ttf'
//@ts-ignore
import SpartanRegular from '../../assets/fonts/SpartanRegular.ttf'
//@ts-ignore
import SpartanExtraBold from '../../assets/fonts/SpartanExtraBold.ttf'
//@ts-ignore
import SpartanSemiBold from '../../assets/fonts/SpartanSemiBold.ttf'
//@ts-ignore
import LatoRegular from '../../assets/fonts/Lato-Regular.ttf'

const InterFont = `
  @font-face {
    font-family: Inter;
    src: url(${Inter}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`

const PublicSansFont = `
  @font-face {
    font-family: Public Sans;
    src: url(${PublicSans}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`
const FontSpartanExtraBold = `
  @font-face {
    font-family: Spartan-ExtraBold;
    src: url(${SpartanExtraBold}) 
  }
`
const FontSpartanSemiBold = `
  @font-face {
    font-family: Spartan-SemiBold;
    src: url(${SpartanSemiBold}) 
  }
`
const FontSpartanRegular = `
  @font-face {
    font-family: Spartan-Regular;
    src: url(${SpartanRegular}) 
  }
`
const FontLato = `
  @font-face {
    font-family: Lato-Regular;
    src: url(${LatoRegular}) 
  }
`

const StyledApp = createGlobalStyle`
 ${InterFont}
 ${PublicSansFont}
 ${FontSpartanExtraBold}
 ${FontSpartanSemiBold}
 ${FontSpartanRegular}
 ${FontLato}

  body {
    width: 100vw;
    height: 100vh;
    margin:0;
    padding:0;
    overflow: hidden;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (max-width: 992px) {
      overflow: scroll;
    }
  }
  #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    @media (max-width: 992px) {
      overflow: scroll;
    }
  }
  nav {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    letter-spacing: 0%;
    line-height: 22px;
    font-weight: 400;
    color: #565867;

  }
  a {
    color: inherit;
    text-decoration: none;
  }
  div a.active {
    background: rgba(230, 171, 203, 0.2);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: 7px solid #c6016b;
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
  div a.active svg{
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
  div a.active :hover {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border-left: none;
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
  }
  div a svg :hover {
    color: #c6016b;
    font-weight: bold;
    fill: #c6016b;
    background: blue;
  }
  h1 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    letter-spacing: 0%;
    line-height: 64px;
    font-weight: bold;
  }
  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    letter-spacing: 0%;
    line-height: 48px;
    font-weight: bold;
  }
  h3 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    letter-spacing: 0%;
    line-height: 36px;
    font-weight: bold;
  }
  h4 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    letter-spacing: 0%;
    line-height: 30px;
    font-weight: bold;
  }
  h5 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    letter-spacing: 0%;
    line-height: 28px;
    font-weight: bold;
  }
  input{
    font-family: 'Public Sans', sans-serif;
    font-size: 16px;
    letter-spacing: 0%;
    line-height: 22px;
    font-weight: normal;
  }
  p{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #797A7C;
  }
  span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13.2466px;
  }

  tr td {
    padding: 10px 0 10px 15px;
  }
  thead th {
  padding: 15px;
  }
  .cardOne{
    background: #DBF0CE;
  }
  .cardTwo{
    background: #D1E6F1;
  }
  .cardThree{
    background: #FFEEDA;
  }
  .cardFour{
    background: #EEDBFE;
  }
  .textAreaDiv{
    font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 220px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;
overflow: auto;
&::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2D749C;
    border-radius: 10px;
    border: 1px solid #2D749C;
    cursor: pointer;
  }
  }
`
export default StyledApp
