interface IProps {
  width?: string
  height?: string
}

const ClockIcon = (props: IProps) => {
  const { width, height } = props
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      style={{ paddingTop: '9px', paddingLeft: '5px' }}
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 11h6v1h-7v-9h1v8z" />
    </svg>
  )
}

export default ClockIcon
