import { Routes } from 'constants/global.constants'
import axios from 'lib/axios'
import { ADMIN_PLATFORM_TABS } from '../constants'
import { UpdateUsers, UpdateUsersResponse, DeleteUser } from '../store'

export const fetchUpdatePlatformAdmins = async (body: UpdateUsers): Promise<UpdateUsersResponse[]> => {
  try {
    const response = await axios.put(`${Routes.users}/${ADMIN_PLATFORM_TABS.update}`, body)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const fetchDeletePlatformAdmins = async (userId: DeleteUser) => {
  try {
    const response = await axios.delete(`${Routes.users}/${userId.userId}`)
    return response.data
  } catch (error) {}
}
