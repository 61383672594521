import styled from 'styled-components'

interface IProgressGraphic {
  width?: number
  backgroundColor?: string
  border?: string
  justifyContent?: string
  color?: string
  backgroundColorbar?: string
}

export const ProgressGraphicContainer = styled.div<IProgressGraphic>`
  display: flex;
  padding: 0.4rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : null)};
`
export const ProgressContainer = styled.div<IProgressGraphic>`
  width: 100%;
  height: 5px;
  background-color: ${(props) => (props.backgroundColorbar ? props.backgroundColorbar : null)};
  position: relative;
  border-radius: 8rem;
`
export const ProgressBar = styled.div<IProgressGraphic>`
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  height: 5px;
  background-color: ${(props) => (props.backgroundColorbar ? props.backgroundColorbar : '#738C9A')};
  position: relative;
  border-radius: 8rem;
  z-index: 2;
`
export const ProgressValue = styled.div<IProgressGraphic>`
  width: 4px;
  height: 4px;
  font-size: 12px;
  position: absolute;
  right: -20px;
  top: 50%;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : '#ffffff')};
  border-radius: 50%;
  padding: 10px;
  border: ${(props) => (props.border ? `1px solid ${props.border}` : '1px solid green')};
  color: ${(props) => (props.color ? `${props.color}` : '#000000')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`
export const DataDivider = styled.hr`
  border: 1px dashed #d2e3ec;
  width: 16px;
  z-index: 1;
`
export const PercentilData = styled.div<IProgressGraphic>`
  background-color: white;
  border-radius: 50%;
  font-weight: bold;
  border: 2px dashed #d2e3ec;
  z-index: 2;
  position: relative;
  align-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 12px;
`
export const PercentilValue = styled.div<IProgressGraphic>`
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 12px;
  border-radius: 50%;
  padding: 10px;
  font-weight: bold;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 1;
  color: #d2e3ec;
`
export const ExpectedScore = styled.div<IProgressGraphic>`
  left: ${(props) => `${props.width}%`};
  top: 0;
  height: 10px;
  background-color: transparent;
  position: absolute;
  border-radius: 8rem;
`
export const UserScore = styled.div<IProgressGraphic>`
  left: ${(props) => `${props.width}%`};
  top: 0;
  height: 10px;
  background-color: transparent;
  position: absolute;
  border-radius: 8rem;
`
export const ExpectedScoreValue = styled.div<IProgressGraphic>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  background-color: #2d749c;
  border-radius: 50%;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
export const UserScoreValue = styled.div<IProgressGraphic>`
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  background-color: #c6016b;
  border-radius: 50%;
  font-weight: bold;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
export const GapContainer = styled.div`
  position: absolute;
  /*  right: -10px; */
  top: -30px;
  color: black;
  font-size: 12px;
  margin-top: 8px;
`
