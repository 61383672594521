import React from 'react'
import styled from 'styled-components'

import logo from '../../../../assets/images/logos/logo.svg'

const Image = styled.img`
  // establece witdh dinamicante con props
  width: ${(props: { width: string }) => props.width || '100%'};
  display: block;
  margin-left: auto;
  margin-right: auto;
`

interface IProps {
  width?: string
}

const Logo = (props: IProps) => {
  const { width } = props

  return <Image width={width || ''} src={logo} alt="Logo de bluesite" />
}

export default Logo
