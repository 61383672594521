import axios from 'axios'

type Data = {
  text: string
  typeQuestion: string
  reverseOrder: boolean
  typeCategoryTestId: number
  chapterId: number
  dimensionId: number
  optionsQuestions?: any[] | []
}

/**
 * @description service to update skill question
 * @param token
 * @param data
 */
const updateQuestionSkills = async (data: Data, idQuestion: number) => {
  const { text, typeQuestion, reverseOrder, typeCategoryTestId, chapterId, dimensionId, optionsQuestions } = data

  const apiUrl = `${process.env.REACT_APP_MAIN_API}/question/${idQuestion}`

  const body: any = {
    text: text,
    type_question: typeQuestion,
    reverse_order: reverseOrder,
    type_category_test_id: typeCategoryTestId,
    chapter_id: chapterId,
    dimension_id: dimensionId,
    optionsData: optionsQuestions,
  }

  const res = await axios
    .put(apiUrl, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })
  return res
}

export default updateQuestionSkills
