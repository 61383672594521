import { useState, useEffect, useRef } from 'react'
import { ReportSchema } from 'views/components/report/skill-report-schema'
import { useSelector } from 'react-redux'
import { Resume } from 'services/skills-report/resume'
import { Groups } from 'services/skills-report/groups'
import { IdealProfile } from 'services/skills-report/ideal-profile'
import { GeneralTextByDimensions } from 'services/skills-report/general-text-by-dimensions'
import { DimensionScore } from 'services/skills-report/dimension-score'
import FetchPercentils from 'services/report/fetchPercentils'
import { useDispatch } from 'react-redux'
import { ReportData } from 'views/components/preview-generate-report/store'

type SkillReportProps = {
  testId?: any
  userTestId?: number
  userId?: number
  handleComplete?: () => void
  isAdmin?: boolean
  processIdParam?: number
}

const ReportSkills: React.FC<SkillReportProps> = ({
  testId,
  userTestId,
  userId,
  handleComplete,
  isAdmin,
  processIdParam,
}) => {
  const dispatch = useDispatch()
  const { token } = useSelector((state: any) => state.user)
  const [resume, setResume] = useState({})
  const [groups, setGroups] = useState({})
  const [idealProfile, setIdealProfile] = useState({} as any)
  const [generalTextByDimensions, setGeneralTextByDimensions] = useState({})
  const [dimensionScore, setDimensionScore] = useState({} as ReportData)
  const [percentilData, setPercentilData] = useState({})
  const [hasCompetenceData, setHasCompetenceData] = useState<boolean>(false)

  const hasCalledHandleComplete = useRef(false)

  useEffect(() => {
    if (token && userTestId && userId) {
      Promise.all([
        Resume(token, userTestId),
        Groups(token, userTestId, true),
        IdealProfile(token, userTestId),
        GeneralTextByDimensions(token),
        DimensionScore(token, userId, testId),
        FetchPercentils(token, userId, testId),
      ])
        .then(
          ([
            resResume,
            resGroups,
            resIdealProfile,
            resGeneralTextByDimensions,
            resDimensionScore,
            resPercentilData,
          ]) => {
            setResume(resResume.data)
            setGroups(resGroups.data)
            setIdealProfile(resIdealProfile.data)
            setGeneralTextByDimensions(resGeneralTextByDimensions.data)
            setDimensionScore(resDimensionScore.data)
            setPercentilData(resPercentilData)
            setHasCompetenceData(true)
          }
        )
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          if (handleComplete && !hasCalledHandleComplete.current) {
            handleComplete()
            hasCalledHandleComplete.current = true
          }
        })
    }
  }, [dispatch, token, userTestId, userId, testId, handleComplete])

  return (
    <>
      {hasCompetenceData && (
        <ReportSchema
          resume={resume}
          groups={groups}
          idealProfile={idealProfile}
          generalTextByDimensions={generalTextByDimensions}
          dimensionScore={dimensionScore}
          percentil={percentilData}
          isAdmin={isAdmin}
          processIdParam={processIdParam ? processIdParam : ''}
        />
      )}
    </>
  )
}

export default ReportSkills
