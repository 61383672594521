/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react'
import { Container, Title, StandardButton, UsersButton, ButtonDisabled, BottomButtonsContainer } from './styled'
import * as M from '../Ninebox/styled'
import { P } from 'views/components/UI/Text/'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import BackButton from 'views/components/UI/BackButton'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import iconInfo from '../../../components/assets/iconInfo.png'
import Modal from 'views/components/UI/Modal'
import { TextField } from '@mui/material'
import getNineBoxAnalytics from 'services/competence/GetNineBoxAnalytics'
import {
  INineBoxAnalytics,
  INineBoxResponse,
  ISavedResponse,
  User9Box,
  RNineBox,
  User,
} from 'views/screens/Platform-administrator/Analytics/constants/types'
import TooltipComponent from 'views/components/Tooltip'
import { useNotification } from 'lib/context/notification.context'
import saveNineBox from 'services/competence/saveNineBox'
import {
  NoDataContainer,
  NoDataIcon,
  NoDataSubContainer,
  NoDataText,
  TextContainer,
} from 'views/screens/Platform-administrator/Analytics/styled'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import updateNineBox from 'services/competence/updateNineBox'
import IdealProfileModal from '../Ninebox/idealProfileModal'
import { useTranslation } from 'react-i18next'

const Bullet = () => <span style={{ color: 'black' }}> • </span>

export interface INineBox {
  typeNineBox?: string
  processId: string
  noData?: (value: boolean) => void
  setShowUsersModal: (value: boolean) => void
  setShowHistory: (value: boolean) => void
  evaluatedUsers: number[]
  savedNineBox: ISavedResponse
  validatedInputs: { potential: string; performance: string }
  setRefreshSavedNineBox: React.Dispatch<React.SetStateAction<boolean>>
  historySelectedNineBox: RNineBox
  clearHistorySelectedNineBox: () => void
  saveSession: (value: INineBoxAnalytics) => void
  sessionData: RNineBox
  setHistoryAddedUsers: React.Dispatch<React.SetStateAction<number[]>>
  triggerEffect: boolean
  sessionValidatedInputs: { potential: string; performance: string }
  setHistoryUsersPerformance: React.Dispatch<React.SetStateAction<User9Box[]>>
  nameOnInput: string
  setFullObjectSavedUsers: React.Dispatch<React.SetStateAction<User[]>>
  checksIdeal: { betesa: boolean; competences: boolean }
  processName: string
  textsNinebox?: any[]
}

const NineBoxCareerPlan: FC<INineBox> = ({
  typeNineBox,
  processId,
  checksIdeal,
  noData,
  setShowUsersModal,
  evaluatedUsers,
  setShowHistory,
  savedNineBox,
  validatedInputs,
  setRefreshSavedNineBox,
  historySelectedNineBox,
  clearHistorySelectedNineBox,
  sessionData,
  saveSession,
  setHistoryAddedUsers,
  triggerEffect,
  sessionValidatedInputs,
  setHistoryUsersPerformance,
  nameOnInput,
  setFullObjectSavedUsers,
  processName,
  textsNinebox,
}) => {
  const { t } = useTranslation()
  const [checks, setChecks] = useState({ betesa: checksIdeal.betesa, competences: checksIdeal.competences })
  const [showModal, setShowModal] = useState(false)
  const [saveInput, setSaveInput] = useState('')
  const [showIdealProfileModal, setShowIdealProfileModal] = useState(false)
  const [nineBoxData, setNineBoxData] = useState<INineBoxResponse>({} as INineBoxResponse)
  const [boxes, setBoxes] = useState<User9Box[][]>(Array.from({ length: 9 }, () => []))
  const [disabledCheckBetesa, setDisabledCheckBetesa] = useState(false)
  const [disabledCheckCompetences, setDisabledCheckCompetences] = useState(false)
  const [triggerApiCall, setTriggerApiCall] = useState(0)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (nameOnInput) {
      setSaveInput(nameOnInput)
    }
  }, [nameOnInput])

  const cleanSelectedHistory = useRef<any>(() => {})

  const { getError, getSuccess } = useNotification()

  cleanSelectedHistory.current = () => {
    setTimeout(() => {
      clearHistorySelectedNineBox()
    }, 4000)
  }

  const updateChecks = (data: any) => {
    let newBetesa = checks.betesa
    let newCompetences = checks.competences

    if (!data?.betesa_ideal_profile?.profile_values?.length) {
      newBetesa = false

      if (isInitialRender) {
        setDisabledCheckBetesa(true)
      }
    }
    if (!data?.competences_ideal_profile?.length) {
      newCompetences = false

      if (isInitialRender) {
        setDisabledCheckCompetences(true)
      }
    }

    if (newBetesa !== checks.betesa || newCompetences !== checks.competences) {
      setChecks((prevChecks) => ({
        ...prevChecks,
        betesa: newBetesa,
        competences: newCompetences,
      }))
    }
  }

  useEffect(() => {
    if (nineBoxData?.user_9box?.length) {
      setFullObjectSavedUsers(
        nineBoxData?.user_9box?.map((user) => {
          return { user_id: user.user_id, full_name: user.full_name, document: user.document, email: user.email }
        })
      )
    }
  }, [nineBoxData])

  useEffect(() => {
    if (processId) {
      setLoading(true)
      let body: INineBoxAnalytics

      if (historySelectedNineBox?.id || sessionData?.process_id) {
        setHistoryAddedUsers(historySelectedNineBox?.users || sessionData?.users)
        body = {
          process_id: Number(historySelectedNineBox?.process_id || sessionData?.process_id),
          is_betesa: historySelectedNineBox?.is_betesa || sessionData?.is_betesa,
          is_competences: historySelectedNineBox?.is_competences || sessionData?.is_competences,
          users: historySelectedNineBox?.users || sessionData?.users,
          career_plan: historySelectedNineBox?.career_plan || sessionData?.career_plan,
          potential_weight: historySelectedNineBox?.potential_weight || sessionData?.potential_weight,
          performance_weight: historySelectedNineBox?.performance_weight || sessionData?.performance_weight,
        }
      } else {
        body = {
          process_id: Number(processId),
          is_betesa: checks?.betesa,
          is_competences: checks?.competences,
          users: evaluatedUsers,
          career_plan: true,
          potential_weight: Number(validatedInputs?.potential) || Number(sessionValidatedInputs?.potential),
          performance_weight: Number(validatedInputs?.performance) || Number(sessionValidatedInputs?.performance),
        }
      }

      getNineBoxAnalytics(body).then(
        (res) => {
          if (res?.data) {
            setNineBoxData(res.data)
            saveSession(body)
            setHistoryUsersPerformance(res.data.user_9box)
            if (noData) {
              noData(false)
            }

            const newBoxes: User9Box[][] = Array.from({ length: 9 }, () => [])
            res?.data.user_9box?.forEach((user: User9Box) => {
              newBoxes[user.nine_box - 1].push(user)
            })

            setBoxes(newBoxes)
            updateChecks(res?.data)
            setIsInitialRender(false)
            setLoading(false)
            clearHistorySelectedNineBox()
          }
        },
        (error) => {
          console.error('Error buscando los 9box: ', error)
          getError('Ha ocurrido un error al cargar los 9box')
          setLoading(false)
        }
      )
    }
  }, [typeNineBox, processId, triggerApiCall, evaluatedUsers, triggerEffect])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaveInput(e.target.value)
  }

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target

    if (
      (name === 'betesa' && !checks.competences && !checked) ||
      (name === 'competences' && !checks.betesa && !checked)
    ) {
      return
    }

    setTriggerApiCall((prevState) => prevState + 1)

    setChecks((prevChecks) => ({
      ...prevChecks,
      [name]: checked,
    }))
  }

  const handleSave = () => {
    setShowModal(true)
  }

  const onSave = async () => {
    if (savedNineBox && savedNineBox.nine_boxes && savedNineBox.nine_boxes.length > 0) {
      const nineBoxes = savedNineBox.nine_boxes
      const inputValue = saveInput

      const matchedBox = nineBoxes.find((box) => box.name === inputValue)

      if (matchedBox) {
        const matchedId = matchedBox.id
        const body = {
          name: saveInput,
          is_betesa: checks?.betesa,
          is_competences: checks?.competences,
          career_plan: true,
          potential_weight: Number(
            validatedInputs.potential || sessionValidatedInputs?.potential || sessionData?.potential_weight
          ),
          performance_weight: Number(
            validatedInputs.performance || sessionValidatedInputs?.performance || sessionData?.performance_weight
          ),
          users: nineBoxData?.user_9box?.map((user) => user.user_id),
        }

        updateNineBox(matchedId, body).then(
          (res) => {
            setRefreshSavedNineBox((prev) => !prev)
            if (res.status === 'OK') {
              getSuccess('9box actualizado correctamente')
            }
          },
          (error) => {
            console.error('Error actualizando el 9box: ', error)
            getError('Ha ocurrido un error al actualizar el 9box')
          }
        )
        setShowModal(false)
        return
      }
    }

    saveNineBox({
      name: saveInput,
      process_id: Number(processId),
      is_betesa: checks?.betesa,
      is_competences: checks?.competences,
      career_plan: true,
      potential_weight: Number(
        validatedInputs.potential || sessionValidatedInputs?.potential || sessionData?.potential_weight
      ),
      performance_weight: Number(
        validatedInputs.performance || sessionValidatedInputs?.performance || sessionData?.performance_weight
      ),
      users: nineBoxData?.user_9box?.map((user) => user.user_id),
    }).then(
      (res) => {
        sessionStorage.setItem('nineBoxName', JSON.stringify(saveInput))
        setRefreshSavedNineBox((prev) => !prev)
        if (res.status === 'OK') {
          getSuccess('9box guardado correctamente')
        }
      },
      (error) => {
        console.error('Error guardando el 9box: ', error)
        getError('Ha ocurrido un error al guardar el 9box')
      }
    )
    setShowModal(false)
  }

  const handleClickIdealProfile = () => {
    setShowIdealProfileModal(true)
  }

  const handleClickHistory = () => {
    setShowHistory(true)
  }

  const toolTipMessage = (user: User9Box) => {
    return (
      <M.ToolTipContainer>
        {user?.betesa_adjustment_level ? (
          <M.ToolTipTitle>Nivel de ajuste Betesa: {user.betesa_adjustment_level} </M.ToolTipTitle>
        ) : null}
        {user?.competences_adjustment_level ? (
          <M.ToolTipTitle>
            Nivel de ajuste {t('global.common.skillText')}: {user.competences_adjustment_level}{' '}
          </M.ToolTipTitle>
        ) : null}
        {user?.performance ? <M.ToolTipTitle>Desempeño: {user.performance / 10}</M.ToolTipTitle> : null}
      </M.ToolTipContainer>
    )
  }

  const BlueTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2D749C',
      color: 'white',
      boxShadow: theme.shadows[2],
      fontSize: 14,
    },
  }))

  const handleUsersModal = () => {
    setShowUsersModal(true)
  }

  if (
    !nineBoxData?.betesa_ideal_profile?.profile_values?.length &&
    !nineBoxData?.competences_ideal_profile?.length &&
    !loading
  ) {
    if (noData) {
      noData(true)
    }
    return (
      <NoDataContainer style={{ marginTop: '-12px' }}>
        <NoDataSubContainer>
          <NoDataIcon src={iconInfo} />
          <TextContainer>
            <NoDataText>No hay un perfil ideal en este proceso</NoDataText>
          </TextContainer>
        </NoDataSubContainer>
      </NoDataContainer>
    )
  }

  if (nineBoxData?.user_9box?.length === 0 && !loading) {
    if (noData) {
      noData(true)
    }
    return (
      <NoDataContainer style={{ marginTop: '0' }}>
        <NoDataSubContainer>
          <NoDataIcon src={iconInfo} />
          <TextContainer>
            <NoDataText>Los usuarios no han contestado la prueba</NoDataText>
          </TextContainer>
        </NoDataSubContainer>
      </NoDataContainer>
    )
  }

  if (loading) {
    return <ModalLoader isLoading={loading} />
  }

  return (
    <Container>
      {nineBoxData && nineBoxData.user_9box?.length ? (
        <>
          <IdealProfileModal
            processName={processName}
            show={showIdealProfileModal}
            options={checks}
            data={nineBoxData}
            close={setShowIdealProfileModal}
          />
          <Modal styleContent={{ width: '530px', height: '240px' }} show={showModal} setShow={setShowModal}>
            <M.ModalSaveContainer>
              <M.ModalSaveText>Guardar 9Box</M.ModalSaveText>
              <TextField
                type="text"
                value={saveInput}
                onChange={handleInputChange}
                label="Nombre de 9Box"
                sx={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029', width: '510px' }}
              />
              {saveInput.length ? (
                <BottomButtonsContainer>
                  <BackButton
                    style={{ width: 'auto', padding: '24px 0px' }}
                    text="Cancelar"
                    type="button"
                    onClick={() => setShowModal(false)}
                  />
                  <StandardButton style={{ width: '15%', alignSelf: 'flex-end' }} onClick={onSave}>
                    Guardar
                  </StandardButton>
                </BottomButtonsContainer>
              ) : (
                <BottomButtonsContainer>
                  <BackButton
                    style={{ width: 'auto', padding: '24px 0px' }}
                    text="Cancelar"
                    type="button"
                    onClick={() => setShowModal(false)}
                  />
                  <ButtonDisabled style={{ alignSelf: 'flex-end' }}>Guardar</ButtonDisabled>
                </BottomButtonsContainer>
              )}
            </M.ModalSaveContainer>
          </Modal>
          <M.HeaderContainer>
            <M.Header>
              <Title>Selecciona las variables para ubicar al usuario evaluado en una de las cajas de 9BOX</Title>
              {checks.betesa || checks.competences ? (
                <StandardButton onClick={handleClickIdealProfile}>Ver perfil ideal</StandardButton>
              ) : (
                <ButtonDisabled>Ver perfil ideal</ButtonDisabled>
              )}
            </M.Header>
            <M.SubHeader>
              <M.ChecksContainer>
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    style={{
                      marginRight: '50px',
                    }}
                    control={
                      <Checkbox
                        checked={checks.betesa}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e)}
                        name="betesa"
                        disabled={disabledCheckBetesa}
                      />
                    }
                    label="Betesa"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checks.competences}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e)}
                        name="competences"
                        disabled={disabledCheckCompetences}
                      />
                    }
                    label={t('global.common.skillText')}
                  />
                </FormGroup>
              </M.ChecksContainer>
              <UsersButton onClick={handleUsersModal}>Administrar usuarios</UsersButton>
            </M.SubHeader>
          </M.HeaderContainer>
          <M.SubContainer>
            <M.ColumnGridSubContainer width="1%">
              <M.TextBold color="#C6016B" transform="rotate(-90deg)">
                Potencial
              </M.TextBold>
            </M.ColumnGridSubContainer>
            <M.ColumnGridSubContainer width="99%">
              <M.GridContainer>
                <M.Grid3X3>
                  <M.ItemGrid gridArea="top-left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <M.TextBold color="#2D749C">6. Talento confiable</M.TextBold>
                      <TooltipComponent
                        tooltipText={textsNinebox?.find((text) => text.box === 6)?.description_box || ''}
                        placement="top"
                        iconColor="#2D749C"
                      />
                    </div>
                    <M.BodyItemGrid>
                      {boxes[5].map((user) => (
                        <M.NameContainer key={user.user_id}>
                          <P style={{ marginRight: '20px' }}>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <BlueTooltip title={toolTipMessage(user)} placement="top">
                            <InfoIcon
                              style={{
                                color: '#2D749C',
                                width: '14px',
                                height: '14px',
                                cursor: 'pointer',
                                marginLeft: '-10px',
                              }}
                            />
                          </BlueTooltip>
                        </M.NameContainer>
                      ))}
                    </M.BodyItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="top-center">
                    <M.ItemGrid gridArea="middle-right" background="#FBF1F6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <M.TextBold margin="0px -10px 0px 0px" color="#2D749C">
                          2. Alto preparado
                        </M.TextBold>
                        <TooltipComponent
                          tooltipText={textsNinebox?.find((text) => text.box === 2)?.description_box || ''}
                          placement="top"
                          iconColor="#2D749C"
                        />
                      </div>
                      <M.BodyItemGrid>
                        {boxes[1].map((user) => (
                          <M.NameContainer key={user.user_id}>
                            <P style={{ marginRight: '20px' }}>
                              <Bullet />
                              {user.full_name}
                            </P>
                            <BlueTooltip title={toolTipMessage(user)} placement="top">
                              <InfoIcon
                                style={{
                                  color: '#2D749C',
                                  width: '14px',
                                  height: '14px',
                                  cursor: 'pointer',
                                  marginLeft: '-10px',
                                }}
                              />
                            </BlueTooltip>
                          </M.NameContainer>
                        ))}
                      </M.BodyItemGrid>
                    </M.ItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="top-right">
                    <M.ItemGrid gridArea="middle-right" background="#FBF1F6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <M.TextBold color="#2D749C">1. Talento Top</M.TextBold>
                        <TooltipComponent
                          tooltipText={textsNinebox?.find((text) => text.box === 1)?.description_box || ''}
                          placement="top"
                          iconColor="#2D749C"
                        />
                      </div>
                      <M.BodyItemGrid>
                        {boxes[0].map((user) => (
                          <M.NameContainer key={user.user_id}>
                            <P style={{ marginRight: '20px' }}>
                              <Bullet />
                              {user.full_name}
                            </P>
                            <BlueTooltip title={toolTipMessage(user)} placement="top">
                              <InfoIcon
                                style={{
                                  color: '#2D749C',
                                  width: '14px',
                                  height: '14px',
                                  cursor: 'pointer',
                                  marginLeft: '-10px',
                                }}
                              />
                            </BlueTooltip>
                          </M.NameContainer>
                        ))}
                      </M.BodyItemGrid>
                    </M.ItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="middle-left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <M.TextBold color="#2D749C">7. Talento promedio</M.TextBold>
                      <TooltipComponent
                        tooltipText={textsNinebox?.find((text) => text.box === 7)?.description_box || ''}
                        placement="top"
                        iconColor="#2D749C"
                      />
                    </div>
                    <M.BodyItemGrid>
                      {boxes[6].map((user) => (
                        <M.NameContainer key={user.user_id}>
                          <P style={{ marginRight: '20px' }}>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <BlueTooltip title={toolTipMessage(user)} placement="top">
                            <InfoIcon
                              style={{
                                color: '#2D749C',
                                width: '14px',
                                height: '14px',
                                cursor: 'pointer',
                                marginLeft: '-10px',
                              }}
                            />
                          </BlueTooltip>
                        </M.NameContainer>
                      ))}
                    </M.BodyItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="middle-center">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <M.TextBold color="#2D749C">5. Colaborador de equipo</M.TextBold>
                      <TooltipComponent
                        tooltipText={textsNinebox?.find((text) => text.box === 5)?.description_box || ''}
                        placement="top"
                        iconColor="#2D749C"
                      />
                    </div>
                    <M.BodyItemGrid>
                      {boxes[4].map((user) => (
                        <M.NameContainer key={user.user_id}>
                          <P style={{ marginRight: '20px' }}>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <BlueTooltip title={toolTipMessage(user)} placement="top">
                            <InfoIcon
                              style={{
                                color: '#2D749C',
                                width: '14px',
                                height: '14px',
                                cursor: 'pointer',
                                marginLeft: '-10px',
                              }}
                            />
                          </BlueTooltip>
                        </M.NameContainer>
                      ))}
                    </M.BodyItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="middle-right">
                    <M.ItemGrid gridArea="middle-right" background="#FBF1F6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <M.TextBold color="#2D749C">3. Líder técnico</M.TextBold>
                        <TooltipComponent
                          tooltipText={textsNinebox?.find((text) => text.box === 3)?.description_box || ''}
                          placement="top"
                          iconColor="#2D749C"
                        />
                      </div>
                      <M.BodyItemGrid>
                        {boxes[2].map((user) => (
                          <M.NameContainer key={user.user_id}>
                            <P style={{ marginRight: '20px' }}>
                              <Bullet />
                              {user.full_name}
                            </P>
                            <BlueTooltip title={toolTipMessage(user)} placement="top">
                              <InfoIcon
                                style={{
                                  color: '#2D749C',
                                  width: '14px',
                                  height: '14px',
                                  cursor: 'pointer',
                                  marginLeft: '-10px',
                                }}
                              />
                            </BlueTooltip>
                          </M.NameContainer>
                        ))}
                      </M.BodyItemGrid>
                    </M.ItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="bottom-left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <M.TextBold color="#2D749C">9. Talento Riesgo</M.TextBold>
                      <TooltipComponent
                        tooltipText={textsNinebox?.find((text) => text.box === 9)?.description_box || ''}
                        placement="top"
                        iconColor="#2D749C"
                      />
                    </div>
                    <M.BodyItemGrid>
                      {boxes[8].map((user) => (
                        <M.NameContainer key={user.user_id}>
                          <P style={{ marginRight: '20px' }}>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <BlueTooltip title={toolTipMessage(user)} placement="top">
                            <InfoIcon
                              style={{
                                color: '#2D749C',
                                width: '14px',
                                height: '14px',
                                cursor: 'pointer',
                                marginLeft: '-10px',
                              }}
                            />
                          </BlueTooltip>
                        </M.NameContainer>
                      ))}
                    </M.BodyItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="bottom-center">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <M.TextBold color="#2D749C">8. Talento inconsistente</M.TextBold>
                      <TooltipComponent
                        tooltipText={textsNinebox?.find((text) => text.box === 8)?.description_box || ''}
                        placement="top"
                        iconColor="#2D749C"
                      />
                    </div>
                    <M.BodyItemGrid>
                      {boxes[7].map((user) => (
                        <M.NameContainer key={user.user_id}>
                          <P style={{ marginRight: '20px' }}>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <BlueTooltip title={toolTipMessage(user)} placement="top">
                            <InfoIcon
                              style={{
                                color: '#2D749C',
                                width: '14px',
                                height: '14px',
                                cursor: 'pointer',
                                marginLeft: '-10px',
                              }}
                            />
                          </BlueTooltip>
                        </M.NameContainer>
                      ))}
                    </M.BodyItemGrid>
                  </M.ItemGrid>
                  <M.ItemGrid gridArea="bottom-right">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <M.TextBold color="#2D749C">4. Talento mal ubicado</M.TextBold>
                      <TooltipComponent
                        tooltipText={textsNinebox?.find((text) => text.box === 4)?.description_box || ''}
                        placement="top"
                        iconColor="#2D749C"
                      />
                    </div>
                    <M.BodyItemGrid>
                      {boxes[3].map((user) => (
                        <M.NameContainer key={user.user_id}>
                          <P style={{ marginRight: '20px' }}>
                            <Bullet />
                            {user.full_name}
                          </P>
                          <BlueTooltip title={toolTipMessage(user)} placement="top">
                            <InfoIcon
                              style={{
                                color: '#2D749C',
                                width: '14px',
                                height: '14px',
                                cursor: 'pointer',
                                marginLeft: '-10px',
                              }}
                            />
                          </BlueTooltip>
                        </M.NameContainer>
                      ))}
                    </M.BodyItemGrid>
                  </M.ItemGrid>
                </M.Grid3X3>
              </M.GridContainer>
              <M.TextBold margin="10px 0px 0px 0px" color="#C6016B">
                Desempeño
              </M.TextBold>
            </M.ColumnGridSubContainer>
          </M.SubContainer>
          <M.Footer>
            <UsersButton onClick={handleClickHistory}>Consultar histórico</UsersButton>
            <StandardButton onClick={handleSave}>Guardar</StandardButton>
          </M.Footer>
          {loading && <ModalLoader isLoading={loading} />}
        </>
      ) : null}
    </Container>
  )
}

export default NineBoxCareerPlan
