import { useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { MainLayout } from "views/layouts";
import { useNotification } from "lib/context/notification.context";
import { Container, Row, Divider, ModalBody } from './styled'
import Input, { TextArea } from 'views/components/UI/TextInput';
import { H2, H5, SubtitleBold } from 'views/components/UI/Text';
import Select from 'views/components/UI/Select';
import { StrokedButton, Button, BackButton } from "views/components/UI/Buttons";
import Modal from "views/components/UI/Modal";
import ModalLoader from "views/components/UI/ModalLoader/modal-loader";
import BehaviorItem from "./BehaviorItem";
import { userSelector } from "ducks/user";
import { createBetesaSkill, updateBetesaSkill } from "views/screens/Platform-administrator/services";
import { platformAdminRoutes } from "router/routes";
import { BRAIN_QUADRANTS } from "constants/global.constants";

interface FormState {
    id?: number;
    name: string;
    description: string;
    quadrant: number;
}

type Behavior = { id: number, description: string }

const HomologationCreateEditSkills = () => {
    const location = useLocation(); 
    const navigate = useNavigate();
    const { getSuccess, getError } = useNotification();
    const skillData = location.state?.skillData;
    const behaviorList = location.state?.skillData?.behavior || [];
    const { companyId } = useSelector(userSelector);

    const initialFormState: FormState = { description: '', quadrant: skillData?.quadrantId, name: skillData?.name || '' };

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<FormState>(initialFormState);
    const [showModal, setShowModal] = useState(false);
    const [behaviors, setBehaviors] = useState<string[]>([]);
    const [behaviorListEdit, setBehaviorListEdit] = useState<Behavior[]>(behaviorList);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm(prevForm => ({
            ...prevForm,
            [event.target.name]: event.target.value
        }));
    }, []);
 
    const handleSelectChange = useCallback((value: number) => {
        setForm(prevForm => ({
            ...prevForm,
            quadrant: value
        }));
    }, []);

    const handleAddBehavior = useCallback(() => {

        if (!form.description || form.description.trim().length < 3) {
            getError('Debes ingresar un comportamiento de competencia válido');
            return;
        }
        
        if (form.description) {
            const behaviorId = behaviorListEdit.length + 1;
            setForm({ description: '', quadrant: form.quadrant, name: form.name });
            if (skillData) {
                setBehaviorListEdit(behaviorsListEdit => [...behaviorsListEdit, { id: behaviorId, description: form.description }]);
            } else {
                setBehaviors(behaviorsItems => [...behaviorsItems, form.description]);
            }
            setShowModal(false);
        }

    }, [form.description, form.quadrant, form.name, skillData, behaviorListEdit, getError]);

    const handleDeleteBehavior = useCallback((index: number) => {
        if (skillData) {
            setBehaviorListEdit(behaviorsListEdit => behaviorsListEdit.filter((behavior) => behavior.id !== index));
        } else {
            setBehaviors(behaviorsItem => behaviorsItem.filter((_, i) => i !== index));
        }
    }, [skillData]);

    const handleEditBehavior = useCallback((index: number) => {
        if (skillData) {
            const behaviorItem = behaviorListEdit.find((behavior) => behavior.id === index);
            setForm({ ...form, description: behaviorItem?.description || '', id: behaviorItem?.id || 0 });
            setShowModal(true);
        } else {
            setForm({ ...form, description: behaviors[index] });
            handleDeleteBehavior(index);
            setShowModal(true);
        }
    }, [behaviors, form, handleDeleteBehavior, skillData, behaviorListEdit]);

    const handleConfirmEditBehavior = useCallback(() => {
        if (form.description) {
            if (skillData) {
                const behaviorItem = behaviorListEdit.find((behavior) => behavior.id === form.id);
                if (behaviorItem) (behaviorItem.description = form.description);
                setBehaviorListEdit(behaviorListEdit);
            } else {
                setBehaviors(behaviorsItems => [...behaviorsItems, form.description]);
            }
            setForm({ description: form.description, quadrant: form.quadrant, name: form.name });
            setShowModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.description]);

    const handleCreateSkill = useCallback(async () => {

        if (!form.name || !form.quadrant || !behaviors.length) {
            if (!form.name || form.name.trim().length < 3) {
                getError('Debes ingresar un nombre de competencia válido');
                return;
            }

            getError('Por favor ingrese todos los campos');
            return;
        }


        if (!loading) {
            const data = {
                name: form.name,
                company_id: companyId,
                quadrant_id: form.quadrant,
                behaviorsDescription: behaviors
            }
            setLoading(true);
              await createBetesaSkill(data).then(() => {
                setForm(initialFormState);
                setBehaviors([]);
                getSuccess('Competencia creada con éxito');
            }).catch(() => {
                getError('Ha ocurrido un error al crear la competencia de betesa');
            }).finally(() => {
       
                setLoading(false);
                navigate(platformAdminRoutes.HomologationSkills)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [behaviors, form.description, form.name, form.quadrant, companyId]);

    const handleUpdateSkill = useCallback(async () => {

        if (!form.name || form.name.trim().length < 3) {
            getError('Debes ingresar un nombre de competencia válido');
            return;
        }


        if (!loading) {
            const data = {
                id: skillData?.id,
                name: form.name,
                company_id: companyId,
                quadrant_id: form.quadrant,
                behaviorsDescription: behaviorListEdit.map((behavior) => behavior.description)
            }
            setLoading(true);
            await updateBetesaSkill(data).then((res) => {
                setForm(initialFormState);
                if (res.status === 'error') {
                    throw new Error('Ha ocurrido un error al actualizar la competencia de betesa');
                }
                setBehaviorListEdit([]);
                navigate(platformAdminRoutes.HomologationSkills)
                getSuccess('Competencia actualizada con éxito');
            }
            ).catch(() => {
                getError('Ha ocurrido un error al actualizar la competencia de betesa');
            }
            ).finally(() => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [behaviors, form.description, form.name, form.quadrant, companyId, behaviorListEdit, skillData]);

    return (
        <MainLayout>
            <Container>
                <Row >
                    <H2>{`${!skillData ? 'Nueva' : 'Editar'} competencia`}</H2>
                </Row>
                <Row margin="20px 0px" justify="space-between">
                    <Input
                        label="Nombre de la competencia"
                        name="name"
                        value={form.name}
                        onChange={handleChange}
                        type="text"
                        style={{width: '40%'}}
                    />
                    <Select width="220px" value={form.quadrant} label='Cuadrante cerebral' options={BRAIN_QUADRANTS} onChange={(e) => handleSelectChange(parseInt(e))} hideNeverChoice/>
                </Row>
                <Row justify="flex-end" margin="10px 0px 20px 0px">
                    <StrokedButton onClick={() => {
                        setForm({ description: '', quadrant: form.quadrant, name: form.name });
                        setShowModal(true);
                    }}>Agregar nuevo comportamiento</StrokedButton>
                </Row>
                <div>
                    <SubtitleBold>Comportamientos</SubtitleBold>
                    <Divider margin="10px 0px" />
                    {
                        !skillData && behaviors.map((description, index) => (
                            <BehaviorItem
                                key={index}
                                description={description}
                                onEdit={() => handleEditBehavior(index)}
                                onDelete={() => handleDeleteBehavior(index)}
                            />
                        ))
                    }
                    {
                        skillData && behaviorListEdit.map((behavior: any) => (
                            <BehaviorItem
                                key={behavior.id}
                                description={behavior.description}
                                onEdit={() => handleEditBehavior(behavior.id)}
                                onDelete={() => handleDeleteBehavior(behavior.id)}
                            />
                        ))
                    }
                </div>
                <Row justify="space-between" margin="40px 0px 20px 0px" style={{flex: '1'}}>
                     <BackButton onClick={() => navigate(platformAdminRoutes.HomologationSkills)}/>
                    {
                        !skillData ? (
                            <Button disabled={!form.name || !form.quadrant || !behaviors.length} type='submit' onClick={handleCreateSkill}>Guardar competencia</Button>
                        ) : (
                            <Button disabled={!behaviorListEdit.length || !form.name || !form.quadrant} type='submit' onClick={handleUpdateSkill}>Editar competencia</Button>
                        )
                    } 
                </Row>
            </Container>
            <Modal show={showModal} setShow={setShowModal} styleContent={{ width: '450px' }} >
                <ModalBody>
                    <H5 margin='0px 0px 10px 0px'>{!form.id ? 'Nuevo comportamiento' : 'Editar comportamiento'}</H5>
                    <TextArea style={{ width: '95%' }} value={form.description} onChange={handleChange} name='description' placeholder="Escribe aquí el comportamiento" />
                    <Row justify="flex-end" margin="20px 0px 0px 0px">
                        {
                            !skillData || !form.id ? (
                                <Button type="submit" onClick={handleAddBehavior}>Guardar</Button>
                            ) : (
                                <Button type="submit" onClick={handleConfirmEditBehavior}>Guardar cambios</Button>
                            )
                        }
                    </Row>
                </ModalBody>
            </Modal>
            <ModalLoader isLoading={loading} />
        </MainLayout>
    )
}

export default HomologationCreateEditSkills;
