export const BACK_PAGE = -1;

export const API_URL = `/company/subscription/summary/`

export const headers = [
    {
        key:'name',
        name: 'Nombre'
    },
    {
        key:'type',
        name: 'Tipo'
    },
    {
        key:'credits',
        name: 'Pruebas adquiridas'
    },
    {
        key:'tests_used',
        name: 'Pruebas usadas'
    },
    {
        key:'tests_available',
        name: 'Pruebas por usar'
    },
    {
        key:'start_date',
        name: 'Fecha de activación'
    },
    {
        key:'expiration_date',
        name: 'Fecha de vencimiento'
    },
    {
        key:'state',
        name: 'Estado'
    },
]
