import React from 'react'
import { Container, RowContainer, ContainerItemType, UnderlineItemType } from './styled'
import { P } from 'views/components/UI/Text/'

interface ItemOptionProps {
  name: string
  focused: string
  setSelected: (value: string) => void
  value: string
}

const ItemOption = (props: ItemOptionProps) => {
  const { name, focused, setSelected, value } = props

  return (
    <ContainerItemType
      onClick={() => {
        setSelected(value)
      }}
    >
      <P color={focused === value ? '#2D749C' : '#707070'} weight={600} paddingBottom={focused === value ? '0' : '7px'}>
        {name}
      </P>
      {focused === value && <UnderlineItemType />}
    </ContainerItemType>
  )
}

interface IProps {
  items: Array<{ name: string; value: string }>
  selected: string
  setSelected: (value: string) => void
}

//Main component
const HorizontalSelector = (props: IProps) => {
  const { items, selected, setSelected } = props

  return (
    <Container>
      <RowContainer justifyContent="center">
        {items.map((item, index) => {
          return (
            <ItemOption
              key={`item-${index}`}
              name={item.name}
              focused={selected}
              setSelected={setSelected}
              value={item.value}
            />
          )
        })}
      </RowContainer>
    </Container>
  )
}

export default HorizontalSelector
