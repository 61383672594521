import styled from 'styled-components';

export const HeadPercentiles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
    margin-bottom: 1%;
`;

export const BoldText = styled.p`
    margin-right: 8px;
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
    color: #303030;
`;

export const Text = styled.p`
    text-align: center;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    color: #303030;
`;

interface IContainerRow {
    justifyContent?: string;
    margin?: string;
}

export const ContainerRow = styled.div<IContainerRow>`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: ${props => props.margin ? props.margin : '4px 0px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
`;

export const ContainerPercentiles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    justify-self: center;
    align-items: center;
`;

export const ContainerPercentilesRowItems = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    /* margin: 4px 0px; */
`;

export const NumberValue = styled.span`
    font-style: normal;
    font-size: 22px;
    font-weight: 700;
    color: #303030;
`;

export const ContainerItemPercentiles = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
