import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'lib/redux'

const getPercentiles = (state: RootState) => state.percentiles.info
const getTestId = (state: RootState) => state.percentiles.test_id
const getEvaluatedUserId = (state: RootState) => state.percentiles.evaluated_user_id

export const percentilesSelector = createSelector(getPercentiles, (percentiles) => percentiles)

export const testIdSelector = createSelector(getTestId, (testId) => testId)

export const evaluatedUserIdSelector = createSelector(getEvaluatedUserId, (evaluatedUserId) => evaluatedUserId)
