import { FC, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Formik, Form } from 'formik'

import { Button } from 'views/components/UI/Buttons'
import { MainLayout } from 'views/layouts'
import { generalDataSelector } from 'ducks/auth'
import { STATUS_OK } from 'constants/global.constants'
import { putFamilyRolBasicInfo } from 'services/rolFamily'
import { useNotification } from 'lib/context/notification.context'
import { validationEditBasicInfoSchema } from '../../../schemas'
import Footer from 'views/components/UI/Footer/footer'
import BasicInfoForm from '../../../components/basic-info-form'
import BackButton from 'views/components/UI/BackButton/back-button'
import { BackButton as Back } from 'views/components/UI/Buttons'
import { BackContainer } from './styled'

interface EditBasicInfoProps {
  information?: any
  handleForm?: any
}

const EditBasicInfo: FC<EditBasicInfoProps> = ({ information, handleForm }) => {
  const { id } = useParams()
  const { data } = useSelector(generalDataSelector)
  const levels = data.level
  const { getError, getSuccess } = useNotification()
  const [basicInfo, setBasicInfo] = useState({
    name: '',
    level_id: '',
    description: '',
    categories: [],
    category: '',
    premium_betesa: false,
    competences: false,
  })

  const handleSubmit = async (values: any) => {
    values.level = levels.find((element: any) => element.id === Number(values?.level_id))?.name || ''

    const body = {
      name: values.name,
      level: values.level,
      description: values.description,
    }

    await putFamilyRolBasicInfo(body, id)
      .then((response: any) => {
        if (!response.ok && response.message) getError(response.message)
        if (response?.status === STATUS_OK) handleForm(0)

        getSuccess('Datos guardados con éxito')
      })
      .catch((error: any) => {
        getError(error.message)
      })
  }

  const handleCancelButtonClick = () => {
    handleForm(0)
  }

  const hasError = (errors: any) => {
    return Object.keys(errors).length > 0
  }

  useEffect(() => {
    setBasicInfo({
      categories:
        information.map((item: any) => {
          return {
            name: item.category,
            id: item.id,
            value: item.category === 'Betesa Premium' ? 'premium_betesa' : 'competences',
          }
        }) || [],
      name: information[0].name,
      level_id: levels.find((element: any) => element.name === information[0]?.level)?.id || 0,
      description: information[0].description,
      category: information.category,
      premium_betesa: information.some((item: any) => item.category === 'Betesa Premium'),
      competences: information.some((item: any) => item.category === 'Competencias'),
    })
  }, [information, levels])

  return (
    <MainLayout>
      <BackContainer>
        <Back onClick={handleCancelButtonClick} />
      </BackContainer>
      <Formik
        initialValues={basicInfo}
        validateOnChange
        validationSchema={validationEditBasicInfoSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleChange, setFieldValue, values, errors, touched }) => (
          <Form id="form-family-rol-settings-basic-info">
            <BasicInfoForm
              props={{
                handleChange,
                values,
                errors,
                touched,
                setFieldValue,
                category: basicInfo.categories,
                levels,
              }}
            />

            <Footer>
              <BackButton type="button" text="Cancelar" onClick={handleCancelButtonClick} />
              <Button type="submit" disabled={hasError(errors)}>
                Guardar
              </Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}

export default EditBasicInfo
