export type QuadrantType = typeof QUADRANTS[keyof typeof QUADRANTS];

export const QUADRANTS_NAMES = [
  'Frontal Izquierdo',
  'Frontal Derecho',
  'Basal Izquierdo',
  'Basal Derecho',
]

export const SCALE = 10;
export const MIN_DISTANCE = 10;
export const INIT_DEFAULT = 10;
export const MAX_DEFAULT = 30;
export const INIT_SCALE = 10;
export const MAX_SCALE = 110;

export const QUADRANTS = {
  FRONT_LEFT: 'Frontal Izquierdo',
  FRONT_RIGHT: 'Frontal Derecho',
  BASAL_LEFT: 'Basal Izquierdo',
  BASAL_RIGHT: 'Basal Derecho',
} as const;

export const INDEX_OPPOSITE: any = {
  0: 3,
  1: 2,
  2: 1,
  3: 0,
};

export const PRIORITY_OPPOSITE: any = {
  1: 4,
  2: 3,
  3: 2,
  4: 1,
};

export const OPPOSITE_QUADRANTS: Record<QuadrantType, QuadrantType>=  {
  [QUADRANTS.FRONT_LEFT]: QUADRANTS.BASAL_RIGHT,
  [QUADRANTS.FRONT_RIGHT]: QUADRANTS.BASAL_LEFT,
  [QUADRANTS.BASAL_LEFT]: QUADRANTS.FRONT_RIGHT,
  [QUADRANTS.BASAL_RIGHT]: QUADRANTS.FRONT_LEFT,
};

export const QUADRANT_VALUES = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
]
