export const selectOptions = [
    { value: 'Proceso 1', label: 'Proceso 1' },
    { value: 'Proceso 2', label: 'Proceso 2' },
    { value: 'Proceso 3', label: 'Proceso 3' },
    { value: 'Proceso 4', label: 'Proceso 4' },
]

 export  const HEADERS_DRAFT_PROCESSES = [
    { name: 'Nombre del proceso', key: 'name' }, 
    { name: 'Pruebas a aplicar', key: 'categories' }, 
    { name: 'Cantidad total de usuarios', key: 'count_users' }
]

export   const borradores = [
    { id: 1, name: 'Proceso 1', type: 'Betesa', cuantityUsers: '800' },
    { id: 2, name: 'Proceso 2', type: 'Betesa', cuantityUsers: '800' },
    { id: 3, name: 'Proceso 3', type: 'Betesa', cuantityUsers: '800' },
    { id: 4, name: 'Proceso 4', type: 'Betesa', cuantityUsers: '800' },
    { id: 5, name: 'Proceso 5', type: 'Betesa', cuantityUsers: '800' },
]

export const HEADERS_SENT_PROCESSES = [
    { name: 'Nombre del proceso', key: 'name' },
    { name: 'Pruebas a aplicar', key: 'categories' },
    { name: 'Cantidad de usuarios', key: 'count_users' },
    { name: 'Usuarios sin responder', key: 'unanswered_users' }
]


export const enviados = [
    { id: 1, name: 'Proceso 1', type: 'Betesa', cuantityUsers: '800', userWithOutResponse: '500' },
    { id: 2, name: 'Proceso 2', type: 'Betesa', cuantityUsers: '800', userWithOutResponse: '8' },
    { id: 3, name: 'Proceso 3', type: 'Talent 4 tech', cuantityUsers: '800', userWithOutResponse: '10' },
    { id: 4, name: 'Proceso 4', type: 'Betesa', cuantityUsers: '800', userWithOutResponse: '2' },
    { id: 5, name: 'Proceso 5', type: 'Betesa', cuantityUsers: '800', userWithOutResponse: '800' },
]
