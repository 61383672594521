import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import Tooltip from '@mui/material/Tooltip'
import TitleDiv from 'views/components/UI/TitleDiv'
import { P } from 'views/components/UI/Text'
import Radio from '@mui/material/Radio'
import Select from 'views/components/UI/Select'
import ChallengeModal from './Modals/Challenge'
import FitnessModal from './Modals/Fitness'
import { useNotification } from 'lib/context/notification.context'
import { handleFetchError, BACK_PAGE } from '../constants'
import { fetchGetQuadrantsTextAction, updateQuadrantsTextAction } from '../services/fetch-betesa-texts'
import {
  FormContainer,
  LanguageRow,
  LanguageRowItem,
  Paragraph,
  TextArea,
  SelectDiv,
  SaveButton,
  SelectTitle,
  Card,
  CardDescriptionSection,
  CardRow,
  Row,
} from './styled'
import { EMPTY_STRING } from 'constants/global.constants'
const Home: FC = () => {
  const navigate = useNavigate()
  const [openChallengeModal, setOpenChallengeModal] = useState(false)
  const [openFitnessModal, setOpenFitnessModal] = useState(false)

  const { getWarning, getSuccess } = useNotification()
  const [textTypeOptions, setTextTypeOptions] = useState([])
  const [selectedTextType, setSelectedTextType] = useState('')
  const [descriptionText, setDescriptionText] = useState('')
  const [idQuadrantsTextType, setIdQuadrantsTextType] = useState(0)
  const [selectedLenguage, setSelectedLenguage] = useState('Español')

  useEffect(() => {
    const fetchData = () => {
      fetchGetQuadrantsTextAction()
        .then((response) => {
          if (!response) {
            throw new Error('Error en la solicitud')
          }
          const textType = response.map((item: any) => ({
            value: item.name,
            label: item.name,
          }))
          setTextTypeOptions(textType)
        })
        .catch((error) => {
          handleFetchError(error)
        })
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (selectedTextType) {
      const fetchSelectedData = () => {
        fetchGetQuadrantsTextAction(selectedTextType)
          .then((response) => {
            if (!response || response.length === 0) {
              throw new Error('Error en la solicitud')
            }
            const [item] = response
            if (item) {
              setDescriptionText(item.description)
              setIdQuadrantsTextType(item.id)
            } else {
              setDescriptionText('')
              setIdQuadrantsTextType(0)
            }
          })
          .catch((error) => {
            handleFetchError(error)
          })
      }
      fetchSelectedData()
    }
  }, [selectedTextType])

  const handleLanguageChange = (language: string) => {
    setSelectedLenguage(language)
  }

  const handleUpdateGneral = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const body = {
      description: descriptionText,
    }
    if (body.description.trim() === EMPTY_STRING) {
      getWarning("Es obligatorio que el campo no este vacio ");
      return false;
    }
    updateQuadrantsTextAction(body, idQuadrantsTextType)
      .then(() => {
        getSuccess('Cuadrante actualizado correctamente')
        navigate(BACK_PAGE)
      })
      .catch((error) => {
        console.error(error)
        getWarning('Error al actualizar el cuadrante')
      })
  }

  const handleOpenChallengeModal = (id: number) => {
    if (selectedTextType) {
      setOpenChallengeModal(!openChallengeModal)
      setOpenFitnessModal(false)
      setIdQuadrantsTextType(id)
    }
  }
  const handleOpenFitnessModal = (id: number) => {
    if (selectedTextType) {
      setOpenFitnessModal(!openFitnessModal)
      setOpenChallengeModal(false)
      setIdQuadrantsTextType(id)
    }
  }

  const handleTextTypeChange = (selectedOption: any) => {
    if (selectedOption === 0) {
      setDescriptionText('')
      setSelectedTextType('')
    } else {
      setSelectedTextType(selectedOption)
    }
  }

  return (
    <MainLayout>
      <form onSubmit={handleUpdateGneral}>
        <TitleDiv>
          <h2 style={{ fontSize: 32, marginBottom: 10 }}>Texto por cuadrante</h2>
        </TitleDiv>
        <FormContainer> 
          <LanguageRow>
            <LanguageRowItem>
              <Paragraph>Idioma del texto</Paragraph>
            </LanguageRowItem>
            <LanguageRowItem>
              <Radio
                checked={selectedLenguage === 'Español'}
                disabled={true}
                onChange={() => handleLanguageChange('Español')}
              />
              <Paragraph>Español</Paragraph>
            </LanguageRowItem>
            {/* <LanguageRowItem>
              <Radio
                checked={selectedLenguage === 'Inglés'}
                disabled={true}
                onChange={() => handleLanguageChange('Inglés')}
              />
              <Paragraph>Inglés</Paragraph>
            </LanguageRowItem> */}
          </LanguageRow>
          <SelectDiv>
            <SelectTitle style={{marginBottom: '1rem' }}> Características del cuadrante dominante</SelectTitle>
            <Select
              style={{ zIndex: 1 }}
              label="Tipo de texto"
              hideNeverChoice={true}
              options={textTypeOptions}
              value={selectedTextType || ''}
              onChange={handleTextTypeChange}
            />
          </SelectDiv>
          <TextArea
            placeholder="Descripcion"
            value={descriptionText}
            onChange={(e) => setDescriptionText(e.target.value)}
          />
          <CardRow>
            <Tooltip title="Presiona doble clic para abrir">
              <Card
                onClick={() => {
                  handleOpenFitnessModal(idQuadrantsTextType)
                }}
              >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20}}>Aptitudes de cuadrante</span>
                </CardDescriptionSection>
              </Card>
            </Tooltip>
            <Tooltip title="Presiona doble clic para abrir">
              <Card
                onClick={() => {
                  handleOpenChallengeModal(idQuadrantsTextType)
                }}
              >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Retos del cuadrante</span>
                </CardDescriptionSection>
              </Card>
            </Tooltip>
          </CardRow>
          <Row>
            <P
              onClick={() => {
                navigate(-1)
              }}
              style={{ color: '#2D749C', fontSize: 14, fontWeight: 700, cursor: 'pointer' }}
            >
              Cancelar
            </P>
            <SaveButton
              type="submit"
              disabled={!selectedTextType || !descriptionText}
              descriptionText={descriptionText}
            >
              Guardar
            </SaveButton>
          </Row>
        </FormContainer>
        <FitnessModal openFitness={openFitnessModal} id={idQuadrantsTextType} />
        <ChallengeModal openChallenge={openChallengeModal} id={idQuadrantsTextType} />
      </form>
    </MainLayout>
  )
}
export default Home
