import styled from 'styled-components'

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`

export const TextInterpretation = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700; 
  color: #C6016B;
  cursor: pointer;
  margin-right: 10px;
`;
