import styled from 'styled-components'

export const HeadDiv = styled.div`
  display:flex; 
  flex-direction:row; 
  justify-content:space-between; 
  margin-bottom:0px;
  margin-top:50px; 
  align-items: center;
`

const TitleDiv = ({children}:any): JSX.Element => {
  return (
        <HeadDiv>
            {children}
          </HeadDiv>
  )}
export default TitleDiv

