import styled from "styled-components";

interface IContainerItem {
  showDivider?: boolean
}

export const ContainerItem = styled.div<IContainerItem>`
   /* border-right: ${({ showDivider }) => showDivider ? '1px solid #E5E5E5' : 'none'}; */
   border-left: ${({ showDivider }) => showDivider ? '1px solid #E5E5E5' : 'none'};
   padding: 0px 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
`
