import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js'
import { Radar } from 'react-chartjs-2'
import { Score } from 'views/screens/Platform-administrator/Analytics/analytics'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

interface IProps {
  userResultData: Score[]
  userResultLabels: string[]
  idealProfileData?: number[]
  idealProfileLabels?: string[]
  labelResult?: string
  userScoreResult?: number[]
}

interface IDataset {
  label: string
  data: number[]
  backgroundColor: string
  borderColor: string
  borderWidth: number
}

export default function RadarChart(props: IProps) {
  const { userResultData, idealProfileData, userResultLabels, labelResult, userScoreResult } = props

  const labels = userResultLabels.map((item) => item)

  const averageUserData = userResultData.map((scoreObj) => scoreObj.mean)

  const dataSets: IDataset[] = [
    {
      label: labelResult || ' Promedio',
      data: userScoreResult ? userScoreResult : averageUserData,
      backgroundColor: 'rgba(54, 162, 235, 0.2)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
  ]



  if (idealProfileData) {
    dataSets.push({
      label: ' Perfil ideal',
      data: idealProfileData,
      backgroundColor: 'rgba(246, 90, 249, 0.325)',
      borderColor: '#eb36df',
      borderWidth: 1,
    })
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scale:{
      min: 5,
      max: 100,
    },
    scales: {
      r: {
        angleLines: {},
        ticks: {
          beginAtZero: true,
          min: 5,
          max: 100,
          stepSize: 10,
          font: { size: 12 },
        },
        pointLabels: {
          font: { size: 12 },
          maxRotation: 0,
          autoSkip: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: { font: { size: 14 } },
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            return context[0].chart.data.labels[context[0].dataIndex]
          },
          label: (context: any) => {
            return `${context.dataset.label}: ${Number(context.parsed.r.toFixed(2))}`
          },
          afterBody: (context: any) => {
            const afterBody: any = []
            if (context[0].dataset.label === ' Promedio') {
              userResultData[context[0].dataIndex].users.forEach((user) => {
                afterBody.push(`${user.full_name}: ${user.competence_score}`)
              })
            }
            return afterBody
          },
        },
      },
    },
  }

  const dataChart = { labels, datasets: dataSets }
  return <Radar data={dataChart} options={options} width={600} height={600}  />
}
