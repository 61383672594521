import { createSelector } from '@reduxjs/toolkit'

// TODO: import Store type and add reducer type in Store interface 
const getCreateprocess= (state: any) => state.createProcess

export const rootProcessSelector =  createSelector(getCreateprocess, (createProcess) => createProcess)
export const loadingCreateProcessSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.loading)
export const errorSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.error)
export const stepOneSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepOne)
export const stepTwoSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepTwo)
export const stepThreeSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepThree)
export const stepFourSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFour)
export const stepFiveSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFive)
export const getUserListAdded =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFive.userListAdded)
export const getUserListFailed =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFive.userListFailed)
export const getBetesaSkillIds =  createSelector(getCreateprocess, (createProcess) => createProcess.process.idsBetesaSkillsSelected)
export const getStepSuccess =  createSelector(getCreateprocess, (createProcess) => createProcess.stepsSuccess)
export const getEmailTemplateStepFive =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFive.emailTemplate)
export const getLinkExcelUsersFailed =  createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFive.linkDownloadExcelUsersFailed)
export const getTestTypes = createSelector(getCreateprocess, (createProcess) => createProcess.process.stepFive.testTypes)
export const stepsErrorsSelector =  createSelector(getCreateprocess, (createProcess) => createProcess.stepsErrors)

 