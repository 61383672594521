import { FC, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PublicRouter from 'router/public/index'
import PrivateRouter from 'router/private/index'
import UseAuthentication from 'hooks/useAuthentication'
import ModalLoader from 'views/components/UI/ModalLoader'
import i18n from 'lib/i18n'
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const paramValue = urlParams.get('lang');
const Router: FC = () => {
  
  const isLoading = UseAuthentication()

  const { isAuth, role } = useSelector((state: any) => state.auth)

  const isAuthenticated = isAuth && role !== null && role !== undefined

  useEffect(() => {
    if (paramValue) {
      if (paramValue === 'es') {
        i18n.changeLanguage('es')
      } else {
        i18n.changeLanguage('en')
      }
    }
  } , [])

  return isLoading ? (
    <ModalLoader isLoading={isLoading} />
  ) : (
    <BrowserRouter>{isAuthenticated ? <PrivateRouter /> : <PublicRouter />}</BrowserRouter>
  )
}
export default Router
