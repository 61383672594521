/* eslint-disable @typescript-eslint/ban-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSagasTrigger } from 'lib/redux/sagas/trigger'
import { IGeneralData } from 'types/common'


export type InitialState = typeof initialState

type IRoleType = 'special_admin' | 'super_admin' | 'platform_admin' | 'super_platform_admin' | 'user'

interface IState {
  generalData: IGeneralData | {}
  role: IRoleType | ''
  isAuth: boolean
  loading: boolean
  error: boolean
}

export const initialState: IState = {
  generalData: {},
  role: '',
  isAuth: false,
  loading: false,
  error: false,
}


type SetAuth = PayloadAction<{ role: IRoleType | ''; isAuth: boolean }>

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setGeneralData(state, action: PayloadAction<IGeneralData | {}>) {
      state.generalData = action.payload
    },
    setAuth(state, action: SetAuth) {
      state.role = action.payload.role
      state.isAuth = action.payload.isAuth
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      action.payload.isAuth === false && window.location.pathname !== '/register' && window.location.pathname !== '/forgot'  ? window.location.href = '/' : null // for manage redirect
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    logout(state) {
      state.isAuth = false
      state = initialState
    },
  },
})



export const { setAuth, setLoading, setError, logout, setGeneralData } = authSlice.actions

export const authSagasTrigger = createSagasTrigger<{ role: IRoleType; isAuth: boolean }>('auth_sagas')

export const authReducer = authSlice.reducer




