import { FC, useCallback, useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate, Link } from 'react-router-dom'

import { ContainerActionItem, UpdateTag, ViewTag } from './styled'
import { StrokedButton } from 'views/components/UI/Buttons'
import EditIcon from 'assets/icons/EditCoin'
import TitleDiv from 'views/components/UI/TitleDiv'
import ActionDetailModal from './action-detail-modal/action-detail-modal'
import fetchAllActionsSkills from 'services/developmentActions/fetchAllActions/fetchAllActionsSkills'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { HEADERS } from './constants'
import { specialAdminRoutes } from 'router/routes'
import { useNotification } from 'lib/context/notification.context'

interface DevelopmentPlansProps {
  id?: number
}

const DevelopmentPlans: FC<DevelopmentPlansProps> = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [actions, setActions] = useState([])
  const [actionId, setActionId] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const navigate = useNavigate()

  const getAllDevelopmentActions = useCallback(
    (page = 1, limit = 10) => {
      setCurrentPage(page)
      fetchAllActionsSkills(limit, page, { search: searchTerm }).then(({ development_plans, total_pages }) => {
        setActions(development_plans)
        setTotalPages(total_pages)
      })
    },
    [searchTerm]
  )

  useEffect(() => {
    getAllDevelopmentActions()
  }, [getAllDevelopmentActions])

  const ActionComponent = (props: any) => {
    const { getError } = useNotification()
    const { id } = props

    const setActionAndOpenModal = () => {
      if (id) {
        setActionId(id)
        setOpenModal(true)
      } else {
        getError('No se pudo obtener el id de la acción de desarrollo')
      }
    }

    return (
      <ContainerActionItem>
        <ViewTag onClick={setActionAndOpenModal}>Ver acciones de desarrollo</ViewTag>
        <Link to={id ? `/modules/skills/actions/update?id=${id}` : '#'}>
          <UpdateTag>
            <EditIcon /> Editar acciones del plan de desarrollo
          </UpdateTag>
        </Link>
      </ContainerActionItem>
    )
  }

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Acciones de desarrollo por competencia</h2>
        <StrokedButton
          type="button"
          onClick={() => {
            navigate(specialAdminRoutes.NewActionSkill)
          }}
        >
          Nueva acción
        </StrokedButton>
      </TitleDiv>

      <BluesiteTableComponent
        data={actions}
        headers={HEADERS}
        searchBar={true}
        ActionComponent={ActionComponent}
        searchLabel=""
        totalPages={totalPages}
        handlePagination={getAllDevelopmentActions}
        currentPage={currentPage}
        onSearch={(value: string) => setSearchTerm(value as any)}
      />
      {actionId && <ActionDetailModal openModal={openModal} setOpenModal={setOpenModal} actionId={actionId} />}
    </MainLayout>
  )
}

export default DevelopmentPlans
