import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {InterpretationButton} from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { Container } from './styled'
import { MainLayout } from 'views/layouts'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import getAllUninterpretedBetesas from 'services/common/betesa/getBetesaUninterpreted'
import { ACTIONS, HEADER, SEARCH_LABEL } from './constants'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'

type UninterpretedBetesas = {
    user_name: string
    company_name: string
    betesa_result_id: number
}

const BetesaInterpretations: FC = () => {
    const { token } = useSelector((state: any) => state.user)
    const [betesas, setBetesas] = useState<UninterpretedBetesas[] | []>([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [searchParam, setSearchParam] = useState('')

useEffect(() => {
    if (searchParam.length !== 0) return

    setIsLoading(true)
    getAllUninterpretedBetesas(token, page).then((res) => {
        setBetesas(res.uninterpretedBetesas)
        setTotalPages(res.total_pages)
        setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [token, page, searchParam])

const handleSearch = (search: string) => {
    setSearchParam(search)
    if (search.length === 0) return

    getAllUninterpretedBetesas(token, page, search).then((res) => {
        setBetesas(res.uninterpretedBetesas)
        setTotalPages(res.total_pages)
    })
}

if (isLoading) {
    return <ModalLoader isLoading={isLoading} />
}

return (
    <MainLayout>
        <Container>
            <div
                style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 35,
                marginTop: 50,
                alignItems: 'baseline',
            }}
            >
            <h2 style={{ fontSize: 32 }}>Reinterpretación de pruebas betesa</h2>
            </div>
            <BluesiteTableComponent
                ActionComponent={InterpretationButton}
                data={betesas}
                headers={HEADER}
                actions={ACTIONS}
                searchBar={true}
                searchLabel={SEARCH_LABEL}
                currentPage={page}
                handlePagination={setPage}
                totalPages={totalPages}
                fetchSearchData={handleSearch}
                onSearch={(e) => handleSearch(e)}
            />
        </Container>
    </MainLayout>
  )
}

export default BetesaInterpretations
