import axios from 'axios'




/**
 * @description service to restore password
 * @param password 
 */

interface IChangePassword {
    password: string,
    token: string
}

/**
 * @description service to restore password
 * @param password: String
 * @param token: String
 * @returns the response of the request
 */
const changePassword = async ({password, token}: IChangePassword) => {

  const apiUrl = `${process.env.REACT_APP_MAIN_API}/user/new-password`

  // config put request
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*',
      'Authorization': `Bearer ${token}`
    },
  }

    // body post request
    const body = {
        "new_password": password,
    }

    // post request
    const response = await axios.put(apiUrl, body, config)
    console.info('response de changePassword', response)

    // return response
    return response
}

export default changePassword
