import React, { FC } from 'react'
import { CardTitleWithColor, CardInfoWithBorderTitle, StressorText, SelfPerceptionsWrapper } from './styled'
import { H5 } from 'views/components/UI/Text'

interface StressorsDetailProps {
  selfPerceptionsItems: string[]
}

const SelfPerceptions: FC<StressorsDetailProps> = ({ selfPerceptionsItems }) => {
  return (
    <CardInfoWithBorderTitle>
      <CardTitleWithColor>
        <H5>Autopercepción</H5>
      </CardTitleWithColor>

      <SelfPerceptionsWrapper>
        <StressorText>{selfPerceptionsItems.join(', ')}</StressorText>
      </SelfPerceptionsWrapper>
    </CardInfoWithBorderTitle>
  )
}

export default SelfPerceptions
