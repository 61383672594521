import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import HorizontalSelector from 'views/components/Selectors/HorizontalSelector'
import BrainMap from 'views/components/Graphics/BrainMap'
import TeamMapGrid from '../../../../containers/Grids/TeamMapGrid'
import { Container, StandardButton, Title, UsersButtonContainer } from './styled'
import { useNotification } from 'lib/context/notification.context'
import iconInfo from '../../../../components/assets/iconInfo.png'
import {
  NoDataContainer,
  NoDataIcon,
  NoDataSubContainer,
  NoDataText,
  TextContainer,
} from 'views/screens/Platform-administrator/Analytics/styled'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { MainLayout } from 'views/layouts'
import BrainGraphicContainer from 'views/containers/TeamMap/BrainGraphicContainer'
import { BetesaResultsResponse } from 'views/containers/TeamMap/store/entities'
import { useLocation, useNavigate } from 'react-router-dom'
import { OPTIONS_DATA, optionsMap } from '../Constants/Constants'
import { platformAdminRoutes } from 'router/routes'
import { BrainResultsResponse, QuadrantResults } from '../Entities'
import { useTeamMap } from '../Hooks/useTeamMap'

export interface TeamMapProps {
  betesaMapId?: string
  usersByQuadrant?: QuadrantResults
  talents?: boolean
}

const TeamMapDevelopment: FC<TeamMapProps> = () => {
  const { getError } = useNotification()
  const location = useLocation()
  const navigate = useNavigate()
  const { mapName, teamMapId: betesaMapId } = location.state
  const { getTeamMap } = useTeamMap()
  const [selectedOption, setSelectedOption] = useState<string>('1')
  const [betesaResults, setBetesaResults] = useState<BetesaResultsResponse>({} as BetesaResultsResponse)
  const [page, setPage] = useState<number>(1)
  const [usersQuadrants, setUsersQuadrants] = useState<QuadrantResults>({} as QuadrantResults)
  const [talentQuadrants, setTalentQuadrants] = useState<QuadrantResults>({} as QuadrantResults)
  const [planQuadrants, setPlanQuadrants] = useState<QuadrantResults>({} as QuadrantResults)
  const [brainResponse, setBrainResponse] = useState<BrainResultsResponse>({} as BrainResultsResponse)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)

    getTeamMap(betesaMapId)
      .then((data: any) => {
        setBetesaResults({ users: data } as BetesaResultsResponse)
        const usersFIDomination = data.filter((user: any) => user.domination === 'FI')
        const usersBIDomination = data.filter((user: any) => user.domination === 'BI')
        const usersBDDomination = data.filter((user: any) => user.domination === 'BD')
        const usersFDDomination = data.filter((user: any) => user.domination === 'FD')

        const usersFIplan = data.filter((user: any) => user.quadrant_develop === 'FI')
        const usersBIplan = data.filter((user: any) => user.quadrant_develop === 'BI')
        const usersBDplan = data.filter((user: any) => user.quadrant_develop === 'BD')
        const usersFDplan = data.filter((user: any) => user.quadrant_develop === 'FD')

        const usersFItalent = data.filter((user: any) => user.FI >= 80)
        const usersBITalent = data.filter((user: any) => user.BI >= 80)
        const usersBDTalent = data.filter((user: any) => user.BD >= 80)
        const usersFDTalent = data.filter((user: any) => user.FD >= 80)

        setUsersQuadrants({
          fi: usersFIDomination,
          bi: usersBIDomination,
          bd: usersBDDomination,
          fd: usersFDDomination,
        })
        setTalentQuadrants({
          fi: usersFItalent,
          bi: usersBITalent,
          bd: usersBDTalent,
          fd: usersFDTalent,
        })
        setPlanQuadrants({
          fi: usersFIplan,
          bi: usersBIplan,
          bd: usersBDplan,
          fd: usersFDplan,
        })

        const calculateAverage = (users: any[], key: string) => {
          const total = users.reduce((acc, user) => Number(acc) + Number(user[key]), 0);
          return users.length ? total / users.length : 0;
        };

        const averageFI = Math.floor(calculateAverage(data, 'FI'));
        const averageBI = Math.floor(calculateAverage(data, 'BI'));
        const averageBD = Math.floor(calculateAverage(data, 'BD'));
        const averageFD = Math.floor(calculateAverage(data, 'FD'));

        setBrainResponse({
          FI: averageFI,
          BI: averageBI,
          BD: averageBD,
          FD: averageFD,
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        getError('Ha ocurrido un error al cargar los usuarios')
      })
  }, [betesaMapId, getError, page, getTeamMap])

  useLayoutEffect(() => {
    setLoading(true)
  }, [selectedOption])

  const handleManageUsers = () => {
    navigate(platformAdminRoutes.TeamDevelopmentUsers, { state: { manageUsers: betesaResults, mapName, betesaMapId } })
  }

  return (
    <MainLayout>
      <Container>
        <Title>Mapa - {mapName}</Title>
        <UsersButtonContainer>
          <StandardButton onClick={handleManageUsers}>Administrar usuarios</StandardButton>
        </UsersButtonContainer>
        <HorizontalSelector items={optionsMap} setSelected={() => void 0} selected="1" />

        {Object.keys(betesaResults).length > 0 ? (
          <div>
            <HorizontalSelector items={OPTIONS_DATA} selected={selectedOption} setSelected={setSelectedOption} />
            {selectedOption === '1' && <TeamMapGrid results={betesaResults} handlePagination={setPage} />}
            {selectedOption === '2' && <BrainMap usersByQuadrant={usersQuadrants} />}
            {selectedOption === '3' && <BrainMap usersByQuadrant={talentQuadrants} talents />}
            {selectedOption === '4' && (
              <div
                style={{
                  marginTop: '20px',
                  width: '100%',
                }}
              >
                <div>
                  {Object.keys(brainResponse).length > 0 ? (
                    <BrainGraphicContainer
                      FI={brainResponse.FI}
                      FD={brainResponse.FD}
                      BD={brainResponse.BD}
                      BI={brainResponse.BI}
                    />
                  ) : (
                    <NoDataContainer>
                      <NoDataSubContainer>
                        <NoDataIcon src={iconInfo} />
                        <TextContainer>
                          <NoDataText>Error al cargar los datos del perfil ideal</NoDataText>
                        </TextContainer>
                      </NoDataSubContainer>
                    </NoDataContainer>
                  )}
                </div>
              </div>
            )}
            {selectedOption === '5' && <BrainMap usersByQuadrant={planQuadrants} />}
          </div>
        ) : betesaMapId ? (
          <NoDataContainer>
            <NoDataSubContainer>
              <NoDataIcon src={iconInfo} />
              <TextContainer>
                <NoDataText>No hay datos de Betesa en este proceso</NoDataText>
              </TextContainer>
            </NoDataSubContainer>
          </NoDataContainer>
        ) : !loading ? (
          <NoDataContainer>
            <NoDataSubContainer>
              <NoDataIcon src={iconInfo} />
              <TextContainer>
                <NoDataText>Los usuarios no han contestado la prueba</NoDataText>
              </TextContainer>
            </NoDataSubContainer>
          </NoDataContainer>
        ) : (
          <ModalLoader isLoading={loading} />
        )}
      </Container>
    </MainLayout>
  )
}

export default TeamMapDevelopment
