import { useState, useEffect, useRef } from 'react'
import jsPDF from 'jspdf'
import DownloadIcon from 'assets/icons/DownloadIcon'
import DevelopmentPlanSchema from 'views/components/report/components/development-plan'
import { fetchFeedBack } from 'views/screens/Evaluated-User/services/fetch-development-plan-betesa'
import { useNotification } from 'lib/context/notification.context'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DownloadContainer, TextDownload } from './styled'
const DownloadPlanCard = ({ skillParam, typeTest, processIdParam, userName }: any) => {
  const { t } = useTranslation()
  const { getSuccess } = useNotification()
  const [data, setData] = useState<any>()
  const developmentPlanTemplateRef = useRef<HTMLDivElement>(null)
  const userData = useSelector((state: any) => state.user)

  const nameSelection = userName ? userName : userData.userName

  useEffect(() => {
    if (skillParam) {
      const getSkillsData = async () => {
        try {
          const response = await fetchFeedBack(skillParam)
          setData(response.data.data.development_plan)
        } catch (error) {
          console.error(error)
        }
      }
      getSkillsData()
    }
  }, [skillParam])

  const generatePDF = async () => {
    return new Promise((resolve, reject) => {
      const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: [595, 842],
        compress: true,
        precision: 2,
      })
      doc.html(developmentPlanTemplateRef.current !== null ? developmentPlanTemplateRef.current : '', {
        async callback(pdfDoc: any) {
          const numberOfPages = pdfDoc.internal.getNumberOfPages()
          if (numberOfPages > 1) {
            pdfDoc.deletePage(numberOfPages)
          }

          try {
            await pdfDoc.save(
              `PD__${typeTest === 'betesa' ? 'Betesa' : t('global.common.skillText')}_${nameSelection.replace(
                / /g,
                '_'
              )}`
            )
            getSuccess(t('global.notifications.planGenerated'))
          } catch (error) {
            reject(error)
          }
        },
      })
    })
  }

  return (
    <>
      <DownloadContainer>
        <DownloadIcon />
        <TextDownload onClick={generatePDF}>Descargar plan de desarrollo</TextDownload>
      </DownloadContainer>

      {data && (
        <div style={{ visibility: 'hidden', width: '595px', alignSelf: 'center', position: 'absolute', zIndex: '-1' }}>
          <div ref={developmentPlanTemplateRef}>
            <DevelopmentPlanSchema developmentPlan={data} type={typeTest} processId={processIdParam} />
          </div>
        </div>
      )}
    </>
  )
}

export default DownloadPlanCard
