import { FC } from 'react'

interface LogoShortBluesiteProps {
  style?: React.CSSProperties
}

const LogoShortBluesite: FC<LogoShortBluesiteProps> = ({ style }) => (
  <div style={{ height: '30px', ...style }}>
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 30V-5.72205e-06H9.43904C12.3005 -5.72205e-06 14.5557 0.624239 16.2046 1.87273C17.8536 3.12119 18.678 5.00604 18.678 7.52726C18.678 10.5818 17.4413 12.7636 14.9679 14.0727C17.0291 14.4121 18.6053 15.2121 19.6965 16.4727C20.7877 17.7333 21.3333 19.3879 21.3333 21.4364C21.3333 23.2182 20.9272 24.7515 20.1148 26.0364C19.3146 27.3091 18.1749 28.2909 16.6957 28.9818C15.2286 29.6606 13.4947 30 11.4942 30H0ZM7.02017 11.6545H7.74766C9.09351 11.6545 10.118 11.3818 10.8213 10.8363C11.5366 10.2909 11.8943 9.50303 11.8943 8.47273C11.8943 7.4424 11.5487 6.6727 10.8576 6.16361C10.1786 5.65452 9.14199 5.39998 7.74766 5.39998H7.02017V11.6545ZM7.02017 24.5818H9.02075C10.6818 24.5818 11.9185 24.303 12.7309 23.7454C13.5433 23.1879 13.9494 22.3454 13.9494 21.2182C13.9494 20.0667 13.5311 19.1939 12.6945 18.6C11.8579 17.9939 10.6333 17.6909 9.02075 17.6909H7.02017V24.5818Z"
        fill="#2D749C"
      />
      <circle cx="25.6673" cy="25.7337" r="4.33333" fill="#C6016B" />
    </svg>
  </div>
)

export default LogoShortBluesite
