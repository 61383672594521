import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    overflow: hidden;
    max-height: 100vh;
`


export const MasterSquare = styled.div`
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 420px;
    height: 420px;
    border-radius: 50%;
    overflow: hidden;
`

interface SquareProps {
    color: string;
}

export const SquareRadius = styled.div<SquareProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 49.5%;
    height: 49.5%;
    background-color: ${props => props.color};
    margin-bottom: 4px;
    opacity: 0.8;
    // shadow
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;


    // hover
    &:hover {
        cursor: pointer;
        transform: scale(1.015);
    }
`;

export const NameQuadrantsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 800px;
    flex-wrap: wrap;
    position: absolute;
    left: 36%;
`
