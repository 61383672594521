import React from 'react'
import styled from 'styled-components'
import { BackButtonContainer, TextButton } from './styled'
import { color, position, space, layout, LayoutProps, SpaceProps, ColorProps, PositionProps } from 'styled-system'
import { Link } from 'react-router-dom'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'

export type BlockProps = LayoutProps & SpaceProps & ColorProps & PositionProps

interface IButtonProps {
  background?: string
  color?: string
  width?: string
  height?: string
  direction?: string
  justify?: string
  align?: string
  bgColor?: string
  padding?: string
  margin?: string
  border?: string
  borderRadius?: string
}

const StyledButton = styled.button<IButtonProps>`
  background-color: ${(props) => props.background || '#2D749C'};
  border: none;
  border-radius: 8px;
  color: white;
  height: 48px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  margin: 4px 2px;
  padding: 10px 20px;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  cursor: pointer;
  min-width: 112px;
  min-height: 48px;
  &&:hover {
    opacity: 0.8;
  }

  @media (max-width: 1340px) {
    font-size: 14px;
  }
`

interface IButtonProps {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  onDisabledClick?: () => void
  children?: any
  disabled?: boolean
  fakeDisabled?: boolean
  style?: React.CSSProperties
  onContextMenu?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onContextMenuCapture?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Button = (props: IButtonProps) => {
  const {
    onClick,
    onDisabledClick,
    children,
    type,
    disabled,
    fakeDisabled,
    style,
    onContextMenu,
    onContextMenuCapture,
  } = props

  return (
    <StyledButton
      onContextMenu={onContextMenu}
      onContextMenuCapture={onContextMenuCapture}
      style={style}
      background={disabled && !fakeDisabled ? '#C2C2C2' : '#C6016B'}
      type={type}
      onClick={() => {
        if (!disabled && onClick) onClick()
        if (disabled && onDisabledClick) onDisabledClick()
      }}
    >
      {children}
    </StyledButton>
  )
}

export const LinkButton = styled(Link)<BlockProps>`
  color: ${(props) => props.theme.colors.main};
  padding: 1rem;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`

export const ButtonHighlight = styled.button`
  // for delete
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.main};
  border-radius: 8px;

  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`
const StrokedButtonStyled = styled.button`
  font-weight: 700;
  background-color: white;
  border: 1px solid #2d749c;
  border-radius: 24px;
  color: #2d749c;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: #2d749c;
    color: white;
  }
  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }

  @media (max-width: 1430px) {
    font-size: 14px;
  }

  @media (max-width: 1355px) {
    padding: 10px 23px;
  }

  @media (max-width: 1287px) {
    padding: 10px 21px;
  }

  @media (max-width: 1270px) {
    font-size: 12px;
  }
`

export const StrokedButton = (props: IButtonProps) => {
  const { onClick, children, type, disabled, style, fakeDisabled, onDisabledClick } = props

  return (
    <StrokedButtonStyled
      style={{
        backgroundColor: disabled && !fakeDisabled ? '#DFE3E8' : 'white',
        color: disabled && !fakeDisabled ? '#C2C2C2' : '#2D749C',
        borderColor: disabled && !fakeDisabled ? '#DFE3E8' : '#2D749C',
        ...style,
        maxHeight: '48px',
      }}
      type={type}
      onClick={() => {
        if (!disabled && onClick) onClick()
        if (disabled && onDisabledClick) onDisabledClick()
      }}
    >
      {children}
    </StrokedButtonStyled>
  )
}

interface IBackButtonProps {
  onClick?: () => void
  style?: React.CSSProperties
  text?: string
  hideIcon?: boolean
}

export const BackButton = ({ onClick, style, text, hideIcon }: IBackButtonProps) => {
  return (
    <BackButtonContainer onClick={onClick} style={style}>
      {!hideIcon && <ArrowLeftIcon />}
      <TextButton>{text ?? 'Atrás'}</TextButton>
    </BackButtonContainer>
  )
}
