import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    padding: 3.5%;
    margin-bottom: 40px;
`;


interface IRowProps {
    width?: string;
    justify?: string;
    height?: string;
    margin?: string;
}

export const Row = styled.div<IRowProps>`
    display: flex;
    flex-direction: row;
    width:  ${props => props.width ? props.width : '100%'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    height: ${props => props.height ? props.height : 'auto'};
    align-items: center;
    margin: ${props => props.margin ? props.margin : '0px'};
`;

interface IDividerProps {
    margin?: string;
}


export const Divider = styled.div<IDividerProps>`
    width: 100%;
    height: 1px;
    background-color: #1F2124;
    margin: ${props => props.margin ? props.margin : '0px'};
`;

export const TextDescriptionBehavior = styled.div`
    color: #637381;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    max-height: 80%;
    overflow: hidden;
`;

export const ModalBody = styled.div`
    width: 100%;
    height: 100%;
`;