import axios from 'axios'
import { UserInfo } from '../store'

export const fetchGeneratePDF = async (userInfo: UserInfo) => {
  // eslint-disable-next-line max-len
  const apiTest = `/generate_report?user_id=${userInfo.userId}&test_id=${userInfo.testId}&user_test_id=${userInfo.userTestId}&token=${userInfo.token}&type=${userInfo.type}&is_admin_report=${userInfo.isAdminReport}&is_reinterpretation=${userInfo.isReinterpretation}`

  const response: Response = await axios
    .get(apiTest)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })

  return response
}
