import { useEffect, useState } from 'react'
import { useParams, } from 'react-router-dom'
import { useNotification } from 'lib/context/notification.context'
import { Form, Formik } from 'formik'

import { validationEditJobPositionSchema } from '../../../schemas';
import { MainLayout } from 'views/layouts'
import { putFamilyRolFamilyPositions } from 'services/rolFamily/fetchPutBasicInfo'
import Footer from 'views/components/UI/Footer/footer'
import BackButton from 'views/components/UI/BackButton/back-button'
import JobPositionForm from '../../../components/job-position-form'
import { Button } from 'views/screens/NotFound/styled'
import { STATUS_OK } from 'constants/global.constants';

const EditJobPosition = ({ information, handleForm, typicalCharges = 'typical_positions', category }: any) => {
  const { id }: any = useParams()
  const { getError, getSuccess } = useNotification();
  const [basicInfo, setBasicInfo] = useState({ typical_positions: [] })

  const handleSubmit = async (values: any) => {
    const familyData = Object.assign({}, values)
    await putFamilyRolFamilyPositions(familyData, id).then((response: any) => {
      if (!response.ok && response.message) getError('Error al guardar, verifica que los datos estén correctos')
      if (response?.status === STATUS_OK) handleForm(0)
    }).catch(() => {
      getError('Error al guardar, verifica que los datos estén correctos')
    }).finally(() => {
      getSuccess('Datos guardados correctamente')
    })
  }

  const hasError = (errors: any) => {
    return Object.keys(errors).length > 0;
  }

  useEffect(() => {
    setBasicInfo({
      typical_positions: information,
    })
  }, [information])

  return (
    <MainLayout>
      <Formik
        initialValues={basicInfo}
        validationSchema={validationEditJobPositionSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form id="form-family-rol-settings-skill">

            <JobPositionForm props={{ values, errors, touched, setFieldValue }} category={category} typicalCharges={typicalCharges} />
            <Footer>
              <BackButton type='button' onClick={() => handleForm(0)} text='Cancelar' />
              <Button type="submit" disabled={hasError(errors)}>Guardar</Button>
            </Footer>
          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}

export default EditJobPosition
