import { useCallback } from 'react'
import { useNotification } from 'lib/context/notification.context'

const useReports = () => {
  const { getError, getSuccess } = useNotification()

  const handleReportDownload = useCallback(
    (user_id: number, test_id: number, process_Id: number, user_test_id: number, type: string) => {
      try {
        const queryString =
          `?user_id=${encodeURIComponent(user_id)}` +
          `&test_id=${encodeURIComponent(test_id)}` +
          `&process_id=${encodeURIComponent(process_Id)}` +
          `&user_test_id=${encodeURIComponent(user_test_id)}` +
          `&type=${encodeURIComponent(type)}`
        const reportDownloadUrl = `${window.location.origin}/reports-download${queryString}`
        window.open(reportDownloadUrl, '_blank')

        getSuccess('El reporte se está generando, espera unos segundos e intenta nuevamente')
      } catch (error) {
        console.error('Error fetching data:', error)
        getError('Error al descargar el reporte')
      }
    },
    [getSuccess, getError]
  )

  return {
    handleReportDownload,
  }
}

export default useReports
