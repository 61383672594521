import React, { FC } from 'react'
import { CardTitleWithColor, CardInfoWithBorderTitle, BlueText, StressorsList, StressorText } from './styled'
import { H5, P } from 'views/components/UI/Text'

type Answer = {
  text: string
  score: number
}
interface StressorsDetailProps {
  stressors: Answer[]
}

const EmptyStressors: FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '24px' }}>
      <BlueText>0/100</BlueText>
      <P>No se encontraron estresores</P>
    </div>
  )
}

const StressorsDetail: FC<StressorsDetailProps> = ({ stressors }) => {
  const stressorsScore = stressors.reduce((acc, stressor) => acc + stressor.score, 0)
  return (
    <CardInfoWithBorderTitle>
      <CardTitleWithColor>
        <H5>Estresores</H5>
        <H5 margin="0 0 0 5px">{`${stressorsScore}/100`} </H5>
      </CardTitleWithColor>
      {stressors.reduce((acc, stressor) => acc + stressor.score, 0) === 0 ? (
        <EmptyStressors />
      ) : (
        <StressorsList>
          {stressors
            .filter((stressor) => stressor.score !== 0)
            .map((stressor, index) => (
              <li key={index}><StressorText>{stressor.text}</StressorText></li>
            ))}
        </StressorsList>
      )}
    </CardInfoWithBorderTitle>
  )
}

export default StressorsDetail
