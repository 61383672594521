import axios from 'axios'

const getCompetencesByProcess = async (id: number) => {
  const competenceUrl = `${process.env.REACT_APP_MAIN_API}/process/test/competence/${id}`

  const competence = await axios
    .get(competenceUrl)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })

  return competence
}

export default getCompetencesByProcess
