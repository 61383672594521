import axios from 'lib/axios'

export const fetchHistoryTeamMap = async (page: number, search?: string) => {
  try {
    const response = await axios.get(`/team_map/history?page=${page}&limit=10${search ? `&search=${search}` : ''}`)
    return response.data
  } catch (error) {
    throw new Error('Error creating new team map')
  }
}
