import { AxiosResponse } from 'axios'
import axios from 'lib/axios'

interface ProcessData {
  type_process_id: number
  name: string
  participant_report: boolean
  development_plan: boolean
  level_id: number
  language: string
  company_id: number
  subscription_company_id: number
  user_id?: number
  admins: number[]
}

interface FamilyRole {
  process_id: number
  role_family: boolean
  role_family_name?: string
}

export interface IdealProfileCompetences {
  skill_id: number
  weight?: number
}

export interface BetesaIdealProfileValues {
  quadrant: string
  priority: number
  min: number
  max: number
}

export interface IdealProfileBetesa {
  betesa_skills?: number[]
  alert_level_range?: number[]
  profile_values?: BetesaIdealProfileValues[]
}

type IdealProfile = {
  process_id: number
  competences_ideal_profile?: IdealProfileCompetences[]
  betesa_ideal_profile?: IdealProfileBetesa
}

interface Users {
  process_id: number
  betesa_test_id?: number
  competences_test_id?: number
  users_id?: number[]
}

interface TestSelection {
  process_id?: number
  betesa_test: boolean
  competences_test: boolean
  online_course: boolean
}

interface RequestBody {
  process_data?: ProcessData
  family_role?: FamilyRole
  test_selection?: TestSelection
  ideal_profile?: IdealProfile
  users?: Users
  finishProcess?: boolean
}

interface FinishProcessResponse {
  status: string
  error?: any
  data?: any
}

interface FinishProcessResponse {
  status: string
  error?: any
  data?: any
}

interface ProcessResume {
  id: number
  name: string
  amount_of_tests: number
  test_send: string
  test_completed: string
  test_uncompleted: string
}

interface ProcessResumeResponse {
  status: string
  data: ProcessResume
  error?: any
}

interface UserDetail {
  user_id: number
  usetTestId: number
  personId: number
  companyId: number
  userId: number
  processId: number
  first_name: string
  last_name: string
  document: string
  email: string
  shipping_date: string
  reply_date: string
  active: boolean
}

interface UserDetailResponse {
  status: string
  data: UserDetail[]
  error?: any
  total_pages: number
}

export async function createProcessService(props: RequestBody): Promise<FinishProcessResponse> {
  const PATH = '/process'

  try {
    const response: AxiosResponse<FinishProcessResponse> = await axios.post(PATH, props)
    return response.data
  } catch (error) {
    console.error('Error creating new process', error)
    return { status: 'error', error: error }
  }
}

export async function updateProcess(processId: number, props: RequestBody): Promise<FinishProcessResponse> {
  const PATH = `/process/update/${processId}`
  const response: AxiosResponse<FinishProcessResponse> = await axios.put(PATH, props)
  return response.data
}

export async function getProcessDetail(processId: number): Promise<FinishProcessResponse> {
  const PATH = `/process/${processId}/steps`
  try {
    const response: AxiosResponse<FinishProcessResponse> = await axios.get(PATH)
    return response.data
  } catch (error: any) {
    console.error('Error retrieving process', error)
    return { status: 'error', error: error.response.data.message }
  }
}

export async function getProcessResume(processId: number, isForSettingsPage = false): Promise<FinishProcessResponse> {
  const PATH = `/process/details/${processId}?isForSettingsPage=${isForSettingsPage}`
  try {
    const response: AxiosResponse<ProcessResumeResponse> = await axios.get(PATH)
    return response.data
  } catch (error) {
    console.error('Error retrieving process resume', error)
    return { status: 'error', error: error }
  }
}

interface FilterGetUsersByProcess {
  search?: string
  complete?: boolean
  limit?: number
  page?: number
  viewUsersWithoutTests?: boolean
}

export async function getUsersByProcess(
  processId: number,
  filters?: FilterGetUsersByProcess
): Promise<UserDetailResponse> {
  let PATH = `/process/users/${processId}`

  if (filters) {
    PATH += '?'
    if (filters.search) {
      PATH += `search=${filters.search}`
    }
    if (filters.complete) {
      PATH += `&complete=${filters.complete}`
    }

    if (filters.viewUsersWithoutTests) {
      PATH += `&sent_tests=${!filters.viewUsersWithoutTests}`
    }

    if (filters.limit) {
      PATH += `&limit=${filters.limit}`
    }
    if (filters.page) {
      PATH += `&page=${filters.page}`
    }
  }

  try {
    const response: AxiosResponse<UserDetailResponse> = await axios.get(PATH)
    return response.data
  } catch (error) {
    return { status: 'error', error: error, data: [], total_pages: 0 }
  }
}

export async function deleteUsersOfOneProcess(processId: number, usersIds: number[]): Promise<FinishProcessResponse> {
  const PATH = `/delete/process/users/${processId}`

  const body = {
    users: usersIds,
  }

  try {
    const response: AxiosResponse<any> = await axios.put(PATH, body)
    return response.data
  } catch (error) {
    console.error('Error deleting users of process', error)
    return { status: 'error', error: error }
  }
}

export async function updateAdmins(processId: number, platformAdmins: number[]): Promise<FinishProcessResponse> {
  const PATH = `/process/assign/admin/users/${processId}`

  const body = {
    platformAdmins,
  }

  try {
    const response: AxiosResponse<any> = await axios.put(PATH, body)
    return response.data
  } catch (error) {
    console.error('Error updating admins', error)
    return { status: 'error', error: error }
  }
}
