import axios from 'axios';

interface Response {
  status: 'OK' | string;
  skill: {
    id: number;
    skill: string;
    description: string;
    language: string;
  };
}

interface Data {
  id: number;
  skill: string;
  description?: string;
  language?: string;
  active?: boolean;
  categoryId?: number;
  groupId?: number;
  levelId?: number;
  typeCategoryId?: number;
}

/**
 * @description Service to edit a questionnaire
 * @param {string} token Token of the user
 * @param {Data} data Data to edit the questionnaire
 * @returns {Promise<Response>} Information of the questionnaire edited
**/
const editQuestionnaire = async (token: string, data: Data) => {
  const { id } = data;
  const apiUrl = `${process.env.REACT_APP_MAIN_API}/skill/${id}`;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const { skill, description, language, active, categoryId, groupId, levelId, typeCategoryId } = data;

  const body = {
    skill,
    ...(description && { description }),
    ...(language && { language }),
    ...(active !== undefined && { active }),
    ...(categoryId !== undefined && { category_id: categoryId }),
    ...(groupId !== undefined && { group_id: groupId }),
    ...(levelId !== undefined && { level_id: levelId }),
    ...(typeCategoryId !== undefined && { type_category_id: typeCategoryId }),
  };

  try {
    const response: Response = await axios.put(apiUrl, body, config);
    return response;
  } catch (error) {
    console.error('Error updating questionnaire', error);
  }
};

export default editQuestionnaire;
