import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 5% 2%;
    gap:30px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
`;

export const SelectorsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: center;
`;

export const FooterContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 20px;
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  padding-left: 40%;
  gap:20px;
  /* margin-bottom: 20px; */
`

export const TextViewMore = styled.p`
// public sans font
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #C6016B;
  cursor: pointer;
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2D749C;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`
export const NextButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-end;
`;
export const ModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ModalBody = styled.div`
    width: 90%;
    height: 90%;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
`;
export const GridModal = styled.div`
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 10px;
    width: 100%;
    margin-top: 20px;
`;

export const SectionOne = styled.div`
  background-color: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-self:center;
  padding: 10px 80px;
  gap: 15px;
  width: 55%;
  height: 120px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;

  @media (max-width: 1540px) {
    width: 70%;
  }
`
export const SectionOneItem = styled.div`
  padding-top: 24px; 
  padding-bottom: 24px; 
  padding-right: 13px; 
  padding-left: 13px; 
  text-align: center;
`
export const SectionOneItemNumber = styled.span`
  font-weight: bold; 
  color: #2D749C; 
  font-size: 24px;
`
export const SectionOneItemDescription = styled.p`
  width: 150px;
`
export const HorizontalDivider = styled.hr`
  border: 1px solid  rgba(145, 158, 171, 0.24);
  background: rgba(145, 158, 171, 0.24);
  border-radius: 19px;
  height: 90px;
  align-self: center;
`
export const TextArea = styled.textarea`
font-family: 'Public Sans';
font-style: normal; 
width: 100%;
height: 120px;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;

`

export const TextAreaDiv = styled.div`
font-family: 'Public Sans';
font-style: italic;
color:#707070;
font-weight: 400;
font-size: 16px;
margin-top:30px;  
width: 100%;
height: 100%;
padding: 15px;
background: #FFFFFF;
border: 1px solid rgba(145, 158, 171, 0.2);
/* s1 */

box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 8px;
overflow: auto;
&::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2D749C;
    border-radius: 10px;
    border: 1px solid #2D749C;
    cursor: pointer;
  }
`

export const EditIconContainer = styled.a`
  width: 20px;
  height: 20px; 
  margin: 0px 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
`