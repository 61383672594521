import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import {
  Container,
  DashboardContainer,
  Row,
  ContainerItem,
  TextCancel,
  ButtonsContainer,
  TextBoldViolet,
  TextBoldBlue,
} from './styled'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import SearchBar from 'views/components/Searchbar/search-bar'
import { Button, BackButton } from 'views/components/UI/Buttons'
import { H2 } from 'views/components/UI/Text'
import WhiteContainer from 'views/components/common/WhiteContainer'
import { useNotification } from 'lib/context/notification.context'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import Modal from 'views/components/UI/Modal'
import { ActionSendReminderAndReturnCreditToInventory } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { userSelector } from 'ducks/user'
import { useFetchGetResumeProcess, useFetchGetUsersByProcess } from 'hooks/process/useGetProcess/useGetProcess'
import sendReminderTestToUserService from 'services/common/reports/postReminderTestToUser/postReminderTestToUser'
import returnCreditsToInventory from 'services/inventory/returnCredits/returnCredits'
import RollBackIcon from 'assets/icons/RollBackIcon'
import { DEFAULT_LIMIT } from 'constants/global.constants'

const HEADERS_GRID = [
  { name: 'Documento', key: 'document' },
  { name: 'Correo', key: 'email' },
  { name: 'Nombre', key: 'first_name' },
  { name: 'Apellido', key: 'last_name' },
  { name: 'Cantidad', key: 'tests' },
]

interface ItemDashboardProps {
  text: string
  quantity: string
  showDivider?: boolean
}
interface FilterGetUsersByProcess {
  limit?: number
  page?: number
  search?: string
  complete?: boolean
}

type Users = {
  userId: number
  processId: number
  isWithin72Hours: boolean
  testReminder: string
}

type UserProcessInfo = { userId: number; processId: number }

const ItemDashboard = ({ text, quantity, showDivider }: ItemDashboardProps) => {
  return (
    <ContainerItem showDivider={showDivider}>
      <H2 style={{ color: '#2D749C' }}>{quantity}</H2>
      <p>{text}</p>
    </ContainerItem>
  )
}

const UsersWithImcompletedTests = () => {
  const { getSuccess, getError } = useNotification()
  const navigate = useNavigate()
  const location = useLocation()
  const [filterGetUSers, setFilterGetUsers] = useState<FilterGetUsersByProcess>({ limit: DEFAULT_LIMIT })
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const { companyId } = useSelector(userSelector)
  const { id } = location.state as any
  const { listUsersByProcess, loadingUsersByProcess, fetchUsers, totalPages, usersWithReminderState } =
    useFetchGetUsersByProcess(id, filterGetUSers)
  const { listProcessTestResume, loadingProcessResume, fetchProcessResume } = useFetchGetResumeProcess(id)

  const [showModal, setShowModal] = useState(false)
  const [flag, setFlag] = useState(false)
  const [userSelected, setUserSelected] = useState<Users>()
  const [usersCheckBox, setUsersCheckBox] = useState<Set<number>>(new Set())

  useEffect(() => {
    fetchUsers()
    fetchProcessResume()
  }, [fetchUsers, fetchProcessResume, reload])

  useEffect(() => {
    if (search.length > 0) {
      setFilterGetUsers({ ...filterGetUSers, search, page: 1 })
    } else {
      const filtersMofiied = { ...filterGetUSers }
      delete filtersMofiied.search
      setFilterGetUsers(filtersMofiied)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const handleReturnCreditToInventory = async ({ userId, processId: processIdReturn }: Users) => {
    const dataToReturnCredits = {
      company_id: companyId,
      users: [
        {
          userId: userId,
          processId: processIdReturn,
        },
      ],
    }

    try {
      const responseReturnCredits = await returnCreditsToInventory(dataToReturnCredits)
      if (responseReturnCredits.status === 'OK') {
        getSuccess('Créditos devueltos al inventario')
        fetchUsers()
        fetchProcessResume()
      } else {
        getError('Error al devolver los créditos')
      }
    } catch (error) {
      getError('Error al devolver los créditos')
    } finally {
      setUserSelected(undefined)
    }
  }

  const handlePagination = async (page: number) => {
    setFilterGetUsers({ ...filterGetUSers, limit: DEFAULT_LIMIT, page })
  }

  const handleSetFilterGetUsers = (searchTerm: string) => {
    setFilterGetUsers({ search: searchTerm })
  }

  const dataUsers = Array.from(new Set(listUsersByProcess?.map((user: any) => user.userId)))
    .map((userId: any, index: number) => {
      const currentUserData = listUsersByProcess?.filter((user: any) => user.userId === userId)

      if (!currentUserData) {
        return null
      }

      const hasUserWithNonEmptyReminder = currentUserData.some((user: any) => user.test_reminder !== null)
      const filteredUsers = hasUserWithNonEmptyReminder
        ? currentUserData.filter((user: any) => user.test_reminder !== null)
        : currentUserData

      const userData = filteredUsers[0]

      return {
        ...userData,
        tests: (
          <div>
            {userData.completedTests}/{userData.totalTests}
          </div>
        ),
        index: index,
        testReminder: userData.test_reminder,
      }
    })
    .filter(Boolean)

  const handleSendReminderToSingleUser = async (emailUser: string) => {
    const email = {
      emails: [{ email: emailUser }],
    }
    try {
      const dataResponse = await sendReminderTestToUserService(email, id)
      if (dataResponse.data[0].status) {
        getSuccess('Recordatorio enviado exitosamente')
      }
    } catch (error) {
      getError('Ha ocurrido un error al enviar el recordatorio')
    }
  }

  const handleSendReminderToMultipleUsers = async () => {
    const emailsToSend = Array.from(usersCheckBox)
      .map((userId) => {
        const user = dataUsers.find((item) => item.userId === userId)
        return user ? { email: user.email } : null
      })
      .filter((emailObj): emailObj is { email: string } => emailObj !== null)
      .map((email) => ({ emails: [email] }))

    const promises = emailsToSend.map((emailObj) => sendReminderTestToUserService(emailObj, id))

    try {
      const results = await Promise.allSettled(promises)

      const rejected = results.filter((result) => result.status === 'rejected')
      const fulfilled = results.filter((result) => result.status === 'fulfilled' && result.value.data[0].status)

      setUsersCheckBox(new Set())

      if (rejected.length > 0) {
        getError('No se pudo enviar las pruebas a todos los usuarios')
      } else if (fulfilled.length === emailsToSend.length) {
        getSuccess('Recordatorios enviados exitosamente')
      } else {
        getError('No se pudo enviar las pruebas a todos los usuarios')
      }
      setLoading(true)
      setReload(!reload)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } catch (error) {
      getError('Ha ocurrido un error inesperado al enviar los recordatorios')
    }
  }

  const handleReturnCreditsToInventoryForMultipleUsers = async () => {
    const userIds = Array.from(usersCheckBox)

    const usersToReturnCredits = userIds
      .map((userId) => {
        const user = dataUsers.find((item) => item.userId === userId)
        if (!user) {
          console.error('Usuario no encontrado:', userId)
          return null
        }
        return { userId: user.userId, processId: user.processId }
      })
      .filter((user) => user !== null) as UserProcessInfo[]

    const dataToReturnCredits = {
      company_id: companyId,
      users: usersToReturnCredits,
    }

    try {
      const responseReturnCredits = await returnCreditsToInventory(dataToReturnCredits)
      setUsersCheckBox(new Set())
      if (responseReturnCredits.status === 'OK') {
        getSuccess('Créditos devueltos al inventario para todos los usuarios seleccionados')
        fetchUsers()
        fetchProcessResume()
      } else {
        getError('Error al devolver los créditos para algunos usuarios')
      }
    } catch (error) {
      console.error('Error en la devolución de créditos:', error)
      getError('Error al devolver los créditos')
    }
  }

  const handleClickReturnCreditsToInventory = () => {
    setFlag(true)
    setShowModal(true)
  }

  const modifiedDataUsers = dataUsers.map((user) => {
    return {
      ...user,
      id: user.userId,
    }
  })

  return (
    <MainLayout>
      {!loadingUsersByProcess && listUsersByProcess ? (
        <Container>
          <H2>Usuarios con pruebas sin completar</H2>
          <DashboardContainer>
            <WhiteContainer
              padding="20px"
              width="55%"
              height="90px"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <ItemDashboard text="Total de pruebas enviadas" quantity={listProcessTestResume.test_send} />
              <ItemDashboard showDivider text="Pruebas completadas" quantity={listProcessTestResume.test_completed} />
              <ItemDashboard text="Pruebas sin completar" quantity={listProcessTestResume.test_uncompleted} />
            </WhiteContainer>
          </DashboardContainer>
          <Row width="100%" height="48px" margin="0px 0px 30px 0px">
            <SearchBar
              label="Buscar por nombre, correo, documento"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              onClick={() => handleSetFilterGetUsers(search)}
              style={{ margin: '0px 0px 0px 0px', width: 'auto' }}
            />
            {usersCheckBox.size > 0 && (
              <ButtonsContainer>
                <TextBoldBlue onClick={handleClickReturnCreditsToInventory}>Devolver al inventario</TextBoldBlue>
                <TextBoldViolet onClick={handleSendReminderToMultipleUsers}>Enviar recordatorio</TextBoldViolet>
              </ButtonsContainer>
            )}
          </Row>

          <BluesiteTable
            loading={loading}
            headers={HEADERS_GRID}
            data={modifiedDataUsers}
            searchBar={false}
            checks={true}
            selectedItems={usersCheckBox}
            setSelectedItems={setUsersCheckBox}
            hideMainCheckbox
            searchLabel=""
            totalPages={totalPages}
            handlePagination={handlePagination}
            currentPage={filterGetUSers.page ? filterGetUSers.page : 1}
            ActionComponent={ActionSendReminderAndReturnCreditToInventory}
            extraDataAction={{
              disabled: usersCheckBox.size > 0,
              onReturnCreditToInventory: (e: Users) => {
                setShowModal(true)
                setUserSelected(e)
              },
              onSendReminder: (e: string) => handleSendReminderToSingleUser(e),
              usersWithReminderState: { usersWithReminderState },
            }}
          />
          <Modal
            show={showModal}
            setShow={setShowModal}
            hideCloseButton
            styleContent={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '50px',
            }}
          >
            <RollBackIcon width="100px" height="100px" />
            <H2 style={{ textAlign: 'center' }} margin="0 15%">
              {!flag
                ? '¿Seguro que deseas devolver esta prueba al inventario?'
                : '¿Seguro que deseas devolver estas pruebas al inventario?'}
            </H2>
            <Row margin="40px 0px -20px 0px">
              <TextCancel onClick={() => setShowModal(false)}>Cancelar</TextCancel>
              <Button
                onClick={() => {
                  if (userSelected) {
                    handleReturnCreditToInventory(userSelected)
                    setShowModal(false)
                  } else if (flag) {
                    handleReturnCreditsToInventoryForMultipleUsers()
                    setShowModal(false)
                    setFlag(false)
                  }
                }}
              >
                Devolver
              </Button>
            </Row>
          </Modal>
          <BackButton onClick={() => navigate(-1)} />
        </Container>
      ) : (
        <ModalLoader isLoading={loadingUsersByProcess || loadingProcessResume} />
      )}
    </MainLayout>
  )
}

export default UsersWithImcompletedTests
