import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5% 2%;
  box-sizing: border-box;
  gap: 30px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
`

export const SelectorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: center;
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
`

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  padding-left: 40%;
  gap: 20px;
  /* margin-bottom: 20px; */
`

export const TextViewMore = styled.p`
  // public sans font
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #c6016b;
  cursor: pointer;
`

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`
export const NextButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
`
export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ModalBody = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
`
export const GridModal = styled.div`
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 10px;
  width: 100%;
  margin-top: 20px;
`

export const SectionOne = styled.div`
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  align-self: center;
  padding: 10px 80px;
  gap: 5px;
  width: 88%;
  height: 130px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;

  @media (max-width: 1540px) {
    width: 70%;
  }
`
export const SectionOneItem = styled.div`
  padding-top: 24px;
  padding-bottom: 28px;
  padding-right: 13px;
  padding-left: 13px;
  text-align: center;
  width: auto;
`
export const SectionOneItemNumber = styled.span`
  font-weight: bold;
  color: #2d749c;
  font-size: 1rem;
  width: auto;
`
export const SectionOneItemDescription = styled.p`
  width: auto;
`
export const HorizontalDivider = styled.hr`
  border: 1px solid rgba(145, 158, 171, 0.24);
  background: rgba(145, 158, 171, 0.24);
  border-radius: 19px;
  height: 90px;
  align-self: center;
`
export const TextArea = styled.textarea`
  font-family: 'Public Sans';
  font-style: normal;
  width: 100%;
  height: 120px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  /* s1 */

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`
export const TextAreaDiv = styled.div`
  font-family: 'Public Sans';
  font-style: italic;
  color: #707070;
  font-weight: 400;
  font-size: 16px;
  margin-top: 30px;
  width: 100%;
  height: 120px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  /* s1 */

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2d749c;
    border-radius: 10px;
    border: 1px solid #2d749c;
    cursor: pointer;
  }
`

export const SearchButton = styled.div`
  display: flex;
  padding: 12px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const MagnifyingGlass = styled.img`
  width: 20px;
  height: 20px;
`

export const ButtonEdit = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
  padding-left: 88px;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`

export const ButtonSave = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  border: solid 1px #2d749c;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonCancel = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 700;
  color: #d20000;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`
