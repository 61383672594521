import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import { Link, useLocation } from 'react-router-dom'
import {
  Card,
  ModulesContainer,
  CardDescriptionSection
}
  from './styled'
import TitleDiv from 'views/components/UI/TitleDiv'
// ROUTES
import { superAdminRoutes } from 'router/routes'

const Setting: FC = () => {
  const { EditBasicInfoQuestionaire, QuestionsQuestionaire, ChaptersQuestionaire } = superAdminRoutes
  const location = useLocation()

  // router state
  const { questionnaireId, questionnaireName } = location.state

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Configuración {questionnaireName}</h2>
      </TitleDiv>
      {
        questionnaireId && (
          <ModulesContainer>
            <Link to={EditBasicInfoQuestionaire} state={{ questionnaireId, questionnaireName }}>
              <Card >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Información basica</span>
                  {/*  */}
                </CardDescriptionSection>
              </Card>
            </Link>
            <Link to={QuestionsQuestionaire} state={{ questionnaireId, questionnaireName }}>
              <Card >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Preguntas</span>
                  {/*  */}
                </CardDescriptionSection>
              </Card>
            </Link>
            <Link to={ChaptersQuestionaire} state={{ questionnaireId, questionnaireName }}>
              <Card >
                <CardDescriptionSection>
                  <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Capítulos</span>
                  {/*  */}
                </CardDescriptionSection>
              </Card>
            </Link>
          </ModulesContainer>
        )
      }
    </MainLayout>
  )
}
export default Setting
