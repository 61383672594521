import styled from "styled-components";

export const TitleWrapper = styled.div`
  width: 80%;
`

export const BetesaTitle = styled.h1` 
  font-family: 'Spartan-ExtraBold';
  line-height: 28px;
  letter-spacing: -0.07em;
  font-size: 45px;
  margin-top: 60px;
  margin-left: 24px;
  color: #2d749c;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 800;
  text-align: justify;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  width: calc(100% - 48px);
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const Title = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.24px;
  color: #C6016B;
`;

export const InfoName = styled.span`
  font-family: 'Spartan-ExtraBold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  text-align: justify;
`

export const InfoDate = styled.span`
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.1em;
`;

export const ContainerT = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 48px;
  justify-content: center;
`

export const PreviewTable = styled.table`
  width: 100%;
  justify-content: center;
  align-self: center;
  margin: 16px 0;
  border: 0;
  border-spacing: 0;
  border: 0.1px solid #738c9a;
  border-style: solid;
  border-radius: 4px;
  tr, th {
    border: 0.5px solid #738c9a;
  }
  td {
    border: 1px solid #738c9a;
  }
`

export const FirstPageFooter = styled.div`
  width: 100%;
  height: 50px;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #1e1e1e;
  padding: 8px 0;
`;
