import { FC } from 'react'
import { MainLayout } from 'views/layouts'
import { Link} from 'react-router-dom'
import {
  Card,
  ModulesContainer,
  CardDescriptionSection
}
  from './styled'
import TitleDiv from 'views/components/UI/TitleDiv'
import { useTranslation } from 'react-i18next'

const SkillsModules: FC = () => {
  const { t } = useTranslation()

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Configuración módulo {t('global.common.skillText')}</h2>
      </TitleDiv>
      <ModulesContainer>
        <Link to='/modules/skills/questions'>
          <Card>
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Preguntas</span>
            </CardDescriptionSection>
          </Card>
        </Link>
        <Link to='/modules/skills/actions'>
          <Card >
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Acciones de desarrollo</span>
            </CardDescriptionSection>
          </Card>
        </Link> 
        <Link to='/modules/skills/texts'>
          <Card>
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Textos</span>
            </CardDescriptionSection>
          </Card>
        </Link>  
      
       <Link to='/modules/skills/bank'>
        <Card>
            <CardDescriptionSection>
              <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Banco de competencias</span>
            </CardDescriptionSection>
          </Card>
       </Link>
       <Link to='/modules/skills/new'>
          <Card>
              <CardDescriptionSection>
                <span style={{ fontWeight: 'bold', color: '#1F2124', fontSize: 20 }}>Nueva competencia</span>
              </CardDescriptionSection>
          </Card>
       </Link> 
      </ModulesContainer>
    </MainLayout>
  )
}
export default SkillsModules
