import { QuadrantData  } from 'views/containers/BrainQuadrant/constants';

export const parseQuadrant = (quadrant: string) => {
  
    switch (quadrant) {
      case 'Frontal Izquierdo':
        return 'FI';
      case 'Frontal Derecho':
        return 'FD';
      case 'Basal Izquierdo':
        return 'BI';
      case 'Basal Derecho':
        return 'BD';
      default:
        return quadrant;
    }
  };

  
  export const orderQuadrants = (items: QuadrantData[]): QuadrantData[] => {
    const order = {
      "Frontal Izquierdo": 0,
      "FI": 0,
      "Frontal Derecho": 1,
      "FD": 1,
      "Basal Izquierdo": 2,
      "BI": 2,
      "Basal Derecho": 3,
      "BD": 3,
    };
  
    return items.sort((a, b) => {
      const orderA = order[a.quadrant] !== undefined ? order[a.quadrant] : Infinity;
      const orderB = order[b.quadrant] !== undefined ? order[b.quadrant] : Infinity;
      return orderA - orderB;
    });
};