import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
`
export const Content = styled.div`
  width: calc(80vw - 300px);
  left: 250px;
  height: 100vh;
  overflow-y: visible;
`

export const Title = styled.div`
  margin-top: 32px;
  font-size: 32px;
  font-weight: 700;
  color: #1f2124;
`

export const TableDivider = styled.hr`
  border: 1px solid #1f2124;
  width: 100%;
`

export const ActionsCell = styled.th`
  justify-content: flex-end;
  width: 25%;
`

export const TableSelect = styled.select`
  width: 63px;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: none;
  margin: 10px;
  padding: 10px;
`
export const OptionSelect = styled.option`
  padding: 10px;
`

export const GridDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  cursor: pointer;
  gap: 5px;
`
export const AddIcon = styled.svg`
  width: 24px;
  height: 24px;
  color: #2d749c;
  fill: #2d749c;
`
export const AddText = styled.p`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #2d749c;
`
export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #c2c2c2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover {
    background: #c6016b;
  }
`
export const GridModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 500px;
  align-items: center;
`
export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ModalBody = styled.div`
  width: 95%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
`
export const RowStart = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-start;
`
export const RowEnd = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
`

export const Form = styled.form`
  width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
