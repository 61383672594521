import axios from 'axios'
import { Company } from 'types/common'

interface IFindUserResponse  {
    status: string
    data: Company
}

/**
 * @description service to get one company
 * @param companyId
 */
const getOneCompany = async ( companyId: number) => {
    const res: IFindUserResponse = await axios.get(`/company/${companyId}`).then((response) => {
      return response.data;
    }).catch((error) => {
      return { ok: false, message: error.message };
    });
    return res
}

export default getOneCompany