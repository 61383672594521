/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  UpdateTag,
  Row, GridModal,
  ModalContainer,
  ModalHeader,
  ModalBody, BtnGoBack
} from './styled'
import Modal from 'views/components/UI/Modal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import fetchCompetenceById from 'services/competence/fetchCompetenceById';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { tokenSelector } from 'ducks/user';

interface ActionDetailModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  actionId: number;
  functionAdd: any;
  competencesAdded: any;

}

const ActionDetailModal: FC<ActionDetailModalProps> = ({
  openModal,
  setOpenModal,
  actionId,
  functionAdd,
  competencesAdded
}) => {
  const token = useSelector(tokenSelector);
  const [actionDetail, setActionDetail] = useState({
    description: '',
    skill: '',
    id: ''
  });


  useEffect(() => {
    if (actionId === 0) return;
    fetchCompetenceById(token, actionId).then(({ data }) => {
      setActionDetail(data);
    }, error => {
      console.error( 'Función de rechazo llamada: ', error );
      setActionDetail({description: '',
      skill: '',
      id: ''});
    })
  }, [token, actionId])

  return (
    <Modal
      hideCloseButton={true}
      show={openModal}
      setShow={setOpenModal}
      styleContent={{
        width: '35%',
        height: '40%',
        borderRadius: '10px',
        padding: '20px',
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
      }}
    >
      <ModalContainer>
        <ModalHeader>
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CloseOutlinedIcon />
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <h3>{actionDetail?.skill}</h3>
              <UpdateTag 
                style={{
                  background: _.find(competencesAdded, {skill_id: actionDetail?.id}) ? 'rgb(194, 194, 194)' : '#FFFFFF', 
                  color: _.find(competencesAdded, {skill_id: actionDetail?.id}) ? '#FFFFFF' : '#2D749C',
                  border: _.find(competencesAdded, {id: actionDetail?.id}) ? 'transparent' : '1px solid #2D749C'
                }}
                onClick={() => functionAdd(actionDetail?.skill, actionDetail?.id) }
                >{
                  _.find(competencesAdded, {skill_id: actionDetail?.id}) ? 'Agregada' : 'Agregar'
                }</UpdateTag>
          </div>
          <p>{actionDetail?.description}</p>
          
          
        </ModalBody>
      </ModalContainer>
    </Modal>
  )
}

export default ActionDetailModal
