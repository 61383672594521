import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const SectionContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center; 
justify-content: center; 
place-content: center;
`
export const SectionOne = styled.div`
  background-color: #FFFFFF;
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 80px;
  gap: 15px;
  width: 55%;
  height: 120px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;

  @media (max-width: 1540px) {
    width: 70%;
  }
`
export const SectionOneItem = styled.div`
  padding-top: 24px; 
  padding-bottom: 24px; 
  padding-right: 13px; 
  padding-left: 13px; 
  text-align: center;
`
export const SectionOneItemNumber = styled.span`
  font-weight: bold; 
  color: #2D749C; 
  font-size: 24px;
`
export const SectionOneItemDescription = styled.p`
  width: 150px;
`
export const SectionTwo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
`
export const SectionTwoPair = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: 79px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  margin-right: 36px;
`
export const SectionTwoPairContainer = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: center;
`
export const SectionTwoIndividual = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
    align-items: center;
  width: 173px;
  height: 77px;
  left: 359px;
  top: 1px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
`

export const CardTitle = styled.div`
width: 67px;
height: 18px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 18px;

text-align: left;

color: #1F2124;
margin-bottom: 5px;
`

export const HorizontalDivider = styled.hr`
  border: 1px solid  rgba(145, 158, 171, 0.24);
  background: rgba(145, 158, 171, 0.24);
  border-radius: 19px;
  height: 90px;
  align-self: center;
`
export const Description = styled.span`
  width: 40px; 
  font-size: 12; 
  color: #797A7C;
`
export const ImgContainer = styled.div`
  width: 51px; 
  height: 51px; 
  padding: 10px; 
  border-radius: 8px;
  margin-right: 10px;
`
export const SectionTwoIndividualContainer = styled.div`
  display: flex; 
  flex-direction: row;
`
export const CardDescription = styled.div`
  margin:5px;
`


interface IRow {
    height?: string;
    width?: string;
    justifyContent?: string;
}

export const Row = styled.div<IRow>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || 'auto'};
`
export const CompanyRow = styled.div`
    width: 100%;   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 35px;
    align-items: baseline;
    margin-top: 80px;
`
// ACTIONS  

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-end;
  padding-left: 40%;
`

export const TextViewMore = styled.p`
// public sans font
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #C6016B;
  cursor: pointer;
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TextEdit = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2D749C;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
`
