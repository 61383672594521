import { FC, useEffect, useState } from 'react'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { MainLayout } from 'views/layouts'
import { useLocation, useNavigate } from 'react-router-dom'
import { CreatePlatformAdminButton, Title, WrapperButtons, BottomContainer } from './styled'
import { User } from './store'
import { EMPTY_STRING, USER_ROLE_ID } from 'constants/global.constants'
import { fetchGePlatformAdmins } from './services/fetch-admin-platform'
import { COMPANIES_UPDATE_PATH, COMPANY_PATH, HEADERS } from './constants'
import { fetchUpdatePlatformAdmins, fetchDeletePlatformAdmins } from './services/fetch-suspend-admin'
import { useNotification } from 'lib/context/notification.context'
import { StrokedButton, BackButton } from 'views/components/UI/Buttons'
import ActionItem from './components/action-item/action-item'
import CreateAdminPlatformModal from './pages/create-admin-platform-modal/create-admin-platform-modal'
import SuspendAdminPlatformModal from './pages/suspend-admin-platform-modal'
import useGetCompanyDetail from 'hooks/company/useGetCompanyDetail/useGetCompanyDetail'

const INACTIVE_STATUS_ID = 2
const ACTIVE_STATUS_ID = 1
const DEFAULT_INACTIVE_STATUS_ID = 3

const AdminPlatformCompany: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const companyId = location.state.companyId
  const { getOneCompany, companyInfo } = useGetCompanyDetail()
  const { getSuccess, getError, getWarning } = useNotification()
  const [adminId, setAdminId] = useState(0)
  const [statusId, setStatusId] = useState(0)
  const [isClosed, setIsClosed] = useState(true)
  const [allPlatformAdmins, setAllPlatformAdmins] = useState([] as User[])
  const [isSuspendModal, setIsSuspendModal] = useState(true)
  const [isSuspendAction, setIsSuspendAction] = useState(true)
  const [totalPage, setTotalPage] = useState(0)
  const [adminIds, setAdminIds] = useState<Set<number>>(new Set())
  const [uniqueStatusIds, setUniqueStatusIds] = useState<number[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const handleCreateAdminModalClick = () => {
    setIsClosed(false)
    setIsSuspendModal(false)
  }

  useEffect(() => {
    const selectedAdmins = Array.from(adminIds).map((adminIdData) =>
      allPlatformAdmins.find((user) => user.userId === adminIdData)
    )

    const selectedStatusIds = selectedAdmins.map((admin) => (admin?.status_id === null ? 3 : admin?.status_id))
    const filteredStatusIds = selectedStatusIds.filter((id): id is number => id !== undefined)
    const uniqueStatusId = Array.from(new Set(filteredStatusIds))

    getOneCompany(companyId)

    setUniqueStatusIds(uniqueStatusId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminIds, allPlatformAdmins, companyId])

  const isDisableActionItem = () => {
    return uniqueStatusIds.length >= 1
  }

  const handleActionClick = (id: number, isSuspend: boolean, idStatus: number) => {
    if (!isDisableActionItem()) {
      setIsClosed(false)
      setAdminId(id)
      setIsSuspendModal(true)
      setIsSuspendAction(isSuspend)
      setStatusId(idStatus)
    }
  }

  const handleReactiveAdminsButtonClick = () => {
    setIsClosed(false)
    setIsSuspendModal(true)
    setIsSuspendAction(false)
  }

  const handleSuspendAdminsButtonClick = () => {
    setIsClosed(false)
    setIsSuspendModal(true)
    setIsSuspendAction(true)
  }

  const handleRefreshListAdmins = () => {
    const platformUsersBody = {
      role: [USER_ROLE_ID.platform_admin, USER_ROLE_ID.super_platform_admin],
      companyId,
      statusInfo: true,
    }

    fetchGePlatformAdmins(platformUsersBody).then(({ users, total_pages }) => {
      const usersFullName = users?.map((user: User) => {
        return {
          ...user,
          id: user.userId,
          idStatus: user.status_id,
          name: `${user.first_name} ${user.last_name}`,
        }
      })
      setTotalPage(total_pages)
      setAllPlatformAdmins(usersFullName)
    })
  }

  const handleButtonClick = () => {
    const adminPlatformToSuspend = Array.from(adminIds.values())
    const updatedStatus = statusId

    if (updatedStatus === DEFAULT_INACTIVE_STATUS_ID) {
      fetchDeletePlatformAdmins({
        userId: adminId,
      }).then((deleteUsers) => {
        if (deleteUsers) {
          allPlatformAdmins.forEach((user) => {
            if (user.userId === adminId) {
              user.status_id = updatedStatus
            }
          })
          getSuccess('Usuario eliminado correctamente')
          setIsClosed(true)
          setAdminIds(new Set())
          // fetch users
          handleRefreshListAdmins()
        } else {
          getError('Error al eliminar el usuario')
        }
      })
    } else {
      fetchUpdatePlatformAdmins({
        user_ids: !adminPlatformToSuspend.length ? [adminId] : adminPlatformToSuspend,
        id_status: updatedStatus ? statusId : ACTIVE_STATUS_ID,
      }).then((updatedUsers) => {
        if (updatedUsers.length > 0) {
          allPlatformAdmins.forEach((user) => {
            if (user.userId === adminId) {
              user.status_id = updatedStatus
            }
          })
          getSuccess('Usuario actualizado correctamente')
          setIsClosed(true)
          handleRefreshListAdmins()
          setAdminIds(new Set())
        } else {
          getError('Error al actualizar el usuario')
        }
      })
    }
  }

  const handleSearchUser = (search: string) => {
    const platformUsersBody: any = {
      role: [USER_ROLE_ID.platform_admin, USER_ROLE_ID.super_platform_admin],
      companyId,
      statusInfo: true,
    }

    if (search.length > 0) {
      platformUsersBody.search = search
    }

    fetchGePlatformAdmins(platformUsersBody, currentPage).then(({ users, total_pages }) => {
      if (users) {
        const usersFullName = users?.map((user: User) => {
          return {
            ...user,
            id: user.userId,
            idStatus: user.status_id,
            name: `${user.first_name} ${user.last_name}`,
          }
        })
        setTotalPage(total_pages)
        setAllPlatformAdmins(usersFullName)
      } else {
        setTotalPage(1)
        setAllPlatformAdmins([])
      }
    })
  }

  useEffect(() => {
    if (uniqueStatusIds.length > 1) {
      const adminIdsArray = Array.from(adminIds)
      const filteredAdminIds = adminIdsArray.filter((adminIdItem) => {
        const admin = allPlatformAdmins.find((user) => user.userId === adminIdItem)
        return admin?.status_id !== uniqueStatusIds[uniqueStatusIds.length - 1]
      })

      setAdminIds(new Set(filteredAdminIds))
    }
  }, [uniqueStatusIds.length, adminIds, allPlatformAdmins, uniqueStatusIds])

  useEffect(() => {
    if (companyId) {
      const platformUsersBody = {
        role: [USER_ROLE_ID.platform_admin, USER_ROLE_ID.super_platform_admin],
        companyId,
        statusInfo: true,
      }

      const admin = async () => {
        fetchGePlatformAdmins(platformUsersBody, currentPage).then(({ users, total_pages }) => {
          const usersFullName = users?.map((user: User) => {
            return {
              ...user,
              id: user.userId,
              idStatus: user.status_id,
              name: `${user.first_name} ${user.last_name}`,
            }
          })
          setTotalPage(total_pages)
          setAllPlatformAdmins(usersFullName)
        })
      }
      admin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, isClosed, currentPage])

  let renderContent = null

  if (isDisableActionItem()) {
    const firstStatusId = uniqueStatusIds[0]
    const secondStatusId = uniqueStatusIds[1]

    if (firstStatusId === ACTIVE_STATUS_ID) {
      if (secondStatusId === INACTIVE_STATUS_ID) {
        renderContent = getWarning('No puedes seleccionar multiples usuarios con diferentes estados.')
      } else {
        renderContent = (
          <StrokedButton
            style={{
              borderColor: '#E44646',
              color: '#E44646',
            }}
            onClick={handleSuspendAdminsButtonClick}
          >
            Suspender administradores
          </StrokedButton>
        )
      }
      if (secondStatusId === DEFAULT_INACTIVE_STATUS_ID) {
        renderContent = getWarning('Espera a que el usuario active su cuenta')
      }
    } else if (firstStatusId === 2 && secondStatusId !== 1) {
      renderContent = (
        <StrokedButton
          style={{
            borderColor: '#E44646',
            color: '#E44646',
          }}
          onClick={handleReactiveAdminsButtonClick}
        >
          Reactivar
        </StrokedButton>
      )
    } else if (firstStatusId === 3 || secondStatusId === 3) {
      renderContent = getWarning('Espera a que el usuario active su cuenta')
    } else {
      renderContent = getWarning('No puedes seleccionar multiples usuarios con diferentes estados')
    }
  }

  const modifiedData = allPlatformAdmins?.map((user) => {
    return {
      ...user,
      isBold: user.role_type_id === 6 ? true : false,
    }
  })

  return (
    <MainLayout>
      <WrapperButtons>
        <StrokedButton style={{ margin: '40px 0px 0px 0px' }} onClick={() => navigate('/users-suspend-request')}>
          Solicitudes de suspensión de administradores
        </StrokedButton>
      </WrapperButtons>
      <Title>Administradores de plataforma - {companyInfo?.data.name}</Title>

      <WrapperButtons>
        {renderContent ? (
          <div>{renderContent}</div>
        ) : (
          <CreatePlatformAdminButton onClick={handleCreateAdminModalClick}>
            Agregar nuevo administrador
          </CreatePlatformAdminButton>
        )}
      </WrapperButtons>

      <div style={{ minHeight: '420px' }}>
        <BluesiteTableComponent
          data={modifiedData}
          headers={HEADERS}
          currentPage={currentPage}
          selectedItems={adminIds}
          setSelectedItems={(e: any) => {
            if (e) {
              const adminIdsArray = Array.from(e)
              const filteredAdminIds = adminIdsArray.filter((adminIdItem) => {
                const admin = allPlatformAdmins.find((user) => user.userId === adminIdItem)
                if (admin?.status_id === null || admin?.status_id === DEFAULT_INACTIVE_STATUS_ID) {
                  if (adminIds.size === 0) {
                    getWarning('Espera a que el usuario active su cuenta')
                  } else {
                    getWarning('No puedes seleccionar multiples usuarios con diferentes estados')
                  }
                }
                return admin?.status_id !== null && admin?.status_id !== DEFAULT_INACTIVE_STATUS_ID
              })

              setAdminIds(new Set(filteredAdminIds) as any)
            }
          }}
          searchBar={true}
          checks={true}
          viewUrl={COMPANY_PATH}
          updateUrl={COMPANIES_UPDATE_PATH}
          searchLabel={EMPTY_STRING}
          ActionComponent={ActionItem}
          totalPages={totalPage}
          onSearch={handleSearchUser}
          setSearchParam={handleSearchUser}
          handlePagination={(page: number) => {
            setCurrentPage(page)
          }}
          hideMainCheckbox={true}
          extraDataAction={{
            handleActivateActionClick: (id: number, idStatus: number) => handleActionClick(id, false, idStatus),
            handleSuspendActionClick: (id: number) => {
              handleActionClick(id, true, 1)
            },
            handleDeleteActionClick: (id: number, idStatus: number) => handleActionClick(id, false, idStatus),
          }}
        />
      </div>
      {isSuspendModal ? (
        <SuspendAdminPlatformModal
          handleCloseClick={() => setIsClosed(true)}
          isClosed={isClosed}
          setIsClosed={setIsClosed}
          isSuspendAction={isSuspendAction}
          handleButtonActionClick={handleButtonClick}
          idStatus={statusId}
        />
      ) : (
        <CreateAdminPlatformModal
          companyId={companyId}
          handleCloseClick={() => setIsClosed(true)}
          openModal={isClosed}
          setOpenModal={setIsClosed}
          onFinished={handleRefreshListAdmins}
        />
      )}
      <BottomContainer>
        <BackButton onClick={() => navigate(-1)} />
      </BottomContainer>
    </MainLayout>
  )
}

export default AdminPlatformCompany
