import styled, { css } from 'styled-components'

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

export const ContainerTest = styled.div<{ isSingleCard: boolean }>`
  width: 100%;
  max-width: ${({ isSingleCard }) => (isSingleCard ? '391px' : '1000px')};
  padding-top: 30px;
  padding-bottom: 40px;
  ${iOS &&
  css`
    /*  padding-top: 0px;
    @media (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 20px;
    }
    @media (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 20px;
    }
    @media (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
      padding-top: 0 !important;
    } */
  `}
`
export const ContainerCards = styled.div<{ isSingleCard: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSingleCard }) => (isSingleCard ? 'auto' : 'repeat(2, minmax(200px, 1fr))')};
  grid-auto-rows: auto;
  grid-gap: 16px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    grid-gap: 16px;
    padding-bottom: 10px;
  }

  @media (max-width: 764px) {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    grid-gap: 16px;
  }

  @media (max-width: 574px) {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    grid-gap: 16px;
    margin-top: 0px;
  }
`
export const CardTest = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
  height: auto;
  background: white;
  border-radius: 23px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  gap: 12px;
  justify-content: flex-start;
  margin-left: 10px;
  padding-bottom: 1rem;
  @media (max-width: 764px) {
    width: 80%;
    justify-content: flex-start;
    height: auto;
    padding-bottom: 20px;
  }
  @media (max-width: 574px) {
    width: auto;
    justify-content: flex-start;
    height: auto;
  }
`
export const NotTest = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
`

export const TitleCard = styled.h4`
  min-height: 60px;
  font-weight: 700;
  color: #1f2124;
  margin-bottom: 0;
  // max three lines
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 574px) {
    font-size: 3.5vw;
  }
`

export const CardTestItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
`
export const CardTestItemImgDiv = styled.div`
  width: 30%;
  height: 100%;
  background-color: #81acc4;
  @media (max-width: 574px) {
    height: auto;
    width: 50%;
  }
`
export const TestItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
export const CardTestItemContentDiv = styled.div`
  padding: 10px;
  width: 65%;
`
export const CardTestItemContentTitle = styled.h4`
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: 574px) {
    font-size: 3.5vw;
  }
`
export const CardTestItemContentDescription = styled.p`
  @media (max-width: 574px) {
    font-size: 3vw;
    margin: 0;
  }
`
export const CardTestItemContentParagraph = styled.p`
  color: #1f2124;
`
export const CardTestItemContentStartDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
`
export const CardTestItemContentDurationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 0;

  @media (max-width: 485px) {
    flex-direction: column;
    gap: 0px;
  }
`
export const ViewMoreDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  @media (max-width: 574px) {
    margin-top: 5%;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
`

export const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
