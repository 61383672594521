import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { Formik, Form } from 'formik'

import SkillInfo from '../../../components/skill-info-form'
import * as Yup from 'yup';
import { useNotification } from 'lib/context/notification.context'
import { putFamilyRolFamilySkill } from 'services/rolFamily/fetchPutBasicInfo'
import BackButton from 'views/components/UI/BackButton/back-button'
import { Button } from 'views/components/UI/Buttons'
import { STATUS_OK } from 'constants/global.constants'

const validationSchema = Yup.object().shape({
  family_skills_data: Yup.array()
    .min(1, "Al menos un dato de la familia debe ser seleccionado")
})

const EditSkills = ({ information, handleForm }: any) => {
  const { id }: any = useParams()
  const { getError, getSuccess } = useNotification();
  const [basicInfo, setBasicInfo] = useState({
    family_skills_data: ['']
  })

  const hasError = (errors: any) => {
    return Object.keys(errors).length > 0;
  }

  const handleSubmit = async (values: any) => {
    const familyData = Object.assign({}, values)
    await putFamilyRolFamilySkill(familyData, id).then((response: any) => {
      if (!response.ok && response.message) getError('No se pudo guardar, verifica que los campos estén llenos')
      if (response?.status === STATUS_OK) handleForm(0)
      getSuccess('Datos guardados correctamente')
    }).catch(() => {
      getError('No se pudo guardar, verifica que los campos esten llenos')
    })
  }
  useEffect(() => {

    setBasicInfo({
      family_skills_data: information,
    })
  }, [information])

  return (
    <MainLayout>
      <Formik
        initialValues={basicInfo}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form id="form-family-rol-settings-skill">

            <SkillInfo props={{ values, errors, touched, setFieldValue }} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30, alignItems: 'center', paddingBottom: 20 }}>

              <BackButton type='button' onClick={() => handleForm(0)} text='Cancelar' />

              <Button type="submit" disabled={hasError(errors)}>Guardar</Button>
            </div>

          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}
export default EditSkills
