import axios from "axios";

 interface IBodyData {
    skill: string
    description: string
    language: string
    active:boolean
    category_id: number
    group_id: number
    level_id: number
    type_category_id: number
    competence_descriptions?:{
        emotional?: string
        cognitive?: string
        interactive?: string
    }
}

interface IData {
    skill: string
    description: string
    language: string
    active:boolean
    categoryId: number
    groupId: number
    levelId: number
    typeCategoryId: number
    competence_descriptions?:{
        cognitive?: string
        emotional?: string
        interactive?: string
    }
}

/**
 * Create a skill or questionary
 * @param {IData} data  - The data of the skill or questionary
 * @returns {Promise} - The promise of the axios call
 */

const createSkill = (data: IData) => {
    
    const apiUrl = `${process.env.REACT_APP_MAIN_API}/type_category_test/create`



    // post body
    const body : IBodyData = {
        'skill': data.skill,
        'description': data.description,
        'language': data.language,
        'active': data.active,
        'category_id': Number(data.categoryId),
        'group_id': data.groupId,
        'level_id': data.levelId,
        'type_category_id': data.typeCategoryId,
        'competence_descriptions': {
            'cognitive': data.competence_descriptions?.cognitive,
            'emotional': data.competence_descriptions?.emotional,
            'interactive': data.competence_descriptions?.interactive,
        },
    }

    const response = axios.post(apiUrl, body).then((res) => {
        return res.data
    }
    ).catch((error) => {
        return { ok: false, message: error.message };
    })

    return response
}

export default createSkill;
