import { FieldArray } from 'formik';
import {
  ErrorContainer,
  FormContainer,
  TextArea,
  TextError,
  ContainerInputs,
  TitleWrapper,
  Title,
} from './styled'
import { ROLE_FAMILY_SKILLS_FIELDS } from '../../../constants';

const SkillInfo = ({ props }: any) => {
  const { values, errors, setFieldValue } = props

  return <>
    <TitleWrapper>
      <Title>Habilidades asociadas a la familia de rol</Title>
    </TitleWrapper>
    <FormContainer>
      <FieldArray name="family_skills_data">
        {() => (
          <ContainerInputs>
            {(errors?.family_skills_data) && (
              <ErrorContainer>
                <TextError>{errors.family_skills_data}</TextError>
              </ErrorContainer>
            )}
            {ROLE_FAMILY_SKILLS_FIELDS?.map((skill: any, index: any) => (
              <TextArea
                key={`${skill.fieldName}${index}`}
                name={skill.fieldName}
                value={values.family_skills_data[index]}
                placeholder={`Ej: ${skill.placeholder}`}
                onChange={(e: any) => {
                  setFieldValue(`family_skills_data.${index}`, e.target.value)
                }}
              />
            ))}
          </ContainerInputs>
        )}
      </FieldArray>
    </FormContainer>
  </>
}

export default SkillInfo
