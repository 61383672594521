import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  requestForSuspension,
  getAdminsRequestedForSuspension,
  suspendAdminUser,
  BodySuspendAdminUser
} from 'services/companies/adminUsers/admin-users-services';
import {FiltersGetAdminsRequestedForSuspension, RequestForSuspensionResponse, GetAdminsRequestedForSuspensionResponse} from 'types/services'
import { userSelector } from 'ducks/user';
interface IPaginationListAdmins {
  totalResults: number;
  totalPages: number;
}
interface UseSuspensionReturnType {
  requestForSuspensionData: RequestForSuspensionResponse | null;
  adminsRequestedForSuspensionData: GetAdminsRequestedForSuspensionResponse | null;
  suspendAdminUserData: RequestForSuspensionResponse | null;
  isLoading: boolean;
  error: any;
  makeRequestForSuspension: (usersId: number[]) => Promise<void>;
  fetchAdminsRequestedForSuspension: (filters: FiltersGetAdminsRequestedForSuspension) => Promise<void>;
  makeSuspendAdminUser: (data: BodySuspendAdminUser[]) => Promise<void>;
  paginationListAdmins: IPaginationListAdmins | null;
}


function useSuspension(): UseSuspensionReturnType {
  const {companyId} = useSelector(userSelector);

  const [requestForSuspensionData, setRequestForSuspensionData] = useState<RequestForSuspensionResponse | null>(null);
  const [adminsRequestedForSuspensionData, setAdminsRequestedForSuspensionData] = useState<GetAdminsRequestedForSuspensionResponse | null>(null);
  const [suspendAdminUserData, setSuspendAdminUserData] = useState<RequestForSuspensionResponse | null>(null);
  const [paginationListAdmins, setPaginationListAdmins] = useState<IPaginationListAdmins| null>(null); 
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const makeRequestForSuspension = useCallback(async (usersId: number[]) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await requestForSuspension(usersId, companyId);
      setRequestForSuspensionData(response);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [companyId]);

  const fetchAdminsRequestedForSuspension = useCallback(async (filters: FiltersGetAdminsRequestedForSuspension) => {
    setIsLoading(true);
    setError(null);
    try {
      const response: any = await getAdminsRequestedForSuspension(filters);
      setAdminsRequestedForSuspensionData(response.requests);
      setPaginationListAdmins({totalResults: response.total_results, totalPages: response.total_pages});
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const makeSuspendAdminUser = useCallback(async (data: BodySuspendAdminUser[]) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await suspendAdminUser(data);
      setSuspendAdminUserData(response);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    requestForSuspensionData,
    adminsRequestedForSuspensionData,
    suspendAdminUserData,
    isLoading,
    error,
    makeRequestForSuspension,
    fetchAdminsRequestedForSuspension,
    makeSuspendAdminUser,
    paginationListAdmins
  };
}

export default useSuspension;
