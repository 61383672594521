import { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import { Title } from './styled'
import ModalAddAdmin from './modal-add-admin'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { StrokedButton } from 'views/components/UI/Buttons'
import SearchBar from 'views/components/Searchbar/search-bar'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { ActionRequestSuspensionPlatformAdmin } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { userSelector } from 'ducks/user'
import useGetCompanyDetail from 'hooks/company/useGetCompanyDetail/useGetCompanyDetail'
import { useSearchUsers } from 'hooks/users/useSearchUser/useSearchUser'
import { useNotification } from 'lib/context/notification.context'
import useSuspension from 'hooks/company/useSuspendPlatformAdmin/useSuspendPlatformAdmin'
import { PLATFORM_ADMIN_ROLE_ID } from 'constants/global.constants'
import { HEADERS_ADMIN_USERS } from './constants'
import _  from 'lodash'

const AdministratorsCompany: FC = () => {
  const { getError, getSuccess } = useNotification()
  const {
    requestForSuspensionData,
    isLoading,
    error,
    makeRequestForSuspension
  } = useSuspension();
  const { getOneCompany } = useGetCompanyDetail()
  const { results, search, paginationUsers } = useSearchUsers()

  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const { companyId, userId } = useSelector(userSelector)
  const [openModal, setOpenModal] = useState(false)

  function getAdmins() {
    search({ companyId, role: [PLATFORM_ADMIN_ROLE_ID], status: true, disableFilterByCompany: true })
  }

  const handlePagination = (page: number) => {
    setCurrentPage(page)
    search({
      companyId,
      role: [PLATFORM_ADMIN_ROLE_ID],
      status: true,
      disableFilterByCompany: true,
      page: page.toString(),
    })
  }


  const handleSuspend = async (userIdToSuspend: number) => {
    const usersToSuspend = [userIdToSuspend];
    await makeRequestForSuspension(usersToSuspend);
  };

  useEffect(() => {
    if (requestForSuspensionData) {
      getSuccess('Solicitud de suspensión de usuario enviada con exito')
      getAdmins()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestForSuspensionData])

  useEffect(() => {
    getOneCompany(companyId)
    getAdmins()

    if (error) {
      getError('Hubo un error al intentar suspender al usuario')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  

  useEffect(() => {
    if (search.length) {
      search({
        companyId,
        role: [PLATFORM_ADMIN_ROLE_ID],
        status: true,
        disableFilterByCompany: true,
        nameFilter: searchTerm,
      })
    } else {
      getAdmins()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])


  return (
    <MainLayout>
      <Title>Administradores</Title>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 35,
          alignItems: 'center',
        }}
      >
        <StrokedButton
          type="button"
          onClick={() => {
            setOpenModal(true)
          }}
        >
          Agregar usuario
        </StrokedButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 5,
          alignItems: 'center',
        }}
      >
        <p />
        <SearchBar
          value={searchTerm}
          label="Buscar usuarios..."
          onChange={(e: any) => {
            setSearchTerm(e.target.value)
          }}
        />
      </div>
      <BluesiteTable
        data={(results.length && _.filter(results, obj => obj.userId !== userId)) || []}
        headers={HEADERS_ADMIN_USERS}
        searchBar={false}
        searchLabel=""
        totalPages={paginationUsers.total_pages}
        currentPage={currentPage}
        handlePagination={handlePagination}
        ActionComponent={ActionRequestSuspensionPlatformAdmin}
        extraDataAction={{
          onRequestDeletePlatformAdmin: (e: number) =>
            handleSuspend(e),
        }}
      />
      <ModalAddAdmin onCreateSuccess={() => {
        getAdmins()
        setOpenModal(false)
      }} isOpen={openModal} setOpenModal={setOpenModal} />
      <ModalLoader isLoading={isLoading}   />
    </MainLayout>
  )
}

export default AdministratorsCompany
