import React, { FC, useState } from 'react'
import {
  Container,
  QuadrantsContainer,
  AbsoluteContainer,
  QuadrantTagContainer,
  QuadrantTag,
  BodyQuadrant,
  ShowName,
} from './styled'
import WhiteContainer from 'views/components/common/WhiteContainer'
import { TeamMapProps } from 'views/containers/TeamMap'

const BrainMap: FC<TeamMapProps> = ({ usersByQuadrant, talents }) => {
  const [showFd, setShowFd] = useState(false)
  const [showFi, setShowFi] = useState(false)
  const [showBi, setShowBi] = useState(false)
  const [showBd, setShowBd] = useState(false)

  return (
    <Container>
      <QuadrantsContainer>
        <WhiteContainer width="47%" height="100%" overflow="auto" margin="10px 0px 0px 0px">
          <BodyQuadrant left>
            {showFi &&
              usersByQuadrant?.fi
                ?.slice()
                .sort((a: any, b: any) => b.FI - a.FI)
                .map((user, index) =>
                  !talents ? (
                    <ShowName left key={`fi-${index}`}>
                      {user.full_name}
                    </ShowName>
                  ) : (
                    <ShowName left key={`fi-${index}`}>
                      {user.full_name} - {user.FI}
                    </ShowName>
                  )
                )}
          </BodyQuadrant>
          <QuadrantTagContainer alignITems="flex-end">
            <QuadrantTag show={showFi} onClick={() => setShowFi(!showFi)}>
              FI
            </QuadrantTag>
          </QuadrantTagContainer>
        </WhiteContainer>

        <WhiteContainer width="47%" height="98%" margin="10px 0px 0px 0px">
          <BodyQuadrant>
            {showFd &&
              usersByQuadrant?.fd
                ?.slice()
                .sort((a: any, b: any) => b.FD - a.FD)
                .map((user, index) =>
                  !talents ? (
                    <ShowName key={`fd-${index}`}>{user.full_name}</ShowName>
                  ) : (
                    <ShowName key={`fd-${index}`}>
                      {user.full_name} - {user.FD}
                    </ShowName>
                  )
                )}
          </BodyQuadrant>
          <QuadrantTagContainer>
            <QuadrantTag show={showFd} onClick={() => setShowFd(!showFd)}>
              FD
            </QuadrantTag>
          </QuadrantTagContainer>
        </WhiteContainer>

        <WhiteContainer width="47%" height="98%" margin="10px 0px 0px 0px">
          <QuadrantTagContainer alignITems="flex-end">
            <QuadrantTag show={showBi} onClick={() => setShowBi(!showBi)}>
              BI
            </QuadrantTag>
          </QuadrantTagContainer>
          <BodyQuadrant left>
            {showBi &&
              usersByQuadrant?.bi
                ?.slice()
                .sort((a: any, b: any) => b.BI - a.BI)
                .map((user, index) =>
                  !talents ? (
                    <ShowName left key={`bi-${index}`}>
                      {user.full_name}
                    </ShowName>
                  ) : (
                    <ShowName left key={`bi-${index}`}>
                      {user.full_name} - {user.BI}
                    </ShowName>
                  )
                )}
          </BodyQuadrant>
        </WhiteContainer>

        <WhiteContainer width="47%" height="98%" margin="10px 0px 0px 0px">
          <QuadrantTagContainer>
            <QuadrantTag show={showBd} onClick={() => setShowBd(!showBd)}>
              BD
            </QuadrantTag>
          </QuadrantTagContainer>
          <BodyQuadrant>
            {showBd &&
              usersByQuadrant?.bd
                ?.slice()
                .sort((a: any, b: any) => b.BD - a.BD)
                .map((user, index) =>
                  !talents ? (
                    <ShowName key={`bd-${index}`}>{user.full_name}</ShowName>
                  ) : (
                    <ShowName key={`bd-${index}`}>
                      {user.full_name} - {user.BD}
                    </ShowName>
                  )
                )}
          </BodyQuadrant>
        </WhiteContainer>
      </QuadrantsContainer>
      <AbsoluteContainer />
    </Container>
  )
}

export default BrainMap
