import { useCallback } from 'react'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'

interface IdealProfileData {
  alertLevelId?: number
  profileValues?: string
  betesaSkills?: string
  profile_values?: any
  alert_level_range?: any
}

interface IdealProfile {
  alertLevelId?: number
  profileValues?: string
  betesaSkills?: string
  profile_values?: any
  alert_level_range?: any
}

interface SkillIdealProfile {
  skill_id: number
  skill_name: string
  weight: number
  level: string
}

interface InfoRoleFamilyEdited {
  isNewProcess: boolean
  ideal_profile: IdealProfile[]
  competences_ideal_profile?: SkillIdealProfile[]
  alert_level_range: number[]
}

interface DataEditedRoleFamily {
  id: number
  role_family: boolean
  role_family_name: string
  betesa_test: boolean
  competences_test: boolean
  betesa_ideal_profile?: IdealProfileData
  betesa_skills?: string
  competences_ideal_profile?: IdealProfileData
  process?: any
}

interface UseGetEditedRoleFamily {
  handleGetEditedRoleFamily: (processId: number) => Promise<InfoRoleFamilyEdited | null>
}

const useFinishProcess = (): UseGetEditedRoleFamily => {
  const handleGetEditedRoleFamily = useCallback(async (processId: number): Promise<InfoRoleFamilyEdited | null> => {
    try {
      const dataEditedRoleFamily: DataEditedRoleFamily = (await getProcessDetail(processId)) as any
      const { betesa_ideal_profile: betesaIdealProfile, competences_ideal_profile: competencesIdealProfile } =
        dataEditedRoleFamily.process

      const infoRoleFamilyEdited: InfoRoleFamilyEdited = {
        isNewProcess: false,
        ideal_profile: betesaIdealProfile?.profile_values,
        alert_level_range: betesaIdealProfile?.alert_level_range,
        competences_ideal_profile: competencesIdealProfile,
      }

      return infoRoleFamilyEdited
    } catch (e: any) {
      console.error('error obteniendo la familia de rol editada editada', e)
      return null
    }
  }, [])

  return { handleGetEditedRoleFamily }
}

export default useFinishProcess
