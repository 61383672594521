import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from "ducks/user";
import { useNotification } from 'lib/context/notification.context';
import getProcessByCompany from 'services/companies/getProcessByCompany'

interface ProcessItem {
  value: string;
  label: string;
}

export const useFetchAllProcesses = () => {
  const { getError} = useNotification();
  const {companyId } = useSelector(userSelector);
  const dataHome = useSelector((state: any) => state.user.userId)
  const [allProcess, setAllProcess] = useState<ProcessItem[]>([]);
  const [loadingAllProcesses, setLoading] = useState<boolean>(false);

  const fetchAllProcesses = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getProcessByCompany(companyId, dataHome);
      const allProcessFormatted: any = response?.data.processes.map((processitem: any) => ({
        value: processitem.process_id,
        label: processitem.process_name,
        ...processitem
      }));

      setAllProcess(allProcessFormatted);
    } catch (error) {
      console.error("Error getting all process of this company",error);
      getError('Error al obtener los procesos de la compañía');
    } finally {
      setLoading(false);
    }
  }, [companyId, getError, dataHome]);

  return { allProcess,  loadingAllProcesses, fetchAllProcesses };
};