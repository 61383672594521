export const optionsMap = [
  {
    name: 'Mapa de equipo Betesa',
    value: '1',
  },
]

export const HEADERS = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'full_name',
    name: 'Nombre',
  },
  {
    key: 'button',
    name: '',
  },
]

export const HEADERS_ADDED_USERS = [
  {
    key: 'document',
    name: 'Documento',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'full_name',
    name: 'Nombre',
  },
  {
    key: 'button',
    name: '',
  },
]

export const OPTIONS_DATA = [
  {
    name: 'Resumen',
    value: '1',
  },
  {
    name: 'Dominancia',
    value: '2',
  },
  {
    name: 'Talentos',
    value: '3',
  },
  {
    name: 'Gráfico',
    value: '4',
  },
  {
    name: 'Plan',
    value: '5',
  },
]

export const QUADRANTS = {
  fi: 'FI',
  fd: 'FD',
  bi: 'BI',
  bd: 'BD',
}

export const HISTORY_HEADERS = [
  {
    key: 'name',
    name: 'Nombre',
  },
  {
    key: 'button',
    name: '',
  },
]