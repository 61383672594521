import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PercentileData } from './entities'

interface PercentileState {
  info: PercentileData;
  test_id: number;
  evaluated_user_id: number;
}

export const initialState: PercentileState = {
  info :{
    test_name: '',
    user_name: '',
    result:[{
      competenceName: '',
      dimension: '',
      levelName: '',
      competenceId: 0,
      dimensionScore: 0,
      competencePercentage: 0,
      dimensionAverage: 0,
      percentileScoresToFind: [{
        competenceDimensionScoreValidate: 0,
        percentileScore: 0,
        percentile: 0,
      }],
      percentiles: '',
    }]
  },
  test_id: 0,
  evaluated_user_id: 0,
}

const percentilesSlice = createSlice({
  name: 'percentiles',
  initialState,
  reducers: {
    setPercentiles(state, action: PayloadAction<PercentileData>) {
      state.info = action.payload
    },
    setTestId(state, action: PayloadAction<number>) {
      state.test_id = action.payload
    },
    setEvaluatedUserId(state, action: PayloadAction<number>) {
      state.evaluated_user_id = action.payload
    },
    cleanPercentiles(state) {
      state.info = initialState.info
      state.test_id = initialState.test_id
      state.evaluated_user_id = initialState.evaluated_user_id
    },
  },
})

export const { setPercentiles, setTestId, setEvaluatedUserId, cleanPercentiles } = percentilesSlice.actions
export const percentilesReducer = percentilesSlice.reducer
