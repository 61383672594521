import axios from 'lib/axios'

export const postFamilyRol = async ( data: any) => {
  const familyRolCreated = await axios.post(`/role_family/create`, data)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return { ok: false, message: error.message };
  });

  return familyRolCreated;
}

