import styled from 'styled-components'
import WhiteContainer from 'views/components/common/WhiteContainer'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  padding-bottom: 140px;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
`

export const LitleCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 50px 0 10px 0;
`

export const LitleCard = styled(WhiteContainer)`
  gap: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #919eab;

  &:hover {
    transform: scale(1.05);
    transition: ease-in-out 0.3s;
  }
`

interface ITextLitleCard {
  color?: string
}

export const TextLitleCard = styled.span<ITextLitleCard>`
  font-size: 14px;
  font-weight: 700;
  color: ${(props: ITextLitleCard) => props.color || '#1F2124'};
`

export const CountTestAvailables = styled.span`
  font-weight: 700;
  font-size: 32px;
  color: #2d749c;
  text-align: 'center';
  margin: 4px 0px;
`

export const BigCard = styled(WhiteContainer)`
  display: flex;
  padding: 16px;
  width: 31.5%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`

export const MyProcessContainer = styled(WhiteContainer)`
  padding: 10px 20px 10px 20px;
  width: 25%;
  margin-bottom: 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  min-height: 192px;
`

export const BigContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
`
// items processes

export const ItemProcessContainer = styled.div`
  width: 100%;
  font-family: 'Public Sans', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TextItalicProcess = styled.p`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 400;
  color: #707070;
`

export const TextEdit = styled.p`
  color: #2d749c;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Public Sans', sans-serif;
  cursor: pointer;
  margin-left: 10px;
`

interface IBadge {
  bgColor?: string
}

export const Badge = styled.div<IBadge>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  background-color: ${(props: IBadge) => props.bgColor || '#60CC1E'};
  color: #ffffff;
  height: 16px;
  border-radius: 20px;
  padding: 6px 10px;
`

interface IRow {
  width?: string
  margin?: string
}

export const Row = styled.div`
  width: ${(props: IRow) => props.width || '100%'};
  margin: ${(props: IRow) => props.margin || '0'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const ChartContainer = styled.div`
  width: 100%;
  z-index: 2;
`

export const MyProcessBottomContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
