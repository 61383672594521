import { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import jwtDecode from 'jwt-decode'
import {
  Container,
  SectionContainer,
  SectionOne,
  HorizontalDivider,
  SectionTwo,
  SectionTwoPair,
  SectionTwoIndividual,
  CardTitle,
  SectionOneItem,
  SectionOneItemNumber,
  SectionOneItemDescription,
  SectionTwoPairContainer,
  CompanyRow,
  Description,
  ImgContainer,
  SectionTwoIndividualContainer,
  CardDescription,
  ContainerActions,
  TextViewMore,
  EditContainer,
  TextEdit,
} from './styled'
import { Button } from 'views/components/UI/Buttons'
import ViewMoreDiv from 'views/components/UI/ViewMoreDiv'
import Betesa from '../../../components/assets/Betesa.png'
import Competencias from '../../../components/assets/competencias.png'
import InterpretationBetesa from 'assets/icons/InterpretationBetesa'
import EditIcon from 'assets/icons/EditCoin'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { superAdminRoutes } from 'router/routes'
import getAllCompaniesById, { ICompanies } from 'services/companies/getAllCompaniesById'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import getDataDashboard from 'services/common/super-admin-index'
import { useTranslation } from 'react-i18next'

interface IDataDashboard {
  numberOfTests: number
  numberOfCompanies: number
  numberOfCompanyUsers: number
  numberOfResponsibleCompanies: number
  numberOfBetesas: number
  numberOfUninterpretedBetesas: number
  numberOfCompetencies: number
}

const headers = [
  {
    key: 'name',
    name: 'Nombre',
  },
]

const ActionComponent = (item: any) => {
  const { id } = item
  return (
    <ContainerActions>
      <Link to={`/company?companyId=${id}`}>
        <TextViewMore>Ver más</TextViewMore>
      </Link>
      <Link to={`/companies/update?companyId=${id}`}>
        <EditContainer>
          <EditIcon />
          <TextEdit>Editar</TextEdit>
        </EditContainer>
      </Link>
    </ContainerActions>
  )
}

const Index = () => {
  const { t } = useTranslation()
  const { token } = useSelector((state: any) => state.user)
  const decoded: any = jwtDecode(token)
  const [dataDashboard, setDataDashboard] = useState<IDataDashboard | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)

  const [companiesData, setCompaniesData] = useState({
    companies: [] as ICompanies[],
    total_pages: 0,
  })

  const fetchGetUserCompanies = useCallback((page = 1, limit = 10) => {
    getAllCompaniesById(page, limit).then(({ companies, total_pages }) => {
      setCompaniesData({ companies, total_pages })
      setLoading(false)
    })
  }, [])

  const getUserCompanies = useCallback(
    (searchParam: string, page = 1, limit = 5) => {
      setSearchValue(searchParam)
      if (searchParam === '') {
        fetchGetUserCompanies(page, limit)
      } else {
        getAllCompaniesById(page, limit, searchParam).then(({ companies, total_pages }) => {
          setLoading(false)
          setCompaniesData({ companies, total_pages })
        })
      }
    },
    [fetchGetUserCompanies]
  )

  useEffect(() => {
    getDataDashboard(decoded.id).then((res: any) => {
      setDataDashboard(res.data)
    })
  }, [decoded.id, searchValue])

  useEffect(() => {
    if (searchValue.length > 0) {
      getUserCompanies(searchValue)
    }
  }, [searchValue, getUserCompanies])

  useEffect(() => {
    if (searchValue.length <= 0) {
      fetchGetUserCompanies()
    }
  }, [searchValue, fetchGetUserCompanies])

  return (
    <MainLayout>
      <Container>
        {!loading ? (
          <>
            {dataDashboard && (
              <SectionContainer>
                <SectionOne>
                  <SectionOneItem>
                    <SectionOneItemNumber>{dataDashboard.numberOfTests}</SectionOneItemNumber>
                    <SectionOneItemDescription>Total de pruebas enviadas</SectionOneItemDescription>
                  </SectionOneItem>
                  <HorizontalDivider />
                  <SectionOneItem>
                    <SectionOneItemNumber>{dataDashboard.numberOfCompanies}</SectionOneItemNumber>
                    <SectionOneItemDescription>Total compañías vinculadas</SectionOneItemDescription>
                  </SectionOneItem>
                  <HorizontalDivider />
                  <SectionOneItem>
                    <SectionOneItemNumber>{dataDashboard.numberOfCompanyUsers}</SectionOneItemNumber>
                    <SectionOneItemDescription>Total usuarios</SectionOneItemDescription>
                  </SectionOneItem>
                  <HorizontalDivider />
                  <SectionOneItem>
                    <SectionOneItemNumber>{dataDashboard.numberOfResponsibleCompanies}</SectionOneItemNumber>
                    <SectionOneItemDescription>Compañías a mi cargo</SectionOneItemDescription>
                  </SectionOneItem>
                </SectionOne>
                <SectionTwo>
                  <SectionTwoPair style={{ padding: 10 }}>
                    <SectionTwoPairContainer>
                      <ImgContainer style={{ background: '#81ACC4' }}>
                        <img src={Betesa} alt="betesa" style={{ width: 55, height: 55 }} />
                      </ImgContainer>
                      <CardDescription>
                        <CardTitle>{dataDashboard.numberOfBetesas}</CardTitle>
                        <Description>Betesas aplicados</Description>
                      </CardDescription>
                    </SectionTwoPairContainer>
                  </SectionTwoPair>
                  <SectionTwoPair style={{ padding: 10, paddingRight: '10px' }}>
                    <Link to="/interpretation">
                      <SectionTwoPairContainer style={{ cursor: 'pointer' }}>
                        <ImgContainer>
                          <InterpretationBetesa />
                        </ImgContainer>
                        <CardDescription>
                          <CardTitle>{dataDashboard.numberOfUninterpretedBetesas}</CardTitle>
                          <Description>Betesas por interpretar</Description>
                        </CardDescription>
                      </SectionTwoPairContainer>
                    </Link>
                  </SectionTwoPair>
                  <SectionTwoIndividual style={{ padding: 10, paddingRight: '40px' }}>
                    <SectionTwoIndividualContainer>
                      <ImgContainer style={{ background: '#81ACC4' }}>
                        <img src={Competencias} alt="competencias" style={{ width: 45, height: 55, marginLeft: 3 }} />
                      </ImgContainer>
                      <CardDescription>
                        <CardTitle>{dataDashboard.numberOfCompetencies}</CardTitle>
                        <Description>{t('global.common.skillText')} aplicados</Description>
                      </CardDescription>
                    </SectionTwoIndividualContainer>
                  </SectionTwoIndividual>
                </SectionTwo>
              </SectionContainer>
            )}
            <CompanyRow>
              <h2 style={{ fontSize: 32 }}>Compañías a mi cargo</h2>
              <Link to={superAdminRoutes.CreateCompany}>
                <Button type="button">Nueva compañía</Button>
              </Link>
            </CompanyRow>
            <BluesiteTableComponent
              ActionComponent={ActionComponent}
              data={companiesData.companies}
              headers={headers}
              searchBar={true}
              searchLabel="Buscar empresa por:"
              totalPages={1}
              handlePagination={fetchGetUserCompanies}
              onSearch={getUserCompanies}
              handleSearchFunction={() => getUserCompanies(searchValue)}
              currentPage={1}
              limit={5}
              loading={loading}
            />

            <ViewMoreDiv>
              <Link
                to={superAdminRoutes.Companies}
                style={{ color: '#2D749C', fontSize: 14, marginBottom: 30, fontWeight: '700' }}
              >
                {' '}
                Ver más
              </Link>
            </ViewMoreDiv>
          </>
        ) : (
          <ModalLoader isLoading={loading} />
        )}
      </Container>
    </MainLayout>
  )
}

export default Index
