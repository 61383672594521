import React from 'react';
import {MainLayout} from 'views/layouts/';

// components
import {Container, ButtonContainer, InforCardsContainer, ContainerRow, ContainerTestsPerformed } from './styled';
import WhiteContainer from 'views/components/common/WhiteContainer';
import { H2, H5, P, Span } from 'views/components/UI/Text';
import { StrokedButton } from 'views/components/UI/Buttons';
import CardTypeTest from 'views/components/Cards/TestType';

import imageBrain from 'assets/images/brain-icon.png';
import imageBox from 'assets/images/box-icon.png';

const index = () => {

    return (
        <MainLayout>
            <Container>
                <H2>Espacio de evaluado Nombre de Usuario</H2> 
                <ButtonContainer>
                    <StrokedButton>Enviar reporte a usuario</StrokedButton>
                </ButtonContainer>
                <InforCardsContainer>  
                    <WhiteContainer padding='24px 20px 0px 20px' width="45%" float="left">
                        <H5 margin="0px 0px 8px 0px">Datos personales</H5>
                        <ContainerRow>
                            <P  margin="0px 6px 0px 0px" weight={600}>Nombre completo: </P>
                            <Span>Nombre de usuario</Span>
                        </ContainerRow>
                        <ContainerRow>
                            <P  margin="0px 6px 0px 0px" weight={600}>Fecha de nacimiento: </P>
                            <Span>01/02/1994 (28 años)</Span>
                        </ContainerRow>
                        <ContainerRow>
                            <P  margin="0px 6px 0px 0px" weight={600}>Documento de identidad: </P>
                            <Span>112233445566</Span>
                        </ContainerRow>
                        <ContainerRow>
                            <P  margin="0px 6px 0px 0px" weight={600}>Información demografica: </P>
                            <Span>Medellín, Antiquia, CO</Span>
                        </ContainerRow>
                    </WhiteContainer>
                    <WhiteContainer padding='24px 10px 0px 20px' width="45%" height='auto' float='right'>
                        <H5 margin="0px 0px 8px 0px">Procesos a los que pertenece</H5>
                        <ContainerRow>
                            <Span>Nombre de cargo - </Span>
                            <P  margin="0px 0px 0px 8px" weight={600}>Nombre de empresa </P>
                        </ContainerRow>
                        <ContainerRow>
                            <Span>Nombre de cargo - </Span>
                            <P  margin="0px 0px 0px 8px" weight={600}>Nombre de empresa </P>
                        </ContainerRow>
                    </WhiteContainer>
                 </InforCardsContainer>   
                 <ContainerTestsPerformed>
                    <H5 margin="30px 0px 8px 0px">Pruebas relizadas</H5>
                    <ContainerRow margin='18px 0px' justifyContent='space-between'>
                        <CardTypeTest link='/super-admin/evaluated-user-results/'  title='Betesa' image={imageBrain}/>
                        <CardTypeTest link='/super-admin/evaluated-user-results/' title='Competencias'  image={imageBox} />
                    </ContainerRow>
                 </ContainerTestsPerformed>   

            </Container>
        </MainLayout>
    );
}

export default index;