import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5% 2%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60vh;
`;

export const SelectorsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: center;
`;

export const FooterContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
`;


