import { Container } from './styled'
import GroupPage from './components/group-page'
import IdealProfilePage from './components/ideal-profile-page'
import './Spartan-ExtraBold-normal'
import './Lato-Regular-normal'
import './Spartan-SemiBold-normal'
import './Spartan-Regular-normal'
import FirstPage from './components/competencies/first-page/first-page'
import DescriptionPage from './components/competencies/description-page'
import { FC, useMemo } from 'react'
import DimensionDetailPage from './components/competencies/dimension-detail-page'
import { ReportData } from 'views/components/preview-generate-report/store'

interface ReportSchemaProps {
  resume: any
  groups: any
  idealProfile?: any
  generalTextByDimensions: any
  dimensionScore: ReportData
  isAdmin?: boolean
  percentil?: any
  processIdParam?: any
  token?: string
}

export const ReportSchema: FC<ReportSchemaProps> = ({
  resume,
  groups,
  idealProfile,
  generalTextByDimensions,
  dimensionScore,
  isAdmin,
  percentil,
}: any) => {
  const validateIdealProfile = useMemo(() => {
    if (idealProfile && idealProfile.length > 0) {
      return idealProfile[0].competences.some((competence: any) => competence.ideal_profile !== 0)
    }
    return false
  }, [idealProfile])

  const { answer_date: answerDate } = percentil

  const totalPages =
    (dimensionScore && dimensionScore.report_data ? dimensionScore.report_data.length : 0) +
    (idealProfile && idealProfile.length > 1 && validateIdealProfile && isAdmin ? 3 : 2)

  const currentPage = 1

  return (
    <Container>
      <FirstPage answerDate={answerDate} resume={resume} />

      <GroupPage
        groups={groups}
        name={resume?.first_name}
        lastName={resume?.last_name}
        percentil={percentil}
        totalPages={totalPages}
        page={currentPage}
        answerDate={answerDate}
      />

      <DescriptionPage
        currentPage={currentPage}
        totalPages={totalPages}
        resume={resume}
        generalTextByDimensions={generalTextByDimensions}
        answerDate={answerDate}
      />

      <DimensionDetailPage
        dimensionScore={dimensionScore}
        percentil={percentil}
        resume={resume}
        totalPages={totalPages}
        answerDate={answerDate}
      />

      {idealProfile && idealProfile.length > 1 && validateIdealProfile && isAdmin && (
        <IdealProfilePage
          answerDate={answerDate}
          data={idealProfile}
          name={resume?.first_name}
          lastName={resume?.last_name}
          percentil={percentil}
          totalPages={totalPages}
          currentPage={totalPages}
        />
      )}
    </Container>
  )
}
