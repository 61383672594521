import axios from 'axios'

const getRadarData = async (id: number, users: string[], competences: string[]) => {
  const competenceUrl = `${process.env.REACT_APP_MAIN_API}/process/test/competence/result/${id}`

  const data = {
    users,
    competences,
  }

  const results = await axios
    .post(competenceUrl, data)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })

  return results
}

export default getRadarData
