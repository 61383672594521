import { Route, Routes } from 'react-router-dom'
import RoleFamily from '../role-family'

import { ROLE_FAMILY_CREATE_PATH, ROLE_FAMILY_SETTINGS_PATH } from '../constants'
import EditRoleFamily from '../pages/edit-role-family/edit-role-family'
import FormFamilyRol from '../pages/create-role-family/form-page'

const RoleFamilyRoutes = () => {
  return (
    <Routes> 
      <Route index element={<RoleFamily />} /> 
      <Route path={`${ROLE_FAMILY_SETTINGS_PATH}/:id`}>
        <Route index element={<EditRoleFamily />} />
      </Route>
      <Route path={`${ROLE_FAMILY_CREATE_PATH}/*`} >
        <Route index element={<FormFamilyRol />} />
      </Route>
    </Routes >
  )
}

export default RoleFamilyRoutes
