import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CompetenceQuestion } from './entities'

interface CompetencesQuestionsState {
  questions: CompetenceQuestion[]
  competencesAnswers: any
  totalQuestions: number
}

export const initialState: CompetencesQuestionsState = {
  questions: {} as CompetenceQuestion[],
  competencesAnswers: {} as any,
  totalQuestions: 0
}

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setCompetencesQuestions(state, action: PayloadAction<CompetenceQuestion[]>) {
      state.questions = action.payload
    },
    setCompetencesAnswers(state, action: PayloadAction<any>) {
      state.competencesAnswers = action.payload
    },
    setTotalQuestions(state, action: PayloadAction<number>) {
      state.totalQuestions = action.payload;
    },
    clearTotalQuestions(state) {
      state.totalQuestions = 0;
    },
    cleanCompetencesAnswers(state) {
      state.competencesAnswers = {}
    },
  },
})

export const { setCompetencesQuestions, setCompetencesAnswers, cleanCompetencesAnswers, setTotalQuestions,clearTotalQuestions } = questionsSlice.actions
export const competencesQuestionsReducer = questionsSlice.reducer
