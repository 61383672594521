import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'

interface IDatePickerProps {
  label: string
  onChange: (value: string) => void
  useISO8601?: boolean
  defaultValue?: dayjs.Dayjs | string
  disableFullWidth?: boolean
  value?: dayjs.Dayjs | string
  clearDate?: boolean
  format?: string
}

export default function DatePicker(props: IDatePickerProps) {
  const { label, onChange, useISO8601, defaultValue, disableFullWidth, value, clearDate, format } = props
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | string | null>(
    defaultValue ? dayjs(defaultValue) : null
  )

  useEffect(() => {
    if (value) {
      setSelectedDate(value)
    } else {
      if (value === '') {
        setSelectedDate(null)
      }
    }

    if (clearDate === true) {
      setSelectedDate(null)
    }
  }, [value, clearDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <MuiDatePicker
        label={label || 'Inserte label'}
        value={selectedDate ? dayjs(selectedDate) : null}
        inputFormat={format || 'MM/DD/YYYY'}
        onChange={(newValue) => {
          if (useISO8601 === true) {
            const date: any = newValue ? newValue.format() : ''
            onChange(date)
          } else {
            const date: any = newValue ? newValue.format('MM/DD/YYYY') : ''
            onChange(date)
          }
          setSelectedDate(newValue)
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'blue',
                },
              },
            }}
            {...params}
            fullWidth={!disableFullWidth}
          />
        )}
      />
    </LocalizationProvider>
  )
}

// import { useState } from 'react';
// import dayjs from 'dayjs';
// import 'dayjs/locale/es';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

// interface IDatePickerProps {
//   label: string;
//   onChange: (value: string) => void;
//   useISO8601?: boolean;
//   defaultValue?: dayjs.Dayjs;
//   disableFullWidth?: boolean;
// }

// export default function DatePicker(props: IDatePickerProps) {
//   const { label, onChange, useISO8601, defaultValue, disableFullWidth } = props;

//   const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | string | null>(
//     defaultValue ? dayjs(defaultValue, 'MM/DD/YYYY') : null
//   );

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
//       <MuiDatePicker
//         label={label || 'Inserte label'}
//         value={selectedDate ? dayjs(selectedDate) : null}
//         inputFormat="MM/DD/YYYY"
//         onChange={(newValue) => {
//           if (useISO8601 === true) {
//             const date: any = newValue ? newValue.format('') : ''
//             onChange(date)
//           } else {
//             const date: any = newValue ? newValue.format('MM/DD/YYYY') : ''
//             onChange(date)
//           }
//           setSelectedDate(newValue);
//         }}
//         renderInput={(params) => (
//           <TextField
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'transparent',
//                   boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
//                   borderRadius: '10px',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: 'transparent',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: 'blue',
//                 },
//               },
//             }}
//             {...params}
//             fullWidth={!disableFullWidth}
//           />
//         )}
//       />
//     </LocalizationProvider>
//   );
// }
