import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import Checkbox from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckboxChecked from '@mui/icons-material/CheckBox'

interface IProps {
  label: string
  tooltipText?: string
  defaultValue?: string
  value?: string
  checked?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void | any
  name?: string
  square?: boolean
}

const InputRadio: React.FC<IProps> = (props) => {
  const { label, tooltipText, defaultValue, value, checked, onChange, name, square } = props

  // Handle change event
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const BlueTooltip = styled(({ className, ...Tprops }: TooltipProps) => (
    <Tooltip {...Tprops} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2D749C',
      color: 'white',
      boxShadow: theme.shadows[2],
      fontSize: 14,
    },
  }))

  return (
    <FormControl style={{ marginRight: '10px' }}>
      <RadioGroup
        aria-label={label}
        defaultValue={defaultValue}
        name={name || 'radio-buttons-group'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FormControlLabel
          value={value}
          control={
            <Radio
              checkedIcon={square ? <CheckboxChecked style={{ color: '#1976d2' }} /> : undefined}
              icon={square ? <Checkbox /> : undefined}
              checked={checked}
              onChange={handleChange}
              value={value}
              name={name || 'radio-buttons'}
              inputProps={{ 'aria-label': label }}
            />
          }
          label={label || 'Insert label'}
        />
        {tooltipText && (
          <BlueTooltip title={tooltipText} placement="top">
            <InfoIcon
              style={{ color: '#2BB5D4', width: '14px', height: '14px', cursor: 'pointer', marginLeft: '-10px' }}
            />
          </BlueTooltip>
        )}
      </RadioGroup>
    </FormControl>
  )
}

export default InputRadio
