export const MOCK_ENDPOINT = {
    status: 'OK',
    data: {
      talents: [
        {
            id: 1,
            name: "Analisis estructural",
            description: "Esta es una descripcion de prueba para analisis estructural.",
            actions: [
              { id: 1, name: "Realizar un análisis de carga en la estructura." },
              { id: 2, name: "Identificar los puntos de mayor tensión en la estructura." },
              { id: 3, name: "Calcular la resistencia de los materiales utilizados en la estructura." },
              { id: 4, name: "Evaluar la estabilidad sísmica de la estructura." },
              { id: 5, name: "Identificar los puntos débiles de la estructura y proponer soluciones." },
              { id: 6, name: "Realizar pruebas de carga para validar la resistencia de la estructura." },
              { id: 7, name: "Analizar los resultados de las pruebas y hacer recomendaciones." },
              { id: 8, name: "Diseñar estructuras más resistentes y estables." },
              { id: 9, name: "Identificar la capacidad de carga máxima de la estructura." },
              { id: 10, name: "Determinar las cargas de diseño que deben soportar las estructuras." },
              { id: 11, name: "Evaluar el comportamiento de las estructuras ante cargas dinámicas." },
              { id: 12, name: "Realizar estudios de patología de las estructuras para determinar su estado de deterioro." }
            ]
          },
          {
            id: 2,
            name: "Autoconocimiento",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.",
            actions: [
              { id: 1, name: "Reflexionar sobre los valores personales y la identidad." },
              { id: 2, name: "Identificar las emociones y pensamientos que influyen en el comportamiento." },
              { id: 3, name: "Evaluar las fortalezas y debilidades personales." },
              { id: 4, name: "Buscar oportunidades para mejorar y desarrollar habilidades." },
              { id: 5, name: "Practicar la autoaceptación y el amor propio." },
              { id: 6, name: "Aprender a establecer límites y expresar las necesidades de manera efectiva." },
              { id: 7, name: "Desarrollar habilidades sociales y de comunicación." },
              { id: 8, name: "Buscar apoyo y retroalimentación de amigos y familiares." },
              { id: 9, name: "Identificar las situaciones que generan estrés o ansiedad." },
              { id: 10, name: "Desarrollar habilidades para manejar el estrés y la ansiedad." },
              { id: 11, name: "Aprender a reconocer y regular las emociones." },
              { id: 12, name: "Establecer metas personales y trabajar para alcanzarlas." }
            ]
          },
          {
            id: 3,
            name: "Capacidad numérica",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.",
            actions: [
              { id: 1, name: "Realizar cálculos aritméticos y algebraicos con rapidez y precisión." },
              { id: 2, name: "Interpretar y analizar datos numéricos para tomar decisiones informadas." },
              { id: 3, name: "Resolver problemas matemáticos y aplicar fórmulas para encontrar soluciones." },
              { id: 4, name: "Utilizar herramientas informáticas para realizar cálculos y análisis numéricos." },
              { id: 5, name: "Realizar estimaciones y aproximaciones de números y medidas." },
              { id: 6, name: "Comprender y aplicar conceptos matemáticos avanzados, como cálculo y estadística." },
              { id: 7, name: "Desarrollar y utilizar modelos matemáticos para simular situaciones reales." },
              { id: 8, name: "Realizar análisis de tendencias y proyecciones numéricas." },
              { id: 9, name: "Utilizar herramientas de visualización de datos para presentar información numérica de manera clara." },
              { id: 10, name: "Realizar análisis de riesgo financiero y económico." },
              { id: 11, name: "Interpretar y analizar gráficos y tablas numéricas." },
              { id: 12, name: "Aplicar conceptos matemáticos en diferentes áreas, como ciencias naturales, ingeniería y economía." }
            ]
          },
          {
            id: 4,
            name: "Toma de decisiones",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.",
            actions: [
              { id: 1, name: "Identificar el problema y definir el objetivo de la toma de decisiones." },
              { id: 2, name: "Recopilar información relevante y analizarla de manera objetiva." },
              { id: 3, name: "Evaluar las posibles alternativas y los riesgos asociados a cada una." },
              { id: 4, name: "Seleccionar la mejor alternativa y desarrollar un plan de acción." },
              { id: 5, name: "Implementar el plan de acción y realizar un seguimiento de los resultados." },
              { id: 6, name: "Evaluar el éxito del plan de acción y hacer ajustes si es necesario." },
              { id: 7, name: "Buscar retroalimentación y opiniones de otros para tener una perspectiva diferente." },
              { id: 8, name: "Tomar decisiones en situaciones de incertidumbre y ambigüedad." },
              { id: 9, name: "Desarrollar habilidades de resolución de conflictos para tomar decisiones en situaciones difíciles." },
              { id: 10, name: "Considerar las consecuencias a largo plazo de las decisiones." },
              { id: 11, name: "Mantener la integridad y ética al tomar decisiones importantes." },
              { id: 12, name: "Aprender de errores pasados para tomar mejores decisiones en el futuro." },
            ]
          },
          {
            id: 5,
            name: "Comunicación efectiva",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, incidunt provident neque optio corrupti delectus excepturi.",
            actions: [
              { id: 1, name: "Escuchar activamente y comprender los puntos de vista de los demás." },
              { id: 2, name: "Expresar ideas de manera clara y concisa." },
              { id: 3, name: "Utilizar el lenguaje corporal para reforzar el mensaje y la postura." },
              { id: 4, name: "Adaptar el estilo de comunicación según la audiencia y el contexto." },
              { id: 5, name: "Utilizar preguntas efectivas para obtener información y aclarar dudas." },
              { id: 6, name: "Evitar la comunicación defensiva y ser abierto a la retroalimentación." },
              { id: 7, name: "Utilizar ejemplos y analogías para ilustrar conceptos complejos." },
              { id: 8, name: "Utilizar el humor y la empatía para conectar con los demás." },
              { id: 9, name: "Ser claro y preciso al dar instrucciones y delegar tareas." },
              { id: 10, name: "Utilizar herramientas de comunicación como correo electrónico y chat de manera efectiva." },
              { id: 11, name: "Ser consciente de las barreras de comunicación y buscar maneras de superarlas." },
              { id: 12, name: "Dar y recibir retroalimentación de manera constructiva para mejorar la comunicación." }
            ]
          },
      ],
    }
  }