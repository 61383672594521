import { useEffect, useState } from 'react'

import { Page, Container, ContainerScheme, StaticGraphicDiv, P } from '../../styled'

import RotatedImage from '../../rotatedImage'
import StaticGraphic from 'views/components/assets/images/staticGraphic.png'
import { useNameQuadrant } from 'hooks/useNameQuadrant'
import '../../Spartan-ExtraBold-normal'
import '../../Lato-Regular-normal'
import '../../Spartan-SemiBold-normal'
import '../../Spartan-Regular-normal'
import useCompanyLogo from 'hooks/cobranding/get-company-logo/get-company-logo-hook'
import { DescriptionText, Item, Text, Title, WrapperContent, WrapperList, Subtitle, DominanceText } from './styled'
import Headpage from '../header'
import FooterPage from '../footer-page'
import { useTranslation } from 'react-i18next'

const DevelopmentPlanSchema: any = ({ developmentPlan, type, processId }: any) => {
  const { t } = useTranslation()
  const { getCompanyLogo } = useCompanyLogo()
  const { actions } = developmentPlan
  const userInfo = developmentPlan?.user_info
  const names = actions.map((action: any) => action?.name)
  const parsedQuadrant = useNameQuadrant(userInfo?.quadrant)
  const [hasCalledGetLogo, setHasCalledGetLogo] = useState(false);

  const totalPages = actions.length

  useEffect(() => {
    setTimeout(() => {
      if (!hasCalledGetLogo && processId) {
        setHasCalledGetLogo(true);
        getCompanyLogo({ processId: processId })
      }
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyLogo, processId, hasCalledGetLogo])

  return (
    <Container>
      <Page>
        <Headpage isBetesa={type === 'betesa' ? true : false} />
        <WrapperContent>
          <Title>
            {type === 'betesa' ? 'Plan de Desarrollo' : `Plan de Desarrollo de ${t('global.common.skillText')}`}
          </Title>
          {type === 'betesa' ? (
            <>
              <ContainerScheme style={{ position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Text>Cuadrante elegido como foco de desarrollo:</Text>
                  <DominanceText>{parsedQuadrant}</DominanceText>
                </div>
                <StaticGraphicDiv>
                  <RotatedImage src={StaticGraphic} category={userInfo.quadrant} />
                </StaticGraphicDiv>
              </ContainerScheme>
            </>
          ) : null}
          <Text>{type === 'betesa' ? 'Talentos a desarrollar:' : `${t('global.common.skillText')} a desarrollar:`}</Text>
          <WrapperList>
            {names.map((name: string, nameIndex: any) => (
              <Item key={nameIndex}>
                <P>{name}</P>
              </Item>
            ))}
          </WrapperList>
          <Text>
            Para desarrollar estas competencias, te sugerimos poner en práctica las siguientes acciones específicas:
          </Text>
          <Subtitle>{actions[0].name}</Subtitle>
          <DescriptionText>{actions[0].description}</DescriptionText>
          <WrapperList style={{ boxSizing: 'border-box' }}>
            {actions[0].actionPlan.map((item: any) => (
              <Item key={item.id}>
                <P>{item.description}</P>
              </Item>
            ))}
          </WrapperList>
        </WrapperContent>
        <FooterPage page={1} name={userInfo.first_name} lastName={userInfo.last_name} totalPages={totalPages} />
      </Page>
      {actions.length > 1 &&
        actions.slice(1).map((action: any, index: any) => (
          <Page key={`actions-${index}-${actions}`}>
            <Headpage isBetesa={type === 'betesa' ? true : false} />
            <WrapperContent>
              <Subtitle>{action.name}</Subtitle>
              <DescriptionText>{action.description}</DescriptionText>
              <WrapperList>
                {action.actionPlan.map((item: any) => (
                  <Item key={item.id}>
                    <P>{item.description}</P>
                  </Item>
                ))}
              </WrapperList>
            </WrapperContent>
            <FooterPage
              page={index + 2}
              name={userInfo.first_name}
              lastName={userInfo.last_name}
              totalPages={totalPages}
            />
          </Page>
        ))}
    </Container>
  )
}

export default DevelopmentPlanSchema
