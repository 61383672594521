import axios from 'lib/axios'
import { USER_DETAIL_PROCESS } from '../constants'

export const fetchGetUserProcessAction = async (userId: number, companyId: number) => {

  const PATH = `${USER_DETAIL_PROCESS}${userId}`

  if (companyId) {
    PATH.concat(`?company_id=${companyId}`)
  }

  try {
    const response = await axios.get(`${USER_DETAIL_PROCESS}${userId}`)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}
