import React, { useState, useEffect, useRef, FC, SetStateAction } from 'react'

import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import SearchBar from 'views/components/Searchbar'
import { P } from 'views/components/UI/Text'
import Loader from 'views/components/UI/Loader'
import { StrokedButton } from '../Buttons'
import Tooltip from 'views/components/Tooltip'
import { useSelectedItems } from '../../../screens/Platform-administrator/EvaluatedSpace/context/context'
import { downloadHeader } from 'views/screens/Platform-administrator/EvaluatedSpace/constants'
import TextField from '@mui/material/TextField'
import {
  TableContainer,
  Table,
  TableHeaders,
  TableBody,
  PaginationDiv,
  TableRow,
  LoaderContainer,
  ActionsCell,
} from './styled'
import { VALIDATE_EMAIL_REGEX } from 'constants/regex.constants'

const ACTIVE_STATUS = 1

type RowDataType = {
  id: string | number
  [key: string]: any
}

type TextWidthRefsType = {
  [key: string]: HTMLSpanElement | null
}

type InputWidthType = {
  [key: string]: string | null
}

interface BluesiteTableProps {
  data: any[]
  getData?: any
  loading?: boolean
  headers: any[]
  sorting?: (a: any, b: any) => number
  searchBar: boolean
  searchLabel: string
  searchFields?: string[]
  labelInput?: string
  viewUrl?: any
  updateUrl?: any
  actions?: any[]
  questionnaireId?: any
  ActionComponent?: React.FC<any>
  extraDataAction?: any
  totalPages?: any
  currentPage?: number
  handlePagination?: (value: number) => void
  limit?: any
  styleSearchBar?: React.CSSProperties
  setNewPage?: (value: number) => void
  page?: number
  setSearchParam?: (value: string) => void
  handleSearchFunction?: (value: string) => void
  checks?: boolean
  hideMainCheckbox?: boolean
  selectedItems?: Set<number>
  setSelectedItems?: React.Dispatch<SetStateAction<Set<number>>>
  setOpenModal?: (value: boolean) => void
  fetchSearchData?: (searchValue: string) => void
  isAlignLeft?: boolean
  onSearch?: (value: string) => void
  renderAdditionalColumn?: (rowData?: any) => React.ReactNode
  editable?: boolean
  onSave?: (rowIndex: number, newData: any) => void
}

const BluesiteTable: FC<BluesiteTableProps> = ({
  ActionComponent,
  data,
  searchBar,
  headers,
  searchLabel,
  labelInput,
  extraDataAction,
  currentPage,
  handlePagination = () => {},
  totalPages,
  styleSearchBar,
  loading = false,
  checks,
  hideMainCheckbox,
  selectedItems,
  setSelectedItems,
  setOpenModal,
  setSearchParam,
  onSearch,
  fetchSearchData,
  isAlignLeft,
  renderAdditionalColumn,
  editable = false,
  onSave,
}) => {
  const [sortedContent, setSortedContent] = useState<any[]>(data?.length > 0 ? data : [])
  const [selectedPage, setSelected] = useState(currentPage)
  const [order, setOrder] = useState('ASC')
  const [showIcon, setShowIcon] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState(data)
  const [editingRow, setEditingRow] = useState<RowDataType | null>(null)
  const [inputWidths, setInputWidths] = useState<InputWidthType>({})
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

  const textWidthRefs = useRef<TextWidthRefsType>({})

  const headerCheckboxRef = useRef(null)
  const selectedItemsData = useSelectedItems()
  const isDisabled = selectedItemsData.size > 1

  useEffect(() => {
    if (currentPage !== selectedPage) {
      handlePagination(Number(currentPage))
      setSelected(currentPage)
    }
  }, [currentPage, handlePagination, selectedPage])

  const sorting = (col: string) => {
    if (order === 'ASC') {
      const sorted = [...data].sort((a, b) => (a[col] > b[col] ? 1 : -1))
      setSortedContent(sorted)
      setShowIcon(true)
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...data].sort((a, b) => (a[col] < b[col] ? 1 : -1))
      setSortedContent(sorted)
      setShowIcon(true)
      setOrder('ASC')
    }
  }
  const sortingIcon = () => {
    if (order === 'ASC') {
      return <ArrowUpwardIcon style={{ color: '#2D749C', fontSize: 16 }} />
    } else {
      return <ArrowDownwardIcon style={{ color: '#2D749C', fontSize: 16 }} />
    }
  }

  const handlePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePagination(value)
    setSelected(value)
  }

  const handleSearch = async () => {
    const searchValue = search.trim().toLowerCase()
    if (fetchSearchData) {
      await fetchSearchData(searchValue)
    } else {
      setFilteredData(data)
    }
    if (searchValue.length === 0) {
      setSearch('')
      setSearchParam?.('')
      setFilteredData(data)
      return
    }
    setSearchParam?.(searchValue)
  }

  const handleSelectItem = (id: number) => {
    if (!setSelectedItems) {
      return
    }
    const newSelectedItems = new Set(selectedItems)
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id)
    } else {
      newSelectedItems.add(id)
    }
    setSelectedItems(newSelectedItems)
  }

  const handleSelectAll = (event: any) => {
    if (!setSelectedItems) {
      return
    }
    const checked = event.target.checked
    const newSelectedItems: Set<number> = new Set()

    if (checked) {
      const itemsToSelect = search.length < 1 ? sortedContent : filteredData

      const itemsToSelectFiltered = itemsToSelect.filter((item: any) => {
        if (item.status === ACTIVE_STATUS) {
          return item
        }
        return item.status === itemsToSelect[0].status
      })
      itemsToSelectFiltered.forEach((item) => newSelectedItems.add(item.id))
    }

    setSelectedItems(newSelectedItems)
  }

  useEffect(() => {
    const handleSearch2 = (value: any, fields: string[] = ['name']) => {
      const searchValue = value.trim().toLowerCase()

      const filtered = data.filter((term: any) =>
        fields.some((field) => term[field] && term[field].toLowerCase().includes(searchValue))
      )

      setFilteredData(filtered)
    }

    if (data?.length > 0) {
      handleSearch2(search)
    }
  }, [data, search])

  useEffect(() => {
    if (data?.length > 0) {
      setSortedContent(data)
    }
    if (search.length > 0) {
      setFilteredData(data)
    }
  }, [data, search])

  // #region EDITABLE FIELDS

  useEffect(() => {
    const updateInputWidths = () => {
      const newInputWidths: InputWidthType = {}
      data?.forEach((item) => {
        headers.forEach((header) => {
          const key = `${item.id}-${header.key}`
          const textElement = textWidthRefs.current[key]
          if (textElement) {
            const textWidth = textElement.offsetWidth + 20
            newInputWidths[key] = `${textWidth + 2}px`
          }
        })
      })
      setInputWidths(newInputWidths)
    }
    updateInputWidths()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingRow])

  const handleEdit = (row: RowDataType) => {
    setEditingRow({ ...row })
  }

  const handleChange = (key: string, value: string) => {
    let newValue = value

    if (key === 'document') {
      newValue = newValue.replace(/\D/g, '')
    } else if (key === 'email') {
      newValue = newValue.replace(/\s+/g, '')
    } else if (key === 'first_name' || key === 'last_name') {
      newValue = value.replace(/^[ ]+|[0-9]+/g, '')
    } else {
      newValue = newValue.replace(/^\s+/g, '')
    }

    setEditingRow((prevRow: any) => ({ ...prevRow, [key]: newValue }))
  }

  const isValidEmail = (email: string) => {
    if (!email) return false
    const emailRegex = VALIDATE_EMAIL_REGEX
    return emailRegex.test(email)
  }

  const validateRow = (row: RowDataType): boolean => {
    const newRowErrors: { [key: string]: boolean } = {}
    let isValid = true

    headers.forEach((header) => {
      if (header.editable) {
        const value = row[header.key]
        const validator = header.validator ? isValidEmail(value) : !!value

        if (!validator) {
          newRowErrors[header.key] = true
          isValid = false
        }
      }
    })
    setErrors(newRowErrors)
    return isValid
  }

  const handleSave = (rowIndex: number) => {
    if (editingRow && validateRow(editingRow) && onSave) {
      onSave(rowIndex, editingRow)
      setEditingRow(null)
      setErrors({})
    }
  }

  const handleCancel = () => {
    setEditingRow(null)
    setErrors({})
  }

  // #region

  return (
    <>
      {searchBar === true ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '5px',
            marginBottom: '15px',
            alignItems: 'center',
          }}
        >
          <P style={{ display: isAlignLeft === false ? 'flex' : 'none' }} color="#1F2124" fontSize="22px">
            {searchLabel}
          </P>
          <div style={styleSearchBar}>
            <SearchBar
              label={labelInput || 'Buscar'}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                onSearch?.(e.target.value)
              }}
              onClick={handleSearch}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              style={{ margin: '0px 0px 10px 0px' }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <TableContainer>
        {!loading ? (
          <Table>
            <TableHeaders>
              <tr>
                {checks === true ? (
                  <th>
                    {!hideMainCheckbox && (
                      <input
                        type="checkbox"
                        ref={headerCheckboxRef}
                        onChange={handleSelectAll}
                        checked={selectedItems && data ? selectedItems.size === data.length && data.length > 0 : false}
                      />
                    )}
                  </th>
                ) : null}
                {headers.map((header, index) => (
                  <th key={`${header.key}-${index}`} onClick={() => sorting(header.key)}>
                    {header.renderer === 'StrokedButton' ? (
                      <StrokedButton
                        onClick={() => {
                          if (setOpenModal) {
                            setOpenModal(true)
                          }
                        }}
                      >
                        {header.name}
                      </StrokedButton>
                    ) : (
                      <>
                        {header.name}
                        {showIcon === false ? '' : sortingIcon()}
                      </>
                    )}
                  </th>
                ))}
                {selectedItems &&
                  selectedItems.size > 1 &&
                  downloadHeader.key === 'download' &&
                  isDisabled &&
                  renderAdditionalColumn &&
                  renderAdditionalColumn()}
              </tr>
            </TableHeaders>

            {data?.length > 0 ? (
              <>
                <TableBody>
                  {data?.length > 0 && sortedContent.length > 0 && search.length < 1
                    ? sortedContent?.map((item, index) => {
                        const isEditing = editingRow && editingRow.id === item.id
                        return (
                          <TableRow key={`${item.id}-${index}-${item?.name}`}>
                            {checks === true ? (
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedItems ? selectedItems.has(item.id) : false}
                                  onChange={() => handleSelectItem(item.id)}
                                />
                              </td>
                            ) : null}
                            {headers.map((header) => {
                              const key = `${item.id}-${header.key}`
                              const isEditable = header.editable && editable
                              return (
                                <td key={key} style={{ fontWeight: `${item.isBold ? 'bold' : 'normal'}` }}>
                                  {header.showDescriptionTooltip ? (
                                    <>
                                      {item[header.key]}{' '}
                                      <Tooltip
                                        style={{ background: '#2D749C' }}
                                        tooltipText={item[header.description]}
                                      />
                                    </>
                                  ) : isEditable && isEditing ? (
                                    <>
                                      <TextField
                                        size="small"
                                        key={key}
                                        error={!!errors[header.key]}
                                        style={{
                                          width: inputWidths[key] || '150px',
                                          minWidth: '150px',
                                          maxWidth: '270px',
                                        }}
                                        value={editingRow && isEditing ? editingRow[header.key] : item[header.key]}
                                        onChange={(e) => handleChange(header.key, e.target.value)}
                                      />
                                      <span
                                        ref={(el) => (textWidthRefs.current[key] = el)}
                                        style={{
                                          position: 'absolute',
                                          visibility: 'hidden',
                                          height: 'auto',
                                          width: 'auto',
                                          whiteSpace: 'nowrap',
                                          fontFamily: 'inherit',
                                          fontSize: 'inherit',
                                          letterSpacing: 'inherit',
                                          padding: 'inherit',
                                        }}
                                      >
                                        {editingRow && isEditing ? editingRow[header.key] : item[header.key]}
                                      </span>
                                    </>
                                  ) : (
                                    item[header.key]
                                  )}
                                </td>
                              )
                            })}
                            {editable && (
                              <td>
                                {item.edit &&
                                  item.edit(
                                    isEditing,
                                    () => handleEdit(item),
                                    () => handleSave(index),
                                    handleCancel,
                                    index
                                  )}
                              </td>
                            )}
                            <ActionsCell>
                              {ActionComponent && <ActionComponent {...item} {...extraDataAction} />}
                            </ActionsCell>
                          </TableRow>
                        )
                      })
                    : filteredData?.map((item, index) => {
                        const isEditing = editingRow && editingRow.id === item.id
                        return (
                          <TableRow key={`${item.id}-${index}`}>
                            {checks === true ? (
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedItems ? selectedItems.has(item.id) : false}
                                  onChange={() => handleSelectItem(item.id)}
                                />
                              </td>
                            ) : null}
                            {headers.map((header) => {
                              const key = `${item.id}-${header.key}`
                              const isEditable = header.editable && editable
                              return (
                                <td key={key}>
                                  {isEditable && isEditing ? (
                                    <>
                                      <TextField
                                        size="small"
                                        error={!!errors[header.key]}
                                        style={{
                                          width: inputWidths[key] || '150px',
                                          minWidth: '150px',
                                          maxWidth: '270px',
                                        }}
                                        value={editingRow && isEditing ? editingRow[header.key] : item[header.key]}
                                        onChange={(e) => handleChange(header.key, e.target.value)}
                                      />
                                      <span
                                        ref={(el) => (textWidthRefs.current[key] = el)}
                                        style={{
                                          position: 'absolute',
                                          visibility: 'hidden',
                                          height: 'auto',
                                          width: 'auto',
                                          whiteSpace: 'nowrap',
                                          fontFamily: 'inherit',
                                          fontSize: 'inherit',
                                          letterSpacing: 'inherit',
                                          padding: 'inherit',
                                        }}
                                      >
                                        {editingRow && isEditing ? editingRow[header.key] : item[header.key]}
                                      </span>
                                    </>
                                  ) : (
                                    item[header.key]
                                  )}
                                </td>
                              )
                            })}
                            {editable && (
                              <td>
                                {item.edit &&
                                  item.edit(isEditing, () => handleEdit(item), handleSave, handleCancel, index)}
                              </td>
                            )}

                            <ActionsCell>
                              {ActionComponent && <ActionComponent {...item} {...extraDataAction} />}
                            </ActionsCell>
                          </TableRow>
                        )
                      })}
                </TableBody>
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <td>No hay datos para mostrar</td>
                </TableRow>
              </TableBody>
            )}
          </Table>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {totalPages > 1 && !loading ? (
          <PaginationDiv>
            <Stack spacing={2}>
              <Pagination count={totalPages} page={selectedPage} onChange={handlePaginate} color="primary" />
            </Stack>
          </PaginationDiv>
        ) : (
          <></>
        )}
      </TableContainer>
    </>
  )
}

export default BluesiteTable
