import axios from 'axios'


interface Response {
  status: string
  data: any
}


export const Resume = async (token: string, userTestId: number) => {

  const apiTest = `${process.env.REACT_APP_MAIN_API}/competence_result/results/resume/${userTestId}`

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }


  // post get
  const response: Response = await axios.get(apiTest, config).then((res) => {
    return res.data
  })

  return response
}
