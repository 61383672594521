import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNotification } from 'lib/context/notification.context'
import { evaluatedUserIdSelector, testIdSelector } from 'views/screens/super-admin/percentiles/store'
import {
  fetchGetCompetenceQuestions,
  fetchGetCompetenceResults,
  fetchSaveCompetenceQuestions,
  fetchUpdateCompetenceFinished,
} from '../services/fetch-questions-betesa'
import { AnswerQuestion, setCompetencesQuestions, setTotalQuestions } from '../pages/QuestionsSkills/store'
import { userTestIdSelector } from 'ducks/evaluatedUser'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'constants/global.constants'
import { setLoading } from 'ducks/ui'
import { fetchGeneratePDF } from '../pages/generate-reports-pdf/services/fetch-generate-report'
import { setShowChapterInfo, setTestStarted } from '../store'

const useQuestions = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const testId = useSelector(testIdSelector)
  const userTestId = useSelector(userTestIdSelector)
  const userEvaluatedId = useSelector(evaluatedUserIdSelector)
  const { token, userId } = useSelector((state: any) => state.user)
  const { getError, getSuccess } = useNotification()
  const { role } = useSelector((state: any) => state.auth)

  const getCompetencesQuestions = useCallback(async () => {
    try {
      const questions = await fetchGetCompetenceQuestions(testId)
      const { results } = await fetchGetCompetenceResults(userTestId)
      const answeredMap = new Set(Object.keys(results).map(Number))
      const sortedArray = [...questions].sort((a, b) => {
        const aAnswered = answeredMap.has(a.id)
        const bAnswered = answeredMap.has(b.id)
        return aAnswered === bAnswered ? 0 : aAnswered ? -1 : 1
      })
      dispatch(setCompetencesQuestions(sortedArray))
    } catch {
      getError('No hemos podido cargar las preguntas')
    }
  }, [dispatch, getError, testId, userTestId])

  const getTotalQuestions = useCallback(async () => {
    try {
      const [questionsTotal] = await Promise.all([fetchGetCompetenceQuestions(testId)])
      dispatch(setTotalQuestions(questionsTotal.length))
    } catch {
      getError('No hemos podido cargar las preguntas')
    }
  }, [dispatch, getError, testId])

  const saveCompetencesQuestions = useCallback(
    async (questionId: number, score: number) => {
      const answerQuestion: AnswerQuestion = {
        user_test_id: userTestId,
        question_id: questionId,
        score,
      }

      try {
        const response = await fetchSaveCompetenceQuestions(answerQuestion)
        return response
      } catch (error) {
        getError('Tus respuestas no se están guardando, por favor contacta soporte.')
      }
    },
    [userTestId, getError]
  )

  const handleGeneratePdf = useCallback(
    async (type: string, isAdminReport: boolean, isReinterpretation: boolean, navidateUrl?: boolean) => {
      const userIdForReport = role === 'user' ? userId : String(userEvaluatedId)
      if (userIdForReport && testId) {
        try {
          fetchGeneratePDF({
            userId: userIdForReport,
            testId: String(testId),
            userTestId,
            token,
            type,
            isAdminReport,
            isReinterpretation,
          })
          if (!navidateUrl) {
            navigate(Routes.home)
          }
          dispatch(setLoading(false))
        } catch (error: any) {
          getError(error.message)
        }
      }
    },
    [navigate, token, testId, getError, dispatch, userTestId, userEvaluatedId, role, userId]
  )

  const finishedCompetencesTest = useCallback(async () => {
    try {
      const promises = [
        fetchUpdateCompetenceFinished(userTestId),
        getSuccess('Prueba enviada con éxito. Estamos generando los resultados, espéranos unos segundos'),
      ]
      const [response] = await Promise.all(promises)
      return response
    } catch {
      getError("No hemos podido enviar tu prueba, por favor contacta soporte.")
    }
  }, [getError, getSuccess, userTestId])

  const handleStartTest = useCallback(() => {
    dispatch(setTestStarted(true))
    dispatch(setShowChapterInfo(false))
  }, [dispatch])

  return {
    getCompetencesQuestions,
    saveCompetencesQuestions,
    finishedCompetencesTest,
    handleGeneratePdf,
    handleStartTest,
    getTotalQuestions,
  }
}

export default useQuestions
