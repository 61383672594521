import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'

import { Container, InputsContainer, InputContainer } from './../new/styled'
import Input from 'views/components/UI/TextInput'
import Select from 'views/components/UI/Select'
import DatePicker from 'views/components/UI/Datepicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Button } from 'views/components/UI/Buttons'
import { generalDataSelector } from 'ducks/auth'

import { updateInventory, fetchGetInventoryAction } from './services/services-components'
import { useNotification } from 'lib/context/notification.context'
import { IBodyData } from './store/entitites'
import { BACK_PAGE } from '../constants'
import { BtnGoBack, ContainerRow } from 'views/components/Modal/styled'

function Index() {
  const navigate = useNavigate()
  const { getWarning, getSuccess } = useNotification()
  const { data } = useSelector(generalDataSelector)
  const [companyName, setCompanyName] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [credits, setCredits] = useState('')
  const [testUsed, setTestsUsed] = useState('')
  const [type, setType] = useState('')
  const [optionsType, setOptionsType] = useState<any>([])
  const [params, setParams] = useState<any>({})
  const [idCompany, setIdCompany] = useState<number | null>(null)

  dayjs.extend(utc)

  useEffect(() => {
    if (data.hasOwnProperty('subscription')) {
      const options = _.map(data.subscription, (item: any) => ({
        value: item.id,
        label: item.name,
      }))

      const defaultType = data.subscription.length > 0 ? data.subscription[0].id : ''
      setType(type || defaultType)
      setOptionsType(options)
    } else {
      setOptionsType([])
    }

    const searchParams = new URLSearchParams(window.location.search)
    setParams(Object.fromEntries(searchParams))
  }, [data, type])

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      const companyId = parseInt(params.companyId)
      setIdCompany(companyId)
    }
  }, [params])

  useEffect(() => {
    const handleFetchError = () => {
      getWarning('Error al cargar el inventario')
    }

    if (!idCompany) return
    fetchGetInventoryAction(Number(idCompany))
      .then((response) => {
        setCompanyName(response?.name ?? '')
        setExpirationDate(response?.expiration_date ?? '')
        setCredits(response?.credits?.toString() ?? '')
        setTestsUsed(response?.tests_used?.toString() ?? '')
        setType(response?.type ?? '')
      })
      .catch(handleFetchError)
  }, [idCompany, getWarning])

  if (!expirationDate) return null
  const expiration = new Date(expirationDate)

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const credistParse = parseInt(credits)
    const testUsedPase = parseInt(testUsed)
    if (credistParse < testUsedPase) {
      getWarning('El número de créditos disponibles no puede ser menor a los créditos usados')
      return
    }
    const bodyData: IBodyData = {
      name: companyName,
      credits: parseInt(credits),
      expiration_date: expirationDate,
      tests_used: parseInt(testUsed),
    }
    try {
      await updateInventory(bodyData, Number(idCompany))
      getSuccess('Inventario actualizado correctamente')
      navigate(BACK_PAGE)
    } catch (error) {
      console.error(error)
      getWarning('Error al actualizar el inventario')
    }
  }

  return (
    <MainLayout>
      <form onSubmit={handleUpdate}>
        <Container>
          <h1>Editar inventario</h1>
          <InputsContainer>
            <InputContainer>
              <Input
                type="text"
                name="company_name"
                label="Nombre"
                placeholder="Nombre del inventario"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
            </InputContainer>
            <InputContainer>
              <Select
                name="type"
                label="Tipo"
                options={optionsType || []}
                onChange={(e: string) => {
                  setType(e)
                }}
                value={type}
                disabled={true}
                defaultValue={type}
              />
            </InputContainer>
            <InputContainer>
              {type === 'Ilimitado' ? (
                <div style={{ margin: '10px' }}></div>
              ) : (
                <Input
                  type={type !== '3' ? 'text' : 'number'}
                  disabled={type === '3'}
                  name="acquired_tests"
                  label="Cantidad de pruebas"
                  placeholder="Cantidad de pruebas"
                  onChange={(e: any) => {
                    const value = e.target.value
                    const intValue = parseInt(value)
                    if (value === '' || !isNaN(intValue)) {
                      setCredits(value)
                    } else {
                      setCredits('')
                    }
                  }}
                  value={credits || ''}
                />
              )}
            </InputContainer>
            <InputContainer>
              <DatePicker
                useISO8601={true}
                label="Fecha de vencimiento"
                defaultValue={dayjs.utc(expiration)}
                onChange={(date) => setExpirationDate(date)}
              />
            </InputContainer>
          </InputsContainer>
          <ContainerRow>
            <BtnGoBack onClick={() => navigate(-1)}>Cancelar</BtnGoBack>
            <Button type="submit">Guardar</Button>
          </ContainerRow>
        </Container>
      </form>
    </MainLayout>
  )
}

export default Index
