import React from 'react';

const LeftWithCircleIcon: React.FC = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4307_37599)">
                <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
                <path d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z" stroke="#2D749C" strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M16.2461 12.25H7.24609" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.7461 7.75L7.24609 12.25L11.7461 16.75" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_4307_37599">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default LeftWithCircleIcon;









