import { FC } from "react"
import { ThText, ScoreTable, TdWrapper } from "./styled"
import ScoreProgressGraphic from "../score-progress-graphic"
import { CompetenceContentProps } from "views/components/report/store"

const CompetenceContent: FC<CompetenceContentProps> = ({ competence, percentilData }) => {
  const findPercentil = (competenceName: string) => {
    let percentileProm = 0
    percentilData?.result.forEach((ps: any) => {
      if (ps.competenceName === String(competenceName)) {
        percentileProm = competence.competence_score === 100 ? 100 : ps.percentile_prom
      }
    })
    return percentileProm
  }

  return (
    <ScoreTable>
      <tbody>
        <tr>
          <ThText>{competence.competence}</ThText>
          <TdWrapper>
            <ScoreProgressGraphic
              progressValue={competence.competence_score}
              percentilValue={findPercentil(competence.competence)}
            />
          </TdWrapper>
        </tr>
      </tbody>
    </ScoreTable>
  )
}

export default CompetenceContent
