import React, { FC } from 'react'
import { ActionWrapper, ActivateAction, ContainerActionItem, InactiveAction, PendingAction } from './styled'
import DisassociateIcon from 'assets/icons/DIsassociate'
import RollBackIcon from 'assets/icons/RollBackIcon'
import PendingIcon from 'assets/icons/PendingIcon'

interface ActionItemProps {
  id: number
  active?: boolean
  handleActivateActionClick: (id: number) => void
  handleSuspendActionClick: (id: number) => void
  handleDeleteActionClick: (id: number, idStatus: number) => void
  handleEditActionClick?: (id: number) => void
  isDisableActionItem?: boolean
  onClick?: (id: number) => void
  idStatus?: number
  request_status: string | null
}

const ActionItem: FC<ActionItemProps> = ({
  id,
  active = false,
  handleActivateActionClick,
  handleSuspendActionClick,
  handleDeleteActionClick,
  // handleEditActionClick,
  isDisableActionItem = false,
  idStatus,
  // request_status: requestStatus,
}) => {

  return (
    <ContainerActionItem>
      {!active && idStatus !== 3 ? (
        <>
          {
            idStatus === null ? (
              <ActionWrapper onClick={() =>{ handleDeleteActionClick(id, 3)}}>
                <PendingIcon color={isDisableActionItem ? '#C2C2C2' : '#C2C2C2'} />
                <PendingAction isDisable={isDisableActionItem}>Por Activar</PendingAction>
              </ActionWrapper>
            ) : (
              <ActionWrapper
                onClick={() => {
                  handleActivateActionClick(id)
                }}>
                <RollBackIcon color={isDisableActionItem ? '#C2C2C2' : '#2D749C'} />
                <ActivateAction isDisable={isDisableActionItem}>Reactivar</ActivateAction>
              </ActionWrapper>
            )
          }
        </>
      ) : idStatus === 3 ? (
        <ActionWrapper onClick={() => handleDeleteActionClick(id, idStatus)}>
          <PendingIcon color={isDisableActionItem ? '#C2C2C2' : '#C2C2C2'} />
          <PendingAction isDisable={isDisableActionItem}>Por Activar</PendingAction>
        </ActionWrapper>
      ) : (
        <ActionWrapper
          onClick={() => {
            handleSuspendActionClick(id)
          }}
        >
          <DisassociateIcon color={isDisableActionItem ? '#C2C2C2' : '#E44646'} />
          <InactiveAction isDisable={isDisableActionItem}>Suspender</InactiveAction>
        </ActionWrapper>
      )}
    </ContainerActionItem>
  )
}

export default ActionItem
