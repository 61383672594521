import axios from 'lib/axios'

/**
 * @description service to get an specific betesa result by test id
 * @param testId: Number - id of the test
 */
const getBetesaResult = async (testId: number, userId?: number) => {
  const PATH = `${process.env.REACT_APP_MAIN_API}/betesa_result/resume/${testId}${userId ? '?user_id=' + userId : ''} `
  try {
    const response = await axios.get(PATH)
    return response.data
  } catch (error) {
    console.error(error)
    return error
  }
}

export default getBetesaResult
