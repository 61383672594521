import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 40px 0px 20px 0px;
`

export const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0px 40px 0px;
`

interface IContainerItem {
  showDivider?: boolean
}

export const ContainerItem = styled.div<IContainerItem>`
  border-right: ${({ showDivider }) => (showDivider ? '1px solid #E5E5E5' : 'none')};
  border-left: ${({ showDivider }) => (showDivider ? '1px solid #E5E5E5' : 'none')};
  padding: ${({ showDivider }) => (showDivider ? '0px 20px' : '0px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface IRow {
  width?: string
  height?: string
  margin?: string
  padding?: string
  justifyContent?: string
  alignItems?: string
}

export const Row = styled.div<IRow>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0px'};
  padding: ${({ padding }) => padding || '0px'};
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #c2c2c2;
`
export const TextCancel = styled.p`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  color: #2d749c;
  cursor: pointer;
`

interface IProps {
  width?: string
  height?: string
  maxHeigth?: string
  padding?: string
  margin?: string
  justifyContent?: string
  alignItems?: string
  float?: string
  alignSelf?: string
  maxWidth?: string
  backgroundColor?: string
  shadow?: string
  flexDirection?: string
}

export const BlueContainer = styled.div<IProps>`
  overflow: hidden;
  display: flex;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'center')};
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  max-height: ${(props) => (props.maxHeigth ? props.maxHeigth : '100%')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '10px')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'flex-start')};
  float: ${(props) => (props.float ? props.float : 'none')};
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'rgb(45, 116, 156)')};
  box-shadow: ${(props) => (props.shadow ? props.shadow : '0px 2px 10px rgba(0, 0, 0, 0.1)')};
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 20px 20px 20px;
`

export const TextBoldViolet = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 700;
  color: #c6016b;
  text-align: center;
  padding: 10px 32px;
  border-radius: 24px;
  border: 1px solid #c6016b;
  cursor: pointer;
`

export const TextBoldBlue = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.95rem;
  color: #2d749c;
  cursor: pointer;
  text-align: center;
  margin-right: 60px;
  padding: 10px 32px;
  border-radius: 24px;
  border: 1px solid #2d749c;
`
