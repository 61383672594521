import { memo } from 'react'
import styles from './style.module.css'
// import convert64GrayScale from "utils/convert64GrayScale";

interface HeaderCobrandingLogoProps {
  logoUrl: string
  hideMobile?: boolean
  style?: React.CSSProperties
  isGrayScale?: boolean
  marginTop?: string
  imageStyle?: React.CSSProperties
}

const HeaderCobrandingLogo: React.FC<HeaderCobrandingLogoProps> = ({ logoUrl, style, hideMobile }) => (
  <img
    className={[styles.image, hideMobile ? styles.imageHiddenMobile : ''].join(' ')}
    style={style}
    src={logoUrl || ''}
    alt="logo"
  />
)

export default memo(HeaderCobrandingLogo)
