import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 3.5% 3.5% 0 3.5%;
    margin-bottom: 40px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0px;
    }
    
`;

export const StepsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 3%;
    padding: 3% 0%;
`;

export const ConfigsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 80px;
`;
export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 150px;
    background-color: transparent;
`;