import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { MainLayout } from 'views/layouts'
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { Formik, Form } from 'formik'

import * as Yup from 'yup'

// components
import { InputGridDiv, ContainerRow, BtnGoBack, InputContainer, Error } from './styled'
import { Button } from 'views/components/UI/Buttons'
import TitleDiv from 'views/components/UI/TitleDiv'
import Input from 'views/components/UI/TextInput'
import Select from 'views/components/UI/Select'

// selectors
import { generalDataSelector } from 'ducks/auth';

// slices
import { setDataNewQuestionnaire } from 'ducks/modules/'


// services 
import createQuestionary from 'services/common/skills/create';
import { superAdminRoutes } from 'router/routes';
import { useNotification } from 'lib/context/notification.context';

const validationSchema = Yup.object({
  name: Yup.string()
    .max(50, 'El nombre debe tener máximo 50 caracteres')
    .required('El nombre es requerido'),
  type: Yup.string().required('El tipo es requerido'),
  language: Yup.string().required('El idioma es requerido'),
})


const languageOptions = [
  { value: 'es', label: 'Español' },
]

interface IData {
  skill: string
  description: string
  language: string
  active:boolean
  categoryId: number
  groupId: number
  levelId: number
  typeCategoryId: number
}

/**
 * 
 * @description Screen to init a new questionary
 */
const NewQuestionary: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const steps = ['Información básica', 'Capítulos', 'Preguntas']
  const { getWarning, getSuccess } = useNotification()
  // REDUX
  const { data: generalData } = useSelector(generalDataSelector)


  // STATE
  
  const [loading, setLoading] = useState<boolean>(false)

  // options
  const [questionaryTypeOptions, setQuestionaryTypeOptions] = useState<any[]>([])

  useEffect(() => {

    const typeOptions = generalData?.type_company.map((group: any) => {
      return {
        value: group.id,
        label: group.name
      }
    })

    setQuestionaryTypeOptions(typeOptions)
  }, [generalData?.type_company])
 
  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Nuevo cuestionario</h2>
      </TitleDiv>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Formik
        initialValues={{
          name: '',
          type: '1',
          language: 'es',
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values) => {
          if (!loading) {
            setLoading(true)

            const valuesToCreate: IData = {
              skill: values.name,
              description: values.name,
              language: values.language,
              active:true,
              categoryId: 1, 
              groupId: 1, 
              levelId: 1, 
              typeCategoryId: parseInt(values.type),  
            }
            createQuestionary(valuesToCreate)
              .then((res: any) => {
                if (res.status === 'OK') {
                  const dataOfQuestionary = {
                    id: res.skill.id,
                    name: res.skill.skill,
                    type: parseInt(values.type),
                    language: values.language,
                  }
                  getSuccess('Cuestionario creado con éxito')
                  setLoading(false)
                  dispatch(setDataNewQuestionnaire(dataOfQuestionary))
                  navigate(superAdminRoutes.CreateChapterQuestionaire, { state: { questionnaireId: res.skill.id } })
                }
              }
              ).catch(()=>{
                getWarning('No fue posible crear el cuestionario')
              })

          }
        }
        }
      >
        {({ values, handleChange, errors, touched, setFieldValue }) => (
          <Form>
            <InputGridDiv>
              <InputContainer>
                <Input
                  name="name"
                  type="text"
                  label="Nombre"
                  onChange={handleChange}
                  value={values.name}
                />
                {
                  errors.name  && touched.name ? (
                    <Error>{errors.name}</Error>
                  ) : null
                }
              </InputContainer>
              <InputContainer>
                <Select
                  hideNeverChoice={true}
                  label="Tipo"
                  options={questionaryTypeOptions}
                  onChange={(e) => setFieldValue('type', e)}
                  value={values.type}
                />
                {
                  errors.type ? (
                    <Error>{errors.type}</Error>
                  ) : null
                }
              </InputContainer>
              <InputContainer>
                <Select
                  hideNeverChoice={true}
                  label="Seleccione idioma"
                  options={languageOptions}
                  onChange={(e) => setFieldValue('language', e)}
                  value={values.language}
                />
                {
                  errors.language ? (
                    <Error>{errors.language}</Error>
                  ) : null
                }
              </InputContainer>
            </InputGridDiv>
            <ContainerRow>
              <BtnGoBack onClick={() => navigate(-1)}>Cancelar</BtnGoBack>
              <Button type='submit' disabled={Object.keys(errors).length > 0}>Crear</Button>
            </ContainerRow>
          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}

export default NewQuestionary
