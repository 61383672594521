import { FC, useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts'
import { useNavigate } from 'react-router-dom';
import { StrokedButton } from 'views/components/UI/Buttons';
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import TitleDiv from 'views/components/UI/TitleDiv'
import Modal from 'views/components/UI/Modal'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Row, GridModal,
  ModalContainer,
  ModalBody, BtnGoBack, SaveButton, ContainerActionItem, DeleteTag, UpdateTag
} from './styled'

import fetchDeleteActionById from 'services/developmentActions/fetchDeleteActionById/fetchDeleteActionById';
import { specialAdminRoutes } from 'router/routes';
import { fetchGetActionsById } from '../../services/fetch-skills-talents';
import { Action } from '../../store';
import EditOneAction from '../edit-one-action/edit-one-action';

type ActionType = {
  step: number;
  data: {
    id: number;
    description: string;
  };
};

const EditActions: FC = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [actions, setActions] = useState<Action[]>([])
  const [actionId, setDevActionId] = useState(0);
  const [actionName, setActionName] = useState('')
  const [step, setStep] = useState<ActionType>({
    step: 1,
    data: {
      id: 0,
      description: ''
    }
  })
  const searchParams = new URLSearchParams(window.location.search)
  const developmentPlanId = searchParams.get('id')

  useEffect(() => {
    fetchGetActionsById(Number(developmentPlanId)).then((developmentPlan) => {
      setActions(developmentPlan.actions);
      setActionName(developmentPlan.name)
    })
  }, [actions?.length, developmentPlanId, step])

  const hangleModalButton = (id: number) => {
    setOpenModal(true)
    setDevActionId(id);
  }
  const handleDeleteButton = () => {
    setOpenModal(false)
    fetchDeleteActionById(actionId).then(() => {
      setActions(actions.filter((action: any) => action.id !== actionId))
    })
  }

  const headers = [
    {
      key: 'description',
      name: 'Acciones',
    },
  ]
  const ActionComponent = (props: any) => {
    const { id, description = '' } = props;

    return (
      <ContainerActionItem>
        <UpdateTag onClick={() => setStep({
          step: 2,
          data: {
            id: id,
            description: description
          }
        })}
        >
          Editar</UpdateTag>
        <DeleteTag onClick={() => hangleModalButton(id)}>Eliminar</DeleteTag>
      </ ContainerActionItem>
    );
  }

  if (step.step === 1) {
    return (
      <MainLayout>
        <TitleDiv>
          <h2 style={{ fontSize: 32 }}>Editar acción de desarrollo {actionName}</h2>
          <StrokedButton type='button' onClick={() => { navigate(`${specialAdminRoutes.NewBetesaAction}?developmentPlanId=${developmentPlanId}`) }}>Nueva acción</StrokedButton>
        </TitleDiv>
        <BluesiteTableComponent
          data={actions}
          currentPage={1}
          headers={headers}
          searchBar={true}
          ActionComponent={ActionComponent}
          searchLabel=''
        />
        <Modal
          hideCloseButton={true}
          show={openModal}
          setShow={setOpenModal}
          styleContent={{
            width: '560px',
            height: '280px',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#FFFFFF',
          }}
        >
          <ModalContainer>

            <ModalBody>
              <DeleteOutlineOutlinedIcon />
              <h3 style={{ textAlign: 'center' }}>¿Seguro que deseas eliminar esta acción?</h3>
              <GridModal>
                <p style={{ width: '100%', marginTop: 0, color: '#303030', textAlign: 'center' }}>
                  Si eliminas esta acción no podrás volver a acceder a ella
                </p>


                <Row>
                  <BtnGoBack onClick={() => { setOpenModal(false) }}>Cancelar</BtnGoBack>
                  <SaveButton
                    onClick={handleDeleteButton}
                  >
                    Eliminar
                  </SaveButton>
                </Row>
              </GridModal>
            </ModalBody>
          </ModalContainer>
        </Modal>

      </MainLayout>
    )
  } else if (step.step === 2) {
    return (
      <>
        <EditOneAction action={step} handleStep={() => setStep({
          step: 1,
          data: {
            id: 0,
            description: ''
          }
        })} />
      </>
    )
  }
  return (
    <div></div>
  )

}
export default EditActions
