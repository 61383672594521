import axios from 'axios'
import firstTokenRequest from 'lib/axios/firstTokenRequest'

interface ReportBetesaResponse {
  status: string
  data: any
}

export const fetchReportBetesa = async (userId: number, testId: number,) => {
  const apiTest = `/report/betesa?userId=${userId}&testId=${testId}`

  const response: ReportBetesaResponse = await firstTokenRequest.get(apiTest).then((res) => {
    return res.data
  }).catch((error) => {
    return { ok: false, message: error.message };
  })

  return response
}

export const fetchReportBetesaWithToken = async (userId: number, testId: number,) => {
  const apiTest = `/report/betesa?userId=${userId}&testId=${testId}`

  const response: ReportBetesaResponse = await axios.get(apiTest).then((res) => {
    return res.data
  }).catch((error) => {
    return { ok: false, message: error.message };
  })

  return response
}

