import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1%;
`

export const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const Title = styled.div`
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
`

export const SubTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 18px;
  width: 88%;
`

export const SubTitles = styled.div`
  font-size: 16px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const SubTitlesMiddle = styled(SubTitles)`
  padding-left: 20px;
`

export const SubTitlesLast = styled(SubTitles)`
  padding-right: 20px;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
`

export const HistoryButton = styled.div`
  display: flex;
  height: 11px;
  width: 30px;
  padding: 9px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #2d749c;
  color: #2d749c;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 56px;
  border-radius: 0 8px 8px 0;
  background-color: #2d749c;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const MagnifyingGlass = styled.img`
  width: 30px;
  height: 30px;
`

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  // height: 100%;
  padding: 2% 0%;
`
