import React, { useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts/'
import { useLocation } from 'react-router-dom'
import { userSelector } from 'ducks/user'
import { useSelector } from 'react-redux'
import { Container, RowContainer, InfoUserContainer, InfoColumn, CardInfo, ReportContainer } from './styled'
import { BackButton } from 'views/components/UI/Buttons'
import { H2, H5, H4, H3, P, SubtitleBold } from 'views/components/UI/Text'
import { fetchGetUserProcessAction } from './services/fetch_user_detail_process'
import { handleFetchError } from './constants'
import { UserData, ProcessData } from './store/entities'
import CardTypeTest from 'views/components/Cards/TestType'
import skillIcon from 'assets/images/box-icon.png'
import betesaIcon from 'assets/images/brain-icon.png'
import { orderTestsByProcess } from 'utils/common'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const EvaluatedUserDetail = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const userId = location.state?.userId
  const processId = location.state?.processId[0] || location.state?.processId
  const { companyId } = useSelector(userSelector)

  const [dataUser, setDataUser] = useState<UserData | null>(null)
  const [dataProcess, setDataProcess] = useState<ProcessData | null>(null)
  const [tests, setTests] = useState<any>(null)

  useEffect(() => {
    const fetchDataUser = () => {
      fetchGetUserProcessAction(userId, processId)
        .then((response) => {
          if (response) {
            setDataUser(response.user_detail)
            setDataProcess(response.companies_history.filter((item: any) => item.company_id === companyId))
            setTests(orderTestsByProcess(response.tests as any))
          } else {
            throw new Error('Invalid response or missing user detail')
          }
        })
        .catch((error) => {
          handleFetchError(error)
        })
    }
    fetchDataUser()
  }, [userId, processId, companyId])

  const renderProcessInfo = () => {
    if (dataProcess && Array.isArray(dataProcess)) {
      return dataProcess.map((item: any, index: number) => (
        <RowContainer key={`dataprocess-key-${index}`} margin="2px 0px">
          <SubtitleBold margin="0">{item.process_name} -</SubtitleBold>
          <P margin="0px 0px 4px -1rem" weight={700}>
            {item.company_name}
          </P>
        </RowContainer>
      ))
    } else if (dataProcess) {
      return (
        <RowContainer margin="2px 0px">
          <P>{dataProcess.process_name} -</P>
          <P margin="0px 2px 0px 0px" weight={700}>
            {dataProcess.company_name}
          </P>
        </RowContainer>
      )
    } else {
      return <p>No hay procesos disponibles</p>
    }
  }

  return (
    <MainLayout>
      <Container>
        <div>
          <H2 margin="1.5% 1.3%">Espacio de evaluado {dataUser?.user_name}</H2>
        </div>
        <InfoUserContainer>
          <InfoColumn>
            <CardInfo>
              <H5 margin="8px 0px">Datos personales</H5>
              <RowContainer margin="8px 0px">
                <P margin="0px 6px 0px 0px" weight={700}>
                  Nombre completo:
                </P>
                <P>{dataUser ? dataUser.user_name : ''}</P>
              </RowContainer>
              <RowContainer margin="2px 0px">
                <P weight={700} margin="0px 6px 0px 0px">
                  {' '}
                  Fecha de nacimiento:{' '}
                </P>
                <P>
                  {dataUser?.user_birth_date}
                  {` (${dayjs().diff(dayjs(dataUser?.user_birth_date, 'DD/MM/YYYY'), 'year')}) años`}
                </P>
              </RowContainer>
              <RowContainer margin="2px 0px">
                <P weight={700} margin="0px 6px 0px 0px">
                  Documento de identidad:
                </P>
                <P>{dataUser ? dataUser.user_document : ''}</P>
              </RowContainer>
            </CardInfo>
          </InfoColumn>
          <InfoColumn>
            <CardInfo>
              <H4 style={{ marginBottom: 20 }}>Procesos a los que pertenece</H4>
              {renderProcessInfo()}
            </CardInfo>
          </InfoColumn>
        </InfoUserContainer>
        <ReportContainer>
          <H3 margin="10px 0px">Pruebas realizadas</H3>
          {tests && tests.length > 0 ? (
            tests.map((process: any, index: number) => {
              return (
                <div key={`user-detail-${index}`}>
                  <SubtitleBold fontSize="1.5rem">{process.process_name}</SubtitleBold>
                  <RowContainer margin="2px 0px">
                    {process.tests.map((test: any, indexItem: number) => {
                      const CardToRender =
                        test.category_name === 'Betesa Premium' ? (
                          <CardTypeTest
                            key={`user-detail-process-${indexItem}`}
                            link="/user-test-detail-talents/"
                            params={{
                              testId: test.test_id,
                              userName: dataUser?.user_name || '',
                              userId,
                              companyId,
                              processId,
                            }}
                            title="Betesa - Talentos Naturales"
                            image={betesaIcon}
                            disabled={test.domination === null}
                          />
                        ) : (
                          <CardTypeTest
                            link="/user-test-detail-skills/"
                            params={{
                              userId,
                              testId: test.test_id,
                              userName: dataUser?.user_name,
                              companyId,
                              completed: test.completed_test,
                            }}
                            title={t('global.common.skillText')}
                            image={skillIcon}
                            disabled={!test.completed_test}
                          />
                        )
                      return CardToRender
                    })}
                  </RowContainer>
                </div>
              )
            })
          ) : (
            <P>Este usuario no ha presentado ninguna prueba</P>
          )}
          <BackButton style={{ marginTop: '60px' }} onClick={() => window.history.back()} />
        </ReportContainer>
      </Container>
    </MainLayout>
  )
}

export default EvaluatedUserDetail
