/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import TitleDiv from 'views/components/UI/TitleDiv'
import { FieldArray, Field } from 'formik';
import {
  ErrorContainer,
  FormContainer,
  TextArea,
  TextError,
  ContainerInputs,
} from '../../pages/edit-role-family/styled'

const SkillInfoForm = ({ props }: any) => {
  const { values, errors, touched, setFieldValue } = props
  const Skill = [1, 2, 3]

  return <>
    <TitleDiv>
      <h2 style={{ fontSize: 32 }}>Habilidades asociadas a la familia de rol</h2>
    </TitleDiv>
    <FormContainer>
      <FieldArray name="family_skills_data">
        {({ insert, remove, push }) => (
          <ContainerInputs>
            {
              Skill?.map((competence: any, index: any) => (
                <Field
                  as={TextArea}
                  key={`family_skills_data6_${index}`}
                  name={`family_skills_data.${index}.name`}
                  placeholder={`Texto ${competence}`}
                />
              ))
            }
          </ContainerInputs>
        )}
      </FieldArray>
    </FormContainer>
  </>
}

export default SkillInfoForm
