import styled from 'styled-components'

export const Title = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #c6016b;
  letter-spacing: -0.05em;
  margin: 0;
  margin-bottom: 16px;
  text-align: justify;
  line-height: 2;
`

export const Subtitle = styled.span`
  font-family: 'Spartan-SemiBold';
  color: #2d749c;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.05em;
  line-height: 22px;
  text-align: left;
  margin-bottom: 8px;
  letter-spacing: -0.7px;
`

export const WrapperContent = styled.div`
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Text = styled.span`
  font-family: 'Lato-Regular';
  font-size: 10px;
  margin-bottom: 8px;
  text-align: justify;
  line-height: 1.2;
`

export const DescriptionText = styled(Text)``

export const WrapperList = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: 16px;
  margin-bottom: 8px;
`

export const Item = styled.li`
  width: 100%;
  font-family: 'Lato-Regular';
  letter-spacing: -0.05em;
  text-align: justify;
  line-height: 1;
  font-size: 10px !important;
  margin: 4px 0;
  padding: 0 0 0 4px;
  p {
    margin: 0.1px 0;
    font-family: 'Lato-Regular';
    letter-spacing: -0.05em;
    text-align: justify;
    line-height: 1.2;
    font-size: 10px !important;
  }
`

export const DominanceText = styled.div`
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 1.2;
  margin-bottom: 8px;
  margin-left: 4px;
  text-transform: uppercase;
`
