import React, { useState } from 'react';
import { TextField, IconButton } from '@mui/material';
import styled from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const StyledInput = styled(TextField)`
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  }
  & .MuiInputBase-root {
    width: 100%;
    padding: 0px 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  & .MuiFormHelperText-root {
    font-size: 12px;
    margin-left: 8px;
    color: #ff0000;
    margin-top: 8px;
  }
  &.Mui-focused {
    color: #797A7C;
  }
  &.MuiInputLabel-shrink {
    transform: translate(0, -6px) scale(0.75);
  }
`;

interface IInputProps {
  type: React.HTMLInputTypeAttribute | undefined;
  value?: any | undefined;
  placeholder?: string | undefined;
  name?: string;
  label: string;
  error?: boolean | undefined;
  helperText?: string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined | any;
  className?: string | undefined;
  select?: boolean | undefined;
  style?: React.CSSProperties | undefined;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void | undefined;
  disabled?: boolean | undefined;
  pattern?: string;
  onKeyDown?:(e?:any)=> void;
  onFocus?:(e?:any)=> void;
}

const Input = (props: IInputProps) => {
  const {
    type,
    name,
    label,
    error,
    helperText,
    onChange,
    onBlur,
    value,
    className,
    placeholder,
    select,
    style,
    disabled,
    onKeyDown,
    onFocus,
} = props;
  const labelProps = {
    style: {
      color: '#797A7C',
      marginTop: '-6px',
      marginLeft: '10px',
      fontFamily: 'Public Sans',
      fontStyle: 'normal',
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <StyledInput
      style={{
        width: style?.width || '97.5%',
        maxWidth: style?.maxWidth || '100%',
        justifyContent: style?.justifyContent || 'center',
        height: style?.height || '54px',
        backgroundColor: disabled ? '#F3F3F3' : '#FFFFFF',
        border: disabled
          ? 'border: 1px solid rgba(145, 158, 171, 0.2)'
          : '0.1px solid rgba(251, 251, 251, 0.2)',
        ...style,
      }}
      InputLabelProps={{ style: labelProps.style, shrink: Boolean(value) }}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className={className}
      value={value}
      type={type === 'password' && !showPassword ? 'password' : 'text'}
      name={name}
      onFocus={onFocus}
      label={label}
      error={error}
      helperText={helperText}
      onChange={onChange}
      placeholder={placeholder && placeholder}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        endAdornment:
          type === 'password' ? (
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={handleTogglePassword}
              onMouseDown={(e) => e.preventDefault()}
              size="small"
              style={{ marginTop: '-8px', transform: 'scale(0.9)' }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ) : null,
      }}
      /*   InputLabelProps={{ ...labelProps }} */
      select={select && select}
      disabled={disabled}
      multiline={type === 'textarea' ? true : false}
      sx={{
        fontFamily: `Public Sans', sans-serif`,
        padding: '0px 0px 2px 10px',
        justifyContent: 'center',
        width: '98%',
      }}
    />
  );
};

export default Input;


export const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #797A7C;
  background-color: #ffffff;
  padding: 10px;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #797a7c;
  resize: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #797a7c;
  }
`;
