import styled from 'styled-components';

const Container = styled.div`
    cursor: pointer;
`;

interface IProps {
    width?: string;
    height?: string;
    onClick?: () => void;
}

const FinishIcon = (props: IProps) => {
    const { width, height, onClick } = props;


    return (
        <Container onClick={onClick}>
            <svg width={width || '22px'} height={height || '22px'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6461_16548)">
                    <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
                    <path d="M21 13V20C21 20.5523 20.5523 21 20 21H4C3.44771 21 3 20.5523 3 20V4C3 3.44771 3.44771 3 4 3H11" stroke="#2D749C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 13.36V17H10.6586L21 6.65405L17.3475 3L7 13.36Z" stroke="#2D749C" strokeWidth="1.5" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_6461_16548">
                        <rect width="22" height="22" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Container>
    );
};

export default FinishIcon;


