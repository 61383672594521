import axios from 'lib/axios'
import { GET_TYPE_SKILL_TEXTS, GET_TYPE_DIMENSION, GET_TYPE_SCORE, GET_DATA, ACTIONS_SKILLS } from '../constants'

interface Filters {
  id?: number
  groupId?: number | string
  levelId?: number | string
  categoryId?: number | string
  typeCategoryTestId?: number | string
  skillId?: number | string
  page?: number
  limit?: number
}

export const fetchGetSkillTextTypeAction = async (filters?: Filters) => {
  try {
    let PATH = GET_TYPE_SKILL_TEXTS
    let hasQuery = false

    if (filters?.categoryId) {
      PATH += `${hasQuery ? '&' : '?'}category_id=${filters.categoryId}`
      hasQuery = true
    }

    if (filters?.groupId) {
      PATH += `${hasQuery ? '&' : '?'}group_id=${filters.groupId}`
      hasQuery = true
    }

    if (filters?.levelId) {
      PATH += `${hasQuery ? '&' : '?'}level_id=${filters.levelId}`
      hasQuery = true
    }

    if (filters?.limit) {
      PATH += `${hasQuery ? '&' : '?'}limit=${filters.limit}`
      hasQuery = true
    }

    const requests = []
    requests.push(axios.get(`${PATH}`))

    const responses = await Promise.all(requests)

    const skills = responses.flatMap((responseDate: any) =>
      responseDate.data.skills.map((item: any) => ({
        id: item.id,
        skill: item.skill,
      }))
    )
    return skills
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const fetchGetDimensionTextAction = async () => {
  try {
    const response = await axios.get(`${GET_TYPE_DIMENSION}`)
    return response.data.dimension
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}

export const fetchGetScoreTextAction = async () => {
  try {
    const response = await axios.get(`${GET_TYPE_SCORE}`)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}

export const fetchGeSillsAction = async (skill: number, dimension: number, result: string) => {
  try {
    const response = await axios.get(`${GET_DATA}/${skill}/${dimension}/${result}/`)
    return response.data.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
      return false
    } else {
      throw new Error('Error fetching actions')
    }
  }
}

export const updateSkillTextAction = async (body: any) => {
  const { score, text, id } = body
  const requestData = {
    score,
    text,
  }
  try {
    const response = await axios.put(`${ACTIONS_SKILLS}/update/${id}`, requestData)
    return response.data
  } catch (error) {
    return error
  }
}

export const createSkillTextAction = async (bodyData: any) => {
  const { score, text, typeId, dimensionId } = bodyData
  const body = {
    score,
    text,
    type_category_test_id: typeId,
    dimension_id: dimensionId,
  }
  try {
    const response = await axios.post(`${ACTIONS_SKILLS}/create`, body)
    return response.data
  } catch (error) {
    return error
  }
}
