import axios from 'axios'

/**
 * @description service to development actions
 * @param token
 */

interface FiltersGetActions {
  search?: string,
}

const fetchAllActions = async ( limit: number, page: number, filters?: FiltersGetActions) => {

  let apiUrl = `${process.env.REACT_APP_MAIN_API}/development_plan?competence=${true}`

  if (filters?.search) {
    const { search } = filters
    apiUrl = `${apiUrl}&search=${search}`
  }

  apiUrl = `${apiUrl}&limit=${limit}&page=${page}`
  
  const response = await axios.get(apiUrl).then((res) => {
    return res.data
  }).catch((error) => {
    return { ok: false, message: error.message };
  })

  return response
}

export default fetchAllActions
