import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from './entities'

export const initialState = {
  users: [] as User[],
}

export type InitialState = typeof initialState
type SetUsers = PayloadAction<User[]>

const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers(state, action: SetUsers) {
      state.users = action.payload
    },
  },
})

export const { setUsers } = usersSlice.actions


export const usersReducer = usersSlice.reducer
