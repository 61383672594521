import axios from 'lib/axios'

interface Iprops {
  userId: number
  firstName: string
  lastName: string
  birthDate?: string
  email?: string
  document?: string
  documentType?: number
}

export default async function updateUser(props: Iprops) {
  const { firstName, lastName, birthDate, userId, email, document, documentType } = props

  const path = `/user/update/${userId}`

  const body: any = {
    first_name: firstName,
    last_name: lastName,
    document: document,
    email: email,
    document_type_id: documentType,
  }

  if (birthDate) {
    body.birth_date = birthDate
  }

  try {
    const response = await axios.put(path, body)
    return response.data
  } catch (error: any) {
    return { status: 'error', message: error.response.data.message, error: error }
  }
}
