import styled from 'styled-components';

interface IDividerProps {
    margin?: string;
}

export const Divider = styled.div<IDividerProps>`
    width: 100%;
    height: 1px;
    background-color: #1F2124;
    margin: ${props => props.margin ? props.margin : '0px'};
`;


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    padding: 3.5%;
    margin-bottom: 40px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0px;
    }
    
`;

export const PaginationDiv = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: center;
`

export const StepsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 3%;
    padding: 3% 0%;
`;

//Grids

// grid container
export const GridContainer = styled.div`
    width: 100%;
    padding: 0% 0%;
    margin-top: 20px;

`;

// grid with 2 columns, proportion 7:3
export const GridCompetence = styled.div`
    display: grid;
    //grid-template-columns: 9fr 3fr;
    width: 100%;
    padding: 0% 0%;
`;

// grid with 2 columns, proportion 5:5
export const GridMinimumRecord = styled.div`
    display: grid;
    grid-template-columns: 6fr 6fr;
    width: 100%;
    padding: 0% 0%;
`;




interface IGridItemProps {
    flexDirection?: string;
    margin?: string;
    height?: string;
}

export const GridItem = styled.div<IGridItemProps>`
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    width: 100%;
    height: ${props => props.height ? props.height : '60px'};
    padding: 0% 0%;
    margin: ${props => props.margin ? props.margin : '0px'};
`;

export const TitleGrid = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #1F2124;
    font-family: 'Public Sans', sans-serif;
    font-weight: 600;
    margin: 10px 0px 1px 0px;

`;

interface IRowProps {
    width?: string;
    justify?: string;
    height?: string;
    margin?: string;
}

export const Row = styled.div<IRowProps>`
    display: flex;
    flex-direction: row;
    width:  ${props => props.width ? props.width : '100%'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    height: ${props => props.height ? props.height : 'auto'};
    align-items: center;
    margin: ${props => props.margin ? props.margin : '0px'};
`;


// competences added

export const CompetencesAddedContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0% 0%;
    margin-top: 20px;
`;


// next button

export const NextButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-end;
`;

export const MinimumRecordContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`;

export const ContainerItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 5px;
`

export const SaveButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  background: #C2C2C2;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: white;
  font-weight: bold;
  :hover{
    background: #C6016B;
  }`

export const CancelarButton = styled.button`
  justify-content: center;
  padding: 11px 22px;
  gap: 10px;
  width: 146px;
  height: 48px;
  box-shadow: 0px 8px 16px rgba(112, 112, 112, 0.24);
  border-radius: 8px;
  border: none;
  color: #2D749C;
  font-size:14; 
  font-weight: 700;
  cursor: 'pointer';
  font-weight: bold;
  :hover{
    background: #C6016B;
  }`


export const ContainerInputs = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
`

export const ErrorContainer = styled.div`
margin-top: 0px;
margin-bottom: 12px;
color: red;
`;

export const TextError = styled.span`
    color: red;
    text-align: left!important;
`;