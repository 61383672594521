import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { useSelector } from 'react-redux'
import { generalDataSelector } from 'ducks/auth'
import { tokenSelector } from 'ducks/user'
import { TextArea, ModulesQuestionContainer, InvertedContainer, InputContainer, ErrorMessage } from './styled'
import { P, H2 } from 'views/components/UI/Text'
import TitleDiv from 'views/components/UI/TitleDiv'
import Select from 'views/components/UI/Select'
import { Button } from 'views/components/UI/Buttons'
import RoundedCheck from 'views/components/UI/InputRadio'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'

// services
import createNewQuestion from 'services/common/questions/new'
import getSkillByFilter from 'services/common/skills/getByFilters'
import { useNotification } from 'lib/context/notification.context'

const NewQuestionSkill: FC = () => {
  const navigate = useNavigate()
  const { getWarning, getSuccess } = useNotification()
  const { data } = useSelector(generalDataSelector)
  const token = useSelector(tokenSelector)
  type TypeQuestion = 'abierta' | 'multiple'

  interface ICreateQuestion {
    text: string
    typeQuestion: TypeQuestion
    active: boolean
    reverseOrder: boolean
    typeCategoryTestId: number
    chapterId: number
    dimensionId: number
  }

  function handleCreateQuestion(values: any) {
    const { dimension, inverted, questionText, competencyId } = values
    const bodyData: ICreateQuestion = {
      text: questionText,
      typeQuestion: 'multiple', // by default
      active: true,
      reverseOrder: inverted,
      typeCategoryTestId: competencyId, // id of competence
      chapterId: 1, // by default because skill only have one chapter and is the number one
      dimensionId: parseInt(dimension),
    }

    createNewQuestion(token, bodyData)
      .then(() => {
        setTimeout(() => {
          getSuccess('Pregunta creada con exito')
        }, 1000)
        navigate('/modules/skills/questions')
      })
      .catch(() => {
        getWarning('No fue posible crear la pregunta')
      })
  }

  // DATA OPTIONS

  const [skills, setSkills] = useState<any[]>([])

  // groups data

  const groups = data.group
    .map((group: any) => {
      if (group.id === 1 || group.name === 'Betesa') {
        return null
      }
      return {
        value: group.id,
        label: group.name,
      }
    })
    .filter((option: any) => option !== null)

  // dimensions data
  const dimensions = data.dimension
    .map((dimension: any) => {
      if (dimension.name === 'Betesa' || dimension.name === 'betesa') {
        return null
      }
      return {
        value: dimension.id,
        label: dimension.name,
      }
    })
    .filter((option: any) => option !== null)

  // levels data
  const levels = data.level
    .filter((level: any) => level.name !== 'Estratégico-Táctico' && level.name.toLowerCase() !== 'betesa')
    .map((level: any) => {
      return {
        value: level.id,
        label: level.name,
      }
    })

  const initialValues = {
    level: '',
    group: '',
    competency: '',
    competencyId: '',
    dimension: '',
    inverted: false,
    questionText: '',
  }

  const validationSchema = Yup.object({
    level: Yup.string().required('El nivel es requerido'),
    group: Yup.string().required('El grupo es requerido'),
    competency: Yup.string().required('La competencia es requerida'),
    dimension: Yup.string().required('La dimensión es requerida'),
    questionText: Yup.string().required('El texto de la pregunta es requerido'),
    inverted: Yup.boolean().required('El campo invertido es requerido'),
  })

  interface IFilters {
    group: number
    level: number
  }

  function handleGetSkillByFilter(values: IFilters) {
    const { level, group } = values

    getSkillByFilter({ groupId: group, levelId: level }).then((response) => {
      const skillsFormated = response.skills.map((skill: any) => {
        return {
          value: skill.id,
          label: skill.skill,
        }
      })
      setSkills(skillsFormated)
    })
  }

  return (
    <MainLayout>
      <TitleDiv>
        <H2 style={{ fontSize: 32 }}>Nueva pregunta</H2>
      </TitleDiv>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleCreateQuestion(values)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form>
            <ModulesQuestionContainer>
              <InputContainer>
                <Select
                  width="100%"
                  options={levels}
                  value={values.level}
                  label="Nivel"
                  hideNeverChoice
                  onChange={(e: any) => {
                    setFieldValue('level', e.toString())
                    setFieldValue('competency', '')
                    setFieldValue('competencyId', '')
                  }}
                />
                {errors.level && touched.level ? <ErrorMessage>{errors.level}</ErrorMessage> : null}
              </InputContainer>
              <InputContainer>
                <Select
                  width="100%"
                  options={groups}
                  value={values.group}
                  label="Grupo"
                  hideNeverChoice
                  onChange={(e: any) => {
                    setFieldValue('group', e.toString())
                    setFieldValue('competency', '')
                    setFieldValue('competencyId', '')
                    handleGetSkillByFilter({ level: parseInt(values.level), group: parseInt(e) })
                  }}
                />
                {errors.group && touched.group ? <ErrorMessage>{errors.group}</ErrorMessage> : null}
              </InputContainer>
              <InputContainer>
                <Select
                  disabled={values.group === '' && values.level === ''}
                  width="100%"
                  options={skills.length > 0 ? skills : []}
                  value={values.competency}
                  label="Competencia"
                  hideNeverChoice
                  onChange={(e: any) => {
                    setFieldValue('competencyId', e)
                    setFieldValue('competency', e.toString())
                  }}
                />
                {errors.competency && touched.competency ? <ErrorMessage>{errors.competency}</ErrorMessage> : null}
              </InputContainer>
              <InputContainer>
                <Select
                  width="100%"
                  options={dimensions.length > 0 ? dimensions : []}
                  value={values.dimension}
                  label="Dimensión"
                  hideNeverChoice
                  onChange={(e: any) => setFieldValue('dimension', e.toString())}
                />
                {errors.dimension && touched.dimension ? <ErrorMessage>{errors.dimension}</ErrorMessage> : null}
              </InputContainer>
            </ModulesQuestionContainer>
            <InvertedContainer>
              <P marginRight="10px" color="#1F2124">
                ¿Está invertido?
              </P>
              <RoundedCheck
                label="Si"
                value="invertido"
                checked={values.inverted}
                onChange={() => setFieldValue('inverted', true)}
              />
              <RoundedCheck
                label="No"
                value="no invertido"
                checked={!values.inverted}
                onChange={() => setFieldValue('inverted', false)}
              />
            </InvertedContainer>
            {errors.inverted && touched.inverted ? <ErrorMessage>{errors.inverted}</ErrorMessage> : null}
            <InputContainer>
              <TextArea
                placeholder="Texto de la pregunta"
                value={values.questionText}
                onChange={handleChange}
                onBlur={handleBlur}
                name="questionText"
              />
              {errors.questionText && touched.questionText ? <ErrorMessage>{errors.questionText}</ErrorMessage> : null}
            </InputContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 35,
                marginTop: 40,
                alignItems: 'baseline',
              }}
            >
              <span
                style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }}
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancelar
              </span>
              <Button
                disabled={!_.isEmpty(errors)}
                type="submit"
                onClick={() => {
                  handleSubmit()
                }}
              >
                Guardar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </MainLayout>
  )
}
export default NewQuestionSkill
