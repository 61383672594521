import {Container, ImageContainer, TextContainer, Image, TitleContainer, ActionContainer, CheckCircle} from './styled';
import { H4 } from 'views/components/UI/Text';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import noImg from 'assets/images/no-image.png';


interface IProps {
    image: string;
    title: string;
    onClick: () => void;
    checked: boolean;
}

function Index(props: IProps) {
    const {image, title, onClick, checked} = props;


    return (
        <Container isChecked={checked} onClick={onClick}>
            <ImageContainer> 
                {
                    image ? <Image src={image} /> : <Image src={noImg} />
                }
            </ImageContainer>
            <TextContainer>
                <TitleContainer> 
                    <H4>{title || "Inserte titulo"}</H4> 
                </TitleContainer>
                <ActionContainer>
                   <CheckCircle >
                        {
                            checked ? <CheckIcon style={{color: '#fff'}}/> : <AddIcon style={{color: '#fff'}} />
                        }
                    </CheckCircle>
               </ActionContainer>          

            </TextContainer>       
        </Container>
    );
}

export default Index;