import { FC, useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import { useSelector } from 'react-redux'
import { TextArea, ModulesQuestionContainer, InvertedContainer, InputContainer, ErrorMessage } from './styled'
import { P, H2 } from 'views/components/UI/Text'
import TitleDiv from 'views/components/UI/TitleDiv'
import Select from 'views/components/UI/Select'
import { Button } from 'views/components/UI/Buttons'
import RoundedCheck from 'views/components/UI/InputRadio'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'

// selectors
import { generalDataSelector } from 'ducks/auth'

// services
import updateQuestionSkills from 'services/common/questions/update'
import getSkillByFilter from 'services/common/skills/getByFilters'
import getQuestionSkillsById from 'services/common/questions/getById'
import { useNotification } from 'lib/context/notification.context'
const UpdateQuestionSkill: FC = () => {
  const navigate = useNavigate()

  const { data } = useSelector(generalDataSelector)

  // ::::::::::: STATE :::::::::::

  interface IDataQuestion {
    id: number
    levelId: number
    groupId: number
    text: string
    active: boolean
    is_betesa?: boolean
    reverse_order: boolean
    options?: any[]
    dimensionId: number
    skillId: number
    skill: string
  }

  const { getWarning, getSuccess } = useNotification()
  const [idCurrentQuestion, setIdCurrentQuestion] = useState<number | null>(null)
  const [question, setQuestion] = useState<IDataQuestion | null>(null)
  const [skills, setSkills] = useState<any[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  type TypeQuestion = 'abierta' | 'multiple'

  interface ICreateQuestion {
    text: string
    typeQuestion: TypeQuestion
    active?: boolean
    reverseOrder: boolean
    typeCategoryTestId: number
    chapterId: number
    dimensionId: number
  }

  function handleUpdateQuestion(values: any) {
    const { dimension, inverted, questionText, competencyId } = values

    const bodyData: ICreateQuestion = {
      text: questionText,
      typeQuestion: 'multiple', // by default
      reverseOrder: inverted,
      typeCategoryTestId: competencyId, // id of competence
      chapterId: 1, // by default because skill only have one chapter and is the number one
      dimensionId: parseInt(dimension),
    }

    if (idCurrentQuestion !== null) {
      updateQuestionSkills(bodyData, idCurrentQuestion).then((response: any) => {
        if (response.status === 'OK') {
          setTimeout(() => {
            getSuccess('Compañía modificada con exito')
          }, 1000)
          navigate('/modules/skills/questions')
        } else {
          getWarning('No fue posible actualizar la pregunta')
        }
      })
    }
  }

  // levels data
  const levels = data?.level
    .filter((item: any) => item.name !== 'Betesa' && item.name !== 'Estratégico-Táctico')
    .map((group: any) => {
      return {
        value: group.id,
        label: group.name,
      }
    })

  // groups data
  const groups = data?.group.map((group: any) => {
    return {
      value: group.id,
      label: group.name,
    }
  })

  // dimensions data
  const dimensions = data?.dimension
    .filter((item: any) => item.name !== 'Betesa')
    .map((dimension: any) => {
      return {
        value: dimension.id,
        label: dimension.name,
      }
    })

  const initialValues = {
    level: question?.levelId.toString() || '',
    group: question?.groupId.toString() || '',
    competency: question?.skillId.toString(),
    competencyId: question?.skillId,
    dimension: question?.dimensionId?.toString() || '',
    inverted: question?.reverse_order || false,
    questionText: question?.text || '',
  }

  const validationSchema = Yup.object({
    level: Yup.string().required('El nivel es requerido'),
    group: Yup.string().required('El grupo es requerido'),
    competency: Yup.string().required('La competencia es requerida'),
    dimension: Yup.string().required('La dimensión es requerida'),
    questionText: Yup.string().trim().required('El texto de la pregunta es requerido'),
    inverted: Yup.boolean().required('El campo invertido es requerido'),
  })

  interface IFilters {
    group: number
    level: number
  }

  function handleGetSkillByFilter(values: IFilters) {
    const { level, group } = values

    getSkillByFilter({ groupId: group, levelId: level }, undefined, 100).then((response) => {
      if (response.status === 'OK' || response.status === 'success') {
        const skillsFormated = response.skills.map((skill: any) => {
          return {
            value: skill.id,
            label: skill.skill,
          }
        })
        setSkills(skillsFormated)
      }
    })
  }

  // ::::::::::::::EFFECTS::::::::::::::

  useLayoutEffect(() => {
    // READ ID FROM URL => Reading id from url for get question by id
    const searchParams = new URLSearchParams(window.location.search)
    const idOfQuestion = Object.fromEntries(searchParams).id
    setIdCurrentQuestion(parseInt(idOfQuestion))

    // GETTING DATA OF QUESTION BY ID
    getQuestionSkillsById(parseInt(idOfQuestion))
      .then((response) => {
        const questionData: IDataQuestion = response.question

        handleGetSkillByFilter({ level: questionData.levelId, group: questionData.groupId }) // get skills by group and level
        setQuestion(questionData) // set question data
      })
      .catch((error) => {
        console.error('error getting question by skill ', error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <TitleDiv>
        <H2 style={{ fontSize: 32 }}>Editar pregunta</H2>
      </TitleDiv>
      {question && Object.keys(question).length > 1 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (!isUploading) {
              setIsUploading(true)
              setButtonDisabled(true)
              handleUpdateQuestion(values)
              navigate(-1)
              setTimeout(() => {
                setButtonDisabled(false)
                setIsUploading(false)
              }, 400)
            }
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, dirty, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <ModulesQuestionContainer>
                <InputContainer>
                  <Select
                    hideNeverChoice={true}
                    width="100%"
                    options={levels}
                    value={values.level}
                    label="Nivel"
                    onChange={(e: any) => {
                      setFieldValue('level', e.toString())
                      setFieldValue('competency', '')
                      setFieldValue('competencyId', '')
                    }}
                  />
                  {errors.level && <ErrorMessage>{errors.level}</ErrorMessage>}
                </InputContainer>
                <InputContainer>
                  <Select
                    hideNeverChoice={true}
                    disabled={values.level === ''}
                    width="100%"
                    options={groups}
                    value={values.group}
                    label="Grupo"
                    onChange={(e: any) => {
                      setFieldValue('group', e.toString())
                      setFieldValue('competency', '')
                      setFieldValue('competencyId', '')
                      handleGetSkillByFilter({ level: parseInt(values.level), group: parseInt(e) })
                    }}
                  />
                  {errors.group && <ErrorMessage>{errors.group}</ErrorMessage>}
                </InputContainer>
                <InputContainer>
                  <Select
                    hideNeverChoice={true}
                    disabled={values.group === ''}
                    width="100%"
                    options={skills.length > 0 ? skills : []}
                    value={values.competency || ''}
                    label="Competencia"
                    onChange={(e: any) => {
                      setFieldValue('competencyId', e)
                      setFieldValue('competency', e.toString())
                    }}
                  />
                  {errors.competency && <ErrorMessage>{errors.competency}</ErrorMessage>}
                </InputContainer>
                <InputContainer>
                  <Select
                    hideNeverChoice={true}
                    disabled={values.competency === ''}
                    width="100%"
                    options={dimensions.length > 0 ? dimensions : []}
                    value={values.dimension}
                    label="Dimensión"
                    onChange={(e: any) => setFieldValue('dimension', e.toString())}
                  />
                  {errors.dimension && <ErrorMessage>{errors.dimension}</ErrorMessage>}
                </InputContainer>
              </ModulesQuestionContainer>
              <InvertedContainer>
                <P marginRight="10px" color="#1F2124">
                  ¿Está invertido?
                </P>
                <RoundedCheck
                  label="Si"
                  value="invertido"
                  checked={values.inverted}
                  onChange={() => setFieldValue('inverted', true)}
                />
                <RoundedCheck
                  label="No"
                  value="no invertido"
                  checked={!values.inverted}
                  onChange={() => setFieldValue('inverted', false)}
                />
              </InvertedContainer>
              {errors.inverted && <ErrorMessage>{errors.inverted}</ErrorMessage>}
              <InputContainer>
                <TextArea
                  color="#1F2124"
                  fontSize="16px"
                  placeholder="Texto de la pregunta"
                  value={values.questionText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="questionText"
                />
                {errors.questionText && <ErrorMessage>{errors.questionText}</ErrorMessage>}
              </InputContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 35,
                  marginTop: 40,
                  alignItems: 'center',
                }}
              >
                <span
                  style={{ fontWeight: 'bold', color: '#2D749C', cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  Cancelar
                </span>
                <Button
                  disabled={!_.isEmpty(errors) || !dirty || isSubmitting || buttonDisabled}
                  type="button"
                  onClick={handleSubmit}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </MainLayout>
  )
}
export default UpdateQuestionSkill
