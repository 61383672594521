import styled from 'styled-components'

interface Props {
  disabled: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
`

export const SubContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
`

export const GridContainer = styled.div`
  width: 98%;
  background-color: #d1e6f1;
  height: 99%;
  border-radius: 14px;
  border: 1px solid #d1e6f1;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden\;;
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
`

interface IColumnGridSubContainer {
  width: string
}
export const ColumnGridSubContainer = styled.div<IColumnGridSubContainer>`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Grid3X3 = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'top-left top-center top-right'
    'middle-left middle-center middle-right'
    'bottom-left bottom-center bottom-right';
  // separate the grid items with a 1px black border
  grid-gap: 1px;
`

interface IItemGrid {
  gridArea: string
  background?: string
}
export const ItemGrid = styled.div<IItemGrid>`
  grid-area: ${(props) => props.gridArea};
  width: 90%;
  min-height: 230px;
  display: flex;
  flex-direction: column;
  padding: 5% 5% 5% 5%;
  background-color: ${(props) => (props.background ? props.background : '#ffffff')};
`

export const BodyItemGrid = styled.div`
  width: 100%;
  margin-top: 2%;
  padding: 2% 0% 1% 0%;
  height: 270px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d8d8d8;
  }
`

interface ITextBold {
  color?: string
  transform?: string
  margin?: string
  background?: string
}

export const TextBold = styled.p<ITextBold>`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.color ? props.color : '#000000')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  transform: ${(props) => (props.transform ? props.transform : 'none')};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3%;
`

export const Title = styled.div`
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

export const StandardButton = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c6016b;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ButtonDisabled = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #c2c2c2;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`

export const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
`

export const ChecksContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const UsersButton = styled.div`
  display: flex;
  height: 20px;
  padding: 11px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #2d749c;
  color: #2d749c;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2D749C;
  padding: 10px;
`

export const ToolTipTitle = styled.div`
  font-size: 14px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const ModalSaveContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;
`

export const ModalSaveText = styled.div`
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

export const ModalBetesaContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalCompetenceContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalTitle = styled.div`
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
`

export const ModalText = styled.div`
  color: #1f2124;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 168%;
`

export const ModalIdealContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CrossContainer = styled.div`
  width: 500px;
  height: 300px;
  margin-top: -30px;
`

export const Cross = styled.div`
  position: relative;
  width: 500px;
  height: 300px;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 280px;
    background-color: black;
  }

  ::before {
    width: 200px;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ::after {
    width: 400px;
    transform: translate(-50%, -50%);
  }
`

export const Quadrant = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  padding: 10px;
`

export const IconQuadrant = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #d1e6f1;
  border-radius: 50%;
  font-size: 12px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`

export const TitleQuadrant = styled.h5`
  margin: 5px 0;
  color: #c6016b;
  font-size: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
`

export const TextQuadrant = styled.p`
  color: #1f2124;
  font-size: 16px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 950px;
  margin: 20px 0;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 950px;
  margin: 10px 0;
`

export const AddUsersButton = styled.div<Props>`
  display: flex;
  height: 11px;
  width: 30px;
  padding: 9px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: ${(props) => (props.disabled ? '1px solid #c2c2c2' : '1px solid #2d749c')};
  color: ${(props) => (props.disabled ? '#c2c2c2' : '#2d749c')};
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
`

export const UsersModalText = styled.div`
  color: #1f2124;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const UsersModalTitle = styled.div`
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 10px 16px;
`

export const UsersSearchButton = styled.div`
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  font-size: 15px;
  font-family: Public Sans;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const ResetButton = styled.div`
  margin-left: 30px;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const DeleteButtonText = styled.div`
  color: #e44646;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`

export const DeleteButtonIcon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`

export const UsersGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  // height: 100%;
  padding: 2% 1% 0 1%;
`

export const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const SearchButton = styled.div`
  display: flex;
  padding: 14px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: white;
  background-color: #2d749c;
  box-shadow: 0px 8px 16px 0px rgba(112, 112, 112, 0.24);
  cursor: pointer;

  :active {
    box-shadow: 0 0px #666;
    transform: translateY(1px);
  }
`

export const MagnifyingGlass = styled.img`
  width: 20px;
  height: 20px;
`
export const BottomButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
