import { useState } from 'react';
import axios from 'axios';
import { Company } from 'types/common'

interface IFindCompanyResponse {
  status: string;
  data: Company;
}

interface ResponseGetOneCompany {
    companyInfo: IFindCompanyResponse | null;
    loadingCompanyInfo: boolean;
    errorCompanyInfo: string | null;
    getOneCompany: (companyId: number) => Promise<void>;
}

const useGetCompanyDetail = (): ResponseGetOneCompany => {
  const [companyInfo, setCompanyData] = useState<IFindCompanyResponse | null>(null);
  const [loadingCompanyInfo, setLoading] = useState<boolean>(true);
  const [errorCompanyInfo, setError] = useState<string | null>(null);

  const getOneCompany = async (companyId: number): Promise<void> => {
    try {
      const response = await axios.get<IFindCompanyResponse>(`/company/${companyId}`);
      setCompanyData(response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    }
  };

  return { companyInfo,  loadingCompanyInfo, errorCompanyInfo, getOneCompany} 
};

export default useGetCompanyDetail;
