import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MainLayout } from 'views/layouts'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import TitleDiv from 'views/components/UI/TitleDiv'
import { ContainerRow, BtnGoBack, InputGridDiv, TextError, InputContainer } from './styled'
import Input from 'views/components/UI/TextInput'
import { Button } from 'views/components/UI/Buttons'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { dataNewQuestionnaire } from 'ducks/modules/selectors'
import { setDataNewChapter } from 'ducks/modules'
import { specialAdminRoutes, superAdminRoutes } from 'router/routes'

// services
import getChapters from 'services/common/chapters/get'
import getQuestionnaires from 'services/common/skills/getByFilters'

const STEPS = ['Información básica', 'Capítulos', 'Preguntas']

interface ICurrentQuestionnaire {
  id: number,
  skill: string,
  description: string,
  language: string,
  category_id: number,
  group_id: number,
  level_id: number,
  type_category_id: number,
}

/**
 * 
 * @description Screen to create a new chapter
 */
const NewChapter: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { questionnaireId } = location.state

  const { id: idQuestionnaire, language, type: questionnaireType } = useSelector(dataNewQuestionnaire);




  // Local state
  const [loading, setLoading] = useState(false)
  const [chaptersCreated, setChaptersCreated] = useState<any[] | null>(null)
  const [infoCurrentQuestionnaire, setInfoCurrentQuestionnaire] = useState<ICurrentQuestionnaire | null>(null)

  const validationSchema = Yup.object({
    chapterNumber: Yup.string().required('El número del capítulo es obligatorio'),
    chapterName: Yup.string().trim().required('El nombre del capítulo es obligatorio'),
    chapterDescription: Yup.string().trim().required('La descripción del capítulo es obligatoria'),
  })


  // EFFECTS
  useEffect(() => {
    if (questionnaireId) {
      getChapters({ typeCategoryTestId: parseInt(questionnaireId) }).then((res) => {
        if (res.status === 'OK') {
          setChaptersCreated(res.chapters)
        } else {
          setChaptersCreated([])
        }
      })
    }
  }, [questionnaireId])



  useEffect(() => {
    if (questionnaireId) {
      getQuestionnaires({ id: questionnaireId }, 1, 10).then((res: any) => {
        if (res.skills) {
          setInfoCurrentQuestionnaire(res.skills[0])
        }
      }
      )
    }
  }, [idQuestionnaire, questionnaireId])

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Nuevo capítulo</h2>
      </TitleDiv>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={1} alternativeLabel>
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {
        (idQuestionnaire || chaptersCreated !== null) && (
          <Formik
            initialValues={{ chapterNumber: chaptersCreated !== null ? (chaptersCreated.length + 1) : 1, chapterName: '', chapterDescription: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {

              if (!loading) {
                setLoading(true)

                if (idQuestionnaire || questionnaireId) {
                  const dataCreateFirstQuestion = {
                    idQuestionnaire: idQuestionnaire || questionnaireId,
                    language: language || infoCurrentQuestionnaire?.language,
                    questionnaireType: questionnaireType || infoCurrentQuestionnaire?.type_category_id,
                    chapterNumber: values.chapterNumber,
                    chapterName: values.chapterName,
                    chapterDescription: values.chapterDescription,
                  }

                  dispatch(setDataNewChapter(dataCreateFirstQuestion))
                  setLoading(false)
                  navigate(superAdminRoutes.CreateQuestionQuestionaire, { state: { questionnaireId, chapterId: dataCreateFirstQuestion.chapterNumber } })

                }
              }

            }}
          >
            {({ errors, touched, handleChange, values, isSubmitting }) => (
              <Form>
                <InputGridDiv>
                  <InputContainer>
                    <Field
                      value={values.chapterNumber}
                      // handling the change of the input to avoid that the user can type a number that is greater than the number of chapters created
                      onChange={(e: any) => parseInt(e.target.value) > 0 && (
                        chaptersCreated !== null ? parseInt(e.target.value) > chaptersCreated.length ? handleChange('chapterNumber')(e) : null : handleChange('chapterNumber')(e)
                      ) ? handleChange('chapterNumber')(e) : null}
                      type="number"
                      label="Numero del capítulo"
                      as={Input}
                      disabled={true}
                    />
                    {errors.chapterNumber && touched.chapterNumber ? (
                      <TextError>{errors.chapterNumber}</TextError>
                    ) : null}
                  </InputContainer>
                  <InputContainer>
                    <Field
                      name="chapterName"
                      type="text"
                      label="Nombre del capítulo"
                      as={Input}
                    />
                    {errors.chapterName && touched.chapterName ? (
                      <TextError>{errors.chapterName}</TextError>
                    ) : null}
                  </InputContainer>
                </InputGridDiv>
                <InputContainer>
                  <Field
                    style={{ height: 200 }}
                    name="chapterDescription"
                    type="textarea"
                    label="Descripción del capítulo"
                    as={Input}
                  />
                  {errors.chapterName && touched.chapterName ? (
                    <TextError>{errors.chapterName}</TextError>
                  ) : null}
                </InputContainer>
                <ContainerRow>
                  <BtnGoBack onClick={() => navigate(specialAdminRoutes.QuestionnairesBetesa)}>Cancelar</BtnGoBack>
                  <Button disabled={Object.keys(errors).length > 0 || isSubmitting} type="submit">Guardar</Button>
                </ContainerRow>
              </Form>
            )}
          </Formik>
        )
      }
    </MainLayout>
  )
}

export default NewChapter

