import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 40px 0px 20px 0px;
`

export const TableContainer = styled.div`
  margin-right: 30px;
`
