import axios from 'lib/axios'
import {
  GET_TYPE_TEXTS,
  GENERAL_TEXT,
  ALERT_TEXT,
  QUADRANTS_TEXT,
  FITNESS_TEXT,
  CHALLENGE_TEXT,
  GENERAL_TEXT_BETESA
} from '../constants'

export const fetchGetGeneralTextTypeAction = async () => {
  try {
    const response = await axios.get(`${GET_TYPE_TEXTS}`)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const fetchGetGeneralTextAction = async (type: string) => {
    const response = await axios.get(`${GENERAL_TEXT}`, {
      params: {
        id_type: type,
      },
    })
    return response.data.data
}

export const fetchGetGeneralTextBetesaAction = async (type: string) => {
  const response = await axios.get(`${GENERAL_TEXT_BETESA}`, {
    params: {
      id_type: type,
    },
  })
  return response.data.data
}


export const updateGeneralTextAction = async (data: any, id: number) => {
  const { text } = data
  const body = {
    text,
  }
  await axios
    .put(`${GENERAL_TEXT}/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const createGeneralTextAction = async (data: any, id: number) => {
  const { text } = data
  const body = {
    text,
    type_general_text_id: id
  }
  await axios.post(`${GENERAL_TEXT}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const updateGeneralTextBetesaAction = async (data: any, id: number) => {
  const { text } = data
  const body = {
    text,
  }
  await axios
    .put(`${GENERAL_TEXT_BETESA}/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const fetchGetAlertTextAction = async (name?: string) => {
  try {
    const config = name ? { params: { name } } : {}
    const response = await axios.get(`${ALERT_TEXT}`, config)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const updateAlertTextAction = async (data: any, id: number) => {
  const { description } = data
  const body = {
    description,
  }
  await axios
    .put(`${ALERT_TEXT}/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message };
    })
}

export const fetchGetQuadrantsTextAction = async (name?: string) => {
  try {
    const config = name ? { params: { name } } : {}
    const response = await axios.get(`${QUADRANTS_TEXT}`, config)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const updateQuadrantsTextAction = async (data: any, id: number) => {
  const { description } = data
  const body = {
    description,
  }
  await axios
    .put(`${QUADRANTS_TEXT}/${id}`, body)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
}

export const fetchGetFitnessTextAction = async (id: number, language?: string) => {
  try {
    const config = language ? { params: { language } } : {}
    const response = await axios.get(`${FITNESS_TEXT}/${id}`, config)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const updateFitnessTextAction = async (body: any) => {
  const { data } = body;
  const requestData = {
    data: data.map((item: { id: number, description: string }) => ({
      id: item.id,
      description: item.description
    }))
  };
  try {
    const response = await axios.put(`${FITNESS_TEXT}/update`, requestData);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const fetchGetChangelleTextAction = async (id: number, language?: string) => {
  try {
    const config = language ? { params: { language } } : {}
    const response = await axios.get(`${CHALLENGE_TEXT}/${id}`, config)
    return response.data.data
  } catch (error) {
    throw new Error('Error fetching actions by id')
  }
}

export const updateChangelleTextAction = async (body: any) => {
  const { data } = body;
  const requestData = {
    data: data.map((item: { id: number, description: string }) => ({
      id: item.id,
      description: item.description
    }))
  };
  try {
    const response = await axios.put(`${CHALLENGE_TEXT}/update`, requestData);
    return response.data;
  } catch (error) {
    return error;
  }
};


