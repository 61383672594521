import { Alert, AlertColor } from '@mui/material'
import Box from '@mui/material/Box'
type NotificationProps = {
  open: boolean
  message: string
  severity: AlertColor | undefined
  onClose?: (event?: React.SyntheticEvent | Event, reason?: string) => void
}

const ToastView: React.FC<NotificationProps> = ({ open, message, severity, onClose }) => {
  return (
    <Box sx={{ width: '100%', zIndex: 9999 }}>
      {open ? (
        <Alert
          onClose={onClose}
          severity={severity}
          sx={{
            position: 'absolute',
            top: 18,
            left: 10,
            right: 10,
            borderRadius: 2,
            zIndex: 9999,
          }}
        >
          {message}
        </Alert>
      ) : null}
    </Box>
  )
}

export default ToastView
