import ListTable from 'views/components/ListTable'
import { Container, Title, TitleWrapper } from './styled'

const DiagnosticInfo = ({ props }: any) => {
  const { competencesAdded, handleAdd, setCompetencesAdded, values } = props

  return (
    <Container>
      <TitleWrapper>
        <Title>Configuración de competencias de la familia de rol</Title>
      </TitleWrapper>

      <ListTable
        setCompetencesAdded={setCompetencesAdded}
        competencesAdded={competencesAdded}
        handleAdd={handleAdd}
        filterData={values?.level_id}
      />
    </Container>
  )
}

export default DiagnosticInfo
