import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import {
  INineBoxAnalytics,
  ISavedResponse,
  RNineBox,
  User,
  User9Box,
} from 'views/screens/Platform-administrator/Analytics/constants/types'
import { TextField } from '@mui/material'
import {
  ButtonDisabled,
  ButtonEdit,
  Container,
  InputsContainer,
  Line,
  StandardButton,
  SubContainer,
  SubTitles,
  SubTitlesContainer,
  SubTitlesLast,
  SubTitlesMiddle,
  Title,
  UsersButton,
} from './styled'
import CareerUsersModal from './careerUsersModal'
import EvaluateUsersModal from './evaluateUsersModal'
import { useNotification } from 'lib/context/notification.context'
import NineBoxCareerPlan from './NineBoxCareerPlan'
import useGetTextsNinebox from 'hooks/useGetNineboxTexts/use-get-ninebox-texts'

export interface INineBox {
  typeNineBox?: string
  processId: string
  setIsCareerPlan: (value: boolean) => void
  setShowHistory: (value: boolean) => void
  savedNineBox: ISavedResponse
  setRefreshSavedNineBox: React.Dispatch<React.SetStateAction<boolean>>
  historySelectedNineBox: RNineBox
  clearHistorySelectedNineBox: () => void
  triggerEffect: boolean
  checksIdeal: any
  processName: string
}

const CareerPlan: FC<INineBox> = ({
  processId,
  checksIdeal,
  setShowHistory,
  savedNineBox,
  setRefreshSavedNineBox,
  historySelectedNineBox,
  clearHistorySelectedNineBox,
  triggerEffect,
  processName,
}) => {
  const { textsNinebox } = useGetTextsNinebox('plan_carrera')
  const { getWarning } = useNotification()
  const [inputs, setInputs] = useState({ potential: '', performance: '' })
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [showEvaluateModal, setShowEvaluateModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<User[]>([] as User[])
  const [evaluatedUsers, setEvaluatedUsers] = useState<number[]>([] as number[])
  const [validatedInputs, setValidatedInputs] = useState({ potential: '', performance: '' })
  const [nineBoxCareer, setNineBoxCareer] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [historyAddedUsers, setHistoryAddedUsers] = useState<number[]>([])
  const [historyUsersPerformance, setHistoryUsersPerformance] = useState<User9Box[]>([] as User9Box[])
  const [fullObjectSavedUsers, setFullObjectSavedUsers] = useState<User[]>([] as User[])

  useEffect(() => {
    if (historySelectedNineBox.career_plan) {
      sessionStorage.setItem('history', JSON.stringify(historySelectedNineBox))
      sessionStorage.setItem('nineBoxName', JSON.stringify(historySelectedNineBox?.name))
    }
    if (validatedInputs.potential !== '' && validatedInputs.performance !== '') {
      sessionStorage.setItem('inputs', JSON.stringify(validatedInputs))
    }
  }, [historySelectedNineBox, validatedInputs])

  useEffect(() => {
    if (historyUsersPerformance.length > 0) {
      const newGrades: Record<number, number> = historyUsersPerformance.reduce((acc, userPerformance) => {
        acc[userPerformance.user_id] = userPerformance.performance / 10
        return acc
      }, {} as Record<number, number>)
      sessionStorage.setItem('userGrades', JSON.stringify(newGrades))
    }
  }, [historyUsersPerformance])

  const nineBoxName = sessionStorage.getItem('nineBoxName')
  let nameOnInput
  if (nineBoxName) {
    nameOnInput = JSON.parse(nineBoxName)
  }

  const grades = sessionStorage.getItem('userGrades')
  let sessionGrades
  if (grades) {
    sessionGrades = JSON.parse(grades)
  }

  const data = sessionStorage.getItem('history')
  let sessionData
  if (data) {
    sessionData = JSON.parse(data)
  }

  const inputsData = sessionStorage.getItem('inputs')
  let sessionValidatedInputs
  if (inputsData) {
    sessionValidatedInputs = JSON.parse(inputsData)
  }

  const cleanSession = () => {
    sessionStorage.removeItem('history')
    sessionStorage.removeItem('nineBoxName')
    setHistoryAddedUsers([])
    clearHistorySelectedNineBox()
  }

  const saveSession = (sessionNewPlan: INineBoxAnalytics) => {
    sessionStorage.setItem('history', JSON.stringify(sessionNewPlan))
    const newPlanData = sessionStorage.getItem('history')
    if (newPlanData) {
      sessionData = JSON.parse(newPlanData)
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (Number(value) >= 0) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleUsers = () => {
    if (validatedInputs.potential === '' || validatedInputs.performance === '') {
      getWarning('Debes ingresar los porcentajes de potencial y desempeño')
      return
    }
    setShowUsersModal(true)
  }

  const handleSavePercentils = () => {
    if (inputs.potential === '' || inputs.performance === '') {
      getWarning('Debes ingresar los porcentajes de potencial y desempeño')
      return
    }
    if (Number(inputs.potential) + Number(inputs.performance) !== 100) {
      getWarning('La suma de los porcentajes debe ser 100')
      return
    }
    setDisabledButton(true)
    setValidatedInputs(inputs)
  }

  const handleShowHistory = () => {
    setShowHistory(true)
  }

  const handleEditPercentils = () => {
    setInputs({ potential: '', performance: '' })
    setValidatedInputs({ potential: '', performance: '' })
    setDisabledButton(false)
  }

  return (
    <Container>
      <CareerUsersModal
        show={showUsersModal}
        close={setShowUsersModal}
        setSelectedUsers={setSelectedUsers}
        processId={processId}
        showEvaluatedModal={setShowEvaluateModal}
        setNineBoxCareer={setNineBoxCareer}
        cleanSession={cleanSession}
        historyAddedUsers={historyAddedUsers}
        sessionData={sessionData}
        fullObjectSavedUsers={fullObjectSavedUsers}
      />
      <EvaluateUsersModal
        show={showEvaluateModal}
        close={setShowEvaluateModal}
        usersSelected={selectedUsers}
        setEvaluatedUsers={setEvaluatedUsers}
        setNineBoxCareer={setNineBoxCareer}
        processId={processId}
        cleanSession={cleanSession}
        historyUsersPerformance={historyUsersPerformance}
        sessionGrades={sessionGrades}
      />
      {nineBoxCareer || historySelectedNineBox?.career_plan || sessionData?.process_id ? (
        <NineBoxCareerPlan
          processName={processName}
          checksIdeal={checksIdeal}
          evaluatedUsers={evaluatedUsers}
          setShowUsersModal={setShowUsersModal}
          setShowHistory={handleShowHistory}
          savedNineBox={savedNineBox}
          processId={processId}
          validatedInputs={validatedInputs}
          setRefreshSavedNineBox={setRefreshSavedNineBox}
          historySelectedNineBox={historySelectedNineBox}
          clearHistorySelectedNineBox={clearHistorySelectedNineBox}
          sessionData={sessionData}
          saveSession={saveSession}
          setHistoryAddedUsers={setHistoryAddedUsers}
          triggerEffect={triggerEffect}
          sessionValidatedInputs={sessionValidatedInputs}
          setHistoryUsersPerformance={setHistoryUsersPerformance}
          nameOnInput={nameOnInput}
          setFullObjectSavedUsers={setFullObjectSavedUsers}
          textsNinebox={textsNinebox || []}
        />
      ) : null}
      <SubContainer
        nineBox={nineBoxCareer}
        historyPlan={historySelectedNineBox?.career_plan}
        sessionData={!!sessionData?.process_id}
      >
        <Title>Porcentajes de potencial y desempeño</Title>
        <SubTitlesContainer>
          <SubTitles>Potencial</SubTitles>
          <SubTitlesMiddle>Desempeño</SubTitlesMiddle>
          <SubTitlesLast>Sugerido</SubTitlesLast>
        </SubTitlesContainer>
        <Line />
        <InputsContainer>
          <TextField
            type="number"
            disabled={disabledButton}
            value={inputs.potential}
            name="potential"
            onChange={handleInputChange}
            label="Ejemplo: 85"
            sx={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029', width: '310px', marginRight: '10px' }}
          />
          <TextField
            type="number"
            disabled={disabledButton}
            value={inputs.performance}
            name="performance"
            onChange={handleInputChange}
            label="Ejemplo: 15"
            sx={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029', width: '310px', marginRight: '10px' }}
          />
          {!disabledButton ? (
            <StandardButton style={{ marginRight: '20px', marginLeft: '20px' }} onClick={handleSavePercentils}>
              Guardar
            </StandardButton>
          ) : (
            <ButtonDisabled style={{ marginRight: '20px', marginLeft: '20px' }}>Guardar</ButtonDisabled>
          )}
          <ButtonEdit style={{ marginRight: '20px', marginLeft: '20px' }} onClick={handleEditPercentils}>
            Editar porcentajes
          </ButtonEdit>

          <SubTitles>Potencial: 70, Desempeño: 30</SubTitles>
        </InputsContainer>
        <SubTitles>Agrega los usuarios y su desempeño</SubTitles>
        <UsersButton onClick={handleUsers}>Administrar usuarios</UsersButton>
      </SubContainer>
    </Container>
  )
}

export default CareerPlan
