
import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import TitleDiv from 'views/components/UI/TitleDiv'
import { P } from 'views/components/UI/Text'
import Select from 'views/components/UI/Select'
import getGeneralText, {putGeneralText} from 'services/common/generalTexts'

import {
  FormContainer,
  SelectDiv,
  SaveButton,
}
  from './styled'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNotification } from 'lib/context/notification.context'
import { generalDataSelector } from 'ducks/auth'
import { useSelector } from 'react-redux'



const Home: FC = () => {
  const navigate = useNavigate()
  const [questionType, setQuestionType] = useState('')
  const [textOptions, setTextOptions] = useState([])
  const [generalData, setGeneralData] = useState({
    id: 0,
    text: ''
  })
  const { data: general } = useSelector(generalDataSelector);
  const [editorData, setEditorData] = useState('');
  const { getWarning, getSuccess } = useNotification();
  const handleEditorChange = (event: any, editor: { getData: () => any }) => {
    const data = editor.getData();
    setEditorData(data);
  };

  useEffect(() => {
    if(general){
      const options = general.type_general_text
      setTextOptions(options)
    }
  }, [general])
  
  useEffect(() => {
    if (questionType) {
      getGeneralText(questionType).then((res: any) => {
        setGeneralData(res)
      })
    }
  }, [questionType])

  const textList = textOptions.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    }
  })
  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Generales</h2>
      </TitleDiv>
      <FormContainer>
        <SelectDiv>
          <Select
            style={{ width: '50%' }}
            label="Tipo de texto"
            options={textList}
            hideNeverChoice={true}
            value={questionType}
            onChange={(e) => setQuestionType(e === '0' ? '' : e)}
          />
        </SelectDiv>
        <CKEditor
          editor={ ClassicEditor }
          data={generalData.text}
          onChange={handleEditorChange}
        />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30, alignItems: 'center' }}>
          <P onClick={() => {
            navigate(-1)
          }} style={{ color: '#2D749C', fontSize: 14, fontWeight: 700, cursor: 'pointer' }}>Cancelar</P>
          <div>
            <SaveButton type="submit" background={generalData.id === 0 ? '#C2C2C2' : '#C6016B'}onClick={() => {
              if(generalData.id !== 0) {
                putGeneralText({text: editorData}, generalData.id).then((res) => {
                  if (res.status === 'OK') {
                    getSuccess('Campo editado correctamente')
                    
                  } else {
                    getWarning('No fue posible editar el campo')
                  }
                })
            
              }
            }
            }>Guardar</SaveButton>
          </div>
        </div>
      </FormContainer>
    </MainLayout>
  )
}
export default Home
