import { AuthLayout } from 'views/layouts'
import {  ContainerForm  } from './styled'
import ForgotFormContainer from '../../containers/Forms/Forgot'

const Forgot = () => {
  return (
    <AuthLayout>
        <ContainerForm>
          <ForgotFormContainer />
        </ContainerForm>
    </AuthLayout>
  )
}

export default Forgot
