import styled from 'styled-components'


export const InputGrid = styled.div`
max-width: 1400px;
display: grid;
grid-template-columns : repeat(3, 1fr);;
gap: 16px;
@media (max-width: 980px) {
  grid-template-columns : repeat(2, 1fr);;
}
@media (max-width: 680px)  {
  grid-template-columns : repeat(1, 1fr);;
}
`
