export const GET_TYPE_SKILL_TEXTS = "/skill";
export const GET_TYPE_DIMENSION = "/dimension_general"
export const GET_TYPE_SCORE = "/score_range"
export const GET_DATA = "/skill_result"
export const ACTIONS_SKILLS = "/skill_result"

export const handleFetchError = (text: string) => {
  return text
}

export const BACK_PAGE = -1;
