import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CardTitleWithColor,
  CardInfoWithBorderTitle,
  FormContainer,
  RadioWrapper,
  RadioLabel,
  SaveButton,
  WrapperContainerForm,
  WrapperButton,
} from './styled'
import Radio from '@mui/material/Radio'
import { INTERPRETATION_OPTIONS } from '../../constants'
import updateBetesaInterpretation from 'services/common/betesa/update-betesa-interpretation'
import { useNotification } from 'lib/context/notification.context'
import { useDispatch } from 'react-redux'
import { setInterpretation } from 'ducks/ui'
import { CardTitle } from '../../interpretation-detail/styled'

interface InterpretationFormProps {
  interpretation?: string
  userIdParam?: number
  testIdParam?: number
  loadingState?: (value: boolean) => void
  disabled?: boolean
}

const InterpretationForm: FC<InterpretationFormProps> = ({
  interpretation = '',
  userIdParam,
  testIdParam,
  loadingState = () => {},
  disabled = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getSuccess, getError } = useNotification()
  const searchParams = new URLSearchParams(window.location.search)
  const id = searchParams.get('id')
  const [selectedDomination, setSelectedDomination] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setInterpretation(false))
    setSelectedDomination((event.target as HTMLInputElement).value)
  }

  const handleClickButton = useCallback(async () => {
    try {
      await updateBetesaInterpretation({
        domination: selectedDomination,
        userId: userIdParam ?? 0,
        testId: testIdParam ?? 0,
        betesaResultsId: Number(id),
      })

      loadingState(true)
      dispatch(setInterpretation(true))
      getSuccess('Prueba interpretada correctamente.')
      navigate(-1)
    } catch (error) {
      loadingState(false)
      getError('Hubo un problema para interpretar la prueba, comuníquese con el administrador.')
    } finally {
      loadingState(false)
    }
  }, [selectedDomination, dispatch, getError, getSuccess, id, loadingState, testIdParam, userIdParam, navigate])

  return (
    <CardInfoWithBorderTitle>
      <CardTitleWithColor>
        <CardTitle>Dominancia</CardTitle>
      </CardTitleWithColor>
      <WrapperContainerForm>
        <FormContainer>
          {INTERPRETATION_OPTIONS.map((option, index) => (
            <RadioWrapper key={index}>
              <Radio
                key={`${option.value}-${index}`}
                checked={
                  selectedDomination === option.value || (interpretation === option.value && !selectedDomination)
                }
                onChange={handleChange}
                value={option.value}
                name="radio-buttons"
                disabled={disabled}
              />
              <RadioLabel>{option.value}</RadioLabel>
            </RadioWrapper>
          ))}
        </FormContainer>
        <WrapperButton>
          {!disabled && (
            <SaveButton
              disabled={!selectedDomination || disabled}
              onClick={handleClickButton}
              allowed={!!selectedDomination}
            >
              Enviar
            </SaveButton>
          )}
        </WrapperButton>
      </WrapperContainerForm>
    </CardInfoWithBorderTitle>
  )
}

export default InterpretationForm
