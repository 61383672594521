import axios from 'lib/axios'

interface UpdateBetesaResponse {
  status: string
  data: {
    id: number
  }
}

interface DataUpdateInterpretation {
  domination: string
  betesaResultsId: number
  userId: number
  testId: number
}

const updateBetesaInterpretation = async (data: DataUpdateInterpretation) => {
  const { betesaResultsId, userId, testId, domination } = data;
  const body = { domination, user_id: userId, test_id: testId };

  try {
    const response: UpdateBetesaResponse = await axios.put(`/betesa_interpretation/${betesaResultsId}`, body);
    return response.data;
  } catch (err) {
    console.error('Error en la actualización de la interpretación Betesa', err);
    throw err;
  }
}

export default updateBetesaInterpretation
