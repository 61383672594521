import axios from 'axios';
import { FIRST_TOKEN } from 'constants/global.constants';

const firstTokenRequest = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

firstTokenRequest.interceptors.request.use(request => {
  const token = localStorage.getItem(FIRST_TOKEN);

  if (token && request.headers) {
    // eslint-disable-next-line
    request.headers['Authorization'] = `Bearer ${token}`;
  } 
  return request;
}, error => { return Promise.reject(error); });

export default firstTokenRequest;
