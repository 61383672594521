import axios from 'axios'

const getSavedNineBox = async (id: number, limit: number, page: number, search: string) => {
  const nineBoxResults = await axios
    .get(
      search?.length
        ? `/nine-box/process/${id}?limit=${limit}&page=${page}&search=${search}`
        : `/nine-box/process/${id}?limit=${limit}&page=${page}`
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return { ok: false, message: error.message }
    })

  return nineBoxResults
}

export default getSavedNineBox
