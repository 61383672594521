/* eslint-disable no-useless-rename */
import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import login from 'services/login'
import { setError, setLoading, setUser, userLoginSagas } from 'ducks/user/reducer'
import { TOKEN } from 'constants/global.constants'

function* loginSaga(action: any) {
  yield put(setLoading(true))

  try {
    const { data: user, message } = yield call(login, action?.payload?.email, action?.payload?.password)
    if (user?.token) {
      const { company_id: companyId, token, user_name: userName } = user

      yield put(
        setUser({
          email: action?.payload?.email,
          token,
          companyId,
          userName,
        })
      )
      localStorage.setItem(TOKEN, token)
      yield put(setLoading(false))
    } else {
      yield put(
        setError({
          error: true,
          message,
          code: message.slice(-3),
        })
      )
      yield put(setLoading(false))
    }
  } catch (error) {
    yield put(setError(error))
    yield put(setLoading(false))
  } finally {
    yield put(setLoading(false))
  }
}

export function* userSagas(): SagaIterator {
  yield all([takeLatest(userLoginSagas.trigger, loginSaga)])
}
