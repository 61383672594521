import { FC } from 'react'
import WhiteContainer from 'views/components/common/WhiteContainer'
import {
  ContainerItemPercentiles,
  HeadPercentiles,
  ContainerRow,
  ContainerPercentiles,
  ContainerPercentilesRowItems,
  BoldText,
  Text,
  NumberValue,
} from './styled'
import { Span } from 'views/components/UI/Text'
import { Percentile, PercentileToFind } from '../../store'

interface ContentCardPercentileProps {
  percentilesInfo: Percentile
  levelName: string
}

const ContentCardPercentile: FC<ContentCardPercentileProps> = ({ percentilesInfo, levelName }) => {
  const percentileToShow = percentilesInfo.percentileScoresToFind.map((item: PercentileToFind) => {
    return item.percentile
  })

  return (
    <WhiteContainer padding="35px 40px" margin="20px 0px" width="95%" overflow="none">
      <HeadPercentiles>
        <ContainerRow>
          <BoldText>Competencia: </BoldText>
          <Text>{percentilesInfo.competenceName}</Text>
        </ContainerRow>
        <ContainerRow>
          <BoldText>Dimensión:</BoldText>
          <Text>{percentilesInfo.dimension}</Text>
        </ContainerRow>
        <ContainerRow>
          <BoldText>Nivel:</BoldText>
          <Text>{levelName}</Text>
        </ContainerRow>
      </HeadPercentiles>

      <ContainerPercentiles>
        <WhiteContainer padding="3%" margin="25px 0px" width="60%">
          <ContainerPercentilesRowItems>
            <ContainerItemPercentiles>
              <NumberValue>{percentilesInfo.dimensionAverage}</NumberValue>
              <Span color="#637381">Promedio</Span>
            </ContainerItemPercentiles>
            <ContainerItemPercentiles>
              <NumberValue>{percentilesInfo.competencePercentage}</NumberValue>
              <Span color="#637381">Porcentaje</Span>
            </ContainerItemPercentiles>
            <ContainerItemPercentiles>
              <NumberValue>
                [{percentileToShow[0] === 100 ? 100 : String(percentileToShow[0])}]
              </NumberValue>
              <Span color="#637381">Percentiles</Span>
            </ContainerItemPercentiles>
          </ContainerPercentilesRowItems>
        </WhiteContainer>
      </ContainerPercentiles>
    </WhiteContainer>
  )
}

export default ContentCardPercentile
