/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import Stepper from 'views/components/Stepper'
import { MainLayout } from 'views/layouts'
import { Container, Row } from './styled'
import { H2, P } from 'views/components/UI/Text'
import { Button, BackButton } from 'views/components/UI/Buttons'
import CardTypeTest from 'views/components/Cards/SelectTest'
import brainImage from 'assets/images/brain-icon.png'
import boxImage from 'assets/images/box-icon.png'
import { platformAdminRoutes } from 'router/routes'
import { STEPS_NEW_PROCESS } from '../constants'
import { stepThreeSelector, setStepThreeTrigger, stepTwoSelector, stepOneSelector } from 'ducks/createProcess'
import { useTranslation } from 'react-i18next'

type TypeTests = 'betesa' | 'skills'

const SelectTestsType = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const stepOneData = useSelector(stepOneSelector)
  const stepTwoData = useSelector(stepTwoSelector)
  const stepThreeData = useSelector(stepThreeSelector)
  const InventoryTypeIlimit = 3
  const InventoryTypeIlimitIntegration = 4
  const [testsSelected, setTestsSelected] = useState<TypeTests[]>([])

  const handleSelectTest = (test: TypeTests) => {
    if (_.includes(testsSelected, test)) {
      setTestsSelected(_.without(testsSelected, test))
    } else {
      setTestsSelected((prevTestsSelected) => [...prevTestsSelected, test])
    }
  }

  const handleNextStep = () => {
    const dataStepThreeToSave = {
      processId: stepTwoData?.processId,
      onlineCourse: false,
      useBetesaTest: testsSelected.includes('betesa'),
      useSkillsTest: testsSelected.includes('skills'),
    }

    try {
      dispatch(setStepThreeTrigger.run(dataStepThreeToSave))
    } catch (error) {
      return error
    } finally {
      if (testsSelected.includes('betesa')) {
        navigate(platformAdminRoutes.HomologationSkills)
        return
      }

      if (testsSelected.includes('skills') && !testsSelected.includes('betesa')) {
        navigate(platformAdminRoutes.ProcessSkills)
        return
      }
    }
  }

  useEffect(() => {
    if (Object.keys(stepThreeData).length > 0) {
      const listTestsSelected: TypeTests[] = []
      if (stepThreeData?.useBetesaTest) {
        listTestsSelected.push('betesa')
      }
      if (stepThreeData?.useSkillsTest) {
        listTestsSelected.push('skills')
      }
      setTestsSelected(listTestsSelected)
    }
  }, [stepThreeData])

  return (
    <MainLayout>
      <Container>
        <H2 marginBottom="30px">Configuración de pruebas</H2>
        <Stepper activeStep={2} steps={STEPS_NEW_PROCESS} />
        <P margin="30px 0px 20px 6px">Seleccione pruebas para aplicar a los participantes</P>
        <Row>
          {((stepOneData.levelName === 'Operativo' &&
            (stepOneData.inventoryType === InventoryTypeIlimit ||
              stepOneData.inventoryType === InventoryTypeIlimitIntegration)) ||
            stepOneData.levelName === 'Táctico' ||
            stepOneData.levelName === 'Estratégico') && (
            <CardTypeTest
              title="Betesa - Talentos Naturales"
              image={brainImage}
              onClick={() => handleSelectTest('betesa')}
              checked={testsSelected && (testsSelected.includes('betesa') || false)}
            />
          )}
          <CardTypeTest
            title={t('global.common.skillText')}
            image={boxImage}
            onClick={() => handleSelectTest('skills')}
            checked={testsSelected && (testsSelected.includes('skills') || false)}
          />
        </Row>
        <Row style={{ flex: 1 }} justifyContent="space-between" alignItems="flex-end">
          <BackButton onClick={() => navigate(platformAdminRoutes.PreconfigProcess)} />
          <Button disabled={testsSelected.length < 1} type="submit" onClick={handleNextStep}>
            Siguiente
          </Button>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default SelectTestsType
