import { FC, useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  ContainerActions,
  TextViewMore,
  EditContainer,
  TextEdit,
} from './styled';
import BluesiteTableComponent from 'views/components/UI/BluesiteTable';
import { Button, StrokedButton } from 'views/components/UI/Buttons';
import { MainLayout } from 'views/layouts';
import EditIcon from 'assets/icons/EditCoin';
import getAllCompanies from 'services/companies/getAllCompanies';
import getAllCompaniesById from 'services/companies/getAllCompaniesById';
import { headers } from './constants';
import { setCompanyId } from './store';
import { useDispatch } from 'react-redux';
import { useNotification } from 'lib/context/notification.context';

const MIN_LIMIT = 10;

interface ActionComponentProps {
  id: number;
}

const ActionComponent: FC<ActionComponentProps> = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getError } = useNotification();
  return (
    <ContainerActions>
      <Link to={`/company?companyId=${id}`}>
        <TextViewMore onClick={() => {
          dispatch(setCompanyId(id));
          if (id) navigate(`/company?companyId=${id}`);
          else getError('No se pudo obtener el id de la empresa');
        }}>
          Ver más
        </TextViewMore>
      </Link>
      <Link to={`/companies/update?companyId=${id}`}>
        <EditContainer>
          <EditIcon />
          <TextEdit>Editar</TextEdit>
        </EditContainer>
      </Link>
    </ContainerActions>
  );
};

const Companies: FC = () => {

  const { token } = useSelector((state: any) => state.user);
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [showCompaniesUnderMyResponsability, setShowCompaniesUnderMyResponsability] = useState(false);
  const [totalPagesAllCompanies, setTotalPagesAllCompanies] = useState(0)
  const [currentPageAllCompanies, setCurrentPageAllCompanies] = useState(1)
  const [totalPagesCompaniesUnderMyResponsability, setTotalPagesCompaniesUnderMyResponsability] = useState(0)
  const [currentPageCompaniesUnderMyResponsability, setCurrentPageCompaniesUnderMyResponsability] = useState(1)
  const [searchParam, setSearchParam] = useState('');

  const handleShowCompaniesUnderMyResponsability = useCallback((page = 1, limit = MIN_LIMIT) => {
    if (token) {
      getAllCompaniesById(page, limit).then((res: any) => {
        setCompaniesData(res.companies);
        setTotalPagesCompaniesUnderMyResponsability(res.total_pages);
        setShowCompaniesUnderMyResponsability(true);
      })
    }
  }, [token])


  const handleShowAllCompanies = useCallback((page = 1, limit = MIN_LIMIT) => {
    if (token) {
      getAllCompanies(token, page, limit).then((res) => {
        if (res.status === 'OK' || res.status === 'success') {
          const { data, total_pages: TotalPages } = res
          setCompaniesData(data);
          setTotalPagesAllCompanies(TotalPages);
          setShowCompaniesUnderMyResponsability(false);
        } else {
          console.error('Error in get all companies')
        }
      })
    }
  }, [token])

  const fetchSearchData = useCallback((searchValue: any, page = 1, limit = MIN_LIMIT) => {
    setSearchParam(searchValue);
    if (searchValue === "") {
      handleShowAllCompanies(page, limit);
    } else {
      getAllCompanies(token, page, limit, searchValue).then((res) => {
        setCompaniesData(res.data);
        setTotalPagesAllCompanies(res.total_pages);
        setShowCompaniesUnderMyResponsability(false);
      })
    }
  }, [token, handleShowAllCompanies]);

  useEffect(() => {
    if (searchParam.length > 0) {
      fetchSearchData(searchParam);
    }
  }, [searchParam, fetchSearchData]);

  useEffect(() => {
    if (searchParam.length <= 0) {
      handleShowAllCompanies()
    }
  }, [searchParam, handleShowAllCompanies]);


  const handlePaginationAllCompanies = useCallback((page = 1) => {

    if (token) {
      setCurrentPageAllCompanies(page);
      getAllCompanies(token, page, MIN_LIMIT).then((res) => {
        if (res.status === 'OK' || res.status === 'success') {
          const { data, total_pages: TotalPages } = res
          setCompaniesData(data);
          setTotalPagesAllCompanies(TotalPages);
        } else {
          console.error('Error in get all companies')
        }
      })
    }
  }, [token])


  const handlePaginationCompaniesUnderMyResponsability = useCallback((page = 1) => {
    if (token) {
      setCurrentPageCompaniesUnderMyResponsability(page);
      getAllCompaniesById(page, MIN_LIMIT).then((res: any) => {
        setCompaniesData(res.companies);
        setTotalPagesCompaniesUnderMyResponsability(res.total_pages);
      })
    }
  }
    , [token])


  return (
    <MainLayout>
      <Container>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 35,
            marginTop: 50,
            alignItems: 'baseline',
          }}
        >
          <h2 style={{ fontSize: 32 }}>Compañías</h2>
          <Link to='/companies/new'>
            <Button type="button">
              Nueva compañía
            </Button>
          </Link>
        </div>

        <div>
          {showCompaniesUnderMyResponsability ? (
            <StrokedButton
              style={{
                backgroundColor: '#2D749C',
                color: '#fff',
              }}
              onClick={(handleShowAllCompanies)}
              type="button"
            >
              Dejar de mostrar solo compañías a mi cargo
            </StrokedButton>
          ) : (
            <StrokedButton
              onClick={handleShowCompaniesUnderMyResponsability}
              type="button"
            >
              Mostrar solo compañías a mi cargo
            </StrokedButton>
          )}
        </div>

        <BluesiteTableComponent
          ActionComponent={ActionComponent}
          data={companiesData}
          headers={headers}
          searchBar={true}
          totalPages={showCompaniesUnderMyResponsability ? totalPagesCompaniesUnderMyResponsability : totalPagesAllCompanies}
          onSearch={fetchSearchData}
          handlePagination={(e) => {
            if (showCompaniesUnderMyResponsability) { handlePaginationCompaniesUnderMyResponsability(e) } else { handlePaginationAllCompanies(e) }
          }}
          currentPage={showCompaniesUnderMyResponsability ? currentPageCompaniesUnderMyResponsability : currentPageAllCompanies}
          setSearchParam={setSearchParam}
          handleSearchFunction={() => fetchSearchData(searchParam)}
          searchLabel="Buscar empresa por:"
        />
      </Container>
    </MainLayout>
  );
};

export default Companies;
