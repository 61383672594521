/* eslint-disable prefer-const */
import { useState, useEffect } from 'react'
import { Option, DroppedItem } from 'views/screens/Evaluated-User/store'

export function useDrop(initialOptions: Option[], history?:any) {

 
  const [historyState, setHistoryState] = useState<{ [optionId: number]: DroppedItem[] }>(history)
  const [newHistoryState, setNewHistoryState] = useState<{ [optionId: number]: DroppedItem[] }>({})

  const [itemsUnselected, setItemsUnselected] = useState<Option[]>(initialOptions)
  const [itemsSelected, setItemsSelected] = useState<{ [optionId: number]: DroppedItem[] }>({})
  
  useEffect(() => {
    setHistoryState(history);
  }, [history]);


  const handleDragStart = (event: any, item: any) => {
    event.dataTransfer.setData('item', JSON.stringify(item))
  }

  const handleDragOver = (event: any) => {
    event.preventDefault()
  }
  
  const handleDrop =  (event: any, ) => {
    event.preventDefault()
    
    const targetArea = event.target.getAttribute('data-area')
    const targetId = event.target.getAttribute('data-id')

    

    const itemString = event.dataTransfer.getData('item')
    const item: Option = JSON.parse(itemString)
    let newInitialOptions = [...itemsUnselected]
    let updatedItemsSelected = { ...itemsSelected }

    
    
    if (targetArea === 'initialArea' && Object.keys(historyState).length > 0) {
      newInitialOptions = [];
      updatedItemsSelected = {...historyState};
    
      const optionIndex = parseInt(item?.optionIndex, 10);
  
      if (!isNaN(optionIndex) && updatedItemsSelected[optionIndex]) {
        updatedItemsSelected[optionIndex].pop();
        setItemsSelected({...updatedItemsSelected});
        newInitialOptions.push(item);
        setItemsUnselected([...newInitialOptions]);
      }
    }


    setItemsUnselected([...newInitialOptions]);

    if (targetArea === 'initialArea' && Object.keys(historyState).length <= 0) {
      const optionIndex = parseInt(item?.optionIndex, 10);
      if (!isNaN(optionIndex) && updatedItemsSelected[optionIndex]) { 
        updatedItemsSelected[optionIndex].pop();
        setItemsSelected({...updatedItemsSelected});
        newInitialOptions.push(item);
        setItemsUnselected([...newInitialOptions]);
      }

    } else if (targetArea === 'dropArea') {
      const targetOptionId = parseInt(targetId, 10); 
      const originalOptionId = item.optionIndex
  
      if (originalOptionId) {
          let originalOptionIdInt = originalOptionId;
          if (updatedItemsSelected[originalOptionIdInt]) {
              updatedItemsSelected[originalOptionIdInt] = updatedItemsSelected[originalOptionIdInt].filter(droppedItem => droppedItem.item.optionIndex !== item.id);
          }
          if (!updatedItemsSelected[targetOptionId] || updatedItemsSelected[targetOptionId].length === 0) {
              updatedItemsSelected[targetOptionId] = [];
          }
          updatedItemsSelected[targetOptionId].push({
              optionIndex: targetOptionId,
              item: item.item,
          });
  
          setItemsSelected({...updatedItemsSelected});
      }
  
      newInitialOptions = newInitialOptions.filter(optionItem => optionItem.id !== item.id);
      setItemsUnselected([...newInitialOptions]);
  
      if (!updatedItemsSelected[targetOptionId] || updatedItemsSelected[targetOptionId].length === 0) {
          updatedItemsSelected[targetOptionId] = updatedItemsSelected[targetOptionId] || [];
  
          updatedItemsSelected[targetOptionId].push({
              optionIndex: targetOptionId,
              item: item,
          });
  
          newInitialOptions.splice(item.id, 1);
          setNewHistoryState({...updatedItemsSelected})
          setItemsSelected({...updatedItemsSelected});
          setItemsUnselected([...newInitialOptions]);
      }
  }
}

  return {
    initialOptions: itemsUnselected,
    droppedItems: itemsSelected,
    initialOptionsState: itemsUnselected,
    newHistoryState,
    setInitialOptionsState: setItemsUnselected,
    handleDragStart,
    handleDragOver,
    handleDrop,

  }
}
