import { FC } from 'react'
import { Count, Container } from './styled'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/es'

interface FooterPageProps {
  page: number
  totalPages: number
  name: string
  lastName: string
  answerDate?: string
}

const FooterPage: FC<FooterPageProps> = ({ page = 1, totalPages, name, lastName, answerDate }) => {
  dayjs.extend(utc)
  const isPageActive = (pageNumber: number) => {
    return pageNumber === page
  }

  const generatePagination = () => {
    const items = []

    for (let i = 1; i <= totalPages; i++) {
      items.push(<Count key={i} active={isPageActive(i)}></Count>)
    }

    return items
  }
  function formatDate(date: any) {
    const formattedDate = dayjs.utc(date).locale('es').format('DD [de] MMMM [de] YYYY')
    return formattedDate
  }

  const date = new Date(answerDate || new Date())
  const reportDate = formatDate(date)
  return (
    <Container>
      <p>
        {name} {lastName} <br />
        {reportDate}
      </p>
      <div className="count_page">
        <p>
          {page} de {totalPages}
        </p>
        <div className="row">{generatePagination()}</div>
      </div>
    </Container>
  )
}

export default FooterPage
