import { RootState } from 'lib/redux'

export const processDetailSelector = (state: RootState) => state.processDetail.data.processDetail

export const idealProfileBetesaSelector = (state: RootState) => state.processDetail.data.idealProfileBetesa

export const idealProfileSkillsSelector = (state: RootState) => state.processDetail.data.idealProfileSkills

export const allPlatformAdminsSelector = (state: RootState) => state.processDetail.data.allPlatformAdmins

export const currentPageSelector = (state: RootState) => state.processDetail.pagination.page

export const totalPagesSelector = (state: RootState) => state.processDetail.pagination.totalPages

export const saveChangesSelector = (state: RootState) => state.processDetail.ui.saveChanges

export const isDirtySelector = (state: RootState) => state.processDetail.ui.isDirty

export const tableChangedSelector = (state: RootState) => state.processDetail.ui.tableChanged

export const isUpdatedSelector = (state: RootState) => state.processDetail.ui.isUpdated

export const adminIdsToDeleteSelector = (state: RootState) => state.processDetail.data.adminIdsToDelete

export const adminIdsToAddSelector = (state: RootState) => state.processDetail.data.adminIdsToAdd

export const adminIdsToSaveSelector = (state: RootState) => state.processDetail.data.adminIdsToSave
