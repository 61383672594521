import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  overflow: auto;
`;

export const ManageAdminText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-bottom: 20px;
  margin-left: 48px;
`;

export const BluesiteTableWrapper = styled.div`
  width: 80%;
  margin-left: 48px;
  margin-bottom: 20px;
`;