import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { MainLayout } from 'views/layouts';
import TitleDiv from 'views/components/UI/TitleDiv';
import Input from 'views/components/UI/TextInput';
import Select from 'views/components/UI/Select';
import { Button } from 'views/components/UI/Buttons';
import { InputGridDiv, ContainerRow, BtnGoBack, TextArea, LabelContainer, Label, OptionContainerItem, OptionContainerLabel, Row, TextQuadrantSelect } from './styled';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import getQuestionSkillsById from 'services/common/questions/getById';
import updateQuestionSkills from 'services/common/questions/update';
import { QUADRANT_OPTIONS, TYPE_QUESTION } from 'constants/global.constants';


const typeOptions = TYPE_QUESTION
const quadrantOptions = QUADRANT_OPTIONS

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Este campo es requerido'),
  questionText: Yup.string().trim().required('Este campo es requerido'),
});



const UpdateQuestion: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { questionId, typeQuestion, questionnaireId, chapterId } = location.state;

  const [infoQuestion, setInfoQuestion] = useState<any | null>(null);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    getQuestionSkillsById(questionId).then((res: any) => {
      if (res.status === 'OK') {
        setInfoQuestion(res.question);
        if (res.options) {
          setOptions(res.options);
        }
      }
    });
  }, [questionId]);


  const initialValues = {
    type: typeQuestion || '',
    questionText: infoQuestion?.text || '',
    options: options || [],
    quadrantOption1: '',
  };

  const handleSubmit = (values: any) => {
    type Data = {
      text: string,
      typeQuestion: string,
      reverseOrder: boolean,
      typeCategoryTestId: number,
      chapterId: number,
      dimensionId: number,
      optionsQuestions: any[] | [],
    }
    values.options.forEach((option: any) => {
      delete option.question_id;
    });

    const valuesFormated: Data = {
      text: values.questionText,
      typeQuestion: values.type,
      reverseOrder: false,
      typeCategoryTestId: questionnaireId,
      chapterId: chapterId,
      dimensionId: infoQuestion.dimensionId,
      optionsQuestions: values.options,
    };

    updateQuestionSkills(valuesFormated, parseInt(questionId)).then((res: any) => {
      if (res.status === 'OK') {
        navigate(-1);
      }
    });
  };

  return (
    <MainLayout>
      <TitleDiv>
        <h2 style={{ fontSize: 32 }}>Editar pregunta de cuestionario</h2>
      </TitleDiv>
      {
        infoQuestion && (
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}
            validate={(values: any) => {
              const errors: any = {};
              values.options.map((option: { text: any; }, index: any) => {
                const fieldName = `option${index}`;
                if (!String(option.text).trim()) {
                  errors[fieldName as keyof typeof errors] = ``;
                }
                return null
              })
              return errors;
            }}
          >
            {({ values, isSubmitting, errors, setFieldValue }) => (
              <Form>
                <InputGridDiv>
                  <div style={{ width: '50%' }}>
                    <Field
                      as={Select}
                      label="Tipo de pregunta"
                      name="type"
                      options={typeOptions}
                      value={values.type}
                      hideNeverChoice
                      onChange={(e: any) => {
                        setFieldValue('type', e);
                      }}
                    />
                  </div>
                  <div style={{ margin: '20px 0px' }}>
                    <LabelContainer>
                      <Label>Texto de la pregunta</Label>
                    </LabelContainer>
                    <Field as={TextArea} style={{ marginTop: 5 }} placeholder="Texto de la pregunta" name="questionText" />
                    <div style={{ color: 'red' }} >
                      <ErrorMessage name="questionText" />
                    </div>
                  </div>
                  <LabelContainer>
                    <Label>Opciones de respuesta</Label>
                  </LabelContainer>
                  {values.options.map((option: any, index: number) => (
                    <OptionContainerItem key={index}>
                      <OptionContainerLabel>Opción {index + 1}</OptionContainerLabel>
                      <Field
                        as={Input} style={{ width: '50%' }}
                        type="text" name={`option${index}`}
                        label={`opción ${index + 1}`}
                        value={option.text}
                        onChange={(e: any) => {
                          setFieldValue(`options[${index}].text`, e.target.value);
                        }}
                      />
                      {
                        values.type === 'DGD' ? (
                          <Row style={{ width: '40%', justifyContent: 'flex-end' }} >
                            <TextQuadrantSelect >Cuadrante asociado</TextQuadrantSelect>
                            <Select
                              label="Cuadrante"
                              options={quadrantOptions}
                              onChange={(e) => setFieldValue(`options[${index}].quadrant`, e)}
                              value={option.quadrant}
                              width='40%'
                            />
                          </Row>
                        ) : null
                      }
                      {!option.text.trim() ? <p style={{ color: 'red' }}>Llena los campos de Opción {index + 1}</p> : null}
                      <ErrorMessage name={`option${index}`} />
                    </OptionContainerItem>
                  ))}
                </InputGridDiv>
                <ContainerRow>
                  <BtnGoBack onClick={() => navigate(-1)}>Volver</BtnGoBack>
                  <Button disabled={isSubmitting || Object.keys(errors).length > 0} type="submit">Guardar</Button>
                </ContainerRow>
              </Form>
            )}
          </Formik>
        )
      }
    </MainLayout>
  );

};

export default UpdateQuestion;
