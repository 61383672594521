import axios from 'axios'



/**
 * @description service to get all chapters
 * @param token
 */

type IChapter = {
    id: number,
    name: string,
    number: string,
    description: string,
}


interface IFindUserResponse  {
    status: string | 'success' | 'error'
    chapters: IChapter[]
}

interface IFilters {
  id?: string | number
  name?: string
  number?: string
  description?: string
  typeCategoryTestId?: number
}


const getChapters = async (filters: IFilters | undefined ) => {

  let apiUrl = `${process.env.REACT_APP_MAIN_API}/chapter`

  // applying filters

    if (filters?.id) {
      apiUrl += `?id=${filters.id}`
    }

    if (filters?.name) {
      apiUrl += `${ filters.id? '&' : '?' }name=${filters.name}`
    }

    if (filters?.number) {
      apiUrl += `${ filters.id || filters.name ? '&' : '?' }number=${filters.number}`
    }

    if (filters?.description) {
      apiUrl += `${ filters.id || filters.name || filters.number ? '&' : '?' }description=${filters.description}`
    }

    if (filters?.typeCategoryTestId) {
      apiUrl += `${ filters.id || filters.name || filters.number || filters.description ? '&' : '?' }type_category_test_id=${filters.typeCategoryTestId}`
    }


    // post get
    const response: IFindUserResponse = await axios.get(apiUrl).then((res) => {
      return res.data
    }).catch((error) => {
      return { ok: false, message: error.message };
    })
    return response
}

export default getChapters
