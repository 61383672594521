import axios from 'axios'

const baseUrl = `${process.env.REACT_APP_MAIN_API}/person/user/document`

interface Iprops {
  document: string
  personId: number
  token: string
}

const validateDocument = async (props: Iprops) => {
  const { document, personId, token } = props
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const body = {
    document,
    personId,
  }

  const res = await axios
    .post(baseUrl, body, { headers })
    .then((response) => {
      return { data: response.data, status: response.status }
    })
    .catch((error) => {
      console.error('error on validating document', error)
      return { ok: false, message: error.message }
    })
  return res
}

export default validateDocument
