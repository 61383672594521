import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  HeadContainer,
  Row,
  GridContainer,
  TitleGrid,
  Divider,
  GridCompetences,
  GridItem,
  GridMinimunRecord,
  FooterContent,
  BackButton,
  TextBackButton,
  BetesaContainer,
} from './styled'
import Input from 'views/components/UI/TextInput'
import { P, H4, H2 } from 'views/components/UI/Text'
import { Button, StrokedButton } from 'views/components/UI/Buttons'
import { useNotification } from 'lib/context/notification.context'
import Select from 'views/components/UI/Select'
import ModalAddSkill from '../../skills/edit/modal-add-skills'
import BrainQuadrant from 'views/containers/BrainQuadrant/BrainQuadrant'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import _ from 'lodash'
import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  stepOneSelector,
  stepFourSelector,
  setStepFourTrigger,
  setStepTwoTrigger,
  setStepThreeTrigger,
} from 'ducks/createProcess'
import { DetailRoleFamily, CompetenceSkills, ALERT_LEVELS, BetesaIdealProfile } from 'types/common'
import { parseQuadrantFullNameToShortName } from 'utils/common'
import { useTranslation } from 'react-i18next'

interface Props {
  onCancel: () => void
  data: DetailRoleFamily
  onAddNewSkill?: (skill: CompetenceSkills) => void
  onFinishEdit: () => void
}

const EditSkillAndBetesaRoleFamily = (props: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { onCancel, data, onAddNewSkill, onFinishEdit } = props
  const { getWarning, getSuccess } = useNotification()
  const stepOneData = useSelector(stepOneSelector)
  const stepFourData = useSelector(stepFourSelector)

  const alertLevelLabels = Object.keys(ALERT_LEVELS)

  const alertLevelOptions = Object.values(ALERT_LEVELS).map((value: number[], index) => {
    return {
      value: `${value}`,
      label: alertLevelLabels[index],
    }
  })

  const nameRoleFamilySelected = data.role_families[0].name || ''

  const skillsSelected = data?.competence_skills?.length > 0 ? [...data?.competence_skills] : []

  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [skills, setSkills] = useState<CompetenceSkills[]>(skillsSelected)
  const [alertLevel, setAlertLevel] = useState<number[]>(data.ideal_profile[0]?.alert_level_range || [])
  const [betesaIdealProfile, setBetesaIdealProfile] = useState<BetesaIdealProfile[]>(data.ideal_profile || [])

  const listIdSkills = _.uniqBy(skills, 'id').map((item) => item.id) || []

  const handleFinishEditRoleFamily = () => {
    setLoading(true)
    if (!loading) {
      dispatch(
        setStepTwoTrigger.run({
          isRoleFamilySelected: true,
          processId: stepOneData.processId,
          roleFamilyName: nameRoleFamilySelected,
        })
      )

      setTimeout(() => {
        if (listIdSkills.length === 0 && !data.ideal_profile.length) {
          getWarning('Debe agregar al menos una competencia')
          return
        }

        if (!data.ideal_profile.length && alertLevel.length === 0 && !skills.length) {
          getWarning('Debe agregar al menos un nivel de alerta')
          return
        }

        if (!data.ideal_profile.length && betesaIdealProfile.length === 0 && !skills.length) {
          getWarning('Debe agregar al menos un perfil ideal')
          return
        }

        const dataToEditRoleFamily: any = {
          processId: stepOneData?.processId,
          isEditingRoleFamily: Object.keys(stepFourData).length > 0,
        }

        if (skills.length > 0) {
          const skillsToEditThisRoleFamily = skills.map((item) => {
            return {
              skill_id: item.id,
              weight: item.weight,
            }
          })
          dataToEditRoleFamily.competencesIdealProfile = skillsToEditThisRoleFamily
        }

        if (betesaIdealProfile.length > 0) {
          const buildProfileValue = betesaIdealProfile.map((item) => {
            return {
              quadrant: item.quadrant.length === 2 ? item.quadrant : parseQuadrantFullNameToShortName(item.quadrant),
              priority: item.priority,
              min: item.min,
              max: item.max,
            }
          })

          const betesaIdealProfileToEditThisRoleFamily = {
            alertLevelId:
              alertLevel.length > 0
                ? alertLevel.map((item: any) => parseInt(item))
                : data.ideal_profile[0].alert_level_range,
            profileValues: buildProfileValue,
            betesaSkills: [],
          }

          dataToEditRoleFamily.betesaIdealProfile = betesaIdealProfileToEditThisRoleFamily
        }

        dispatch(
          setStepThreeTrigger.run({
            processId: stepOneData.processId,
            onlineCourse: false,
            useBetesaTest: betesaIdealProfile.length > 0,
            useSkillsTest: skills.length > 0,
          })
        )

        try {
          dispatch(setStepFourTrigger.run(dataToEditRoleFamily))
          setTimeout(() => {
            onFinishEdit()
          }, 1000)
        } catch (error) {
          console.error('error saving the skills and ideal profile', error)
        }
        getSuccess('Se ha editado la familia de rol ')
        setLoading(false)
      }, 2000)
    }
  }

  const handleRemoveSkill = (id: number) => {
    const newSkills = skills.filter((item) => item.id !== id)
    setSkills(newSkills)
  }

  return (
    <Container>
      {skills.length > 0 && (
        <>
          <H2 margin="0px 0px 0px 0px">Editar {t('global.common.skillText')}</H2>
          <HeadContainer>
            <Row width="50%">
              <H4 margin="0px 0px 0px 0px">{t('global.common.skillText')} para este cargo</H4>
            </Row>
            <Row justify="flex-end" width="50%">
              <StrokedButton
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                Agregar {t('global.common.skillText')}
              </StrokedButton>
            </Row>
          </HeadContainer>
          {/* Grid competences */}
          <GridContainer>
            <TitleGrid>Nombre</TitleGrid>
            <Divider />
            <GridCompetences>
              {skills.map((item: any) => {
                return (
                  <>
                    <GridItem justify="center" key={item.id}>
                      <P>{item.skill || item.skill_name}</P>
                    </GridItem>
                    <GridItem>
                      <Row height="40px" justify="flex-end">
                        <DeleteOutlineOutlinedIcon
                          onClick={() => handleRemoveSkill(item.id)}
                          style={{
                            color: '#FF0000',
                            cursor: 'pointer',
                            marginRight: '20px',
                          }}
                        />
                      </Row>
                    </GridItem>
                  </>
                )
              })}
            </GridCompetences>
          </GridContainer>
        </>
      )}
      {skills.length > 0 && (
        <GridContainer>
          <H4 marginTop="30px">Puntaje mínimo por competencia</H4>
          <TitleGrid>Nombre</TitleGrid>
          <Divider />
          <GridMinimunRecord>
            {skills.map((item, index) => {
              if (item.skill_id) {
                item.id = item.skill_id
              }

              return (
                <>
                  <GridItem justify="center">
                    <P key={index}>{item.skill || item.skill_name}</P>
                  </GridItem>
                  <GridItem>
                    <Row width="50%" justify="flex-start">
                      <Input
                        name="minimunRecord"
                        value={item?.weight || ''}
                        label="Puntaje mínimo"
                        type="text"
                        onChange={(e: any) => {
                          let value = e.target.value
                          if (!/^\d*\.?\d*$/.test(value)) value = ''
                          else if (parseFloat(value) > 100) value = '100'
                          else if (parseFloat(value) < 0) value = '0'
                          const newSkills = skills.map((skill) =>
                            skill.id === item.id ? { ...skill, weight: value } : skill
                          )
                          setSkills(newSkills)
                        }}
                      />
                    </Row>
                  </GridItem>
                </>
              )
            })}
          </GridMinimunRecord>
        </GridContainer>
      )}
      {data.ideal_profile.length > 0 && (
        <BetesaContainer>
          <H2 margin="16px 0px 32px 0px">Editar perfil ideal Betesa</H2>
          <div style={{ width: '30%', marginLeft: '-10px' }}>
            <Select
              label="Nivel de alerta"
              options={alertLevelOptions}
              value={alertLevel || ''}
              onChange={(e: string) => {
                const value = e.split(',').map((item) => parseInt(item))
                setAlertLevel(value)
              }}
              hideNeverChoice
            />
          </div>
          <BrainQuadrant onChange={(e) => setBetesaIdealProfile(e)} quadrantData={data.ideal_profile} />
        </BetesaContainer>
      )}
      <ModalAddSkill
        skillsSelected={listIdSkills}
        open={openModal}
        setOpen={setOpenModal}
        onAddNewSkill={(e: any) => {
          if (!skills.includes(e)) {
            setSkills([...skills, e])
            if (onAddNewSkill) onAddNewSkill(e)
          } else {
            getWarning('Ya existe esta competencia')
          }
        }}
      />
      <FooterContent>
        <BackButton>
          <ArrowLeftIcon />
          <TextBackButton onClick={onCancel}>Atrás</TextBackButton>
        </BackButton>
        <Button
          disabled={skills.some((item) => item.weight === undefined || item.weight === 0) }
          onClick={handleFinishEditRoleFamily}
          onDisabledClick={() => {
            if (skills) {
              if (skills.length === 0) {
                getWarning('Debe agregar al menos una competencia')
                return
              }

              if (skills.length > 0 && skills.some((item) => item.weight === undefined || item.weight === 0)) {
                getWarning('Debe agregar un puntaje mínimo a cada competencia')
                return
              }
            }

            if (betesaIdealProfile.length === 0) {
              getWarning('Debe agregar al menos un perfil ideal')
              return
            }

            if (alertLevel.length === 0) {
              getWarning('Debe agregar al menos un nivel de alerta')
              return
            }

            if (
              betesaIdealProfile.length > 0 &&
              betesaIdealProfile.some((item) => item.min === undefined || item.max === undefined)
            ) {
              getWarning('Debe agregar un rango de puntaje a cada perfil ideal')
              return
            }
          }}
          type="submit"
        >
          Guardar
        </Button>
      </FooterContent>
      <ModalLoader isLoading={loading} />
    </Container>
  )
}

export default EditSkillAndBetesaRoleFamily
