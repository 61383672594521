import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MainLayout } from 'views/layouts'
import { useNotification } from 'lib/context/notification.context'
import { fetchHomeUserEvaluated, fetchUserConsented } from '../../services/fetch-home-user-evaluated'
import { GetDataHome, cleanPreviousAnswers, setBetesaResultId, setCurrentQuestion } from '../../store'
import {
  setProcessSelected,
  setHaveSkillFinished,
  setHaveBetesaInterpreted,
  setUserTestId,
  userTestIdSelector,
  setIsBetesaTest,
  dataHomeGeneralSelector,
} from 'ducks/evaluatedUser'
import { logout } from 'ducks/user'
import { logout as logoutAuth } from 'ducks/auth'

import {
  CardTest,
  CardTestItem,
  CardTestItemContentDescription,
  CardTestItemContentDiv,
  CardTestItemContentDurationDiv,
  CardTestItemContentParagraph,
  CardTestItemContentStartDiv,
  CardTestItemContentTitle,
  CardTestItemImgDiv,
  ContainerCards,
  ContainerTest,
  TestItemImg,
  TitleCard,
  NotTest,
  ButtonContainer,
} from './styled'
import skillsImg from 'assets/images/box-icon.png'
import talentsImg from 'assets/images/brain-icon.png'
import ClockIcon from 'assets/icons/ClockIcon'
import { StrokedButton } from 'views/components/UI/Buttons'
import { ProcessSelected } from 'types/common'
import { setTestId } from 'views/screens/super-admin/percentiles/store'
import { setDataHomeGeneral } from 'ducks/evaluatedUser/selectors/dataHome'
import { useTranslation } from 'react-i18next'

interface StartTest {
  companyName: string
  testLanguage?: string
  testType: string
  companyId: number
  processId?: number
}

function Home() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getError } = useNotification()
  const [itemsThatMatch, setItemsThatMatch] = useState<GetDataHome[]>([])
  const userTestIdStored = useSelector(userTestIdSelector)
  const dataHome = useSelector(dataHomeGeneralSelector);
  const [currentBetesaResultId, setCurrentBetesaResultId] = useState(null);
  const { t } = useTranslation();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchHomeUserEvaluated()
        if (response.length === 0) {
          getError('No hay procesos disponibles')
          dispatch(logout())
          dispatch(logoutAuth())
        }

        response.filter((item: any) => {
          const typeCategoryFinished = item.type_category.filter((typeFinishInfo: any) => typeFinishInfo.isFinished || typeFinishInfo.isInterpreted)

          if (typeCategoryFinished.length === 1) {
            if (typeCategoryFinished[0].isFinished) {
              dispatch(setHaveSkillFinished(true))
            } else {
              dispatch(setHaveBetesaInterpreted(true))
            }
          }

          if (typeCategoryFinished.length === 2) {
            dispatch(setHaveSkillFinished(true))
            dispatch(setHaveBetesaInterpreted(true))
          }

          return true
        })
        dispatch(setDataHomeGeneral(response))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [dispatch, getError])

  const handleSetProcessSelected = ({
    companyName,
    testLanguage,
    testType,
    companyId,
    userTestId,
    processId,
  }: ProcessSelected) => {
    dispatch(setProcessSelected({ companyName, testLanguage, testType, companyId, userTestId, processId }))
  }

  const handleStartTest = (
    { companyName, testLanguage, testType, companyId, processId }: StartTest,
    testId: number,
    userTestId: number
  ) => {
    handleSetProcessSelected({
      companyName: companyName,
      testLanguage: testLanguage ?? 'ES',
      testType: testType,
      companyId,
      userTestId,
      processId,
    })

    if (userTestIdStored !== userTestId) {
      dispatch(setCurrentQuestion(0))
      dispatch(cleanPreviousAnswers())
    }
    dispatch(setTestId(testId))
    dispatch(setIsBetesaTest(testType === 'Betesa Premium'))
    dispatch(setUserTestId(userTestId))
    navigate('/test/start')
  }


  useEffect(() => {
    if (dataHome) {
      setItemsThatMatch(dataHome
        .map((item) => {
          const typesNotFinished = item.type_category.filter((typeFinishFilter) => !typeFinishFilter.isFinished)
          return { ...item, type_category: typesNotFinished }
        })
        .filter((item) => item.type_category.length > 0))
    }
  }, [dataHome])

  useEffect(() => {
    const getCardTextStartButton = async () => {
      for (const item of itemsThatMatch) {
        for (const typeInfo of item.type_category) {
          if (typeInfo.category_test_name === 'Betesa Premium') {
            const response = await fetchUserConsented(String(typeInfo.user_test_id), true);
            const newBetesaResultId = response.data?.betesaResult?.id ?? 0;
  
            if (newBetesaResultId !== currentBetesaResultId) {
              setCurrentBetesaResultId(newBetesaResultId);
              dispatch(setBetesaResultId(newBetesaResultId));
            }
          }
        }
      }
    };
  
    if (itemsThatMatch.length > 0) {
      getCardTextStartButton();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  

  return (
    <MainLayout style={{ maxWidth: '100%' }}>
      {itemsThatMatch.length > 0 ? (
        <ContainerTest isSingleCard={itemsThatMatch.length === 1}>
          <ContainerCards isSingleCard={itemsThatMatch.length === 1}>
            {itemsThatMatch.map((item, index) => (
              <CardTest key={`${item.company_name}-card-${index}`}>
                <TitleCard>
                  {item.process_name} - {item.company_name}
                </TitleCard>
                <CardTestItemContentParagraph>Pruebas pendientes por realizar</CardTestItemContentParagraph>
                {item?.type_category.map((typeTestInfo, indexTest) =>
                  typeTestInfo.isFinished || typeTestInfo.isInterpreted || indexTest > 1 ? null : (
                    <CardTestItem key={`${typeTestInfo.test_id}-typeTestInfo-of-test-${indexTest}`}>
                      <>
                        <CardTestItemImgDiv>
                          {typeTestInfo.category_test_name === 'Betesa Premium' ? (
                            <TestItemImg src={talentsImg} alt="Talentos naturales - " />
                          ) : (
                            <TestItemImg src={skillsImg} alt="Competencias " />
                          )}
                        </CardTestItemImgDiv>
                        <CardTestItemContentDiv>
                          {typeTestInfo.category_test_name === 'Betesa Premium' ? (
                            <CardTestItemContentTitle>Betesa - Talentos Naturales </CardTestItemContentTitle>
                          ) : (
                            <CardTestItemContentTitle>{t('global.common.skillText')}</CardTestItemContentTitle>
                          )}
                          <CardTestItemContentStartDiv>
                            {typeTestInfo.category_test_name === 'Betesa Premium' ? (
                              <CardTestItemContentDurationDiv>
                                <ClockIcon />
                                <CardTestItemContentDescription> 25 mn </CardTestItemContentDescription>
                              </CardTestItemContentDurationDiv>
                            ) : (
                              <CardTestItemContentDurationDiv>
                                <ClockIcon />
                                <CardTestItemContentDescription> 25 mn </CardTestItemContentDescription>
                              </CardTestItemContentDurationDiv>
                            )}
                            <ButtonContainer>
                              <StrokedButton
                                onClick={() =>
                                  handleStartTest(
                                    {
                                      companyName: item.company_name,
                                      testLanguage: 'ES',
                                      testType: typeTestInfo.category_test_name,
                                      companyId: item.company_id,
                                      processId: item.process_id,
                                    },
                                    typeTestInfo.test_id,
                                    typeTestInfo.user_test_id
                                  )
                                }
                                type="submit"
                              >
                                {typeTestInfo.informed_consent ? (
                                  'Retomar'
                                ) : (
                                  'Iniciar'
                                )}
                              </StrokedButton>
                            </ButtonContainer>
                          </CardTestItemContentStartDiv>
                        </CardTestItemContentDiv>
                      </>
                    </CardTestItem>
                  )
                )}
              </CardTest>
            ))}
          </ContainerCards>
        </ContainerTest>
      ) : (
        <NotTest>
          <p>No tienes pruebas pendientes por contestar.</p>
        </NotTest>
      )}
    </MainLayout>
  )
}

export default Home
