import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainLayout } from 'views/layouts'
import BluesiteTable from 'views/components/UI/BluesiteTable'
import { useNotification } from 'lib/context/notification.context'
import { ActionViewDownloadAddUsers } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { SubtitleBold } from 'views/components/UI/Text'
import Loader from 'views/components/UI/Loader'
import { Container, TableContainer } from './styled'
import { useFetchProcesses } from 'hooks/process/useGetProcess/useGetProcess'
import { useFinishProcess } from 'hooks/process/useFinishProcess/useFinishProcess'
import { getProcessDetail } from 'services/common/process/createProcess/create-process-service'
import { platformAdminRoutes } from 'router/routes'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useReportDownloaderByExcel from 'hooks/process/useGetExcelReportByProcess/use-get-excel-report-by-process'
import { useFetchAllProcesses } from 'hooks/process/useGetAllProcessCurrentCompany/useGetAllProcessCurrentCompany'
import { HEADERS_SENT_PROCESSES } from '../constants'
import SelectSearch from '../../Analytics/selectSearch'

type IDownloadProcess = {
  id: number
  users: number[]
  name: string
  competencesTest: boolean
  betesaTest: boolean
}

interface DownloadProcess {
  processId: number
  processName: string
  useBetesa: boolean
  useCompetence: boolean
}

const DeliveredProcesses = () => {
  const navigate = useNavigate()
  const { handleFinishProcess } = useFinishProcess()
  const { getError } = useNotification()

  const { listProcess, fetchProcesses, loadingListProcesses, paginationListProcess } = useFetchProcesses()
  const { fetchProcesses: fetchAllProcess } = useFetchProcesses()
  const { allProcess, fetchAllProcesses } = useFetchAllProcesses()

  const { fetchGetReportsByExcel } = useReportDownloaderByExcel()
  const [loading, setLoading] = useState(false)
  const [processSelected, setProcessSelected] = useState<number | ''>('')
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (processSelected === '') {
      fetchAllProcesses()
    }
  }, [processSelected, fetchAllProcesses])

  useEffect(() => {
    const processIDs = allProcess?.map((processFilter) => processFilter.value)
    const numberProcessIDs = processIDs?.map((id) => Number(id))
    const processToSend = !processSelected ? numberProcessIDs : processSelected

    if (processIDs?.length === 0 || !processToSend) {
      return
    }

    fetchProcesses({ sent: true, processId: processToSend })
    fetchAllProcess({ sent: true, limit: 10000, processId: processToSend })
  }, [allProcess, processSelected, fetchProcesses, fetchAllProcess])

  function handleViewProcess(id: number) {
    navigate(platformAdminRoutes.DetailProcessDelivered, { state: { id } })
  }

  const handleSelectProcess = (processId: number) => {
    fetchAllProcess({ sent: true, limit: 10000, processId })
    setProcessSelected(processId)
  }

  const handleDownloadReports = ({ processId, processName, useBetesa, useCompetence }: DownloadProcess) =>
    fetchGetReportsByExcel({
      processId: processId,
      useBetesa,
      useCompetence,
      processName: processName,
    })

  const handleAddUsers = useCallback(
    (processId: number) => {
      getProcessDetail(processId)
        .then((response: any) => {
          setLoading(false)
          handleFinishProcess(response.process)
          navigate(platformAdminRoutes.CreateUsers, { state: { id: processId, isSendedProcess: true } })
        })
        .catch(() => {
          getError('Ha ocurrido un error al cargar este proceso')
          setLoading(false)
        })
    },
    [navigate, getError, handleFinishProcess]
  )

  const handlePagination = useCallback(
    (page: number) => {
      fetchProcesses({ sent: true, page })
      setCurrentPage(page)
    },
    [fetchProcesses, setCurrentPage]
  )

  return (
    <MainLayout>
      <Container>
        <h2>Procesos creados</h2>
        {!loadingListProcesses && !loading ? (
          <>
            {listProcess?.length > 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    width: 430,
                    alignItems: 'center',
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  <SubtitleBold>Visualizar:</SubtitleBold>
                  <SelectSearch
                    showCheckboxes={false}
                    noChip={true}
                    label="Todos los procesos"
                    options={allProcess || []}
                    value={processSelected}
                    onChange={(e: any) => {
                      handleSelectProcess(e as number)
                    }}
                    limitTags={1}
                  />
                  <HighlightOffIcon
                    style={{ cursor: 'pointer', color: 'gray' }}
                    onClick={() => {
                      setProcessSelected('')
                    }}
                  />
                </div>
                <TableContainer>
                  <BluesiteTable
                    data={listProcess}
                    headers={HEADERS_SENT_PROCESSES}
                    searchBar={false}
                    searchLabel=""
                    ActionComponent={ActionViewDownloadAddUsers}
                    totalPages={paginationListProcess.totalPages}
                    currentPage={currentPage}
                    handlePagination={handlePagination}
                    extraDataAction={{
                      onAddUsers: (e: number) => handleAddUsers(e),
                      onViewProcess: (e: number) => handleViewProcess(e),
                      onDownloadProcess: (e: IDownloadProcess) =>
                        handleDownloadReports({
                          processId: e.id,
                          processName: e.name,
                          useBetesa: e.betesaTest,
                          useCompetence: e.competencesTest,
                        }),
                    }}
                  />
                </TableContainer>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                }}
              >
                <h3 style={{ color: 'rgb(198, 1, 107)' }}>Esta compañía no tiene procesos creados</h3>
              </div>
            )}
          </>
        ) : (
          <div style={{ margin: '80px 0px' }}>
            <Loader />
          </div>
        )}
      </Container>
    </MainLayout>
  )
}

export default DeliveredProcesses
