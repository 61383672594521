import axios, { AxiosRequestConfig } from 'axios'


const getSumary = async (token: string) => {
  const fileUrl = `${process.env.REACT_APP_MAIN_API}/company/subscription/summary/file`
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }

    const response = await axios.get(fileUrl, config).then((res)=>{
      const url = window.URL.createObjectURL(new Blob([res.data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'resumen_general_de_inventarios.xlsx') 
      document.body.appendChild(link)
      link.click()
    }).catch((err)=>{
      return err
    })
  return response
}
export default getSumary
