export const API_URL = process.env.REACT_APP_MAIN_API
export const SKILLS_NAME = '3D Skills'
export const MEGABYTE = 1024
export const MAX_FILE_SIZE_LOGO = 600 * MEGABYTE
export const IOS_DEVICE = /iPad|iPhone|iPod/
export const ANDROID_DEVICE = /Android/
export const STORAGE_TOKEN = 'persist:root'
export const TOKEN = 'token'
export const FIRST_TOKEN = 't'
export const DEFAULT_LIMIT = 10
export const FIRST_PAGE = 1
export const EMPTY_STRING = ''
export const BETESA_CATEGORY_ID = 1
export const STATUS_OK = 'OK'
export const BACK_PAGE = -1
const SUPER_ADMIN_ROLE_ID = 2
export const PLATFORM_ADMIN_ROLE_ID = 3
const EVALUATE_USER_ROLE_ID = 4
const SUPER_PLATFORM_USER_ROLE_ID = 6

export const USER_ROLE_ID = {
  super_admin: SUPER_ADMIN_ROLE_ID,
  platform_admin: PLATFORM_ADMIN_ROLE_ID,
  user: EVALUATE_USER_ROLE_ID,
  super_platform_admin: SUPER_PLATFORM_USER_ROLE_ID
}

export const DEFAULT_LEVEL = 'Táctico'

export const DOCUMENT_TYPES = [
  { value: '1', label: 'Documento de identidad' },
  { value: '2', label: 'Documento de extranjería' },
  { value: '3', label: 'Pasaporte' },
  { value: '4', label: 'Tarjeta de identidad' },
]

export enum Routes {
  home = '/',
  login = '/login',
  register = '/register',
  forgot_password = '/forgot-password',
  reset_password = '/reset-password',
  dashboard = '/dashboard',
  profile = '/profile',
  users = '/user',
}

export const BRAIN_QUADRANTS = [
  {
    value: 1,
    label: 'FI',
  },
  {
    value: 2,
    label: 'BI',
  },
  {
    value: 3,
    label: 'BD',
  },
  {
    value: 4,
    label: 'FD',
  },
  {
    value: 5,
    label: 'DB',
  },
  {
    value: 6,
    label: 'DF',
  },
  {
    value: 7,
    label: 'DD',
  },
  {
    value: 8,
    label: 'DI',
  },
]

export const SELECTED_QUADRANTS = [1, 2, 3, 4]

export const NEW_PROCESS_TRUE = true

export interface Pagination {
  totalPages: number
  totalResults: number
  page?: number
}

export const TYPE_QUESTION = [
  { value: 'MULTIPLE', label: 'Selección multiple' },
  { value: 'DRAG_AND_DROP', label: 'Drag and Drop' },
  { value: 'UNIQUE', label: 'Unica respuesta' },
]

export const QUADRANT_OPTIONS = [
  { value: 'FI', label: 'FI' },
  { value: 'FD', label: 'FD' },
  { value: 'BI', label: 'BI' },
  { value: 'BD', label: 'BD' },
]

export const NO_INTERPRETABLE_TEXT = 'No interpretable'

export const BETESA_MAX_GENERAL_SCORE = {
  childHood: 132,
}
export const BETESA_MAX_SCORE_PER_CHAPTER = {
  childHood: 36,
}

// FORMIK CONSTANTS
export const FORMIK_SPECIAL_CHARACTERS_REGEX = /[!@#$%^&*(),.?":{}|<>]/
export const FORMIK_UPPERCASE_REGEX = /^(?=.*[A-Z]).+$/
export const FORMIK_LOWERCASE_REGEX = /^(?=.*[a-z]).+$/
export const FORMIK_NUMBER_REGEX = /[0-9]/
export const FORMIK_EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const ALERT_PASSWORD_LENGTH = 'Contraseńa muy corta'
export const ALERT_PASSWORD_UPPERCASE = 'La contraseña debe contener al menos una mayúscula'
export const ALERT_PASSWORD_LOWERCASE = 'La contraseña debe contener al menos una minúscula'
export const ALERT_PASSWORD_SPECIAL_CHARACTERS = 'La contraseña debe contener al menos un caracter especial'
export const ALERT_PASSWORD_CONFIRM = 'Las contraseñas ingresadas no coinciden'
export const ALERT_PASSWORD_NUMBER = 'La contraseña debe contener al menos un número'
export const ALERT_EMAIL = 'El email no es válido'
export const ALERT_PASSWORD_REQUIRED = 'La contraseña es requerida'
export const ALERT_REQUIRED = 'Este campo es requerido'
export const ALERT_MIN_LENGTH = 'Muy corta'
export const ALERT_MAX_LENGTH = 'Contraseña demasiado larga'
