import ScoreProgressGraphic from '../score-progress-graphic'
import { CompetenceNameHeadText, ContainerTd, Row } from './styled'

const IdealProfileTable = ({ competences }: any) => {
  return (
    <table
      style={{
        borderStyle: 'none',
        marginBottom: 0,
        width: '90%',
        alignSelf: 'center',
      }}
    >
      {competences.map((competence: any, competenceIndex: any) => (
        <tbody key={`${competence}${competenceIndex}`}>
          <tr>
            <CompetenceNameHeadText>{competence.competence}</CompetenceNameHeadText>
            <ContainerTd>
              <Row>
                <ScoreProgressGraphic
                  adjustLevel={true}
                  idealScore={competence.ideal_profile}
                  userScore={competence.competence_score}
                  backgroundColorBar="#D2E3EC"
                />
              </Row>
            </ContainerTd>
          </tr>
        </tbody>
      ))}
    </table>
  )
}

export default IdealProfileTable
