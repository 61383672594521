import styled from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const LanguageRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
export const LanguageRowItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`
export const SelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
`
export const Paragraph = styled.p`
  color: #1f2124;
  font-size: 18px;
  @media (max-width: 574px) {
    font-size: 2.5vw;
  }
`
export const TextArea = styled.textarea`
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 30px;
  width: 100%;
  height: 120px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  /* s1 */

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const BackContainer = styled.div`
  margin-top: 50px;
  margin-bottom: -40px;
`
