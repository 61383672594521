import styled from "styled-components";

export const ErrorContainer = styled.div`
margin-top: 0px;
margin-bottom: 12px;
color: red;
`;

export const TextError = styled.span`
    color: red;
    text-align: left!important;
`;

export const LastButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
`;


export const TextUploadSizeRecomended = styled.p`
  width: 174px;
  color: #797A7C;
  font-weight: 100;
  font-size: 16px;
  margin-left: 20px;
`

export const ChangeLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 40px;
`;