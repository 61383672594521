import React, { useRef } from 'react'
import { Block } from '../../../components/UI/Contents'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// Components
import { Container, Row, ErrorText, ItemValidation, ValidationsContainer } from './styled'
import { Button } from '../../../components/UI/Buttons'
import Input from '../../UI/TextInput'
import { H2, P } from 'views/components/UI/Text'
import { useNotification } from 'lib/context/notification.context'
import { IItemValidate } from '../Register/constants'
import { Check } from '@mui/icons-material'
import {
  VALIDATE_LOWERCASE_REGEX,
  VALIDATE_SPECIAL_CHARACTERS_REGEX,
  VALIDATE_UPPERCASE_REGEX,
} from 'constants/regex.constants'
import {
  ALERT_PASSWORD_LENGTH,
  ALERT_MAX_LENGTH,
  ALERT_PASSWORD_REQUIRED,
  FORMIK_UPPERCASE_REGEX,
  ALERT_PASSWORD_UPPERCASE,
  FORMIK_LOWERCASE_REGEX,
  ALERT_PASSWORD_LOWERCASE,
  FORMIK_SPECIAL_CHARACTERS_REGEX,
  ALERT_PASSWORD_SPECIAL_CHARACTERS,
  ALERT_PASSWORD_CONFIRM,
  ALERT_MIN_LENGTH,
  ALERT_REQUIRED,
} from 'constants/global.constants'
import { t } from 'i18next'

const SignInSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, ALERT_PASSWORD_LENGTH)
    .max(50, ALERT_MAX_LENGTH)
    .required(ALERT_PASSWORD_REQUIRED)
    .matches(FORMIK_UPPERCASE_REGEX, ALERT_PASSWORD_UPPERCASE)
    .matches(FORMIK_LOWERCASE_REGEX, ALERT_PASSWORD_LOWERCASE)
    .matches(FORMIK_SPECIAL_CHARACTERS_REGEX, ALERT_PASSWORD_SPECIAL_CHARACTERS),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], ALERT_PASSWORD_CONFIRM)
    .min(8, ALERT_MIN_LENGTH)
    .max(50, ALERT_MAX_LENGTH)
    .required(ALERT_REQUIRED),
})

interface IProps {
  onSubmit: (values: any) => void
}

const ItemValidate = (props: IItemValidate) => {
  const { text, validate } = props

  return (
    <ItemValidation>
      <Check sx={{ color: validate ? '#60CC1E' : '#797A7C' }} />
      <P margin="0px 6px" color={validate ? '#60CC1E' : '#797A7C'}>
        {text}
      </P>
    </ItemValidation>
  )
}

// MAIN
/**
 * @description Form to change password
 */
const ChangePasswordForm = (props: IProps) => {
  const { getSuccess } = useNotification()
  const { onSubmit } = props

  const initialValues = useRef({
    password: '',
    confirmPassword: '',
  })

  // const dispatch = useDispatch()

  const handleSubmit = (values: any): void => {
    onSubmit(values)
    getSuccess(t('global.notifications.sendDataSuccess'))
  }

  const validateButton = (values: any, errors: any): boolean => {
    if (values.password.length === 0 || errors.password || errors.confirmPassword) {
      return true
    }

    return false
  }

  return (
    <Container>
      <H2 marginTop="20px">Ingrese su nueva contraseña</H2>

      <Formik
        validateOnChange={true}
        validationSchema={SignInSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues.current}
      >
        {({ errors, touched, values, handleChange }) => (
          <Form style={{ width: '100%' }}>
            <Block display="flex" flexDirection="column">
              <Block m="30px 0px 16px 0px">
                <Input
                  value={values.password}
                  onChange={handleChange('password')}
                  type="password"
                  name="password"
                  label="Contraseña"
                  error={errors.password && touched.password ? true : false}
                />
                {errors.password && touched.password ? (
                  <div>
                    <ErrorText>{errors.password}</ErrorText>
                  </div>
                ) : null}
              </Block>
              {errors && (
                <ValidationsContainer>
                  <ItemValidate
                    text="Una mayúscula"
                    validate={values.password.match(VALIDATE_UPPERCASE_REGEX) ? true : false}
                  />

                  <ItemValidate
                    text="Una minúscula"
                    validate={values.password.match(VALIDATE_LOWERCASE_REGEX) ? true : false}
                  />

                  <ItemValidate
                    text="Un caracter especial (!#$%&*-/?_~)"
                    validate={values.password.match(VALIDATE_SPECIAL_CHARACTERS_REGEX) ? true : false}
                  />

                  <ItemValidate text="Mínimo 8 caracteres" validate={values.password.length >= 8 ? true : false} />
                </ValidationsContainer>
              )}

              <Block m="0px 0px 30px 0px">
                <Input
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  type="password"
                  name="confirm-password"
                  label="Confirmar contraseña"
                  error={errors.confirmPassword && touched.confirmPassword ? true : false}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <div>
                    <ErrorText>{errors.confirmPassword}</ErrorText>
                  </div>
                ) : null}
              </Block>

              <div style={{ display: 'flex', flexDirection: 'row', verticalAlign: 'middle' }}>
                <Row>
                  <Button
                    style={{
                      width: '100%',
                    }}
                    disabled={validateButton(values, errors)}
                    type="submit"
                  >
                    Cambiar contraseña
                  </Button>
                </Row>
              </div>
            </Block>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ChangePasswordForm
